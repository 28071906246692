<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-back-button
        (click)="close()"
        style="display: block !important"
        back-close
        back-locale
        [disableClose]="showBackButton"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>{{
      "deviceManagement.deviceSettings.threedPickerHeader" | translate
    }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col
        size-md="3"
        size-xs="4"
        *ngFor="let model of models"
        class="model-item"
      >
        <ion-item
          (click)="selectItem(model)"
          mode="md"
          lines="none"
          button
          class="ion-no-padding"
        >
          <!-- <ion-checkbox
            color="primary"
            [checked]="img.isSelected"
            [hidden]="!enableSelection"
          ></ion-checkbox> -->
          <ion-img
            #image
            [src]="model.img"
          >
          </ion-img>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>