import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GeofenceinfopopupComponent } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/alert/geofenceinfopopup/geofenceinfopopup.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PajPopupTemplateModule } from '../../../../../../components/paj-popup-template/paj-popup-template.module';
import { AlertComponent } from './alert.component';
import { GeoSelectComponent } from './geo-select/geo-select.component';
import { AlertSettingComponent } from './modal/alert-setting/alert-setting.component';
import { ShockAlertPopupComponent } from './shock-alert-popup/shock-alert-popup.component';
import { SosComponent } from './sos/sos.component';
import { VibrateOnToggleDirectiveModule } from 'src/app/directives/vibration-for-toggle-buttons.module';
import { SelectDeviceComponent } from '../select-device/select-device.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BackgroundContrastDirective } from 'src/app/directives/background-contrast';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { AlertInfoPopupComponent } from './alert-info-popup/alert-info-popup.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translations/", ".json");
}

@NgModule({
    imports: [
      CommonModule,
      IonicModule,
      FormsModule,
      ReactiveFormsModule,
      PipesModule.forRoot(),
      PajPopupTemplateModule,
      VibrateOnToggleDirectiveModule,
      ScrollingModule,
      DirectivesModule,
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
    ],

    declarations: [AlertComponent, GeoSelectComponent, AlertSettingComponent, SosComponent, GeofenceinfopopupComponent, ShockAlertPopupComponent,SelectDeviceComponent, AlertInfoPopupComponent],
    exports: [AlertComponent, GeoSelectComponent, AlertSettingComponent, SosComponent, GeofenceinfopopupComponent, ShockAlertPopupComponent, VibrateOnToggleDirectiveModule],

  })

  export class PajDeviceAlertModule {}
