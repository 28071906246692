<ng-container [ngTemplateOutlet]="timerTemplate ? timerTemplate : DEFAULT_TIMER" [ngTemplateOutletContext]="{ h, m, s }"></ng-container>

<ng-template #DEFAULT_TIMER>
  <div class="normal" [class.small]="isSmall">
    <ion-text>
      <h1 *ngIf="showHour">{{ h }}<span *ngIf="showMin">:</span></h1>
      <h1 *ngIf="showMin">{{ m }}<span *ngIf="showSec">:</span></h1>
      <h2 *ngIf="showSec">{{ s }}</h2>
    </ion-text>
  </div> 
</ng-template>
