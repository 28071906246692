import { StepOptions, TourOptions } from "src/app/components/tour/tour.component"

export const mapTour = (tour, translate, data: any = { isBrowser: false } ) => {
  //let tour: TourOptions = { name: 'tour.map'}; //_ Thiking when someone else, say; but the tour will able to stop and continue later
  //_ Or can skipped or canceled tourOptions will used for this 
  const isSmallScale = window.screen.width < 768;
  let steps: StepOptions[] = [];
  
  steps = [
    {
      dom: '',
      title: translate.instant('mapTour.step1.title'),
      content: translate.instant('mapTour.step1.content'),
      image: 'assets/images/tour/start.svg',
      delay: 0,
      isStart: true,
      tour: tour
    },
    {
      dom: '.mainMenuButton',
      title: translate.instant('mapTour.step2.title'),
      content: translate.instant('mapTour.step2.content'),
      image: 'assets/images/tour/map/i1.gif',
      mobileImage: 'assets/images/tour/map/i1-mobile.gif',
      delay: 500,
    },
    {
      dom: '#menuList',
      title: translate.instant('mapTour.step3.title'),
      content: translate.instant('mapTour.step3.content'),
      image: 'assets/images/tour/map/i2.gif',
      mobileImage: 'assets/images/tour/map/i2-mobile.gif',
      delay: 600
    },
    {
      dom: '.notificationButton',
      title: translate.instant('mapTour.step4.title'),
      content: translate.instant('mapTour.step4.content'),
      image: 'assets/images/tour/map/new-i3.gif',
      mobileImage: 'assets/images/tour/map/new-i3-mobile.gif',
      delay: 300
    },
    {
      dom: '.devicesMenuButton',
      title: translate.instant('mapTour.step5.title'),
      content: translate.instant('mapTour.step5.content'),
      image: 'assets/images/tour/map/i4.gif',
      mobileImage: 'assets/images/tour/map/i4-mobile.gif',
      delay: 100
    },
    {
      dom: '#map3dview',
      title: translate.instant('mapTour.step6.title'),
      content: translate.instant('mapTour.step6.content'),
      image: 'assets/images/tour/map/i5.gif',
      mobileImage: 'assets/images/tour/map/i5-mobile.gif',
      delay: 600
    },
    {
      dom: '#mapGps' + (isSmallScale ? '': '-NO-SHOW'),
      title: translate.instant('mapTour.step7.title'),
      content: translate.instant('mapTour.step7.content'),
      image: 'assets/images/tour/map/i6.gif',
      mobileImage: 'assets/images/tour/map/i6-mobile.gif',
      delay: 100
    },
    {
      dom: '#mapCompass',
      title: translate.instant('mapTour.step8.title'),
      content: translate.instant('mapTour.step8.content'),
      image: 'assets/images/tour/map/i7.gif',
      mobileImage: 'assets/images/tour/map/i7-mobile.gif',
      delay: 100
    },
    {
      dom: '#mapSpeedPolylinesToggle',
      title: translate.instant('mapTour.step8.speedPolylinesTitle'),
      content: translate.instant('mapTour.step8.speedPolylinesContent'),
      image: 'assets/images/tour/map/ispeed-polylines.gif',
      mobileImage: 'assets/images/tour/map/ispeed-polylines-mobile.gif',
      delay: 100
    },
    {
      dom: '#mapHeatmapToggle',
      title: translate.instant('mapTour.heatmap.title'),
      content: translate.instant('mapTour.heatmap.content'),
      image: 'assets/images/tour/map/heatmap.gif',
      mobileImage: 'assets/images/tour/map/heatmap-mobile.gif',
      delay: 100
    },
    {
      dom: '#mapAlarmMarker',
      title: translate.instant('mapTour.alarmMarker.title'),
      content: translate.instant('mapTour.alarmMarker.content'),
      image: 'assets/images/tour/map/ialarm.gif',
      mobileImage: 'assets/images/tour/map/ialarm-mobile.gif',
      delay: 100
    },
    {
      dom: '#mapRoutemarker',
      title: translate.instant('mapTour.step9.title'),
      content: translate.instant('mapTour.step9.content'),
      image: 'assets/images/tour/map/i8-new.gif',
      mobileImage: 'assets/images/tour/map/i8-mobile-new.gif',
      delay: 100
    },
    {
      dom: '#mapPolylines',
      title: translate.instant('mapTour.step10.title'),
      content: translate.instant('mapTour.step10.content'),
      image: 'assets/images/tour/map/ipolylines.gif',
      mobileImage: 'assets/images/tour/map/ipolylines-mobile.gif',
      delay: 100
    },
  ];

  console.log('STEPS', steps)
  return steps;
}