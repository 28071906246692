import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class sidebarService {
  public updateUserInfo = new BehaviorSubject<any>([]);
  public choiceTour = new Subject();
  constructor() { }

}