<ion-menu
  menuId="devicesDashboard"
  side="end"
  contentId="main-map-content"
  type="overlay"
  swipeGesture="false"
  swipeEnabled="false"
  (ionWillOpen)="openStatus(true)"
  (ionDidOpen)="open()"
  (ionDidClose)="close()"
  (ionWillClose)="openStatus(false)"
>
  <ion-nav #navDashboard></ion-nav>
</ion-menu>