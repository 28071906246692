
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private goToSetting = new Subject<void>();
  goToSetting$ = this.goToSetting.asObservable();

  emitEvent(data: any){
    this.goToSetting.next(data)
  }
}