export const App_Languages = [
    {
        id: 'en',
        shortCode: 'EN',
        name: 'English (UK)',
        img: 'assets/images/language-picker/uk.svg'
    },
    {
        id: 'en_US',
        shortCode: 'EN (US)',
        name: 'English (US)',
        img: 'assets/images/language-picker/us.svg'
    },
    {
        id: 'de',
        shortCode: 'DE',
        name: 'Deutsch',
        img: 'assets/images/language-picker/germany.svg'
    },
    {
        id: 'es',
        shortCode: 'ES',
        name: 'Español',
        img: 'assets/images/language-picker/spain.svg'
    },
    {
        id: 'fr',
        shortCode: 'FR',
        name: 'Français',
        img: 'assets/images/language-picker/france.svg'
    },
    {
        id: 'it',
        shortCode: 'IT',
        name: 'Italiano',
        img: 'assets/images/language-picker/italy.svg'
    },
    {
        id: 'pl',
        shortCode: 'PL',
        name: 'Polski',
        img: 'assets/images/language-picker/poland.svg'
    },
    {
        id: 'pt',
        shortCode: 'PT',
        name: 'Português',
        img: 'assets/images/language-picker/portugal.svg'
    },
    {
        id: 'el',
        shortCode: 'GR',
        name: 'Greek',
        img: 'assets/images/language-picker/greece.svg'
    },
    {
        id: 'nl',
        shortCode: 'NL',
        name: 'Dutch',
        img: 'assets/images/language-picker/netherland.svg'
    }
];