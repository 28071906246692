import { Injectable } from "@angular/core";
import { Browser } from "@capacitor/browser";
@Injectable({
  providedIn: "root",
})
export class BrowserService {
  constructor() {}

  async openBrowser(url, type = "_blank") {

    await Browser.open({ url: url, windowName: type });
  }
}
