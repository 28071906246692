<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-back-button
        (click)="dismiss()"
        style="display: block !important"
        back-close
        back-locale
      ></ion-back-button>
    </ion-buttons>
    <ion-title>{{ "deviceNote.deviceNote" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item lines="none">
    <!-- <ion-button class="no-shadow" slot="end" color="primary" *ngIf="!isEditing" (click)="isEditing = true" shape="round" size="default">
      <ion-icon name="create-outline" slot="start"></ion-icon>
      <ion-label>Edit</ion-label>
    </ion-button> -->

    <ion-button
      class="no-shadow"
      slot="end"
      color="light"
      (click)="deleteNote()"
      shape="round"
      size="default"
      *ngIf="note"
    >
      <ion-icon name="trash-outline" slot="start"></ion-icon>
      <ion-label>{{ "deviceNote.delete" | translate }}</ion-label>
    </ion-button>

    <!-- <div slot="end" *ngIf="isEditing">
      <ion-button class="no-shadow" color="primary" shape="round" size="default" (click)="save()">
        <ion-icon name="save" slot="start"></ion-icon>
        <ion-label>Save</ion-label>
      </ion-button>
      <ion-button class="no-shadow" color="light" (click)="cancel()" shape="round" size="default" [hidden]="!note">
        <ion-icon name="ban-outline" slot="start"></ion-icon>
        <ion-label>Cancel</ion-label>
      </ion-button>
    </div> -->
  </ion-item>

  <!-- NOTE FORM -->
  <form [formGroup]="noteForm">
    <ion-item lines="none">
      <ion-textarea
        placeholder="{{ 'deviceNote.enterMoreInformation' | translate }}"
        rows="7"
        formControlName="note"
        [backgroundContrast]="selectedColor"
        maxlength="255"
      >
      </ion-textarea>
    </ion-item>
  </form>

  <section class="bottom-section">
    <div class="div-colors">
      <div
        class="circle"
        *ngFor="let c of colors"
        [style.background]="c"
        (click)="changeColor(c)"
      ></div>
    </div>
    <div class="string-size">{{ noteLength }}</div>
  </section>
  <section class="ion-text-center">
    <ion-button class="save-button"
        slot="end"
        color="primary"
        shape="round"
        size="default"
        (click)="saveNote()">
      Save
    </ion-button>
  </section>
</ion-content>
