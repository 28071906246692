
import { environment } from "src/environments/environment";
import mapboxgl from 'mapbox-gl';
import { AuthenticationService } from "src/app/services/authentication.service";
import { inject } from "@angular/core";
import { MapEventType } from "maplibre-gl";

export interface DynamicMapLib_Model {
  Map;
  Marker;
  NavigationControl;
  LngLatBounds;
  LngLat;
  GeolocateControl;
  GeoJSONSource;
  AttributionControl;
  Popup;
  MAP_LIB;
  mapEventType: MapEventType;
}

//_ Lazy loading of map libraries based on the useMapbox flag to load one or another Interfaces in the class vars
export class DynamicMapLib implements DynamicMapLib_Model {
  public Map: any;
  public Marker;
  public NavigationControl;
  public LngLatBounds;
  public LngLat;
  public GeolocateControl;
  public GeoJSONSource;
  public AttributionControl;
  public Popup;
  public MAP_LIB;
  public libName = 'maplibre';
  public mapEventType: MapEventType;

  private readonly _authService: AuthenticationService = inject(AuthenticationService);
  constructor() { }

  public async setupMapLibrary(useMapbox: boolean) {
    const maplibName = useMapbox ? 'mapbox' : 'maplibre';
    this.mapEventType = null as any;
    this.libName = maplibName;

    return new Promise((resolve, reject) => {
      if (useMapbox) {
        import("mapbox-gl").then(mapbox => {
          this.Map = mapbox.Map;
          this.Marker = mapbox.Marker;
          this.NavigationControl = mapbox.NavigationControl;
          this.LngLatBounds = mapbox.LngLatBounds;
          this.LngLat = mapbox.LngLat;
          this.GeolocateControl = mapbox.GeolocateControl;
          this.GeoJSONSource = mapbox.GeoJSONSource;
          this.AttributionControl = mapbox.AttributionControl;
          this.MAP_LIB = mapbox;
          this.Popup = mapbox.Popup;
          mapboxgl.accessToken = environment.MAPBOX_TOKEN;


          this.postMetric(maplibName);
          resolve(this.MAP_LIB);
        });
      } else {
        import("maplibre-gl").then(mgl => {
          const maplibre = mgl.default ? mgl.default : mgl;
          // console.log('MAP LIB LOADED', maplibre);
          this.Map = maplibre.Map;
          this.Marker = maplibre.Marker;
          this.NavigationControl = maplibre.NavigationControl;
          this.LngLatBounds = maplibre.LngLatBounds;
          this.LngLat = maplibre.LngLat;
          this.GeolocateControl = maplibre.GeolocateControl;
          this.GeoJSONSource = maplibre.GeoJSONSource;
          this.AttributionControl = maplibre.AttributionControl;
          this.Popup = maplibre.Popup;
          this.MAP_LIB = maplibre;


          this.postMetric(maplibName);
          resolve(this.MAP_LIB);
        });
      }
    })

  }

  private postMetric (maplibName) {
    console.warn('LOADING MAP LIB ' + maplibName);
    //_ Post event for metrics
    this._authService.countlyMetrics({ key: 'Map Init ' + maplibName }).subscribe();
  }
}
