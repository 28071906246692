import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ModalController } from '@ionic/angular';
import { cropCircularImage, frametize } from './files-util';
import { animations } from 'src/app/animations/animations';
import { MediaApiService } from '../media-api.service';

@Component({
  selector: 'app-crop-and-frame',
  templateUrl: './cut-and-frame.component.html',
  styleUrls: ['./cut-and-frame.component.scss'],
  animations: animations
})
export class CutAndFrameComponent implements OnInit {
  @Input() files = [];
  @Input() acceptText = 'Upload';
  @Input() showAcceptIcon = true;

  imagesData = [];
  selectedImage = null;
  sampleImage = null;
  tempFile = null;

  isCropping = false;
  isFramming = false;
  cropCircle = false;
  rotation = 0;
  loading = true;
  constructor(private modalCtrl: ModalController, private mediaService: MediaApiService) { }

  async ngOnInit() {
    if (this.files.length > 0) {
      for (let i = 0; i < this.files.length; i++) {
        let file: any = this.files[i];
        //_ Compresed before to load in the tool for performance to manage small resolution/file size of the images.
        file = await this.mediaService.compressFile(file, { maxSizeMB: 1, useWebWorker: true, maxWidthOrHeight: 1500 }).catch(e => {
          console.log('ERROR COMPRESSION FILE', e);
          return null;
        });
        
        this.imagesData[i] = { id: i, image: this.mediaService.getBase64(file), file: file };
      }

      this.loading = false;
      setTimeout(() => { this.selectImage(this.imagesData[0]) }, 200);
    }
  }

  //_ Get the croped data when drag or resize the image-cropper shpae
  async imageCropped(event: ImageCroppedEvent) {
    this.tempFile = event.base64
  }

  //_ @base64 image string
  //_ Output File object
  async doCrop(base64String) {
    let base64: any = base64String;
    if (this.cropCircle)
      base64 = await cropCircularImage(base64String);

    return this.mediaService.base64ToFile(base64, this.selectedImage.file.name);
  }

  async selectImage(imageData) {
    this.selectedImage = imageData;
  }

  async enableCrop() {
    this.sampleImage = await this.mediaService.getBase64(this.selectedImage.file);
    this.isCropping = true;
  }

  async enableFrame() {
    let img = await this.mediaService.getBase64(this.selectedImage.file);
    img = await cropCircularImage(img, 500, 500);
    this.sampleImage = await frametize(img, 'assets/default_brand/img/frame1.png', 430, 430, { x: 5, y: 5 });
    this.tempFile = this.sampleImage;
    this.isFramming = true;
  }

  close() {
    this.modalCtrl.dismiss();
  }

  //_ Apply changes crop and shape
  //_ frametize is already done in sampleImage and tempFile
  async applyChange() {
    this.tempFile = await this.doCrop(this.tempFile);
    if (this.tempFile) {
      this.imagesData[this.selectedImage.id].file = this.tempFile;
      this.imagesData[this.selectedImage.id].image = this.mediaService.getBase64(this.tempFile);
      this.selectImage(this.imagesData[this.selectedImage.id]);
    }

    this.cancel();
  }

  cancel() {
    this.isCropping = false;
    this.isFramming = false;
    this.tempFile = null;
    this.rotation = 0;
  }

  upload() {
    this.modalCtrl.dismiss(this.imagesData.map(i => i.file));
  }
}
