import { Pipe, PipeTransform } from '@angular/core';
import { HumanizeTimeService } from '../services/humanize-time.service';
import * as moment from "moment/moment";

@Pipe({
  name: 'translateUnixDate'
})

// Use humanizeTimerService which has date format translation functions
// Using moment and the selected language
// Used for translate dates in html files
export class TranslateDatePipe implements PipeTransform { //duration in seconds
  constructor(private humanizeTime: HumanizeTimeService) { }

  transform(date: any, format?: any, alwaysUseGivenFormat= false, dateType = '', language = null) {
    if (dateType == 'groupDate') date = moment(date.split(' ')[1], "DD.MM.YYYY").unix();
    return this.humanizeTime.formatDateTime(date, format, alwaysUseGivenFormat);
  }
}
