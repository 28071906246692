import { StorageService } from "../services/storage.service";

export const ADMIN_URL = '/a/customer/';

//_ Factory to initialize something when app start bootstraping
export const AppInitializerFactory = (storage: StorageService) => {
  return (): Promise<any> => {
    return new Promise( (resolve, reject) => {
        //_ Example hide show/hide splash screen :)
    });
  };
}

//_ Set up base url to not duplicate routes for admins
//_ Using already existents routes just added a base url
export const BaseHrefFactory = () =>{
  const path = window.location.pathname;
  if (path.startsWith(ADMIN_URL)){
    if (window['super_user'] == 1){
        const userId = path.split('/')[3];
        return ADMIN_URL + userId;
    }else
        return '/';
  }
}