import { Component, OnInit } from '@angular/core';
import { AlertController, PopoverController } from "@ionic/angular";
@Component({
  selector: 'app-device-warning',
  templateUrl: './device-warning.component.html',
  styleUrls: ['./device-warning.component.scss'],
})
export class DeviceWarningComponent implements OnInit {

  constructor(private popoverController: PopoverController) { }

  ngOnInit() { }
  onCancel() {
    this.popoverController.dismiss(null)
  }
}
