import { Pipe, PipeTransform } from '@angular/core';
import { HumanizeTimeService } from '../services/humanize-time.service';
@Pipe({
  name: 'JSON'
})

export class JsonPipe implements PipeTransform { //duration in seconds
  constructor() { }

  transform(value, property): any {
    return JSON[property](value);
  }
}