import { MapComponent } from "../../map.component";
import { getSource } from "../castMaplibreData";
import { AvailableHooks } from "../componentHooks";
import { Data, dataSourceType, dataTypes } from "../data";
import { DataSource } from "../dataSource";
import { Geometry, GeometryTypes } from "../geometry";
import { IProperties } from "../Iproperties";
import { Layer, layerType } from "../layer";
import { addMapImage } from "../mapUtil";
import { FeatureInterface } from "./feature-interface";
import * as turf from '@turf/turf'
import { TextObject } from "src/app/members/generate-video/canvasObjects/textObject";
import { ServiceLocator } from "src/app/locator.service";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs";
import { MainMapInterface } from "../../main-map.interface";

export class Watermark implements FeatureInterface {
    SOURCE_NAME: string = 'watermarkSource';
    LAYER_NAME: string = 'watermarkLayer'
    markImage = '';
    imageName = 'watermark'
    source = null;
    layer = null;
    show = false;
    obs = {};
    constructor(public main: MainMapInterface,  private translate = ServiceLocator.injector.get(TranslateService)){
        this.init();
    }

    init(){
        if (this.main.appService.user){
          this.loadWaterMarkFetures();
        }
        else{
          this.main.appService.user$.pipe(first()).subscribe(()=>{
          this.loadWaterMarkFetures();
          });
        }
    }
    loadWaterMarkFetures(){
      let user = this.main.appService.user;
      if (!user.isTestAccount) return;
      this.createSource();
      this.createLayer();
      this.markImage = this.createTextToImage();
      this.load();
    }

    //_ Listen for Component hooks to load data
    async load(){

        this.main.on(AvailableHooks.onLoadImages, (e) => {
            this.loadImage();
        });

        this.main.on(AvailableHooks.onLoadLayer, (e) => {
            if (!this.main.map.getLayer(this.LAYER_NAME))
                this.main.map.addLayer(this.layer, 'markers');
        });

        this.main.on(AvailableHooks.onLoadSource, (e) => {
            if (!this.main.map.getSource(this.SOURCE_NAME))
                this.main.map.addSource(this.SOURCE_NAME, this.source);
        });

        this.main.on(AvailableHooks.onDestroy, (e) => {
            this.onDestroy();
        })

        this.obs['mapReady'] = this.main.mapReady.subscribe( r => {
            if (r){
                this.loadFeature();
            }
        });

        //_ Wait a bit, because in appsettings obs is trigger before change app language (translate.use)
        this.obs['languageChange'] = this.main.appService.language$.subscribe(r => setTimeout( () => this.languageChanged(), 300));
        //_ Show or hide if is test Account
        if (this.main.appService.user)
            this.setVisible(this.main.appService.user?.isTestAccount);
        this.obs['userLoggedIn'] = this.main.appService.user$.subscribe((u: any) => this.setVisible(u.isTestAccount));
    }

    createLayer(){
        this.layer = new Layer(this.LAYER_NAME);
        this.layer.source = this.SOURCE_NAME;
        this.layer.type = 'fill';
        this.layer.paint['fill-pattern'] = this.imageName;
        this.layer.paint['fill-color'] = '#0080ff'; // blue color fill
        this.layer.paint['fill-opacity'] =  0.8;
        this.layer['filter'] = [
            "all",
            ["==", ['get', "showWatermark"], true], //_ Flag from user settings
        ];
    }

    createSource(){
        this.source = <DataSource>{};
        this.source.data = <Data>{};
        this.source.type = dataSourceType.geojson;
        this.source.data.type = dataTypes.Feature;
    }

    loadFeature(){
          let properties = {} as IProperties;
          properties["showWatermark"] = this.show;

          let geometry = new Geometry();
          delete(geometry.layerType);
          geometry.type = GeometryTypes.Polygon;
          geometry.coordinates = [
            [
                [-174.4557926665,-55.7124394654],
                [177.3906574141,-55.7124394654],
                [177.3906574141,81.75486262],
                [-174.4557926665,81.75486262],
                [-174.4557926665,-55.7124394654]
            ]
        ];

        this.source.data = this.main._createFeature(geometry, properties);;
          if (this.main.map) {
            if (this.main.map.getSource(this.SOURCE_NAME))
              getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
          }
    }

    createTextToImage(){
        const canvas = document.createElement('canvas');
        canvas.width = 200;
        canvas.height = 200;
        const text = this.translate.instant('app.map.testAccount');
        const textToImage = new TextObject(canvas.getContext("2d"), 0, 0, text, 15, 'center', '#f05123');
        textToImage.draw();
        return canvas.toDataURL();
    }

    setVisible(status){
        this.show = status;
        this.loadFeature();
        //_ Un load layer to free space
        if (status){
            if (!this.main.map?.getLayer(this.LAYER_NAME))
                this.main.map?.addLayer(this.layer, 'clusterMarkers');
        }else{
            if (this.main.map?.getLayer(this.LAYER_NAME))
                this.main.map?.removeLayer(this.LAYER_NAME);
        }
    }

    languageChanged(){
        if (this.main.map)
            this.loadImage();
    }

    loadImage(){
        if (this.main.map.hasImage(this.imageName))
            this.main.map.removeImage(this.imageName);
        this.markImage = this.createTextToImage();
        addMapImage(this.markImage, this.main.map, this.imageName, 200, 200);
    }

    onDestroy(){
        Object.keys(this.obs).forEach( k => this.obs[k].unsubscribe() );
    }

}
