import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import {
  ModalController,
  LoadingController,
  ToastController,
} from "@ionic/angular";
import { DeafultIconsComponent } from "../deafult-icons/deafult-icons.component";
import { AuthenticationService } from "./../../../../services/authentication.service";
import { Platform } from "@ionic/angular";
import { DomSanitizer } from "@angular/platform-browser";
import { StorageService as Storage } from "./../../../../services/storage.service";
import { TranslateService } from "@ngx-translate/core";
import { MediaManagmentComponent } from "src/app/components/media-managment/media-managment.component";
import { MediaApiService } from "src/app/components/media-managment/media-api.service";
import { DeviceManagmentService } from "../../device-managment.service";
import { MapService } from "src/app/members/map/components/map/map.service";
import { ThreedModels } from "src/app/members/map/components/map/class/features/3dModels";
import { CameraService } from "src/app/services/plugins/camera.service";
import { ThreedModelPickerComponent } from "../threed-mode-picker/threed-model-picker.component";
import { Constants } from '../../../../constants.enum';
import { AppService } from "src/app/app.service";
@Component({
  selector: "app-device-img",
  templateUrl: "./device-img.component.html",
  styleUrls: ["./device-img.component.scss"],
})
export class DeviceImgComponent implements OnInit {
  @Input() iconname: string;
  @Input() id: number;
  @Input() iconusecustom: number;
  @Input() url: string;
  @Input() user_id: number;
  @Input() iconcustomimage: string;
  @Input() device = null;
  @Input() showBackButton = false;
  @Input() customPopupClass = null;

  isApp: boolean;
  imgUrl: any;
  tempImage = "";
  @ViewChild("customimage") customimage: ElementRef;
  updateButton = false;
  imageFrom: string;
  newImageData: any;

  popoverOptions = [
    { id: 0, name: "Upload file" },
    { id: 1, name: "Choise from upload files" },
  ];
  use3DMarkers = null;
  constructor(
    public loadingCtrl: LoadingController,
    public modelCtrl: ModalController,
    public authService: AuthenticationService,
    public platform: Platform,
    private cameraService: CameraService,
    public toastController: ToastController,
    private sanitizer: DomSanitizer,
    private storage: Storage,
    private translate: TranslateService,
    private mediaApi: MediaApiService,
    private deviceService: DeviceManagmentService,
    private mapService: MapService,
    private appService: AppService,
  ) {}

  ngOnInit() {
    if (this.platform.is("mobileweb")) {
      this.isApp = false;
    } else {
      this.isApp = true;
    }
    if (this.iconusecustom) {
      if (this.iconcustomimage) {
        const collection = this.iconcustomimage.split("---")[0];
        this.customImage = this.mediaApi.createImageObject(
          collection,
          this.iconcustomimage
        );
      }
      //_ Select 3d model
      if (this.iconusecustom == 2) {
        this.threedObj = ThreedModels.find(
          (o) => o.name == this.device.properties.threedModel_name
        );
      }
    } else {
      this.imgUrl =
        "assets/device_markers/" +
        this.iconname +
        this.getImageExtention(this.iconname);
    }

    this.use3DMarkers = this.mapService.use3dMarkers.value;
    // this.user = this.appService.user;
  }

  async dismiss() {
    let iconUpdated = "";
    let customIconUpdated = "";
    let threedObj = null;
    if (!this.updateButton) {
      //_ This IF doesnt has sense !
      iconUpdated = this.iconname;
      customIconUpdated = this.iconcustomimage;
      threedObj = this.threedObj;
    }

    await this.modelCtrl.dismiss({
      dismissed: true,
      id: this.id,
      iconname: iconUpdated,
      iconusecustom: this.iconusecustom,
      iconcustomimage: customIconUpdated,
      threedObj: threedObj,
    });
  }

  async updateProfilePhoto() {
    switch (this.imageFrom) {
      case "defaultPhoto":
        await this.updateCustomImage(this.iconname, 0, null);
        break;
      case "3dModel":
        await this.save3dModel();
        break;
      default:
        await this.updateCustomImage(this.iconcustomimage, 1, null);
        break;
    }
    this.updateButton = false;
    this.dismiss();
  }

  async alertFunction(toastMsg, toastClass) {
    const toast = await this.toastController.create({
      message: toastMsg,
      duration: 2000,
      position: "bottom",
      cssClass: toastClass,
    });
    toast.present();
  }

  async updateCustomImage(iconName, isCustom, file = null) {
    const loading = await this.loadingCtrl.create({
      message: this.translate.instant("deviceManagement.deviceImg.pleaseWait"),
      translucent: true,
    });

    await this.authService
      .updateDeviceCustImage(this.user_id, this.id, isCustom, iconName, file)
      .then(async (res) => {
        this.authService.componentName.next("device_management");
        this.storage.set("updateMap", 1).then((call) => {});

      this.iconusecustom = isCustom;
      this.iconcustomimage = res.iconcustomimage;
      this.alertFunction(this.translate.instant('deviceManagement.deviceImg.ImageUploadedSuccessfully'), 'toaster');
    }).catch(error => {
      console.log("ERROR: ", error);
      this.alertFunction(this.translate.instant('deviceManagement.deviceImg.errorImageUpload'), 'toasterError');
    });

    loading.dismiss();
  }

  async DeafultImg() {
    const modal = await this.modelCtrl.create({
      component: DeafultIconsComponent,
      componentProps: { showBackButton: this.showBackButton },
      cssClass: this.customPopupClass ?? ''
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.iconname) {
      this.iconname = data.iconname;
      this.imgUrl =
        "assets/device_markers/" +
        this.iconname +
        this.getImageExtention(this.iconname);
      this.iconusecustom = 0;
      this.updateButton = true;
      this.imageFrom = "defaultPhoto";
    } else {
      this.updateButton = false;
    }
  }

  async fileupload($event: any) {
    try {
      const loading = await this.loadingCtrl.create({
        message: "Please Wait compressing Image",
        translucent: true,
      });
      await loading.present();
      if ($event.target.files[0]) {
        this.iconusecustom = 1;
        const reader = new FileReader();
        let imageUrl = "";
        let img = document.createElement("img");
        let canvas = document.createElement("canvas");
        reader.onload = async (e: any) => {
          imageUrl = e.target.result;
          this.tempImage = imageUrl;
          // Compress file

          img.src = imageUrl;

          let ctx = await canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          if (ctx) {
            const MAX_WIDTH = 200;
            const MAX_HEIGHT = 200;

            img.onload = async () => {
              let width = img.width;
              let height = img.height;
              if (img.width && img.height) {
                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx1 = await canvas.getContext("2d");
                await ctx1.drawImage(img, 0, 0, width, height);
                const dataUrl = canvas.toDataURL($event.target.files[0].type);
                this.imgUrl = dataUrl;
                this.updateButton = true;
                canvas.toBlob(async (blob) => {
                  this.newImageData = blob;
                  loading.dismiss();
                }, $event.target.files[0].type);
              } else {
                loading.dismiss();
              }
            };
          } else {
            loading.dismiss();
          }
          // End of Compress file
        };
        reader.readAsDataURL($event.target.files[0]);
      } else {
        loading.dismiss();
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  async openCamera() {
    // const options: CameraOptions = {
    //   quality: 15,
    //   allowEdit: true,
    //   destinationType: this.camera.DestinationType.FILE_URI,
    //   encodingType: this.camera.EncodingType.JPEG,
    //   mediaType: this.camera.MediaType.PICTURE,
    //   correctOrientation: true,
    // };
    let base64Image: any = await this.cameraService.getPicture();
    this.imgUrl = base64Image;
    this.customimage.nativeElement.src = this.imgUrl;
    this.newImageData = await base64Image.blob();
    this.imageFrom = "cameraPhoto";
    this.updateButton = true;
    // this.camera.getPicture(options).then(async (uri) => {
    //   this.file.resolveLocalFilesystemUrl(uri).then((entry: FileEntry) => {
    //     entry.file(file => {
    //       const reader = new FileReader();
    //       let imgBlob;
    //       reader.onloadend = async () => {
    //         imgBlob = new Blob([reader.result], {
    //           type: file.type
    //         });
    //         this.newImageData = imgBlob;
    //         this.imageFrom = 'cameraPhoto';
    //       };
    //       reader.readAsArrayBuffer(file);

    //       const dataReader = new FileReader();
    //       dataReader.onloadend = () => {
    //         // convert image file to base64 string
    //         this.imgUrl = dataReader.result;
    //         this.customimage.nativeElement.src = this.imgUrl;
    //       };
    //       dataReader.readAsDataURL(file);
    //     });
    //   });
    //   this.updateButton = true;
    // }, (err) => {
    //   console.log('Error while selecting image.', err);
    // });
  }

  imageExists(imageUrl: string) {
    if (imageUrl.slice(-1) == ".") {
      return null;
    } else {
      return imageUrl;
    }
  }

  getImageExtention(iconname) {
    return iconname.startsWith("paj_iconset") ? ".svg" : ".png";
  }

  customImage = null;
  async mediaPicker() {
    let userData = await this.storage.get(Constants.USER_DATA);
      if(userData.id == 3396){
        this.appService.showToast('', this.translate.instant('company.users.cannotAddUserText'), 3000, 'danger');
        return;
      }
    const modal = await this.modelCtrl.create({
      component: MediaManagmentComponent,
      componentProps: { showBackButton: this.showBackButton },
      cssClass: this.customPopupClass ?? ''
    });
    await modal.present();

    const response = await modal.onWillDismiss();
    if (response.data) {
      const image = response.data;
      this.customImage = this.mediaApi.createImageObject(
        image.collection_name,
        image.file_name
      );
      this.iconcustomimage = this.customImage.file_name;
      this.iconusecustom = 1;
      this.updateButton = true;
      this.imageFrom = "cameraPhoto";
      //this.updateCustomImage(this.customImage.file_name, 1);
    }
  }

  threedObj = null;
  async threedModel() {
    const modal = await this.modelCtrl.create({
      component: ThreedModelPickerComponent,
      componentProps: { showBackButton: this.showBackButton },
      cssClass: this.customPopupClass ?? ''
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data) {
      this.threedObj = data.model;
      this.iconusecustom = 2;
      this.updateButton = true;
      this.imageFrom = "3dModel";
    }
  }

  async save3dModel() {
    const upData = {
      threedModel_name: this.threedObj.name,
      iconusecustom: 2,
    };
    await this.authService.updateDevice(this.device.properties.id, upData).then ( r => {
    }).catch( error => console.log("ERROR: ", error));
  }
}
