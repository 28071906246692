import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { animations } from 'src/app/animations/animations';
import { AppService } from 'src/app/app.service';
import { Constants } from 'src/app/constants.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StorageService as Storage } from "src/app/services/storage.service";
import { AppModesArray, AppModesEnum } from './app-modes-helper';
import { first } from 'rxjs';

@Component({
  selector: 'app-app-modes',
  templateUrl: './app-modes-popup.page.html',
  styleUrls: ['./app-modes-popup.page.scss'],
  animations: animations
})
export class AppModesPopupPage implements OnInit {
  @Input() app_modes: any;
  @Input() step = 1;

  appModes = null;
  selectedModes = [];
  logbookAppMode = 0;
  clickedMode = null;
  switchedMode = true;
  userAppModes = {};
  saving = false;
  showLogbookToggle = false;
  @Input() useInSettings = false;
  constructor(private modalController: ModalController, private storage: Storage, private appService: AppService,
              private translate: TranslateService, private authService: AuthenticationService) { }

  ngOnInit() {
    this.appModes = AppModesArray(this.translate);
    console.log("APP MODES ::: ", this.appModes)
    let user = this.appService.user.super_user;
    if (user == 0 && !this.appService.isGhostMode) {
      const objWithIdIndex = this.appModes.findIndex((obj) => obj.type === 'BUSINESS');
      this.appModes.splice(objWithIdIndex, 1);
    }
    this.setLogbookToggle();
    this.clickedMode = this.appModes[0];
    this.userAppModes =  JSON.parse(this.appService.userSettings.app_modes);
    this.logbookAppMode = this.appService.userSettings.logbook_mode;
    //_ Check the user selected|stored modes
    this.appModes.forEach(m => m.checked = this.userAppModes[m.dbField] == 1 ? true : false)
  }



  setLogbookToggle(){
    console.log(this.appService.devices);
    if (!this.appService.devices) {
      this.appService.devices$.pipe(first()).subscribe((devices: any) => {
        devices.forEach(singleDevice => {
          if(singleDevice['logbook_access'] || singleDevice['device_models'][0]['logbook_access']){
            this.showLogbookToggle = true;
            return;
          }
        });
      });
    } else {
      this.appService.devices.forEach(singleDevice => {
        if(singleDevice['logbook_access'] || singleDevice['device_models'][0]['logbook_access']){
          this.showLogbookToggle = true;
          return;
        }
      });
    }
  }

  setLogbookAppMode(event){
    if(event){
      this.logbookAppMode = 1;
    }
      else{
        this.logbookAppMode = 0;
      }
      let modesToStore = {
        logbook_mode: this.logbookAppMode
      }
      this.appService.userSettings.logbook_mode = this.logbookAppMode;
    this.authService.updateUserSettings(this.appService.user.id, modesToStore).then( async (r: any) => {
      if (r.success){
        this.appService.showToast('', this.translate.instant("deviceManagement.mapSettingMessage"), 2000, 'success');
        //_ Save status in storage and update using the obs
      }
    }).catch(error => {
      console.log("ERROR: ", error);
      this.appService.showToast('', this.translate.instant("deviceManagement.errorUpdateSettings"), 3000, 'danger');
    });
  }

  dismiss() {
    if (!this.useInSettings)
      this.modalController.dismiss();
  }

  setAppMode(){
    //_ Set by default 0
    this.setAllModes(0);

    this.appModes.forEach( m => {
      // this.storage.set(m.dbField, m.checked ? 1 : 0)
      this.userAppModes[m.dbField] = m.checked ? 1 : 0;
    });

    this.saveModes();
  }

  checkMode(mode){
    mode.checked = mode.checked ? false : true;
    //_ Used for animate the content of the mode
    if (this.clickedMode.type != mode.type)
      this.switchedMode = false;

    this.clickedMode = mode;

    //_ Used for animate the content of the mode
    setTimeout(() => this.switchedMode = true, 50 );

    if (this.useInSettings)
      this.setAppMode();
  }

  setAllModes(value){
    Object.keys(this.userAppModes).forEach( k => {
      // this.storage.set(k, value);
      this.userAppModes[k] = value;
    });
  }

  useAllModes(){
    this.setAllModes(1);
    this.appModes.forEach(m => m.checked = 1);
    this.saveModes();
  }

  async saveModes(){
    this.checkNoModeSelected();
    let modesToStore = {
      app_modes: JSON.stringify(this.userAppModes)
    }

    this.saving = true;
    this.authService.updateUserSettings(this.appService.user.id, modesToStore).then( async (r: any) => {
      if (r.success){
        this.appService.showToast('', this.translate.instant("deviceManagement.mapSettingMessage"), 2000, 'success');
        //_ Save status in storage and update using the obs
        this.appService.userSettings.app_modes = modesToStore.app_modes;
        this.storage.set(Constants.APP_MODES, this.userAppModes);
        this.appService.appModeChanged.next(true);

        if (this.userAppModes[AppModesEnum.BUSINESS] == 1 && this.appService.user.company_id == null){
          //_ Create company for the user
          await this.authService.createCompanyForCustommer(this.appService.user.id).then( (r: any) => {
            if (r.success){
              this.appService.user.company_id = r.company_id;
              window.location.reload();
            }
          });
        }
        this.saving = false;
        this.dismiss();
      }
    }).catch(error => {
      console.log("ERROR: ", error);
      this.appService.showToast('', this.translate.instant("deviceManagement.errorUpdateSettings"), 3000, 'danger');
    });

  }

  //_ Check if is selected one mode otherwise change NO_MODE = 0
  //_ use variable userAppModes to update it values
  checkNoModeSelected(){
    let noMode = true;
    Object.keys(this.userAppModes).forEach( k => {
      if (this.userAppModes[k] == 1) noMode = false;
    });

    this.userAppModes[AppModesEnum.NO_MODE] = noMode ? 1 : 0;
  }
}
