<ng-container [ngTemplateOutlet]="step == 2 ? selectModes : firstStep">

</ng-container>

<!-- FIRST STEP -->
<ng-template #firstStep >
  <ion-content class="ion-padding" @fadeIn>
    <h2 class="ion-text-wrap ion-text-center">{{ 'appModes.startTitle' | translate }}</h2>
    <ion-img class="img-step1" src="assets/images/app-modes/all-modes.jpg"></ion-img>
    <h3 style="font-size: 16px" class="ion-text-wrap ion-text-center">{{ 'appModes.descriptionStart' | translate }}</h3>
    <p>{{ 'appModes.adviceStart' | translate }}</p>
  </ion-content>

  <ion-footer class="ion-padding">
    <div class="ion-text-center">
      <ion-button color="light" shape="round" (click)="dismiss()" style="float: left">
        {{ 'appModes.setupLater' | translate }}
      </ion-button>
      <ion-button color="primary" shape="round" (click)="step = 2" style="float: right">
        <ion-icon name="arrow-forward-circle-outline" slot="end"></ion-icon>
        {{ 'appModes.continue' | translate }}
      </ion-button>
    </div>
  </ion-footer>
</ng-template>

<!-- SECOND STEP = SELECT APP MODES -->
<ng-template #selectModes >
  <div @swipeLeft class="content" [ngStyle]="useInSettings ? { 'height': 'auto'} : {}">
    <ion-item lines ="none">
      <ion-label class="ion-text-center heading">{{ 'appSettings.appModes' | translate }}
        <ion-badge>BETA</ion-badge>
      </ion-label>
      
    </ion-item>
    <!-- <div class="ion-text-center"><span class="heading">{{ 'appSettings.appModes' | translate }}</span>
    <span><ion-badge>BETA</ion-badge></span></div> -->
    <!-- <h2 class="ion-text-center">{{ 'appSettings.appModes' | translate }}</h2> -->

    <ion-header [class.ion-padding]="!useInSettings" >
      <!-- <h2 class="ion-text-center">{{ clickedMode?.name }}</h2> -->

      <ng-container *ngFor="let mode of appModes">
        <ion-row  @zoomIn class="mode-row">
          <!-- IMAGE OF THE MODE -->  
          <ion-col size-md="6" size-xs="12" style="align-self: center;" >
            
            <h3 *ngIf="mode.type == 'CAR'" class="pet-mode-title">{{ 'appModes.car' | translate }}</h3>
            <h3 *ngIf="mode.type == 'PET'" class="pet-mode-title">{{ 'appModes.pet' | translate }}</h3>
            <h3 *ngIf="mode.type == 'PEOPLE'" class="pet-mode-title">{{ 'appModes.people' | translate }}</h3>
            <h3 *ngIf="mode.type == 'BUSINESS'" class="pet-mode-title">{{ 'appModes.business' | translate }}</h3>

            <div class="mode-header ion-activatable" (click)="checkMode(mode)">
              <ion-ripple-effect></ion-ripple-effect>
              <div class="checkmark-wrapper" >
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 98.5 98.5" enable-background="new 0 0 98.5 98.5" xml:space="preserve" *ngIf="mode?.checked">
                  <path class="checkmark" fill="none" stroke-width="11" stroke-miterlimit="10" d="M81.7,17.8C73.5,9.3,62,4,49.2,4
                  C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"/>
                </svg>
                <svg x="0px" y="0px" viewBox="0 0 98.5 98.5" *ngIf="!mode?.checked" @fadeIn>
                  <circle class="shape checkmark" fill="none" stroke-width="11" cx="50" cy="50" r="50" />
                </svg>
              </div>
        
              <svg height="100" width="100" xmlns="http://www.w3.org/2000/svg">
                <circle class="shape" cx="50" cy="50" r="50" [class.active]="mode?.checked"/>
              </svg>
              <ion-img [src]="mode?.img" [class.gray-scale]="!mode?.checked"></ion-img>
            </div>
          </ion-col>
          
          <!-- FEATURES OF THE MOD -->
          <ion-col size-md="6" size-xs="12">
            <ion-item lines="none" *ngFor="let feature of mode?.features" class="mode-features" [class.gray-scale]="!mode?.checked">
              <ion-icon [src]="feature.icon" color="primary" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap" >{{ feature.name }}
                <p *ngIf="feature.icon == 'assets/icons/logbook.svg' && showLogbookToggle">{{ 'appModes.logbookAppModeText' | translate }}</p></ion-label>
              
              <ion-toggle *ngIf="feature.icon == 'assets/icons/logbook.svg' && showLogbookToggle"
                slot="end"
                mode="ios"
                class="ion-custom"
                color="success"
                
                [(ngModel)]="logbookAppMode"
                (ngModelChange)="setLogbookAppMode($event)"
                [checked]="logbookAppMode"
                aria-label=""
              ></ion-toggle>
            </ion-item>
          </ion-col>
        </ion-row>
        
        <!-- <hr class="divider"> -->
        <!-- <ion-item lines="none" class="mode-details">
          <ion-label class="ion-text-center ion-text-wrap">{{ clickedMode?.details }}</ion-label>
        </ion-item> -->
      </ng-container>
      
    </ion-header>
    <!-- <ion-list lines="none">
      <ion-item button detail="false" *ngFor="let mode of appModes" (click)="checkMode(mode)">
        <ion-icon slot="start" [src]="mode.icon" color="primary"></ion-icon> 
        <ion-label class="ion-text-wrap">{{ mode.name }}</ion-label>
        <ion-checkbox slot="end" [checked]="mode.checked"></ion-checkbox>
      </ion-item>
    </ion-list> -->

    <br>
    <ion-item color="transparent" *ngIf="useInSettings" lines="none">
      <ion-icon name="alert-circle-outline" slot="start" color="warning"></ion-icon>
      <ion-label class="ion-text-wrap">
        {{ 'appModes.adviceSettings' | translate }}
      </ion-label>
    </ion-item>

  </div>

  <div class="ion-padding" class="footer" [ngStyle]="useInSettings ? { 'position': 'initial' } : {}">
    <div class="ion-text-center">
      <ion-button color="primary" fill="outline" shape="round" (click)="useAllModes()" style="float: left" [disabled]="saving">
        <ion-spinner color="light" *ngIf="saving" @zoomIn slot="start"></ion-spinner>
        {{ 'appModes.useAllFeatures' | translate }}
      </ion-button>
      <ion-button color="primary" shape="round" (click)="setAppMode()" style="float: right" [disabled]="saving" *ngIf="!useInSettings">
        <ion-icon name="save-outline" slot="start" *ngIf="!saving" @zoomIn></ion-icon>
        <ion-spinner color="light" *ngIf="saving" @zoomIn slot="start"></ion-spinner>
        {{ 'appModes.save' | translate }}
      </ion-button>
    </div>
  </div>
</ng-template>