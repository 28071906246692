import { Pipe, PipeTransform } from '@angular/core';
import { HumanizeTimeService } from '../services/humanize-time.service';
@Pipe({
  name: 'humanizeTime'
})

export class HumanizeTimePipe implements PipeTransform { //duration in seconds
  constructor(private humanizeTime: HumanizeTimeService) { }

  transform(duration: any, type = 'duration', format? , short = false,
            alwaysUseGivenFormat= false, replaceDot = false): any {
    if (duration == null || duration == undefined) return '';
    switch (type) {
      case 'duration':
        return this.humanizeTime.formatTime(duration, short);
      case 'duration-without-week':
        return this.humanizeTime.formatTimeWithoutWeek(duration, short);
      case 'date':
        return this.humanizeTime.formatDateTime(duration, format, alwaysUseGivenFormat, replaceDot);
    }

    return '0';
  }
}
