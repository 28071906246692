import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PajPopupTemplateComponent } from './paj-popup-template.component';

@NgModule({
    imports: [
        CommonModule, 
        IonicModule,
        TranslateModule,
    ],
    declarations: [PajPopupTemplateComponent],
    exports: [PajPopupTemplateComponent]
  })

  
  export class PajPopupTemplateModule {}