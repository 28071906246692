import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PredefinedAudiosComponent } from './predefined-audios.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [PredefinedAudiosComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [PredefinedAudiosComponent]
})
export class PredefinedAudiosModule { }
