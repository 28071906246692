import * as moment from "moment";
import { getDistance } from "./mapUtil";

// All data of the device for all map, alarms, route and info component.
export class DeviceData {
    // Used for the alarm component
    alarmSettings = [];

    // Used datapoints from server
    dataPoints = [];

    //used to store the previous datapoints while using activity & timeline
    previousDataPoints = []
    // Used for show the route in the map
    snapedPoints = [];
    unSnapedPoints = [];
    tripsData = null; //_ Save the whole response of valhalla

    // Used as a temporary datapoints from server
    newDataPoints = [];
    // Used as a temporary route points for the map
    newSnapedPoints = [];

    // Used to show the marker in the map
    // And for move the marker and the pulse if the devices is online
    lastLocation = [0, 0];
    previousLastLocation = [0, 0];
    lastPoint = null;

    // Used for the device model data
    deviceModel: any = {};

    //properties = {};
    totalRouteLength = 0;

    // Selected date range
    selected_date_range = null;

    // Used to show the pause markers in the map
    pauseMarkers = [];
    newPauseMarkers = [];

    // Used to stored position and show the direction markers in the map
    directionMarkers = []
    newDirectionMarkers = []

    // Use for show the realtime movements
    // for route = points, directions, pauses and snaped points
    realtimePoints = [];
    newRealtimePoints = [];
    realtimeDirectionMarkers = [];
    realtimeSnapedPoints = [];
    realtimePauseMarkers = [];

    wifiMarkers = [];

    notifications = [];

    geofences = [];
    id = 0;
    online = false;
    color = null;

    //autofocus

    autoFocus = false;

    //_ Default permissinos until permissons are loaded
    permissions = { see: true, modify: true, share: true };

    pointsForPauses = [];
    // Properties store the device data getted from server
    properties;

    optimizedDistance = 0;

    //_ Handle the last point of the animation and status of it
    animationLocation = null;
    isRunningAnimation = false;

    constructor(_properties) {
        this.properties = _properties;
        this.id = _properties.id;
        this.lastLocation = [_properties.last_lng, _properties.last_lat];
        this.selected_date_range = _properties.selected_date_range;
        this.color = _properties.spurfarbe;

        this.configureDate();
        if (_properties.device_models)
            this.deviceModel = _properties.device_models[0];
    }


    // Initialize for first time
    // Setted all datapoints, direction and lastLocation according to passed dataPoints
    init(dataPoints) {

        this.dataPoints = dataPoints;
        this.newDataPoints = dataPoints;
        this.directionMarkers = dataPoints;
        this.newDirectionMarkers = dataPoints; //this.reduceDirectionPoints(dataPoints);

        if (dataPoints.length > 0) {

            //this.lastPoint = dataPoints[dataPoints.length-1];
            //this.lastPoint.iddevice = this.id;
            //this.lastLocation = [ this.lastPoint.lng, this.lastPoint.lat ];
        }
        this.previousDataPoints = JSON.parse(JSON.stringify(dataPoints));

    }

    //_ selected_date_range is stored in route component after pick a new daterange
    //_ And also is aplicated this new daterange when request all datapoints when map starts
    configureDate() {
        //_ By default it uses today date range
        var startD = moment().startOf('day');
        var endD = moment().endOf('day');

        if (this.selected_date_range == 'YESTERDAY') { startD.subtract(1, 'days'); endD.subtract(1, 'days'); }
        if (this.selected_date_range == '7DAYS') startD.subtract(6, 'days');
        if (this.selected_date_range == '30DAYS' || this.selected_date_range == '1MONTH') startD.subtract(29, 'days');
        if (this.selected_date_range == '60DAYS') startD.subtract(59, 'days');
        if (this.selected_date_range == '100DAYS') startD.subtract(99, 'days');
        if (this.selected_date_range == '365DAYS') startD.subtract(364, 'days');

        //_ In backend is taken 1MONTH as 30DAYS range, 1MONTH is used in v1 and not in v2
        // if (this.selected_date_range == '1MONTH') {
        //     startD = moment().startOf('month');
        //     endD = moment().endOf('month');
        // }

        if (this.selected_date_range == 'RANGE') {
            startD = moment.unix(this.properties.spurdatum);
            endD = moment.unix(this.properties.spurdatumbis);
        }

        this.properties.spurdatum = startD.unix();
        this.properties.spurdatumbis = endD.unix();
    }

    removeNearestPoints(dataPoints) {
        let newPoints = [];

        if (dataPoints.length > 0 && dataPoints) {
            for (let i = 0; i < dataPoints.length - 1; i++) {
                let distance = getDistance(dataPoints[i].lat, dataPoints[i].lng, dataPoints[i + 1].lat, dataPoints[i + 1].lng);
                if (distance > 10) { // if distance between 2 coordinates is less than 5 meters
                    newPoints.push(dataPoints[i]);
                }
            }
        }

        return newPoints;
    }

    reduceDirectionPoints(points) {
        let lastP = null;
        let newArray = [];
        points.forEach(p => {
            if (lastP) {
                if (lastP.direction != p.direction)
                    newArray.push(p);
            }
            lastP = p;
        });
        return newArray;
    }

    addNewPoint(point) {
        this.previousLastLocation = [this.lastLocation[0], this.lastLocation[1]];
        this.realtimePoints.push(point);
        this.newRealtimePoints.push(point);
        this.realtimeDirectionMarkers.push(point);
        this.newDirectionMarkers = [point];
        this.lastLocation = [point.lng, point.lat];
        this.lastPoint = point;
        this.unSnapedPoints.push(point);
        this.pointsForPauses.push(point);
    }

    clearNewData() {
        this.newDataPoints = [];
        this.newDirectionMarkers = [];
        this.newSnapedPoints = [];
        this.newPauseMarkers = [];
        this.newRealtimePoints = [];
    }

    parseAndStoreWifis(wifiData) {
      const wifis = [];
      for (let i = 1; i <= 3; i++) {
        const wifi = {
          wifiIndex: i,
          id: wifiData.id,
          deviceId: wifiData.iddevice,
          current_wh: wifiData.current_wh,
          prefix: `wh${i}`,
          mac1: wifiData[`wh${i}_mac1`] || null,
          mac2: wifiData[`wh${i}_mac2`] || null,
          mac3: wifiData[`wh${i}_mac3`] || null,
          lat: wifiData[`wh${i}_lat`] || null,
          lng: wifiData[`wh${i}_lng`] || null,
        };

        //_ Check if wifi have lat and lng to store it
        if (wifi.lat && wifi.lng) {
          wifis.push(wifi);
        }
      }

      this.wifiMarkers = wifis;
      return wifis;
    }



}
