export enum Constants {
    SUPER_USER = 'super_user',
    USER_ID = 'userID',
    TOKEN_KEY = 'auth-token',
    REFRESH_TOKEN_KEY = 'refresh-token',
    SELECTED_DEVICE = 'selectedDevice',
    DEVICE_NAME = 'deviceName',
    UPDATE_MAP = 'updateMap',
    UPDATE_COLOR = 'updateColor',
    ROUTE_ICON = 'routeIcon',
    PAUSE_ICON = 'pauseIcon',
    LOG_ROUTE = 'route',
    LOG_ROUTE_CHANGE = 'route_change',
    ROUTE_TYPE = 'routeType',
    MAP = 'map-style',
    UPDATE_STYLE = 'updateStyle',
    UPDATE_LOGIN = 'updateLogin',
    LANGUAGE = 'langauge',
    CURRENCY = 'currency',
    NCOUNT = 'notificationsCount',
    USER_DATA = 'userData',
    DATE_RANGE = 'dateRange',
    SELECTED_DEVICE_DATA = 'selected-device-data',
    REFRESH_DATA = 'refresh_data',
    DEVICE_DATA = 'deviceData',
    IMEI_STATUS = 'imei-status',
    APP_MODES = 'app_modes',
    SHOW_APP_MODE_OPTION = 'show-app-mode-option',
    LAST_USER_LOGGEDIN = 'LAST_USER_LOGGEDIN',
    PET_MODE='pet_mode',
    MAP_PIXEL_RATIO = Math.min(window.devicePixelRatio, 2),

    // *************** Map Tile URLs **********
    MAP_TILES_PAJ_STREET = 'https://maps.paj-gps.de/styles/street/style.json',
    MAP_TILES_PAJ_HILL = 'https://maps.paj-gps.de/styles/hills/style.json',
    MAP_TILES_PAJ_STREET_3D = 'https://maps.paj-gps.de/styles/street3d/style.json',
    MAP_TILES_OSM_STREET = 'https://maps.paj-gps.de/data/osm.json',
    MAP_TILES_PAJ_STREET_RASTER = 'https://maps.paj-gps.de/styles/street/{z}/{x}/{y}.png',
    MAP_TILES_MAPTILER_SATELLITE = 'https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=lZmFbcInLqQTB8vTyGZl',

    //_ MAPBOX MAP STYLES
    MAP_TILES_MAPBOX_STANDARD = 'mapbox://styles/mapbox/standard',
    MAP_TILES_MAPBOX_STREETS_V11 = 'mapbox://styles/mapbox/streets-v11',
    MAP_TILES_MAPBOX_OUTDOOR_V11 = 'mapbox://styles/mapbox/outdoors-v11',
    MAP_TILES_MAPBOX_SATELLITE_STREETS_V12 = 'mapbox://styles/mapbox/satellite-streets-v12',
    MAP_TILES_MAPBOX_LIGHT_V10 = 'mapbox://styles/mapbox/light-v11',
    MAP_TILES_MAPBOX_DARK_V10 = 'mapbox://styles/mapbox/dark-v11',
    MAP_TILES_MAPBOX_SATELLITE_V9 = 'mapbox://styles/mapbox/satellite-v9',
    MAP_TILES_MAPBOX_NAVIGATION_DAY_V1 = 'mapbox://styles/mapbox/navigation-day-v1',
    MAP_TILES_MAPBOX_NAVIGATION_NIGHT_V1 = 'mapbox://styles/mapbox/navigation-night-v1',

    // *************** GOOGLE **********
    //GOOGLE_ANALYTICS_TRACKING_ID = '281664835',
    GOOGLE_ANALYTICS_TRACKING_ID = 'G-WSE3ZMCEN4',

    // *************** DASHBOARD AND VIDEO RENDER **********
    DASHBOARD_DEVICES = 'dashboardDevices',
    DASHBOARD_ACT_DATA = 'dashboardActivitiesData',
    DASHBOARD_SPEED = 'dashboardSpeed',
    DASHBOARD_SPEEDRT = 'dashboardSpeedRT',
    DASHBOARD_CHARTSDATA = 'dashboardChartsData',

    DEVICES_LOCATION = 'devicesLocation',

    SPURMODUS = 'spurmodus',
    NOTIFICATIONS_LIST = 'notifications-list',
    ANDROID = './map.page.scss',
    IOS_APP_VERSION = 0,
    ANDROID_APP_VERSION = 0,

    //MAP last points
    LASTPOINTS = 'lastpoints',

    //_ Used for datapoint images - media collection name
    DATAPOINTS_COLLECTION_NAME = 'datapoint_files',
    LOGBOOK_COSTS_COLLECTION_NAME = 'logbook_costs_files',
    USER_SETTINGS = 'userSettings',
    ADMIN_SETTINGS = 'adminSettings',
    HIDE_TOOLBAR_IN_DEVICE_LIST_MIN_COUNT = 3,

    // *************** USE_BIOMETRIC **********
    USE_BIOMETRIC = 'USE_BIOMETRIC',
    USE_COMPRESSION_FOR_GPX_KML = 'Use compression for gpx and kml file to export routes',
}

export const DEMO_ACCOUNTS = ['test002@paj-gps.de'];
export const VALIDATION_PATTERNS= {
    email :"^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,7}$",
    numbers : /[0-9.,]/
  } ;
