<ion-header>
  <ion-toolbar color="dark" class="title-toolbar">
    <ion-buttons slot="start">
      <ion-back-button
        (click)="close()"
        style="display: block !important"
        back-close
        back-locale
      ></ion-back-button>
    </ion-buttons>
    <ion-title>
      <span *ngIf="modalTitle">{{
        modalTitle | translate
      }}</span>
      <span *ngIf="mapLoaded && !modalTitle">{{
        (countryBounds ? countryTitle : title) | translate
      }}</span>
    </ion-title>
  </ion-toolbar>

  <ion-toolbar>
    <ion-item
      *ngIf="canSearch"
      lines="none"
      mode="md"
      class="ion-no-padding"
      style="--inner-padding-end: 0"
    >
      <form (ngSubmit)="hideKeyboard()" style="width: 100% !important">
        <ion-searchbar
          (ionInput)="searchAddress()"
          placeholder="{{ 'deviceManagement.searchText' | translate }}"
          [(ngModel)]="addressInput"
          debounce="250"
          (ionFocus)="showList = true;searchAddress()"
          enterkeyhint="{{ 'deviceManagement.search' | translate }}"
          name="addressInput"
        >
        </ion-searchbar>
      </form>
    </ion-item>

    <ion-item lines="none">
      <!-- <ion-icon
        src="assets/images/locate-device/pin-location.svg"
        color="primary"
        slot="start"
      ></ion-icon> -->
      <ion-icon
          *ngIf="type == 'home'"
          color="primary"
          src = 'assets/images/home.svg'
          slot="start">
      </ion-icon>
      <ion-icon
          *ngIf="type == 'work'"
          color="primary"
          src = 'assets/images/logbook/route-towork.svg'
          slot="start">
      </ion-icon>
      <ion-icon
          *ngIf="!(type == 'home' || type == 'work')"
          color="primary"
          src = 'assets/images/location.svg'
          slot="start">
      </ion-icon>
      <ion-label class="ion-text-wrap" *ngIf="!loadingAddress">
        {{ address }}</ion-label
      >
      <ion-spinner color="primary" *ngIf="loadingAddress"></ion-spinner>
    </ion-item>
  </ion-toolbar>

  <!-- <ion-item class="infos" *ngIf="!hideInfo">
    <ion-list >
      <ion-list-header mode="ios">
        <ion-label style="margin: 0 auto;">{{ 'deviceManagement.pickAddress' | translate }}</ion-label>
      </ion-list-header>

      <ion-item lines="none" *ngIf="canSearch">
        <ion-icon name="search" color="primary" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">{{ 'deviceManagement.writeAddress' | translate }}</ion-label>
      </ion-item>

      <ion-item lines="none">
        <ion-icon name="pin" color="primary" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">{{ 'deviceManagement.changeAddress' | translate }}</ion-label>
      </ion-item>
    </ion-list>

    <ion-button color="light" shape="round" fill="solid" style="position: absolute; top: 10px; right: 10px;"
      (click)="updateMap()" size="small">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-item> -->
</ion-header>

<ion-content>
  <div *ngIf="showWorkAndHomeOptions" class="address-types">
    <div
      class="edit-address-div"
      *ngIf="defaultType == 'home' || !defaultType">
      <p *ngIf="!defaultType" class="edit-address-text">
        {{ "routeForm.saveAsHome" | translate }}
      </p>
      <div
        class="address-type home"
        [ngClass]="type == 'home' ? 'selected' : ''"
        (click)="saveAddressType($event, 'home')"
      >
        <ion-icon
          src="assets/images/home.svg"
          >
        </ion-icon>
      </div>
    </div>

    <div
      class="edit-address-div"
      *ngIf="defaultType == 'work' || !defaultType">
      <p *ngIf="!defaultType" class="edit-address-text">
        {{ "routeForm.saveAsWork" | translate }}
      </p>
      <div

        class="address-type work"
        [ngClass]="type == 'work' ? 'selected' : ''"
        (click)="saveAddressType($event, 'work')"
      >
        <ion-icon
          src="assets/images/logbook/route-towork.svg"
          >
        </ion-icon>
      </div>
    </div>
  </div>
  <app-map-min
    #map
    id="mini-map"
    [height]="'100%'"
    [pitch]="0"
    [mapStyle]="mapStyle"
    [useDefinedStyle]="true"
    [customerSettings]="{ map_projection: 'mercator' }"
    [events]="{
      mouseenter: false,
      mouseleave: false,
      click: true,
      dblclick: false
    }"
    (onClickMap)="showList = false"
  >
  </app-map-min>
</ion-content>

<ion-card
  *ngIf="addressList.length > 0 && showList"
  class="ion-no-margin addressList"
>
  <ion-list lines="full" class="ion-no-padding">
    <ion-item
      *ngFor="let item of addressList"
      button
      (click)="selectAddress(item)"
      mode="md"
    >
      <ion-icon
          *ngIf="item.props.type == 'home'"
          src = 'assets/images/home.svg'
          slot="start">
      </ion-icon>
      <ion-icon
          *ngIf="item.props.type == 'work'"
          src = 'assets/images/logbook/route-towork.svg'
          slot="start">
      </ion-icon>
      <ion-icon
          *ngIf="!(item.props.type == 'home' || item.props.type == 'work')"
          src = 'assets/images/location.svg'
          slot="start">
      </ion-icon>
      <ion-label
        class="ion-text-wrap"
        [innerHTML]="item.stringAddress | highLightText: addressInput"
      ></ion-label>
      <!--ion-label>{{ item.info.street }}, {{ item.info.locality }}, {{ item.info.city }}, {{ item.info.country }} </ion-label-->
    </ion-item>
  </ion-list>
</ion-card>

<ion-footer>
  <ion-segment style="background-color: transparent">
    <ion-button
      shape="round"
      color="light"
      fill="solid"
      (click)="close()"
      style="--box-shadow: none"
    >
      <ion-label>{{ "deviceManagement.cancel" | translate }}</ion-label>
    </ion-button>
    <ion-button
      style="min-width: 80px"
      shape="round"
      color="primary"
      fill="solid"
      (click)="select()"
      [disabled]="(newCoords?.lat == 0 && newCoords?.lng == 0) || !address"
    >
      <ion-label>{{ "deviceManagement.ok" | translate }}</ion-label>
    </ion-button>
  </ion-segment>
</ion-footer>
