import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { BackcloseDirectiveModule } from 'src/app/directives/backclose-directive.module';
import { PajMapMinModule } from 'src/app/members/generate-video/map-min/paj-map-min.module';
import { HighLightPipeModule } from 'src/app/pipes/HighLightPipe.module';
import { MapAddressPickerComponent } from './map-address-picker.component';
import { DirectivesModule } from 'src/app/directives/directives.module';


@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      TranslateModule,
      PajMapMinModule,
      HighLightPipeModule,
      BackcloseDirectiveModule,
      DirectivesModule
    ],

    declarations: [MapAddressPickerComponent],
    exports: [MapAddressPickerComponent, PajMapMinModule, HighLightPipeModule],
    
  })

  
  export class PajMapAdressPickerModule {}