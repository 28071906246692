<!-- <device-setup-step-template [template]="steps[currentStep]?.templateRef" [device]="device" [showBackButton]="steps[currentStep]?.showBack" [showNextButton]="steps[currentStep]?.showNext"
      [progress]="steps[currentStep]?.progress" (onNext)="nextStep()" (onBack)="prevStep()" *ngIf="steps">
</device-setup-step-template>

<ng-template #NAME_CONTENT>
  <setup-device-name [device]="device" @fadeIn></setup-device-name>
</ng-template>

<ng-template #COLOR_CONTENT>
  <setup-device-color [device]="device" @fadeIn></setup-device-color>
</ng-template>

<ng-template #IMAGE_CONTENT>
  <setup-device-image [device]="device" @fadeIn></setup-device-image>
</ng-template>

<ng-template #ALARMS_CONTENT>
  <setup-device-alarms [device]="device" @fadeIn></setup-device-alarms>
</ng-template>

<ng-template #POSITION_CONTENT>
  <setup-device-position [device]="device" @fadeIn></setup-device-position>
</ng-template> -->

<ion-nav #nav>
  
</ion-nav>