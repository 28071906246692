//_ Change colors of every layer of the gjson fetched by url
//_ Using specific colors only for pajHills map
export const applyDarkStyle = (json, mapStyle) => {
  const Color = require('color-converter').default;
  const darkenPorcentage = mapStyle == 'pajHills' ? 40 : 80;
  const saturation = -80;

  json.layers.forEach(layer => {
    if (layer['paint']) {
      Object.keys(layer['paint']).forEach((k: any) => {
        if (k == 'text-color')
          layer.paint[k] = '#ddd';

        if (k == 'text-halo-color')
          layer.paint[k] = '#222';

        if (k == 'background-color') {
          if (layer.paint[k]['stops']) {
            layer.paint[k].stops.forEach(st => {
              let color = Color.fromCSS(st[1]).darken(darkenPorcentage).saturate(saturation);
              st[1] = '#151515'; //color.css();
            })
          }
        }

        if (k == 'background-color' || k == 'fill-color' || k == 'line-color' || k == 'fill-outline-color') {
          if (!layer.paint[k]['stops']) {
            let color = Color.fromCSS(layer.paint[k]).darken(darkenPorcentage);
            layer.paint[k] = specificLayerColor(mapStyle)[layer.id] ? specificLayerColor(mapStyle)[layer.id] : color.css();
          } else if (k != 'background-color') {
            layer.paint[k].stops.forEach(st => {
              let color = Color.fromCSS(st[1]).darken(darkenPorcentage);
              st[1] = specificLayerColor(mapStyle)[layer.id] ? specificLayerColor(mapStyle)[layer.id] : color.css();
            });
          }
        }

        //_ all tunnels
        if (layer.id.startsWith('tunnel') && (k == 'background-color' || k == 'fill-color' || k == 'line-color' || k == 'fill-outline-color'))
          layer.paint[k] = '#181b22'; //'#2a2a2a';

        //_ all bridges tunnel
        if (layer.id.startsWith('bridge') && (k == 'background-color' || k == 'fill-color' || k == 'line-color' || k == 'fill-outline-color'))
          layer.paint[k] = '#3a3d44'; //'#2a2a2a';

        // //_ Delimiters of contries and cities
        // if (layer.id.startsWith('boundary') && ( k == 'background-color' || k == 'fill-color' || k == 'line-color' || k == 'fill-outline-color' ) )
        //   layer.paint[k] = '#00ffff'; //'#444';

        //_ Some labels colors and background
        if (layer.id.startsWith('highway-shield') && k == 'text-color') {
          layer.paint[k] = '#fff';
          layer.paint['icon-opacity'] = 0.5;
        }
      })
    }
  });

  return json;
}

//_ Specific colors only for pajHills map style name
function specificLayerColor(mapStyle) {
  const colors = [];

  if (mapStyle == 'pajHills') {
    colors['building'] = "#171717";
    colors['background'] = '#212121';
  }

  //_ Aeroway
  colors['aeroway_fill'] = "#131f2f";

  //_ Land use colors
  colors['landuse_cemetery'] = "#1f2730";
  colors['landuse_hospital'] = "#303543";
  colors['landuse_school'] = "#444853";
  colors['landuse_stadium'] = "#305063";

  //_ Land color
  colors['landcover_wood'] = "#212121";
  colors['landcover_grass'] = "#1a3325";
  colors['landcover_grassland'] = "#1a3325";
  colors['landcover_scrubland'] = "#1a3325";
  colors['landcover_cropland'] = "#1a3325";
  colors['landcover_treeland'] = "#1a3325";
  colors['landcover_forestland'] = "#1a3325";
  colors['landcover_snowland'] = "#314557";

  //_ Road labels
  // colors['road_label'] = '#ff0000';
  colors["highway-shield"] = "#00ff00";
  // colors["highway-shield-us-interstate"] = "#0000ff";
  // colors["highway-shield-us-other"] = "#00ffff";

  //_ Road color
  colors['road_pier'] = '#4b4e55';
  colors['road_area_pier'] = "#4b4e55"
  colors['road_pier'] = '#4b4e55';
  colors['road_pier'] = '#4b4e55';
  colors['road_pier'] = '#4b4e55';

  colors['road_area_bridge'] = "#292c33";

  colors['road_minor'] = '#3a3d44';
  colors['road_major_rail'] = "#3a3d44";
  colors['road_major_rail_hatching'] = "#3a3d44";
  colors['road_motorway_link'] = '#3a3d44';
  colors['road_secondary_tertiary'] = "#3a3d44";
  colors['road_trunk_primary'] = '#3a3d44';
  colors['road_motorway'] = '#3a3d44';
  colors['road_link'] = "#3a3d44";
  colors['road_trunk_primary_link'] = '#3a3d44';
  colors['road_trunk_primary_link_casing'] = "#3a3d44";
  colors['road_service_track_casing'] = "#3a3d44";
  colors['road_secondary_tertiary_casing'] = "#3a3d44";
  colors['road_motorway_casing'] = '#3a3d44';
  colors['road_trunk_primary_casing'] = '#3a3d44';
  colors['road_link_casing'] = "#3a3d44";
  colors['road_minor_casing'] = "#3a3d44";
  colors['road_motorway_link_casing'] = "#3a3d44";
  colors["road_path_pedestrian"] = "#292c33";
  colors['park_outline'] = "#777";

  //_ Water color
  colors['water'] = '#0e274b'; //'#191a1a';
  colors['water_intermittent'] = '#0e274b'; //'#191a1a';
  return colors;
}

//_ Find layer by color value
function findKey(json, value) {
  json.layers.forEach(layer => {
    Object.keys(layer['paint']).forEach((k: any) => {
      if (layer.paint[k] == value)
        return layer;
      else if (layer.paint[k]['stops']) {
        layer.paint[k].stops.forEach(st => {
          if (st[1] == value)
            return layer;
        });
      }
    });
  });
}
