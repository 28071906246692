
<div class="content">
  <ion-row class="routes-counter">
    <h4 class="title">{{ title }}</h4>
  </ion-row>

  <!-- LOADING INDICATOR -->
  <ion-row class="loading-row" *ngIf="loadingData" @fadeIn>
    <ion-col size="12" >
      <loading-indicator ></loading-indicator>
    </ion-col>
  </ion-row>

  <!-- No Data -->
  <ion-row *ngIf="lastRoutes?.length == 0 && !loadingData">
    <ion-col size="12">
      <ion-img src="assets/images/map/device-dashboard/no-route.svg" style="height: 90px; margin: 15px 0 auto;"></ion-img>
    </ion-col>
    <ion-col size="12">
      <h5 class="ion-text-center">{{"map.dashboard.noRecords"|translate}}</h5>
    </ion-col>
  </ion-row>

  <!-- Map y Top Route Buttoons -->
  <div class="map-and-pagination" [hidden]="lastRoutes?.length == 0 && !loadingData">
    <ion-row class="routes-counter">
      <ion-col *ngFor="let route of lastRoutes; let index = index" class="ion-no-padding">
        <ion-item button lines="none" (click)="selectRoute(route)" class="ion-no-padding route-item" [class.selected]="selectedRoute?.id == route.id" detail="false">
          <ion-label class="ion-text-center">{{ index+1 }}</ion-label>
        </ion-item>

      </ion-col>
    </ion-row>


    <paj-main-map-lite #map [height]="'200px'" [pitch]="0" [markerSize]="{ width: 50, hight: 50 }" [hideFabButton]="true" [hidden]="!selectedRoute" [filterSource]="{ directions: false }" [hideLegends] = "true" [customerSettings]="{ map_projection: 'mercator' }" [fakeUser]="mapUser" [connectWithServices]="{ mapService: false }"></paj-main-map-lite>

    <!-- Distance badge -->
    <ion-badge class="distance-badge" [hidden]="!selectedRoute" [class.loader]="optimizing" @zoomIn *ngIf="!loadingData"
    [ngClass]="{'min': distancePipe.transform(distance, '1.1-1', true).toString().length >= 5 }">
      
      <ion-label class="ion-text-center ion-text-wrap">
        <span class="ion-text-nowrap">{{ distance | KmtoMiles : '1.1-1' :  true }}</span>
        &nbsp;<span class="ion-text-nowrap">{{ distance_unit }}</span>        
      </ion-label>
    </ion-badge>

    <ion-fab-button [hidden]="!selectedRoute" color="light" class="show-route-fab" size="small" (click)="openRouteInMainMap()" title="Show route in main map.">
      <ion-icon src="assets/images/map/device-dashboard/maximize.svg" color="primary"></ion-icon>
    </ion-fab-button>
  </div>

  <!-- Route Information -->
  <ion-card class="route-card ion-no-padding" [hidden]="!selectedRoute">
    <!-- <ion-fab-button color="light" class="maximize-button" size="small" (click)="openRouteInMainMap()" title="Show route in main map.">
      <ion-icon src="assets/images/map/device-dashboard/maximize.svg" color="primary"></ion-icon>
    </ion-fab-button> -->
    <ion-card-content class="ion-no-padding">
      <ion-badge style="font-weight: normal;">{{ selectedRoute?.endID | translateUnixDate: "dddd DD MMMM, YYYY": false: '': language}}</ion-badge> <br />
      <ion-item lines="none" class="ion-no-padding route-info">
        <ion-icon slot="start" src="assets/images/logbook/arrow-up.svg" color="primary"></ion-icon>
        <ion-label>
          <p [title]="selectedRoute?.end_address"><b>{{ selectedRoute?.endID | translateUnixDate : "HH:mm": false : '' : language }}</b> {{ selectedRoute?.end_address }}</p>
          <p [title]="selectedRoute?.start_address"><b>{{ selectedRoute?.startID | translateUnixDate : "HH:mm": false : '' : language }}</b> {{ selectedRoute?.start_address }}</p>
        </ion-label>
      </ion-item>
    </ion-card-content>
  </ion-card>
</div>
