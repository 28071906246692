import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App } from "@capacitor/app";
@Injectable({
  providedIn: 'root'
})
export class ThreedeetouchService {

  constructor(private router: Router) {

  }

  enable3dtouch() {
    console.log('enable3dtouch')
    setTimeout(() => {
      if ((window as any).shortcutItemType) {
        console.log('enable3dtouch 1', (window as any).shortcutItemType)
        this.navigate((window as any).shortcutItemType);
        (window as any).shortcutItemType = null;
      }
    })

    App.addListener("appStateChange", (change) => {
      setTimeout(() => {
        if (change.isActive && (window as any).shortcutItemType) {
          console.log('appStateChange', (window as any).shortcutItemType)
          this.navigate((window as any).shortcutItemType);
          (window as any).shortcutItemType = null;
        }
      }, 1000);
    })

    // if (this.platform.is("ios")) {
    //   this.threeDeeTouch.isAvailable().then(isAvailable => {
    //     console.log('isAvailable', isAvailable)
    //   });
    //    this.threeDeeTouch.watchForceTouches()
    //     .subscribe(
    //       (data: ThreeDeeTouchForceTouch) => {
    //         console.log('watchForceTouches', data)
    //       }
    //     );
    //   let actions: Array<ThreeDeeTouchQuickAction> = [

    //     {
    //       type: 'alerts',
    //       title: 'Alerts',
    //       subtitle: 'Deactivate all alerts',
    //       iconType: 'Notification'
    //     },
    //     {
    //       type: 'devices',
    //       title: 'Device Management',
    //       subtitle: 'Manage devices',
    //       iconType: 'Search'
    //     },
    //     {
    //       type: 'map',
    //       title: 'Home Page',
    //       subtitle: 'Quickly to home.',
    //       iconType: 'Home'
    //     },
    //   ];

    //     this.threeDeeTouch.configureQuickActions(actions);

    //     this.threeDeeTouch.onHomeIconPressed().subscribe(
    //     (payload) => {
    //       // returns an object that is the button you presed
    //       switch (payload.type) {

    //         case 'map':
    //           this.router.navigate(['map'])
    //           break;
    //         case 'devices':
    //           this.router.navigate(['device-management'])
    //           break;
    //         case 'alerts':
    //           this.router.navigate(['alerts'])
    //           break;


    //       }

    //     }
    //   )
    // }
  }
  navigate(shortcutItemType) {
    switch (shortcutItemType) {
      case 'map':
        this.router.navigate(['map'])
        break;
      case 'devices':
        this.router.navigate(['device-management'])
        break;
      case 'alerts':
        this.router.navigate(['alerts'])
        break;
      case 'logbook':
        this.router.navigate(['logbook'])
        break;
    }
  }
}
