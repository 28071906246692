import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { DeviceColorPage } from '../device-color/device-color.page';
import { DeviceAlarmsPage } from '../device-alarms/device-alarms.page';
import { DevicePositionPage } from '../device-position/device-position.page';
import { animations } from 'src/app/animations/animations';
import { DeviceImagePage } from '../device-image/device-image.page';
import { DeviceGroupSelectionComponent } from '../device-group-selection/device-group-selection.component';
import { CommunicationService } from '../communitaction.service';

@Component({
  selector: 'setup-device-settings-list',
  templateUrl: './device-settings-list.component.html',
  styleUrls: ['./device-settings-list.component.scss'],
  animations: animations
})
export class SetupDeviceSetingsListComponent implements OnInit, AfterViewInit {
  @Input() device = null;
  @Input() navigation: IonNav = null;
  @Input() deviceGroups = [];
  externalChange = ""

  constructor (private modalCtrl: ModalController, private communicationService: CommunicationService) {

  }

  ngOnInit(): void {
    // console.log('DEVICES GROUP IN SETTING LIST', this.deviceGroups)    
  }

  ngAfterViewInit(){
    this.communicationService.goToSetting$.subscribe(param => {
      this.openSetting(param)
    })
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  openSetting (setting) {
    const pagesArray = { 
      'device_color': DeviceColorPage, 
      'device_alerts': DeviceAlarmsPage, 
      'device_tracking': DevicePositionPage,
      'device_image': DeviceImagePage,
      'device_group': DeviceGroupSelectionComponent
    };
    this.navigation.push( pagesArray[setting], { device: this.device, deviceGroups: this.deviceGroups }, {}  );
  }
}