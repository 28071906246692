import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { animations } from 'src/app/animations/animations';
import { AppService } from 'src/app/app.service';
import { getLogbookAddress } from 'src/app/components/map-address-picker/util';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DashboardTimelineService } from '../timeline-service';
import { MapRenderImagePipe } from '../map-render-image.pipe';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouteService } from '../../../../devices-sidebar/device-item/device-submenu/route/route.service';
import { ModalController } from '@ionic/angular';
import { imageDataToFile } from '../util/util';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { DevicesDashboardService } from '../../devices-dashboard.service';

@Component({
  selector: 'timeline-route-card',
  templateUrl: './route-card.component.html',
  styleUrls: ['./route-card.component.scss'],
  animations: animations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteCardComponent  implements OnInit {
  @Input('route') get route() {
    return this._route;
  };
  @Input('index') index = 0;
  _route = null;
  device = null;
  userId = this.appService.user.id;
  distance = null;
  loading = { address: false, mapImage: false, routeData: false, snap: false };
  @Output('onItemInView') onItemInView = new EventEmitter();
  whitePicture = ('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
  routePoints = null;
  mapPreview: Observable<string> = new BehaviorSubject(this.whitePicture);
  isIntoView = false;
  valhallaSubscription = null;
  imageFromMedia = null;
  constructor(private appService: AppService, private authService: AuthenticationService,
              private cdr: ChangeDetectorRef,
              private mapImagePipe: MapRenderImagePipe, private routeService: RouteService,
              private modalCtrl: ModalController, private timelineService: DashboardTimelineService, private devicesService:DeviceDataService,
              private devicesDashboardService: DevicesDashboardService) { }

  ngOnInit() {
  }

  set route(value) {
    this._route = value;
    if (this.route){
      // this.requestAddress(value);
      this.device = this.appService.devices.find(d => d.id == this.route?.iddevice);
      this.imageFromMedia = this.timelineService.getImageFromMediaStorage(this.route);
      // this.cdr.markForCheck();
    }
  }

  requestAddress(route) {
    let prom1 = null, prom2 = null;
    if (!route.start_address && (route.start_lat && route.start_lng)) {
      prom1 = this.authService.getStreetAdrressNew(route.start_lat, route.start_lng).then(res => {
        if (res) {
          if (res.success){
            route.start_address = getLogbookAddress(res.success.features[0].properties).address;
            this.cdr.markForCheck();
          }
        }
      });
    }
    if (!route.end_address && (route.end_lat && route.end_lng)) {
      this.loading.address = true;
      prom2 = this.authService.getStreetAdrressNew(route.end_lat, route.end_lng).then(res => {
        if (res) {
          if (res.success){
            route.end_address = getLogbookAddress(res.success.features[0].properties).address;
            this.cdr.markForCheck();
          }
        }
      });
    }

    this.loading.address = true;
    if ((prom1 || prom2) && !this.loading.address){
      Promise.all([prom1, prom2]).then(() => {
        this.loading.address = false;
      });
    }
  }

    async openRouteInMainMap(){
      if(this.appService.userSettings.hide_all_devices_default){
        const toggleValue = {
          deviceId: this.device.id,
          checked: true,
        };
        //this.deviceListService.toggleDevice(toggleValue)
      }    

      if (this.valhallaSubscription)this.valhallaSubscription.unsubscribe();
      if (!this.routePoints) {
        await this.getRoutePoints(this.route);
        }

        const device = this.devicesService.devices[this.device.id]
        device.properties.deviceshow = 1
        this.devicesService.devices[device.id].properties.deviceshow = 1;

      this.routeService.updateRoute({
      dismissed: true,
      datapoints: this.routePoints,
      rangeMode: 4,
      deviceId: this.device.id,
      startDate: this.route.startID,
      endDate: this.route.endID,
      isManualRoute: this.routePoints.length === 2,
      notSaveOriginalDataPoints:true,
      routeFrom: 'timeline'
    });


    this.dismiss();
  }
  async dismiss(){
    // const modal = await this.modalCtrl.getTop();
    // if(modal != null){
    //   this.modalCtrl.dismiss();
    // }
    this.devicesDashboardService.openInMobiles.next(false);
  }

  isInViewport(){
    this.isIntoView = true;
    // this.cdr.markForCheck();
    this.onItemInView.emit(this.route);
    this.requestAddress(this.route);

    //_ Request route points and map preview if image doesnt exist
    if (!this.routePoints && !this.loading.routeData && !this.imageFromMedia)
      this.getRoutePoints(this.route);

    //_ Request distance
    if (!this.loadingDistance && !this.distance && +this.route.optimized_distance == 0)
      this.requestDistance();
    else{
      this.distance = +this.route.optimized_distance;
      this.cdr.markForCheck();
    }
  }

  outOfViewport(){
    // this.isIntoView = true;
    // this.cdr.markForCheck();
  }

  getRoutePoints(route){
    if (!route.startID || !route.endID)
      return;

    //this.distance = 0;
    this.loading.routeData = true;
    return this.authService.getTrackerDataForLogBook(route.iddevice,
      this.route.startID,
      this.route.endID).then(res => {
        if (res) {
          if (res.success) {
            //_ Show only start and end route marker in the map
            if(res.success.length > 0){
              let points = res.success;

              //_ Set start|end points with the start|end stored on the route
              if (points.length > 0) {
                //_ Map the updated route markers
                points = points.map(p => {
                  if (p.upt) {
                    p.lat = p.upt[0].lat;
                    p.lng = p.upt[0].lon;
                  }
                  return p;
                })

                points[0].lat = +this.route.end_lat;
                points[0].lng = +this.route.end_lng;
                points[points.length - 1].lat = +this.route.start_lat;
                points[points.length - 1].lng = +this.route.start_lng;
                this.routePoints = points;
              } else {
                this.loading.routeData = false;
                //_ SHOW ERROR IMAGE
                // this.appService.showToast('', this.translate.instant('toast.errortoLoadTrackerPoint'), 3000, 'danger');
              }
            } else {
                if(this.route.manual_route === 1){  // manual route
                  let singleRouteData;
                  const points = [];
                  singleRouteData = {
                    id:1,
                    iddevice: this.route.iddevice,
                    battery: 0,
                    dateunix: this.route.endID,
                    direction: 0,
                    lat: this.route.end_lat,
                    lng: this.route.end_lng,
                    speed: 0,
                    upt: null
                  }
                  points.push(singleRouteData);
                  singleRouteData = {
                    id:2,
                    iddevice: this.route.iddevice,
                    battery: 0,
                    dateunix: this.route.startID,
                    direction: 0,
                    lat: this.route.start_lat,
                    lng: this.route.start_lng,
                    speed: 0,
                    upt: null
                  }
                  points.push(singleRouteData);
                  this.routePoints = points;
                }
             }

             this.loading.routeData = false;

             this.mapPreview = this.mapImagePipe.transform(this.device, this.routePoints, { renderStartEndFlag: true });
             this.mapPreview.subscribe( r => {
              if (r != this.whitePicture){
                console.log('Saving the image', this.device.name);
                this.timelineService.uploadImageOnServer(this.route.id ?? this.route._id, null, imageDataToFile(r));
              }
             });
             //_ Render route in map
            //  this.timelineService.renderRouteInMap(this.device, this.routePoints).then((r: any) => {
            //   console.log('Response from map ', r);
            //   this.mapPreview = r;
            //  });

          }

        }
        this.cdr.markForCheck();
      });
  }

  //_ Request optimized distance or use no optimized in case return 0
  loadingDistance;
  requestDistance() {
    this.loadingDistance = true;
    this.cdr.markForCheck();
    this.authService.optimizeSingleRouteDistance(this.route.id, 0).then( r => {
      if (r.route){
        const newRouteData = r.route;
        if (+newRouteData.optimized_distance > 0)
          this.distance = +newRouteData.optimized_distance;
        else
          this.distance = +newRouteData.distance;
      }
      this.loadingDistance = false;
      this.cdr.markForCheck();
    })

  }

}
