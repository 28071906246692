<ion-menu
  menuId="deviceMenu"
  side="end"
  contentId="main-content"
  type="overlay"
  swipeGesture="false"
  swipeEnabled="false"
  (ionWillOpen)="openStatus(true)"
  (ionDidOpen)="open()"
  (ionDidClose)="close()"
  (ionWillClose)="openStatus(false)"
  [class.box-shadow]="showShadow"
  [class.pull-left]="!isAnimatingMenu"
>
  <ion-nav #nav class="devicesListNavigation"></ion-nav>
</ion-menu>
<!-- <ion-menu
  menuId="deviceMenu"
  side="end"
  contentId="main-content"
  type="overlay"
  swipeGesture="false"
  (ionDidOpen)="open()"
  (ionDidClose)="close()"
  tabindex="-1"
>
  <ion-nav #nav id="devicesListNavigation"></ion-nav>
</ion-menu> -->

