const runSetup = require('./setup');
const setupOptions = require('@mapbox/mapbox-gl-draw/src/options');
const setupAPI = require('@mapbox/mapbox-gl-draw/src/api');
const Constants = require('@mapbox/mapbox-gl-draw/src/constants');

// Create a custom Mapbox loader
const setupDraw = function (options, api) {

  options = setupOptions.default(options);

  const ctx = {
    options: options
  };

  api = setupAPI.default(ctx, api);
  ctx.api = api;

  const setup = runSetup.default(ctx);

  api.onAdd = setup.onAdd;
  api.onRemove = setup.onRemove;
  api.types = Constants.types;
  api.options = options;
  api.ctx = ctx;

  return api;
};



module.exports = function (options) {
  setupDraw(options, this);
};

module.exports.modes = require('@mapbox/mapbox-gl-draw/src/modes');