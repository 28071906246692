<ion-content class="ion-no-padding">
  <!-- Close button -->
  <ion-button
    class="close-btn ion-no-margin ion-no-padding"
    fill="clear"
    shape="round"
    (click)="close()"
  >
    <ion-icon name="close"></ion-icon>
  </ion-button>

  <ion-card color="transparent">
    <ion-label class="ion-text-center ion-text-wrap title">
      <h1>{{ permissionItem?.title | translate }}</h1>
    </ion-label>
    <ion-item lines="none" color="transparent">
      <ion-label class="ion-text-center ion-text-wrap">
        <ion-icon
          [src]="permissionItem?.image"
          style="height: 50px; width: 50px"
        >
        </ion-icon>
        <p>{{ permissionItem?.description | translate }} </p>
      </ion-label>
    </ion-item>

    

    <ion-card-content class="ion-no-margin">
      <ion-icon name="checkmark-circle-outline" color="success" class="check-icon" @fadeIn *ngIf="permissionEnabled"></ion-icon>
      <ion-icon name="ban-outline" color="black" class="check-icon" @fadeIn *ngIf="!permissionEnabled"></ion-icon>
      <ion-button color="primary" size="small" shape="round" (click)="openSettings()" *ngIf="!permissionEnabled" @fadeIn>
        <ion-label>{{ permissionItem?.buttonText | translate }}</ion-label>
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
