import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";
import { DecimalPipe } from "@angular/common";
import { AppService } from "../app.service";

@Directive({
  selector: "[input-validation]",
})
export class InputValidationDirective {
  decimalSymbol = ",";
  symbolToReplace = ".";
  delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  constructor(
    private el: ElementRef,
    public model: NgControl,
    private appService: AppService,
    private decimalPipe: DecimalPipe
  ) {}

  async ngAfterViewInit() {
    await this.delay(200);
    this.loadInputvalues();
  }
  ngOnInit(): void {
    this.appService.language$.subscribe((res) => this.loadInputvalues());
  }
  async loadInputvalues() {
    let value = this.el.nativeElement.value;
    if (this.appService.language === "en" || this.appService.language === "en_US") {
      this.decimalSymbol = ".";
      this.symbolToReplace = ",";
    }
    else {
      this.decimalSymbol = ",";
      this.symbolToReplace = ".";
      
    }
    this.el.nativeElement.value = value.replace(
      this.symbolToReplace,
      this.decimalSymbol
    );
  }
  @HostListener("input", ["$event"]) onEvent($event) {
    let value = this.el.nativeElement.value;
    value = value.replace(this.symbolToReplace, this.decimalSymbol);

    try {
      this.model.control.setValue(value);
    } catch {
      this.model.control.setErrors({ incorrect: true });
    }
  }
  @HostListener("keypress", ["$event"]) onkeyPress(event) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);
    const dotExist = event.srcElement.value.includes(this.decimalSymbol);

    if (
      !pattern.test(inputChar) ||
      (event.srcElement.value == "" &&
        (inputChar == "." || inputChar == ",")) ||
      ((inputChar == "." || inputChar == ",") && dotExist)
    ) {
      event.preventDefault();
    }
  }
}
