import { Injectable, Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CommandsService {
  public sendCommand$ = new Subject<any>();

  constructor(){}

  sendCommand(item){
    this.sendCommand$.next({
      commandId: item.id,
      item: item
    });
  }

}
