import { Component, OnInit,Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-select-device',
  templateUrl: './select-device.component.html',
  styleUrls: ['./select-device.component.scss'],
})
export class SelectDeviceComponent implements OnInit {

  @Input() data;
  public results=[]
  userId:any;
  selectedValues = [];

  constructor(private appService:AppService,private popoverController: PopoverController) {
  }

  ngOnInit() {
    this.userId = Object.assign({}, this.appService.user.id);
    this.results = [...this.data];
  }

  onCancel(){
    this.popoverController.dismiss()
  }

  onSubmit(){
    this.popoverController.dismiss({devices:this.selectedValues})
  }

  handleChange(event) {
    const query = event.target.value.toLowerCase();
    this.results = this.data.filter(d => d.name.toLowerCase().indexOf(query) > -1 || d.imei.toLowerCase().indexOf(query) > -1);
  }

  checkedDevice(event: any, item: any) {
    if (event.detail.checked) this.selectedValues.push(item);
    else  {
      let index = this.selectedValues.findIndex((value) => value.id == item.id);
      this.selectedValues.splice(index, 1);
    }
  }
}
