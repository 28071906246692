export const AllDevicesItem = {
    name: "timeLine.allDeivce",
    id: -1,
    iconusecustom: null,
    iconname: null,
    iconcustomimage: null,
};

export const TimelineCollectionTypes: Array<TimelineCollectionInterface> = [
    {
        id: -1,
        name: 'timeLine.all',
        icon: 'assets/images/device-picker/select-all.svg',
        color: 'primary',
        backendName: null,
        noDataImage: 'assets/images/device-picker/no-data.svg',
        noDataMessage: 'timeLine.noDataFound'
    },
    {
        id: 1,
        name: 'timeLine.trips',
        icon: 'assets/images/devices-list/route-marker.svg',
        color: 'primary',
        backendName: 'routes',
        noDataImage: 'assets/images/map/device-dashboard/no-route.svg',
        noDataMessage: 'logBook.dontHaveTripRecords'
    },
    {
        id: 2,
        name: 'timeLine.notifications',
        icon: 'assets/images/bell.svg',
        color: 'primary',
        backendName: 'alarms',
        noDataImage: 'assets/images/notifications.svg',
        noDataMessage: 'dashboard.noNotifications'
    }
]

export interface TimelineCollectionInterface {
    id: number, 
    name: string, 
    icon: string, 
    color: string,
    backendName?: string,
    noDataImage: string,
    noDataMessage: string
}

//_ Files manipulation
export const imageDataToFile = (imgData, imageType='image/png') => {
    // Decode the image data
    const binaryData = atob(imgData.split(",")[1]);
    const array = new Uint8Array(binaryData.length);

    for (let i = 0; i < binaryData.length; i++) {
        array[i] = binaryData.charCodeAt(i);
    }

    // Create a blob object from the array with the image MIME type
    const blob = new Blob([array], { type: imageType });
    return blob;
}

export const fetchUrlToImageData = async (url)  => {
    return new Promise( (resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            // Get the image data
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const imageData = canvas.toDataURL('image/png');
            resolve(imageData);
        };

        // Set the image URL
        img.crossOrigin = "Anonymous";
        img.src = url;
    })
  }