import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DeviceListService {
  public deviceData;
  public deviceMarkers;
  public drawer;
  public _customDeviceGroups;
  //Device Toggle Button:
  private _deviceToggleSource = new Subject<string>();
  deviceToggle$ = this._deviceToggleSource.asObservable();

  //All Devices Toggle:
  private _allDevicesToggleSource = new Subject<string>();
  allDevicesToggle$ = this._allDevicesToggleSource.asObservable();

  //Device Selection:
  private _deviceSelectSource = new Subject<string>();
  deviceSelect$ = this._deviceSelectSource.asObservable();

  //Device expand content:
  public _deviceExpandSource = new Subject<{}>();
  deviceExpand$ = this._deviceExpandSource.asObservable();

  private lastSelectedItem = null;
  expandItem = null;
  dragActive = new BehaviorSubject<boolean>(false);

  //_ Use when devices are ready on the map
  _devicesReady = new Subject<{}>();
  devicesReady$ = this._devicesReady.asObservable();

  flyToDevice = new Subject();
  disableSearchbar = new BehaviorSubject(false);

  constructor() { }

  toggleDevice(buttonStatus) {    
    this._deviceToggleSource.next(buttonStatus);
  }

  toggleAllDevices(devicesData) {    
    this._allDevicesToggleSource.next(devicesData);
  }

  selectDevice(deviceID) {
    // if (this.lastSelectedItem != deviceID) //_ only select the device at first time clicked
    this._deviceSelectSource.next(deviceID);
    this.lastSelectedItem = deviceID;

    //_ ENABLE TO USE TWO STATUS 
    //_ EXPAND | UNEXPAND
    // if (this.expandItem != deviceID) {
    //   this.expandItem = deviceID;
    this._deviceExpandSource.next({ deviceID, expand: true });
    // } else {
    //   this.expandItem = null;
    //   this._deviceExpandSource.next({ deviceID, expand: false });
    // }
  }

  unExpandAll() {
    if (this.expandItem != null) {
      this._deviceExpandSource.next({
        deviceID: this.expandItem,
        expand: false,
      });
      //this.expandItem = null;
    }
  }
}
