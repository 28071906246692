import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NativeDateAdapter } from '@angular/material/core';
import { AppService } from 'src/app/app.service';
import { Constants } from 'src/app/constants.enum';
import { StorageService } from 'src/app/services/storage.service';

@Injectable()

export class CustomDateAdapter extends MomentDateAdapter {
  constructor (private appService: AppService) {
    super('en-Us');
    this.appService.language$.subscribe(language => {
      this.setLocale(language);
    });
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}