
<ion-header class="ion-no-border">
  <ion-toolbar color="light">
    <ion-searchbar [debounce]="1000" (ionChange)="handleChange($event)" placeholder="{{ 'dashboard.search' | translate }}"></ion-searchbar>
  </ion-toolbar>
</ion-header>
<div class="scroll ion-padding"> 
  <ion-list lines="full">
    <ion-item *ngFor="let item of results; let i=index">
      <ion-avatar slot="start">
        <ion-img
        src="{{ item | DeviceImage: userId | async }}"
        ></ion-img>
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        <h3>{{item.name }}</h3>
       </ion-label>
       <ion-checkbox mode="md" slot="end" [value]="item"
                     (ionChange)="checkedDevice($event, item)"
       ></ion-checkbox>
    </ion-item> 
  </ion-list>
</div>
<ion-footer class="ion-no-border" style="position: absolute;">
  <ion-toolbar color="light">
    <ion-buttons slot="end">
      <ion-button  fill="solid" (click)="onCancel()" expand="block" color="light">
        {{ "alerts.cancel" | translate }}
       </ion-button>
       &nbsp;  &nbsp;
       <ion-button fill="solid" [disabled]="!selectedValues.length" (click)="onSubmit()" expand="block" color="primary">        
        {{ "dateFormat.submit" | translate }}
       </ion-button>
       &nbsp;  &nbsp;
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

