import { Directive, ElementRef, OnInit, Renderer2, Input } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[appDatetimeDragDrop]'
})
export class DatetimeDragDropDirective implements OnInit {
  @Input() sensitivity: number = 20;

  private isDragging = false;
  private startY: number;
  private startValue: number;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private platform: Platform
  ) {}

  ngOnInit() {
    if (!this.platform.is('mobile')) {
      this.setupDragAndDrop();
    }
  }

  private setupDragAndDrop() {
    const ionDatetime = this.el.nativeElement;
    setTimeout(() => {
      const columns = ionDatetime.shadowRoot?.querySelectorAll('ion-picker-column');
      if (columns.length > 0)
        columns.forEach((column: HTMLElement) => {
          this.renderer.setStyle(column, 'cursor', 'ns-resize');

          this.renderer.listen(column, 'mousedown', (event: MouseEvent) => this.onMouseDown(event, column));
          this.renderer.listen(column, 'mousemove', (event: MouseEvent) => this.onMouseMove(event, column));
          this.renderer.listen(column, 'mouseup', () => this.onMouseUp());
          this.renderer.listen(column, 'mouseleave', () => this.onMouseUp());
        });
      else
        console.warn("No columns found..");

    }, 100); // Increased timeout to ensure the component is fully rendered
  }

  private onMouseDown(event: MouseEvent, column: HTMLElement) {
    this.isDragging = true;
    this.startY = event.clientY;
    const activeItem = column.querySelector('ion-picker-column ion-picker-column-option.option-active');
    this.startValue = activeItem ? parseInt(activeItem.textContent || '0', 10) : 0;
  }

  private onMouseMove(event: MouseEvent, column: HTMLElement) {
    if (!this.isDragging) return;
    const childElementCount = column.childElementCount;
    const deltaY = this.startY - event.clientY;
    const step = Math.floor(deltaY / this.sensitivity);

    let newValue = this.startValue + step;
    if (newValue < 0) newValue = 0
    else if (newValue > childElementCount - 1) newValue = childElementCount - 1

    this.updateColumnValue(column, newValue);
  }

  private onMouseUp() {
    this.isDragging = false;
  }

  private updateColumnValue(column: HTMLElement, newValue: number) {
    const items = column.querySelectorAll('ion-picker-column ion-picker-column-option');
    const totalItems = items.length;

    // Ensure the new value is within the range of available items
    newValue = (newValue + totalItems) % totalItems;

    // Find the item corresponding to the new value
    const targetItem = Array.from(items).find(item =>
      parseInt(item.textContent || '0', 10) === newValue
    );

    if (targetItem) {
      const inputEvent = new Event('input', { bubbles: true });
      targetItem.dispatchEvent(inputEvent);

      // Dispatch the change event manually if needed
      const ionChangeEvent = new CustomEvent('ionChange', {
        detail: { value: newValue }
      });
      column.dispatchEvent(ionChangeEvent);
    }
  }
}
