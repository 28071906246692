import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { environment as ENV } from 'src/environments/environment';
import { SelectableComponent } from '../selectable.component';

@Component({
  selector: 'app-selectable',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {

  @Input() useImageFromItem = false;
  @Input() useIconFromItem = false;
  @Input() tagName = '';
  @Input() tagId = '';
  @Input() get items() { return this._items; }
  @Input() selItem = null;
  @Input() canSearch = false;
  @Input() icon = null;
  @Output() onItemChanged = new EventEmitter<{}>();
  @Input() header = true;
  @Input() color = ''
  @Input() showInPopover = false;
  @Input() badge = null;
  @Input() badgeColor = 'light';
  @Input() rightIcon = null;

  @Input() forFilters = false;
  @Input() label = "Select";
  @Input() mini = false;
  @Input() headerColor = 'dark';
  @Input() isKebab = false;
  @Input() cssClassSelect = '';
  @Input() cssClassList = '';
  @Input() disabled = false;
  @Input() showRightIcon = false;
  @Input() selectColor = 'light';
  @Input() placeholder = null;
  @Input() useNameAsTranslationKey = false;
  @Input() hideSelectHeader = false;
  @Input() listHeight = '200px';
  @Input() popupCssClass = 'car-popover';
  @Input() showLabel = true;
  url: string = ENV.ROUTE;

  // _
  //_ When change the item, set to null selItem and trigger item change
  set items(value) {
    // This line is to add a properti called name, for use in headerFn for the virtual scroll header
    if (value)
      value.forEach(it => {
        if (!it.name || it.name == undefined)
          it.name = it[this.tagName];
        if (!it.id || it.id == undefined)
          it.id = it[this.tagId];
      });

    this._items = value;

  }

  _items = [];
  constructor(private modalCtrl: ModalController, private popoverCtr: PopoverController) { }

  firstInit = true;
  ngOnInit() { this.firstInit = false; }

  async openSelectable(event) {
    if (this.isKebab) event.stopPropagation();

    let selectable: any = await this.popoverCtr.create({
      component: SelectableComponent,
      event: event,
      mode: 'ios',
      cssClass: this.popupCssClass,
      componentProps: {
        items: this.items, tagId: this.tagId,
        tagName: this.tagName, canSearch: this.canSearch,
        selItem: this.selItem, icon: this.icon,
        showHeader: this.header, showInPopover: this.showInPopover,
        badgeColor: this.badgeColor, badge: this.badge,
        forFilters: this.forFilters, label: this.label,
        mini: this.mini, headerColor: this.headerColor,
        isKebab: this.isKebab, cssClass: this.cssClassList,
        useImageFromItem: this.useImageFromItem,
        useIconFromItem: this.useIconFromItem,
        useNameAsTranslationKey:this.useNameAsTranslationKey,
        hideSelectHeader:this.hideSelectHeader,
        listHeight: this.listHeight,
        showLabel:this.showLabel
      }
    });

    if (!this.showInPopover)
      selectable = await this.modalCtrl.create({
        component: SelectableComponent,
        componentProps: {
          items: this.items, tagId: this.tagId,
          tagName: this.tagName, canSearch: this.canSearch,
          selItem: this.selItem, icon: this.icon,
          showHeader: this.header, badgeColor: this.badgeColor,
          badge: this.badge, label: this.label, mini: this.mini,
          headerColor: this.headerColor, isKebab: this.isKebab,
          cssClass: this.cssClassList, useImageFromItem: this.useImageFromItem,
          useIconFromItem: this.useIconFromItem,
          useNameAsTranslationKey:this.useNameAsTranslationKey,
          hideSelectHeader: this.hideSelectHeader,
          listHeight: this.listHeight
        }
      });

    await selectable.present();

    const { data } = await selectable.onWillDismiss();
    if (data) {
      if (this.selItem != data.selItem) {
        this.selItem = data.selItem;
        this.onItemChanged.emit(this.selItem);
      }
    }
  }

  selectItem(item) {
    this.selItem = item;
    this.onItemChanged.emit(this.selItem);
  }

  clear(e) {
    e.stopPropagation();
    this.selItem = null
    this.onItemChanged.emit(null);
  }
}
