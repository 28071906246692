import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { animations } from 'src/app/animations/animations';
import { DetailsPage } from 'src/app/members/notifications/details/details.page';
import { translationsKeys } from 'src/app/members/notifications/notificationsUtil';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swiper from 'swiper';

@Component({
  selector: 'app-last-alarms',
  templateUrl: './last-alarms.component.html',
  styleUrls: ['./last-alarms.component.scss'],
  animations: animations
})
export class LastAlarmsComponent implements OnInit {
  @Input() title = this._translate.instant("timeLine.lastAlarms");
  @Input() get device () { return this._device; };
  @Input() get activityType () { return this._activityType; };
  @Input() language = 'en';

  _device: any = null;
  _activityType: any = null;
  @ViewChild('slides') swiperRef: ElementRef | undefined;
  swiperEl?: Swiper;

  loadingData = true;
  alarmTypes = [];
  lastAlarms = [];
  isBrowser = window['isBrowser'];
  constructor(private authService: AuthenticationService, private modalCtrl: ModalController, private route: Router, private _translate: TranslateService) { }

  ngOnInit() {

  }

  set device (value){
    this._device = value;
    if (this.activityType)
      this.getLastAlarms();
  }

  set activityType (value){
    this._activityType = value;
    if (this.device)
      this.getLastAlarms();
  }

  getLastAlarms(){
    this.loadingData = true;
    const queryParams = { itemsPerPage: 11, todaysAlarms: (this.activityType == 'today').toString() };
    this.authService.getLastNotifications(this.device.id, queryParams).then( (res: any) => {
      this.loadingData = false;
      const newAlarms = [];
      if (Array.isArray(res?.success)){
        res.success.forEach(alarm => {
          const alarmExtraData = translationsKeys.find(a => a.id == alarm.meldungtyp);
          if (alarmExtraData != undefined){
            if (!newAlarms[alarm.meldungtyp])
              newAlarms[alarm.meldungtyp] = [];

            newAlarms[alarm.meldungtyp].push({
              ...alarm, title: alarmExtraData.title, img: alarmExtraData.img, bezeichnung: alarmExtraData.title, meldung: alarmExtraData.description,
            });
          }
        })

      }

      this.lastAlarms = newAlarms;
      this.alarmTypes = Object.keys(newAlarms);
    }, (error) => {
      console.error('ERROR Getting last notifications.', error);
      this.loadingData = true;
    });
  }

  async openDetail(alarm){
    const md = await this.modalCtrl.create({
      component: DetailsPage,
      cssClass: 'submodal-shadow',
      componentProps: {
        notification: alarm
      }
    });

    await md.present();
  }

  async navigateToAlarmSummary(){
    this.modalCtrl.dismiss();
    await this.route.navigate(['/members/notifications']);
  }

  swiperReady () {
    this.swiperEl = this.swiperRef?.nativeElement.swiper;
  }

  @HostListener('window:resize')
  onResize() {
    if (this.swiperEl)
      setTimeout(() => this.swiperEl.update(), 100);
  }

}
