<paj-popup-template headerTitle="{{ 'map.usbFinderPopup.title' | translate }}" [contentTemplate]="Content">

</paj-popup-template>

<ng-template #Content>
  <ion-grid fixed class="ion-padding">
    <ion-row>
      <ion-col size="12">
        <h3 class="ion-text-center">{{ 'map.usbFinderPopup.title' | translate }}</h3>
        <ion-item color="transparent" lines="none" detail="false">
          <ion-icon slot="start" src="assets/images/usb-finder-popup/cigarette.svg" color="primary"></ion-icon>
          <ion-label class="ion-text-wrap">
            {{ 'map.usbFinderPopup.chargeFinderDetails' | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col size="12" class="ion-text-end">
        <ion-button
        (click)="onCancel()"
        color="light"
        shape="round"
      >
        OK
      </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>