<div [class]="cssClassSelect">
  <ion-item
    button
    detail="false"
    (click)="openSelectable($event)"
    lines="none"
    class="select"
    *ngIf="items && !isKebab"
    [class.mini]="mini"
    [disabled]="disabled"
    [color]="selectColor"
  >
    <ion-buttons slot="start">
      <ion-button>
        <ion-icon
          [name]="icon"
          *ngIf="icon && icon != 'deviceImage' && !showRightIcon"
          slot="start"
        ></ion-icon>

        <ion-icon *ngIf="showRightIcon && selItem?.icon" [icon]="selItem?.icon"></ion-icon>

        <ion-avatar
          *ngIf="icon == 'deviceImage'"
          style="--border-radius: 0"
          slot="start"
        >
          <ion-img
            alt="Image not Found"
            style="object-fit: contain"
            src="{{
              selItem?.device | DeviceImage: selItem?.customer_id | async
            }}"
          >
          </ion-img>
        </ion-avatar>
      </ion-button>
    </ion-buttons>
    <ion-label *ngIf="selItem && !useNameAsTranslationKey && showLabel" style="font-size: 14px">
      {{ selItem[tagName] }}
    </ion-label>
    <ion-label *ngIf="selItem && useNameAsTranslationKey && showLabel" style="font-size: 14px">
      {{ selItem[tagName] | translate }}
    </ion-label>
    <ion-label *ngIf="forFilters && !selItem && showLabel" style="font-size: 14px">
      {{ label }}
    </ion-label>
    <ion-label *ngIf="placeholder" style="font-size: 14px">
      {{ placeholder }}
    </ion-label>
    <ion-buttons slot="end">
      <ion-button
        shape="round"
        fill="clear"
        size="small"
        *ngIf="!forFilters || (forFilters && !selItem)"
        [disabled]="disabled"
      >
        <ion-icon name="chevron-down"></ion-icon>
      </ion-button>
      <ion-icon *ngIf="rightIcon" [name]="rightIcon"></ion-icon>
    </ion-buttons>
    <ion-buttons slot="end" *ngIf="forFilters && selItem">
      <ion-button
        shape="round"
        fill="solid"
        color="transparent"
        size="small"
        (click)="clear($event)"
        [disabled]="disabled"
      >
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>

  <ion-button
    *ngIf="isKebab"
    (click)="openSelectable($event)"
    [color]="color"
    fill="clear"
    shape="round"
    [size]="mini ? 'small' : 'default'"
    [disabled]="disabled"
  >
    <ion-icon src="assets/icons/dots.svg"></ion-icon>
  </ion-button>
</div>
