import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-choice-tour',
  templateUrl: './choice-tour.component.html',
  styleUrls: ['./choice-tour.component.scss'],
})
export class ChoiceTourComponent implements OnInit {

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() { }

  close(tour = null) {
    this.popoverCtrl.dismiss(tour)
  }
}
