import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-select-popover',
  templateUrl: './select-popover.component.html',
  styleUrls: ['./select-popover.component.scss'],
})
export class SelectPopoverComponent implements OnInit {
  @Input() data;
  @Input() selectedItems = [];
  @Input() multiSelection = false;
  @Input() selectedItem = null;
  @Input() selectTemplete: TemplateRef<any> = null;
  @Input() itemTemplate: TemplateRef<any> = null;
  @Input() headerTemplete: TemplateRef<any> = null;
  @Input() footerTemplate: TemplateRef<any> = null;
  
  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {

  }

  selectItem(item, index){
    if (this.multiSelection){
      if (item.checked){
        item.checked = false;
        this.selectedItems.splice( this.selectedItems.map(i=>i.id).indexOf(item.id), 1);
      }
      else{
        item.checked = true;
        this.selectedItems.push(item);
      }
    }
    else{
      this.selectedItems = [item];
      this.selectItem = item;
      this.popoverCtrl.dismiss(item);
    }
  }

  accept(){
    this.popoverCtrl.dismiss({ selectedItems: this.selectedItems });
  }

}
