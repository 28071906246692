<div class="content" #loadingDiv [hidden]="startupCompleted">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.58 45.87">
      <g id="Ebene_2-2">
          <g id="Layer_1">
              <path class="cls-1" d="m18.58,27.91L0,13.96,18.04,0s-10.9,14,.54,27.91h0Z" />
              <path class="cls-1" d="m0,17.96l18.58,13.96L.54,45.87s10.9-14-.54-27.91h0Z" />
          </g>
      </g>
  </svg>
</div>
