import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppService } from "../app.service";
import { MapService } from "../members/map/components/map/map.service";
import { ApiService } from "./api.service";
import * as moment from 'moment-timezone/moment-timezone';

@Injectable({
  providedIn: "root",
})
export class UserSettingsService {
  public _userSettings = new Subject<{}>();
  public _userSettings$ = this._userSettings.asObservable();
  public userSettings;
  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private mapService: MapService
  ) { }

  //_ ! I THINK THIS SERVICE IS NOT USED
  //_ CHECK TO DELETE ....
  getUserSettings() {
    this.apiService
      .getUserSettings()
      .then(async (res: any) => {
        if (res && res.success) {
          this.userSettings = await res.success;
          this._userSettings.next(res.success);
          this.appService._darkMode.next(Boolean(res.success.dark_mode));
          this.appService.showIMEI.next(Boolean(res.success.show_imei));
          this.appService.logoutOnClose?.next(
            Boolean(res.success.logout_onclose)
          );
          this.mapService.iconSize?.next(
            this.getIconSize(res.success.icon_size)
          );

          this.mapService.thickness?.next(res.success.polyline_thickness);
          //   this.mapService.showClusterMarkers.next(res.success.cluster_markers);
          //   this.mapService.showDotEffect.next(res.success.green_dot);
          //   this.mapService.noAnimatedMarker.next(res.success.disable_animation);
          //   this.mapService.showDefaultImage.next(res.success.default_image);

          //_ Apply timezone to moment lib
          // moment.tz.setDefault(this.userSettings.timezone);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }
  getIconSize(step) {
    return { width: 20 + step * 15, height: 20 + step * 15 };
  }
}
