<div class="content">
  <ion-row class="row-title">
    <h4 class="title">{{ title }}</h4>
  </ion-row>

  <!-- LOADING INDICATOR -->
  <ion-row class="loading-row" *ngIf="loadingData" @fadeIn>
    <ion-col size="12">
      <loading-indicator ></loading-indicator>
    </ion-col>
  </ion-row>

  <!-- No Data -->
  <ion-row *ngIf="lastAlarms.length == 0 && !loadingData">
    <ion-col size="12">
      <ion-img
        src="assets/images/notifications.svg"
        style="height: 90px; margin: 15px 0 auto;"
      ></ion-img>
    </ion-col>
    <ion-col size="12">
      <h5 class="ion-text-center">
        {{ "dashboard.noNotifications" | translate }}
      </h5>
    </ion-col>
  </ion-row>

  <!-- Alarms Slides -->
  <ion-row *ngIf="lastAlarms.length > 0 && !loadingData" style="padding: 0 0;">
    <!-- <swiper-container
      #slides
      (afterinit)="swiperReady()"
      [loop]="false"
      [pagination]="isBrowser"
    >
      <swiper-slide *ngFor="let alarmType of alarmTypes" class="alarm-slide">
          <ion-card class="ion-no-padding">
            <ion-badge color="primary">{{ lastAlarms[alarmType].length }}</ion-badge>
            <ion-card-content>
              <div class="icon-wrapper">
                <ion-icon [src]="lastAlarms[alarmType][0].img" color="primary"></ion-icon>
              </div>
              <ion-list class="alarms-list" lines="full">
                <ion-item class="ion-no-padding ion-no-margin" *ngFor="let alarm of lastAlarms[alarmType]; let i = index" detail="false" button (click)="openDetail(alarm)">
                  <ion-label>
                    <b>{{ i+1 }}.</b> {{ alarm.dateunix | translateUnixDate : "d.MM HH:mm": true }}
                  </ion-label>
                </ion-item>
              </ion-list>
              <ion-label>{{ lastAlarms[alarmType][0].title | translate }}</ion-label>
            </ion-card-content>
          </ion-card>
      </swiper-slide>
    </swiper-container> -->

    <ion-card class="ion-no-padding" *ngFor="let alarmType of alarmTypes">
      <!-- <ion-badge color="primary">{{ lastAlarms[alarmType].length }}</ion-badge> -->
      <ion-card-content style="padding-bottom: 0;">
        <div class="card-header-text">
          <div class="icon-wrapper">
            <ion-icon [src]="lastAlarms[alarmType][0].img" color="primary"></ion-icon>
            <ion-badge color="primary">{{ lastAlarms[alarmType].length }}</ion-badge>
          </div>
          <h2 style="width: 100%">{{ lastAlarms[alarmType][0].title | translate }}</h2>
        </div>
        <ion-list class="alarms-list" style="padding: 0 0;">
          <ion-item class="" *ngFor="let alarm of lastAlarms[alarmType]; let i = index; let last = last;" detail="false" button (click)="openDetail(alarm)"
          [lines]="last ? 'none' : 'full'"
          >
            <ion-icon slot="start" name="time-outline"></ion-icon>
            <ion-label>
              {{ alarm.dateunix | translateUnixDate : "DD.MM.YYYY HH:mm": true : '' : language }}
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </ion-row>

  <!-- SEE ALL ALARMS BUTTON -->
  <ion-row style="padding: 15px 0;" *ngIf="!loadingData">
    <ion-col size="12" class="ion-text-center">
      <!-- <ion-button shape="round" fill="outline" color="primary" (click) = "navigateToAlarmSummary()">
        <ion-label>{{ "dashboard.showALL" | translate }}</ion-label>
      </ion-button> !-->

      <ion-button
        style="text-transform: none"
        routerDirection="forward"
        size="default"
        shape="round"
        fill="clear"
        expand="full"
        (click)="navigateToAlarmSummary()"
        >{{ "dashboard.showALL" | translate }}</ion-button>
    </ion-col>
  </ion-row>



</div>
