import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { animations } from 'src/app/animations/animations';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  animations: animations
})
export class LoadingIndicatorComponent implements AfterViewInit {
  //_ All names of ionic default spinner
  //_ "bubbles" ｜ "circles" ｜ "circular" ｜ "crescent" ｜ "dots" ｜ "lines" ｜ "lines-sharp" ｜ "lines-sharp-small" ｜ "lines-small" ｜ undefined
  //_ type = circular | lines | clock | dots
  @Input() type = 'circular';
  @Input() className = '';
  @Input() color = 'primary';
  @Input() text = null;
  @Input() vertical = true;
  @Input() overShadow = null;
  @Input() center = false;
  _hideShadow = false;
  @Input() get hideShadow () { return this._hideShadow; };
  _hide = false;
  @Input() get hide () { return this._hide; };

  shouldShow = true;
  constructor() { }

  ngAfterViewInit() {
    setTimeout( () => {
      if (this.overShadow && this.shouldShow)
        this.doOvershadow();
    }, 200);
  }

  set hideShadow (value){
    this._hideShadow = value;
    if (value && this.overShadow)
      this.dropOvershadow();
    else
      this.doOvershadow();
  }

  set hide (value){
    this._hide = value;
    if (value)
      this.hideLoading();
    else
      this.showLoading();
  }

  doOvershadow(){
    const container = document.getElementById(this.overShadow);
    if (container)
      container.classList.add('overshadow');
  }

  dropOvershadow(){
    const container = document.getElementById(this.overShadow);
    if (container)
      container.classList.remove('overshadow');
  }

  showLoading(){
    this.shouldShow = true;
    setTimeout( () => { this.doOvershadow() }, 50);
  }

  hideLoading(){
    this.shouldShow = false;
    setTimeout( () => this.dropOvershadow() , 50);
  }

}
