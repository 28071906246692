import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { SetupWifiService } from '../setup-wifi.service';
import { MapMinComponent } from 'src/app/members/generate-video/map-min/map-min.component';
import { Subject, takeUntil } from 'rxjs';
import {LngLatBounds, Popup} from "maplibre-gl";
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { countryBounds, fixNumber, getLogbookAddress } from 'src/app/components/map-address-picker/util';
import { Keyboard } from '@capacitor/keyboard';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
@Component({
  selector: 'app-home-address-picker',
  templateUrl: './home-address-picker.component.html',
  styleUrls: ['./home-address-picker.component.scss']
})
export class HomeAddressPickerComponent implements OnInit {
  cmpDestroyed$ = new Subject();
  @Input() coords = { lat: 50.79279722739693, lng: 7.64152656277296 };
  @Input() markerTitle = '';
  @ViewChild('map') map: MapMinComponent;
  @Input() limitQueryByCountryBox = false;
  @Input() defaultType;

  type = null;
  markerName = 'marker' + new Date().getTime();
  primaryColor = '#f05123';
  newCoords = { lat: 0, lng: 0 };
  addressInput = '';
  countryBounds = null;
  mapLoaded:boolean=false;
  address = null;
  showList = false;
  workAddress = null;
  homeAddress = null;
  loadingAddress = false;

  constructor(
    public setupWifiService: SetupWifiService,
    private _translate: TranslateService,
    private authService: AuthenticationService,
    private platform: Platform,
    private apiService: ApiService,
    private appService: AppService
  ){}

  back(){
    this.setupWifiService.nav.pop()
  }

  async done(){
    this.setupWifiService.selectedCoords = { lat: this.newCoords.lat, lng: this.newCoords.lng };
    this.setupWifiService.saveWifiAddress(`${this.newCoords.lat}`, `${this.newCoords.lng}`);
    await this.setupWifiService.onSetupDone();
  }

  ngOnInit(): void {
    //this.map.centerTo(this.coords.lng, this.coords.lat, 18, 0.5);
    this.newCoords = Object.assign({}, this.coords);
  }

  CIRCLE_RADIUS = 100; //_in meters
  calculateRadius (zoom) {
    //_ radius = 100 => π * radius^2 => (π * 100^2)
    const constantArea = Math.PI * Math.pow(this.CIRCLE_RADIUS, 2);;
    const center = [this.coords.lng, this.coords.lat];
    const metersPerPixel = (40075016.686 * Math.cos(center[1] * Math.PI / 180)) / (512 * Math.pow(2, zoom)); // Meters per pixel
    const radius = Math.sqrt(constantArea / Math.PI) / metersPerPixel;
    return radius;
  }

  ionViewWillEnter() {
    this.map.mapLoaded.pipe(takeUntil(this.cmpDestroyed$)).subscribe((value)=>{
      // console.log("load isss workign", value);
      if(!value) return
      this.map.map?.resize();

      // console.log("map issssss : ", this.map);
      this.mapEvents();
      let zeroCoords = { lat: 0, lng: 0 };
      if (this.coords != zeroCoords) {
        const dir = this.getCountryBounds(this.coords);
        this.map.addLayerMarker(this.markerName, this.coords.lng, this.coords.lat, 'assets/images/setup-wifi/location-pin.svg', 0.4, this.markerTitle, null, 'viewport');
        this.map.centerTo(this.coords.lng, this.coords.lat, 18, 0.5);
      }
    })
  }

  setupMapCircleLayer () {
    this.map.circlesLayer['paint']['circle-opacity'] = 0.1;
    this.map.circlesLayer['source'] = 'markers';
    this.map.circlesLayer['paint']["circle-stroke-color"] = this.primaryColor;
    this.map.circlesLayer['paint']["circle-stroke-width"] = 3;
    this.map.reloadLayer('circlesLayer');

    this.map.markerLayer['layout']["text-anchor"] = 'left';
    this.map.markerLayer['layout']["text-offset"] = [1, 0];
    this.map.reloadLayer('markerLayer');

    //_ Lisent for zoom changes to fix the circle radius size
    let timerChangeCircleSize = null;
    this.map.map.on('zoom', () => {
      //_ Debounce time to not trigger for every little zoom factor changes
      clearTimeout(timerChangeCircleSize);
      timerChangeCircleSize = setTimeout(() => {
        const currentZoom = this.map.map.getZoom();
        if (this.map.map.getLayer('circles') && this.map.map.getSource('circles'))
          this.map.map.setPaintProperty('circles', 'circle-radius', this.calculateRadius(currentZoom));
      }, 0)

    });
  }

  //_
  //_ Functions for dragging feature
  //_
  clickAdress = false;
  isDragged = false;
  mapPopup = null;
  mapEvents() {
    const tc = this;

    //_ FOR DESKTOP/MOBILE DEVICES
    this.map.map?.on('mousedown', 'markers', startDragging);
    this.map.map?.on('touchstart', 'markers', startDragging);
    this.map.map?.on('click', 'markers', (feature) => {
      feature.preventDefault();

      this.mapPopup = new this.map.Popup({ offset: 25, className: 'new-map-popup' })
        .setLngLat([feature.lngLat.lng, feature.lngLat.lat])
        .setHTML(('<p class="ABC">' + this._translate.instant('mapAddressPicker.popupText') + '</p>'))
        .addTo(this.map.map);
    });



    //_ When start drawing, add mouse move and mouse up listeners
    function startDragging(e) {
      e.preventDefault();
      tc.isDragged = false;
      if (tc.mapPopup) tc.mapPopup.remove();
      let features = tc.map.map.queryRenderedFeatures(e.point);
      if (features.length > 0) {
        // console.log('MARKER NAME', { feature: features[0].properties, markerName: tc.markerName})
        if (features[0].properties.icon == tc.markerName) {
          tc.map.map.getCanvas().style.cursor = 'grab';
          tc.clickAdress = true;

          tc.map.map.on('mousemove', dragging);
          tc.map.map.on('touchmove', dragging);
          tc.map.map.on('touchend', endDragging);
          tc.map.map.on('mouseup', endDragging);
        }
      }
    }

    function dragging(e) {
      e.preventDefault();
      if (tc.clickAdress) {
        tc.isDragged = true;
        tc.newCoords.lat = fixNumber(e.lngLat.lat, 7);
        tc.newCoords.lng = fixNumber(e.lngLat.lng, 7);
        tc.map.moveMarker(tc.markerName, [tc.newCoords.lng, tc.newCoords.lat]);
        tc.map.map.getCanvas().style.cursor = 'grabbing';
      }
    }

    //_ When end drawing, delete mouse move and mouse up listeners
    function endDragging(e) {
      e.preventDefault();
      if (tc.clickAdress && tc.isDragged) {
        tc.map.map.getCanvas().style.cursor = 'default';
        tc.setupWifiService.saveWifiAddress(`${tc.newCoords.lat}`, `${tc.newCoords.lng}`)
        tc.updateAddressByLatLng(tc.newCoords);
      }

      tc.clickAdress = false;
      tc.map.map.off('mousemove', dragging);
      tc.map.map.off('touchmove', dragging);
      tc.map.map.off('mouseup', endDragging);
      tc.map.map.off('touchend', endDragging);
    }
  }

  hideKeyboard() {
    if (this.platform.is('cordova') || this.platform.is('capacitor')){
      Keyboard.hide();
    }
  }

  async updateAddressByLatLng(_coords) {
    this.loadingAddress = true;
    await this.authService.getStreetAdrressNew(_coords.lat, _coords.lng).then(res => {
      const response = res.success;
      if (response) {
        if (response.features.length > 0) {
          this.address = getLogbookAddress(response.features[0].properties).address;
        }
        else {
          this.address = "";
        }
      }
    });
    this.loadingAddress = false;
  }

  countryCode = null;
  async getCountryBounds(coords) {
    this.loadingAddress = true;
    await this.authService.getStreetAdrressNew(coords.lat, coords.lng).then(res => {
      const response = res['success'];
      if (response) {
        if (response.features.length > 0) {
          const res = response.features[0].properties;
          const address = getLogbookAddress(res).address;
          this.address = address;
          this.countryCode = res.countrycode;
          this.countryBounds = countryBounds()[res.countrycode] ? countryBounds()[res.countrycode] : null;
          this.mapLoaded = true;
        }
      }
    });
    this.loadingAddress = false;
  }

  addressList = [];
  searchAddress() {
    if (this.addressInput != '') {
      this.addressList = [];
      let bounds = '';
      if (this.countryBounds && this.limitQueryByCountryBox)
        bounds = '&bbox=' + this.countryBounds.join(',');

      this.apiService.searchAddress(this.addressInput + '&limit=100' + bounds).then(r => {
        if (r.success) {
          let features = r.success.features;
          if (this.countryCode)
            features = features.sort((a: any, b: any) => (b.properties.countrycode === this.countryCode ? 1 : 0) - (a.properties.countrycode === this.countryCode ? 1 : 0));

          features = features.slice(0, 20);
          this.addressList = features.map(f => {
            return {
              stringAddress: getLogbookAddress(f.properties).address,
              coordinates: f.geometry.coordinates, props: f.properties
            }
          });

          if (this.addressList.length == 0)
            this.appService.showToast('', this._translate.instant('toast.noAddressFound'), 3000, 'danger');
          else
            this.showList = true;

          if (!(this.newCoords?.lat ==  0 && this.newCoords?.lng == 0))
            this.address = this.addressInput;
        }
      }).catch(e => this.appService.showToast('', this._translate.instant('toast.errorSearchAddress'), 3000, 'danger'));
    }
    else
      this.addressList = [];
  }

  selectAddress(address) {
    this.showList = false;

    if(this.homeAddress && address.stringAddress == this.homeAddress['stringAddress'])
    {
      this.address = this.homeAddress['originalStringAddress']
    }
    else if(this.workAddress && address.stringAddress == this.workAddress['stringAddress'])
    {
      this.address = this.workAddress['originalStringAddress'];
    }
    else {
      this.address = address.stringAddress;
    }
    this.type = this.defaultType ? this.defaultType : address.props.type;
    this.coords = { lng: address.coordinates[0], lat: address.coordinates[1] };
    console.log('[DEBUG] SELECT ADDRESS FIRED', this.coords);
    const point: any = [this.coords.lng, this.coords.lat];
    this.newCoords = { lat: this.coords.lat, lng: this.coords.lng };
    this.setupWifiService.selectedCoords = { lat: this.coords.lat, lng: this.coords.lng };

    this.map.moveMarker(this.markerName, point);
    this.map.map.flyTo({
      center: point,
      zoom: 15
    });
    //this.map.flyTo(point[0], point[1], 15, 1);

    this.setupWifiService.saveWifiAddress(`${address.coordinates[1]}`, `${address.coordinates[0]}`)
  }
}
