<ion-header [translucent]="true">
  <ion-toolbar color="transparent">
    <ion-title style="font-size: 20px;">{{ 'devicesSetup.setupAssistantTitle' | translate}}</ion-title>
    <ion-buttons slot="end" >
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <!-- <ion-title>{{"devicesSetup.titleText"| translate}}</ion-title> -->
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" scrollY="false">

  <div class="content no-scrollbars">
  <div class="ion-text-center choose-device">
    <ion-img class="setup-icon" src="assets/images/devices-setup/select-device.png"></ion-img>
    <h2>
      {{ 'devicesSetup.chooseDevice' | translate }} {{ 'devicesSetup.yourDevice' | translate }}
    </h2>
  </div>

  <!-- Loading indicator -->
  <div class="ion-text-center" *ngIf="loadingDevices">
    <ion-spinner color="primary"></ion-spinner>
  </div>

  <!-- DEVICES LIST -->
  <ion-list class="item-list" lines="none" *ngIf="devices?.length > 0 && !loadingDevices" color="transparent">
    <ion-item class="device-item" [detail]="false" *ngFor="let device of devices" button (click)="openSetupGuide(device)">
      <ion-img
        src="{{ device | DeviceImage : appService.user.id | async }}"
        slot="start"
      ></ion-img>
      <ion-label>
        {{ device.name }}
        <p>{{ device.imei }}</p>
      </ion-label>

      <ion-icon slot="end" name="settings-outline" color="primary" *ngIf="!device.setup_done"></ion-icon>
      <ion-icon slot="end" name="checkmark-outline" color="success" *ngIf="device.setup_done"></ion-icon>
    </ion-item>
  </ion-list>

  <!-- Advice Message -->
  <ion-list class="advice-list" *ngIf="devices?.length > 0 && !loadingDevices && !showDoneButton" lines="none">
    <ion-item>
      <ion-label class="ion-text-wrap">
        <h5 style="font-size: 18px;">{{ 'devicesSetup.adviceForSetup' | translate }}</h5>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-icon color="primary" slot="start" name="list-outline" class="advice-icon"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'devicesSetup.desktopAdvice' | translate }}</ion-label>
    </ion-item>
    <ion-item>
      <ion-icon color="primary" slot="start" name="phone-portrait-outline" class="advice-icon"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'devicesSetup.mobilesAdvice' | translate }}</ion-label>
    </ion-item>
  </ion-list>

  <!-- SHOW DONE BUTTON -->
  <div class="ion-text-center" *ngIf="showDoneButton" style="margin-top: 30px;" @zoomIn >
    <ion-button class="ion-no-shadow" shape="round" color="primary" (click)="dismiss()">
      <ion-label>{{ 'devicesSetup.doneText' | translate }}</ion-label>
    </ion-button>
  </div>

</div>
</ion-content>
