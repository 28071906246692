import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage-angular";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { InterceptorService } from "./services/interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import {
  TranslateModule,
  TranslateLoader,
  TranslateCompiler,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PhraseAppCompiler } from 'ngx-translate-phraseapp'
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { LocationAccuracy } from "@awesome-cordova-plugins/location-accuracy/ngx";
import { BannerComponent } from "./members/notifications/banner/banner.component";
import { DetailsPageModule } from "./members/notifications/details/details.module";
// import { ListModalPageModule } from "./members/logbook/modal/list-modal/list-modal.module";
// import { MixedRoutePageModule } from "./members/logbook/modal/mixed-route/mixed-route.module";
// import { RouteFormPageModule } from "./members/logbook/modal/route-form/route-form.module";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { Platform } from "@angular/cdk/platform";
import { ScrollingModule } from "@angular/cdk/scrolling";

//_ Used for guide tour
// import { TourComponent } from "./components/tour/tour.component";
import { TourService } from "./components/tour/tour.service";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatDividerModule } from "@angular/material/divider";

//_ Extend dragable functions for the ngx-datatable
// import { DragulaModule, DragulaService } from "ng2-dragula";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { StorageService } from "./services/storage.service";

//_ Fix all ionic|angular components, pipes which needs locale
import { APP_BASE_HREF, CurrencyPipe, registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import localeFr from "@angular/common/locales/fr";
import localeDe from "@angular/common/locales/de";
import localeIt from "@angular/common/locales/it";
import localePl from "@angular/common/locales/pl";
import localePt from "@angular/common/locales/pt";
import localeGr from "@angular/common/locales/el";
import localeNl from "@angular/common/locales/nl";

registerLocaleData(localeEs, "es");
registerLocaleData(localeFr, "fr");
registerLocaleData(localeDe, "de");
registerLocaleData(localeIt, "it");
registerLocaleData(localePl, "pl");
registerLocaleData(localePt, "pt");
registerLocaleData(localeGr, "el");
registerLocaleData(localeNl, "nl");

//_ _________________________________________________________

import { Drivers } from "@ionic/storage";

// import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { initializeApp } from "firebase/app";

import { PwaUpdateService } from "./services/pwa-update.service";
import { DecimalPipe } from "@angular/common";
import {
  BaseHrefFactory,
} from "./providers/base_factory";
import { KeyboardTrickService } from "./services/keyboard-trick.service";
import { ResizeService } from "./services/resize.service";
import { enterAnimation } from "./animations/nav-animation";
import { PajSizeDetectorModule } from "./components/size-detector/paj-size-detector.module";
import { PajTourModule } from "./components/tour/paj-tour.module";
import { PajServerAlertModule } from "./components/server-alert/paj-server-alert.module";
import { PajServerCustomAlertModule } from "./components/server-alert/custom-alert/paj-server-custom-alert.module";
import { PajSidebarModule } from "./components/side-bar/paj-sidebar.module";
import { PwaUpdateComponent } from "./components/pwa-update/pwa-update.component";
import { PajRequestPermissionModule } from "./components/request-permissions/paj-request-permission.module";
import { PajDaterangeModule } from "./components/date-range/paj-daterange.module";
import { PajTimerCountModule } from "./members/generate-video/timer-count/paj-timer-count.module";
import { OptionsPopoverComponent } from "./shared/options-popover/options-popover.component";
import { PajDeviceSettingsTabModule } from "./components/device-settings-tab/paj-device-settings-tab.module";
import { MapPage } from "./members/map/map.page.new";
import { ApiErrorPopupModule } from "./components/api-error-popup/api-error.popup.module";
import { SurveyPopupComponent } from "./components/survey/survey-popup/survey-popup.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslatedMatPaginator } from "./providers/translated-mat-pagination";
import { PetModule } from "./petmode/modules/pet.module";
import { PajLoadingIndicatorModule } from "./components/loading-indicator/paj-loading-indicator.module";
import { StartupService } from "./services/startup.service";
import { StartupLoadingModule } from "./components/startup-loading/startup-loading.module";
import { PasswordResetAdviceModule } from "./members/password-reset-advice/password-reset-advice.module";
import { PlatformUtils } from "./services/plugins/platform-utils";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translations/", ".json");
}

initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    PwaUpdateComponent,
    OptionsPopoverComponent,
    SurveyPopupComponent,
    // TourComponent,
    // ServerAlertComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      animated: true,
      navAnimation: enterAnimation,
      innerHTMLTemplatesEnabled: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: PhraseAppCompiler,
      },
    }),
    // SharedModule,
    AppRoutingModule,
    IonicStorageModule.forRoot({
      //name: 'finder-portal-v2'
      //driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
      driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB],
    }),
    HttpClientModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    // FontAwesomeModule,
    ReactiveFormsModule,
    // DragulaModule,
    DragDropModule,
    DetailsPageModule,
    FormsModule,
    BrowserAnimationsModule,
    // MixedRoutePageModule,
    // RouteFormPageModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatMenuModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    // ListModalPageModule,
    ImageCropperModule,
    NgxDatatableModule,
    ScrollingModule,
    HammerModule,
    // CompaniesMapPagePageModule,
    PajSizeDetectorModule,
    PajTourModule,
    PajServerAlertModule,
    PajServerCustomAlertModule,
    PajSidebarModule,
    PajRequestPermissionModule,
    PajDaterangeModule,
    PajTimerCountModule,
    PajDeviceSettingsTabModule,
    ApiErrorPopupModule,
    PetModule,
    StartupLoadingModule,
    PasswordResetAdviceModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StartupService,
    AndroidPermissions,
    LocationAccuracy,
    Platform,
    TourService,
    // DragulaService,
    StorageService,
    PwaUpdateService,
    DecimalPipe,
    CurrencyPipe,
    KeyboardTrickService,
    ResizeService,
    MapPage,
    PajLoadingIndicatorModule,
    // { provide: APP_INITIALIZER, useFactory: AppInitializerFactory, deps: [StorageService], multi: true },
    { provide: APP_BASE_HREF, useFactory: BaseHrefFactory },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MatPaginatorIntl, useClass: TranslatedMatPaginator }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    PlatformUtils.initialize();
  }
}
