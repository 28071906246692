import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild, Input, Renderer2 } from '@angular/core';
import { Keyboard } from "@capacitor/keyboard";
import { IonNav, Platform } from '@ionic/angular';
import { CupertinoPane } from 'cupertino-pane';
import { AppService } from 'src/app/app.service';
// import { DeviceListComponent } from '../device-sidebar/device-list/device-list.component';
import { DevicesSidebarService } from '../devices-sidebar/devices-sidebar.service';
import { DevicesListHomeComponent } from '../devices-sidebar/home/home.component';
import { NewPullupService } from './pullup.service';
import { Capacitor } from "@capacitor/core";
import { Constants } from 'src/app/constants.enum';
import {DeviceItemComponent} from 'src/app/members/map/devices-sidebar/device-item/device-item.component'
import { MapService } from '../components/map/map.service';
import {
  SelectDeviceComponent
} from "../devices-sidebar/device-item/device-submenu/select-device/select-device.component";
import { SafeArea } from 'capacitor-plugin-safe-area';

@Component({
  selector: "app-new-pullup",
  templateUrl: "./new-pullup.component.html",
  styleUrls: ["./new-pullup.component.scss"],
})
export class NewPullupComponent implements OnInit, OnDestroy {
  @ViewChild("nav", { static: false }) nav: IonNav;
  @Output() public onBreakPointChanged = new EventEmitter<string>();
  @Output() public onViewInit = new EventEmitter<number>();
  drawer: CupertinoPane = null;
  currentBreak: any = "middle";
  lastBreakChanged = "middle";
  sliderHeight = 130;
  settings = {};

  breakPoints = {
    top: this.platform.height(),
    middle: 350,
    bottom: 150 ,
  };

  observables = {};
  lastInputFocus = null;
  @Input('openPullup') get openPullup  () { return this._openPullup; };
  _openPullup = false;
  rootComponent: any;
  componentProps: any;
  drawerIsDragging = false;
  isDraggingTimer = null;

  set openPullup(value) {

    //_ Needs fix map bottom controls to not follow the menu when is hidden
    // this._openPullup = value;
    // console.log('OPEN MENU', value);
    // if (this.drawer) {
    //   if (value && this.drawer.isHidden())
    //     this.drawer.present();
    //   else if(!this.drawer.isHidden())
    //     this.drawer.hide();
    //}
  }

  constructor(
    private appService: AppService,
    private platform: Platform,
    private devicesSidebarService: DevicesSidebarService,
    private drawerService: NewPullupService,
    private mapService: MapService,
    private renderer: Renderer2
    //private storage: Storage,
  ) { }

  ngOnInit() {
    // this.breakPoints.top = this.breakPoints.top - +this.getStylePropertie('--ion-safe-area-top').replace('px', '');
    this.observables['enableDragable'] = this.devicesSidebarService.enableDragPullup.subscribe(isEnable => {
      //_ Enable disable drag function when devices list is on top
      if (this.drawer)
        isEnable ? this.drawer.enableDrag() : this.drawer.disableDrag();
    });

    this.observables["setBreakpointListener"] =
      this.drawerService.setBreakPoint.subscribe((newBreak) => {
        if (this.drawer) this.moveToBreak(newBreak);
      });
    // this.observables['deviceMenu'] = this.appService.deviceMenuisOpen.subscribe( status => {
    //   if (!status){
    //     let scrollOpen = this.scrollSliderExist();
    //     const newStyle = + (scrollOpen ? this.sliderHeight + 15 : 15) + "px !important";
    //     // this.setStyle("map-fab-menu", newStyle);
    //     // this.setStyle('countdown', newStyle);
    //     // this.setStyle('icon_slider', 'bottom: 80px !important');
    //   }
    // });

    // console.log('going to device ID', this._openDevice);
  }

  async ngAfterViewInit() {
    // this.nav.setRoot(DeviceListComponent, {
    this.devicesSidebarService.navigation = this.nav;

    if (!this.appService.devices)
      this.observables['userDevices'] = this.appService.devices$.subscribe(res => this.checkDevicesLength(res));
    else
      this.checkDevicesLength(this.appService.devices);

    //_ Fix dragable header changed on navigate throw the nav element
    this.observables['dragableHeader'] = this.devicesSidebarService.dragableHeader.subscribe(async (r) => {
      if (r) {
        if (this.drawer) {
          // this.drawer.settings.initialBreak = this.currentBreak;
          try { this.drawer.events.resetEvents() } catch (e) {};
          //_ Click dragable div section to pullup the menu
          this.onClickDragDiv();
        }
      }
    });

    //ios:
    // await SafeArea.getSafeAreaInsets().then(({ insets }) => {
    //   this.breakPoints.top = this.breakPoints.top - insets.top;
    // });

    //android:
    // SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
    //   this.breakPoints.top = this.breakPoints.top - statusBarHeight;
    // });

    await this.initPanel();

    if (this.devicesSidebarService.selectedDevice.value != null)
      this.onViewInit.emit(this.devicesSidebarService.selectedDevice.value);

    // console.log('going to device ID', this.openDeviceID);

    // if(this.openDeviceID){
    //   this.openDevice(this.openDeviceID);
    // }

    this.checkSafeAreaTop();
  }

  checkDevicesLength (devices) {
    console.log('[DEBUG] CHECK DEVICES', devices.length);
    if (devices.length === 1) {
      //_ Ask to Cyrille why/what fix this code, because is causing double init of the home->list components and flows…
      //await this.setRoot(DeviceItemComponent);
      this.setRootIfNotWithDelay(DeviceItemComponent);
    } else {
      //_ Ask to Cyrille why/what fix this code, because is causing double init of the home->list components and flows…
      //await this.setRoot(DevicesListHomeComponent);
      this.setRootIfNotWithDelay(DevicesListHomeComponent)
    }
  }

  async setRoot(component: any) {
    let componentProps: any;
    if (component == DevicesListHomeComponent) {
      componentProps = {
        navigation: this.nav,
        isPullup: true,
        data: this.appService.devices,
        user_id: this.appService.user.id,
      };
    } else if (component == DeviceItemComponent) {
      let item = this.appService.devices[0]
      componentProps = {
        navigation: this.nav,
        isPullup: true,
        itemList: {...item, device_id:item.id},
        user_id: this.appService.user.id,
      };
    }
    return await this.nav.setRoot(component, componentProps);
  }

  setRootIfNotWithDelay(component) {
    setTimeout(async () => {
      this.nav.getActive().then(async (r) => {
        if (!r) await this.setRoot(component);
      })
    }, 300);
  }

  async initPanel() {
    const initialBreak = "middle";
    this.settings = {
      parentElement: '.tabs-inner',
      // CupertinoSettings
      // dragBy: ['.draggable-header', '.draggable-header-item', '.draggable-header-filters'],
      clickBottomOpen: false,
      showDraggable: false, //_ Dragable div on top of header
      draggableOver: true,
      buttonClose: false,
      buttonDestroy: false,
      // topperOverflow: true,
      animationDuration: 195,
      followerElement: "#map-bottom-controls",
      initialBreak,
      lowerThanBottom: false,
      topperOverflow: true,
      handleKeyboard: true,
      bottomClose: true,
      breaks: {
        top: {
          // Topper point that pane can reach
          enabled: true, // Enable or disable breakpoint
          height: this.breakPoints.top, // Pane breakpoint height
          bounce: true, // Bounce pane on transition
        },
        middle: {
          enabled: true,
          height: this.breakPoints.middle,
          bounce: true,
        },
        bottom: {
          enabled: true,
          height: this.breakPoints.bottom,
          bounce: true,
        },
      },
    };

    this.drawer = new CupertinoPane(".pullup-menu-content", this.settings);
    // this.drawer.on('onTransitionEnd', () => this.breakPointReached() );

    //_ Fix TrasitionEnd event when is not triggered by default from CP emiter.
    let delayDragEnd = null;
    this.drawer.on("onDragEnd", (obj) => {
      if (delayDragEnd) clearTimeout(delayDragEnd);
      delayDragEnd = setTimeout(() => {
        this.breakPointReached();
        this.drawerService.onDragging.next(false);
        this.drawerService.endDragging.next(true);

        //_ Stop swap dragging value
        this.stopSwapDraggingValue();
        this.drawerIsDragging = false;
      }, 100);
    });

    this.drawer.on("onDragStart", (obj) => {
      this.drawerService.startDragging.next(true);
    });

    this.drawer.on("onDrag", (obj) => {
      this.stopSwapDraggingValue();
      this.isDraggingTimer = setTimeout(() => this.drawerIsDragging = true, 300);
      this.drawerService.onDragging.next(true);
    });

    this.drawer.on("onDidDismiss", (obj) => {
      this.drawerService.onDidDismiss.next(true);
      this.drawerService.currentBreakPoint.next('middle')
    });

    await this.drawer.present({ animate: true });

    //_ Set first breakpoint
    // this.drawerService.breakPointChanged.next(this.currentBreak);

    //_ Fix issue only for ios
    if (this.platform.is('ios') && !this.platform.is('mobileweb'))
      this.fixPullupOnKeyboardOpen();

    this.moveLayoutFix(initialBreak);
    // this.resizeNavContent(initialBreak);
    this.lisentForInputFocus();
    this.onClickDragDiv();
  }

  //_ Fix keyboard issue pulling up whole panel; showing a white space on bottom of the panel
  fixPullupOnKeyboardOpen() {
    this.pane = <HTMLElement>document.querySelector(".pane");
    let initPos;
    if (Capacitor.isNativePlatform()) {
      // Keyboard.addListener("keyboardWillShow", this.onKeyboardWillShow.bind(this));
      // Keyboard.addListener("keyboardWillHide", this.keyboardWillHide.bind(this));
    }

  }

  initPos = null;
  pane = null;
  onKeyboardWillShow(r: any){
    // console.log("keyboard will show - method 2", r);
    //_ In case just change focus to other input, back the panel too previous translateY location
    if (this.initPos)
      this.pane.style.transform = `translateY(${this.initPos}px) translateZ(0px)`;

    this.initPos = this.getPaneTransformY();
    this.lastInputFocus = document.activeElement || null;
    //_ if the input element will be behindof the keyboard, then translateY the panel according to the keyboard height
    // console.log('IS IN VIW PORT', this.willOverlapTheKeyboard(this.lastInputFocus, r.keyboardHeight));
    if (this.lastInputFocus) {
      if (!this.willOverlapTheKeyboard(this.lastInputFocus, r.keyboardHeight))
        this.pane.style.transform = `translateY(${this.getPaneTransformY() - r.keyboardHeight
          }px) translateZ(0px)`;
    }

    // this.setStyle('inner-center-btn', 'display: none');
  }

  keyboardWillHide(){
    // console.log("keyboard will hide");
    if (this.pane)
      this.pane.style.transform = `translateY(${this.initPos}px) translateZ(0px)`;
    this.moveLayoutFix(this.currentBreak);

    // setTimeout( () => this.setStyle('inner-center-btn', 'display: block'), 100);
  }

  //_ Pullup to top the menu if some input got focus
  lisentForInputFocus() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener("keyboardWillShow", (r: any) => {
          // console.log("keyboard WILL show Listen Input:", r);
          // console.log('ACTIVE ELEMENT', document.activeElement);
          //_ Capacitor Keyboard plugins missed input source focused
          this.lastInputFocus = document.activeElement || null;

          if (
            this.lastInputFocus.closest("app-new-pullup") &&
            this.currentBreak != "top"
          )
            this.moveToBreak("top");
        }
      );
      Keyboard.addListener("keyboardDidHide", () => {
        // console.log("keyboard did hide");
      }
    );
    }
  }

  moveToBreak(breakName) {
    this.drawer.moveToBreak(breakName);
    //_ wait until moveToBreak animation ends
    setTimeout(() => this.breakPointReached(), 400);
  }

  minimize() {
    this.drawer.moveToBreak("bottom");
    setTimeout(() => this.breakPointReached(), 400);
  }

  breakPointReached() {
    if (this.currentBreak === this.drawer.currentBreak()) return;
    this.currentBreak = this.drawer.currentBreak();
    this.drawerService.currentBreakPoint.next(this.currentBreak);
    this.lastBreakChanged = this.drawer.currentBreak();

    //_ Emit event when break point is reached
    this.onBreakPointChanged.emit(this.drawer.currentBreak());
    this.drawerService.breakPointChanged.next(this.drawer.currentBreak());

    //_ Change class for fullscreen
    this.fixMapToolbar(this.currentBreak);
    //_ Resize nav to fit the content and pullup breakpoint height
    // this.resizeNavContent(this.drawer.currentBreak());

    if (this.drawer.currentBreak() == 'bottom' || this.drawer.currentBreak() == 'middle') {
      // this.moveLayoutFix(this.drawer.currentBreak());
    }

    if (this.drawer.currentBreak() == 'top') {
      this.applyHeaderColor();
    }

    this.drawer.enableDrag();
  }

  //Move Fab buttons and countdown
  moveLayoutFix(breakName, scrollOpen = null) {
    return;
  }

  //Use to change style of a class
  setStyle(className, style) {
    let button = document.querySelector("." + className);
    if (button) button.setAttribute("style", style);
  }

  moveSubFabMenu() {
    const markersMenu = document.getElementsByClassName("markers-menu")[0];
    const parent = document
      .querySelector("#mapAlarmMarker")
      .getBoundingClientRect();
    if (markersMenu)
      markersMenu.setAttribute("style", "top: " + parent.top + "px");
  }

  scrollSliderExist() {
    return document.querySelector(".icon_slider");
  }

  fixMapToolbar(breakName) {
    const paneElement = document.querySelector('.pane') as HTMLElement || null;
    if(paneElement){
      paneElement.style.borderRadius = '';
      if (breakName == 'top') {
        this.drawer.contentEl.closest('.pane')?.classList.add('border-fullscreen');
        //this.setStyle('map-page-toolbar', '--background: var(--ion-color-dark)');
        /* setTimeout(() => {
          paneElement.style.borderRadius = '0';
        }, 1000); */
        paneElement.style.borderRadius = '0';
      } else {
        this.drawer.contentEl.closest('.pane')?.classList.remove('border-fullscreen');
        this.setStyle('map-page-toolbar', '--background: transparent');
        setTimeout(() => {
          paneElement.style.borderRadius = '25px 25px 0px 0px';
        }, 1000);
      }
    }
  }

  resizeNavContent(breakName) {
    if (this.nav && breakName != "bottom") {
      this.setStyle(
        "pullup-menu-content",
        "height: " + this.breakPoints[breakName] + "px"
      );
    }
  }

  ngOnDestroy() {
    this.drawer.destroy();
    Object.keys(this.observables).forEach((k) =>
      this.observables[k].unsubscribe()
    );
    this.setStyle("map-page-toolbar", "--background: transparent");
    this.setStyle("main-map-controls", "");
    if (Capacitor.isNativePlatform()) {
      Keyboard.removeAllListeners();
    }
    document.querySelector('.pullup-menu-content')?.remove();
  }

  //_ Drag divs onClick -> slideUp menu
  onClickDragDiv() {
    let dragDivs = document.getElementsByClassName('dragable-div');
    const onClick = (e) => this.moveToBreak('top');
    if (dragDivs.length > 0) {
      Array.from(dragDivs).forEach(el => {
        el.removeEventListener('click', onClick);
        el.addEventListener('click', onClick);
      })
    }
  }

  getPaneTransformY = (): number => {
    const translateYRegex = /\.*translateY\((.*)px\)/i;
    return parseFloat(
      translateYRegex.exec(
        (<any>document.querySelector(".pane")).style.transform
      )[1]
    );
  };

  willOverlapTheKeyboard(element, keyboardHeight) {
    //_ Remove the transform style to get the right location of the element
    element.style.transform = 'none';
    const bounding = element.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - keyboardHeight;
    return bounding.top >= 0 && bounding.bottom <= windowHeight;
  }

  getStylePropertie(name){
    let bodyStyles = window.getComputedStyle(document.body);
    return bodyStyles.getPropertyValue(name);
  }

  openDevice(deviceID){
      const  navigateTo = async (deviceId) => {
        //await this.storage.set(Constants.SELECTED_DEVICE, deviceId);
        let item = { device_id: deviceId, deviceshow: true };
        const itemData = (itemList) => { item = itemList; }
        this.devicesSidebarService.navigation.push(DeviceItemComponent, { itemList: item, callBack: itemData });
      }
      navigateTo(deviceID);
  }

  toggleBreakpoint(ev) {
    if (this.currentBreak == 'top')
      this.drawerService.setBreakPoint.next('middle');
    else
      this.drawerService.setBreakPoint.next('top');
  }


  stopSwapDraggingValue () {
    if (this.isDraggingTimer)
      clearInterval(this.isDraggingTimer);
  }

  applyHeaderColor () {
    const ionNav = this.drawer.paneEl.querySelector('ion-nav');

    if (ionNav) {
      const pages = Array.from(ionNav.querySelectorAll('.ion-page'));
      const lastPage = pages[pages.length - 1];

      if (lastPage) {
        const ionHeader = lastPage.querySelector('ion-header');

        if (ionHeader) {
          let headerBackgroundColor = window.getComputedStyle(ionHeader).backgroundColor;

          if (headerBackgroundColor == "rgba(0, 0, 0, 0)")
            headerBackgroundColor = "var(--ion-color-base-background)";

          if (this.drawer.paneEl) {
              this.drawer.paneEl.style.backgroundColor = headerBackgroundColor;
          }
        }
      }
    }
  }

  navChanged (ev) {
    this.applyHeaderColor();
  }

  haveNotch = false;
  checkSafeAreaTop () {
    const safeAreaTop = getComputedStyle(document.documentElement).getPropertyValue('--ion-safe-area-top').trim();
    console.warn('[DEBUG] SAFE AREA TOP', {safeAreaTop, platform: this.platform.platforms })
    const safeAreaTopValue = parseFloat(safeAreaTop);

    if (safeAreaTopValue > 0) {
      this.haveNotch = true;
    }
  }
}
