import { Component, Input, OnInit, inject } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-camera-options',
  templateUrl: './camera-options.component.html',
  styleUrls: ['./camera-options.component.scss']
})
export class CameraOptionsComponent implements OnInit {
  protected readonly popoverCtrl: PopoverController = inject(PopoverController);
  @Input() selectedItem = 'center';
  ngOnInit(): void {

  }

  selectItem(value) {
    this.selectedItem = value;
    this.popoverCtrl.dismiss(this.selectedItem);
  }
}
