//_ FUTURISTIC MODELS
export const Car1Model = {
    obj: 'assets/device_markers/models/car1/scene.gltf',
    type: 'gltf',
    scale: 0.0015,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: 0, z: 0  }, //default rotation
    name: 'car1',
    img: 'assets/images/threed-picker/car1.png'
}

export const TeslaModel = {
    obj: 'assets/device_markers/models/model3/scene.gltf',
    type: 'gltf',
    scale: 0.0009,
    anchor: 'center',
    color: { r: 1, g: 1, b: 1 },
    rotation: { x: 90, y: 0, z: 0  }, //default rotation
    name: 'car2',
    img: 'assets/images/threed-picker/car2.png'
    //rotation: { x: 90, y: -feature.properties.lastPoint.direction, z: 0  }, //default rotation
}

export const Toyota1Model = {
    obj: 'assets/device_markers/models/toyota1/scene.gltf',
    type: 'gltf',
    scale: 0.15,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: 0, z: 0  }, //default rotation
    name: 'car3',
    img: 'assets/images/threed-picker/car3.png'
}

export const Citroen1Model = {
    // obj: 'assets/device_markers/models/citroen1/scene.gltf',
    // type: 'gltf',
    // scale: 5,
    // anchor: 'center',
    // color: { r: 1, g: 0, b: 0},
    // rotation: { x: 90, y: 0, z: 0  }, //default rotation
}

export const Mercedes1Model = {
    obj: 'assets/device_markers/models/mercedes1/scene.gltf',
    type: 'gltf',
    scale: 0.0225,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: 180, z: 0  }, //default rotation
    name: 'car4',
    img: 'assets/images/threed-picker/car4.png'
}

export const MazdaBtModel = {
    obj: 'assets/device_markers/models/mazda-bt/scene.gltf',
    type: 'gltf',
    scale: 0.0675,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: 0, z: 0  }, //default rotation
    name: 'truck1',
    img: 'assets/images/threed-picker/truck1.png'
}

export const ToyotaHiluxModel = {
    obj: 'assets/device_markers/models/toyota-hilux/scene.gltf',
    type: 'gltf',
    scale: 1.125,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: 180, z: 0  }, //default rotation
    name: 'truck2',
    img: 'assets/images/threed-picker/truck2.png'
}

export const FordRaptorModel = {
    obj: 'assets/device_markers/models/ford-raptor/scene.gltf',
    type: 'gltf',
    scale: 0.00675,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: 0, z: 0  }, //default rotation
    name: 'truck3',
    img: 'assets/images/threed-picker/truck3.png'
}

export const FordRangeModel = {
    // obj: 'assets/device_markers/models/ford-ranger/scene.gltf',
    // type: 'gltf',
    // scale: 0.01,
    // units: 'scene', //_ meters | scene
    // anchor: 'center',
    // color: { r: 1, g: 0, b: 0},
    // rotation: { x: 90, y: 180, z: 0  }, //default rotation
}

export const Truck1Model = {
    obj: 'assets/device_markers/models/truck1/scene.gltf',
    type: 'gltf',
    scale: 0.003375,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: -90, z: 0  }, //default rotation
    name: 'truck4',
    img: 'assets/images/threed-picker/truck4.png'
}

export const Truck2Model = {
    obj: 'assets/device_markers/models/truck2/scene.gltf',
    type: 'gltf',
    scale: 0.135,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: 0, z: 0  }, //default rotation
    name: 'truck5',
    img: 'assets/images/threed-picker/truck5.png'
}

export const Van1Model = {
    obj: 'assets/device_markers/models/van1/scene.gltf',
    type: 'gltf',
    scale: 0.15,
    anchor: 'center',
    color: { r: 1, g: 0, b: 0},
    rotation: { x: 90, y: 0, z: 0  }, //default rotation
    name: 'van1',
    img: 'assets/images/threed-picker/van1.png'
}


export const ThreedModels = [
    Car1Model, TeslaModel, Toyota1Model,
    Mercedes1Model, MazdaBtModel, ToyotaHiluxModel,
    FordRaptorModel, Van1Model, Truck1Model, Truck2Model
]
