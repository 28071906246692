export const notificationsImages = [
    '', //0
    'assets/images/alert_shock(1).svg', // 1
    'assets/images/Alert_Battery(1).svg', // 2
    'assets/images/alert_radius_v1.svg', // 3
    'assets/images/alert_sos.svg', // 4
    'assets/images/speed.svg', // 5
    'assets/images/alert_powercut(1).svg', // 6
    'assets/images/ignition-alert.svg', // 7
    '', // 8
    'assets/images/alert_Drop-Alarm.svg', // 9
    'assets/images/map/popup/entering.svg', // 10
    'assets/images/map/popup/leaving.svg', // 11
    'assets/images/alert_falldown', // 12
    'assets/images/volt.svg', // 13
    'assets/images/powerOff.svg', // 14
    '',//15
    '',//16
    '',//17
    '',//18
    '',//19
    '',//20
    '',//21
    'assets/images/powerOff.svg',
];

//This is an ARRAY!!!!! Don't change the Order! e.g. id 3 must ALWAYS be at the array index 3 !!!
export const translationsKeys: AlarmData[] = [
    { id: 0, title: '', description: '' }, //_ 0 Non index position for notification type
    { id: 1, title: 'notifications.shockAlert', description: 'notifications.shockAlertDescription', img: 'assets/images/alert_shock(1).svg' }, // alarm_erschuetterung
    { id: 2, title: 'notifications.batteryAlert', description: 'notifications.batteryAlertDescription', img: 'assets/images/Alert_Battery(1).svg' }, // alarm_batteriestand
    { id: 3, title: 'notifications.leaveAreaAlert', description: 'notifications.leaveAreaAlertDescription', img: 'assets/images/alert_radius_v1.svg' }, // v1 geofence alert
    { id: 4, title: 'notifications.sosAlert', description: 'notifications.sosAlertDescription', img: 'assets/images/alert_sos.svg' }, // alarm_sos
    { id: 5, title: 'notifications.speedAlert', description: 'notifications.speedAlertDescription', img: 'assets/images/speed.svg' }, // alarm_geschwindigkeit
    { id: 6, title: 'notifications.powerAlert', description: 'notifications.powerAlertDescription', img: 'assets/images/alert_powercut(1).svg' }, // alarm_stromunterbrechung
    { id: 7, title: 'notifications.ignitionAlert', description: 'notifications.ignitionAlert', img: 'assets/images/ignition-alert.svg' }, // alarm_zuendalarm
    { id: 8, title: '', description: '' },
    { id: 9, title: 'notifications.dropAlert', description: 'notifications.dropAlertDescription', img: 'assets/images/alert_Drop-Alarm.svg' }, //alarm_drop
    { id: 10, title: 'notifications.enterGeofence', description: 'notifications.enterGeofenceDescription', img: 'assets/images/map/popup/entering.svg' },
    { id: 11, title: 'notifications.leaveGeofence', description: 'notifications.leaveGeofenceDescription', img: 'assets/images/map/popup/leaving.svg' },
    { id: 12, title: 'notifications.falldown', description: 'notifications.falldownDescription', img: 'assets/images/alert_falldown.svg' }, // No Entry In DB
    { id: 13, title: 'notifications.voltageAlert', description: 'notifications.voltageAlertDescription', img: 'assets/images/volt.svg' }, // alarm_volt
    // { id: 14, title: 'notifications.deviceTurnOffAlert', description: 'notifications.deviceTurnOffAlertDescription', img: 'assets/images/powerOff.svg' },
    { id: 21, title: 'notifications.voltageAlert', description: 'notifications.voltageAlertDescription', img: 'assets/images/volt.svg' }, // No Entry In DB
    { id: 22, title: 'notifications.deviceTurnOffAlert', description: 'notifications.deviceTurnOffAlertDescription', img: 'assets/images/powerOff.svg' }, // alarm_turn_off
];

//_ Images for map markers
export const notificationsMarkers = [
    { id: 1, image: 'assets/images/map/markers/shock-alert-marker.svg', },
    { id: 2, image: 'assets/images/map/markers/battery-alert-marker.svg' },
    { id: 3, image: 'assets/images/map/markers/radius-alert-marker_v1.svg' },
    { id: 4, image: 'assets/images/map/markers/sos-alert-marker.svg' },
    { id: 5, image: 'assets/images/map/markers/speed-alert-marker.svg' },
    { id: 6, image:'assets/images/map/markers/powercut-alert-marker.svg' },
    { id: 7, image:'assets/images/map/markers/ignition-alert-marker.svg' },
    { id: 9, image:'assets/images/map/markers/drop-alert-marker.svg' },
    { id: 10, image: 'assets/images/map/markers/enter-geofence-alert-marker.svg' }, // 10
    { id: 11, image: 'assets/images/map/markers/out-geofence-alert-marker.svg' }, // 11
    { id: 12, image: 'assets/images/map/markers/fall-alert-marker.svg' }, // 12
    { id: 13, image: 'assets/images/map/markers/volt.svg' }, // 13
    // { id: 14, image: 'assets/images/powerOff.svg' }, // 14
    { id: 22, image: 'assets/images/map/markers/power-off-marker.svg' }, // 22
];

export interface AlarmData {
    id: number, title: string, description: string, img?: string 
}
