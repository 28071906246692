import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { generateColors } from './util';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-note',
  templateUrl: './device-note.component.html',
  styleUrls: ['./device-note.component.scss'],
})
export class DeviceNoteComponent implements OnInit, OnDestroy {
  @Input() note;
  @Input() note_color;
  @Input() deviceId;
  @Input() onUpdate = null;
  @Input() _id = null;

  isEditing = false;

  colors = null;
  selectedColor = null;
  noteForm: FormGroup;
  noteLength = 255;
  subscriber = null;
  constructor(private modalCtrl: ModalController, private formBuilder: FormBuilder,
    private appService: AppService, private authService: AuthenticationService,
    public _translate: TranslateService, private api: ApiService) { }

  ngOnInit() {
    this.isEditing = !this.note;
    this.createForm();
    // this.loadColors();
  }

  //_ Reset and create form
  createForm() {
    this.noteForm = this.formBuilder.group({
      note: [this.note, [Validators.required, Validators.maxLength(255), Validators.minLength(1)]],
      note_color: [this.note_color, [Validators.required, Validators.maxLength(10)]],
      id: [this.deviceId, Validators.required],
      _id: [this._id]
    });

    if (this.subscriber)
      this.subscriber.unsubscribe();

    if (this.note)
      this.noteLength = 255 - this.note.length;

    //_ Detect changes to count the string length
    this.subscriber = this.noteForm.get('note').valueChanges.subscribe(newValue => {
      this.noteLength = 255 - newValue.length;
    });

    this.loadColors();
  }

  loadColors() {
    this.colors = generateColors();
    if (this.note_color) {
      this.selectedColor = this.note_color;
    } else
      this.selectedColor = this.colors[4];
  }

  changeColor(c) {
    // if (this.isEditing)
    this.selectedColor = c;
  }

  async dismiss() {
    this.noteForm.patchValue({ note_color: this.selectedColor });

    if (this.noteForm.get('note').value != this.note || this.selectedColor != this.note_color) {
      if (this.noteForm.get('note').value != '' && this.noteForm.get('note').value != null) {
        //_ Ask before save
        if (await this.appService.confirmDialogPopup(this._translate.instant('deviceNote.saveChanges'), this._translate.instant('deviceNote.doYouWantToSave'))) {
          //_ Save note for Tracker Point
          if (this._id) {
            await this.api.addTrackerpointNote(this.deviceId, this.noteForm.value).then(r => {
              this.appService.showToast('', this._translate.instant('deviceNote.noteSavedSuccess'), 2000, 'success');
              this.afterSave();
            }).catch(error => {
              console.log("ERROR: ", error);
              this.appService.showToast('', this._translate.instant('deviceNote.errorSavingNote'), 3000, 'danger')
            });
          }
          else {
            //_ Save note for the Device
            await this.authService.updateDevice(this.deviceId, this.noteForm.value).then(r => {
              this.appService.showToast('', this._translate.instant('deviceNote.noteSavedSuccess'), 2000, 'success');
              this.afterSave();
            }).catch(error => {
              console.log("ERROR: ", error);
              this.appService.showToast('', this._translate.instant('deviceNote.errorSavingNote'), 3000, 'danger')
            });
          }
        }
      }
    }

    this.modalCtrl.dismiss(null);
  }

  afterSave() {
    this.isEditing = false;
    this.note = this.noteForm.get('note').value;
    this.note_color = this.noteForm.get('note_color').value;
    this.onUpdate({ note: this.note, note_color: this.note_color });
  }

  async cancel() {
    if (this.noteForm.get('note').value != this.note || this.selectedColor != this.note_color) {
      if (await this.appService.confirmDialog(this._translate.instant('deviceNote.confirmChanges'), this._translate.instant('deviceNote.discardChanges'))) {
        this.isEditing = false;
        this.createForm();
      }
    }
    else
      this.isEditing = false;
  }

  async deleteNote() {
    if (await this.appService.confirmDialogPopup(this._translate.instant('deviceNote.confirmDeleteNote'), this._translate.instant('deviceNote.areYouSureToDeleteNote'))) {
      //_ Delete note of the tracker point
      if (this._id) {
        await this.api.addTrackerpointNote(this.deviceId, { _id: this._id, note: null, note_color: null }).then(r => {
          this.afterDelete();
        }).catch(error => {
          console.log("ERROR: ", error);
          this.appService.showToast('', this._translate.instant('deviceNote.errorSavingNote'), 3000, 'danger')
        });
      }
      else {
        //_ Delete note of the device
        this.authService.updateDevice(this.deviceId, { note: null, note_color: null }).then(r => {
          this.afterDelete();
        }).catch(error => {
          console.log("ERROR: ", error);
          this.appService.showToast('', this._translate.instant('deviceNote.errorTryingToDelete'), 3000, 'danger')
        })
      }
    }
  }

  afterDelete() {
    this.appService.showToast('', this._translate.instant('deviceNote.noteDeleteSuccess'), 2000, 'success');
    this.onUpdate({ note: null, note_color: null });
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {

  }
  saveNote() {
    this.noteForm.patchValue({ note_color: this.selectedColor });

    if (this.noteForm.get('note').value !== this.note || this.selectedColor !== this.note_color) {
      if (this.noteForm.get('note').value !== '' && this.noteForm.get('note').value !== null) {
        this.dismiss();
      }
    }
  }

}