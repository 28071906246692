import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppService } from "src/app/app.service";
import { Constants } from "src/app/constants.enum";
import { AuthenticationService } from "src/app/services/authentication.service";
import { StorageService as Storage } from './../../../services/storage.service';
@Injectable({
  providedIn: 'root'
})

//_
//_ Cars service
//_ Use to manage the cars data
export class CarsService {
  //_ Cars Managment
  public _cars = new Subject<{}>();
  public cars$ = this._cars.asObservable();
  public cars = [];

  user = null;
  constructor(private http: HttpClient, private authService: AuthenticationService, private appService: AppService, public storage: Storage) {
    this.appService.user$.subscribe(u => {
      this.user = u;

      //_ Not load when bootstrap the app -> is already called on everypage when is needed this data
      // this.loadCars();
    });
  }

  async loadCars() {
    return new Promise(async (resolve, reject) => {
      var user = await this.storage.get(Constants.USER_DATA);
      var devices = await this.authService.getDevices();
      devices = devices ? devices.success : [];
      if (user) {
        this.authService.getDevicesCars(user.id, 'km').then(res => {
          if (res.success) {
            //_ Adding car.name for full name use with selectable component and assign device data
            res.success.forEach(c => {
              c = this.addLabels(c);
              c.device = devices.filter(d => d.id == c.iddevice)[0];
            });
            this.cars = res.success.sort((a, b) => (a.active == 0 ? 1 : -1) || (b.active == 0 ? 1 : -1));
            this._cars.next(res.success);
            resolve(true); // it means the wait ends ...
          }
        });
      }
    });
  }

  //_ Add two properties for using in the selectable list to show name and status
  addLabels(car) {
    car.name = car.car_name + ' / ' + car.model_name;
    car.activeStatus = car.active == 1 ? 'Active' : 'Inactive';
    return car;
  }

  addCar(newCar) {
    newCar = this.addLabels(newCar);
    this.cars.push(newCar);
    this._cars.next(this.sortActives());
  }

  editCar(car) {
    let localCar = this.cars.find(c => c.id == car.id);
    localCar = this.addLabels(localCar);
    this.cars[this.cars.indexOf(localCar)] = car;
    this._cars.next(this.sortActives());
  }

  deleteCar(car) {
    this.cars.splice(this.cars.indexOf(car), 1);
    this._cars.next(this.sortActives());
  }

  sortActives() {
    return this.cars = this.cars.sort((a, b) => (a.active == 0 ? 1 : -1) || (b.active == 0 ? 1 : -1));
  }
}
