import { Pipe, PipeTransform } from '@angular/core';
import { HumanizeTimeService } from '../services/humanize-time.service';

@Pipe({
  name: 'humanizeTimeUnits'
})

export class HumanizeTimeUnitsPipe implements PipeTransform { //duration in seconds
  constructor(private humanizeTime: HumanizeTimeService) { }

  transform(duration: any, units = ['d', 'h', 'm']): any {
    return this.humanizeTime.formatTimeUnits(duration, true, units);
  }
}