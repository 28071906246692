

export const DEVICE_STATUS = [
  { id: -1, name: 'All' },
  { id: 1, name: 'Active' },
  { id: 0, name: 'Inactive' }
];

export const DEVICE_BATTERY_LEVEL_FILTERS = [
  { id: -1, name: '', operation:'all' },
  { id: 0, name: '0%', operation:'egal' },
  { id: 1, name: '100%', operation:'egal' },
  { id: 2, name: '40%', operation:'more' },
  { id: 3, name: '40%', operation:'less' },
  { id: 4, name: 'info.connectedToBattery', operation: null },
];
