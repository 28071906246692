<!-- <ion-list> -->
  <ion-list-header *ngIf="!isKebab && !hideSelectHeader" class="ion-no-padding">
    <ion-searchbar
      *ngIf="canSearch"
      color="light"
      (ionInput)="onSearch($event)"
      placeholder="{{ 'locateDevice.writeToSearch' | translate }}"
    ></ion-searchbar>
    <h4 style="width: 100%" class="ion-text-center" *ngIf="!canSearch">
      <span *ngIf="!headerText">
        {{ "deviceList.select" | translate }}
      </span>
      <span *ngIf="headerText">
        {{ headerText }}
      </span>
    </h4>
  </ion-list-header>

<ion-content [scrollY]="false" [ngStyle]="{ 'min-height': listHeight }">
    <cdk-virtual-scroll-viewport
      [itemSize]="44"
      [minBufferPx]="440"
      [maxBufferPx]="880"
      [ngStyle]="{ 'min-height': listHeight }"
      class="viewport ion-content-scroll-host"
    >
      <ion-list >
        <ion-item
          #list
          mode="ios"
          button
          (click)="selectItem(item)"
          lines="none"
          *cdkVirtualFor="let item of items"
          detail="false"
        >
          <ion-icon
            slot="start"
            [src]="item.image"
            *ngIf="useImageFromItem"
            [ngStyle]="{ background: item.color }"
            class="iconClass"
          >
          </ion-icon>
          <ion-icon
            slot="start"
            [src]="item.icon"
            *ngIf="useIconFromItem"
            [color]="item.color ? item.color : 'primary'"
            class="icon-class"
          >
          </ion-icon>
          <ion-avatar *ngIf="icon == 'deviceImage'" slot="start">
            <ion-img
              style="width: initial; object-fit: contain"
              src="{{ item?.device | DeviceImage: item?.customer_id | async }}"
            >
            </ion-img>
          </ion-avatar>
          <ion-label [color]="item.id && item.id === selItem?.id ? 'primary' : ''" class="ion-text-wrap">
            <!-- <h4>{{ item[tagName] }} </h4> -->
            <!-- <h4 innerHTML="{{ item[tagName] | highLightText: searchTerm }}"></h4> -->

            <div *ngIf="driver;else normal">
              <h4 [innerHTML]="item[lastName] + ' ' + item[firstName]| highLightText: searchTerm">
              </h4>
          </div>

          <ng-template #normal><h4 *ngIf="!useNameAsTranslationKey" [innerHTML]="item[tagName] | highLightText: searchTerm"> </h4>
            <h4 *ngIf="useNameAsTranslationKey">{{ item[tagName] | translate }}</h4></ng-template>
          </ion-label>
          <ion-icon
            name="checkmark"
            *ngIf="!icon && item.id && item.id === selItem?.id"
            slot="end"
            color="primary"
          >
          </ion-icon>
          <ion-badge *ngIf="badge" slot="end" [color]="badgeColor">{{
            item?.active == 1
              ? ("logBook.actives" | translate)
              : ("logBook.inactives" | translate)
          }}</ion-badge>
        </ion-item>
      </ion-list>
    </cdk-virtual-scroll-viewport>
    <ion-item (click)="selectItem({id:-1})" class="add-item" *ngIf="addItem">
      <ion-icon
        size="small"
        name="add"
        slot="end"
        class="pointer pb-0"
      >
      </ion-icon>
      <ion-label>{{ addItemText }}</ion-label>
    </ion-item>
</ion-content>
