import { animate, AnimationMetadata, style, AnimationBuilder } from '@angular/animations';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';

@Directive({
  selector: '[back-locale]'
})
export class BackLocaleDirective implements OnDestroy, AfterViewInit {
  hostElement: HTMLElement
  listener: any = null;

  constructor(public elementRef: ElementRef, public render: Renderer2, private platform: Platform,
    private builder: AnimationBuilder, private translate: TranslateService, private appService: AppService) { }

  async ngAfterViewInit() {
    this.hostElement = this.elementRef.nativeElement;
    await this.platform.ready().then(() => {
      if (this.platform.is('ios')) {
        this.render.setProperty(this.hostElement, 'style', 'display:none'); //_ hide to not show at first start
        setTimeout(() => {
          const partText = this.hostElement.shadowRoot.querySelector('.button-text');

          if (partText)
            this.render.setProperty(partText, 'innerHTML', this.translate.instant('app.backButtonText'));
          const factory = this.builder.build(this.fadeIn());
          const player = factory.create(this.hostElement);

          player.play();
          this.render.setProperty(this.hostElement, 'style', 'display: block'); //_ display again, after word changed

          //_ Check for language changes
          this.listener = this.appService.language$.subscribe(l => {
            if (partText) {
              this.translate.get('app.backButtonText').subscribe(r => {
                this.render.setProperty(partText, 'innerHTML', r);
              });
            }
          })
        }, 400);
      }
    });
  }

  private fadeIn(): AnimationMetadata[] {
    return [
      style({ opacity: 0 }),
      animate('400ms ease-in', style({ opacity: 1 })),
    ];
  }

  ngOnDestroy(): void {
    if (this.listener)
      this.listener.unsubscribe();
  }
}