import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getGPUTier } from 'detect-gpu';
import { StorageSetting, STORAGE_SETTINGS } from 'src/app/services/storage-settings';
import { StorageService } from 'src/app/services/storage.service';
import { PajPopupTemplateComponent } from '../../../../../../components/paj-popup-template/paj-popup-template.component';
// import { navJS } from './../../../../../../../assets/js/navigators.js';
declare var navJS;

@Component({
  selector: 'app-slow-performance-popup',
  templateUrl: './slow-performance-popup.component.html',
  styleUrls: ['./slow-performance-popup.component.scss'],
})
export class SlowPerformancePopupComponent implements OnInit, OnDestroy {
  shouldShow = false;
  platform: { isMobile, browserName } = null;
  DONT_SHOW_AGAIN_SETTING = new StorageSetting(this.storage, STORAGE_SETTINGS.DONT_SHOW_AGAIN_PERFORMANCE_POPUP, false);
  
  dontShowCheckboxStatus = false;
  settingsLink = {
    chrome: { local: 'chrome://settings/?search=hardware', external: 'https://help.clickup.com/hc/en-us/articles/6327835447191-Enable-hardware-acceleration-in-Google-Chrome' },
    firefox: { local: 'about:preferences#general', external: 'https://support.mozilla.org/en-US/kb/performance-settings' },
    ie: { local: null, external: 'https://learn.microsoft.com/en-us/troubleshoot/developer/browsers/core-features/gpu-hardware-acceleration' },
    edge: { local: 'edge://settings/?search=hardware', external: 'https://pureinfotech.com/disable-hardware-acceleration-microsoft-edge/' },
    safari: { local: null, external: null }
  }
  
  @ViewChild('Content') Content: TemplateRef<any> = null;
  constructor(private storage: StorageService, private popoverCtrl: PopoverController, private translate: TranslateService) { }

  ngOnInit() {
    this.getSettingsLink();
    this.checkGpu();
  }

  async checkGpu(){
    const gpu = await getGPUTier();
    await this.storage.storageIsReady();
    // console.log('GPU DETECTED', { gpu, dontShow: this.DONT_SHOW_AGAIN_SETTING.get().value });
    //_ FALLBACK means there is not gpu handled by the browser
    //_ FALLBACK exception for mobiles and safary gpu
    if ((gpu.fps == undefined || gpu.fps < 10) && gpu.type == 'FALLBACK'){
      if (this.platform.browserName != 'safari' && !this.platform.isMobile)
        this.shouldShow = true;
    }

    //_ Open popup to show the Content template into it
    if (this.shouldShow && !this.DONT_SHOW_AGAIN_SETTING.get().value){
      this.openPopup();
    }
  }

  async openPopup(){
    const popup = await this.popoverCtrl.create({
      componentProps: {
        headerTitle: this.translate.instant('map.performancePopup.title'),
        contentTemplate: this.Content
      },
      component: PajPopupTemplateComponent,
      cssClass: "paj-popover-template",
      translucent: true,
    });

    await popup.present();
    await popup.onWillDismiss();
    this.DONT_SHOW_AGAIN_SETTING.set(this.dontShowCheckboxStatus);
  }

  getSettingsLink(){
    this.platform = {
      isMobile: navJS.isMobile(),
      browserName: navJS.browser()
    }
    // console.log('NAV JS', navJS.userAgent());
    // console.log('PLATFORM DETECTED', this.platform);
  }

  openSettings(){
    const link = this.settingsLink[this.platform.browserName];
    window.open( link.external, '_blank' );
  }

  close(ev){
    this.dontShowCheckboxStatus = ev.detail.checked;
  }

  ngOnDestroy() {
    
  }
}
