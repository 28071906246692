import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { BackcloseDirectiveModule } from 'src/app/directives/backclose-directive.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PajInfoPopupModule } from '../cspopover/paj-info-popover.module';
import { PajMediaManagementModule } from '../media-managment/paj-media-management.module';
import { DeviceSettingsTabComponent } from './device-settings-tab.component';
import { VibrateOnToggleDirectiveModule } from 'src/app/directives/vibration-for-toggle-buttons.module';
import { SharelinkOptionsModule } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/info/sharelink-popup/sharelink-options.module';



@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      PipesModule,
      DirectivesModule,
      ColorPickerModule,
      PajMediaManagementModule,
      BackcloseDirectiveModule,
      PajInfoPopupModule,
      VibrateOnToggleDirectiveModule,
      SharelinkOptionsModule
    ],

    declarations: [DeviceSettingsTabComponent],
    exports: [DeviceSettingsTabComponent, PipesModule, VibrateOnToggleDirectiveModule, SharelinkOptionsModule],
    
  })

  
  export class PajDeviceSettingsTabModule {}