<ion-menu
  type="overlay"
  side="start"
  menuId="mainMenu"
  contentId="main-content"
  (ionDidOpen)="open()"
  (ionDidClose)="close(); updateMenuState(false)"
  (ionWillClose)="(null)"
  (ionWillOpen)="updateMenuState(true)"
  swipeGesture="false"
  swipeEnabled="false"
>
  <ion-header>
    <ion-toolbar color="dark">
      <ion-item class="user" lines="none" color="dark">
        <ion-avatar slot="start">
          <img
            class="pointer"
            [src]="userProfile | secureMedia : false : 'assets/images/company/user.svg' | async"
            onerror="this.src='assets/images/company/user.svg'"
            (click)="changeProfile()"
          />
        </ion-avatar>
        <ion-label class="ion-text-wrap ion-text-center">
          <span class="userName">{{ authService.userName }}</span> <br/>
          <span class="pin" *ngIf="authService.pin != ''">#{{ authService.pin }}
            <ion-icon
              (click)="showInfoPopup($event, '', this._translate.instant('account.pinDetail'), '', false)"
              src="assets/images/popover/question.svg"
              class="pointer info"
              color="primary"
              slot="start"
            >
            </ion-icon>
          </span>
        </ion-label>
      </ion-item>

      <ion-item
        lines="none"
        class="address"
        color="dark"
        *ngIf="currentAddress != ''"
      >
        <ion-icon class="flaticon-placeholder" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap">{{ currentAddress }}</ion-label>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content color="light" id="menuList">
    <div
      style="height: 100%; overflow: auto;"
      [ngStyle]="{ display: this.isSmallScreen && 'contents'}"
    >
    <ion-list lines="none">
      <ion-item-group *ngFor="let p of appPages">
        <ion-item
          class="ion-item"
          button
          *ngIf="!p.childrens && !p.forAdmin"
          (click)="toggleSidebar(p)"
        >
          <ion-icon [src]="p.svgicon" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">
            <h5>
              {{ p.title | translate }}
            </h5>
          </ion-label>
          <!-- <ion-badge
            [ngStyle]="{ display: p.beta ? '' : 'none' }"
            slot="end"
            color="primary"
          >
            BETA
          </ion-badge> -->
        </ion-item>

        <ion-item
          class="ion-item"
          *ngIf="!p.childrens && p.forAdmin == user?.super_user"
          (click)="toggleSidebar(p)"
          [class.active]="router?.url.startsWith(p.url)"
        >
          <ion-icon [src]="p.svgicon" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">
            <h5>{{ p.title | translate }}</h5>
          </ion-label>
          <!-- <ion-badge
            [ngStyle]="{ display: p.beta ? '' : 'none' }"
            slot="end"
            color="primary"
          >
            BETA
          </ion-badge> -->
        </ion-item>
      </ion-item-group>
      <ion-item-group
        *ngIf="
          userData?.company_id &&
          appModes?.business_mode == 1 &&
          (userRole?.create || userRole?.edit || userRole?.delete)
        "
      >
        <ion-item
          class="ion-item"
          button
          [class.active]="router?.url.startsWith(companyMenu.url)"
          (click)="toggleSidebar(companyMenu)"
        >
          <ion-icon [src]="companyMenu.svgicon" slot="start"></ion-icon>
          <ion-label class="company-user-management-label ion-text-wrap">
            <h5>{{ companyMenu.title | translate }}</h5>
          </ion-label>
        </ion-item>
      </ion-item-group>
      <ion-item-group>
        <!-- <ion-item
          *ngIf="brand != 3"
          (click)="openLink('https://start.finder-portal.com/my-account/')"
        > -->
        <!-- <ion-item class="ion-item" routerLink="['pet']">
          <ion-icon src="assets/icons/user.svg" slot="start"></ion-icon>
          <ion-label>
            <h5>Pets</h5>
          </ion-label>
        </ion-item> -->
        <ion-item class="ion-item" *ngIf="brand != 3" (click)="openAccount()">
          <ion-icon src="assets/icons/user.svg" slot="start"></ion-icon>
          <ion-label>
            <h5>{{ "account.accountText" | translate }}</h5>
          </ion-label>
        </ion-item>
        <ion-item class="ion-item" *ngIf="brand != 3 && showMenuItem" (click)="openDeviceGuard()">
          <ion-icon src="assets/icons/shield.svg" slot="start"></ion-icon>
          <ion-label>
            <div class="title-with-badge">
              <h5>
                {{ "side-bar.deviceGuard" | translate }}
              </h5>
              <ion-badge color="primary">
                {{ "side-bar.menuBadgeItem.new" | translate }}
              </ion-badge>
            </div>
          </ion-label>
        </ion-item>
        <ion-item class="ion-item" *ngIf="brand != 3" (click)="openHelp()">
          <!-- <ion-item
          *ngIf="brand != 3"
          (click)="openHelp('https://www.paj-gps.de/hilfe/')"
        > -->
          <ion-icon
            src="assets/images/sidebar/help.svg"
            slot="start"
          ></ion-icon>
          <ion-label>
            <h5>
              {{ "bottom-fab.help" | translate }}
            </h5>
          </ion-label>
        </ion-item>
        <ion-item class="ion-item" *ngIf="brand != 3" (click)="openManuals()">
          <ion-icon name="book-outline" slot="start"></ion-icon>
          <ion-label>
            <h5>
              {{ "side-bar.manuals" | translate }}
            </h5>
          </ion-label>
        </ion-item>
        <ion-item class="ion-item" (click)="startTour()">
          <ion-icon
            src="assets/images/tour/tooltip.svg"
            slot="start"
          ></ion-icon>
          <ion-label class="ion-text-wrap">
            <h5>{{ "guidedTour.guidedTours" | translate }}</h5>
          </ion-label>
        </ion-item>
        <ion-item class="ion-item" (click)="logout()">
          <ion-icon src="assets/icons/logoutbutton.svg" slot="start"></ion-icon>
          <ion-label>
            <h5>{{ "Logout" | translate }}</h5>
          </ion-label>
        </ion-item>
        <!-- <ion-item  *ngIf="isNative" class="ion-item" (click)="exitApp()">
          <ion-icon name="exit-outline"  slot="start"></ion-icon>
          <ion-label>
            <h5>{{ "ExitApp" | translate }}</h5>
          </ion-label>
        </ion-item> -->
      </ion-item-group>
    </ion-list>
    </div>
  </ion-content>
  <ion-footer>
    <ion-toolbar color="light">
      <ion-row>
        <ion-col>
          <a class="pointer" (click)="openImprint()">
            <span>{{ "side-bar.imprints" | translate }}</span>
          </a>
        </ion-col>
        <ion-col class="ion-text-center">
          <a class="pointer" (click)="getChangeLog()">
            <span>{{ "side-bar.version" | translate }} {{ app_version }}</span>
          </a>
        </ion-col>
        <ion-col class="ion-text-center">
          <a class="pointer" (click)="openPrivacyPolicy()">
            <span style="padding-right: 10px">{{
              "side-bar.policy" | translate
            }}</span>
          </a>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-footer>
</ion-menu>
