import { Injectable } from '@angular/core';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { Capacitor } from "@capacitor/core";
@Injectable({
  providedIn: 'root'
})
export class NativesettingsService {

  constructor() { }

  /**
 * Note that the only supported option by Apple is "App".
 * Using other options might break in future iOS versions
 * or have your app rejected from the App Store.
 */
  async open(settings) {
    let platform = await Capacitor.getPlatform();
    if (platform == 'android') {
      this.openAndroid(settings)
    } else if (platform == 'ios') {
      this.openIOS(settings)
    }
   
    // await NativeSettings.open({
    //   optionAndroid: AndroidSettings.ApplicationDetails,
    //   optionIOS: IOSSettings.App
    // })
  }
  async openAndroid(settings) {
    return await NativeSettings.openAndroid({
      option: settings,
    });
  }
  /**
   * Note that the only supported option by Apple is "App".
   * Using other options might break in future iOS versions
   * or have your app rejected from the App Store.
   */
  async openIOS(settings) {
   return await NativeSettings.openIOS({
      option: settings,
    });
  }

}
