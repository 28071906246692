<ion-header *ngIf="devices?.length > 0">
  <!-- <ion-toolbar color="dark">
    <ion-title>Activity timeline</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon
          slot="icon-only"
          name="close-circle-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar> -->

  <ion-toolbar class="buttons-toolbar" color="light">
    <div class="buttons-toolbar-container">
      <app-selectable class="buttons-toolbar-selectable"
        [items]="devices"
        [selItem]="selectedDevice"
        tagId="id"
        tagName="name"
        [useNameAsTranslationKey]="true"
        color="light"
        [header]="false"
        icon="deviceImage"
        headerColor="light"
        [selectColor]="'secondary'"
        [canSearch]="devices?.length > 6 ? true : false"
        [showInPopover]="true"
        (onItemChanged)="deviceChanged($event)"
      ></app-selectable>

      <app-selectable
        class="timeline-type-picker"
        [items]="timelineTypes"
        tagId="id"
        tagName="name"
        [useNameAsTranslationKey]="true"
        [color]="'light'"
        [hideSelectHeader]="true"
        slot="start"
        [useIconFromItem]="true"
        [canSearch]="false"
        [showInPopover]="true"
        (onItemChanged)="collectionTypeChanged($event)"
        [label]="'Data type'"
        [selItem]="selectedCollectionType"
        headerColor="light"
        [selectColor]="'secondary'"
        [showRightIcon]="true"
        [forFilters]="false"
        listHeight="132px"
        popupCssClass=""
        [showLabel]="false"
      ></app-selectable>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content #content> <!-- [scrollEvents]="false" -->
  <app-map-min
    #map
    id="mini-map"
    [height]="'150px'"
    [width]="'393px'"
    [pitch]="70"
    mapStyle="paj"
    [useDefinedStyle]="true"
    [hidden]="true"
    [customerSettings]="{ map_projection: 'mercator' }" 
  >
  </app-map-min>

  <loading-indicator *ngIf="loadingData && timelineList?.length == 0"></loading-indicator>
  <!-- <cdk-virtual-scroll-viewport itemSize="390" class="vsv-wrapper ion-content-scroll-host" itemSize="50" minBufferPx="1950" maxBufferPx="3900"> -->
    <!-- <ion-infinite-scroll position="top" class="ion-text-center" threshold="100px" [disabled]="paginationData.requestLatests == false || timelineList.length == 0" (ionInfinite)="loadMoreData($event, 'up')">
      <ion-spinner color="primary"></ion-spinner>
    </ion-infinite-scroll> -->

    <!-- NO DATA -->
    <ng-container *ngIf="timelineList?.length == 0 && !loadingData" [ngTemplateOutlet]="NO_DATA">
    </ng-container>

    <ion-list mode="ios">
      <!-- <div class="card-grid" *cdkVirtualFor="let routeGroup of timelineList">
        <ion-list-header>{{ routeGroup[0]?.end_dateunix | translateUnixDate : "dddd DD MMMM, YYYY" }}</ion-list-header>
        <ion-card class="route-card" *ngFor="let route of routeGroup" >
          <timeline-route-card [route]="route" (onItemInView)="onItemInView($event)"></timeline-route-card>
        </ion-card>
      </div> -->

      <!-- <ng-container *cdkVirtualFor="let groupData of dataSource; trackBy: trackByItemDateunix"> -->
      <ng-container *ngFor="let groupData of timelineList">
        <ion-item-group class="timeline">
          <ion-item-divider class="group-header" color="light">{{ groupData?.dateunix | translateUnixDate : "dddd DD MMMM, YYYY": false: '': language }}</ion-item-divider>
            <ul>
              <li *ngFor="let item of groupData.items">
                <ion-card class="route-card ion-no-border"  *ngIf="!item.meldungtyp">
                  <timeline-route-card [route]="item"></timeline-route-card>
                </ion-card>
                <ion-card class="route-card ion-no-border"  *ngIf="item.meldungtyp">
                  <timeline-alarm-card [alarm]="item"></timeline-alarm-card>
                </ion-card>
              </li>
          </ul>
        </ion-item-group>
      </ng-container>

    </ion-list>
    
    <ion-infinite-scroll 
      [infiniteFix]="flatTimelineList" position="bottom" class="ion-text-center" [disabled]="isLastPage || timelineList.length == 0" (ionInfinite)="loadMoreData($event, 'down')" threshold="100px" [spinner]="spinner">
      <ion-spinner color="primary" #spinner></ion-spinner>
    </ion-infinite-scroll>

  <!-- </cdk-virtual-scroll-viewport> -->

</ion-content>


<!-- NO DATA CONTENT -->
<ng-template #NO_DATA>
  <ion-row *ngIf="devices?.length > 1 && timeLineCharged == 2">
    <ion-col size="12">
      <ion-img
        [src]="selectedCollectionType.noDataImage"
        style="height: 90px; margin: 15px 0 auto;"
      ></ion-img>

    </ion-col>
    <ion-col size="12">
      <h5 class="ion-text-center">
        {{ selectedCollectionType.noDataMessage| translate }}
      </h5>      
    </ion-col>
  </ion-row>
  
  <loading-indicator *ngIf="timeLineCharged < 2"></loading-indicator>

  <ion-item class="no-devices" mode="md" lines="none" color="transparent" *ngIf="devices?.length === 1">
    <ion-label class="ion-text-wrap ion-text-center">
      <ion-img src="assets/images/sidebar/gps.svg"></ion-img>
      <h1>{{ "selectSearchable.noDeviceText" | translate }}</h1>
    </ion-label>
  </ion-item>
</ng-template>
