import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import { DevicesDashboardComponent } from '../devices-dashboard.component';
import { DevicesDashboardService } from '../devices-dashboard.service';

@Component({
  selector: 'dashboard-sidebar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class DashboardSideBarComponent  implements OnInit {
  @ViewChild("navDashboard", { static: false }) nav: IonNav;
  @Output() public onMenuChange = new EventEmitter<{}>();
  isLoaded = false;
  constructor(
    private appService: AppService,
    private dashboardService: DevicesDashboardService
  ) {}

  async ngOnInit() {
    
  }

  loadView() {
    this.nav.setRoot(DevicesDashboardComponent, {
    }); 
    this.isLoaded = true;
  }

  async open() {
    if (!this.isLoaded) this.loadView();

    this.onMenuChange.next({ open: true });
    this.dashboardService.dashboardSidebarIsOpen.next(true);
  }

  async close() {
    this.onMenuChange.next({ open: false });
    this.dashboardService.dashboardSidebarIsOpen.next(false);
  }

  openStatus(status) {
    this.onMenuChange.next({ open: status });
    this.dashboardService.dashboardSidebarIsOpen.next(status);
  }

}

