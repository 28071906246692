import { Injectable } from '@angular/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private platform: Platform, private aPermissions: AndroidPermissions) { }

  async writeFile(fileName, file: Blob) {
    let base64 = await this.convertBlobToBase64(file) as string;
    const writeFile = await Filesystem.writeFile({
      path: fileName,
      data: base64,
      directory:this.platform.is('android')? Directory.Cache: Directory.Data
    });
    return writeFile;
  }

  public async convertUriToBlob(photo) {
    // "hybrid" will detect Cordova or Capacitor
    if (this.platform.is("hybrid")) {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: photo.path,
      });
      const response = await fetch(`data:image/jpeg;base64,${file.data}`)
      return await response.blob();
    } else {
      // Fetch the photo, read as a blob
      const response = await fetch(photo.webPath!);
      return await response.blob();
    }
  }

  public convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    if (blob instanceof Blob) {
      const realFileReader = (fileReader as any)._realReader;
      if (realFileReader) {
        fileReader = realFileReader;
      }
    }
    fileReader.onerror = reject;
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsDataURL(blob);
  });

  async readFile(fileName) {
    const contents = await Filesystem.readFile({
      path: fileName,
      directory: this.platform.is('android')? Directory.ExternalStorage: Directory.Data
    });
  };

}
