<paj-popup-template headerTitle="{{ 'app.apiErrorPopupTitle' | translate }}" [contentTemplate]="Content">

</paj-popup-template>

<ng-template #Content>
  <ion-grid class="ion-padding">
    <ion-row>
      <ion-col size="12" class="ion-text-center">
        <div class="face" [class.sad]="isSad">
          <span class="face">
            <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m363.045 183.758a32.045 32.045 0 1 1 -32.045-32.046 32.081 32.081 0 0 1 32.045 32.046zm148.955 72.242c0 141.159-114.841 256-256 256s-256-114.841-256-256 114.841-256 256-256 256 114.841 256 256zm-32 0c0-123.514-100.486-224-224-224s-224 100.486-224 224 100.486 224 224 224 224-100.486 224-224zm-266.955-72.242a32.045 32.045 0 1 0 -32.045 32.042 32.082 32.082 0 0 0 32.045-32.042zm117.294 122.761-156.962 42.058a16 16 0 1 0 8.283 30.91l156.962-42.058a16 16 0 1 0 -8.283-30.91z"/></svg>
          </span>
        </div>
        <br />
        <h3>{{ 'app.apiErrorPopup.message1' | translate }}</h3>
        <p>{{ 'app.apiErrorPopup.message2' | translate }}</p>
      </ion-col>
      
      <ion-col size="12" class="ion-text-end">
        <ion-button
        (click)="onCancel()"
        color="light"
        shape="round"
        >
          Try again
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>