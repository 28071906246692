// platform-utils.ts
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { inject } from '@angular/core';

export class PlatformUtils {
  private static platform: Platform;

  //_ Initialize to get the dependency ready to inject
  static initialize(): void {
    this.platform = inject(Platform);
  }

  static getPlatform(): string {
    if (Capacitor.isNativePlatform()) {
      return 'native-app';
    } else if (this.platform.is('mobileweb')) {
      return 'mobile-browser';
    } else {
      return 'desktop-browser';
    }
  }

  static isBrowser(): boolean {
    return this.getPlatform() === 'mobile-browser' || this.getPlatform() === 'desktop-browser';
  }

  static isDesktopBrowser(): boolean {
    return this.getPlatform() === 'desktop-browser';
  }

  static isMobileBrowser(): boolean {
    return this.getPlatform() === 'mobile-browser';
  }

  static isNative(): boolean {
    return this.getPlatform() === 'native-app';
  }

  static isAndroid(): boolean {
    return Capacitor.getPlatform() === 'android';
  }

  static isIOS(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }
}
