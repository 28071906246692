<ion-header style="position: sticky; top: 0">
  <ion-toolbar color="light">
    <form [formGroup]="timeSearch">
      <ion-searchbar
      
        [placeholder]="_translate.instant('deviceManagement.search')"
        formControlName="searchValue"
        [debounce]="250"
        (ionInput)="search($event)"
        class="popover-searchbar"
      ></ion-searchbar>
      <div
        *ngIf="
          (searchError?.dirty || searchError?.touched) && searchError?.errors
        "
        class="ion-text-center"
      >
        <ion-text color="primary" *ngIf="searchError?.errors?.pattern">{{
          "logBook.noResultsFound" | translate
        }}</ion-text>
      </div>
    </form>
  </ion-toolbar>
</ion-header>

<ion-content class="time-picker-content" style="min-height: 56px">
  <ion-list>
    <ion-item *ngFor="let t of timesFiltered" (click)="selectTime(t)">
      {{ t }}
      <ion-icon
        *ngIf="selectedTime == t"
        color="primary"
        slot="end"
        name="checkmark-outline"
      ></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer style="position: sticky; background-color: var(--ion-color-light)">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelButton()">{{
        "logBook.cancel" | translate
      }}</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="okButton()">{{
        "logBook.ok" | translate
      }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
