import { Injectable } from '@angular/core';
import { Geolocation, GeolocationPluginPermissions } from '@capacitor/geolocation';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { Subject } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { RequestPermissionsComponent } from 'src/app/components/request-permissions/request-permissions.component';
import { AvailablePermissions } from 'src/app/components/request-permissions/permissions';
import { zoomInAnimation } from 'src/app/animations/ionic-animations';
import { AppService } from 'src/app/app.service';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  constructor(
    private locationAccuracy: LocationAccuracy,
    private appService: AppService,
    private popoverCtrl: PopoverController,
    public _translate: TranslateService) { }

  watcher = null;

  public hasGpsPermit = false;
  public gpsActivated: any = false;
  statusChanged = new Subject();

  getCurrentPosition(options:any) {
    const coordinates = Geolocation.getCurrentPosition(options);
    return coordinates
  };

  //On Android/Chrome position request intervals too small/random
  startWatcher(callback) {
    let options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 30000 };
    if(!this.watcher) this.watcher = Geolocation.watchPosition(options, callback);
    return this.watcher;
  }

  stopWatcher() {
    if (this.watcher) Geolocation.clearWatch({ id: this.watcher });
  }

  getPluginInstance(){
    return Geolocation;
  }

  checkPermission() {
    return Geolocation.checkPermissions().then( async (r) => {
      this.hasGpsPermit = r.location == 'granted';
      this.gpsActivated = r.location == 'granted';
      this.statusChanged.next({ hasGpsPermit: this.hasGpsPermit, gpsActivated: this.gpsActivated });
      return r;
    });
  }

  async requestPermission(permissions: GeolocationPluginPermissions = { permissions: ['location', 'coarseLocation'] }){
    return await Geolocation.requestPermissions(permissions).then( async (r) => {
      this.hasGpsPermit = r.location == 'granted';
      this.gpsActivated = r.location == 'granted';
      this.statusChanged.next({ hasGpsPermit: this.hasGpsPermit, gpsActivated: this.gpsActivated });
      return r;
    }).catch( e => { return e });
  }

  //_ Return true when select Ok button -> that turns on the device gps in android
  requestNativeDialog () {
    return new Promise( (resolve) => {
      // the accuracy option will be ignored by iOS
      this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
        () => resolve(true),
        error => resolve(false)
      );
    });
  }



  //_ Request permission popup where the user can grant gps location from device app settings
  async requestPermissionPopup() {
    let iosPermissionCheck = null;
    //_ Permission check not support ios; so this is a custom check fn to pass to the generic request permission popup
    if (Capacitor.getPlatform() == 'ios' && Capacitor.isNativePlatform()){
      iosPermissionCheck = () => { return new Promise( (resolve) => {
        this.requestPermission().then(r => {
            resolve(r.location == 'granted')
          })
        })
      }
    }

    return new Promise(async (resolve, reject) => {
      const popoverObj = await this.popoverCtrl.create({
        component: RequestPermissionsComponent,
        cssClass: "choice-tour-popover",
        componentProps: { permissionName: AvailablePermissions.Geolocation, permissionCheckFn: iosPermissionCheck },
        showBackdrop: true,
        mode: 'ios',
        enterAnimation: zoomInAnimation
      });

      popoverObj.present();
      const { data } = await popoverObj.onWillDismiss();

      if (data) {
        if (data.hasPermission) resolve(true);
      }
      resolve(false);
    })
  }

}
