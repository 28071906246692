import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highLightText',
})
export class HighlightTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, search: string): SafeHtml {
    if (search && text) {
      let pattern = search;

      pattern = pattern.split(' ').filter((t) => {
        return t.length > 0;
      }).join('|');

      const regex = new RegExp(pattern, 'gi');
      const highlighted = text.replace(regex, (match) => `<span class='highlight'>${match}</span>`);

      // Sanitize the HTML before returning it
      return this.sanitizer.bypassSecurityTrustHtml(highlighted);
    } else {
      return text;
    }
  }
}
