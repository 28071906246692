<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-back-button
        (click)="onCancel()"
        style="display: block !important"
        back-close
        back-locale
      >
      </ion-back-button>
    </ion-buttons>
    <ion-title
      >{{"logBookNoAccess.noAccess"|translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <div>
          <ion-card class="icon">
            <img src="assets/default_brand/img/logo_main_square.png" alt="" />
            <!--<img src="../assets/images/paj-icon-forwhatsnew.png" alt="" />-->
          </ion-card>
        </div>
        <div>
          <ion-card
            class="ion-no-margin"
            style="
              box-shadow: none;
              background: transparent;
              --background: transparent;
            "
          >
            <ion-card-header>
              <ion-card-title class="ion-no-padding ion-text-center">
                <h2>{{"logBookNoAccess.noAccess"|translate}}</h2>
              </ion-card-title>
            </ion-card-header>
            <ion-card-content class="ion-text-center">
              {{"logBookNoAccess.notCompatiblePlzUpgrade"|translate}}
              <!-- <ion-list>
                <ion-list-header>
                  <ion-label>
                    <h3 class="ion-text-left">
                      <b>
                        {{"logBookNoAccess.compatibleDevicesAre"|translate}}
                      </b>
                    </h3>
                  </ion-label>
                </ion-list-header>
                <ul
                  class="ion-text-left"
                  *ngFor="let item of   availableDevices; let i=index"
                >
                  <li>{{item?.name}}</li>
                </ul>
              </ion-list> -->

              <ion-list>
                <ion-list-header>
                  <ion-label>
                    <h3 class="ion-text-left ion-text-wrap">
                      <b>
                        {{ "logBook.compatibleDevicesInfo" | translate }}
                      </b>
                    </h3>
                  </ion-label>
                </ion-list-header>
                <div *ngIf="loading" class="spinner-container">
                  <ion-label class="loading-text ion-padding">
                    {{ 'editVehicle.pleaseWait' | translate }}
                  </ion-label>
                  <ion-spinner color="primary"></ion-spinner>
                </div>
                 <div class="ion-text-left" style="float: left; width: 50%;">
                  <ul>
                    <li *ngFor="let item of leftItems">{{ item.model }}</li>
                  </ul>
                </div>
                <div class="ion-text-left" style="float: right; width: 50%;">
                  <ul>
                    <li *ngFor="let item of rightItems">{{ item.model }}</li>
                  </ul>
                </div>
              </ion-list>
              <!-- <ion-button
                class="ion-margin-top dynamic-height-button ion-text-wrap"
                (click)="onUpgrade()"
                expand="full"
                shape="round"
                color="primary"
                style="text-transform: none"
              >
              <ion-label class="ion-text-wrap">{{"logBookNoAccess.upgradeDevice"|translate}}</ion-label>
              </ion-button> -->
              <ion-item lines="none"
              class="upgrade-button"
              (click)="onUpgrade()"
              color="primary"
              style="text-transform: none;"
              >
                <ion-label class="ion-text-wrap" style="text-align: center">{{"logBookNoAccess.upgradeDevice"|translate}}</ion-label>
              </ion-item>

              <!-- <ion-button
                (click)="onCancel()"
                expand="block"
                shape="round"
                color="dark"
                style="margin-top: 8px"
              >
              <ion-label class="ion-text-wrap">{{"logBookNoAccess.cancel"|translate}}</ion-label>
              </ion-button> -->
              <ion-item lines="none"
              (click)="onCancel()"
              color="dark"
              style="margin-top: 8px;"
              class="upgrade-button"
              >
                <ion-label class="ion-text-wrap" style="text-align: center">{{"logBookNoAccess.cancel"|translate}}</ion-label>
              </ion-item>
            </ion-card-content>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
