import { ILayout } from './ILayout';
import { IPaint } from './IPaint';

export enum layerType { raster = 'raster', symbol = 'symbol', line = 'line', circle = 'circle' }

export class Layer {
    id: string = 'layer0';
    type: layerType;
    source: string;
    layout: ILayout = <ILayout>{};
    paint: IPaint = <IPaint>{};
    minzoom: number = 0;
    maxzoom: number = 22;

    constructor(_id) {
        this.id = _id;
    }
}