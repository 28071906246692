import { StepOptions, TourOptions } from "src/app/components/tour/tour.component"
export const devicesListTourObj: TourOptions = { name: "tour.new-devices-list" }; 

export function devicesListFirstTour(tour, translate) {
  //let tour: TourOptions = { name: 'tour.map'}; //_ Thiking when someone else, say; but the tour will able to stop and continue leter
  //_ Or can skipped or canceled tourOptions will used for this 
  let steps: StepOptions[] = [];
  return steps = [
    {
      dom: '',
      title: translate.instant('devicesListTour.step1.title'),
      //title: 'Devices list tour',
      content: translate.instant('devicesListTour.step1.content'),
      image: 'assets/images/tour/devices-tour.svg',
      delay: 0,
      isStart: true,
      tour: tour,
      name: ''
    },
    {
      dom: '.devicesMenuButton',
      title: translate.instant('mapTour.step5.title'),
      content: translate.instant('mapTour.step5.content'),
      // image: 'assets/images/tour/map/i4.gif',
      delay: 200
    },
  ]
}

export function devicesListTour(tour, translate, firstDeviceId) {
  //let tour: TourOptions = { name: 'tour.map'}; //_ Thiking when someone else, say; but the tour will able to stop and continue leter
  //_ Or can skipped or canceled tourOptions will used for this 
  let steps: StepOptions[] = [];
  return steps = [
    {
      dom: '',
      title: translate.instant('devicesListTour.step1.title'),
      //title: 'Devices list tour',
      content: translate.instant('devicesListTour.step1.content'),
      image: 'assets/images/tour/devices-tour.svg',
      delay: 0,
      isStart: true,
      tour: tour,
      name: ''
    },
    {
      dom: '.devicesListNavigation',
      title: translate.instant('devicesListTour.step2.title'),
      content: translate.instant('devicesListTour.step2.content'),
      //image: 'assets/images/tour/map/i1.gif',
      yPosition: 'top',
      delay: 800,
      tour: tour
    },
    {
      dom: '#devicelist-search',
      title: translate.instant('devicesListTour.step3.title'),
      content: translate.instant('devicesListTour.step3.content'),
      //image: 'assets/images/tour/map/i1.gif',
      name: 'deviceList-search',
      delay: 300,
    },
    {
      dom: '#reorder-options',
      title: translate.instant('devicesListTour.step4.title'),
      content: translate.instant('devicesListTour.step4.content'),
      //image: 'assets/images/tour/map/i1.gif',
      name: 'menu-options-button',
      delay: 400,
    },
    {
      dom: '#home-button-filters',
      title: translate.instant('devicesListTour.sortButton.title'),
      content: translate.instant('devicesListTour.sortButtono.content'),
      //image: 'assets/images/tour/map/i1.gif',
      name: 'deviceList-filters-button',
      delay: 300,
    },
    {
      dom: '#home-group-dropdown',
      title: translate.instant('devicesListTour.groupsButton.title'),
      content: translate.instant('devicesListTour.groupsButton.content'),
      //image: 'assets/images/tour/map/i1.gif',
      name: 'deviceList-group-dropdown',
      delay: 300
    },
    // {
    //   dom: '#reorder-options',
    //   title: translate.instant('devicesListTour.step4.title'),
    //   content: translate.instant('devicesListTour.step4.content'),
    //   //image: 'assets/images/tour/map/i1.gif',
    //   name: 'menu-options-popup',
    //   delay: 300,
    // },
    // {
    //   dom: '#reorder-options',
    //   title: translate.instant('devicesListTour.step4.title'),
    //   content: translate.instant('devicesListTour.step4.content'),
    //   //image: 'assets/images/tour/map/i1.gif',
    //   name: 'menu-options',
    //   delay: 1000,
    // },
    // {
    //   dom: '#devicelist-sort',
    //   title: translate.instant('devicesListTour.step5.title'),
    //   content: translate.instant('devicesListTour.step5.content'),
    //   //image: 'assets/images/tour/map/i1.gif',
    //   delay: 300,
    // },
    // {
    //   dom: '#devicelist-box'+firstDeviceId,
    //   title: translate.instant('devicesListTour.step6.title'),
    //   content: translate.instant('devicesListTour.step6.content'),
    //   //image: 'assets/images/tour/map/i1.gif',
    //   delay: 300, name: 'quick-information'
    // },
    {
      dom: '#showhide-device' + firstDeviceId,
      title: translate.instant('guidedTour.showHide'),
      content: translate.instant('guidedTour.showHideContent'),
      //image: 'assets/images/tour/map/i1.gif',
      delay: 300, name: 'showhide-device',
    },
    {
      dom: '#enabledisable-alert' + firstDeviceId,
      title: translate.instant('guidedTour.enableDisableAllAlerts'),
      content: translate.instant('guidedTour.enableDisableAllAlertsContent'),
      delay: 300, name: 'enabledisable-alert',
    },
    {
      dom: '#devicelist-eye' + firstDeviceId,
      title: translate.instant('guidedTour.eyeButton'),
      content: translate.instant('guidedTour.eyeButtonContent'),
      delay: 300, name: 'eye-button',
    },
    {
      dom: '#devicelist-box' + firstDeviceId,
      title: translate.instant('guidedTour.clickOrTapToOpen'),
      content: translate.instant('guidedTour.clickOrTapToOpenContent'),
      delay: 300, name: 'tap-click',
    },
    {
      dom: '#device-swipe-header',
      title: translate.instant('guidedTour.swipeDevices'),
      content: translate.instant('guidedTour.swipeDevicesContent'),
      delay: 800, name: 'swipe-device',
    },
    {
      dom: '#device-view-tab-content',
      title: translate.instant('devicesListTour.step8.title'),
      content: translate.instant('devicesListTour.step8.content'),
      delay: 300, name: 'submenu-info'
    },
    {
      dom: '#device-view-tab-content',
      title: translate.instant('devicesListTour.step9.title'),
      content: translate.instant('devicesListTour.step9.content'),
      delay: 300, name: 'submenu-route'
    },
    {
      dom: '#device-view-tab-content',
      title: translate.instant('devicesListTour.step10.title'),
      content: translate.instant('devicesListTour.step10.content'),
      delay: 300, name: 'submenu-alert'
    },
    {
      dom: '#device-view-tab-content-geofence',
      title: translate.instant('devicesListTour.step11.title'),
      content: translate.instant('devicesListTour.step11.content'),
      image: 'assets/images/tour/map/igeofence-relocate.gif',
      delay: 300, 
      name: 'submenu-alert-geofence-relocate',
      imageStyle: { 'height' : '175px' },
      
    },
    {
      dom: '#device-view-tab-content-geofence',
      title: translate.instant('devicesListTour.step12.title'),
      content: translate.instant('devicesListTour.step12.content'),
      image: 'assets/images/tour/map/igeofence-resize.gif',
      delay: 300, 
      name: 'submenu-alert-geofence-resize',
      imageStyle: { 'height' : '175px' }
    },
    {
      dom: '#device-view-tab-content',
      title: translate.instant('devicesListTour.subMenutSettings.title'),
      content: translate.instant('devicesListTour.subMenutSettings.content'),
      // image: 'assets/images/tour/map/igeofence-resize.gif',
      delay: 500,
      name: 'submenu-settings',
      imageStyle: { 'height' : '175px' }
    }
  ]
}

export function deviceTour(tour, translate) {
  //let tour: TourOptions = { name: 'tour.map'}; //_ Thiking when someone else, say; but the tour will able to stop and continue leter
  //_ Or can skipped or canceled tourOptions will used for this 
  let steps: StepOptions[] = [];
  return steps = [
    {
      dom: '',
      title: translate.instant('devicesListTour.step1.title'),
      //title: 'Devices list tour',
      content: translate.instant('devicesListTour.step1.content'),
      image: 'assets/images/tour/devices-tour.svg',
      delay: 0,      
      isStart: true,
      tour: tour,      
      name: ''
    },
    {
      dom: '.devicesListNavigation',
      title: translate.instant('company.permissions.device'),
      content: translate.instant('deviceTour.step1.content'),      
      yPosition: 'top',      
      delay: 300,      
      tour: tour
    },
    {
      dom: '#device-view-tab-content',
      title: translate.instant('devicesListTour.step8.title'),
      content: translate.instant('devicesListTour.step8.content'),
      delay: 700, name: 'submenu-info'
    },
    {
      dom: '#device-view-tab-content',
      title: translate.instant('devicesListTour.step9.title'),
      content: translate.instant('devicesListTour.step9.content'),
      delay: 300, name: 'submenu-route'
    },
    {
      dom: '#device-view-tab-content',
      title: translate.instant('devicesListTour.step10.title'),
      content: translate.instant('devicesListTour.step10.content'),
      delay: 300, name: 'submenu-alert'
    },
    {
      dom: '#device-view-tab-content-geofence',
      title: translate.instant('devicesListTour.step11.title'),
      content: translate.instant('devicesListTour.step11.content'),
      image: 'assets/images/tour/map/igeofence-relocate.gif',
      delay: 300, 
      name: 'submenu-alert-geofence-relocate',
      imageStyle: { 'height' : '175px' },
      
    },
    {
      dom: '#device-view-tab-content-geofence',
      title: translate.instant('devicesListTour.step12.title'),
      content: translate.instant('devicesListTour.step12.content'),
      image: 'assets/images/tour/map/igeofence-resize.gif',
      delay: 300, 
      name: 'submenu-alert-geofence-resize',
      imageStyle: { 'height' : '175px' }
    },
    {
      dom: '#device-view-tab-content',
      title: translate.instant('devicesListTour.subMenutSettings.title'),
      content: translate.instant('devicesListTour.subMenutSettings.content'),
      // image: 'assets/images/tour/map/igeofence-resize.gif',
      delay: 500,
      name: 'submenu-settings',
      imageStyle: { 'height' : '175px' }
    }
  ]
}