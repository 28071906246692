import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'paj-popup-template',
  templateUrl: './paj-popup-template.component.html',
  styleUrls: ['./paj-popup-template.component.scss'],
})
export class PajPopupTemplateComponent implements OnInit {
  @Input() headerTemplate: TemplateRef<any> = null;
  @Input() contentTemplate: TemplateRef<any> = null;
  @Input() headerTitle = '';
  @Input() enableClose = true;
  @Input() cssContentClass = '';

  @Output() onClose = new EventEmitter();
  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {}

  dismiss() {
    this.onClose.emit({ closing: true });
    if (this.enableClose)
      this.popoverCtrl.dismiss();
  }

}
