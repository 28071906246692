import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService as Storage } from '../../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { environment as ENV } from 'src/environments/environment';
import { mapUtil } from 'src/app/members/generate-video/map-min/class/mapUtil';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { DeviceImageService } from 'src/app/pipes/deviceImagePipe';
import { NotificationsService } from 'src/app/services/notifications.service';
import { MapMinComponent } from '../../generate-video/map-min/map-min.component';
import { ApiService } from '../../../services/api.service';
import { resizeImage, validateLatLng } from 'src/app/members/map/components/map/class/mapUtil';
import { BrowserService } from 'src/app/services/plugins/browser.service';
import { Subject, takeUntil } from 'rxjs';
import { KmtoMilesPipe } from 'src/app/pipes/KmtoMilesPipe';

@Component({
  selector: 'app-details',
  templateUrl: './details.page.html',
  styleUrls: ['./details.page.scss'],
})
export class DetailsPage implements OnInit, OnDestroy {
  @ViewChild('map') map: MapMinComponent;
  @Input() notification;
  device = null;
  url: string = ENV.ROUTE;
  userId: any;
  location = null;
  language: string;
  speedIcon = null;
  distance_unit = '';
  speed_unit = '';
  isValidLatLng = true;
  mapReadySubscriber = null;
  onDestroy = new Subject();
  constructor(private api: ApiService, private browserService: BrowserService, private modalCtrl: ModalController,
    private notifService: NotificationsService, private translate: TranslateService,
    private imageService: DeviceImageService, private appService: AppService
  ) {
    this.distance_unit = this.appService.user ? this.appService.user.distance_unit : 'km';
    this.speed_unit = this.translate.instant('speed.' + this.distance_unit + 'H');
  }



  async ngOnInit() {
    this.userId = this.appService.userId;
    //_ Test not found location
    // this.notification.lat = null;

    if (this.notification)
      this.Initialize();
    else
      this.appService.showToast('', this.translate.instant('toast.errorLoadingAlarmDetails'), 3000, 'danger');

    if (this.notification.speed)
      this.speedIcon = this.notification.speed;
  }

  Initialize() {
    this.isValidLatLng = validateLatLng(this.notification.lat, this.notification.lng, this.notification);
    // this.device = this.devicesService.devices[this.notification.iddevice]?.properties;
    this.device = this.appService.devices.find(d => d.id == this.notification.iddevice);
    this.getLocationAddress();

    //this.speed_unit == 'km' ? this.notification?.speederlaubt : this.notification?.speederlaubt / 1.609344;

    // if(this.notification?.speederlaubt)
    // {
    //   this.notification.speederlaubt = this.kmToMi.transform(this.notification?.speederlaubt,'1.0-0',true);
    // }
    // if(this.notification?.speed)
    // {
    //   this.notification.speed = this.kmToMi.transform(this.notification?.speed,'1.0-0',true);
    // }
    if (this.notification.speed)
      this.speedIcon = this.notification.speed;

      console.log('NOTIFICATION DATA', this.notification)
  }

  ngAfterViewInit() {
    if (this.map){
      this.map.mapReady.pipe(takeUntil(this.onDestroy)).subscribe(r => {
        if (r) this.markMap();
      });
    }
  }

  async markMap() {
    this.imageService.getImage(this.device, this.userId).pipe(takeUntil(this.onDestroy)).subscribe(async (img: any) => {
      if (img != this.imageService.whitePicture) {
        let img2: any;  //img2 create circular image and draw into canvas ...
        if (img.endsWith('.svg'))
          await await resizeImage(img, 72, 100, 'white', false, false).then(r => img2 = r);
        else
          await await resizeImage(img, 100, 100, 'white', false, true).then(r => img2 = r);

        if (this.map){
          this.map.addLayerMarker(this.notification.id, this.notification.lng, this.notification.lat, img2, 0.5, this.device.name, null, 'viewport', 'bottom', { 'text-offset': [0, 3] });
          this.map.addCircle([this.notification.lng, this.notification.lat], 'c' + this.device.id, this.device.spurfarbe, 17);
          this.map.addEffectMarker(this.notification.lat, this.notification.lng, 'dev' + this.device.id, '', '#fff', this.device.spurfarbe);
        }
        //this.map.centerTo(this.notification.lng, this.notification.lat, 16);
      }
    });
  }

  async getLocationAddress() {
    if (this.notification.lat && this.notification.lng) {
      this.api.getLocationAddress(this.notification.lat, this.notification.lng).then((res: any) => {
        this.location = res.data;
      }).catch(error => { console.log("ERROR: ", error); });
    }
  }

  async setReadStatus(not: any, isread = false) {
    console.log("setReadStatus")
    const nots = []; nots.push(not);
    let status = not.isread ? 0 : 1;
    const result = await this.notifService.markAll(nots, isread ? 1 : status);
    if (result) {
      not.isread = isread ? isread : !not.isread;
      const message = not.isread ? 'dashboard.success-text-1' : 'dashboard.success-text-2';
      this.notifService.showToast('',
        this.translate.instant(message), 3000, 'success');
    } else {
      this.notifService.showToast('',
        this.translate.instant('dashboard.error-text'), 4000, 'danger');
    }
  }

  async setReadStatus2(not: any, status = 0) {
    var obj:any = {};
    console.log("not.isread & Status : ", not.isread, status)
    if (not.isread != status) {
      const result = await this.notifService.markAll([not], status);
      if (result) {
        not.isread = status;
        const message = not.isread ? 'dashboard.success-text-1' : 'dashboard.success-text-2';
        this.notifService.showToast('',
          this.translate.instant(message), 3000, 'success');
      } else {
        this.notifService.showToast('',
          this.translate.instant('dashboard.error-text'), 4000, 'danger');
      }
      obj.isread = !status;
    }
    else if(not.isread == status){
      const message = not.isread ? 'dashboard.success-text-1' : 'dashboard.success-text-2';
        this.notifService.showToast('',
          this.translate.instant(message), 3000, 'success');
    }

    this.dismiss(obj);
  }


  async dismiss(data) {
    await this.modalCtrl.dismiss(data);
  }

  async close() {
    await this.modalCtrl.dismiss();
  }
  openMap(lat, lng) {
    const browser = this.browserService.openBrowser(`https://www.google.com/maps/place/${lat},${lng}`, "_system");

  }

  ngOnDestroy(): void {
    this.onDestroy.next(true);
  }


}
