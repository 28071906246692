export const cropCircularImage = (base64File, width = null, height = null) => {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        let img = new Image();
        img.onload = () => {
            let cw, ch;
            cw = canvas.width = width ? width : img.width;
            ch = canvas.height = height ? height : img.height;

            //_ calculate ratio for width and heigth
            var hRatio = canvas.width  / img.width    ;
            var vRatio =  canvas.height / img.height  ;
            var ratio  = Math.min ( hRatio, vRatio );
            //_ calc the center of the canvas
            var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
            var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
            //_ Draw the image into the canvas applying the ratio
            ctx.drawImage(img, 0,0, img.width, img.height, centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);

            ctx.globalCompositeOperation = 'destination-in';
            ctx.beginPath();
            ctx.arc(cw / 2, ch / 2, ch / 2, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();
            resolve(canvas.toDataURL());
        };

        img.src = base64File;
    });
}

//_ @base64 image to put in the frame
//_ @frameSrc = frame source file or string of the frame
//_ @width, @height and @frameOffset size and location of the whole to fit the image into the frame
export const frametize = (base64File, frameSrc, width, height, frameOffset = { x: 0, y: 0 }) => {
    return new Promise(async (resolve, reject) => {
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        const frame: any = await getImageObject(frameSrc);

        let img = new Image();
        img.onload = () => {
            canvas.width = frame.width;
            canvas.height = frame.height;
            ctx.drawImage(img, frameOffset.x, frameOffset.y, width, height);
            ctx.drawImage(frame, 0, 0, frame.width, frame.height);
            resolve(canvas.toDataURL());
        };

        img.src = base64File;
    });
}

//_ Return Image dom element waiting for load the resource
export const getImageObject = (base64) => {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = () => {
            resolve(image);
        };
        image.src = base64;
    })

}