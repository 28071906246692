import { MapComponent } from './components/map/map.component';
import { DeviceDataService } from './devicesData.service';
import {
  boxPolygon,
  centerMapOnGeofence,
} from './components/map/class/geofence_center';
import { Injector } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppService } from 'src/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { roundCoordinates } from './components/map/map.service';
import { circle as Tcircle, point as Tpoint } from '@turf/turf';
import { Constants } from 'src/app/constants.enum';
import { MapWifiPopupComponent } from './components/map-wifi-popup/map-wifi-popup.component';
import { DynamicComponentService } from './components/dinamic.component.service';
import { ModalController, ToastController } from '@ionic/angular';
import { translationsKeys } from '../notifications/notificationsUtil';
import { DetailsPage } from '../notifications/details/details.page';
import { MapGeofenceComponent } from './components/map-geofence/map-geofence.component';
import { BehaviorSubject, first, Subject } from 'rxjs';
import { TabsService } from 'src/app/petmode/services/tabs.service';
export class MapMixin {
  public map: MapComponent;
  public _devicesService: DeviceDataService;
  public storage: StorageService;
  public authService: AuthenticationService;
  public appService: AppService;
  public _translate: TranslateService;
  public dinamicComponent: DynamicComponentService;
  public toastController: ToastController;
  public modalController:ModalController;
  public tabsService:TabsService

  geoOpenSubject = new Subject()
  constructor(injector: Injector) {
    this._devicesService = injector.get(DeviceDataService);
    this.storage = injector.get(StorageService);
    this.authService = injector.get(AuthenticationService);
    this.appService = injector.get(AppService);
    this._translate = injector.get(TranslateService);
    this.dinamicComponent = injector.get(DynamicComponentService);
    this.toastController = injector.get(ToastController);
    this.modalController = injector.get(ModalController);
  }

  addMarkers(devices) {
    this.addWifiMarkers(devices);
    //_ Create markers
    //_ defaultImage: if the user has more than X number of devices
    Object.values(devices).forEach(async (d: any) => {
      // Add the device marker and if is only then add onlineEffect
      // Only add the marker if has a last point
      if (d.lastPoint) {
        await this.map._addMarker(d, null, true);
        this._devicesService.updateOnlineStatus(d.lastPoint);
      }
    });
  }
  addWifiMarkers(devices) {
    // this.authService.getDevicesWifis(this.appService.user.id).subscribe((res: any) => {
    //   console.log('DEVICES WIFI ', res);
    //   if (res.success) {
    //     const devicesRes = res.success;
    //     Object.keys(devicesRes).forEach(deviceId => {
    //       if (devicesRes[deviceId]?.length > 0) {
    //         this._devicesService.devices[deviceId]?.parseAndStoreWifis(devicesRes[deviceId][0]);
    //       }
    //       this._devicesService.devices[deviceId]?.wifiMarkers.forEach(wifi => {
    //         const deviceShow = this._devicesService.devices[deviceId].properties.deviceshow;
    //         wifi.deviceshow = deviceShow;
    //         this.map.wifiHomeMarkers.addMarker([+wifi.lng, +wifi.lat], true, {}, wifi);
    //       })
    //       console.log('WIFI MARKERS ' + this._devicesService.devices[deviceId]?.properties.name, this._devicesService.devices[deviceId]?.wifiMarkers);
    //     })
    //   }
    // });
  }
  async triggerGeozone(data, deviceId) {
    console.log('triggerGeozone in mixin', { data: data, deviceId: deviceId,map:this.map });

    if (data) {
      if (data) {
        if (data.type === 'fitGeo') {
          const fFeature = this.map.geofenceDrawer
            .getAll()
            .features.find((feature) => feature.id == data.geoId);
          if (fFeature) {
            var coordinates = this.map.geofenceDrawer
              .getAll()
              .features.find((feature) => feature.id == data.geoId).geometry
              .coordinates[0];
            var bounds = coordinates.reduce(
              function (bounds, coord) {
                return bounds.extend(coord);
              },
              new this.map.LngLatBounds(coordinates[0], coordinates[0]),
            );
            coordinates = boxPolygon(fFeature, 0.2).geometry.coordinates[0];
            let mapRedirectAnimationDuration = 4000; // 4 secs
            let userSettings = await this.storage.get(Constants.USER_SETTINGS);
            console.log(userSettings.map_projection);
            if (
              userSettings.map_projection == 'mercator' ||
              userSettings.map_projection == 'globe'
            )
              mapRedirectAnimationDuration = 4000;
            else mapRedirectAnimationDuration = 1000;
            this.map.fitBounds(coordinates, 0, 0, false, null, {
              padding: 20,
              duration: mapRedirectAnimationDuration,
            });
          }
        }

        if (data.shapeName) {
          this.drawPolygon(data);
        }
      }
    }
  }
  async drawPolygon(data) {
    let radius = 0.2;

    let portColor = data.shapeColor;
    let device = this._devicesService.devices[data.device.id];
    const authService = this.authService;
    var feature;
    var coordanates;

    if (!device.lastLocation[0]) {
      this.appService.showToast(
        '',
        this._translate.instant('toast.noLastLocationFound'),
        3000,
        'danger',
      );
      return false;
    }

    var x = device.lastLocation[0];
    var y = device.lastLocation[1];

    if (data.shapeName == 'polygon') {
      //const incrementAngle = (2 * Math.PI) / 4;
      // const coordenatesPolygon = [];

      /* for (let i = 0; i < 4; i++) {
        const pointX =  x + (radius / 10) * Math.cos(incrementAngle * i);
        const pointY = y + (radius / 10) * Math.sin(incrementAngle * i);

        coordenatesPolygon.push([roundCoordinates(pointX), roundCoordinates(pointY)]);
      }*/

      // const pointX0 = x + (radius / 10);
      // const pointY0 = y + ((radius / 10) - (radius / 30));
      // coordenatesPolygon.push([roundCoordinates(pointX0), roundCoordinates(pointY0)]);
      // const pointX1 = x + (radius / 10);
      // const pointY1 = y - ((radius / 10) - (radius / 30));
      // coordenatesPolygon.push([roundCoordinates(pointX1), roundCoordinates(pointY1)]);
      // const pointX2 = x - (radius / 10);
      // const pointY2 = y - ((radius / 10) - (radius / 30));
      // coordenatesPolygon.push([roundCoordinates(pointX2), roundCoordinates(pointY2)]);
      // const pointX3 = x - (radius / 10);
      // const pointY3 = y + ((radius / 10) - (radius / 30));
      // coordenatesPolygon.push([roundCoordinates(pointX3), roundCoordinates(pointY3)]);
      //
      // coordenatesPolygon.push(coordenatesPolygon[0]);

      /*
      coordanates = [
        [
          [x + 0.016558801807813772, y + -0.0016876790940614228],
          [x + 0.006734359784540622, y + -0.018751046556673145],
          [x + -0.011569389727829282, y + -0.009224735493489788],
          [x + 0.00007493856831342072, y + 0.010002543226967475],
          [x + 0.016558801807813772, y + -0.0016876790940614228],
        ],
      ];

      */

      // coordanates = [coordenatesPolygon];

      // Convert meters to degrees
      const metersToDegreesLat = 310 / 111320; // Conversion factor for latitude
      const metersToDegreesLng = 420 / (111320 * Math.cos(y * (Math.PI / 180))); // Conversion factor for longitude

      // Calculate the rectangle coordinates
      const halfLatOffset = metersToDegreesLat / 2;
      const halfLngOffset = metersToDegreesLng / 2;

      coordanates = [
        [
          [
            roundCoordinates(x - halfLngOffset),
            roundCoordinates(y - halfLatOffset),
          ], // Bottom left
          [
            roundCoordinates(x + halfLngOffset),
            roundCoordinates(y - halfLatOffset),
          ], // Bottom right
          [
            roundCoordinates(x + halfLngOffset),
            roundCoordinates(y + halfLatOffset),
          ], // Top right
          [
            roundCoordinates(x - halfLngOffset),
            roundCoordinates(y + halfLatOffset),
          ], // Top left
          [
            roundCoordinates(x - halfLngOffset),
            roundCoordinates(y - halfLatOffset),
          ], // Close the polygon
        ],
      ];

      var properties = {
        id: data.device.id,
        color: data.shapeColor,
        portColor: data.shapeColor,
        devicePermissions: device.permissions,
        opacity: data.opacity ?? 0.25,
      };

      feature = {
        id: 'tempId',
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: coordanates,
          //coordinates: coordenatesPolygon
        },
        properties: properties,
      };
    } else if (data.shapeName == 'circle') {
      let options: any = { steps: 60, units: 'kilometers' };
      let circle = Tcircle([x, y], radius, options);
      coordanates = circle.geometry.coordinates;
      // coordanates = [
      //   [
      //     [0, 0.006452426332714367],
      //     [-0.0009771457047627052, 0.006421353055557688],
      //     [-0.0019448772475136877, 0.006328432595189781],
      //     [-0.0028938712726045424, 0.006174560177122146],
      //     [-0.0038149851570743465, 0.0059612182463908425],
      //     [-0.004699345185677828, 0.005690462168850274],
      //     [-0.005538432120506087, 0.005364900407379025],
      //     [-0.006324163336920208, 0.004987669365128511],
      //     [-0.007048970731197812, 0.004562403139210858],
      //     [-0.007705873647125827, 0.004093198477953308],
      //     [-0.008288546117487527, 0.003584575280747515],
      //     [-0.008791377772414677, 0.003041433022751505],
      //     [-0.009209527828595299, 0.002469003525845892],
      //     [-0.009538971640921545, 0.0018728005323893626],
      //     [-0.00977653937092171, 0.001258566569013908],
      //     [-0.009919946403105229, 0.0006322176135356017],
      //     [-0.00996781522050938, -2.1390104620877537e-7],
      //     [-0.00991968853364611, -0.0006326371955083232],
      //     [-0.009776033541779583, -0.001258961806605896],
      //     [-0.009538237290822593, -0.0018731562368259347],
      //     [-0.009208593178207991, -0.0024693060276064216],
      //     [-0.00879027873982352, -0.0030416706968594553],
      //     [-0.008287324937892748, -0.003584738993531289],
      //     [-0.007704577249779021, -0.0040932819380188334],
      //     [-0.007047648935923689, -0.004562403139232174],
      //     [-0.006322866939559191, -0.004987585905098513],
      //     [-0.005537210940889992, -0.005364736694644989],
      //     [-0.004698246153054697, -0.005690224494777851],
      //     [-0.0038140505066550645, -0.00596091574466584],
      //     [-0.0028931369224807213, -0.006174204472721101],
      //     [-0.0019443714183502436, -0.00632803735763332],
      //     [-0.000976887835289375, -0.006420933473606283],
      //     [0, -0.006451998530664582],
      //     [0.000976887835289375, -0.006420933473606283],
      //     [0.0019443714183502436, -0.00632803735763332],
      //     [0.0028931369224807213, -0.006174204472721101],
      //     [0.0038140505066550645, -0.00596091574466584],
      //     [0.004698246153054697, -0.005690224494777851],
      //     [0.005537210940889992, -0.005364736694644989],
      //     [0.006322866939559191, -0.004987585905098513],
      //     [0.007047648935920137, -0.004562403139232174],
      //     [0.007704577249779021, -0.0040932819380188334],
      //     [0.008287324937892748, -0.003584738993531289],
      //     [0.00879027873982352, -0.0030416706968594553],
      //     [0.009208593178207991, -0.0024693060276064216],
      //     [0.009538237290822593, -0.0018731562368259347],
      //     [0.009776033541779583, -0.001258961806605896],
      //     [0.00991968853364611, -0.0006326371955083232],
      //     [0.009967815220505827, -2.1390104620877537e-7],
      //     [0.009919946403105229, 0.0006322176135356017],
      //     [0.00977653937092171, 0.001258566569013908],
      //     [0.009538971640917993, 0.0018728005323893626],
      //     [0.009209527828595299, 0.002469003525845892],
      //     [0.008791377772414677, 0.003041433022751505],
      //     [0.008288546117487527, 0.003584575280747515],
      //     [0.00770587364712938, 0.004093198477953308],
      //     [0.007048970731197812, 0.004562403139210858],
      //     [0.006324163336916655, 0.004987669365128511],
      //     [0.00553843212050964, 0.005364900407379025],
      //     [0.004699345185677828, 0.005690462168850274],
      //     [0.0038149851570743465, 0.0059612182463908425],
      //     [0.0028938712726045424, 0.006174560177122146],
      //     [0.0019448772475136877, 0.006328432595189781],
      //     [0.0009771457047591525, 0.006421353055557688],
      //     [0, 0.006452426332714367],
      //   ],
      // ];

      // coordanates[0] = coordanates[0].map((c) => {
      //   c[0] = c[0] + x;
      //   c[1] = c[1] + y;
      //   return c;
      // });
      feature = {
        id: data.device.id,
        type: 'Feature',
        properties: {
          center: [x, y],
          isCircle: true,
          radiusInKm: radius,
          portColor: portColor,
          devicePermissions: device.permissions,
          opacity: data.opacity ?? 0.25,
        },
        geometry: {
          coordinates: coordanates,
          type: 'Polygon',
        },
      };
    }

    let type, coordinates, stringCoordinates, options;
    switch (data.shapeName) {
      case 'polygon':
        type = 3;
        break;
      case 'circle':
        type = 1;
        break;
      default:
        break;
    }

    stringCoordinates = '[';
    let index = 0;
    options = '{}';
    coordanates.forEach((coordinateArray) => {
      coordinateArray.forEach((coordinate) => {
        stringCoordinates = stringCoordinates.concat('[');
        stringCoordinates = stringCoordinates.concat(coordinate.toString());
        if (index === coordinateArray.length - 1) {
          stringCoordinates = stringCoordinates.concat(']');
        } else {
          stringCoordinates = stringCoordinates.concat('],');
        }
        index = index + 1;
      });
    });

    stringCoordinates = stringCoordinates.concat(']');
    if (type === 1) {
      const centerArray = [x, y];
      let centerCoordinates = '[';
      centerCoordinates = centerCoordinates.concat(centerArray.toString());
      centerCoordinates = centerCoordinates.concat(']');
      options =
        '{"color":"' +
        portColor +
        '","fillColor":"' +
        portColor +
        '","radius":"' +
        radius * 1000 +
        '","center":"' +
        centerCoordinates +
        '", "opacity": 0.25}';
    } else {
      options =
        '{"color":"' +
        portColor +
        '","fillColor":"' +
        portColor +
        '", "opacity": 0.25 }';
    }

    authService
      .createGeofence(device.id, type, options, stringCoordinates)
      .then((res) => {
        if (res) {
          device.geofences.push(res);
          this._devicesService.updateDeviceInfo(device);
          let geoFeature = this.map._createGeoFeature(res, 1, device);
          this.map.drawGeofence(geoFeature, true);

          coordanates = coordanates[0];
          var bounds = coordanates.reduce(
            function (bounds, coord) {
              return bounds.extend(coord);
            },
            new this.map.LngLatBounds(coordanates[0], coordanates[0]),
          );
          let coords = centerMapOnGeofence(geoFeature, 0.2);

          this.map.map.fitBounds(coords, { padding: 20 });
          this.map.openGeofencePopupAndSetEditMode(geoFeature);
        }
      });
  }
  openWifiHomeInfoPopup(ev) {
    console.log('evvvvv ', ev);
    this.tabsService?.openOptionsSource.emit(null)// for petmode
    const device = this._devicesService.devices[ev.feature.properties.deviceId];
    let popupContent = this.dinamicComponent.injectComponent(
      MapWifiPopupComponent,
      (x) => {
        (x.device = device), (x.feature = ev.feature);
      },
    );

    const latLng = [ev.feature.properties.lng, ev.feature.properties.lat];
    const wifiIndex = ev.feature.properties.wifiIndex;

    setTimeout(() => {
      window['wifiHomePopup'] = new this.map.Popup({
        offset: 25,
        className: 'new-map-popup',
      })
        .setLngLat(latLng)
        .setDOMContent(popupContent)
        .addTo(this.map.map);
    }, 20);
  }
  hideMapPopup() {
    //_ Hide popup if is open
    const popup = document.getElementsByClassName('mapboxgl-popup');
    //_ Remove previous popup
    if (popup.length) popup[0].remove();
  }
  myToast: any = null;
  async showToast(title, text, time, colorType) {
    if (this.myToast) {
      this.myToast.dismiss();
    }

    this.myToast = await this.toastController.create({
      message: text,
      duration: time,
      color: colorType,
      position: 'bottom',
      header: title,
      cssClass: 'centerToast',
    });

    this.myToast.present();
  }
      //_ Show notification detail modal
      async showDetail(notification: any) {
        let key = translationsKeys.find((x) => x.id == notification.meldungtyp);
        notification.bezeichnung = key?.title;
        notification.meldung = key.description;
        if (
          (notification.meldungtyp == 10 || notification.meldungtyp == 11) &&
          !notification?.name
        ) {
          notification.meldung = notification.meldung + 'NM'; // no name
        }
        if (notification) {
          const modal = await this.modalController.create({
            component: DetailsPage,
            componentProps: {
              notification: notification,
            },
          });

          await modal.present();
        } else {
          this.showToast(
            this._translate.instant('notifications.errorOpenHeader'),
            this._translate.instant('notifications.errorOpenDetails'),
            3000,
            'danger',
          );
        }
      }

      showGeofencePopup(ev) {
        const device = this._devicesService.devices[ev.feature.properties.deviceId];
        let popupContent = this.dinamicComponent.injectComponent(
          MapGeofenceComponent,
          (x) => (
            (x.feature = ev.feature),
            (x.device = device),
            (x.drawer = this.map.geofenceDrawer)
          ),
        );

        setTimeout(() => {
          window['geofencePopup'] = new this.map.Popup({
            offset: 25,
            className: 'new-map-popup',
          })
            .setLngLat(ev.event.lngLat)
            .setDOMContent(popupContent)
            .addTo(this.map.map);
        }, 20);
      }
        // Hide/Show all information about a device in the map
  // Also update the user info with the api
  async onToggleDeviceShow(data) {
    await this.authService
      .updateDeviceShow(data.deviceId, data.checked ? 1 : 0)
      .then(async (r) => {
        // Show the toast and update the status in the list of devices
        // In Device Data Service
        if (r) {
          this._devicesService.devices[data.deviceId].properties.deviceshow =
            data.checked;
          this.showToast(
            null,
            data.checked
              ? this._translate.instant('deviceManagement.deviceVisibleSuccess')
              : this._translate.instant('deviceManagement.deviceHideSuccess'),
            3000,
            'success',
          );
          this.handleDeviceHideShow(data);
        }
      })
      .catch((error) => {
        this.appService.showToast(
          '',
          this._translate.instant('deviceManagement.errorUpdatingStatus'),
          3000,
          'danger',
        );
      });
  }
  async handleDeviceHideShow(data) {
    //_ Enable|Disable request data if is enabled and add the array to request last location in the timer
    if (data.checked) await this._devicesService.enableDevice(data.deviceId);
    else this._devicesService.disableDevice(data.deviceId);

    let device = this._devicesService.devices[data.deviceId];

    //_ Show/hide layers in map if the marker exists
    //_ else it should add marker and all other layers
    if (this.map.hasMarker(data.deviceId)) {
      this.map.setShowPropertie(device, data.checked);
    } else if (data.checked && device) {
      this.map._addMarker(device, null, false);
      this._devicesService.updateOnlineStatus(device.lastPoint);

      //_ Get datapoints
      await this._devicesService.getDataPoints([device.id]).then((r) => {
        if (r.datapoints && Array.isArray(r.datapoints)) {
          r.datapoints = r.datapoints.sort((a, b) =>
            a.dateunix > b.dateunix ? 1 : -1,
          );
          device.init(r.datapoints);
          this.map._addDataPoints(device, true, false, false);
          this.map._addDirectionMarkers(device, true, false, false);
          device.newDataPoints = [];
          device.newDirectionMarkers = [];
          this.map.updateDataSources();
        }
      });

      //_ Request pauses for the device
      this._devicesService
        .getSnapedAndPausePoints(device.dataPoints, 0.05, false)
        .then((res: any) => {
          device.pauseMarkers = res.pauses;
          device.newPauseMarkers = device.pauseMarkers;
          if (device.newPauseMarkers.length > 0)
            this.map._addPauseMarker(device);
          device.newPauseMarkers = [];
        });

      //_ Get snaped and pauses markers
      this._devicesService
        .getSnapedAndPausePoints(device.dataPoints)
        .then((res: any) => {
          device.snapedPoints = res.snapPoints;
          device.newSnapedPoints = device.snapedPoints;

          // If snaped to the route flag  is active and if the valhalla response has snaped points
          if (
            this._devicesService.user.snap_to_road &&
            device.snapedPoints.length > 0
          )
            this.map._addDataPoints(device, true, false, true); // True flag to delete all datapoints in the feature

          // Setted new snapd and pauses points
          device.newSnapedPoints = [];
          device.newPauseMarkers = [];
        });

      //_ Get Geofences
      //_ alarm_geofence toggle option for v1 - removed for v2
      // if (device.properties.alarm_geofence == 1)
      this._devicesService.getGeoFences([device.id]);

      //_ Get Alarms
      this._devicesService.getNotifications([device.id]);

      this._devicesService.getWifiHomeMarkers([device.id]);
    }
  }
  getUserInfo() {
    return new Promise((resolve, reject) => {
      if (this.appService.user) resolve(this.appService.user);
      else {
        this.appService.user$.pipe(first()).subscribe((u) => {
          resolve(u);
        });
      }
    });
  }
}
