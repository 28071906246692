import { NgModule } from "@angular/core";
// import { BackCloseDirective } from "./backclose.directive";
import { BackLocaleDirective } from "./backlocale.directive";
// import { DragulaExtendedDirective } from "./dragula-extended.directive";
import { DecimalMaskDirective } from "./decimal-mask.directive";
import { BackgroundContrastDirective } from "./background-contrast";
import { LongPressDirective } from "./longpress.directive";
import { IonselectAutoscrollDirective } from "./ion-select-autoscroll.directive";
import { HighLightGroupDirective } from "./highlight-groupItem";
import { InputValidationDirective } from "./input-validation.directive";
import { HideHeaderDirective } from "./hide-header";
import { InfiniteScrollFixDirective } from "./infinite-fix.directive";
import { InViewPortDirective } from "./in-viewPort.directive";
import { GestureDetectDirective } from "./gesture-detect.directive";
import { IsInViewPortDirective } from "./is-in-viewport.directive";

@NgModule({
  declarations: [
    // BackCloseDirective,
    // DragulaExtendedDirective,
    BackLocaleDirective,
    DecimalMaskDirective,
    BackgroundContrastDirective,
    LongPressDirective,
    IonselectAutoscrollDirective,
    HighLightGroupDirective,
    InputValidationDirective,
    HideHeaderDirective,
    InfiniteScrollFixDirective,
    InViewPortDirective,
    IsInViewPortDirective,
    GestureDetectDirective
  ],
  imports: [],
  exports: [
    // BackCloseDirective,
    // DragulaExtendedDirective,
    BackLocaleDirective,
    DecimalMaskDirective,
    BackgroundContrastDirective,
    LongPressDirective,
    IonselectAutoscrollDirective,
    HighLightGroupDirective,
    InputValidationDirective,
    HideHeaderDirective,
    InfiniteScrollFixDirective,
    InViewPortDirective,
    IsInViewPortDirective,
    GestureDetectDirective
  ],
})
export class DirectivesModule {}
