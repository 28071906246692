import { ApiService } from 'src/app/services/api.service';

export class mapUtil {
  constructor(private api: ApiService) {

  }

  public stringToCoordinates(str, precision) {
    let index = 0, lat = 0,
      lng = 0, coordinates = [],
      shift = 0, result = 0,
      byte = null, latitudeChange,
      longitudeChange;
    const factor = Math.pow(10, precision || 6);
    const allCoordinates: number[][] = [];
    // Coordinates have variable length when encoded, so just keep
    // track of whether we've hit the end of the string. In each
    // loop iteration, a single coordinate is decoded.
    while (index < str.length) {

      // Reset shift, result, and byte
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        // tslint:disable-next-line: no-bitwise
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      // tslint:disable-next-line: no-bitwise
      latitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

      shift = result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        // tslint:disable-next-line: no-bitwise
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      // tslint:disable-next-line: no-bitwise
      longitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

      lat += latitudeChange;
      lng += longitudeChange;
      coordinates = [lng / factor, lat / factor];

      allCoordinates.push(coordinates);
    }
    return allCoordinates;
  }

  async traceRoute(points, costing = "auto") {
    let data = [];
    return await this.api.getRoute(points, costing).then(async (res: any) => {
      if (res.data) {
        try {
          data = this.stringToCoordinates(res.data.trip.legs[0].shape, 6);
          return { points: data, response: res.data };
        }
        catch (err) { //If no return data, then return sent points...
          points.forEach(p => {
            data.push([p.lng, p.lat]);
          });
          return { points: data, response: null };
        }
      }
    });
  }

  async resizeImage(base64Str, maxWidth = 400, maxHeight = 350, fill = 'white', cutCircle = true) {
    return new Promise(async (resolve) => {
      let img = new Image();
      img.onload = () => {
        let canvas = document.createElement('canvas')
        let width = maxWidth;
        let height = maxHeight;

        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d', { willReadFrequently: true });


        ctx.beginPath();
        if (cutCircle) {
          ctx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, true);

          ctx.fillStyle = 'rgba(255,255,255,0)'; //base64Str.startsWith('https://')?'rgba(255,255,255,0.1)':'rgba(255,255,255,0)';
          ctx.fill();
          ctx.closePath();
          ctx.clip();
        }

        ctx.drawImage(img, 0, 0, width, height);

        ctx.beginPath();
        ctx.arc(0, 0, 2, 0, Math.PI * 2, true);
        ctx.clip();
        ctx.closePath();
        ctx.restore();


        resolve(canvas.toDataURL());
      }

      img.crossOrigin = "Anonymous";
      img.src = base64Str;
    })
  }

}