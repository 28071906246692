import * as moment from "moment";
import { DeviceDataService } from "./devicesData.service";

export class liveTrackingDevicesHandle{
    liveTrackingDevicesIds = [];
    liveTimers = [];
    timerTime = 20 * 1000; //_ mili seconds
    constructor (private devicesService: DeviceDataService){

    }

    //_ Get the devices which has activated and diff(now & start_time) < tracking_duration in seconds
    Initialize(){
      this.liveTrackingDevicesIds = Object.values(this.devicesService.devices)
      .filter(d => {
          return d.properties.live_tracking_start_time != null && this.getTimeDiff(d) < d.properties.live_tracking_duration;
      })
      .map(d => d.id);

        this.liveTrackingDevicesIds.forEach( deviceId => {
            this.startLiveTracking(deviceId);
        });
    }

    startLiveTracking(deviceId){
        this.removeFromDevicesEnable(deviceId);
        this.liveTimers[deviceId] = setInterval(() => this.requestLastLocation(deviceId), this.timerTime);
    }

    stopLiveTracking(deviceId){
        this.removeFromLiveTracking(deviceId);
        if (this.liveTimers[deviceId])
            clearTimeout(this.liveTimers[deviceId]);
    }

    requestLastLocation(deviceId){
        if (this.shouldStop(deviceId)){
            this.stopLiveTracking(deviceId);
            return;
        }
        this.devicesService._updateLastPosition([deviceId]);
    }

    shouldStop(deviceId){
        const device = this.devicesService.devices[deviceId];
        return this.getTimeDiff(device) > device.properties.live_tracking_duration;
    }

    removeFromDevicesEnable(deviceId){
        this.devicesService.enabledDevicesIds.splice(this.devicesService.enabledDevicesIds.indexOf(deviceId), 1);
    }

    removeFromLiveTracking(deviceId){
        this.liveTrackingDevicesIds.splice(this.liveTrackingDevicesIds.indexOf(deviceId), 1);
        //_ Remove from devices enabled
        this.devicesService.enabledDevicesIds.push(deviceId);
    }

    getTimeDiff(device){
        return moment().diff( moment.unix(device.properties.live_tracking_start_time/1000), 'seconds');
    }

    stopAll(){
        this.liveTrackingDevicesIds.forEach( deviceId => {
            this.stopLiveTracking(deviceId);
        });
    }

    isRunning(deviceId){
        return this.liveTrackingDevicesIds.find(d => d == deviceId) != null;
    }

}
