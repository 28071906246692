<ion-header *ngIf="title">
  <ion-toolbar>
    <ion-item color="transparent" lines="none">
      <ion-label class="ion-text-wrap ion-text-center"> <h3>{{ title }}</h3></ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content class="language-popover-content">
  <ion-list lines="none" mode="md">
    <ion-radio-group [value]="selectedLan?.id">
      <ion-item *ngFor="let lan of languages" detail="false" (click)="selectLanguage(lan)">
        <ion-icon [src]="lan.img" slot="start"></ion-icon>
        <ion-label>{{ lan.name }}</ion-label>
        <ion-radio [value]="lan.id" slot="end"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
