
<ion-card class="ion-no-margin" style="box-shadow: none">
  <ion-button
    color="primary"
    shape="round"
    fill="clear"
    class="close-button"
    (click)="close()"
  >
    <ion-icon name="close"></ion-icon>
  </ion-button>
  <div class="ion-text-center ion-margin-top" *ngIf="!useGif">
    <ion-icon
      *ngIf="!image?.endsWith('.svg')"
      name="information-circle"
      style="font-size: 2.5rem"
      color="primary"
    >
    </ion-icon>
    <ion-icon
      *ngIf="image?.endsWith('.svg')"
      [src]="image"
      color="primary"
      style="font-size: 2.5rem"
    ></ion-icon>


  </div>
  <ion-card-header *ngIf="subtitle">
    <ion-card-subtitle class="ion-text-center"
      >{{ subtitle }}
    </ion-card-subtitle>
  </ion-card-header>

  <ion-card-content class="ion-no-padding" style="padding: 10px">
    <!-- <ion-img *ngIf="useGif" [src]="image"></ion-img> -->
    <img *ngIf="useGif" [src]="image">
    <p style="color: var(--ion-color-light-contrast);">{{ description }}</p>
  </ion-card-content>

</ion-card>
