import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Constants } from '../constants.enum';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;
  storageReady = new BehaviorSubject(false);

  constructor(private storage: Storage) {
    // this.init();
  }

  async init() {
    //await this.storage.defineDriver(CordovaSQLiteDriver);
    const storage = await this.storage.create();
    this._storage = storage;
    this.storageReady.next(true);
    return this._storage;
  }

  //_ Decrypt
  async set(key: string, value: any): Promise<any> {
    return await this._storage?.set(key, value);
  }

  //_ Encrypt
  async get(key: string): Promise<any> {
    return await this._storage?.get(key);
  }

  async remove(key: string): Promise<any> {
    return await this._storage?.remove(key);
  }

  async clear() {
    return this._storage.clear();
  }

  storageIsReady() {
    return new Promise((resolve, reject) => {
      this.storageReady.subscribe(r => resolve(r));
    })
  }

  async getFromLocalOrSession(key: string): Promise<any> {
    return new Promise ( async (resolve) => {
      const localValue = await this._storage?.get(key);
      const sessionValue = sessionStorage.getItem(key);
      resolve(localValue ?? sessionValue)
    })
  }
}