import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CustomAlertComponent } from './custom-alert.component';


@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      PipesModule.forRoot(),
      TranslateModule,
      DirectivesModule
    ],

    declarations: [CustomAlertComponent],
    exports: [CustomAlertComponent, PipesModule],
    
  })

  
  export class PajServerCustomAlertModule {}