<ion-header [translucent]="true">
    <ion-toolbar color="transparent">
      <ion-buttons slot="start">
        <ion-back-button
          style="display: block !important"
          back-close
          back-locale
          >
          </ion-back-button>
        </ion-buttons>

        <ion-title>{{ 'devicesSetup.deviceGroup.SelectGroup' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="ion-padding no-scrollbars">
    <ion-img src="assets/images/devices-setup/groups-illustration.svg"></ion-img>
    <ion-list>
        <ion-label>{{ 'devicesSetup.deviceGroup.chooseGroup' | translate }}</ion-label>
        <ng-container  *ngFor="let group of deviceGroups">
            <ion-item [detail]="false" lines="none" button  *ngIf="group.group_position != -1" (click)="selectGroup(group)">
                <ion-icon slot="start" [color]="group.id === device?.selectedGroup?.id ? 'primary' : ''"
                    [name]="group.id === device?.selectedGroup?.id ? 'radio-button-on-outline' : 'radio-button-off-outline'">
                </ion-icon>
                <ion-label>{{ group.group_name }}</ion-label>
            </ion-item>
        </ng-container>
    </ion-list>
</ion-content>
