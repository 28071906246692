<ion-header [translucent]="true">
  <ion-toolbar color="transparent">
    <ion-buttons slot="start">
      <ion-back-button
        style="display: block !important"
        back-close
        back-locale
        >
        </ion-back-button>
      </ion-buttons>

      <ion-title>{{ 'devicesSetup.deviceImage.deviceImageText' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" clasS="ion-padding no-scrollbars">
  <ion-list class="ion-no-padding">
    <div class="image-container">
      <ion-avatar slot="start">
        <img
          class="deafultIcon"
          [src]="iconUrl"
          style="object-fit: contain"
          *ngIf="iconUrl"
        />
        <ion-img
          *ngIf="!iconUrl"
          style="object-fit: contain"
          [src]="device | DeviceImage : appService.user.id | async"
        >
        </ion-img>
      </ion-avatar>
      <ion-icon class="image-header" [style.fill]="device?.spurfarbe" src="assets/images/devices-setup/flat-map.svg"></ion-icon>

      <ion-button (click)="DeviceImg()" color="primary" shape="round" color="light" class="ion-no-shadow">
        <ion-icon name="camera-outline" color="primary" slot="start"></ion-icon>
        <ion-label style="text-transform: math-auto;">{{ "deviceManagement.deviceSettings.changePhotoText" | translate }}</ion-label>
      </ion-button>
      <!--
      <div style="display: flex;justify-content: center;">
        <ion-button mode="md"  color="primary" fill="solid" (click)="nextSetting()">
          {{ 'devicesSetup.guideSteps.nextButton' | translate }}
        </ion-button>
      </div>
      !-->

      <div style="display: flex;justify-content: center;">
        <ion-button mode="md"  color="primary" fill="solid" (click)="nextSetting()">          
          <div style="display: inline-block;flex-direction: column;">
            <ion-label>{{ 'devicesSetup.guideSteps.nextButton' | translate }}</ion-label><br />
            <small>{{ 'devicesSetup.deviceAlarms.deviceAlarms' | translate }}</small>
          </div>
        </ion-button>
      </div>
            
    </div>
  </ion-list>
</ion-content>
