import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharelinkPopupComponent } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/info/sharelink-popup/sharelink-popup.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { VibrateOnToggleDirectiveModule } from 'src/app/directives/vibration-for-toggle-buttons.module';
import { PajLoadingIndicatorModule } from 'src/app/components/loading-indicator/paj-loading-indicator.module';
import { BackcloseDirectiveModule } from 'src/app/directives/backclose-directive.module';

@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      PipesModule.forRoot(),
      DirectivesModule,
      VibrateOnToggleDirectiveModule,
      TranslateModule,
      PajLoadingIndicatorModule,
      BackcloseDirectiveModule
    ],

    // entryComponents: [SharelinkPopupComponent],
    declarations: [SharelinkPopupComponent],
    exports: [SharelinkPopupComponent, PipesModule, DirectivesModule, VibrateOnToggleDirectiveModule],
    
  })

  export class SharelinkOptionsModule {}