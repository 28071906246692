import { Injectable } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageSetting, STORAGE_SETTINGS } from 'src/app/services/storage-settings';
import { StorageService } from 'src/app/services/storage.service';
import { DefaultFilters } from './filters/filters-helper';

@Injectable({
  providedIn: 'root'
})
export class DevicesSidebarService {
  isOpen = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICES_SIDEBAR_STATUS, false);
  fabList = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICES_FAB_LIST, []);
  filters = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICES_SIDEBAR_FILTERS, JSON.parse(JSON.stringify(DefaultFilters)) );
  //_ used to storage groups and devices
  groups = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICES_SIDEBAR_GROUPS, null);
  devicesFlatList = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICES_SIDEBAR_LIST, null);
  userPermissions = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICES_SIDEBAR_USER_PERMISSIONS, null);

  get navigation() { return this._navigation; };
  _navigation: IonNav = null;
  dragableHeader = new BehaviorSubject(null);
  isOpeninPullup = false;
  
  toggleDevice = new Subject();
  flyToDevice = new Subject();
  enableDragPullup = new BehaviorSubject<boolean>(true);
  groupDeleted = new Subject();
  groupsUpdated = new Subject();
  groupAdded = new Subject();
  //_ List of Devices Group DATA -> returned by api
  devicesGroups = [];
  openMenuOptions = new Subject();
  activePage = '';
  obs = [];
  selectedDevice = new BehaviorSubject<any>(null);
  triggerSwipeDevice = new Subject();
  startDevicesListTour = new Subject<boolean>();

  constructor(private storage: StorageService) { }

  set navigation(value:any) {
    this._navigation = value;
    if (this.obs['navigationListener']) this.obs['navigationListener'].unsubscribe();
    this.obs['navigationListener'] = value.ionNavDidChange.subscribe( (r: any) => {
      const lastElement = r.srcElement.lastChild;
      if (lastElement)
        this.activePage = lastElement.tagName;
    })
  }

  //_ Sync devices list with latest updated list
  syncDevicesList(newList){
    const storagedList = this.devicesFlatList.get().value;
    storagedList.map( (d, i) => {
      const updatedDevice = newList.find(newD => newD.id == d.id);
      storagedList[i] = updatedDevice;
    });
    this.devicesFlatList.set(storagedList);
  }

  //_ Update only the first MAX_ITEMS_PER_PAGE of the array
  syncReorderedDevicesList(newList){
    const MAX_ITEMS_PER_PAGE = 20;
    const newSortedList = newList.slice(0, MAX_ITEMS_PER_PAGE);
    // console.log('NEW SORTED LIST sliced', newSortedList);
    this.devicesFlatList.set(newSortedList);
  }

  resetFilters(){
    this.filters.set( JSON.parse(JSON.stringify(DefaultFilters)) );
  }

}
