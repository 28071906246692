<div isInViewport (inView)="isInViewport()" (outView)="outOfViewport()" class="content">
  <ion-toolbar color="transparent" class="ion-no-padding">
    <ion-item slot="start" color="transparent" class="header-device-item" lines="none" button detail="false">
      <ion-img
        src="{{ device | DeviceImage : userId | async }}"
        slot="start"
        *ngIf="device"
        @zoomIn
      ></ion-img>
      <ion-label>
        <h2 class="device-name">
          {{ device?.name }}
          <p>{{ device?.imei }}</p>
        </h2>
      </ion-label>
      <!-- <ion-icon name="chevron-down-outline" slot="end"></ion-icon> -->
    </ion-item>
  </ion-toolbar>

  <ion-row>
    <ion-col size-md="12" size-xs="12" class="alarm-data ion-no-padding">
      <div class="alarm-icon">
        <ion-icon [src]="alarmData?.img" color="primary"></ion-icon>
      </div>
      <ion-item lines="none" class="alarm-text">
        <ion-label class="ion-text-wrap">
          <h2 class="text-no-wrap">{{ alarmData?.title | translate }}</h2>
          <p class="ion-text-wrap">{{ alarmData?.description | translate }}</p>
        </ion-label>
      </ion-item>
    </ion-col>

    <ion-col size-md="12" size-xs="12" class="ion-no-padding">
      <ion-spinner *ngIf="alarm" name="dots" color="primary" class="loading-spinner" [hidden]="img?.src != whitePicture" ></ion-spinner>
      <ion-img src="{{ (imageFromMedia ? imageFromMedia : mapPreview) | async }}" class="map-preview" #img></ion-img>
    </ion-col>
  </ion-row>

  <ion-card class="alarm-card ion-no-padding">
    <ion-card-content class="ion-no-padding">
      <!-- <ion-badge color="light">{{ route?.end_dateunix | translateUnixDate : "dddd DD MMMM, YYYY" }}</ion-badge> <br /> -->
      <ion-item lines="none" class="ion-no-padding" color="transparent">
        <ion-icon name="time-outline" color="primary" slot="start"></ion-icon>
        <ion-label>
          <p><b>{{ alarm?.dateunix | translateUnixDate : "HH:mm" }}</b> {{ alarm?.dateunix | translateUnixDate : "dddd DD MMMM, YYYY" }}</p>
        </ion-label>
      </ion-item>
      <ion-item lines="none" class="ion-no-padding" color="transparent">
        <ion-icon name="location-outline" color="primary" slot="start"></ion-icon>
        <ion-label>
          <p class="text-no-wrap">{{ address }}</p>
        </ion-label>
      </ion-item>
    </ion-card-content>
  </ion-card>
</div>
