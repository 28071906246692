<div>
  <ion-card
    class="card slideDown"
    [hidden]="!alert.show"
    *ngIf="alert"
  >
    <ion-card-header>
      <ion-button
        slot=""
        color="dark"
        shape="round"
        fill="clear"
        class="close-button"
        (click)="close(alert)"
      >
        <ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-card-header>

    <img [src]="'assets/images/server-alert/' + alert.type + '.svg'" />

    <ion-card-content>
      <p
        class="ion-text-center"
        style="white-space: pre-wrap"
        [innerHTML]="
          alert[messageKey] ? alert[messageKey] : (alert.message | sanitizeHtml)
        "
      ></p>
    </ion-card-content>

    <ion-segment class="transparent">
      <ion-button color="primary" shape="round" (click)="continue(alert)">
        <ion-label>{{ "whatsnew.continueText" | translate }}</ion-label>
      </ion-button>
    </ion-segment>
  </ion-card>
</div>
