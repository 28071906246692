import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TabsService {
   viewWillEnter = new Subject();   
  constructor() {}
}