import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { Subject } from "rxjs";
import { Constants } from "src/app/constants.enum";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DeviceDataService } from "../../../devicesData.service";

@Injectable({ providedIn: 'root' })
export class Geofences {
  constructor(private authService: AuthenticationService, private storage: Storage,
    private devicesService: DeviceDataService) { }

  public overrideDrawFunctions(geofenseDrawer, map, devices, portColor) {

    let deviceMarkers = devices;
    const draw = geofenseDrawer;
    const authService = this.authService;
    const storageService = this.storage;
    let that = this;

    map.on('draw.update', async (event) => {
      var feature = (event.features[0]);
      var opacity = feature.properties.opacity ? feature.properties.opacity : 1

      if (feature.type === 'Feature') {
        let type, coordinates, stringCoordinates, options;
        switch (feature.geometry.type) {
          case 'Polygon':
            if (feature.properties.isCircle) {
              type = 1;
            } else {
              type = 3;
            }
            break;
          case 'Circle':
            type = 1;
            break;
          default:
            break;
        }

        stringCoordinates = '[';
        let index = 0;
        options = '{}';
        coordinates = feature.geometry.coordinates;
        const dID = feature.id.split('_');
        coordinates.forEach(coordinateArray => {
          coordinateArray.forEach(coordinate => {
            stringCoordinates = stringCoordinates.concat('[');
            stringCoordinates = stringCoordinates.concat(coordinate.toString());
            if (index === coordinateArray.length - 1) {
              stringCoordinates = stringCoordinates.concat(']');
            } else {
              stringCoordinates = stringCoordinates.concat('],');
            }
            index = index + 1;
          });
        });

        stringCoordinates = stringCoordinates.concat(']');
        if (feature.properties.portColor) {
          var portColor = feature.properties.portColor;
        }

        if (type === 1) {
          const centerArray = feature.properties.center;
          let centerCoordinates = '[';
          centerCoordinates = centerCoordinates.concat(centerArray.toString());
          centerCoordinates = centerCoordinates.concat(']');
          options = '{"color":"' + portColor +
            '","fillColor":"' + portColor + '","radius":"' + (feature.properties.radiusInKm * 1000) +
            '","center":"' + centerCoordinates + '", "opacity": ' + opacity + '}';

          const dID = feature.id.split('_');
          // authService.updateRadiusInfo(+ dID[1],
          //     feature.properties.radiusInKm * 1000,
          //     centerArray[1],
          //     centerArray[0]);
        } else {
          options = '{"color":"' + portColor +
            '","fillColor":"' + portColor + '", "opacity": ' + opacity + '}';
        }

        await that.authService.updateGeofence(+ dID[0], + dID[1], stringCoordinates, type, options, feature.properties.name).then(res => {
          if (res) {
            if (res.success) {
              res = res.success;
              that.devicesService.setGeofence(res);
            }
          }
        });
      }
    });

    map.on('draw.create', async function (event) {
      let selDeviceID;
      await storageService.get(Constants.SELECTED_DEVICE_DATA).then(res => {
        selDeviceID = res.deviceID;
      });

      let type, coordinates, stringCoordinates, options;
      switch (event.features[0].geometry.type) {
        case 'Polygon':
          if (event.features[0].properties.isCircle) {
            type = 1;
          } else {
            type = 3;
          }
          break;
        case 'Circle':
          type = 1;
          break;
        default:
          break;
      }

      stringCoordinates = '[';
      let index = 0;
      options = '{}';
      coordinates = event.features[0].geometry.coordinates;
      coordinates.forEach(coordinateArray => {
        coordinateArray.forEach(coordinate => {
          stringCoordinates = stringCoordinates.concat('[');
          stringCoordinates = stringCoordinates.concat(coordinate.toString());
          if (index === coordinateArray.length - 1) {
            stringCoordinates = stringCoordinates.concat(']');
          } else {
            stringCoordinates = stringCoordinates.concat('],');
          }
          index = index + 1;
        });
      });

      stringCoordinates = stringCoordinates.concat(']');
      var opacity = event.features[0].properties.opacity ? event.features[0].properties.opacity : 1;
      if (type === 1) {
        const centerArray = event.features[0].properties.center;
        let centerCoordinates = '[';
        centerCoordinates = centerCoordinates.concat(centerArray.toString());
        centerCoordinates = centerCoordinates.concat(']');
        options = '{"color":"' + portColor +
          '","fillColor":"' + portColor + '","radius":"' + (event.features[0].properties.radiusInKm * 1000) +
          '","center":"' + centerCoordinates + '", "opacity": ' + opacity + '}';

      } else {
        options = '{"color":"' + portColor +
          '","fillColor":"' + portColor + '", "opacity": ' + opacity + '}';
      }

      await that.authService.createGeofence(selDeviceID, type, options, stringCoordinates).then(res => {
        const polyFeature = draw.get(event.features[0].id);
        if (res) {
          const id = res.id.toString() + '_' + res.iddevice.toString();
          const allFeatures = draw.getAll();
          const indx = deviceMarkers.findIndex(obj =>
            obj.deviceID === + res.iddevice
          );
          deviceMarkers[indx].geofences.push(res);
          const index = allFeatures.features.findIndex(obj =>
            obj.id === event.features[0].id
          );
          allFeatures.features[index].id = id;
          draw.set(allFeatures);
        }
      });
    });

    map.on('draw.delete', async function (event) {
      if (event.features.length && event.features[0].type === 'Feature') {
        const dID = event.features[0].id.split('_');
        const indx = deviceMarkers.findIndex(obj =>
          obj.deviceID === + dID[1]
        );

        let removeIndex = deviceMarkers[indx].geofences.map(function (item) {
          return item.id;
        }).indexOf(+ dID[0]);

        if (removeIndex > -1) {
          deviceMarkers[indx].geofences.splice(removeIndex, 1);
        }

        await that.authService.deleteGeofence(+ dID[0], + dID[1]);
      }
    });
  }
}


export const styles = (portColor, opacity = 0.5) => {
  return [
    {
      id: 'gl-draw-polygon-fill-inactive',
      type: 'fill',
      filter: ['all', ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static']
      ],
      paint: {
        'fill-color': '#3bb2d0',
        'fill-outline-color': '#3bb2d0',
        'fill-opacity': 0.1
      }
    },
    {
      id: 'gl-draw-polygon-fill-active',
      type: 'fill',
      filter: ['all', ['==', 'active', 'true'],
        ['==', '$type', 'Polygon']
      ],
      paint: {
        'fill-color': portColor,
        'fill-outline-color': portColor,
        'fill-opacity': 0
      }
    },
    {
      id: 'gl-draw-polygon-stroke-inactive',
      type: 'line',
      filter: ['all', ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#3bb2d0',
        'line-width': 2
      }
    },
    {
      id: 'gl-draw-polygon-stroke-active',
      type: 'line',
      filter: ['all', ['==', 'active', 'true'],
        ['==', '$type', 'Polygon']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': portColor,
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    },
    {
      id: 'gl-draw-line-inactive',
      type: 'line',
      filter: ['all', ['==', 'active', 'false'],
        ['==', '$type', 'LineString'],
        ['!=', 'mode', 'static']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#3bb2d0',
        'line-width': 2
      }
    },
    {
      id: 'gl-draw-line-active',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'],
        ['==', 'active', 'true']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': portColor,
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    },
    {
      id: 'gl-draw-point-point-stroke-inactive',
      type: 'circle',
      filter: ['all', ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static']
      ],
      paint: {
        'circle-radius': 15,
        'circle-opacity': 1,
        'circle-color': '#fff'
      }
    },
    {
      id: 'gl-draw-point-inactive',
      type: 'circle',
      filter: ['all', ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static']
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': '#3bb2d0'
      }
    },
    {
      id: 'gl-draw-point-stroke-active',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'],
        ['==', 'active', 'true'],
        ['!=', 'meta', 'midpoint']
      ],
      paint: {
        'circle-radius': 7,
        'circle-color': '#fff'
      }
    },
    {
      id: 'gl-draw-point-active',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true']
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': portColor
      }
    },
    {
      id: 'gl-draw-polygon-fill-static',
      type: 'fill',
      filter: ['all', ['==', 'mode', 'static'],
        ['==', '$type', 'Polygon']
      ],
      paint: {
        'fill-color': '#404040',
        'fill-outline-color': '#404040',
        'fill-opacity': 0
      }
    },
    {
      id: 'gl-draw-polygon-stroke-static',
      type: 'line',
      filter: ['all', ['==', 'mode', 'static'],
        ['==', '$type', 'Polygon']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#404040',
        'line-width': 2
      }
    },
    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', 'mode', 'static'],
        ['==', '$type', 'LineString']
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#404040',
        'line-width': 2
      }
    },
    {
      id: 'gl-draw-point-static',
      type: 'circle',
      filter: ['all', ['==', 'mode', 'static'],
        ['==', '$type', 'Point']
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': '#404040'
      }
    },
    {
      id: 'gl-draw-polygon-color-picker',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'],
        ['has', 'user_portColor']
      ],
      paint: {
        'fill-color': ['get', 'user_portColor'],
        'fill-outline-color': ['get', 'user_portColor'],
        'fill-opacity': ['get', 'user_opacity']
      }
    },
    {
      id: 'gl-draw-line-color-picker',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'],
        ['has', 'user_portColor']
      ],
      paint: {
        'line-color': ['get', 'user_portColor'],
        'line-width': 2
      }
    },
    {
      id: 'gl-draw-point-color-picker',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'],
        ['has', 'user_portColor']
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': ['get', 'user_portColor']
      }
    },
    {
      id: 'gl-draw-polygon-midpoint-stroke',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint']
      ],
      paint: {
        'circle-radius': 6,
        'circle-color': '#e3641f'
      }
    },
    {
      id: 'gl-draw-polygon-midpoint',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint']
      ],
      paint: {
        'circle-radius': 4,
        'circle-color': '#000'
      }
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
      type: 'circle',
      filter: ['all', ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static']
      ],
      paint: {
        'circle-radius': 8, // outer circle
        'circle-color': '#e3641f'
      }
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-inactive',
      type: 'circle',
      filter: ['all', ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static']
      ],
      paint: {
        'circle-radius': 6, // inner circle
        'circle-color': '#000'
      }
    },
  ]
}

// Default layer created with mapbox drawer lib
export const coldLayer = () => {
  return {
    "id": "gl-draw-polygon-fill-inactive.cold",
    "type": "fill",
    "filter": [
      "all",
      [
        "==",
        "active",
        "false"
      ],
      [
        "==",
        "$type",
        "Polygon"
      ],
      [
        "!=",
        "mode",
        "static"
      ]
    ],
    "paint": {
      "fill-color": "#3bb2d0",
      "fill-outline-color": "#3bb2d0",
      "fill-opacity": 0.1
    },
    "source": "mapbox-gl-draw-cold"
  };
}