import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RequestPermissionsComponent } from './request-permissions.component';


@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      TranslateModule
    ],

    declarations: [RequestPermissionsComponent],
    exports: [RequestPermissionsComponent],
    providers: [AndroidPermissions]
  })

  export class PajRequestPermissionModule {}