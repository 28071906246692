
<ion-content>
  <ion-card-header color="primary" class="square-background">
    <!-- <ion-img src=""></ion-img> -->
    <li *ngFor="let in of [].constructor(10)"></li>
    <ion-card-subtitle class="ion-text-wrap ion-text-center">{{'whatsnew.newAppUpdate' | translate}}</ion-card-subtitle>
    <ion-card-title class="ion-text-center">{{'whatsnew.newMomentText' | translate}}</ion-card-title>
  </ion-card-header>

  <ion-card class="ion-no-margin ion-no-shadow">
    <ion-card-content>
      <ng-container [ngTemplateOutlet]="showWhatsNew ? FeatureList : BugFixes"></ng-container>
    </ion-card-content>
  </ion-card>
  
</ion-content>

<!-- FEATURE LIST TEMPLATE -->
<ng-template #FeatureList>
  <ion-list color="transparent">
    <ion-item
      class="ion-no-padding"
      lines="none"
      *ngFor="let item of alertArray"
      color="transparent"
    >
      <ion-icon color="primary" name="download-outline" slot="start"></ion-icon>
      <ion-label style="white-space: inherit"> {{item}} </ion-label>
    </ion-item>
  </ion-list>
</ng-template>

<!-- BUG FIXES TEMPLATE -->
<ng-template #BugFixes>
  <ion-item
      class="ion-no-padding"
      lines="none"
      *ngIf="!showWhatsNew"
      color="transparent"
    >
      <ion-icon color="primary" name="download-outline" slot="start"></ion-icon>
      <ion-label style="white-space: inherit">{{'whatsnew.bugFix' | translate}}</ion-label>
    </ion-item>
</ng-template>


<!-- FOOTER -->
<ion-footer class="ion-no-border">
  <div class="content">
    <ion-button class="ion-no-shadow" shape="round" color="light" (click)="close()">
      {{'whatsnew.later' | translate}}
    </ion-button>
    <ion-button class="ion-no-shadow" shape="round" color="primary" (click)="update()">
      {{'whatsnew.updateNow' | translate}}
    </ion-button>
  </div>
</ion-footer>