import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { StorageService as Storage } from "../../../services/storage.service";
import {
  statusType,
  TimerCountComponent,
} from "../../generate-video/timer-count/timer-count.component";
import { NotificationsService } from "../../../services/notifications.service";
import { DetailsPage } from "../details/details.page";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/constants.enum";
import { MessagingService } from "src/app/services/messaging.service";
import { AppService } from "src/app/app.service";
// import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { tap } from "rxjs/operators";
import { MediaService } from "src/app/services/plugins/media.service";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  wait: any = null;
  notification = null;
  lastNotification = null;
  closed = false;
  time: any;
  language: string;
  @ViewChild("div") div: ElementRef;
  @ViewChild("timer") timer: TimerCountComponent;

  onReceiveNotifications = null;
  constructor(
    private notifiService: NotificationsService,
    private mediaService: MediaService,
    private modalCtrl: ModalController,
    private afMessaging: MessagingService,
    private appService: AppService
  ) {}

  async ngOnInit() {
    this.wait = (ms) => new Promise((r) => setTimeout(r, ms)); //for Delay times

    this.appService.user$.subscribe(() => {
      this.onReceiveNotifications?.unsubscribe();
      if (this.appService.isMobileApp) {
        this.onReceiveNotifications = this.notifiService.notifyNew.subscribe((r: any) => {
          this.notification = r;
          this.notificationReceived(r);
        });
      } else  {
        this.onReceiveNotifications = this.afMessaging.getMessages().subscribe((r) => {
          this.notification = r.notification;
          this.notificationReceived(r);
        });
      }
    })
  }

  getLastNotification() {
    let notifications: Array<any> = this.notifiService.getUnreadNotifications();
    let device = this.appService.devices.filter(d => this.notification.title.includes(d.name))
    if (device) {
      let notify = notifications.find(not => not.iddevice == device[0].id);
      if (notify) this.lastNotification = notify;
      else this.lastNotification = notifications[0];
    } else this.lastNotification = notifications[0];
  }

  notificationReceived(notification) {
    if (notification.wasTapped) this.openDetails();
    else this.showNotification(notification);
  }

  async showNotification(notif: any) {
    if (this.timer.getStatus() == statusType.start) {
      this.close();
      await this.wait(500);
    }

    this.play(notif.android_channel_id);
    this.timer.countDown(0, 0, 15);
    this.div.nativeElement.classList.remove("hidde");
    this.div.nativeElement.classList.add("show");

    this.timer.onCountEnd.subscribe((r) => {
      this.close();
    });
    this.timer.start();
  }

  close() {
    this.timer.stop();
    this.div.nativeElement.classList.remove("show");
    this.div.nativeElement.classList.add("hidde");
  }

  async openDetails() {
    await this.notifiService.updateNotifications();
    this.getLastNotification();
    // let not = await this.notifiService.getNotificationbyId(Number.parseInt(this.notification.id));
    this.openDetail(this.lastNotification);
    this.close();
  }

  async play(soundName: string) {
    await this.mediaService.playAudio(soundName);
  }

  async openDetail(not) {
    if (not) {
      const modal = await this.modalCtrl.create({
        component: DetailsPage,
        componentProps: {
          notification: not,
        },
      });

      await modal.present();
    }
  }
}
