import * as moment from "moment";

export const TODAY = moment().clone().startOf('day');
export const YESTERDAY = moment().clone().subtract(1, 'days').startOf('day');
export const A_WEEK = moment().clone().subtract(6, 'days').startOf('day');
export const TWO_WEEKS = moment().clone().subtract(14, 'days').startOf('day');
export const FOUR_WEEKS = moment().clone().subtract(30, 'days').startOf('day');
export enum DATE_RANGE { 'TODAY', 'YESTERDAY', '7DAYS', '15DAYS', '30DAYS', '60DAYS', '100DAYS', '365DAYS' };

export const isToday = (startDate, endDate) => {
  if (startDate.startOf('day').isSame(endDate, 'd')) {
    return startDate.startOf('day').isSame(TODAY, 'd');
  }
  return false;
}

export const isYesterday = (startDate, endDate) => {
  if (startDate.startOf('day').isSame(endDate.startOf('day'), 'd'))
    return startDate.startOf('day').isBefore(TODAY, 'd');
  else
    return false;
}

export const isWeek = (startDate, endDate) => {
  return startDate.startOf('day').add(6, 'days').isSame(endDate.startOf('day'), 'd');
}

export const isTwoWeeks = (startDate, endDate) => {
  return startDate.startOf('day').add(14, 'days').isSame(endDate.startOf('day'), 'd');
}

export const isFourWeeks = (startDate, endDate) => {
  return startDate.startOf('day').add(29, 'days').isSame(endDate.startOf('day'), 'd');

}
export const isEightWeeks = (startDate, endDate) => {
  return startDate.startOf('day').add(59, 'days').isSame(endDate.startOf('day'), 'd');

}


export const diffDates = (startDate, endDate) => {
  const diffDays = Math.abs(startDate.startOf('day').diff(endDate.startOf('day'), 'days'));
  if (isToday(startDate, endDate)) return DATE_RANGE.TODAY;
  if (isYesterday(startDate, endDate)) return DATE_RANGE.YESTERDAY;
  if (diffDays == 6) return DATE_RANGE['7DAYS'];
  if (diffDays == 13) return DATE_RANGE['15DAYS'];
  if (diffDays == 29) return DATE_RANGE['30DAYS'];
  if (diffDays == 59) return DATE_RANGE['60DAYS'];
  if (diffDays == 99) return DATE_RANGE['100DAYS'];
  if (diffDays == 364) return DATE_RANGE['365DAYS'];
}