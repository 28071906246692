import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-cspopover',
  templateUrl: './cspopover.component.html',
  styleUrls: ['./cspopover.component.scss'],
})
export class CsPopoverComponent implements OnInit {

  constructor(private popoverCtrl: PopoverController, private _translate: TranslateService,) { }
  @Input() title = 'Title';
  @Input() subtitle = null;
  @Input() description = null;
  @Input() image = null;
  @Input() template = null;
  @Input() useGif = false;

  ngOnInit() {
  }

  async close() {
    await this.popoverCtrl.dismiss();
  }
}
