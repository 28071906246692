import { Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core";
import {
  AlertController,
  IonRadioGroup, NavParams,
  PopoverController,
} from "@ionic/angular";
import { Constants } from "src/app/constants.enum";
import { MapStylesList } from "src/app/members/map/components/map/class/mapInterfaces";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-mapstyle-popup",
  templateUrl: "./mapstyle-popup.component.html",
  styleUrls: ["./mapstyle-popup.component.scss"],
})
export class MapstylePopupComponent implements OnInit {
  @ViewChild("radioGroup") radioGroup: IonRadioGroup;
  @Input() map: string;
  user = null;

  mapStyles = [];
  constructor(private popoverController: PopoverController, private storage: StorageService) { }

  ngOnInit() {
    this.storage.get(Constants.USER_DATA).then(res => {
      this.user = res;
      // console.log('USER IN MAP STYLES', this.user);
      this.mapStyles = UserMapStyles(this.user);
      setMapStylesPopupHeightProperty(this.mapStyles)
    });
  }

  onCancel() {
    this.popoverController.dismiss(null);
  }
  onSubmit() {
    this.popoverController.dismiss({ map: this.map });
  }
  selectStyle(style) {
    this.map = style.value;
    this.popoverController.dismiss({ map: this.map });
  }
}

export const UserMapStyles = (user) => {
  const customerMapStyleList = Array.isArray(user.map_types) ? user.map_types : [];
  if (customerMapStyleList.length > 0) {
    return Object.keys(MapStylesList)
      .filter(style => customerMapStyleList.some(cusStyle => cusStyle === style))
      .map(k => MapStylesList[k]).filter(style => style.isUserSetting);
  } else {
    return Object.keys(MapStylesList).map(k => MapStylesList[k]).filter(style => style.isUserSetting);
  }
}

export const setMapStylesPopupHeightProperty = (userMapstyleList) => {
  const mapStylesPopupHeight = userMapstyleList.length > 3 ? '150px' : '85px';
  document.documentElement.style.setProperty('--map-styles-popup-height', mapStylesPopupHeight+'');
}
