import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { environment as ENV } from 'src/environments/environment';
import { MediaApiService } from './media-api.service';
import { TranslateService } from '@ngx-translate/core';
import { CutAndFrameComponent } from './crop-and-frame/cut-and-frame.component';

@Component({
  selector: 'app-media-managment',
  templateUrl: './media-managment.component.html',
  styleUrls: ['./media-managment.component.scss'],
})
export class MediaManagmentComponent implements OnInit {
  @Input('showBackButton') showBackButton = false;
  mediaPath = ENV.ROUTE + ENV.API_VERSION + '_media/';

  selectedItems = 0;
  enableSelection = false;
  media = null;
  companyMedia=null;
  loadingMedia = false;
  selectedFiles: FileList;
  progressInfo = [];
  loading = { delete: false, upload: false };
  whitePicture = ('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
  constructor(private modalCtrl: ModalController, private mediaApi: MediaApiService, private _translate: TranslateService,
    private appService: AppService, private cutModal: ModalController) { }

  ngOnInit() {
    this.loadingMedia = true;
    this.mediaApi.get('custom_markers').then( (r: any) => {
      this.media = r.success.filter(m => m.model_id ==
        this.appService.user.id);
        this.loadingMedia = false;
        this.companyMedia = r.success.filter(m => m.model_id !=
          this.appService.user.id);
    })
  }

  close() {
    this.modalCtrl.dismiss();
  }

  selectItem(ev, img) {
    ev.stopPropagation();
    ev.preventDefault();
    if (this.enableSelection) {
      img.isSelected = !img.isSelected;
      this.selectedItems = this.media.filter(image => image.isSelected).length;
    } else {
      this.modalCtrl.dismiss(img);
    }
  }

  setSelection() {
    this.enableSelection = !this.enableSelection
    if (!this.enableSelection) {
      this.media.forEach(img => img.isSelected = false);
      this.selectedItems = 0;
    }
  }

  //_ Upload methods
  uploadFiles(event) {
    //this.progressInfo = [];
    this.selectedFiles = event.target.files;
    this.loading.upload = true;

    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }

  async cutAndFrameStep(event) {
    if (event.target.files.length == 0) return;
    this.selectedFiles = event.target.files;
    this.loading.upload = true;
    const btnText = this.selectedFiles.length == 1 ? this._translate.instant("imageEditTab.upload") : this._translate.instant("imageEditTab.uploadAll");

    const modal = await this.cutModal.create({
      component: CutAndFrameComponent,
      componentProps: {
        files: this.selectedFiles,
        acceptText: btnText
      },
    });

    modal.present();
    const returnData = await modal.onDidDismiss();
    if (returnData.data != undefined)
      this.uploadFiles({ target: { files: returnData.data } });
  }

  async upload(idx, file) {
    this.progressInfo[idx] = { id: idx, value: 0, fileName: file.name, image: this.getImageData(file), error: false, file: file, compressing: false };

    //_ Moved to cut and frame component; to manage small resolution/file size
    // file = await this.mediaApi.compressFile(file, { maxSizeMB: 1, useWebWorker: true, maxWidthOrHeight: 500 }).catch(e => {
    //   this.progressInfo[idx].value = 0;
    //   this.progressInfo[idx].error = true;
    //   this.progressInfo[idx].compressing = false;
    //   return null;
    // });

    this.progressInfo[idx].compressing = false;
    this.mediaApi.upload(file, 'custom_markers').subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfo[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          if (event.status == 200) {
            this.media.push(event.body.success[0]);
            this.media = [...this.media];
            this.progressInfo[idx].value = 100;
            const uploading = this.progressInfo.filter(info => info.value < 100).length;
            if (uploading == 0) {
              this.progressInfo = [];
              this.loading.upload = false;
            }
          }
        }
      },
      error => {
        console.log("ERROR: ", error);
        //this.progressInfo[idx].value = 0;
        this.progressInfo[idx].error = true;
      });
  }

  getImageData(file) {
    const reader = new FileReader();
    return new Observable(observer => {
      reader.onload = () => {
        observer.next(reader.result);
      }
      reader.readAsDataURL(file)
    });
  }

  //_________________________________

  //_ Delete methods
  async deleteImages() {
    //_ Confirm dialog, cancel delete action
    const response = await this.appService.confirmDialog(this._translate.instant('deviceManagement.deviceSettings.deleteImagesText'), this._translate.instant('deviceManagement.deviceSettings.areYousureToDelete') + ` ${this.selectedItems} ` + `${this.selectedItems > 1 ? 'image(s) ' : 'image '}`, '');
    if (response == false) return;

    this.loading.delete = true;
    const ids = this.media.filter(image => image.isSelected).map(img => img.id);
    await this.mediaApi.delete(ids).then((r: any) => {
      if (r.success > 0) {
        ids.forEach(sel => {
          this.media = this.media.filter(img => { return img.id != sel });
        });
      }

      if (this.media.length == 0)
        this.enableSelection = false;

      this.selectedItems = 0;
      this.appService.showToast('', this._translate.instant('deviceManagement.deviceSettings.imagesDeletedSuccessMessage'), 2000, 'success');
    }).catch(e => {
      this.appService.showToast('', this._translate.instant('deviceManagement.deviceSettings.errorTryingToDeleteText'), 3000, 'danger');
    });

    this.loading.delete = false;
  }

  errorImage(img) {
    if(this.appService.userSettings.dark_mode == 1){
      img.src = 'assets/images/broken-picture.svg'
    }else{
      img.src = 'assets/images/broken-picture-dark.svg'
    }
  }

  pressed(){
    this.setSelection();
  }
}
