import { createAnimation } from "@ionic/angular";

export const zoomInAnimation = (baseEl) => {
    const wrapperAnimation = createAnimation()
    .addElement(baseEl.shadowRoot.querySelector('.popover-content'))
    .beforeStyles({ 'opacity': 1 });

    const backdrop = createAnimation()
    .addElement(baseEl.querySelector('ion-backdrop'))
    .duration(150)
    .fromTo('opacity', '0', '0.4');

    const content = createAnimation()
    .addElement(baseEl.shadowRoot.querySelector('.popover-content'))
    .duration(150)
    .beforeStyles( {
        transform: 'translate(-50%, -50%)'
    })
    .fromTo('transform', 'translate(-50%, -50%) scale(0.5)', 'translate(-50%, -50%) scale(1)');

    return createAnimation()
      .addElement(baseEl)
      .duration(150)
      .fromTo('opacity', '0', '1')
      .addAnimation([backdrop, content, wrapperAnimation]);
}

    //   .fromTo('transform', 'scale(0.5)', 'scale(1)')
