import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DeviceModelEvent } from '../pages/home/components/options/options.component';

@Injectable({
  providedIn: 'root',
})
export class TabsService {
  tabsVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _tabsVisible: boolean = false;
  openOptionsSource: EventEmitter<string> = new EventEmitter<string>(null);
  openRouteTracker:  EventEmitter<boolean> = new EventEmitter<boolean>(false);
  openDeviceModel$ = new Subject<DeviceModelEvent>();

  constructor(){
    
  }
  // Method to trigger the event
  openDevice(key: string) {
    this.openDeviceModel$.next({ value: true, key: key });
  }

  get tabsState(): boolean {
    return this._tabsVisible;
  }

  set tabsState(value: boolean) {
    this._tabsVisible = value;
    console.log('tabsState', value);
    this.tabsVisibility.emit(this._tabsVisible);
  }
}
