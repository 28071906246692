import { NgModule } from '@angular/core';import { SizeDetectorComponent } from './size-detector.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
;

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [SizeDetectorComponent],
    exports: [SizeDetectorComponent]
  })


  export class PajSizeDetectorModule {}
