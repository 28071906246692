<ion-header [translucent]="true">
  <ion-toolbar color="transparent">
    <ion-buttons slot="start">
      <ion-back-button
        style="display: block !important"
        back-close
        back-locale
        >
        </ion-back-button>
      </ion-buttons>

      <ion-title>{{ 'devicesSetup.deviceColor.deviceColor' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" clasS="ion-padding no-scrollbars">
  <ion-list class="ion-no-padding">

    <div class="colors-container">
      <ion-item class="color ion-no-padding" *ngFor="let item of colors" [ngStyle]="{ '--background': item.color }" lines="none" button [detail]="false" (click)="selectColor(item)">
        <ion-checkbox [checked]="item.selected" color="light" *ngIf="item.selected" [value]="item.selected" ></ion-checkbox>

        <input
          type="color"
          class="color-box"
          (change)="addColor($event)"
          [style.background]="item.color"
          [value]="item.color"
          *ngIf="item.isCustom"
        >
    <ion-icon *ngIf="item.isCustom && !item.selected" [ngStyle]="{ color: item.iconColor }" name="add-circle-outline"></ion-icon>
    </ion-item>
    </div>
  </ion-list>

  <div class="map-div">
    <ion-icon class="map-img" src="assets/images/devices-setup/route-color.svg" color="primary" [style.fill]="selectedColor?.color"></ion-icon>
  </div>

  <div style="display: flex;justify-content: center;">
    <ion-button mode="md"  color="primary" fill="solid" (click)="nextSetting()" style="margin-top: 24px;">
      <div style="display: inline-block;flex-direction: column;">
      <ion-label>{{ 'devicesSetup.guideSteps.nextButton' | translate }}</ion-label><br />
      <small>{{ 'devicesSetup.deviceImage.deviceImageText' | translate }}</small>
      </div>
    </ion-button>    
  </div>
</ion-content>
