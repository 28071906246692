<div class="list"
     [ngClass]="'items-' + mapStyles.length"
>
  <div class="item"
       (click)="selectStyle(style)" *ngFor="let style of mapStyles"
       [class.active]="style.value === map">
    <img [src]="style.image" />
    <div>{{ style.name | translate }}</div>
  </div>
</div>
