<ion-header>
  <ion-toolbar color="dark">
    <ion-title>{{
      "deviceManagement.deviceImg.deviceImgText" | translate
    }}</ion-title>
    <ion-buttons slot="start">
      <ion-back-button
        (click)="modelCtrl.dismiss()"
        style="display: block !important"
        back-close
        back-locale
        [disableClose]="showBackButton"
      >
      </ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <img
    class="deafultIcon profile"
    [src]="imgUrl"
    #customimage
    *ngIf="iconusecustom == 0"
  />
  <ion-img
    *ngIf="iconusecustom == 1"
    class="deafultIcon profile"
    [src]="customImage | secureMedia | async"
    #customimage
  >
  </ion-img>

  <ion-img
    *ngIf="iconusecustom == 2"
    class="deafultIcon profile"
    [src]="threedObj?.img"
    #customimage
  >
  </ion-img>

  <input
    type="file"
    style="display: none"
    accept="image/*"
    (change)="fileupload($event)"
    #fileinput
  />

  <div class="update-button">
    <ion-button [hidden]="!updateButton" (click)="updateProfilePhoto()" shape="round" >
      OK
    </ion-button>
  </div>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col
        (click)="DeafultImg()"
        class="center-align pointer ion-activatable ripple-parent"
        [class.selected] = "iconusecustom == 0"
      >
          <ion-icon class="icon" src="assets/images/app-settings/default-marker.svg"></ion-icon>
          <ion-label>{{
            "deviceManagement.deviceImg.defaultPicText" | translate }}
          </ion-label>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-col>

      <ion-col
        (click)="threedModel()"
        class="center-align pointer ion-activatable ripple-parent"
        [class.selected] = "iconusecustom == 2"
        *ngIf="use3DMarkers"
      >
          <ion-icon class="icon" name="cube-outline"></ion-icon>
          <ion-label>{{
            "deviceManagement.deviceImg.3dModel" | translate}}
          </ion-label>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-col>

      <ion-col
        (click)="mediaPicker()"
        class="center-align pointer ion-activatable ripple-parent"
        [class.selected] = "iconusecustom == 1"
      >
          <ion-icon class="icon" name="cloud-upload-outline"></ion-icon>
          <ion-label>{{
            "deviceManagement.deviceImg.selectImgText" | translate }}
          </ion-label>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
