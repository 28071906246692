import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeartBeatComponent } from './heart-beat.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [HeartBeatComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  exports: [HeartBeatComponent]
})
export class HeartBeatModule { }
