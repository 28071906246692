import { environment } from "src/environments/environment.prod";
// Get device battery porcentage by model number
export const getBatteryPercentage = (battery, deviceModel) => {
  if (deviceModel.max_battery == 100) {
    return battery === null ? 0 + '%' : battery + '%';
  } else {
    switch (battery) {
      case 6:
        return '100%';
      case 5:
        return '80-100%';
      case 4:
        return '60-80%';
      case 3:
        return '40-60%';
      case 2:
        return '20-40%';
      case 1:
        return '0-20%';
      case 0:
        return '0%';
      default:
        return '0%';
    }
  }
}

// Flag to show or not the battery
export const showBatteryInfo = (deviceModel) => {
  if (deviceModel) {
    if (!deviceModel.standalone_battery)
      return false
    else
      return true;
  }
  return false;
}

export const getBatteryImage = (battery, deviceModel, status = 0) => {
  if (!deviceModel.standalone_battery) {
    if(status){
      if(status == 1) {
        return 'assets/images/map/popup/DeviceConnectedToBat.svg';
      } else {
        return 'assets/images/map/popup/DeviceNotConnectedToBat.svg';
      }
    } else {
      return '';
    }
  }
  else {
    if (deviceModel.max_battery == 100) {
      if (battery <= 100 && battery > 80)
        return 'assets/images/map/popup/Batterie100.svg';
      else if (battery <= 80 && battery > 50)
        return 'assets/images/map/popup/Batterie80.svg';
      else if (battery <= 50 && battery > 40)
        return 'assets/images/map/popup/Batterie40.svg';
      else if (battery <= 40 && battery > 20)
        return 'assets/images/map/popup/Batterie40.svg';
      // if (battery == 2 || battery < 40 && battery > 20)
      //     return 'assets/images/map/popup/Batterie40.svg';
      else if (battery <= 20 && battery > 0)
        return 'assets/images/map/popup/Batterie20.svg';
      else
        return 'assets/images/map/popup/Batterie0.svg';
    } else {
        if (battery == 6)
          return 'assets/images/map/popup/Batterie100.svg';
        else if (battery == 5)
          return 'assets/images/map/popup/Batterie100.svg';
        else if (battery == 4)
          return 'assets/images/map/popup/Batterie80.svg';
        else if (battery == 3)
          return 'assets/images/map/popup/Batterie80.svg';
        else if (battery == 2)
          return 'assets/images/map/popup/Batterie40.svg';
        // if (battery == 2 || battery < 40 && battery > 20)
        //     return 'assets/images/map/popup/Batterie40.svg';
        else if (battery == 1)
          return 'assets/images/map/popup/Batterie20.svg';
        else
          return 'assets/images/map/popup/Batterie0.svg';
      }
  }

}