import { NgModule } from "@angular/core";
import { VibrateOnToggleDirective } from "./vibration-for-toggle-buttons.directive";

@NgModule({
  declarations: [
    VibrateOnToggleDirective
  ],
  imports: [],
  exports: [
    VibrateOnToggleDirective
  ],
})
export class VibrateOnToggleDirectiveModule {}