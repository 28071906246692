import { notificationsMarkers } from "src/app/members/notifications/notificationsUtil";
import { MapComponent } from "../../map.component";
import { getSource } from "../castMaplibreData";
import { AvailableHooks } from "../componentHooks";
import { Data, dataSourceType, dataTypes } from "../data";
import { DataSource } from "../dataSource";
import { Geometry, GeometryTypes } from "../geometry";
import { IProperties } from "./../Iproperties";
import { Layer, layerType } from "../layer";
import { addMapImage, getCssStyleVariable, svgToData } from "../mapUtil";
import { FeatureInterface } from "./feature-interface";
import { MainMapInterface } from "../../main-map.interface";
import { MapService } from "../../map.service";
import { inject } from "@angular/core";

export class PauseMarkers implements FeatureInterface {
    SOURCE_NAME: string = 'pausesSource';
    LAYER_NAME: string = 'pauses'
    source: DataSource = null;
    layer = null;

    clusterMarkerBackground = null;
    clusterMarkerCount = null;
    clusterMarkerImage = null;
    imagePath = 'assets/images/map/markers/stop-marker.svg';
    defaultClusterMarkerImagePath = 'assets/images/map/markers/stop-marker.svg';
    defaultClusterMarkerImageName = 'default-cluster-pause-marker';
    imageName = 'stop-marker';

    primaryColor = '#FF4E00';
    public show = true;
    counterOffsetText = [-15, -25];
    mapService = inject(MapService);

    obs: any = {};
    constructor(public main: MainMapInterface){
        this.init();
    }

    init(){
        this.primaryColor = getCssStyleVariable('--ion-color-primary');

        this.source = this.createDataSource();
        this.layer = this.createLayer(this.LAYER_NAME);
        this.clusterMarkerBackground = this.createLayer('clusterMarkerBackground');
        this.clusterMarkerCount = this.createLayer('clusterMarkerCount');
        this.clusterMarkerImage = this.createLayer('clusterMarkerImage');

        this.load();
    }

    createDataSource() {
        let source = <DataSource>{};
        source.data = <Data>{};
        source.type = dataSourceType.geojson;
        source.data.features = [];
        source.data.type = dataTypes.FeatureCollection;
        source["generateId"] = true;

        source['cluster'] = true;
        source['clusterMaxZoom'] = 16;
        source['clusterRadius'] = 50;
        source['filter'] = [
            "all",
            ["==", ["get", "layerType"], "pause"], // Select only markers features from the dataSource
            ["==", ["get", "deviceShow"], 1], // Show only if deviceShow == 1
            ["==", ["get", "showPauses"], 1], //_ Flag from user settings

            [
              'any',
              ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
              ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
            ],
            [
              'any',
              ['==', ['get', 'isMonitorModeEnable'], false], // OR condition: isSliderViewOpen == false
              ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
            ]
        ];

        return source;
    }

    createLayer(name){
        let layer = new Layer(name);
        layer.source = this.SOURCE_NAME;
        layer.type = layerType.symbol;

        if (name == this.LAYER_NAME){
            layer.type = layerType.symbol;
            layer.layout["icon-image"] = ["get", "icon"];
            layer.layout["icon-size"] = ["get", "size"];
            layer.layout["icon-allow-overlap"] = true;
            layer.layout["icon-rotation-alignment"] = "viewport"; //could be 'viewport' ; used to rotate the icon with the viewport of the map ...
            layer.paint['icon-translate-anchor'] = 'map';
            layer.layout['icon-pitch-alignment'] = 'map';
            //layer['slot'] = 'top';
            layer.maxzoom = 20;
            layer.minzoom = 6;

            layer['filter'] = ['!', ['has', 'point_count']];
        }

        if (name == 'clusterMarkerImage') {
            layer.layout["text-anchor"] = "top";
            layer.layout["icon-anchor"] = "center";
            layer.layout["icon-allow-overlap"] = true;
            layer.layout["icon-rotation-alignment"] = "viewport";
            layer.paint['icon-translate-anchor'] = 'map';
            layer.layout['icon-pitch-alignment'] = 'map';
            //layer['slot'] = 'top';

            layer.layout["icon-size"] = 0.6;
            layer.layout["icon-image"] = this.defaultClusterMarkerImageName;

            layer['filter'] = ['has', 'point_count'];
          }

        if (name == 'clusterMarkerBackground') {
            layer.paint['circle-translate'] = this.counterOffsetText;
            layer.paint['circle-translate-anchor'] = 'map';
            layer.paint['circle-pitch-alignment'] = 'map';

            layer.type = layerType.circle;
            layer.paint["circle-color"] = this.primaryColor;
            layer.paint["circle-radius"] = 16;
            layer.paint['circle-stroke-color'] = '#fff';
            layer.paint['circle-stroke-width'] = 1;
            //layer['slot'] = 'top';
            layer.maxzoom = 20;
            layer.minzoom = 6;

            layer['filter'] = ['has', 'point_count'];
          }

          if (name == 'clusterMarkerCount') {
            layer.paint['text-translate'] = this.counterOffsetText;
            layer.paint['text-color'] = '#ffffff';
            layer.layout["text-field"] = '{point_count_abbreviated}';
            layer.layout["text-size"] = 13;
            layer.layout["text-allow-overlap"] = true;
            layer.paint['text-translate-anchor'] = 'map';
            layer.layout['text-rotation-alignment'] = 'viewport';
            layer.layout['text-pitch-alignment'] = 'map'
            //layer['slot'] = 'top';
            layer.maxzoom = 20;
            layer.minzoom = 6;

            layer['filter'] = ['has', 'point_count'];
          }

        return layer;
    }

    mouseEvents(){
        const tc = this.main;
        (this.main.map as any).on("mouseenter", this.LAYER_NAME, () => {
            tc.map.getCanvas().style.cursor = "pointer";
        });
        (this.main.map as any).on("mouseleave", this.LAYER_NAME, () => {
        tc.map.getCanvas().style.cursor = "default";
        });
    }

    load(){
        // LOAD IMAGES FOR PAUSE LAYERS
        this.main.on(AvailableHooks.onLoadImages, (e) => {
            addMapImage(this.imagePath, this.main.map, this.imageName, 50, 50);
            addMapImage(this.defaultClusterMarkerImagePath, this.main.map, this.defaultClusterMarkerImageName, 75, 75);
        });

        this.main.on(AvailableHooks.onLoadLayer, (e) => {
            if (!this.main.map.getLayer('clusterMarkerCount'))
                this.main.map.addLayer(this.clusterMarkerCount, 'markers');
            if (!this.main.map.getLayer('clusterMarkerBackground'))
                this.main.map.addLayer(this.clusterMarkerBackground, 'clusterMarkerCount');
            if (!this.main.map.getLayer('clusterMarkerImage'))
                this.main.map.addLayer(this.clusterMarkerImage, 'clusterMarkerBackground');
            if (!this.main.map.getLayer(this.LAYER_NAME))
                this.main.map.addLayer(this.layer, 'clusterMarkerBackground'); //_ Show pauses behind the alarm cluster image
        });

        this.main.on(AvailableHooks.onLoadSource, (e) => {
            if (!this.main.map.getSource(this.SOURCE_NAME))
                this.main.map.addSource(this.SOURCE_NAME, this.source);
        });

        this.main.on(AvailableHooks.onSetSliderView, (e) => {
          this.setSliderView(e);
        });

        this.obs['monitorMode'] = this.main.mapReady.subscribe( r => {
            if (r){
                this.mouseEvents();
            }
        })

        this.obs['monitorMode'] = this.mapService.mapMonitorMode.subscribe(res => {
          this.updateMonitorMode(res);
        })

        this.main.on(AvailableHooks.onDestroy, (e) => {
          this.destroy();
        });
    }

  // The response from api for pauseMarkers are
  // data = End position
  // pause_start = startPosition
  // pause_display = marker position
  // device
  public async addPauseMarkers(
    device,
    deleteDevicePauses = false,
    realtime = false,
    layerOptions = {}
  ) {

    let fname = "pause-" + (realtime ? "realtime-" : "") + device.id + "-";
    if (deleteDevicePauses) {
      this.source.data.features = this.source.data.features.filter(
        function (f) {
          return !(
            f.properties["layerType"] == "pause" &&
            f.properties["deviceId"] == device.id
          );
        }
      );
    }

    device.newPauseMarkers.forEach(async (p) => {
      let name = fname + p.pause_display.id;
      let geometry = new Geometry();
      let properties = {} as IProperties;
      geometry.type = GeometryTypes.Point;
      geometry.coordinates = [p.pause_display.lng, p.pause_display.lat];
      properties.icon = this.imageName; // name of the image loaded in load map when started the component.
      properties.size = 0.6;
      properties["deviceShow"] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the pause marker
      properties["layerType"] = "pause"; //Type of feature
      properties["pauseId"] = p.pause_display.id;
      properties["deviceId"] = device.id;
      properties["id"] = fname + properties["pauseId"];
      properties["showPauses"] = this.show ? 1 : 0;
      properties["isSliderViewOpen"] = this.main.isSliderViewEnable;
      properties['isMonitorModeEnable'] = this.mapService.mapMonitorMode.value;

      Object.keys(layerOptions).forEach(key => {
        properties[key] = layerOptions[key];
      });

      let pmarker = this.main._createFeature(geometry, properties, name);
      // Adding the feature to the data Source ...
      this.source.data.features.push(pmarker);
    });

    this.updateDataSource();
  }


    showHidePauseMarker(show) {
        this.show = show;
        this.source.data.features.forEach((f) => {
          f.properties["showPauses"] = show ? 1 : 0;
        });

        // And then update the source in the map :)
        this.updateDataSource();
      }

    async hidePauses(){
        this.show = false;
        this.mapService.showPauses.next(this.show);
    }

    async showPausesIfEnabled(){
        let value = await this.main.storage.get('showPauses');
        this.show = value ? value : false;
        this.mapService.showPauses.next(this.show);
        this.main.updateSettings({ show_pauses: this.show });
    }

    togglePauses(ev, showToast = true) {
      if (ev) ev.stopPropagation();

      this.show = !this.show;
      this.mapService.showPauses.next(this.show);
      if(!this.main.heatMapClass?.isEnabled)
      {
        this.main.updateSettings({ show_pauses: this.show });
      }
      if (this.show == true && showToast) {
        this.main.appService.showToast('', this.main._translate.instant('fabbuttonToast.showPausesOn'), 2000, 'success');
      }
      else if (showToast) {
        this.main.appService.showToast('', this.main._translate.instant('fabbuttonToast.showPausesOff'), 2000, 'success');
      }
    }

    updateDataSource() {
      if (this.main.map) {
        if (this.main.map.getSource(this.SOURCE_NAME))
          getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
      }
    }

    updateDeviceData(device, show){
      this.source.data.features.forEach((f) => {
        if (f.properties["deviceId"] == device.id)
            f.properties["deviceShow"] = show ? 1 : 0;
      });

      this.updateDataSource();
    }

    setSliderView (ev) {
      //_ Filter source
      this.source.data.features.forEach((f) => {
        f.properties["isSliderViewOpen"] = ev.isEnable;
      });

      //_ If slider is close then needs to filter the sliderData features
      if (!ev.isEnable) {
        this.source.data.features = this.main.filterFeaturesWithoutSliderProps(this.source.data.features);
        this.source.data.features = this.main.cleanSliderFeatureProps(this.source.data.features);
        this.source.data.features = this.source.data.features.filter(f => !this.mapService.mapMonitorMode.value);
      }

      //_ Update source
      if (this.main.map.getSource(this.SOURCE_NAME))
        getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }

    updateMonitorMode (isEnable) {
      this.source.data.features = this.source.data.features.filter(f => !this.mapService?.mapMonitorMode.value);
      //_ Update source
      if (this.main.map?.getSource(this.SOURCE_NAME))
        getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }

    clearData() {
      this.source = this.createDataSource();
      try {
        getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
      } catch { }
    }

    destroy () {
      Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
    }
}
