import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  timesValues = [];
  timesFiltered = [...this.timesValues];
  selectedTime : string;
  @Input() singleSelection = false;
  @Input() showAllSeconds = false;
  timeSearch: FormGroup;
  constructor(private popoverController: PopoverController, public modalController: ModalController, public _translate: TranslateService, private formBuilder: FormBuilder) {
    this.timeSearch = this.formBuilder.group({
      searchValue: ['', Validators.compose([
      Validators.pattern(/[0-9:]/)])],
      });
   }

  ngOnInit() {
    this.createTimeArrays();
    this.search({ detail: { value: '' } }); // call search function with empty query to populate timesFiltered array initially
    // this.timeSearch.valueChanges.subscribe((value) => {
    //   console.log('value', value);
    //   });
  }

  get searchError() {
   //console.log(this.timeSearch.get('searchValue')?.errors)
    return this.timeSearch.get('searchValue');
    }

  okButton() {
    this.popoverController.dismiss(this.selectedTime);
  }
  cancelButton(){
    this.popoverController.dismiss();
  }
  createTimeArrays() {

    // let interval;
    // if (this.showAllSeconds) {
    //   interval = 1;
    // } else {
    //   interval = this.singleSelection ? 10 : 15;
    // }

    const interval = 1;
    for (let h = 0; h <= 23; h++)
      for (let m = 0; m <= 59; m += interval) {
        const hour = h < 10 ? '0' + h : h;
        const min = m < 10 ? '0' + m : m;
        this.timesValues.push(hour + ':' + min);
      }
    //this.timesValues.push('23:59');
  }

  search(event) {
    if (event.detail.value) {
      this.timesFiltered = this.timesValues.filter(time => {
        return time.toLowerCase().includes(event.detail.value.toLowerCase());
      });
    } else {
      this.timesFiltered = [...this.timesValues];
    }
  }

  selectTime(time) {
    this.selectedTime = time;
  }
}
