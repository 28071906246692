import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CompanyAdminGuard } from './guards/company_admin.guard';

const routes: Routes = [
  { path: '', redirectTo: '/map', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'map',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  // { path: '', redirectTo: 'tabs', pathMatch: 'full' },
  {
    path: 'share/:salt',
    loadChildren: () =>
      import('./public/shared-link-map/shared-link-map.module').then(
        (m) => m.SharedLinkMapPageModule,
      ),
  },
  {
    path: 'stand-alone-page/:id',
    loadChildren: () =>
      import('./public/stand-alone-page/stand-alone-page.module').then(
        (m) => m.StandAlonePagePageModule,
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./public/create-password/create-password.module').then(
        (m) => m.CreatePasswordPageModule,
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./public/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./public/register/register.module').then(
        (m) => m.RegisterPageModule,
      ),
  },
  //_ Used to start the selected tour when map is loaded.
  {
    path: 'map/:startTour',
    loadChildren: () =>
      import('./members/map/map.module').then((m) => m.MapPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'map', loadChildren: () => import('./members/map/map.module').then(m => m.MapPageModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'map/:component/:deviceId',
    loadChildren: () =>
      import('./members/map/map.module').then((m) => m.MapPageModule),
    canActivate: [AuthGuard],
  },
  //_ Used account from external link
  /*{
    path: 'account', loadChildren: () => import('./members/account/account.module').then(m => m.AccountPageModule)
  },*/
  {
    path: 'device-management',
    loadChildren: () =>
      import('./members/device-management/device-management.module').then(
        (m) => m.DeviceManagementPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'device-management/:imei',
    loadChildren: () =>
      import('./members/device-management/device-management.module').then(
        (m) => m.DeviceManagementPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./members/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'logbook',
    loadChildren: () =>
      import('./members/logbook/logbook.module').then(
        (m) => m.LogbookPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'members',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./members/member-routing.module').then(
        (m) => m.MemberRoutingModule,
      ),
  },
  {
    path: 'forget-password',
    loadChildren: () =>
      import('./public/forget-password/forget-password.module').then(
        (m) => m.ForgetPasswordPageModule,
      ),
  },
  /*{
    path: 'about-us', loadChildren: () => import('./members/about-us/about-us.module').then(m => m.AboutUsModule)
  },*/
  {
    path: 'admin-device-management/:id',
    loadChildren: () =>
      import(
        './members/admin-main/admin-device-management/admin-device-management.module'
      ).then((m) => m.AdminDeviceManagementPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-user-management',
    loadChildren: () =>
      import(
        './members/admin-main/admin-user-management/admin-user-management.module'
      ).then((m) => m.AdminUserManagementPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-server-alert',
    loadChildren: () =>
      import(
        './members/admin-main/admin-server-alert/admin-server-alert.module'
      ).then((m) => m.AdminServerAlertPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-add-alert',
    loadChildren: () =>
      import(
        './members/admin-main/admin-server-alert/add-alert/add-alert.module'
      ).then((m) => m.AddAlertPageModule),
    canActivate: [AuthGuard],
  },
  /*
  {
    path: 'generate-video',
    loadChildren: () => import('./members/generate-video/generate-video.module').then(m => m.GenerateVideoPageModule),
    canActivate: [AuthGuard]
  },
  */
  {
    path: 'change-password',
    loadChildren: () =>
      import('./members/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'password-reset-advice',
    loadChildren: () =>
      import(
        './members/password-reset-advice/password-reset-advice.module'
      ).then((m) => m.PasswordResetAdviceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/:id',
    loadChildren: () =>
      import('./public/reports/reports.module').then(
        (m) => m.ReportsPageModule,
      ),
  },
  {
    path: 'route-plan',
    loadChildren: () =>
      import('./members/route-plan/route-plan.module').then(
        (m) => m.RoutePlanPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'axa/auth',
    loadChildren: () =>
      import('./public/alexa/login/login.module').then(
        (m) => m.LoginPageModule,
      ),
  },
  {
    path: 'customer-feedback',
    loadChildren: () =>
      import('./public/customer-feedback/customer-feedback.module').then(
        (m) => m.CustomerFeedbackPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-customer-feedback',
    loadChildren: () =>
      import(
        './members/admin-main/admin-customer-feedback/customer-feedback.module'
      ).then((m) => m.CustomerFeedbackPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'whatsnew',
    loadChildren: () =>
      import('./public/whatsnew/whatsnew.module').then(
        (m) => m.WhatsnewPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'change-log',
    loadChildren: () =>
      import('./public/change-log/change-log.module').then(
        (m) => m.ChangeLogPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./public/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordPageModule,
      ),
  },
  {
    path: 'reset-password/:token',
    loadChildren: () =>
      import('./public/create-password/create-password.module').then(
        (m) => m.CreatePasswordPageModule,
      ),
  },
  {
    path: 'companies/map',
    loadChildren: () =>
      import('./members/companies-map-page/companies-map-page.module').then(
        (m) => m.CompaniesMapPagePageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'show-logbook-routes',
    loadChildren: () =>
      import('./public/show-logbook-routes/show-logbook-routes.module').then(
        (m) => m.ShowLogbookRoutesPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'logbook/devices/:id/logs/:token',
    loadChildren: () =>
      import('./members/logbook/device-log/logs/logs.module').then(
        (m) => m.LogsPageModule,
      ),
  },
  {
    path: 'alerts',
    loadChildren: () =>
      import('./public/alerts/alerts.module').then((m) => m.AlertsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pets',
    loadChildren: () =>
      import('./petmode/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'pets/statistics',
  //   loadChildren: () =>
  //     import('./members/dashboard/pet-stats/pet-stats.module').then(
  //       (m) => m.PetStatsModule,
  //     ),
  // },

  {
    path: 'pets/statistics',
    loadChildren: () =>
      import('./petmode/pages/activities/activities.module').then(
        (m) => m.ActivitiesPageModule,
      ),
  },
  {
    path: 'pets/device/:id',
    loadChildren: () =>
      import('./petmode/pages/device/device.module').then(
        (m) => m.DevicePageModule,
      ),
  },
  {
    path: 'pets/geofence/:geoId',
    loadChildren: () =>
      import('./petmode/pages/device/pages/geofence/geofence.module').then(
        (m) => m.GeofencePageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pets/add-geofence/:deviceId',
    loadChildren: () =>
      import(
        './petmode/pages/device/pages/add-geofence/add-geofence.module'
      ).then((m) => m.AddGeofencePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pets/device-management',
    loadChildren: () =>
      import('./members/device-management/device-management.module').then(
        (m) => m.DeviceManagementPageModule,
      ),
  },
  {
    path: 'pets/notifications',
    loadChildren: () =>
      import('./members/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule,
      ),
  },
  {
    path: 'pets/settings',
    loadChildren: () =>
      import('./petmode/pages/settings/settings.module').then(
        (m) => m.SettingsPageModule,
      ),
  },

  //_ REDIRECT TO LOGIN IN CASE ACCESS TO AN UNKNOWN ROUTE -> can also redirect to a "PageNotFoundComponent"
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
