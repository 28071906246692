<ion-content>
  <!-- <div class="paj-logo safe-area-top" style="padding-top:8px">
    <img src="assets/default_brand/img/logo_main_square.png" alt="" />
  </div> -->
  <ion-card class="ion-no-margin no-border background">
    <ion-card-header>
      <ion-card-title class="ion-text-center"
        >{{'whatsnew.newMomentText' | translate}}</ion-card-title
      >
    </ion-card-header>
    <ion-list class="background">
      <ion-item
        class="background"
        lines="none"
        *ngFor="let item of alertArray; let i=index"
      >
        <ion-icon color="primary" name="timer-outline" slot="start"></ion-icon>
        <ion-label style="white-space: inherit"> {{item}} </ion-label>
      </ion-item>
    </ion-list>
  </ion-card>
</ion-content>
<!-- <ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-button
      class="ion-margin-horizontal"
      color="primary"
      mode="ios"
      expand="block"
      (click)="dismiss()"
    >
      {{'whatsnew.continueText' | translate}}
    </ion-button>
  </ion-toolbar>
</ion-footer> -->
