import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../app.service';
import { DecimalPipe } from '@angular/common';


@Pipe({
  name: 'KmtoMiles'
})
//_ Pipe to convert kilometers to miles adding the unit label at end and also formating using Angular Decimal Pipe
export class KmtoMilesPipe implements PipeTransform {
  formatString = '1.0';
  distance_unit = 'km';
  pipeLanguage = null;
  constructor(private appService: AppService, private decimalPipe: DecimalPipe) { }

  transform(value, format = '1.0', onlyNumbers=false, language = null, inMeters = false): any {
    this.pipeLanguage = language;
    this.formatString = format;
    //_ If is undefined or null then change it to 0 to not return null °|
    if (!value)
      value = 0;
    if (inMeters && value > 0) {
      value = value/1000;
    }

    return this.convert(value, onlyNumbers);
  }

  convert(km, onlyNumbers = false){
    if (this.appService.user){
      this.distance_unit = this.appService.user.distance_unit;
    }

    var unit = this.distance_unit;
    switch (unit){
      case 'km':
        km = km;
      break;
      case 'mi':
        km = (km / 1.609);
      break;
    }

    if (onlyNumbers)
      return this.format(km);
    else
      return this.format(km) + ' ' + unit;
  }

  //_ Angular default number pipe to format the number
  format(number){
    return this.decimalPipe.transform(number, this.formatString, this.pipeLanguage ?? this.appService.language);
  }
}
