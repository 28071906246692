import { NgModule } from '@angular/core';
import { MapMinComponent } from './map-min.component';


@NgModule({
    imports: [
      
    ],

    declarations: [MapMinComponent],
    exports: [MapMinComponent],
    providers: []
  })

  export class PajMapMinModule {}