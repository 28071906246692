<!-- POPUP HEADER -->
<ion-header style="line-height: 1 !important">
  <ion-item color="dark" style="--padding-top: 3px" lines="none">
    <ion-icon src="assets/images/pin-circle.svg" color="primary" slot="start" style="margin: 0"></ion-icon>
    <ion-label class="ion-text-center" style="margin: 3px; max-width: calc(100% - 25px)">
      <h6 class="ion-text-wrap">{{ "map.popup.markersPopup.title" | translate }}</h6>
    </ion-label>
  </ion-item>
</ion-header>

<!-- FEATURES LIST -->
<ion-list lines="none" class="ion-no-padding" mode="md">
  <ion-item lines="full" *ngFor="let feature of features" button (click)="selectFeature(feature)">
    <ng-container *ngIf="feature.layer.id === 'markers'"
      [ngTemplateOutlet]="DEVICE_ITEM" [ngTemplateOutletContext]="{ feature: feature }"></ng-container>
    <ng-container *ngIf="feature.layer.id === 'alarms'" [ngTemplateOutlet]="ALARM_ITEM"
      [ngTemplateOutletContext]="{ feature: feature }"></ng-container>
    <ng-container *ngIf="feature.layer.id === 'pauses'" [ngTemplateOutlet]="PAUSE_ITEM"
      [ngTemplateOutletContext]="{ feature: feature }"></ng-container>
    <ng-container *ngIf="feature.layer.id === 'alarms_circle'" [ngTemplateOutlet]="ALARM_CIRCLE_ITEM"
      [ngTemplateOutletContext]="{ feature: feature }"></ng-container>
    <ng-container *ngIf="feature.layer.id === 'symbolsLayer'" [ngTemplateOutlet]="SYMBOL_LAYER_ITEM"
      [ngTemplateOutletContext]="{ feature: feature }"></ng-container>
      <ng-container *ngIf="feature.layer.id === 'wifiHomeLayer'" [ngTemplateOutlet]="WIFI_HOME_LAYER"
      [ngTemplateOutletContext]="{ feature: feature }"></ng-container>
  </ion-item>
</ion-list>

<!-- ITEMS TEMPLATES -->
<!-- DEVICE TEMPLATE -->
<ng-template #DEVICE_ITEM let-feature="feature">
  <ion-img class="item-icon" slot="start" src="{{ feature?.device | DeviceImage : -1 | async }}"
    style="object-fit: contain;"></ion-img>
  <ion-label>{{ feature.device?.name }}</ion-label>
</ng-template>

<!-- ALARM TEMPLATE -->
<ng-template #ALARM_ITEM let-feature="feature">
  <ion-icon class="item-icon" slot="start" [src]="'assets/images/map/markers/' + feature.properties.icon">
  </ion-icon>
  <ion-label>
    <h6>{{ feature.device?.name }}</h6>
    <!--<p>{{ AlarmTranslationsKeys[feature.alarm.meldungtyp]?.title | translate}}</p>-->
    <p>{{ getAlertTitle(feature.alarm.meldungtyp) | translate}}</p>
    <p>{{ feature.alarm?.dateunix | humanizeTime:'date':'D.MM.YYYY HH:mm' }}</p>
  </ion-label>
</ng-template>

<!-- PAUSE TEMPLATE -->
<ng-template #PAUSE_ITEM let-feature="feature">
  <ion-icon class="item-icon" slot="start" src="assets/images/map/markers/stop-marker.svg"></ion-icon>
  <ion-label>
    <h6>{{ feature.device?.name }}</h6>
    <p
      title="{{ feature.pause?.pause_start?.dateunix - feature.pause?.data?.dateunix | humanizeTime : 'duration' : '' }}">
      {{
      feature.pause?.pause_start?.dateunix - feature.pause?.data?.dateunix | humanizeTime : "duration" : ""
      }}
    </p>
  </ion-label>
</ng-template>

<!-- ALARM_CIRCLE_ITEM TEMPLATE -->
<ng-template #ALARM_CIRCLE_ITEM let-feature="feature">
  <ion-icon class="item-icon" slot="start" src="assets/icons/dataPoint.svg"></ion-icon>
  <ion-label>
    <h6>{{ feature.device?.name }} - Direction marker</h6>
    <!--<p
      title="{{ feature.pause?.pause_start?.dateunix - feature.pause?.data?.dateunix | humanizeTime : 'duration' : '' }}">
      {{
      feature.pause?.pause_start?.dateunix - feature.pause?.data?.dateunix | humanizeTime : "duration" : ""
      }}
    </p>!-->
  </ion-label>
</ng-template>

<!-- SYMBOLS_LAYER TEMPLATE -->
<ng-template #SYMBOL_LAYER_ITEM let-feature="feature">
  <ion-icon class="item-icon" slot="start" src="assets/images/render-video/end-marker.svg"></ion-icon>
  <ion-label>
    <h6 *ngIf="feature.properties?.image == 'start-marker'">{{ feature.device?.name }} - {{ "logBook.report.start" | translate }}</h6>
    <h6 *ngIf="feature.properties?.image == 'end-marker'">{{ feature.device?.name }} - {{ "logBook.report.end" | translate }}</h6>

    <p *ngIf="feature.direction">
      {{ feature.direction.dateunix | humanizeTime:'date':'D.MM.YYYY HH:mm' }}
    </p>
  </ion-label>
</ng-template>

<!-- SYMBOLS_LAYER TEMPLATE -->
<ng-template #WIFI_HOME_LAYER let-feature="feature">
  <ion-icon class="item-icon" slot="start" src="assets/images/setup-wifi/wifi-home-pin.svg"></ion-icon>
  <ion-label>
    <h6 class="ion-text-wrap">{{ feature.device?.name }} - {{ "setupWifi.wifiHomeText" | translate }} {{ feature.wifiHome.wifiIndex }}</h6>
  </ion-label>
</ng-template>

<!----------------------------------->
