import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, HostListener, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { TranslateService } from "@ngx-translate/core";
import { IonModal } from "@ionic/angular";
import { Commons } from "../../../members/app-settings/commons";
import { Constants } from "../../../constants.enum";

@Component({
  selector: 'custom-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})

export class CustomTimePicker implements OnInit, OnChanges, AfterViewInit {
  @Input() minuteDigits: boolean = false;
  @Input() labelTitle: string = "";
  @Input() uniqueId: string="modal";
  @Input() style: 'ios' | 'android' = 'ios';
  @Input() initialHour: string | null = null;
  @Output() timeChanged = new EventEmitter<{}>();

  @ViewChild('minutes') minutesEl: ElementRef
  private applyFocus = false;

  @ViewChild('timeContainer', { static: false }) containerEl: ElementRef
  @ViewChild('modal', { static: false }) modal: IonModal
  @ViewChild('modalSetTime', { static: false }) modalSetTime: IonModal
  //@ViewChild('line1') line1El: ElementRef
  private container;
  //private line1;
  public view: 'hour' | 'minute' = 'hour';
  public hour:string = '00';
  public minute: string = '00'
  public minuteFirstDigit: string = '0'
  public minuteSecondDigit: string = '0'
  isSmallScreen = false;
  time; // ios Style time
  locale;
  obs: any = {};
  isModalOpen: boolean = false;

  constructor(private renderer: Renderer2, private appService: AppService,
              private  translate: TranslateService, private sc: Commons){
    let language = this.translate.currentLang;
    this.locale = language.includes('_') || language.includes('-') ?
      language.replace('_', '-') :
      language + "-" + language.toUpperCase();
  }

  private items: Array<{value: string, label: string}> = [
    {value: '16', label: '16'},
    {value: '17', label: '17'},
    {value: '18', label: '18'},
    {value: '19', label: '19'},
    {value: '20', label: '20'},
    {value: '21', label: '21'},
    {value: '22', label: '22'},
    {value: '23', label: '23'},
    {value: '00', label: '00'},
    {value: '13', label: '13'},
    {value: '14', label: '14'},
    {value: '15', label: '15'}
  ]

  private subItems: Array<{value: string, label: string}> = [
    {value: '04', label: '04'},
    {value: '05', label: '05'},
    {value: '06', label: '06'},
    {value: '07', label: '07'},
    {value: '08', label: '08'},
    {value: '09', label: '09'},
    {value: '10', label: '10'},
    {value: '11', label: '11'},
    {value: '12', label: '12'},
    {value: '01', label: '01'},
    {value: '02', label: '02'},
    {value: '03', label: '03'}
  ]

  private minutes  = [
    {value: '15', label: '15'},
    {value: '16', label: '16', visibility: 'hidden'},
    {value: '17', label: '17', visibility: 'hidden'},
    {value: '18', label: '18', visibility: 'hidden'},
    {value: '19', label: '19', visibility: 'hidden'},
    {value: '20', label: '20'},
    {value: '21', label: '21', visibility: 'hidden'},
    {value: '22', label: '22', visibility: 'hidden'},
    {value: '23', label: '23', visibility: 'hidden'},
    {value: '24', label: '24', visibility: 'hidden'},
    {value: '25', label: '25'},
    {value: '26', label: '26', visibility: 'hidden'},
    {value: '27', label: '27', visibility: 'hidden'},
    {value: '28', label: '28', visibility: 'hidden'},
    {value: '29', label: '29', visibility: 'hidden'},
    {value: '30', label: '30'},
    {value: '31', label: '31', visibility: 'hidden'},
    {value: '32', label: '32', visibility: 'hidden'},
    {value: '33', label: '33', visibility: 'hidden'},
    {value: '34', label: '34', visibility: 'hidden'},
    {value: '35', label: '35'},
    {value: '36', label: '36', visibility: 'hidden'},
    {value: '37', label: '37', visibility: 'hidden'},
    {value: '38', label: '38', visibility: 'hidden'},
    {value: '39', label: '39', visibility: 'hidden'},
    {value: '40', label: '40'},
    {value: '41', label: '41', visibility: 'hidden'},
    {value: '42', label: '42', visibility: 'hidden'},
    {value: '43', label: '43', visibility: 'hidden'},
    {value: '44', label: '44', visibility: 'hidden'},
    {value: '45', label: '45'},
    {value: '46', label: '46', visibility: 'hidden'},
    {value: '47', label: '47', visibility: 'hidden'},
    {value: '48', label: '48', visibility: 'hidden'},
    {value: '49', label: '49', visibility: 'hidden'},
    {value: '50', label: '50'},
    {value: '51', label: '51', visibility: 'hidden'},
    {value: '52', label: '52', visibility: 'hidden'},
    {value: '53', label: '53', visibility: 'hidden'},
    {value: '54', label: '54', visibility: 'hidden'},
    {value: '55', label: '55'},
    {value: '56', label: '56', visibility: 'hidden'},
    {value: '57', label: '57', visibility: 'hidden'},
    {value: '58', label: '58', visibility: 'hidden'},
    {value: '59', label: '59', visibility: 'hidden'},
    {value: '00', label: '00'},
    {value: '01', label: '01', visibility: 'hidden'},
    {value: '02', label: '02', visibility: 'hidden'},
    {value: '03', label: '03', visibility: 'hidden'},
    {value: '04', label: '04', visibility: 'hidden'},
    {value: '05', label: '05'},
    {value: '06', label: '06', visibility: 'hidden'},
    {value: '07', label: '07', visibility: 'hidden'},
    {value: '08', label: '08', visibility: 'hidden'},
    {value: '09', label: '09', visibility: 'hidden'},
    {value: '10', label: '10'},
    {value: '11', label: '11', visibility: 'hidden'},
    {value: '12', label: '12', visibility: 'hidden'},
    {value: '13', label: '13', visibility: 'hidden'},
    {value: '14', label: '14', visibility: 'hidden'},
  ]

  private minuteItems: Array<{value: string, label: string}> = [
    {value: '0', label: '0'},
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'},
    {value: '6', label: '6'},
    {value: '7', label: '7'},
    {value: '8', label: '8'},
    {value: '9', label: '9'},
  ]

  private subMinuteItems: Array<{value: string, label: string}> = [
    {value: '0', label: '0'},
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'}
  ]

  private primaryItems = this.items;
  private secondaryItems = this.subItems;

  async ngOnInit() {
    console.log("this.initialHour ::: ", this.initialHour);
    if(this.initialHour){
      const h = this.initialHour.split(":")
      this.hour = h[0]

      if(this.minuteDigits){
        this.minuteFirstDigit = h[1][0]
        this.minuteSecondDigit = h[1][1]
      }else
        this.minute = h[1]
    }
    this.time = this.initialHour;
    this.getScreenSize();

    await this.sc.chargeData();
    //_ By default right now only ios mode will show; if enable for android please check in html tempalte also there should un comment the template for android style
    this.style = 'ios'; //_ (await this.sc.storage.get(Constants.USER_SETTINGS)).time_picker_style;
    this.obs['userSettings'] = this.appService._userSettings$.subscribe( (settings: any) => {
      this.style = settings.time_picker_style;
    });
  }

  ngOnDestroy() {
    Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.getScreenSize();
  }

  ngAfterViewInit(){

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['initialHour']){
      if(this.initialHour){
        const h = this.initialHour.split(":")
        this.hour = h[0]

        if(this.minuteDigits){
          this.minuteFirstDigit = h[1][0]
          this.minuteSecondDigit = h[1][1]
        }else
          this.minute = h[1]
      }
      this.time = this.initialHour;
    }
  }

  selectItem(items, itemSize, padding, secondCircle=false, clearFirstLine=false, clearSecondLine=false){
    let selectedItem = null;
    let selectedItem2 = null;

    if(clearSecondLine)
      this.container.querySelectorAll('.line-2').forEach(e => e.remove())
    if(clearFirstLine)
      this.container.querySelectorAll('.line').forEach(e => e.remove())

    items.forEach((item) => {
      const itemEl = this.container.querySelector(`.${secondCircle ? "sub-item" : "item"}${item.value}`);
      this.renderer.removeClass(itemEl, "selected-item");
      this.renderer.removeClass(itemEl, `item${item.value}-2`);

      if(this.view == "hour"){
        if(item.value == this.hour){
          this.renderer.addClass(itemEl, "selected-item");
          selectedItem = item.value;
        }
      }else if(this.view == "minute"){
        if(this.minuteDigits){
          if(!secondCircle){
            if(item.value == this.minuteSecondDigit){
              selectedItem = item.value;
              this.renderer.addClass(itemEl, "selected-item");
            }
          }
          else{
            this.renderer.addClass(itemEl, `${secondCircle ? "sub-item" : "item"}${item.value}-2`)
            if(item.value == this.minuteFirstDigit){
               selectedItem2 = item.value;
               this.renderer.addClass(itemEl, "selected-item");
            }
          }
        }else{
          if(item.value == this.minute){
            this.renderer.addClass(itemEl, "selected-item");
            selectedItem = item.value;
          }
        }
      }
    })

    setTimeout(()=>{
      if(selectedItem) this.drawLine(`.${secondCircle ? "sub-item" : "item"}${selectedItem}`);
      if(selectedItem2) this.drawLine(`.${secondCircle ? "sub-item" : "item"}${selectedItem2}-2`);
    }, 100)
  }

  drawCircle(items, itemSize, padding, secondCircle=false, minuteDigits=false){
    const totalItems = items.length;
    const containerSize = this.isSmallScreen ? 350 : 250;
    const radius = containerSize / 2; // circle radius

    const sizeItem = this.isSmallScreen ? 40 : itemSize;
    const distanceFromEdge = (radius * padding); // 10% of radius

    this.container.querySelectorAll('div:nth-child(n+2)').forEach(e => e.remove())
    setTimeout(()=>{
      items.forEach((item, index) => {
        const angleStep = (2 * Math.PI) / totalItems;
        const angle = angleStep * index;
        const x = radius * Math.cos(angle) + radius + Math.cos(angle) * distanceFromEdge;
        const y = radius * Math.sin(angle) + radius + Math.sin(angle) * distanceFromEdge;

        const itemEl = this.renderer.createElement('div');
        itemEl.textContent = item.label;
        itemEl.classList.add(secondCircle ? "sub-item" : "item");

        this.renderer.addClass(itemEl, `${secondCircle ? "sub-item" : "item"}${item.value}`)

        if(this.view == "hour")
          itemEl.addEventListener('click', (e)=> this.setHour(e, item.value))
        else if(this.view == "minute"){
          if(this.minuteDigits){
            if(!secondCircle)
              itemEl.addEventListener('click', (e)=> this.setMinuteSecondDigit(e, item.value));
            else
              itemEl.addEventListener('click', (e)=> this.setMinuteFirstDigit(e, item.value));
          }else{
            itemEl.addEventListener('click', (e)=> this.setMinute(e, item.value))
          }
        }

        itemEl.style.setProperty('--item-size', `${sizeItem}px`);
        itemEl.style.left = `${x}px`;
        itemEl.style.top = `${y}px`;

        if (item.visibility) {
          itemEl.style.zIndex = 0
          itemEl.textContent = '';
          itemEl.style.cursor = 'default'
          itemEl.style.backgroundColor = 'transparent'
        }

        this.renderer.appendChild(this.container, itemEl);
      });
    }, 100)
  }

  drawMainCircle(){
    const padding = this.isSmallScreen ? -0.20 : -0.20;
    //this.drawCircle(this.primaryItems, 25, -0.20)
    this.drawCircle(this.primaryItems, 30, padding)
  }

  drawSubCircle(){
    const padding = this.isSmallScreen ? -0.50 : -0.50;
    //this.drawCircle(this.secondaryItems, 25, -0.50, true)
    this.drawCircle(this.secondaryItems, 30, padding, true)
  }

  draw({size=250}:{size: number}){
    //const container = document.querySelector('.container') as any;
    const finalSize = this.isSmallScreen ? 350 : 250;
    this.container.style.setProperty('--container-size', `${finalSize}px`);
    this.drawMainCircle()
    this.drawSubCircle()
    setTimeout(()=> this.pivote(), 200)
  }


  drawLine(selector: string){
    const selectedItem = this.container.querySelector(selector) as any;
    const lineClass = selector.includes("-2") ? "line-2" : "line";

    if(selectedItem){
      const centerX = (this.container.offsetWidth / 2);
      const centerY = (this.container.offsetHeight / 2);

      const itemX = selectedItem.offsetLeft + selectedItem.offsetWidth / 2 - 12.5;
      const itemY = selectedItem.offsetTop + selectedItem.offsetHeight / 2 - 12.5;

      const distance = Math.sqrt(Math.pow(itemX - centerX, 2) + Math.pow(itemY - centerY, 2))

      const angle = Math.atan2(itemY - centerY, itemX - centerX);

      const midX = (centerX + itemX) / 2;
      const midY = (centerY + itemY) / 2;

      const svgLine2 = this.renderer.createElement('div');
      //this.renderer.addClass(svgLine2, 'line');
      this.renderer.addClass(svgLine2, lineClass);
      //this.renderer.setProperty(svgLine2, '--line-width', `${distance}px`);
      svgLine2.style.setProperty('--line-width', `${distance}px`)
      this.renderer.setStyle(svgLine2, 'width', `${distance}px`);
      this.renderer.setStyle(svgLine2, 'transform', `translate(-50%, -50%) rotate(${angle}rad)`);
      this.renderer.setStyle(svgLine2, 'top', `${midY}px`);
      this.renderer.setStyle(svgLine2, 'left', `${midX}px`);
      this.renderer.setStyle(svgLine2, 'border', '1px solid var(--ion-color-primary)');

      this.renderer.appendChild(this.container, svgLine2)
    }
  }

  pivote(digit='0', mf=false, ms=false){
    if(this.view == "minute"){
      if(this.minuteDigits) {
        if(!ms && !mf){
          this.selectItem(this.primaryItems, 30, -0.20, false, false, false)
          this.selectItem(this.secondaryItems, 30, -0.50, true, false, false)
        }else{
          if(ms){
            this.selectItem(this.secondaryItems, 30, -0.50, true, true, false)
          }
          if(mf){
            this.selectItem(this.secondaryItems, 30, -0.50, true, false, true)
          }else{
            this.selectItem(this.primaryItems, 30, -0.20, false, true, false)
          }
        }
      } else {
        this.selectItem(this.primaryItems, 30, -0.20, false, true, false)
        this.selectItem(this.secondaryItems, 30, -0.50, true, false, true)
      }
    }

    if(this.view === "hour"){
      this.selectItem(this.primaryItems, 30, -0.20, false, true, false)
      this.selectItem(this.secondaryItems, 30, -0.50, true, false, true)
    }
  }

  setHour(e, hour){
    this.hour = hour.toString();
    //this.draw({size: 250});
    this.pivote();
    this.changeView("minute");
    this.setIOSTime(this.hour)
  }

  setMinute(e, minute){
    this.minute = minute.toString();
    this.pivote();
    this.setIOSTime(null, this.minute)
  }

  setMinuteFirstDigit(e, digit){
    this.minuteFirstDigit = digit;
    //this.draw({size: 250});
    this.pivote(digit, true, false)
    const minute = `${this.minuteFirstDigit}${this.minuteSecondDigit}`;
    this.setIOSTime(null, minute)

  }

  setMinuteSecondDigit(e, digit){
    this.minuteSecondDigit = digit;
    //this.draw({size: 250});
    this.pivote(digit, false, true)
    const minute = `${this.minuteFirstDigit}${this.minuteSecondDigit}`;
    this.setIOSTime(null, minute)
  }

  changeView(view: "hour" | "minute"){
    this.view = view;
    if(view == "minute"){
      if(this.minuteDigits){
        this.primaryItems = this.minuteItems;
        this.secondaryItems = this.subMinuteItems;
      }else{
        this.primaryItems = this.minutes
        this.secondaryItems = []
      }
    }else if(view == "hour"){
      this.primaryItems = this.items;
      this.secondaryItems = this.subItems;
    }

    this.draw({size: this.isSmallScreen ? 350 : 250})
  }

  async onTimePickerPresent(){
    console.log('TIME PRESENT', { style: this.style, containerEl: this.containerEl})
    if (this.style === 'android') {
      this.container = this.containerEl.nativeElement;
      this.draw({size: this.isSmallScreen ? 350 : 250})
    }
  }

  onTimeAccept(){
    const minute = this.minuteDigits ? `${this.minuteFirstDigit}${this.minuteSecondDigit}` : this.minute;
    this.timeChanged.emit({time: `${this.hour}:${minute}`, start: this.uniqueId == "start"})
    if(this.style == 'android') this.changeView("hour")
  }

  async canDismiss(data?: any, role?: string) {
    return role !== 'gesture';
  }

  getScreenSize() {
    this.isSmallScreen =
      document.documentElement.clientWidth < 769 ? true : false;
  }

  async toggleStyle(newStyle: 'ios' | 'android') {
    this.style = newStyle;
    if(newStyle === 'android') {
      setTimeout(() => {
        this.onTimePickerPresent();
      }, 100)
    }
    await this.saveStyle(newStyle);
  }

  async saveStyle(style) {
    if (!this.sc.initialized) return false;
    this.sc.userSettings.time_picker_style = style;
    await this.sc.storage.set(Constants.USER_SETTINGS, this.sc.userSettings);
    this.appService._userSettings.next(this.sc.userSettings);
    this.sc.updateUserSettings();
  }

  onTimeUpdate(event) {
    const h = event.detail.value.split(":")
    this.hour = h[0]

    if(this.minuteDigits){
      this.minuteFirstDigit = h[1][0]
      this.minuteSecondDigit = h[1][1]
    }else
      this.minute = h[1]
  }

  onTimeUpdateForStep(minute, more){
    const max = minute ? 59 : 23

    let newMinute;
    let newHour;
    if(minute){
      const op = more ? parseInt(this.minute) + 1 : parseInt(this.minute) - 1;
      newMinute = op < 0 ? 0 : op;
      newMinute = newMinute > max ? max : newMinute;

      this.minute = newMinute.toString().padStart(2,"0");
      newHour = this.hour;
    }else{
      const op = more ? parseInt(this.hour) + 1 : parseInt(this.hour) - 1;
      newHour = op < 0 ? 0 : op;
      newHour = newHour > max ? max : newHour;

      this.hour = newHour.toString().padStart(2,"0");
      newMinute = this.minute;
    }

    this.time = `${this.hour}:${this.minute}`;
  }

  setIOSTime(hour=null, minute=null) {
    if(!hour && !minute) return;

    const h  = this.time.split(":");

    if(hour && !minute)
      this.time = `${hour}:${h[1]}`
    else if(!hour && minute)
      this.time = `${h[0]}:${minute}`
    else
      this.time = `${hour}:${minute}`

  }

  formatInput(input)   {
    // console.log("test")
    let value = parseInt(input.value, 10);
    // Ensure the value is a number and within the allowed range
    if (isNaN(value)) {
      value = 0;
    } else if (input.id === 'hours' && value > 23) {
      value = 23;
    } else if (input.id === 'minutes' && value > 59) {
      value = 59;
    }

    if(input.id === 'hours' && input.value.toString().length === 3 && !this.applyFocus){
      this.minutesEl.nativeElement.focus();
      this.applyFocus = true;
    }

    // Update the input value, formatting as two digits
    input.value = value.toString().padStart(2, '0');
    // console.log("se llamó...")
  }

  presentModalFromSetTime() {
    this.modalSetTime.dismiss();
    this.modal.present()
  }

  presentModalSetTimeFromModal() {
    this.modal.dismiss();
    this.modalSetTime.present();
  }

  acceptTimeSet(hourInput, minutesInput) {
    this.hour = hourInput.value;
    this.minute = minutesInput.value;
    this.modalSetTime.dismiss();
    this.onTimeAccept();
  }

  setOpenModal(open: boolean) {
    this.isModalOpen = open;
  }


}
