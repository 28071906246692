import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingIndicatorComponent } from './loading-indicator.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translations/", ".json");
}

@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
    ],

    declarations: [LoadingIndicatorComponent],
    exports: [LoadingIndicatorComponent],
    
  })

  export class PajLoadingIndicatorModule {}