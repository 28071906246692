<div class="test-account-popup" *ngIf="isOpen">
  <ion-grid class="ion-text-center">
    <ion-row>
      <ion-col size="12">
        <ion-label class="ion-text-wrap">
          <h2>{{ "testAccount.message" | translate }}</h2>
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
    </ion-row>
  </ion-grid>
  <div class="ion-text-center">
    <ion-button (click)="logout()" color="primary" size="small">
      <ion-icon slot="start" name="power-outline"></ion-icon>
      {{ "Logout" | translate }}
    </ion-button>
    <ion-button (click)="dismissPopup()" color="primary" size="small">
      {{ "testAccount.okButton" | translate }}
    </ion-button>
  </div>
</div>