<paj-popup-template headerTitle="{{ 'map.alertText' | translate }}" [contentTemplate]="Content">

</paj-popup-template>

<ng-template #Content>
  <ion-grid fixed class="ion-padding">
    <ion-row>
      <ion-col class="ion-text-center ion-no-padding" size="12">
        <ion-label>
          {{ "side-bar.errorPopUpText" | translate }}
        </ion-label>
      </ion-col>
      
      <ion-col size="12" class="ion-text-center">
        <ion-button
          (click)="onCancel()"
          shape="round"
          color="light"
        >
          OK
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  
</ng-template>

