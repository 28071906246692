<ion-header style="position: sticky">
  <ion-toolbar>
    <ion-label class="label-fontsize">{{ "survey.heading" | translate }}</ion-label>
  </ion-toolbar>
</ion-header>

<div>
  <ion-grid class="ion-text-center button-container">
    <ion-item lines="none">
      <img class="survey-logo" src="../assets/images/survey.svg" alt="" />
    </ion-item>
    <ion-row>
      <ion-col style="padding-bottom: 0px;">
        <ion-item lines="none">
          <ion-label class="ion-text-wrap hide-scroll label-fontsize">
            <div class="ion-text-wrap hide-scroll label-fontsize" [innerHTML]="alertContent | sanitizeHtml"></div>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-checkbox
          slot="start"
          [(ngModel)]="uptoDateEmail"
        ></ion-checkbox>
          <ion-label style="font-size: 12px">
            {{ "survey.uptodateViaEmail" | translate }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col
        class="ion-text-center"
        size="6"
        size-md="6"
        size-sm="6"
        size-lg="6"
        size-xl="6"
      >
        <ion-button
          size="small"
          (click)="selectOption(1)"
          expand="full"
          shape="round"
          color="primary"
          class="button-size"
        >
          <ion-label class="ion-text-wrap label-fontsize">{{
            "survey.yes" | translate
          }}</ion-label>
        </ion-button>
      </ion-col>
      <ion-col
        class="ion-text-center"
        size="6"
        size-md="6"
        size-sm="6"
        size-lg="6"
        size-xl="6"
      >
        <ion-button
          size="small"
          (click)="selectOption(0)"
          expand="full"
          shape="round"
          color="primary"
          class="button-size"
        >
          <ion-label class="ion-text-wrap label-fontsize">{{
            "survey.no" | translate
          }}</ion-label>
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-item class="ion-text-center" lines="none">
          <ion-label class="ion-text-wrap note-fontsize">{{ "survey.note" | translate }}</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
