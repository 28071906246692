<ion-content scrollY="false" scrollX="false" slot="fixed">
  <!-- <div class="test-item">
    <p>Device rotation</p>
    <input type="range" min="0" max="360" value="0" class="sliderInput" #range (input)="testFn(range.value)">
    <p>{{ range.value }} deg</p>
  </div> -->

  <app-slow-performance-popup></app-slow-performance-popup>
  <div id="map-{{ id }}" [style.width]="width" [style.height]="height"
    class="main-map {{ mapStyle }} {{ mapType }} {{ dimension }}" [class.dark-style-bg]="
      (mapStyle != 'pajLite' && isDarkmode) ||
      (mapStyle != 'pajLite')
    "></div>
</ion-content>

<div
  class="legends-section-item"
  [class.legends-section-for-tabs]="hasTabBar"
  [class.left-sidebar-open]="mainMenuOpen"
  [hidden]="hideLegends"
  *ngIf="user?.super_user == 1"
>
  <!--<ion-item
    class="main-icon ion-no-padding content"
    *ngIf="userSettings?.use_graphhopper && !graphhopperLegendIsOpen"
    shape="round"
    lines="none"
    (click)="graphhopperLegendAction($event)"
    button
    mode="md"
  >
    <ion-icon
      class="ion-no-margin"
      src="assets/images/app-settings/animate-marker.svg"
      color="primary"
    ></ion-icon>
  </ion-item>!-->
  <ion-item
    class="content route-engine"
    #graphhopperLegend
    *ngIf="userSettings?.use_graphhopper && graphhopperLegendIsOpen"
    lines="none"
    style="--max-height: 30px; --height: 30px;"
    @fadeIn
  >
    <ion-icon
      src="assets/images/app-settings/animate-marker.svg"
      slot="start"
      color="primary"
      style="margin: 0 15px 0 0;"
    ></ion-icon>
    Using Graphhopper
  </ion-item>

  <ion-item class="main-icon ion-no-padding content" lines="none"
    *ngIf="userSettings?.map_monitor_mode && !mapMonitorLegendIsOpen" shape="round"
    (click)="mapMonitorLegendAction($event)" button mode="md">
    <div class="circle-pulse" src=""></div>
  </ion-item>

  <ion-item class="content monitor-mode" *ngIf="userSettings?.map_monitor_mode && mapMonitorLegendIsOpen" lines="none"
    #mapMonitorLegend>
    <div class="circle-pulse" src="" slot="start"></div>
    {{ "appSettings.performance.monitorModeLegend" | translate }}
    <ion-icon name="information-circle-outline" color="primary" slot="end" (click)="
        showInfoPopup(
          $event,
          'appSettings.performance.monitorModeLegend',
          'appSettings.performance.monitorMode',
          null,
          null
        )
      "></ion-icon>
  </ion-item>
</div>
<div id="map-bottom-controls" class="main-map-controls" [class.middle-break]="deviceMenuOpen"
  [class.device-menu-open-content]="deviceMenuOpen || dashboardMenuIsOpen" [class.left-sidebar-open]="mainMenuOpen">
  <div class="first-section" *ngIf="BottomButtons['first-section']">
    <ng-container [ngTemplateOutlet]="sliderTemplate ? sliderTemplate : null"></ng-container>
    <ng-container [ngTemplateOutlet]="timerTemplate ? timerTemplate : null"></ng-container>
  </div>

  <div class="second-section" *ngIf="BottomButtons['second-section']">
    <div class="legends-section" [class.legends-section-for-tabs]="hasTabBar">
      <!-- HEATMAP LEGENDs -->
      <div class="content" *ngIf="!isSliderOpen && !fabValue" @fadeIn>
        <div class="gradient-segment heatmap">
          <div>
            <span>{{ "map.heatmapLessPoints" | translate }}</span>
          </div>
          <div>
            <span>{{ "map.heatmapManyPoints" | translate }}</span>
          </div>
        </div>
        <p class="gradient-segment-name">
          {{ "dashboard.heatmapText" | translate }}
        </p>
      </div>

      <!-- SPEED POLYLINES LEGENDs -->
      <div class="content" *ngIf="
          HighSpeedPolylinesClass?.show &&
          HighSpeedPolylinesClass?.show_speed_btn &&
          !isSliderOpen &&
          !fabValue
        " @fadeIn>
        <div class="gradient-segment">
          <div class="slow">
            <span>0-{{
              HighSpeedPolylinesClass.SPEED_RANGES[0].max +
              " " +
              user?.distance_unit
              }}/h
            </span>
          </div>
          <div class="medium">
            <span>{{ HighSpeedPolylinesClass.SPEED_RANGES[1].min }}-{{
              HighSpeedPolylinesClass.SPEED_RANGES[1].max +
              " " +
              user?.distance_unit
              }}/h</span>
          </div>
          <div class="high">
            <span>+{{
              HighSpeedPolylinesClass.SPEED_RANGES[2].min +
              " " +
              user?.distance_unit
              }}/h
            </span>
          </div>
        </div>
        <p class="gradient-segment-name">
          {{ "appSettings.speedPolylines" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
