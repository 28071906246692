export class CanvasObject {
    isVisible = false;
    isPlaying = false;
    animationTime = 0;
    previousTime = 0;
    pixelRatio = 1;
    elapsed = 0;
    effect = '';
    alpha = 1;

    constructor(public ctx, public x, public y, public width, public height) {

    }

    //_ Get pixel size related to pixel ratio
    gp(pixels) { return this.pixelRatio * pixels; }

    show() {
        this.alpha = 1;
        this.isVisible = true;
        this.isPlaying = true;
    }

    hide() {
        this.alpha = 0;
        this.isVisible = false;
        this.isPlaying = false;
    }

    draw() { }

    update(timestamp) {
        if (!this.isPlaying) return;
        this.elapsed += timestamp;
        if (this.effect == 'fadeIn' && this.alpha < 1) { //_ Effect logic here
            const newAlpha = (this.elapsed / this.animationTime);
            this.alpha = newAlpha > 1 ? 1 : newAlpha;
        }

        if (this.effect == 'fadeOut' && this.alpha > 0) { //_ Effect logic here
            const newAlpha = 1 - (this.elapsed / this.animationTime);
            this.alpha = newAlpha < 0 ? 0 : newAlpha;
        }
    }

    //_ Global Effects
    fadeIn(ms) {
        this.animationTime = ms;
        this.effect = 'fadeIn';
        this.alpha = 0;
        this.resetTime();
        this.isPlaying = true;
    }

    fadeOut(ms) {
        this.animationTime = ms;
        this.effect = 'fadeOut';
        this.alpha = 1;
        this.resetTime();
        this.isPlaying = true;
    }

    resetTime() {
        this.elapsed = 0;
        this.previousTime = 0;
    }
}