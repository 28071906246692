import { Component, Input, OnInit } from '@angular/core';
import { animations } from 'src/app/animations/animations';
import { SetupDeviceService } from '../device-setup-guide/device-setup.service';
import { CommunicationService } from '../communitaction.service';

@Component({
  selector: 'setup-device-alarms',
  templateUrl: './device-alarms.page.html',
  styleUrls: ['./device-alarms.page.scss'],
  animations: animations
})
export class DeviceAlarmsPage implements OnInit {
  @Input() device = null;
  constructor(private setupDeviceService: SetupDeviceService, private communicationService: CommunicationService) { }

  ngOnInit() {
    
  }

  alarmChange(ev){
    const { name, status, device } = ev;
    this.device[name] = status;
    this.setupDeviceService.onDeviceChange.next({ device: this.device, stepName: 'alarms' })
  }

  nextSetting(){
    this.communicationService.emitEvent("device_tracking")
  }
}
