import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TimerCountComponent } from './timer-count.component';


@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
    ],

    declarations: [TimerCountComponent],
    exports: [TimerCountComponent],
    
  })

  export class PajTimerCountModule {}