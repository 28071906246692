import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

export interface Message {
    source: string;
    content: string;
}

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    public messages: Subject<any>;
    private socket$: WebSocketSubject<any>;
    private messagesSubject$ = new Subject();
    public messages$ = this.messagesSubject$;
    constructor() {


    }

    public connect(url) {
        this.socket$ = webSocket({
            url: url,
            deserializer: ({ data }) => data,
            serializer: msg => msg
        });
        this.socket$.subscribe({
            next: msg => {
                this.messagesSubject$.next(msg);
            }, // Called whenever there is a message from the server.
            error: err => {
                console.log(err);
            }, // Called if at any point WebSocket API signals some kind of error.
            complete: () => {
                console.log('complete');
            } // Called when connection is closed (for whatever reason).
        });
    }

    close() {
        if (this.socket$) {
            this.socket$.complete();
        }
    }

    sendMessage(msg: any) {
        if (this.socket$) {
            this.socket$.next(msg);
        }
    }
}