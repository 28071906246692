import { Subject } from "rxjs";

export class PanFeature {
    private _isPan = new Subject<boolean>();
    isPan$ = this._isPan.asObservable();
    private map;
    private center;
    private mouseDown;
    private mouseUp;

    // Receive a Mapbox instance
    constructor(_map) {
        this.map = _map;
        this.loadEvents();
    }

    loadEvents() {
        this.mouseDown = (e) => {
            this.center = this.map.getCenter().wrap();
        };

        this.mouseUp = (e) => {
            const newCenter = this.map.getCenter().wrap();
            if (this.checkIsMoved(newCenter, this.center)) {
                this._isPan.next(true);
            }
        };

        this.map.on('mousedown', this.mouseDown);
        this.map.on('touchstart', this.mouseDown);
        this.map.on('mouseup', this.mouseUp);
        this.map.on('touchend', this.mouseUp);
    }

    checkIsMoved(locA, locB) {
        return locA && locB && (locA.lat !== locB.lat || locA.lng !== locB.lng);
    }

    destroy() {
        // Remove event listeners
        this.map.off('mousedown', this.mouseDown);
        this.map.off('touchstart', this.mouseDown);
        this.map.off('mouseup', this.mouseUp);
        this.map.off('touchend', this.mouseUp);

        // Complete the subject to clean up observable
        this._isPan.complete();
    }
}
