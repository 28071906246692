import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonList, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment as ENV } from 'src/environments/environment';
import { StorageService as Storage } from './../../services/storage.service';


@Component({
  selector: 'app-select',
  templateUrl: './selectable.component.html',
  styleUrls: ['./selectable.component.scss'],

})
export class SelectableComponent implements OnInit {
  // @ViewChild('list') list: IonVirtualScroll;
  @ViewChild('list') list: IonList;
  @Input() canSearch = false;
  @Input() showAll = false;
  @Input() items: any = null;
  @Input() tagId = '';
  @Input() tagName = '';
  @Input() firstName = '';
  @Input() driver = 0;
  @Input() lastName = '';
  @Input() icon = null;
  @Input() showHeader = true;
  @Input() showInPopover = false;
  @Input() selItem: any = null;

  @Input() badge = null;
  @Input() badgeColor = 'light';
  @Input() rightIcon = null;
  @Input() forFilters = false;
  @Input() label = 'Select';
  @Input() mini = false;
  @Input() headerColor = 'dark';
  @Input() isKebab = false;
  @Input() cssClass = '';
  @Input() useImageFromItem = false;
  @Input() useIconFromItem = false;
  @Input() headerText = '';
  @Input() addItem = false;
  @Input() addItemText = '';
  @Input() useNameAsTranslationKey = false;
  @Input() hideSelectHeader = false;
  @Input() listHeight = '200px';

  url: string = ENV.ROUTE;
  searchItems: any = null;
  language: string;
  all = { name: 'All Devices', id: -1 };

  constructor(private modelCtrl: ModalController, private storage: Storage,
    private translate: TranslateService, private popoverCtr: PopoverController) {
    this.loadLanguage();
  }




  async ngOnInit() {
    if (this.items) {
      if (this.items[0]) {
        if (this.items[0].hasOwnProperty('active')) {
          if(this.driver)
            this.tagName = 'last_name';
          this.searchItems = this.items.filter(r => {
            return (r.active != null ? r.active == 1 : r) && (r[this.tagName]);
          });
          this.items = this.items.filter(r => {
            return (r.active != null ? r.active == 1 : r) && (r[this.tagName]);
          });
        } else {
          this.searchItems = this.items.filter(r => {
            return r[this.tagName];
          });
        }
      }
    }
  }

  async dismiss() {
    if (!this.showInPopover)
      await this.modelCtrl.dismiss({
        dismissed: true,
        selItem: this.selItem,
      });
    else
      await this.popoverCtr.dismiss({
        dismissed: true,
        selItem: this.selItem,
      });
  }

  async selectItem(it: any) {
    if (this.selItem?.id == it.id) {
      this.selItem.change = false
    }
     else {
      this.selItem = it;
      this.selItem.change = true
    }
    await this.dismiss();
  }

  async close() {
    if (this.showInPopover) await this.popoverCtr.dismiss();
    else await this.modelCtrl.dismiss();
  }

  searchTerm = null;
  onSearch(ev) {
    this.searchTerm = ev.srcElement.value;
    this.items = this.searchItems;
    if (this.searchTerm && this.searchTerm.trim() !== '') {
      this.items = this.items.filter(term => {
        // let fullName = term["first_name"][this.tagName]
        let fullName = (term["first_name"] ? term["first_name"] : '')+' '+term[this.tagName]
        let fullNameInverted =term[this.tagName]+' '+(term["first_name"] ? term["first_name"] : '')

        return fullName?.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 || fullNameInverted?.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1;;
        // return term[this.tagName]?.toLowerCase().indexOf(val.trim().toLowerCase()) > -1;
      });
    }
  }

  async loadLanguage() {
    this.all.name = this.translate.instant('selectSearchable.all');
  }


  //_ Alfabetic header

  headerFn(record, recordIndex, records) {
    let prevLetter = record.name[0];
    if (recordIndex > 0) prevLetter = records[recordIndex - 1].name[0];
    else return prevLetter;

    if (prevLetter != record.name[0])
      return record.name[0];
    else return null;
  }

  //_
  //_ Still working on this part
  //_
  scrollTo(index: number) {

  }
}
