import { animate, style, transition, trigger } from "@angular/animations";

export const animations = [
  trigger("slideDown", [
    transition(":enter", [
      style({ transform: "translateY(-30%)", opacity: 0 }),
      animate("350ms", style({ transform: "translateY(0)", opacity: 1 })),
    ]),
    transition(":leave", [
      style({ transform: "translateY(0)", opacity: 1 }),
      animate("350ms", style({ transform: "translateY(-30%)", opacity: 0 })),
    ]),
  ]),

  trigger("slideUp", [
    transition(":enter", [
      style({ transform: "translateY(30%)", opacity: 0 }),
      animate("350ms", style({ transform: "translateY(0%)", opacity: 1 })),
    ]),
    transition(":leave", [
      style({ transform: "translateY(0%)", opacity: 1 }),
      animate("350ms", style({ transform: "translateY(30%)", opacity: 0 })),
    ])
  ]),

  trigger("fadeIn", [
    transition(":enter", [
      style({ opacity: 0, }),
      animate("300ms", style({ opacity: 1 })),
    ]),
    transition(":leave", [
      style({ opacity: 1 }),
      animate("0ms", style({ opacity: 0 })),
    ]),
  ]),

  trigger("zoomIn", [
    transition(":enter", [
      style({ opacity: 0, transform: 'scale(0.5)' }),
      animate("300ms", style({ opacity: 1, transform: 'scale(1)' })),
    ]),
    transition(":leave", [
      style({ opacity: 1, transform: 'scale(1)' }),
      animate("300ms", style({ opacity: 0, transform: 'scale(0.5)' })),
    ]),
  ]),

  trigger("swipeLeft", [
    transition(":enter", [
      style({ opacity: 0, transform: 'translateX(-30%)' }),
      animate("300ms", style({ opacity: 1, transform: 'translateX(0)' })),
    ]),
    transition(":leave", [
      style({ opacity: 1, transform: 'translateX(0)' }),
      animate("300ms", style({ opacity: 0, transform: 'translateX(30%)' })),
    ]),
  ])
]
