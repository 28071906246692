
<ng-template #Content>
  <ion-grid fixed class="ion-no-padding">
    <ion-item color="transparent" lines="none">
      <ion-icon slot="start" name="warning"></ion-icon>
      <ion-label class="ion-text-wrap">
        <p>{{ 'map.performancePopup.canRunFaster' | translate }}</p>
        <p>{{ 'map.performancePopup.pleaseEnable' | translate }}</p>
        <div class="tooltip">
          <p>{{ 'map.performancePopup.platformDetected' | translate }} <b>{{ platform?.browserName }}</b>
            {{ 'map.performancePopup.goto' | translate }} <span><ion-badge color="light">{{ settingsLink[platform.browserName]?.local }}</ion-badge></span> {{ 'map.performancePopup.lookFor' | translate }}.</p>
        </div>
      </ion-label>
    </ion-item>

    <ion-item color="transparent" lines="none" class="dont-show-again">
      <ion-checkbox mode="ios" slot="start" (ionChange)="close($event)"></ion-checkbox>
      <ion-label class="ion-text-wrap">{{ 'alerts.shockPopup.dontShowAgain' | translate }}</ion-label>
    </ion-item>

    <ion-item color="transparent" lines="none">
      <ion-button slot="end" color="light" (click)="openSettings()">
        <ion-icon name="help-circle-outline" slot="start"></ion-icon>
        <ion-label>{{ 'map.performancePopup.howTo' | translate }}</ion-label>
      </ion-button>
    </ion-item>
  </ion-grid>
</ng-template>
<!-- </ion-card> -->