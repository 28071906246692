import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api.service';
import { SupportLinkService } from "../../services/support-link.service";

@Component({
  selector: 'password-reset-advice',
  templateUrl: './password-reset-advice.html',
  styleUrls: ['./password-reset-advice.scss'],
})
export class PasswordResetAdvice implements OnInit {
  emailSent = false;
  emailAlreadSent = false;
  isBrowser = false;
  emailNotReceived: boolean;
  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private supportLink: SupportLinkService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isBrowser = window['isBrowser'];
  }

  requestPasswordEmail() {
    this.apiService.forgotPassword({ email: this.appService?.user.email }).subscribe(res => {
      this.emailSent = true;
    }, error => {
      console.log('DEBUG ', error)
      if (error.error.error === 'Email already send.') {
        this.emailAlreadSent = true;
        this.emailSent = true;
      } else {
        this.emailNotReceived = true;
        this.appService.showToast('', 'Error requesting reset password email', 3000, 'danger');
      }
    });
  }

  goToLogin () {
    this.appService.doLogout.next(true);
    // this.router.navigate(['/login'], { replaceUrl: true });
  }

  requestEmailFromAccountManager() {
    this.supportLink.requestEmailFromAccountManager();
  }

}
