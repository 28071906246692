import { ServerAlertComponent } from "./server-alert.component";
import * as moment from 'moment';

export const Error_Status = (minStart) => [ {
    min: minStart,
    message: 'Updating servers cluster'
  },
  {
    min: minStart + (60*15),
    message: 'Firewall update',
  },
  {
    min: minStart + (60*30),
    message: 'Performing backup'
  },
  {
    min: minStart + (60*45),
    message: 'Database security update'
  },
  {
    min: minStart + (60*60),
    message: 'Database security update'
  },
  {
    min: minStart + (60*75),
    message: 'Server security updates'
  },
  {
    min: minStart + (60*90),
    message: 'Migrating resources'
  },
  {
    min: minStart + (60*105),
    message: 'Syncing data'
  },
  {
    min: minStart + (60*120),
    message:  'Cleaning cache'
  },
  {
    min: minStart + (60*135),
    message: 'Restarting services'
  },
  {
    min: minStart + (60*150),
    message: 'Checking services'
  } ];

  export const MaintenanceAlert = {
    id: -1,
    name: '',
    message: 'We are really sorry for the inconvenience. \nOur servers are in maintenance.',
    message_de: 'Wir entschuldigen uns für die Unannehmlichkeiten. \nUnsere Server werden gewartet.',
    message_es: 'Lamentamos mucho las molestias. \nNuestros servidores están en mantenimiento.',
    message_fr: 'Nous sommes vraiment désolés pour la gêne occasionnée. \nNos serveurs sont en maintenance.',
    message_it: "Siamo davvero dispiaciuti per l'inconveniente. \nI nostri server sono in manutenzione.",
    message_pt: 'Sentimos muito pelo inconveniente. \nNossos servidores estão em manutenção.',
    show: true,
    type: 'warning'
  };

  export const SpecialAlert = {
    id: -2,
    name: '',
    message: 'Please check your alarm settings. Additionally, please log in and out again to reactivate the push notifications functions. \nDue to a software update, certain settings had to be reset. <br><b>We apologise for this.</b>',
    message_de: 'Bitte überprüfen Sie Ihre Alarmeinstellungen. <br>Loggen Sie sich bitte zusätzlich erneut ein und wieder aus um die Funktionen der Push-Nachrichten wieder zu aktiveren. <br>Aufgrund eines Software-Updates mussten bestimmte Einstellungen zurück gesetzt werden. <br>Wir bitten dies zu entschuldigen.',
    message_es: 'Por favor, compruebe la configuración de sus alarmas. Además, inicie sesión y vuelva a cerrar sesión para reactivar las funciones de notificaciones automáticas. \nDebido a una actualización de software, se tuvieron que restablecer ciertas configuraciones. <br><b>Pedimos disculpas por esto.</b>',
    message_fr: "Veuillez vérifier vos paramètres d'alarme. De plus, veuillez vous reconnecter et vous déconnecter pour réactiver les fonctions de notifications push. \nEn raison d'une mise à jour logicielle, certains paramètres ont dû être réinitialisés.  <br><b>Nous nous en excusons.</b>",
    message_it: "Si prega di controllare le impostazioni della sveglia. Inoltre, effettua nuovamente il login e la disconnessione per riattivare le funzioni di notifica push. \nA causa di un aggiornamento software, è stato necessario ripristinare alcune impostazioni.  <br><b>Ci scusiamo per questo.</b>",
    message_pt: 'Verifique suas configurações de alarme. Além disso, faça login e logout novamente para reativar as funções de notificações push. \nDevido a uma atualização de software, algumas configurações tiveram que ser redefinidas.  <br><b>Pedimos desculpas por isso.</b>',
    show: true,
    type: 'warning'
  };

  export const logbookAlert = {
    id: -2,
    name: '',
    message: 'Due to software update, Please mark your logbook routes as we have lifted the limit to mark routes older than 7 days. <br><b>Sorry for this inconvenience.</b>',
    message_de: 'Aufgrund eines Software-Updates bitten wir Sie, Ihre Logbuch-Routen zu markieren, da wir das Limit für die Markierung von Routen, die älter als 7 Tage sind, aufgehoben haben. <br><b>Entschuldigung für diese Unannehmlichkeiten.</b>',
    message_es: 'Debido a la actualización del software, por favor, marque las rutas de su logbook, hemos quitado el límite temporalmente para marcar rutas despues de 7 días. <br><b>Disculpe las molestias.</b>',
    message_fr: "En raison d'une mise à jour du logiciel, veuillez marquer vos itinéraires de carnet de route car nous avons levé la limite pour marquer les itinéraires de plus de 7 jours. <br><b>Désolé pour cet inconvénient.</b>",
    message_it: "A causa di un aggiornamento del software, vi invitiamo a segnare i percorsi del vostro diario di bordo, poiché abbiamo eliminato il limite di segnare i percorsi più vecchi di 7 giorni. <br><b>Ci scusiamo per l'inconveniente.</b>",
    message_pt: 'Devido à actualização do software, por favor marque as rotas do seu diário de bordo, uma vez que levantámos o limite de marcação de rotas com mais de 7 dias. <br><b>Desculpe por este inconveniente</b>',
    show: true,
    type: 'warning'
  };

  export const Geofencev1Alert = (translate) => {
    return {
      id: -3,
      name: '',
      message: translate.instant('serverAlert.geofencesv1'),
      message_de: translate.instant('serverAlert.geofencesv1'),
      message_es: translate.instant('serverAlert.geofencesv1'),
      message_fr: translate.instant('serverAlert.geofencesv1'),
      message_it: translate.instant('serverAlert.geofencesv1'),
      message_pt: translate.instant('serverAlert.geofencesv1'),
      show: false,
      type: 'warning',
      customImg: 'assets/images/alarm/geofence-alert.svg',
      actionData: {
        buttonName: translate.instant('serverAlert.DeleteGeofences'),
        fn: (main: ServerAlertComponent) => new Promise(( resolve, reject) => {
          main.authService.deleteGeofencesV1(main.appService.user.id).then( r => {
            main.appService.showToast(null, translate.instant('serverAlert.geofencesDeletedSuccess'), 2000, 'success');
            resolve(true);
          }).catch(e => resolve(true));
        })
      }
  };
}

export const extractHtmlLinks = (inputString: string): string[] => {
  const linkPattern = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
  let matches;
  const links = [];

  while ((matches = linkPattern.exec(inputString))) {
    links.push(matches[1]);
  }

  return links;
}

export const checkAlertModelsAndLanguage = (alert, deviceModels, language) => {
  let existModel = true;
  let existLanguage = true;

  if (alert.only_models)
    if (alert.only_models.length > 0)
      existModel = deviceModels.some(item => alert.only_models.includes(item));

  if (alert.only_lang) {
    if (alert.only_lang?.length > 0) {
      const languageCode = language.includes('_') ? language.split('_')[0].toLowerCase() : language.toLowerCase();
      existLanguage = alert.only_lang.some(item => item.toLowerCase() === languageCode);
    }
  }

  return existModel && existLanguage;
}

export const getDeviceModels = (devices) => {
  let models = [];
  devices.forEach(d => {
    if (d.device_models)
      if (d.device_models.length > 0) {
        d.device_models.forEach(m => {
          models.push(m.model_nr);
        })
      }
  })
  return models;
}

export const checkAlertIsReadUsingTriggeredInterval = async (alert, storage) => {
  return await storage.get('alert-' + alert.id).then((al: any) => {
    switch (alert.triggered_interval) {
      case "once":
        return !!al;
      case "daily":
        if (al) {
          if (moment.unix(al.date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))
            return true;
        }
        return false;
      case "weekly":
        if (al) {
          if (moment().isBetween(moment.unix(al.date), moment.unix(al.date).add(7, 'days')))
            return true;
        }
        return false;
      case "everyTime":
        return false;
      default:
        if (al) {
          if (moment().isBetween(moment.unix(al.date), moment.unix(al.date).add(parseFloat(alert.triggered_interval), 'hours')))
            return true;
        }
        return false;
    }
  });
}

export const saveAlertIsRead = (alert, storage) => {
  storage.set('alert-' + alert.id, { id: alert.id, date: moment().unix() });
}
