import { Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
// import { Map, NavigationControl, LngLatBounds, Marker,  } from "maplibre-gl";
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Style } from './class/style';
import { Layer, layerType } from './class/layer';
import { DataSource } from './class/dataSource';
import { Data, dataSourceType, dataTypes } from './class/data';
import { Geometry, GeometryTypes } from './class/geometry';
import { Feature } from './class/feature';
import { ApiService } from './../../../services/api.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Effects } from './class/effects';
import { mapUtil } from './class/mapUtil'
import { DARK_ATMOSPHERE, MapStylesList, directionMarker } from '../../map/components/map/class/mapInterfaces';
import {  BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { cloneObject, resizeImage } from '../../map/components/map/class/mapUtil';
import { IProperties } from './class/Iproperties';
import { AppService } from 'src/app/app.service';
import { applyDarkStyle } from '../../map/components/map/class/darkMode';
import { DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS, MapService } from '../../map/components/map/map.service';
import { getSource } from '../../map/components/map/class/castMaplibreData';
import * as moment from 'moment';
import { distance as Tdistance, lineString, along } from "@turf/turf";
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/constants.enum';
import { StorageService } from 'src/app/services/storage.service';
import { DynamicMapLib } from '../../map/components/map/dinamic-map-lib';
import { PanFeature } from "src/app/members/map/components/map/class/features/PanFeature";

@Component({
  selector: 'app-map-min',
  templateUrl: './map-min.component.html',
  styleUrls: ['./map-min.component.scss'],
})

export class MapMinComponent extends DynamicMapLib implements OnInit {
  public map: any;
  @Input() height = '300px';
  @Input() width = 'auto';
  @Input() pitch = 55;
  @Input() bearing = 0;
  @Input() interactive = true;
  @Input() dragableRouteMarkers = false;

  @Output() public onClickLayer = new EventEmitter<{}>();
  @Output() public onClickMap = new EventEmitter<{}>();
  @Output() public onDoubleClickMap = new EventEmitter<{}>();
  @Output() public onMouseEventMap = new EventEmitter<{}>();
  @Output() public onClickRoute = new EventEmitter<{}>();

  routeLayer: any = null;
  circlesLayer: any = null;
  markerLayer: any = null;
  effectsLayer: any = null;
  infoLayer: any = null;
  directionsLayer: any = null;

  routeSource: any = null;
  circlesSource: any = null;
  markerSource: any = null;
  infoSource: any = null;
  effectsSource: any = null;
  directionsSource: any = null;
  primaryColor: string = '#FF4E00';
  id = (new Date()).getTime();

  markers = [];
  @Input() mapStyle = 'paj';
  @Input() center: any = [13.404954, 52.520008];
  @Input() zoom = 0;
  @Input() useDefinedStyle = false;
  @Input() useMapbox: boolean = false;

  mapReady = new Subject();
  isDarkmode = false;
  mapLoaded:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mapRenderEnds = new Subject();

  //_ Set to true to prevent trigger mapRenderEnds because is lisenting for map idle state;
  disableRenderEnds = false;
  @Input() customerSettings = { map_projection: 'globe' };
  userSettings = null;
  loadedImages = []
  panFeature: PanFeature = null;

  constructor(private platform: Platform, private http: HttpClient, private api: ApiService,
    private permission: AndroidPermissions, private mapService: MapService,
    private appService: AppService, private storage: StorageService) {
      super();
  }

  ngOnInit() {
    let style = getComputedStyle(document.body);
    this.primaryColor = style.getPropertyValue('--ion-color-primary');

    if (!this.useDefinedStyle)
        this.mapStyle = this.mapService.mapStyle.value;

    this.routeSource = this.createDataSource('route');
    this.circlesSource = this.createDataSource('circles');
    this.markerSource = this.createDataSource('markers');
    this.effectsSource = this.createDataSource('effects');
    this.infoSource = this.createDataSource('infos');
    this.directionsSource = this.createDataSource('directions');

    this.routeLayer = this.createLayer('route', 'route');
    this.circlesLayer = this.createLayer('circles', 'circles');
    this.markerLayer = this.createLayer('markers', 'markers');
    this.effectsLayer = this.createLayer('effects', 'effects');
    this.infoLayer = this.createLayer('info', 'infos');
    this.directionsLayer = this.createLayer('directions', 'directions');

    this.isDarkmode = this.appService.darkMode;
    //_ This map no need listend for dark mode changes
    // this.appService.darkMode$.subscribe(r => {
    //   this.isDarkmode = r;
    //   this.setMapStyle(this.mapStyle, null);
    // });
  }

  ngAfterViewInit() {
    this.buildMap();
  }

  canvasDrawer;
  async buildMap() {
    this.userSettings = this.customerSettings ?? await this.storage.get(Constants.USER_SETTINGS);
    let style = await this.createMapStyle(this.mapStyle);

    this.useMapbox = this.shouldUseMapboxLib(this.mapStyle).useMapbox;
    await this.setupMapLibrary(this.useMapbox);

    // if (this.mapStyle == '3d')
    //   style = Constants.MAP_TILES_PAJ_STREET_3D;
    // mapboxgl.accessToken = environment.MAPBOX_TOKEN;
    window.devicePixelRatio = Constants.MAP_PIXEL_RATIO;

    const mapOptions = {
      container: 'mini-map-' + this.id,
      'style': style,
      zoom: this.zoom,
      maxZoom: 18,
      center: this.center, //Berlin, Germany Lat and Lng
      pitch: this.pitch, // pitch in degrees
      bearing: this.bearing,
      interactive: this.interactive,
      projection: this.userSettings.map_projection ?? 'mercator'
    }

    //_ Remove cache tiles for ios
    if (window['isSafari'])
      mapOptions['maxTileCacheSize'] = 0;

    //_ CREATE MAP
    console.log('MAP OBJ', this.MAP_LIB);
    this.map = new this.MAP_LIB.Map(mapOptions);

    this.panFeature = new PanFeature(this.map);

    let tc = this;

    this.map.on('load', async  ()=> {
      tc.mapLoaded.next(true);
      tc.map.resize();
      // Load Direction Image
      await tc.map.loadImage(directionMarker['arrow'], (error, image) => {
        if (!error) tc.map.addImage("direction-marker", image, { sdf: true });
      });

      //_ End flag image
      const flagImg: any = await resizeImage('assets/images/render-video/end-marker.svg', 84, 100, "white", false);
      await tc.map.loadImage(flagImg, (error, image) => {
        if (!error) tc.map.addImage("end-marker", image, { sdf: false });
      });
      //_ Start flag image
      const startImg: any = await resizeImage('assets/images/render-video/start-marker.svg', 82, 100, "white", false);
      await tc.map.loadImage(startImg, (error, image) => {
        if (!error) tc.map.addImage("start-marker", image, { sdf: false });
      });

      tc.map.addSource('route', tc.routeSource);
      tc.map.addSource('circles', tc.circlesSource);
      tc.map.addSource('markers', tc.markerSource);
      tc.map.addSource('infos', tc.infoSource);
      tc.map.addSource('effects', tc.effectsSource);
      tc.map.addSource('directions', tc.directionsSource);
      //tc.map.addSource('canvas-source', tc.canvasSource);

      tc.map.addLayer(tc.markerLayer);
      tc.map.addLayer(tc.routeLayer, 'markers');
      tc.map.addLayer(tc.circlesLayer, 'markers');
      tc.map.addLayer(tc.infoLayer, 'markers');
      tc.map.addLayer(tc.effectsLayer, 'info');
      tc.map.addLayer(tc.directionsLayer, 'markers');
      //tc.map.addLayer(tc.canvasLayer);

      tc.onClickMarkers();
      this.applyAtmosphere();
    });

    this.map.on('idle', () => {
      if (this.map.loaded() && !this.disableRenderEnds) {
        this.mapRenderEnds.next(true);
      }
    });

    this.map.once('idle', () => this.mapReady.next({ ok: true, id: this.id }));
    this.map.addControl(new this.NavigationControl( { showCompass: false, showZoom: true, visualizePitch: false } ), "top-left");
  }



  public setBearing(rad) {
    this.map.setBearing(rad);
  }

  public async addMark(name, x, y, img: any = null) {
    let marker;
    if (img) {
      let el = document.createElement('div');
      el.style.backgroundImage = 'url(' + img + ')';
      el.style.backgroundSize = 'cover';
      el.style.width = '48px';
      el.style.height = '48px';
      el.style.borderRadius = '50%';
      el.style.zIndex = '9';
      marker = new this.Marker(el);
      marker.setLngLat([x, y]);
    }



    marker.addTo(this.map);

  }

  public async addLayerMarker(name, lng, lat, img, size, title, data = null, alignment = 'viewport', anchor = 'bottom', layerOptions: any = { 'text-offset': [0, 2], 'text-color': '#000000'  }) {
    if (img.endsWith(".svg"))
      img = await resizeImage(img, 200, 200, "white", false);

    await this.map.loadImage(img, (error, image) => {
      if (error)
        this.addLayerMarker(name, lng, lat, 'assets/device_markers/v2_car_2.png', size, title, data, alignment); //throw error;
      else if (this.markerSource.data.features.map(x => x.properties.icon).indexOf(name) == -1) {
        let marker = this.createFeature([lng, lat], name, title, size, 'center', data, alignment);
        marker.properties['anchor'] = anchor;
        marker.properties["icon-offset"] = [0, 0];
        //_ Add layer options
        Object.keys(layerOptions).forEach(key => marker.properties[key] = layerOptions[key])

        if (this.map.hasImage(name)) {
          return this.addLayerMarkerUsingExistentImage(name, lng, lat, size, title, data, alignment, anchor, layerOptions, );
        }

        this.loadedImages.push({ name, image });
        this.map.addImage(name, image);
        this.markerSource.data.features.push(marker);
        if (getSource(this.map, 'markers'))
          getSource(this.map, 'markers').setData(this.markerSource.data);
      }
    });
  }

  public async addLayerMarkerUsingExistentImage(name, lng, lat, size, title, data = null, alignment = 'viewport', anchor = 'bottom', props: any = { 'text-offset': [0, 2] }) {
      let marker = this.createFeature([lng, lat], name, title, size, 'center', data, alignment);
      marker.properties['anchor'] = anchor;

      //_ Apply custom props
      Object.keys(props).forEach(key => {
        marker.properties[key] = props[key];
      })

      this.markerSource.data.features.push(marker);
      if (getSource(this.map, 'markers'))
        getSource(this.map, 'markers').setData(this.markerSource.data);
  }

  public async removeLayerMarkers(id = null, all = true) {
    try {
      if (id) {
        if (this.map.hasImage(id)) {
          await this.map.removeImage(id);
          this.markerSource.data.features = this.removeArrayItem(this.markerSource.data.features, id);
        }
      }
      if (all) {
        this.markerSource.data.features.forEach(mark => {
          this.map.removeImage(mark.properties.icon);
        });
        this.markerSource.data.features = [];
      }

      if (getSource(this.map, 'markers')) {
        getSource(this.map, 'markers').setData(this.markerSource.data);
      }
    } catch (error) { console.log("ERROR: ", error); };
  }

  removeArrayItem(arr, name) {
    return arr.filter((e) => {
      return e.properties.icon !== name;
    });
  }

  //Marks in divs thats not recorded by recordRTC in canvas
  public async removeMarkers() {
    this.markers.forEach((marker) => {
      marker.remove(this.map);
    });
    this.markers = []
  }

  public async drawMarkers() {
    this.markers.forEach((marker) => {

      marker.addTo(this.map);
    });
  }

  // Add directions markers points
  public addDirectionMarkers(points, color, size = 0.5, removeAll = false, updateMap = false) {
    points.forEach(p => this.addDirectionMarker(p, color, size, removeAll, updateMap));
  }
  ß
  public addDirectionMarker(point, color, size = 0.5, removeAll = false, updateMap = false, extraData = {}) {
    if (removeAll) {
      // Remove all features that start with 'direction-' propertie id
      // No realtime directions
      this.directionsSource.data.features =
        this.directionsSource.data.features.filter((f) => {
          return !f.properties.id.startsWith("direction");
        });
    }

    let geometry = new Geometry();
    let properties = {} as IProperties;
    geometry.type = GeometryTypes.Point;
    geometry.coordinates = [point.lng, point.lat];
    properties.icon = "direction-marker"; //name;
    properties.size = 0.4;
    properties["id"] = 'direction';
    properties["layerType"] = "directions"; //Type of feature
    properties["color"] = color;
    properties["invertColor"] = "#fff"; //invert(device.color, true);
    properties["rotate"] = point.direction;
    properties["directionId"] = point.id;

    properties = { ...properties, ...extraData };

    let fdirection = this._createFeature(geometry, properties);
    this.directionsSource.data.features.push(fdirection);

    // Update datasource to update the map layers
    // Only update datasource if updateMap flag is true
    if (this.map.getSource("directions") && updateMap)
      getSource(this.map, "directions").setData(this.directionsSource.data);
  }

  public async fitBounds(points, Xpadding: any = 20, Xspeed = 1.2, linear = true, event = null) {
    points = points.filter(function (el) { return el[0] != null && el[1] != null; });
    var bounds = points.reduce(function (bounds, coord) {
      return bounds.extend(coord);
    }, new this.LngLatBounds(points[0], points[0]));

    await this.map.fitBounds(bounds, { padding: Xpadding, speed: Xspeed, linear: linear }, event);
  }

  public async flyTo(x, y, Xzoom = 10, Xspeed = 1, pitch = this.pitch, bearing = this.bearing) {
    await this.map.flyTo({
      center: [x, y],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      curve: Xzoom,
      speed: Xspeed,
      pitch: pitch,
      bearing: bearing,
      maxDuration: Xspeed
    });

    return 0;
  }

  public async centerTo(x, y, Xzoom = 10, Xspeed = 1) {
    await this.map.flyTo({
      center: [x, y],
      essential: false, // this animation is considered essential with respect to prefers-reduced-motion
      zoom: Xzoom,
      duration: Xspeed
    });
  }

  public createDataSource(which = 'route') {
    let nsource: DataSource = <DataSource>{};
    nsource.data = <Data>{};
    nsource.type = dataSourceType.geojson;
    nsource.data.features = [];
    nsource["generateId"] = true;

    if (which == 'route') {
      nsource.data.type = dataTypes.FeatureCollection;
    }
    else nsource.data.type = dataTypes.FeatureCollection;

    return nsource;
  }

  public createFeature(points, iconName, title, size = 0.25, justify = 'center', data = null, alignment = 'map', colors = { fill: this.primaryColor, border: 0, 'border-color': this.primaryColor }, geometryType: GeometryTypes = GeometryTypes.Point, extraProps: any = {}) {
    let feature = new Feature();
    feature.geometry.type = geometryType;
    feature.geometry.coordinates = points;
    feature.properties.title = title;
    feature.properties.icon = iconName;
    feature.properties.size = size;
    feature.properties.justify = justify;
    feature.properties.alignment = alignment;
    feature.properties.color = colors.fill;
    feature.properties['border-color'] = colors['border-color'];
    feature.properties['border-width'] = colors.border;

    if (extraProps)
      feature.properties = {...feature.properties, ...extraProps };

    if (data) feature.properties.data = data;
    return feature;
  }

  public _createFeature(geometry, properties, id = null) {
    let feature = new Feature();
    feature.geometry = geometry;
    feature.properties = properties;

    if (id) feature.id = id;
    return feature;
  }

  public createLayer(id, source) {
    let layer = new Layer(id);
    layer.source = source;

    if (id == 'route'|| id=='routeLine') {
      layer.type = layerType.line;
      layer.layout["line-join"] = 'round';
      layer.layout["line-cap"] = 'round';
      layer.paint["line-color"] = ['get', 'color'];
      layer.paint["line-width"] = ['get', 'size'];
    }

    if (id == 'circles') {
      layer.type = layerType.circle;
      layer.paint["circle-radius"] = ['get', 'size'];
      layer.paint["circle-color"] = ['get', 'color'];
      layer.paint["circle-stroke-color"] = ['get', 'border-color'];
      layer.paint["circle-stroke-width"] = ['get', 'border-width'];
      layer.paint['circle-pitch-alignment'] = 'map';
      layer.paint["circle-opacity"] = 1.0;
    }

    if (id == 'markers' || id == 'info' || id == 'effects') {
      layer.type = layerType.symbol;
      layer.layout["icon-image"] = ['get', 'icon'];
      layer.layout["icon-rotate"] = ['get', 'rotate'];
      layer.layout["icon-size"] = ['get', 'size'];
      layer.layout["icon-offset"] = [0, 0]; //0
      layer.paint["text-color"] = ['get', 'text-color'];
      layer.layout["text-field"] = ['get', 'title'];
      layer.layout["text-offset"] = ['get', 'text-offset']; //[0, 2]; //2
      layer.layout["icon-allow-overlap"] = true;
      layer.layout["text-allow-overlap"] = true;
      layer.layout["icon-anchor"] = ['get', 'anchor']; //_ before it had 'bottom';
      layer.layout["icon-rotation-alignment"] = 'viewport'; //['get', 'iconAlignment'];
      layer.layout["icon-offset"] = ["get", "icon-offset"];
    }

    if (id == 'info'){
      layer.layout["icon-rotate"] = ["get", "rotate"];
      layer.layout["icon-rotation-alignment"] = 'map';
    }

    if (id == 'effects') {
      layer.layout["icon-anchor"] = 'center';
      layer.layout["icon-rotation-alignment"] = 'map';
    }

    // if (id == 'info') {
    //   layer.layout["icon-offset"] = [-50, 20.0];
    //   layer.layout["text-anchor"] = 'left';
    //   layer.layout["text-justify"] = ['get', 'justify'];
    //   layer.layout["text-field"] = ['get', 'title'];
    // }

    if (id == "directions") {
      layer.type = layerType.symbol;
      layer.layout["icon-image"] = ["get", "icon"];
      layer.layout["icon-rotate"] = ["get", "rotate"];
      layer["paint"]["icon-color"] = ["get", "color"];
      layer.paint["icon-halo-color"] = ["get", "invertColor"];
      layer.paint["icon-halo-width"] = 1.5;
      layer.layout["icon-size"] = ["get", "size"];

      // This propertie allow to show always all the markers
      layer.layout["icon-allow-overlap"] = this.dragableRouteMarkers;

      // This propertie allow to show always all the markers
      layer.layout["icon-rotation-alignment"] = "map"; //['get', 'alignment']; //could be 'viewport' ; used to rotate the icon with the viewport or the map ...
    }

    return layer;
  }

  MAX_DISTANCE_TO_SPLIT_SEGMENTS = DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS;
  MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT = 300;
  public async addPolylines(points, fly = false, zoom = 15, speed = 1, color = this.primaryColor, width = 4, showCircle = false, cutLongPolylines = false, extraProps: any = null) {
    if (this.routeSource) {
      let poly = this.createFeature(points, name, '', width, '', null, '', { fill: color, border: 0, "border-color": '#000' }, GeometryTypes.LineString, extraProps);

      if (cutLongPolylines){
        const newFeature = this.createFeature([], name, '', width, '', null, '', { fill: color, border: 0, "border-color": '#000' }, GeometryTypes.LineString, extraProps);
        if (!this.isArrayofArraysOrObjects(points)){
          points = points.map(p => {
            const dateunix = p['dateunix'] ?? null;
            return { lng: p[0], lat: p[1], dateunix };
          });
        }

        const features = this.createFeaturesFromDataPoints(newFeature, points);
        this.routeSource.data.features = this.routeSource.data.features.concat(features);
      }
      else
        this.routeSource.data.features.push(poly);

      if (this.map.getSource('route'))
        getSource(this.map, 'route').setData(this.routeSource.data);
    }
  }

  isArrayofArraysOrObjects(obj) {
    if (Array.isArray(obj)) {
      if (obj.length > 0) {
        if (Array.isArray(obj[0])) {
          return false;
        } else if (typeof obj[0] === 'object') {
          return true;
        }
      }
    }
    return false;
  }

  public async addPoint(point, fly = false, zoom = 15, speed = 1, color = this.primaryColor, width = 4) {
    if (this.routeSource) {
      let poly = this.createFeature([point], name, '', width, '', null, '', { fill: color, 'border-color': null, 'border': 0 }, GeometryTypes.LineString);

      this.routeSource.data.features.push(poly);
      getSource(this.map, 'route').setData(this.routeSource.data);

    }

    if (fly)
      await this.flyTo(point[0], point[1], zoom, speed);
  }

  public async addCircle(point, id, color = this.primaryColor, radius = 10, border = 0, borderColor = this.primaryColor, props = {}) {
    const feature = this.createFeature(point, '', id, radius, 'center', null, 'center', { fill: color, border: border, "border-color": borderColor });
    //_ Add custom props feature to use in the layer or when click over the feature
    Object.keys(props).forEach(k => feature.properties[k] = props[k])

    this.circlesSource.data.features.push(feature);
    if (this.map)
      if (this.map.getSource('circles'))
        getSource(this.map, 'circles').setData(this.circlesSource.data);
  }

  public async removeCircles(id = null) {
    this.circlesSource.data.features.forEach(circle => {
      if (id) {
        if (circle.title == id) {
          this.markerSource.slice(this.markerSource.indexOf(circle), 1);
        }
      }
    });
    if (!id) this.circlesSource.data.features = [];
    getSource(this.map, 'circles').setData(this.circlesSource.data);
  }

  public async moveCircle(id, point) {
    this.circlesSource.data.features.forEach(mk => {
      if (mk.properties.title == id) {
        mk.geometry.coordinates = point;
      }
    });
    if (this.map.getSource('circles'))
      getSource(this.map, 'circles').setData(this.circlesSource.data);
  }

  /********************************************************* */
  public async addInfo(name, x, y, img, size, title='', anchor = 'bottom', align = 'left', data = null) {
    if (img.endsWith(".svg"))
      img = await resizeImage(img, 200, 200, "white", false);

    await this.map.loadImage(img, (error, image) => {
      if (error)
        this.addInfo(name, x, y, 'assets/device_markers/v2_car_2.png', size, title, anchor, align, data); //throw error;
      else if (this.markerSource.data.features.map(x => x.properties.icon).indexOf(name) == -1) {
        let marker = this.createFeature([x, y], name, title, size, 'center', data, align);
        marker.properties['anchor'] = anchor;
        marker.properties['text-offset'] = [0, 2];
        if (!this.map.hasImage(name))
          this.map.addImage(name, image);
        this.infoSource.data.features.push(marker);
        if (getSource(this.map, 'infos'))
          getSource(this.map, 'infos').setData(this.infoSource.data);
      }
    });
  }

  public async moveInfo(id, point, angle = null, data = null) {
    this.infoSource.data.features.forEach(mk => {
      if (mk.properties.icon == id) {
        mk.geometry.coordinates = point;
        if (angle) mk.properties.rotate = angle;
        if (data) mk.properties.data = data;
      }
    });
    if (this.map.getSource('infos'))
      getSource(this.map, 'infos').setData(this.infoSource.data);
  }

  public async removeInfo(id = null, all = true) {
    this.infoSource.data.features.forEach(info => {
      if (id) {
        if (info.properties.icon == id) {
          this.map.removeImage(info.properties.icon);
          this.infoSource.slice(this.infoSource.indexOf(info), 1);
        }
      }
      else if (all)
        this.map.removeImage(info.properties.icon);
    });

    if (all) this.infoSource.data.features = []; //Delete all else, only others no 'start' 'end'
  }

  public async updateInfo(name, x, y, value) {
    let nr = [];
    this.infoSource.data.features.forEach(info => {
      if (info.properties.icon == name) {
        info.geometry.coordinates = [x, y];
        info.properties.title = value;
      }
    });
    getSource(this.map, 'infos').setData(this.infoSource.data);
  }

  /*********************************************************** */
  public async moveMarker(id, point, angle = null, data = null) {
    this.markerSource.data.features.forEach(mk => {
      if (mk.properties.icon == id) {
        mk.geometry.coordinates = point;
        if (angle) mk.properties.rotate = angle;
        if (data) mk.properties.data = data;
      }
    });
    if (this.map.getSource('markers'))
      getSource(this.map, 'markers').setData(this.markerSource.data);
  }

  public async moveEffect(id, point, angle = null) {
    this.effectsSource.data.features.forEach(mk => {
      if (mk.properties.icon == id) {
        mk.geometry.coordinates = point;
        if (angle) mk.properties.rotate = angle;
      }
    });
    if (this.map.getSource('effects'))
      getSource(this.map, 'effects').setData(this.effectsSource.data);
  }

  public isSourceItem(dataSource, id) {
    let exist = false;
    dataSource.data.features.forEach(mk => {
      if (mk.properties.icon == id) exist = true;
    });
    return exist;
  }

  public removePolylines(id = null, all = true) {
    this.routeSource.data.features.forEach(r => {
      if (id) {
        if (r.properties.title == id) {
          this.routeSource.slice(this.routeSource.indexOf(r), 1);
        }
      }
    });

    if (all) this.routeSource.data.features = []; //Delete all else, only others no 'start' 'end'

    if (this.map.getSource('route'))
      getSource(this.map, 'route').setData(this.routeSource.data);
  }

  public removePolylinesByFilter(filterFn: (feature: any) => boolean, updateSource = true) {
    this.routeSource.data.features = this.routeSource.data.features.filter(
      feature => !filterFn(feature) // Mantener solo las que no cumplen la condición del filtro
    );

    // Actualizar la fuente del mapa si existe
    if (this.map.getSource('route') && updateSource) {
      (this.map.getSource('route') as any).setData(this.routeSource.data);
    }
  }

  async setLayerProperty(layer, properties = []) {
    properties.forEach(p => {
      try {
        this.map.setPaintProperty(layer, p.name, p.value);
      } catch (err) { };
    });
  }

  public areEquals(startPoint, endPoint) {
    try {
      if (startPoint[0] == endPoint[0])
        if (startPoint[1] == endPoint[1])
          return true;
    } catch (e) { return false; }

    return false;
  }

  public stringToCoordinates(str, precision) {
    let mu = new mapUtil(this.api);
    return mu.stringToCoordinates(str, precision);
  }


  async snapToRoad(points) {
    let data = null;
    return await this.api.getPolySTR(points).then(async (res: any) => {
      if (res.data) {
        try {
          data = this.stringToCoordinates(res.data.trip.legs[0].shape, 6);
          return data;
        }
        catch (err) { //If no return data, then return sent points...
          data = [];
          points.forEach(p => {
            data.push([p.lng, p.lat]);
          });
        }
      }
      return data;
    });
  }

  async traceRoute(points, costing = "auto") {
    let data = [];
    return await this.api.getRoute(points, costing).then(async (res: any) => {
      if (res.data) {
        try {
          data = this.stringToCoordinates(res.data.trip.legs[0].shape, 6);
          return { points: data, response: res.data };
        }
        catch (err) { //If no return data, then return sent points...
          points.forEach(p => {
            data.push([p.lng, p.lat]);
          });
          return { points: data, response: null };
        }
      }
    });
  }

  /***************** EFFECTS LAYER *********************** */
  async addEffectMarker(lat, lng, id, name, inColor, outColor, data = null, effect: any = null, alignment = 'map', layerOptions: any = { 'text-offset': [0, 2] }) {
    let img = !effect ? (new Effects()).Glass(this.map, inColor, outColor, name) : effect;

    if (this.effectsSource.data.features.map(x => x.properties.icon).indexOf(id) == -1) {
      this.map.addImage(id, img, { pixelRatio: 2 });
      let marker = this.createFeature([lng, lat], id, '', 1, 'center', null, alignment);

      Object.keys(layerOptions).forEach(key => marker.properties[key] = layerOptions[key]);
      this.effectsSource.data.features.push(marker);
      if (this.map.getSource('effects'))
        getSource(this.map, 'effects').setData(this.effectsSource.data);
    }
  }

  public async removeEffectMarker(id = null, all = true) {
    let imageName = '';
    this.effectsSource.data.features.forEach(mark => {
      if (id) {
        if (mark.properties.icon == id) {
          imageName = mark.properties.icon;
          this.effectsSource.data.features.slice(this.effectsSource.data.features.indexOf(mark), 1);
        }
      }
      else if (all)
        this.map.removeImage(mark.properties.icon);
    });

    if (all) this.effectsSource.data.features = []; //Delete all else, only others no 'start' 'end'
    else this.effectsSource.data.features.forEach(mark => {
      if (mark.properties.icon != 'start' && mark.properties.icon != 'end')
        this.effectsSource.data.features.slice(this.effectsSource.data.features.indexOf(mark), 1);
    });


    if (this.map.getSource('effects'))
      getSource(this.map, 'effects').setData(this.effectsSource.data);
    if (imageName != '')
      this.map.removeImage(imageName);
  }

  @Input() events = { mouseenter: true, mouseleave: true, click: true, dblclick: true };
  async onClickMarkers() {
    let tc = this;
    if (this.events.mouseenter) {
      this.map.on('mouseenter', 'markers', function () {
        tc.map.getCanvas().style.cursor = 'pointer';
      });

      this.map.on('mouseenter', 'directions', function () {
        tc.map.getCanvas().style.cursor = 'pointer';
      });

      this.map.on('mouseenter', 'info', function () {
        tc.map.getCanvas().style.cursor = 'pointer';
      });
    }

    if (this.events.mouseleave) {
      this.map.on('mouseleave', 'markers', function () {
        tc.map.getCanvas().style.cursor = 'default';
      });

      this.map.on('mouseleave', 'directions', function () {
        tc.map.getCanvas().style.cursor = 'default';
      });

      this.map.on('mouseleave', 'info', function () {
        tc.map.getCanvas().style.cursor = 'default';
      });
    }

    if (this.events.dblclick){
      this.map.on('dblclick', 'markers', function (e) {
        e.preventDefault();
      });

      this.map.on('dblclick', function (e) {
        tc.onDoubleClickMap.emit( { ev: e });
      });
    }

    if (this.events.click)
      this.map.on('click', function (e) {

        let features = tc.map.queryRenderedFeatures(e.point);
        let featureFound = false;
        if (features.length > 0) {

          if (features[0].layer.id == 'markers') { tc.onClickLayer.emit({ e: e, data: features[0] }); featureFound = true; }
          if (features[0].layer.id == 'directions') { tc.onClickLayer.emit({ e: e, data: features[0] }); featureFound = true; }
          if (features[0].layer.id == 'route') { tc.onClickRoute.emit({ e: e, data: features[0] }); featureFound = true; }
          if (features[0].layer.id == 'info') { tc.onClickLayer.emit({ e: e, data: features[0] }); featureFound = true; }
        }
        if (!featureFound || features.length == 0)
          tc.onClickMap.emit({ e: e });
      });
  }

  isPointInsideSquad(point, squad) {
    let x = point[0]; let y = point[1];

    let inside = false;
    for (let i = 0, j = squad.length - 1; i < squad.length; j = i++) {
      let xi = squad[i][0], yi = squad[i][1];
      let xj = squad[j][0], yj = squad[j][1];

      let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect)
        inside = !inside;
    }

    return inside;
  }

  /************************************************************ */

  canvasSource;
  canvasLayer;

  async zoomToBounds(points, options, event) {
    const promise = new Promise((resolve, reject) => {
      this.map.once("moveend", (e) => { resolve(e); });
      let bounds = points.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new this.LngLatBounds(points[0], points[0]));

      this.map.fitBounds(bounds, options, event);
    });

    return promise;
  }

  createCanvasContainer() {
    //this.canvasDrawer = new container('cv-container', 858, 480);
  }

  /********************************************************* */
  /********************* MANAGE STYLES ********************* */
  // Set the style parsing a key of urlStyles Interface
  // osm, paj, pajHills, 3d = style
  setStyle = false;
  async setMapStyle(style, event) {
    if (event) event.stopPropagation();

    this.setStyle = true; // Flag to only update one time with styledata event
    this.mapStyle = style;
    this.map.setStyle(await this.createMapStyle(style));
    if (style == '3d') {
      this.map['dragRotate'].enable();
      this.map['touchPitch'].enable();
      this.map['touchZoomRotate'].enable();
      this.map.easeTo({ pitch: 70, bearing: this.map.getBearing() });
    }
    else {
      this.map['dragRotate'].disable();
      //_ Rearch one method to do this in maplibre
      // this.map['dragRotate']._mousePitch._enabled = true;
      this.map.easeTo({ pitch: 0, bearing: 0 });
    }

  }

  // Use for change style, to generate style with layer and source
  async createMapStyle(mapStyle) {
    let style: any = new Style();
    //_ Only used for raster tiles
    if (mapStyle == "pajLite") {
      style = new Style();
      let layer = new Layer("simple-tiles");

      style.sources["raster-tiles"].tiles.push(MapStylesList[mapStyle].url);

      layer.type = layerType.raster;
      layer.source = "raster-tiles";
      style.layers.push(layer);
    }
    //_ Used for vector tiles
    else {
        const newStyle = MapStylesList[mapStyle.toLowerCase()]?.url;
        style = newStyle ?? MapStylesList.paj.url;
      // }
      //_ If app is in Darkmode, it should exchange layers colors applyDarkStyle
      if ((this.isDarkmode)) {
        //_ Mapbox standard style uses light presets so we can use night to show as dark mode :)
        if (this.mapStyle === 'mapbox_standard') {
          setTimeout(() => this.map.setConfigProperty('basemap', 'lightPreset', 'night'), 300);
        } else if (this.mapStyle === 'mapbox_outdoor_v11') {
          style = Constants.MAP_TILES_MAPBOX_DARK_V10;
        } if (this.mapStyle === 'mapbox_navigation_day_v1') {
          style = Constants.MAP_TILES_MAPBOX_NAVIGATION_NIGHT_V1;
        }else if (!this.mapStyle.startsWith('mapbox')){
          style = await new Promise ((resolve) => {
            this.http.get(style).subscribe((res) => {
              resolve(res)
            });
          });

          style = applyDarkStyle(style, mapStyle);
        }
      }
    }

    console.log('NEW STYLE ', style)
    return style;
  }

  isMapboxStyle (styleName) {
    console.log('STYLE NAME', styleName)
    return styleName?.toLowerCase().startsWith('mapbox');
  }

  highlightDirection(directionId, props: any = {}, prevProps: any = {}, updateMap = true) {
    this.directionsSource.data.features.forEach(f => {
      if (f.properties.directionId == directionId) {
        Object.keys(props).forEach(key => {
          f.properties[key] = props[key];
        });
      } else {
        Object.keys(prevProps).forEach(key => {
          f.properties[key] = prevProps[key];
        });
      }
    });

    if (updateMap)
      this.updateDataSources({ directions: true, markersAndRoutes: false, circles: false, effects: false });
  }

  createFeaturesFromDataPoints(
    initialFeature,
    dataPoints,
    isSnapPoints = false
  ) {
    const featureData = cloneObject(initialFeature);
    let lastLocation: any = null;
    let secondLastLocation: any = null;
    let lastFeature: any = null;
    let newFeatures: any = [];

    dataPoints.forEach((p, index) => {
      // console.log('POINT ID', p.id);
      if (p.wifi == null || p.wifi == undefined) {
        //_ No take the wifi points for the polylines{
        //_ First get lastLocation from lastFeature
        if (lastFeature)
        {
          lastLocation = lastFeature.geometry.coordinates
          [
            lastFeature.geometry.coordinates.length - 1
          ];
          if(lastFeature.geometry.coordinates.length > 1){
            secondLastLocation = lastFeature.geometry.coordinates
            [
              lastFeature.geometry.coordinates.length - 2
            ];
          }
        }

        if (lastLocation) {
          let distanceLastLocToNewLoc = Math.abs(
            Tdistance(lastLocation, [p.lng, p.lat], { units: "meters" })
          );

          let distanceSecondLastLocToNewLoc  = 0;
          if(secondLastLocation){
            distanceSecondLastLocToNewLoc = Math.abs(
              Tdistance(secondLastLocation, [p.lng, p.lat], { units: "meters" })
            );
          }

          //_ Then check if last feature coordinate and new coordinate is longer than X meters
          //_ And split in new feature to cut the line into two or more segments
          //_ Also check the time diff in seconds for everypoint
          //************** CHECK TIME DIFF -> but need to fix for valhalla points  */
          let diffSeconds = 30;
          if (lastFeature.properties["lastPointData"] && p.dateunix) {
            const startDate = moment.unix(
              lastFeature.properties["lastPointData"].dateunix
            );
            const endDate = moment.unix(p.dateunix);
            diffSeconds = Math.abs(startDate.diff(endDate, "seconds"));
          }

          let MAX_DISTANCE_PER_SECONDS = Math.abs(
            (this.MAX_DISTANCE_TO_SPLIT_SEGMENTS / 30) * diffSeconds
          );

          //_ Debug distances and time diff
          // if (distanceLastLocToNewLoc > 1000){
          //   console.log('DATA', { distanceLastLocToNewLoc, MAX_DISTANCE_TO_SPLIT_SEGMENTS: this.MAX_DISTANCE_TO_SPLIT_SEGMENTS, diffSeconds, points: { lastLocation, p }});
          // }

          //_ For snap points max lengh not can be calculated by the dateunix so use an small max distance to break the line
          if (isSnapPoints) MAX_DISTANCE_PER_SECONDS = this.MAX_DISTANCE_TO_SPLIT_SEGMENTS * 2.5;

          p['last_loc_distance'] = distanceLastLocToNewLoc;
          p['second_last_loc_distance'] = distanceSecondLastLocToNewLoc;
          //_ Added condition to check max diff in seconds from previous point and distance driven in that time
          //_ Conditionnally to distance < MAX_DISTANCE_PER_SECONDS and diffSeconds < MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT
          if (
              (distanceLastLocToNewLoc < MAX_DISTANCE_PER_SECONDS
              && diffSeconds < this.MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT )
              // || isSnapPoints
          ) {
            //_ Previous check
            if(p['second_last_loc_distance'] > 0 && p['last_loc_distance'] > p['second_last_loc_distance']){
              // Removed point if the distance from last location is greater than second last location
              // Logically the last point is close to the current point than the second last point
              lastFeature.geometry.coordinates.pop();
            }
            lastFeature.geometry.coordinates.push([p.lng, p.lat]);
            lastFeature.properties["lastPointData"] = p; //isSnapPoints ? p : null;
          }
          //_ push new lastFeature and create new feature for next segment
          else {
            lastFeature.properties["lastPointData"] = p; //isSnapPoints ? p : null;
            newFeatures.push(cloneObject(lastFeature));
            const newSegment = cloneObject(featureData);
            newSegment.geometry.coordinates.push([p.lng, p.lat]);
            lastFeature = cloneObject(newSegment);
          }
        } else {
          initialFeature.geometry.coordinates.push([p.lng, p.lat]);
          initialFeature.properties["lastPointData"] = p; //isSnapPoints ? p : null;
          lastFeature = cloneObject(initialFeature);
        }
      }

      //_ when finish the looop add lastFeature in case is not added and has some coordinates
      if (
        index == dataPoints.length - 1 &&
        lastFeature.geometry.coordinates.length > 0
      )
        newFeatures.push(lastFeature);
    });

    return newFeatures;
  }

  updateDataSources(which = { directions: true, markersAndRoutes: true, effects: true, circles: true }) {
    if (which.directions && this.map.getSource("directions"))
      getSource(this.map, "directions").setData(this.directionsSource.data);

    if (which.effects && this.map.getSource('effects'))
      getSource(this.map, "effects").setData(this.effectsSource.data);
    if (which.effects && this.map.getSource('circles'))
      getSource(this.map, "circles").setData(this.circlesSource.data);

    if (which.markersAndRoutes) {
      if (this.map.getSource('markers'))
        getSource(this.map, "markers").setData(this.markerSource.data);
      if (this.map.getSource("route"))
        getSource(this.map, "route").setData(this.routeSource.data);
    }
  }

  clearAllData() {
    if (this.map) {
      this.directionsSource = this.createDataSource("directions");
      this.routeSource = this.createDataSource("route");
      // this.alarmsSource = this.createDataSource("alarmsSource");
      this.markerSource = this.createDataSource("markers");
      this.circlesSource = this.createDataSource('circles');

      try {
        getSource(this.map, "markers").setData(this.markerSource.data);
        getSource(this.map, "directions").setData(this.directionsSource.data);
        getSource(this.map, "circles").setData(this.circlesSource.data);
        getSource(this.map, "route").setData(this.routeSource.data);

      } catch {}
    }
  }

  //_ Reload a layer
  reloadLayer (layerName) {
    const layer = this[layerName];
    if (!this.map || !layer) {
      throw('Layer or map doesnt exist.');
      return;
    }

    if (this.map.getLayer(layer.id))
      this.map.removeLayer(layer.id);
    if (!this.map.getLayer(layer.id))
      this.map.addLayer(layer);
  }

  //_ Apply atmosphere to the map style
  //_ Using MapStyleList and if is projection globe or dimension 3d
  applyAtmosphere() {
    if (!this.useMapbox) return;

    if (this.userSettings.map_projection === 'globe') {
      let atmosphere = MapStylesList[this.mapStyle]?.atmosphere ?? MapStylesList.paj.atmosphere;

      if (this.isDarkmode) atmosphere = DARK_ATMOSPHERE;
      this.map?.setFog(atmosphere);
    } else {
      //_ Fix for background color of mapbox_standard style
      if (this.mapStyle === 'mapbox_standard') {
        let atmosphere = MapStylesList[this.mapStyle]?.atmosphere ?? MapStylesList.paj.atmosphere;
        atmosphere.color = MapStylesList[this.mapStyle].backround;
        this.map?.setFog(atmosphere);
      }
    }
  }

  shouldUseMapboxLib (mapStyle: string = null): { useMapbox: boolean, reload: boolean } {
    const newStyle = MapStylesList[(mapStyle).toLowerCase()]?.url;
    const style = newStyle ?? MapStylesList.paj.url;
    const useMapboxLib = style.startsWith('mapbox');
    let reload = false;

    if ((useMapboxLib && !this.useMapbox) || (!useMapboxLib && this.useMapbox)) {
      reload = true;
    }

    return { useMapbox: useMapboxLib, reload };
  }

  //_ Remove the mapbox listeners and all
  //_ Free resources
  ngOnDestroy() {
    if (this.map)
      this.map.remove();
  }
}

