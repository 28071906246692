import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceDataService } from '../../devicesData.service';
import { DeviceNavigationService } from 'src/app/services/device-navigation-service.service';
import { DevicesSidebarService } from '../../devices-sidebar/devices-sidebar.service';
import { DeviceSubmenuService } from '../../devices-sidebar/device-item/device-submenu/device-submenu.service';
import { MenuController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import { translationsKeys } from 'src/app/members/notifications/notificationsUtil';

@Component({
  selector: 'app-map-selected-features-popup',
  templateUrl: './map-selected-features-popup.component.html',
  styleUrls: ['./map-selected-features-popup.component.scss']
})
export class MapSelectedFeaturesPopupComponent implements OnInit{
  @Input() features = [];
  @Input() map: any;
  @Output('onSelectMarker') onSelectMarker = new EventEmitter();

  device = null;
  AlarmTranslationsKeys = translationsKeys;
  constructor (private devicesService: DeviceDataService, private deviceNavigation:DeviceNavigationService,
    private devicesSidebarService: DevicesSidebarService, private devicesubmenu: DeviceSubmenuService,
    private menu: MenuController, private appService: AppService) {

  }

  ngOnInit(): void {
    this.device = this.devicesService.devices[this.features[0].properties["deviceId"]];
    //_ Map features to find the device, alarm and pause object
    //_ from the stored values in devicesService.devices[idDevice]
    console.log('features', this.features)
    this.features = this.features.map(f => {

      const device = this.devicesService.devices[f.properties.deviceId];

      return { ...f,
              direction: device.dataPoints.find(p => p.id === f.properties.directionId),
              device: device?.properties,
              alarm: device?.notifications.find((n) => n.id == f.properties.alarmId??null),
              pause: device?.pauseMarkers.find((p) => p.pause_display.id == f.properties.pauseId),
              geometry: f.geometry,
              wifiHome: device?.wifiMarkers.find((w) => w.wifiIndex === f.properties.wifiIndex)
            }
    });


    //_ debug features with extra data added in map method
    console.log('FEATURES IN POPUP', this.features);
  }

  //_ UNCOMMENT ONLY
  //_ IN CASE WE NEED TO OPEN THE DEVICE ITEM IN RIGHT SIDEBAR
  // async deviceSettings(item, ev) {
  //   const isDifferentDevice =
  //     this.devicesSidebarService.selectedDevice.value != item.id;
  //   this.devicesubmenu.selectSubmenuItem("settings");
  //   console.log('isDifferentDevice',isDifferentDevice)
  // if (
  //   isDifferentDevice ||
  //   this.devicesSidebarService.activePage == "APP-DEVICES-LIST-HOME"
  // ) {
  //   this.devicesSidebarService.selectedDevice.next(null);
  //   await this.openDeviceMenu()
  //       this.devicesSidebarService.selectedDevice.next(item.id);
  // }
  // else {
  //   await this.openDeviceMenu()
  // }

  // // Navigate to device view
  // if (isDifferentDevice)
  //   this.deviceNavigation.navigateToDevice(item.id)
  // }

  // async openDeviceMenu() {
  //   if (this.map.isSmallScreen) {
  //     // Pullup Device Menu
  //     this.map.showPullup = true; //Open device pullup menu
  //   } else {
  //     // Desktop Device Menu
  //     this.map.showPullup = false;
  //     await this.menu.enable(true, 'deviceMenu');
  //     await this.menu.open("deviceMenu");
  //   }
  //   this.appService.deviceMenuisOpen.next(true);
  // }

  //_ Trigger selectMarker | feature item
  selectFeature (feature) {
    this.onSelectMarker.emit(feature)
  }

  getAlertTitle(meldungtyp){
    return this.AlarmTranslationsKeys.find(obj => obj.id === meldungtyp)?.title;
  }
}


