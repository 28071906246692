<ion-header class="ion-no-border draggable-header-filters" #header>
  <!-- <div class="dragable-div" *ngIf="isPullup"></div> -->
  <div class="ion-hide-md-up">
    <!-- <ion-buttons style="justify-content: center; height: 18px">
      <ion-button
        class="toggle-btn"
        fill="clear"
        color="light"
        (click)="enablePullup()"
      >
        <ion-icon
          
          [class.rotate]="pullupBreakpoint == 'top'"
          name="chevron-up-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </div>
  <ion-toolbar color="dark" class="no-padding">
    
    <!-- <ion-nav-link router-direction="back" slot="start"> -->
      <ion-buttons slot="start" (click)="backFilters()">
        <ion-back-button></ion-back-button>
      </ion-buttons>
    <!-- </ion-nav-link> -->
    <ion-title>{{ 'devicesSidebar.filters.header' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content #content class="ion-padding" [scrollEvents]="true" (ionScroll)="ScrollingContent($event)" [class.no-scroll]="!enableScroll" [HideHeader]="header" toolbarHeight="62" [hideToolbar]="null" [class.no-scroll]="!enableScroll" (topReached)="topReached($event)" [isEnabled]="false" [hasDragableHeader]="true">
  <div class="fx-layout column gap15" >
    <div class="fx-layout column gap10">
      <ion-item lines="none" class="ion-no-padding title" color="transparent">
        <ion-icon name="toggle-outline" slot="start"></ion-icon>
        <ion-label>{{ 'devicesSidebar.filters.showinMap' | translate }}</ion-label>
      </ion-item>
      
      <app-select-list [selectTemplete]="select" [data]="DeviceStatus"
              [itemTemplate]="itemView" (onSelect)="setStatus($event)" [selectedItem]="selectedStatus">
        <ng-template #select let-selectedItem="selectedItem">
          <ion-button shape="round" class="fx-layout flex-100"  style="margin: 0 2px" color="light">
            <div class="fx-layout row flex-100 space-between">
              <ion-label>{{ ('devicesSidebar.filters.' + selectedItem?.name) | translate }}</ion-label>
              <ion-icon name="chevron-down-outline"></ion-icon>
            </div>
          </ion-button>
        </ng-template>

        <ng-template #itemView let-item="item" let-index="index">
          <ion-item button detail="false">
            <ion-icon slot="start"  [color]="'primary'" name="radio-button-on-outline" *ngIf="selectedStatus.id == item.id"></ion-icon>
            <ion-icon name="ellipse-outline" slot="start" *ngIf="selectedStatus.id != item.id"></ion-icon>
            <ion-label class="ion-text-wrap">{{ ('devicesSidebar.filters.' + item.name) | translate }}</ion-label>
          </ion-item>
        </ng-template>
      </app-select-list>
    </div>

    <div class="fx-layout column gap10" *ngIf="selectedGroup" color="light">
      <ion-item lines="none" class="ion-no-padding title" color="transparent">
        <ion-icon src="assets/images/devices-list/filters/group.svg" slot="start"></ion-icon>
        <ion-label>{{ 'devicesSidebar.filters.deviceGroup' | translate }}</ion-label>
      </ion-item>
      <app-select-list [selectTemplete]="select" [data]="groups"
              [itemTemplate]="itemView" (onSelect)="setGroup($event)" [selectedItem]="selectedGroup">
        <ng-template #select let-selectedItem="selectedItem">
          <ion-button shape="round" class="fx-layout flex-100" style="margin: 0 2px" color="light">
            <div class="fx-layout row flex-100 space-between">
              <ion-label *ngIf="!selectedItem.key">{{ selectedItem.name}}</ion-label>
              <ion-label *ngIf="selectedItem.key">{{ selectedItem.key | translate }}</ion-label>
              <div class="fx-layout row gap15">
                <ion-badge color="light" *ngIf="selectedItem?.devices">{{ selectedItem?.devices.length }}</ion-badge>
                <ion-icon name="chevron-down-outline" slot="end"></ion-icon>
              </div>
            </div>
          </ion-button>
        </ng-template>

        <ng-template #itemView let-item="item" let-index="index">
          <ion-item button detail="false" class="select-item-view">
            <ion-icon slot="start" [color]="'primary'" name="radio-button-on-outline" *ngIf="selectedGroup?.devices_groups_group_id == item.devices_groups_group_id"></ion-icon>
            <ion-icon name="ellipse-outline" slot="start" *ngIf="selectedGroup?.devices_groups_group_id != item.devices_groups_group_id"></ion-icon>
            <ion-label *ngIf="!item.key" class="ion-text-wrap">{{ item.name }}</ion-label>
            <ion-label *ngIf="item.key" class="ion-text-wrap">{{ item.key | translate  }}</ion-label>
            <ion-badge slot="end" color="light" *ngIf="item.devices">{{ item.devices.length }}</ion-badge>
          </ion-item>
        </ng-template>
      </app-select-list>
    </div>

    <div class="fx-layout column gap10" *ngIf="selectedBatteryLevel" >
      <ion-item lines="none" class="ion-no-padding title" color="transparent">
        <ion-icon name="battery-full" slot="start"></ion-icon>
        <ion-label>{{ 'devicesSidebar.filters.batteryLevel' | translate }}</ion-label>
      </ion-item>
      <app-select-list [selectTemplete]="select" [data]="deviceBatteryLevelFilters"
                       [itemTemplate]="itemView" (onSelect)="setBatteryLevel($event)" [selectedItem]="selectedBatteryLevel">
        <ng-template #select let-selectedItem="selectedItem">
          <ion-button shape="round" class="fx-layout flex-100"  style="margin: 0 2px" color="light">
            <div class="fx-layout row flex-100 space-between">
              <ion-label *ngIf="selectedItem.operation">
                {{ ('devicesSidebar.filters.battery.' + selectedItem?.operation) | translate }} {{ selectedItem?.name}}
              </ion-label>
              <ion-label *ngIf="!selectedItem.operation">
                {{ selectedItem?.name | translate }}
              </ion-label>
              <ion-icon name="chevron-down-outline"></ion-icon>
            </div>
          </ion-button>
        </ng-template>

        <ng-template #itemView let-item="item" let-index="index">
          <ion-item button detail="false" class="select-item-view">
            <ion-icon slot="start"  [color]="'primary'" name="radio-button-on-outline" *ngIf="selectedBatteryLevel.id == item.id"></ion-icon>
            <ion-icon name="ellipse-outline" slot="start" *ngIf="selectedBatteryLevel.id != item.id"></ion-icon>
            <ion-label *ngIf="item.operation" class="ion-text-wrap">
              {{ ('devicesSidebar.filters.battery.' + item.operation) | translate }} {{ item.name}}
            </ion-label>
            <ion-label *ngIf="!item.operation" class="ion-text-wrap">
              {{ item.name | translate }}
            </ion-label>
          </ion-item>
        </ng-template>
      </app-select-list>
    </div>

    <div class="fx-layout column gap10">
      <ion-item lines="none" class="ion-no-padding title" color="transparent">
        <ion-icon src="assets/images/devices-list/filters/text.svg" slot="start"></ion-icon>
        <ion-label>{{ 'devicesSidebar.filters.sortByName' | translate }}</ion-label>
      </ion-item>
      <ion-segment mode="ios" class="segment-round" [(ngModel)]="filters.sortName" (ngModelChange)="setSortName($event)">
        <ion-segment-button value="no">{{ 'devicesSidebar.filters.sortNo' | translate }}</ion-segment-button>
        <ion-segment-button value="asc">{{ 'devicesSidebar.filters.sortNameAsc' | translate }}</ion-segment-button>
        <ion-segment-button value="desc">{{ 'devicesSidebar.filters.sortNameDesc' | translate }}</ion-segment-button>
      </ion-segment>
    </div>

    <div class="fx-layout column gap10">
      <ion-item lines="none" class="ion-no-padding title" color="transparent">
        <ion-icon src="assets/images/devices-list/filters/imei.svg" slot="start"></ion-icon>
        <ion-label>{{ 'devicesSidebar.filters.sortByImei' | translate }}</ion-label>
      </ion-item>
      <ion-segment mode="ios" class="segment-round" [(ngModel)]="filters.sortImei" (ngModelChange)="setSortImei($event)">
        <ion-segment-button value="no">{{ 'devicesSidebar.filters.sortNo' | translate }}</ion-segment-button>
        <ion-segment-button value="asc">{{ 'devicesSidebar.filters.sortNameAsc' | translate }}</ion-segment-button>
        <ion-segment-button value="desc">{{ 'devicesSidebar.filters.sortNameDesc' | translate }}</ion-segment-button>
      </ion-segment>
    </div>
  </div>

  <div class="ion-text-center" style="margin-top: 25px" *ngIf="appliedFilters > 0" @fadeIn>
    <ion-button color="light" size="small" shape="round" (click)="resetFilters()">
      <ion-icon name="refresh-outline" slot="start"></ion-icon>
      <ion-label>{{ 'devicesSidebar.filters.resetFilters' | translate }}</ion-label>
    </ion-button>
  </div>
</ion-content>
