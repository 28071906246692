import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { DefaultMatCalendarRangeStrategy, MatDatepickerModule, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BackcloseDirectiveModule } from 'src/app/directives/backclose-directive.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CustomDateAdapter } from './CustomDateAdapter';
import { DateRangeComponent } from './date-range.component';
import { SelectDateComponent } from './select-date/select-date.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { CustomTimePicker } from './custom-time-picker/time-picker.component';
import { ScrollOnDragDirective } from './time-picker-scrolldrag.directive';
import { DatetimeDragDropDirective } from "./datetime-drag-drop.directive";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translations/", ".json");
}

@NgModule({
    imports: [
      CommonModule,
      IonicModule,
      FormsModule,
      ReactiveFormsModule,
      MatDatepickerModule,
      MatNativeDateModule,
      BackcloseDirectiveModule,
      MatInputModule,
      DirectivesModule,
      MatMomentDateModule,
      ScrollOnDragDirective,
      PipesModule.forRoot(),
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
    ],

    declarations: [DateRangeComponent, SelectDateComponent, TimePickerComponent, CustomTimePicker, DatetimeDragDropDirective],
    exports: [DateRangeComponent, SelectDateComponent, PipesModule, MatDatepickerModule, MatNativeDateModule, MatInputModule ],
    providers: [
      {
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy,
      },
      { provide: DateAdapter, useClass: CustomDateAdapter },
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
      MatDatepickerModule
    ],
  })


  export class PajDaterangeModule {}
