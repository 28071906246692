<div class="notifiBanner" #div style="opacity: 0; top: -500px">
  <ion-item>
    <ion-avatar class="center-content">
      <ion-icon class="flaticon-notification-bell" color="primary"> </ion-icon>
    </ion-avatar>

    <ion-label>
      <h3>{{ notification?.title }}</h3>
      <p class="t2lines">{{ notification?.body }}</p>
    </ion-label>
  </ion-item>

  <ion-footer>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-button
          shape="round"
          expand="full"
          fill="clear"
          size="small"
          color="light"
          (click)="close()"
        >
          <ion-label>{{ "notifications.close" | translate }} </ion-label>
          <app-timer-count
            #timer
            [showHour]="false"
            [showMin]="false"
            slot="end"
          >
          </app-timer-count>
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-padding">
        <ion-button
          shape="round"
          color="primary"
          fill="clear"
          expand="full"
          size="small"
          (click)="openDetails()"
        >
          <ion-icon name="eye" slot="start"></ion-icon>
          <ion-label>{{ "notifications.see" | translate }}</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-footer>
</div>
