import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Constants } from 'src/app/constants.enum';
import { StorageService as Storage } from './../../../services/storage.service';
import { extractHtmlLinks } from "../util";

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
  styleUrls: ['./custom-alert.component.scss'],
})
export class CustomAlertComponent implements OnInit {
  alert = null;
  language = 'en';
  messageKey = 'message_en';
  constructor(private storage: Storage, private appService: AppService) { }

  async ngOnInit() {

    this.storage.storageReady.subscribe( async r => {
      this.language = await this.storage.get(Constants.LANGUAGE);
      this.messageKey = this.language != 'en' && this.language != null ? 'message_' + this.language : 'message';

      // this.appService.user$.subscribe( u => {
      //   setTimeout( () => this.alert = SpecialAlert , 1500);
      // });
    });

  }

  close(alert: any) {
    alert['show'] = false;
  }

  continue(alert) {
    const links  = extractHtmlLinks(alert.message);
    this.close(alert);
    links.forEach((link) => window.open(link, "_blanc"))
  }
}
