import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-purchasemodal',
  templateUrl: './purchasemodal.page.html',
  styleUrls: ['./purchasemodal.page.scss'],
})
export class PurchasemodalPage implements OnInit {
  availableDevices: Array<any> = [
    { id: 1, name: 'Vehicle Finder 4G 2.0' },
    { id: 2, name: 'Vehicle Finder 4G 1.0' },
    { id: 3, name: 'Car OBD Finder 4G' },
    { id: 4, name: 'Car OBD Finder 4G 2.0' }
  ]
  logbookSupportedDevices = null;
  leftItems = null;
  rightItems = null;
  loading = true;
  constructor(private modalController: ModalController, private translate: TranslateService,
    private authService: AuthenticationService) { }

  ngOnInit() {
    this.getlogbookSupportedCars();
  }

  async getlogbookSupportedCars() {
    await this.authService.getAlllogbookSupportedDevices().then(async r => {
      if(r.number_of_records > 0){
        this.logbookSupportedDevices = r.success;
      }else {
        this.logbookSupportedDevices = 'No Device Found';
      }
      const middleIndex = Math.ceil(this.logbookSupportedDevices.length / 2);
      this.leftItems = this.logbookSupportedDevices.slice(0, middleIndex);
      this.rightItems = this.logbookSupportedDevices.slice(middleIndex);
      this.loading = false;
    }).catch(error => { console.log("ERROR: ", error);});
    this.loading = false;

  }


  async onCancel() {
    await this.modalController.dismiss({ data: 'cancel' })
  }
  async onUpgrade() {
    await this.modalController.dismiss({ data: 'open' })


  }

}
