import { Component, Input } from '@angular/core';
import { PopoverController } from "@ionic/angular";
import { AlertService } from "../alert.service";

@Component({
  selector: 'app-alert-info-popup',
  templateUrl: './alert-info-popup.component.html',
  styleUrls: ['./alert-info-popup.component.scss']
})
export class AlertInfoPopupComponent {
  @Input() infoText: string = '';
  @Input() alertId: string = '';


  constructor(private popoverCtrl: PopoverController,
              private alertService: AlertService) { }

  ngOnInit() {}

  accept(dontRemind: boolean ) {
    if (dontRemind) {
      switch (this.alertId) {
        case "alarmbewegung":
          this.alertService.DONT_REMIND_AGAIN_SHOCK_ALERT_POPUP.set(true);
          break;
        case "alarmsos":
          this.alertService.DONT_REMIND_AGAIN_REMOVAL_ALERT_POPUP.set(true);
          break;
        default: break;
      }
    }
    this.popoverCtrl.dismiss();
  }
}
