  <ion-button *ngIf="this.style == 'android'" id="{{this.uniqueId}}" class="trigger-button" fill="solid"
  color="light" (click)="setOpenModal(true)">
    <ion-icon slot="start" src="assets/images/map/popup/clock.svg" color="primary" role="img" class="md ion-color ion-color-primary hydrated"></ion-icon>

    <label>{{ this.hour }}</label>
    &nbsp;:&nbsp;
    <label *ngIf="!this.minuteDigits">{{ this.minute }}</label>
    <label *ngIf="this.minuteDigits">{{ this.minuteFirstDigit }}{{this.minuteSecondDigit}}</label>
  </ion-button>

  <!--  ios style button -->
  <ion-button *ngIf="this.style == 'ios'" id="{{this.uniqueId}}" class="trigger-button" fill="solid"
  color="light" (click)="setOpenModal(true)">
    <ion-icon slot="start" src="assets/images/map/popup/clock.svg" color="primary" role="img" class="md ion-color ion-color-primary hydrated"></ion-icon>
    <label>{{ this.time }}</label>
  </ion-button>
  <!--  ios style button end -->

  <ion-modal
    #modal [isOpen]="isModalOpen" (willPresent)="onTimePickerPresent()"
    [canDismiss]="canDismiss" (didDismiss)="setOpenModal(false)">
    <ng-template>
      <ion-header>
        <ion-toolbar class="custom-time-picker-header">
          <ion-title>{{ "dashboard.stadistics.timePicker" | translate }}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="modal.dismiss()">
              <ion-icon name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <div
          style="display: flex; flex-direction: column; justify-content: center; align-items: center;gap: 12px; padding-top: 15px"
        >
          <!--
          <ion-label position="stacked">{{ "timePicker.style.text"| translate }}:</ion-label>
          <ion-radio-group  [value]="style" class="radio-group" >
            <ion-radio (click)="toggleStyle('ios')" value="ios">
              <ion-label class="choose-style">
                <ion-icon  color="primary" src="assets/images/time-picker.svg"></ion-icon>
                <p>{{ "timePicker.style.wheel"| translate }}</p>
              </ion-label>
            </ion-radio>
            <ion-radio style="padding-bottom: 0.093rem;" (click)="toggleStyle('android')" value="android">
              <ion-label class="choose-style">
                <ion-icon  id="clock" color="primary" src="assets/images/map/popup/clock.svg"></ion-icon>
                <p>{{ "timePicker.style.clock"| translate }}</p>
              </ion-label>
            </ion-radio>
          </ion-radio-group>
          !-->

          <!-- <ng-container [ngTemplateOutlet]="style == 'android' ? androidStyle : iosStyle">
          </ng-container> !-->
          <ng-container [ngTemplateOutlet]="iosStyle">
          </ng-container>
        </div>
      </ion-content>
      <ion-footer class="ion-padding">
        <div class="ion-text-center footer-section">
          <ion-button
            class="ion-color ion-color-light md button button-round button-solid ion-activatable ion-focusable hydrated"
            (click)="modal.dismiss()"
            fill="solid"
            color="light"
          >
            <ion-label>{{ "dashboard.cancel" | translate }} </ion-label>
          </ion-button>
          <ion-button
            class="md button button-round button-solid ion-activatable ion-focusable hydrated"
            (click)="this.onTimeAccept();modal.dismiss()"
            fill="solid"
          >
            <ion-label>{{ "dashboard.accept" | translate }}</ion-label>
          </ion-button>
        </div>
      </ion-footer>
    </ng-template>
  </ion-modal>

  <ng-template #iosStyle>
    <div class="ios-picker">
      <ion-buttons style="display: flex;gap: 18px;">
        <ion-button (click)="onTimeUpdateForStep(false, false)">
          <ion-icon name="chevron-up-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="onTimeUpdateForStep(true, false)">
          <ion-icon name="chevron-up-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-datetime
        [ngStyle]="{'width.px': isSmallScreen ? 350 : 250}"
        presentation="time"
        mode="ios"
        [(ngModel)]="time"
        (ionChange)="onTimeUpdate($event)"
        [locale]="locale"
        hourCycle="h23"
        [preferWheel]="true"
        appDatetimeDragDrop
        [sensitivity]="15"
      >
      </ion-datetime>
      <ion-buttons style="display: flex;gap: 18px;">
        <ion-button (click)="onTimeUpdateForStep(false, true)">
          <ion-icon name="chevron-down-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="onTimeUpdateForStep(true, true)">
          <ion-icon name="chevron-down-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-icon style="cursor: pointer;"  src="assets/images/keyboard.svg" (click)="presentModalSetTimeFromModal()" color="primary" size="large"></ion-icon>
    </div>
  </ng-template>
  <ng-template #androidStyle let-timeContainer>
    <div class="android-template">
      <section style="display: flex; flex-direction: column; justify-content: center; align-items: center;gap: 12px;">
        <div class="label-section">
          <label>{{ this.labelTitle }}</label>
          <div class="label-subsection">
            <ion-icon slot="start" src="assets/images/map/popup/clock.svg" color="primary" role="img" class="md ion-color ion-color-primary hydrated" size="large"></ion-icon>
            <div>
              <label [ngClass]="{'selected-mode' : this.view == 'hour'}" (click)="changeView('hour')">{{ this.hour }}</label>
              &nbsp;:&nbsp;
              <label *ngIf="!this.minuteDigits" [ngClass]="{'selected-mode' : this.view == 'minute'}" (click)="changeView('minute')">{{ this.minute }}</label>
              <label *ngIf="this.minuteDigits" [ngClass]="{'selected-mode' : this.view == 'minute'}" (click)="changeView('minute')">{{ this.minuteFirstDigit }}{{this.minuteSecondDigit}}</label>
            </div>
            <div style="visibility: hidden;"></div>
          </div>
        </div>
        <div class="container" #timeContainer>
          <span class="center-point"></span>
        </div>
      </section>
      <ion-icon style="cursor: pointer;" class="ion-color ion-color-primary" src="assets/images/keyboard.svg" (click)="presentModalSetTimeFromModal()" color="primary" size="large"></ion-icon>
    </div>
  </ng-template>

  <ion-modal
    #modalSetTime id="set-time"
    style=" --border-radius: 10px; --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"
  >
    <ng-template>
      <ion-header class="no-safe-area">
        <ion-toolbar class="custom-time-picker-header">
          <ion-title>{{ "timePicker.setTime"| translate}}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="modalSetTime.dismiss()">
              <ion-icon name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class="modal-content">
          <div class="time-inputs">
            <div class="input-container">
              <input #hours type="number" id="hours" [value]="hour" min="0" max="23"  (input)="formatInput(hours)">
              <label for="hours">{{ 'dateTime.hours' | translate }}</label>
            </div>
            <span>:</span>
            <div class="input-container">
              <input #minutes type="number" id="minutes" [value]="minute" min="0" max="59"  (input)="formatInput(minutes)">
              <label for="minutes">{{ 'dateTime.minutes' | translate }}</label>
            </div>
          </div>
        </div>
      </ion-content>
      <ion-footer style="box-shadow: none;" class="ion-no-padding">
        <ion-toolbar>
          <ion-icon
            class="pointer"
            slot="start"
            name="time-outline" id="present-set-time" (click)="presentModalFromSetTime()"
            size="large" color="primary">
          </ion-icon>
          <ion-buttons slot="end">
            <ion-button
              (click)="modalSetTime.dismiss()"
              fill="solid"
              color="light"
              shape="round"
              class="button button-round button-solid"
            >
              <ion-label>{{ "dashboard.cancel" | translate }} </ion-label>
            </ion-button>
            <ion-button
              (click)="acceptTimeSet(hours, minutes)"
              color="primary"
              fill="solid"
              shape="round"
              class="button button-round button-solid"
            >
              <ion-label>{{ "dashboard.accept" | translate }}</ion-label>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>

      </ion-footer>
    </ng-template>
  </ion-modal>
