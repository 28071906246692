<div class="tour-container" [class.hide]="!isVisible">
  <div class="backdrop"></div>
  <div class="hole" #hole [hidden]="step?.isStart"></div>

  <div class="content" #tourcard>
    <!-- <ion-button color="dark" shape="round" fill="clear" class="close-button" (click)="end()"> -->
    <ion-button
      color="primary"
      shape="round"
      fill="clear"
      class="close-button"
      (click)="end('close')"
    >
      <ion-icon name="close-circle-outline"></ion-icon>
    </ion-button>

    <ion-card-header class="ion-no-padding" *ngIf="step?.image || step?.mobileImage">
      <ion-card-header class="ion-no-padding">
        <img *ngIf="!isSmallScreen || !step?.mobileImage" [ngStyle]="step?.imageStyle || {}"  src="{{ step?.image }}" />
        <img class="mobile-image" *ngIf="isSmallScreen && step?.mobileImage" [ngStyle]="step?.imageStyle || {}"  src="{{ step?.mobileImage }}" />
      </ion-card-header>
    </ion-card-header>

    <ion-card-content class="ion-text-center">
      <!-- <h1>{{ step?.title  | translate}}</h1> -->
      <h1
        [innerHTML]="step?.title | translate | sanitizeHtml"
        style="margin-right: 10px; margin-bottom: 10px"
      ></h1>
      <h3 [innerHTML]="step?.content | translate | sanitizeHtml"></h3>
    </ion-card-content>

    <ion-footer>
      <ion-button
        shape="round"
        color="light"
        fill="clear"
        slot="start"
        (click)="back()"
        *ngIf="stepIndex > 0"
      >
        <ion-label>{{ "logBook.back" | translate }}</ion-label>
      </ion-button>

      <ion-button
        color="primary"
        shape="round"
        slot="end"
        fill="solid"
        (click)="next()"
        *ngIf="stepIndex < maxSteps"
      >
        <ion-label *ngIf="!step?.isStart">{{
          "deviceLogMap.next" | translate
        }}</ion-label>
        <ion-label *ngIf="stepIndex == 0 && step?.isStart">{{
          "mapTour.start" | translate
        }}</ion-label>
      </ion-button>
      <ion-button
        color="primary"
        shape="round"
        slot="end"
        fill="solid"
        (click)="end('end')"
        *ngIf="maxSteps == stepIndex"
        >{{ tourOptions.endText | translate }}</ion-button
      >
    </ion-footer>
  </div>
</div>
