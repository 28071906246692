import { Injectable } from "@angular/core";
import { environment as ENV } from 'src/environments/environment';
import { StorageService as Storage } from "src/app/services/storage.service";
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpEventType, } from "@angular/common/http";
import { ApiService } from "src/app/services/api.service";

@Injectable({
  providedIn: "root",
})
export class ApiCompany extends ApiService {
  constructor(storage: Storage, http: HttpClient) {
    super(storage, http);
  }

  async usersList(companyId, page, searchTerm = null, userId = '', sort = []) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/user?';
    if (await this.isGhostMode()) {
      url = ENV.ROUTE + ENV.API_VERSION + 'a/company/' + companyId + '/user?' + 'logedInAs=' + userId + '&';
    }

    const queryParams = {
      name: searchTerm,
      page: page,
      sort: JSON.stringify(sort)
    }
    return new Promise<any>((resolve, reject) => {
      this.http.get(url + new URLSearchParams(queryParams,).toString()).subscribe((res) => {
        resolve(res);
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  getRoles(idRole = null) {
    this.checkAuth();
    return new Promise<any>((resolve, reject) => {
      this.http.get(ENV.ROUTE + ENV.API_VERSION + "role" + (idRole ? '/' + idRole : '')).subscribe((res) => {
      resolve(res);
    }, (error) => {
      resolve(this.requestError(error));
    });
    });
  }

  async createUser(userData, companyId = null, userId = null) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/user';
    if (await this.isGhostMode())
      url = ENV.ROUTE + ENV.API_VERSION + 'a/company/' + companyId + '/user/' + userId;

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, userData).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
    return
  }

  async updateUser(data, userId, companyId = null) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/' + companyId + '/user/' + userId;
    if (await this.isGhostMode())
      url = ENV.ROUTE + ENV.API_VERSION + 'a/company/' + companyId + '/user/' + userId;

    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  async getUser(userId) {
    this.checkAuth();
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/user/' + userId;

    return new Promise<any>((resolve, reject) => {
      this.http.get(url).subscribe((res) => {
        resolve(res)
      }, (error) => {
        resolve(this.requestError(error));
      });
    });
  }

  //_ Creates one permission
  async createPermission(userId, deviceId, data) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'permission/' + userId + '/' + deviceId;

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  //_ Create many permissions
  async createPermissions(userId, data) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'permission/multi/' + userId;

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  async updatePermission(userId, deviceId, data) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'permission/' + userId + '/' + deviceId;

    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  async deletePermission(userId, deviceId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'permission/' + userId + '/' + deviceId;

    return new Promise<any>((resolve, reject) => {
      this.http.delete(url).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  async deleteUser(userId, companyId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/user/' + userId;
    if (await this.isGhostMode())
      url = ENV.ROUTE + ENV.API_VERSION + 'a/company/' + companyId + '/user/' + userId;

    return new Promise<any>((resolve, reject) => {
      this.http.delete(url).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  async updateCompanyProfile(companyId, data) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/' + companyId;
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  async upadateProfilePicture(company, file) {
    const formData: FormData = new FormData();
    const header = new HttpHeaders();

    formData.append('uploadFile', file, file.name);
    header.append('Content-Type', 'multipart/form-data');

    return new Promise<any>((resolve, reject) => {
      this.http.post(ENV.ROUTE + ENV.API_VERSION + 'company/uploadImage/' + company.id,
        formData,
        { headers: header, reportProgress: true, observe: 'events' }).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
          } else if (event.type === HttpEventType.Response) {
            resolve(event.body);
          }
        }, error => {
          reject(error.error?.error);
        });
    });
    return
  }

  async createShareLink(userId, data = {}) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/share/' + userId;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  async getViewMode(company_id) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'viewMode/get/' + company_id;
    return new Promise<any>((resolve, reject) => {
      this.http.get(url).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error.error?.error);
      });
    });
  }

  async checkShareLink(salt, data = {}) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/viewMode/check/' + salt;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error);
      });
    });
  }

  async reportIncidence(userId, data) {
    const url = ENV.ROUTE + ENV.API_VERSION + 'company/support/newincidence/' + userId;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error);
      });
    });
  }

  updateUserImage(fileToUpload: File, userId, subUserId) {
    const formData: FormData = new FormData();
    let url = ENV.mediaServer + ENV.API_VERSION + 'company/upload/userimage/' + userId;
    const header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    header.append('Accept', 'application/json');
    formData.append('uploadedFile', fileToUpload, fileToUpload.name);
    formData.append('subUserId', subUserId);

    const req = new HttpRequest('POST', url, formData, {
        headers: header,
        reportProgress: true,
        responseType: 'json'
    });

    return this.http.request(req);
  }

  async updateParentUser (data, userId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/setParentUser';
    if (await this.isGhostMode()) {
      url = ENV.ROUTE + ENV.API_VERSION + 'a/company/setParentUser/' + userId;
    }
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data).subscribe({
        next: (res) => resolve(res),
        error: (error) => reject(error)
      });
    })

  }

  async removeParentUser (data, userId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/removeParentUser';
    if (await this.isGhostMode()) {
      url = ENV.ROUTE + ENV.API_VERSION + 'a/company/removeParentUser/' + userId;
    }
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data).subscribe({
        next: (res) => resolve(res),
        error: (error) => reject(error)
      });
    })
  }

  async setUsersForManager (data, userId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'company/setUsers';
    if (await this.isGhostMode()) {
      url = ENV.ROUTE + ENV.API_VERSION + 'a/company/setUsers/' + userId;
    }
    return this.http.post(url, data);
  }

}
