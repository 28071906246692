import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Constants } from 'src/app/constants.enum';
import { StorageService } from 'src/app/services/storage.service';
import { App_Currency } from './util';

@Component({
  selector: 'app-currency-picker',
  templateUrl: './currency-picker.component.html',
  styleUrls: ['./currency-picker.component.scss'],
})
export class CurrencyPickerComponent implements OnInit {
  currencies = App_Currency;  
  @Input() selectedCurrency;
  @Input() title = null;
  constructor(private popoverCtrl: PopoverController, private storage: StorageService) { }

  ngOnInit() {    
    if (!this.selectedCurrency){
      this.storage.get(Constants.CURRENCY).then( r => {
        this.selectedCurrency = this.currencies.find(c => c.shortCode == r);
      })      
    }
  }

  selectCurrency(c){
    this.selectedCurrency = c;
    this.popoverCtrl.dismiss({ selectedCurrency: this.selectedCurrency });
  }

}
