import { Injectable } from '@angular/core';
import { App } from "@capacitor/app";
import { Platform } from "@ionic/angular";
@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor(
    private platform:Platform
  ) { }
  
  public async getVersion() {
    if (this.platform.is("capacitor")){
      let versionInfo = await App.getInfo();
      return versionInfo.version;
    }else{
      const aux: any = document.getElementsByTagName('META');
      for (let i = 0; i < aux.length; i++) {
        if (aux[i].name === 'version') {
          return aux[i].content;
        }
      }
    } 
  }
  // public async exitApp() {
  //   if (this.platform.is("capacitor")){
  //     let exit = await App.exitApp();
  //     return exit;
  //   }else{
  //     window.close()
  //     // const aux: any = document.getElementsByTagName('META');
  //     // for (let i = 0; i < aux.length; i++) {
  //     //   if (aux[i].name === 'version') {
  //     //     return aux[i].content;
  //     //   }
  //     // }
  //   } 
  // }

}
