import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-usb-finder-popup',
  templateUrl: './usb-finder-popup.component.html',
  styleUrls: ['./usb-finder-popup.component.scss'],
})
export class UsbFinderPopupComponent {
  constructor (public appService: AppService, private popupCtrl: PopoverController) {

  }

  onCancel() {
    this.popupCtrl.dismiss(null)
  }
}
