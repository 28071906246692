export const generateColors = () => {
  const colors = [];
  colors.push('#F05123');
  colors.push('#ff7070');
  colors.push('#2dd36f');
  colors.push('#ffc409');
  colors.push('#f4f5f8');
  return colors;
}

const getColor = (key) => {
  const style = getComputedStyle(document.body);
  return style.getPropertyValue(key).replace(' ', '');
}