import { animate, AnimationMetadata, style, AnimationBuilder } from '@angular/animations';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { InvertColor } from './colorHelper';

//_ -> @Created by William Valdez <-
//_ Thank u paj gps ....
//_ :D :D :D :D :D :D :D :D :D :D 
@Directive({
  selector: '[backgroundContrast]'
})
export class BackgroundContrastDirective implements AfterViewInit {
  _background = '#eee'
  @Input() get backgroundContrast() { return this._background; };
  @Input() isButton = false;
  @Input() applyToColor = false;
  @Input() appyContrastToColor = true;
  @Input() colorForIcon = false;
  hostElement: HTMLElement

  constructor(public elementRef: ElementRef, public render: Renderer2, private platform: Platform,
    private builder: AnimationBuilder, private translate: TranslateService, private appService: AppService) { }

  set backgroundContrast(value) {
    this._background = value;
    this.updateColor();
  }

  async ngAfterViewInit() {

  }

  updateColor() {
    this.hostElement = this.elementRef.nativeElement;
    if (this.backgroundContrast != null) {
      let color = InvertColor(this.backgroundContrast, true);

      //_ Style for ion-button or for other element
      if (this.applyToColor) {
        this.render.setStyle(this.hostElement, 'color', this.backgroundContrast);

      }
      else {
        const onlyColor = color;
        color = this.appyContrastToColor ? 'color: ' + color : '';
        if (this.isButton){

          this.render.setProperty(this.hostElement, 'style',
            '--background: ' + this.backgroundContrast
            + ';' + color);
          if (this.colorForIcon){
            this.render.setProperty(this.hostElement.querySelector('ion-icon'), 'style', 
            'fill: ' + onlyColor + ' !important');
          }
        }
        else
          this.render.setProperty(this.hostElement, 'style',
            'background: ' + this.backgroundContrast
            + ';' + color);
      }
    }
  }
}