import { Injectable, NgZone } from "@angular/core";
import { STORAGE_SETTINGS, StorageSetting } from "src/app/services/storage-settings";
import { StorageService } from "src/app/services/storage.service";

@Injectable({
    providedIn: 'root'
})
  
export class ActivityService {
    DEVICE_ACTIVITY_SELECTED_DEVICE = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICE_ACTIVITY_SELECTED_DEVICE, null);
    DEVICE_ACTIVITY_TYPE = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICE_ACTIVITY_TYPE, 'last');
    constructor(private storage: StorageService){

    }

}