import { getDistance } from '../mapUtil';
import VPTree from 'vptree';

interface DeviceDirection {
  type: string;
  geometry: {
    coordinates: [number, number][];
    layerType: string;
    type: string;
  };
  properties: {
    icon: string;
    showDirections: boolean;
    size: number;
    deviceId: number;
    id: string;
    layerType: string;
    deviceShow: number;
    color: string;
    invertColor: string;
    rotate: number;
    speed: number;
    directionId: string;
    origin: string;
  };
  id: string;
}

//_ VP TREE
//_ Load VP Tree using Directions Points
//_ Then loop the snaped points and look for one direction point nearest the snaped.
//_ Get 5 points, then sort by distance; then use the nearest index to get the direction point DATA
export const findNearbyPoints = (deviceDirections, deviceSnapedPoints): DeviceDirection[] => {
    type Point = [number, number];
    const mvpTree = VPTree.build<Point>(deviceDirections.map(dd => dd.geometry.coordinates), getDistanceSquare, 0);
    const nearbyPoints: DeviceDirection[] = [];
  
    let lastFeature: any = CLONE_OBJ(deviceDirections[0]);
    for (let index=0; index < deviceSnapedPoints.length; index++) {
        const snappoint = deviceSnapedPoints[index];
        
        let nearestArray = mvpTree.search(snappoint, 5);
    
        
        let direction = null;
        nearestArray = nearestArray.sort((a, b) => a.d - b.d);
        const nearestPoint = nearestArray[0];
        
        if (nearestPoint)
            direction = CLONE_OBJ(deviceDirections[nearestPoint.i]); 

        if (direction) {
            direction.geometry.coordinates = snappoint;
            nearbyPoints.push(direction);
            lastFeature = CLONE_OBJ(direction);
            nearbyPoints.push(direction);
        }
        else{
            lastFeature.geometry.coordinates = snappoint;
            nearbyPoints.push(lastFeature);
        }
    }
  
    return nearbyPoints;
}
//______________

export const getDistanceSquare = (point1, point2): number => {
    return getDistance (point1[1], point1[0], point2[1], point2[0]);
}

export const CLONE_OBJ = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}