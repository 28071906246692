<!-- Use for show the marker and direction popup description -->
<!-- Only need device and point -->
<ion-header style="line-height: 1 !important">
  <ion-item color="dark" style="--padding-start: 12px; --padding-top: 3px" lines="none">
    <ion-avatar slot="start" (click)="deviceSettings(device, $event)">
      <ion-img src="{{ device?.properties | DeviceImage : -1 | async }}" style="object-fit: contain"></ion-img>
    </ion-avatar>
    <ion-label style="margin: 3px; max-width: calc(100% - 10px)">
      <h6 class="ion-text-wrap">{{ device?.properties.name }}</h6>
      <p style="margin: 0" class="ion-text-wrap">
        {{ device?.properties.imei }} {{ super_user ? "/" : "" }} {{ super_user ? device?.properties.id : '' }}
      </p>
    </ion-label>
  </ion-item>
</ion-header>

<ion-list lines="none" class="ion-no-padding" *ngIf="device && point && !pause" mode="md">
  <ion-grid>
    <ion-row>
      <ion-col size="8" class="ion-no-padding">
        <ion-item class="ion-no-padding">
          <ion-icon slot="start" src="assets/images/Kalender - Storage Time.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            {{ point.dateunix | translateUnixDate: "DD.MM.YYYY HH:mm:ss"}}
          </ion-label>
        </ion-item>

        <ion-item class="ion-no-padding" *ngIf="speedIcon">
          <ion-icon slot="start" [src]="speedIcon"></ion-icon>
          <ion-label>{{ point.convertedSpeed | number : "1.0-0" : language }}
            {{ speed_unit }}</ion-label>
        </ion-item>

        <ion-item class="ion-no-padding" *ngIf="height">
          <ion-icon slot="start" src="assets/images/map/Mountain-icon-SJ-01.svg"></ion-icon>
          <ion-label>{{ height }} m</ion-label>
        </ion-item>

        <ion-item class="ion-no-padding" *ngIf="voltage">
          <ion-icon slot="start" src="assets/images/map/flash-outline.svg"></ion-icon>
          <ion-label>
            <h5>{{ voltage }} V</h5>
            <p>
              {{ sensorDate | translateUnixDate }}
            </p>
          </ion-label>
          <br />
        </ion-item>
        <!-- <ion-item class="ion-no-padding sensorDataClass" *ngIf="voltage">
          <ion-label class="ion-text-wrap">{{
            sensorDate | translateUnixDate : "DD.MM.YYYY HH:mm:ss"
          }}</ion-label>
        </ion-item> -->

        <ion-item class="ion-no-padding" *ngIf="temperature">
          <ion-icon slot="start" src="assets/images/map/thermometer-outline.svg"></ion-icon>
          <ion-label>{{ temperature }} </ion-label>
          <ion-label class="ion-text-wrap">
            {{ sensorDate | translateUnixDate: "DD.MM.YYYY HH:mm:ss" }}
          </ion-label>
        </ion-item>

        <ion-item class="ion-no-padding sensorDataClass" *ngIf="temperature">
          <ion-label class="ion-text-wrap">
            {{ sensorDate | translateUnixDate: "DD.MM.YYYY HH:mm:ss" }}
          </ion-label>
        </ion-item>

        <ion-item class="ion-no-padding" lines="none" style="--inner-padding-end: 0"
          *ngIf="feature?.properties.origin == 'wifi'">
          <ion-icon name="wifi-outline" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">{{
            "map.popup.locateWifi" | translate
            }}</ion-label>
        </ion-item>

        <ion-item class="ion-no-padding" lines="none" style="--inner-padding-end: 0"
          *ngIf="feature?.properties.origin == 'gps' && super_user">
          <ion-icon name="location-outline" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap">{{
            "map.popup.locateGps" | translate
            }}</ion-label>
        </ion-item>

        <ion-item class="ion-no-padding" lines="none" style="--inner-padding-end: 0"
          *ngIf="feature?.properties.isHomeWifi && point.wh">
          <ion-icon name="wifi-outline" slot="start"></ion-icon>
          <ion-label class="ion-text-wrap" *ngIf="point.wh === 1">{{
            "commandsTab.SetupWifi.ConnectedWifiHome1" | translate
            }}</ion-label>
          <ion-label class="ion-text-wrap" *ngIf="point.wh === 2">{{
            "commandsTab.SetupWifi.ConnectedWifiHome2" | translate
            }}</ion-label>
        </ion-item>

        <!-- <ion-item class="ion-no-padding" *ngIf="battery"> -->
        <ion-item class="ion-no-padding" *ngIf="battery.show && device.deviceModel.standalone_battery != -1">
          <ion-icon src="{{ battery.icon }}" slot="start"></ion-icon>
          <ion-label>{{ battery.porcentage }}</ion-label>
          <!-- <ion-label *ngIf="battery.icon != 'assets/images/map/popup/DeviceConnectedToBat.svg'">{{ battery.porcentage }}</ion-label>
          <ion-label *ngIf="battery.icon == 'assets/images/map/popup/DeviceConnectedToBat.svg'">{{ "info.connectedToBattery" | translate }}</ion-label> -->
        </ion-item>
      </ion-col>

      <!-- Upload button only show if the feature is a point not a marker or pause -->
      <ion-col size="4" class="ion-no-padding" style="overflow: hidden" *ngIf="
          feature.layer.id != 'markers' &&
          feature.layer.id != 'markers3d' &&
          !appService.isSharedView && userSettings?.upload_markerImg
        ">
        <!-- Upload button -->
        <div style="width: 80px; margin: 0 auto" *ngIf="!progressInfo && !dataPointImage && !loadingImage">
          <ion-img src="assets/images/map/popup/picture.svg" style="height: 100%; max-height: 80px; max-width: 80px">
          </ion-img>
          <input #fileinput type="file" style="display: none" accept="image/*" (change)="uploadPicture($event)" />

          <ion-button color="transparent" expand="full" class="btn-upload" style="height: 80px; width: 80px"
            (click)="fileinput.click()" title="Upload Image">
            <ion-label class="ion-text-wrap" style="display: flex; flex-direction: column; align-items: center;">
              <ion-icon slot="start" src="assets/images/map/popup/upload.svg" style="width: 30px; height: 30px;">
              </ion-icon>
              <p class="label-size">
                {{ "deviceManagement.deviceSettings.uploadText" | translate }}
              </p>
            </ion-label>
          </ion-button>
        </div>

        <!-- Upload Progress -->
        <div class="uploading-item" *ngIf="progressInfo">
          <ion-img [src]="progressInfo?.image | async" style="object-fit: contain; height: 80px">
          </ion-img>
          <ion-progress-bar [value]="progressInfo?.value / 100"></ion-progress-bar>
          <ion-note color="primary" *ngIf="progressInfo?.compressing" style="font-size: 10px">Compressing ...</ion-note>
          <ion-note color="danger" *ngIf="progressInfo?.error" style="font-size: 10px">{{
            "deviceManagement.deviceSettings.errorTryingToUploadImage"
            | translate
            }}</ion-note>
          <ion-button *ngIf="progressInfo?.error" size="small" expand="block"
            (click)="upload(progressInfo?.file, point?.id)">
            {{ "deviceManagement.deviceSettings.tryAgainText" | translate }}
          </ion-button>
        </div>

        <!-- Uploaded Image -->
        <div *ngIf="dataPointImage">
          <ion-img pajImgViewer [src]="dataPointImage" style="object-fit: contain; height: 80px; cursor: pointer">
          </ion-img>
          <ion-item shape="round" color="transparent" lines="none" class="ion-no-padding ion-no-margin"
            style="--min-height: 20px; margin: 0; --inner-padding-end: 0">
            <ion-button slot="end" (click)="deleteImage()" shape="round" color="light" [disabled]="deleting"
              title="Delete image" style="--box-shadow: none">
              <ion-icon name="trash" color="danger" style="width: 15px; margin: 0"></ion-icon>
            </ion-button>
          </ion-item>
        </div>

        <!-- Loading image indicator -->
        <div class="ion-text-center" *ngIf="loadingImage" style="margin-top: 20px">
          <ion-spinner color="primary" style="width: 40px"></ion-spinner>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <!-- Location lat, lng and Note | Location icon-->
      <ion-col size="12" class="ion-no-padding">
        <ion-item class="ion-no-padding" style="--inner-padding-end: 0">
          <ion-icon src="assets/images/globe-outline.svg" slot="start" title="{{ 'popupMap.latlng' | translate }}">
          </ion-icon>
          <!-- <ion-label text-wrap slot="start"
            >{{ point.lat | number: "0.0-10" }} ,
            {{ point.lng | number: "0.0-10" }}
          </ion-label> -->
          <ion-label class="ion-text-wrap coordinates"
            title="{{ point.lat | number : '0.0-10' }}, {{ point.lng | number : '0.0-10' }}"
            (click)="copyCoordinates(point)" style="display: flex;align-items: center;gap:6px;">
            {{ point.lat | latLngToMgrs : point.lng | async }}
            <ion-icon name="copy-outline" style="cursor: pointer;width: 16px;height: 16px;"></ion-icon>
            <!-- <h6>{{ point.lng | number : "0.0-10" }}</h6> -->
          </ion-label>

          <!-- NOTE BUTTON -->
          <!-- <div *ngIf="point && userSettings?.show_note_btn" slot="end">
            <ion-button
              fill="clear"
              color="light"
              *ngIf="!point?.note?.text"
              (click)="openNote(point, $event)"
              title="{{ 'deviceNote.addNote' | translate }}"
              class="note-button"
            >
              <ion-icon
                src="assets/images/devices-list/add-note.svg"
              ></ion-icon>
            </ion-button> -->

          <!-- PIN NOTE BUTTON -->
          <!-- <ion-button
              fill="clear"
              color="light"
              class="pin-button"
              *ngIf="point?.note?.text"
              (click)="openNote(point, $event)"
              title="{{ 'deviceNote.note' | translate }}"
            >
              <ion-icon
                class="note-pin"
                src="assets/images/devices-list/pin.svg"
                [isButton]="true"
                [appyContrastToColor]="false"
                [applyToColor]="point?.note?.color == '#f4f5f8' ? false : true"
                [backgroundContrast]="point?.note?.color"
              >
              </ion-icon>
              <ion-icon src="assets/images/devices-list/sticky-note.svg">
              </ion-icon>
            </ion-button>
          </div> -->
          <!-- END NOTE BUTTON -->

          <!-- LOCATION BUTTON > link to external google map  location -->
          <ion-icon slot="end" src="assets/images/map/popup/pin.svg" title="Google maps"
            style="margin: 0; cursor: pointer" color="primary" (click)="openMap(point.lat, point.lng)"></ion-icon>
        </ion-item>
      </ion-col>

      <!--ion-col size="12" class="ion-no-padding" >
        <ion-item button class="ion-no-padding" style="--inner-padding-end: 0;" [class.google-right]="battery.show" (click)="openMap(point.lat,point.lng)">
          <ion-icon slot="start" src="assets/images/map/popup/pin.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            Google Maps
          </ion-label>
        </ion-item>
      </ion-col-->
    </ion-row>
  </ion-grid>
</ion-list>

<!-- Use for show the pause popup description -->
<!-- This list no need the point variable, only need pause and device variable -->
<!-- Used humanize time to translate and convert unix time to human duration and dateTime -->
<ion-list class="ion-no-padding" lines="none" *ngIf="device && pause" mode="md">
  <ion-grid>
    <ion-row>
      <ion-col size="8" class="ion-no-padding">
        <ion-item class="ion-no-padding">
          <!--i class="fas fa-sign-in-alt" slot="start"></i-->
          <ion-icon src="assets/images/map/popup/login.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            {{ pause.pause_start.dateunix | translateUnixDate: "Do MMMM YYYY, HH:mm:ss" }}
          </ion-label>
        </ion-item>

        <!-- From api the pause -> pause_start variable has the startPosition -->
        <ion-item class="ion-no-padding">
          <!--i class="fas fa-sign-out-alt" slot="start"></i-->
          <ion-icon src="assets/images/map/popup/logout.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            {{ pause.data.dateunix | translateUnixDate: "Do MMMM YYYY, HH:mm:ss" }}
          </ion-label>
        </ion-item>
      </ion-col>

      <ion-col size="4" class="ion-no-padding">
        <!-- From api the data -> pause_start variable has the endPosition -->
        <!-- HumanizeTime for convert duration and format with locale language , (type='duration', shor=true)-->
        <div class="ion-no-padding">
          <!--i class="fas fa-stopwatch" slot="start"></i-->

          <ion-label class="ion-text-center ion-text-wrap">
            <!--ion-icon src="assets/images/map/popup/stop-watch1.svg"></ion-icon-->
            <ion-img src="assets/images/map/markers/stop-marker.svg" style="width: 50px; height: 50px; margin: 0 auto">
            </ion-img>
            <p style="
                font-size: 10px;
                color: var(--ion-color-light-contrast) !important;
                margin: 0;
                white-space: break-spaces;
                overflow: visible;
                direction: initial;
              ">
              {{
              pause.pause_start.dateunix - pause.data.dateunix
              | humanizeTime : "duration" : ""
              }}
            </p>
          </ion-label>
        </div>
      </ion-col>

      <ion-col size="12" class="ion-no-padding" *ngIf="stopAddress">
        <ion-item class="ion-no-padding">
          <!--i class="fas fa-sign-in-alt" slot="start"></i-->
          <ion-icon src="assets/images/map/popup/pin.svg"></ion-icon>
          <ion-label class="ion-text-wrap">{{ stopAddress }}</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-item class="ion-no-padding" button (click)="openMap(pause.pause_display.lat, pause.pause_display.lng)">
          <ion-icon slot="start" src="assets/images/map/popup/pin.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            {{ "map.popup.googleRoute" | translate }}
            <!-- <a class="popupLink" href="https://www.google.de/maps/dir/?api=1&destination={{pause.pause_display.lat}},{{pause.pause_display.lng}}&external=true" target="_blank">Google Maps Route</a> -->
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-list>