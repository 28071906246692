export class Effects {

  public Pulse(map, inColor, outColor, size = 200) {
    return {
      width: size, height: size, data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd() {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d', { willReadFrequently: true });
      },

      // called once before every frame where the icon will be used
      render() {
        const duration = 1500;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;

        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
        context.fillStyle = outColor;
        context.globalAlpha = 0.5;
        context.fill();



        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        if (map)
          map.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      }
    };
  }

  public Glass(map, inColor, outColor, name = '') {
    const size = 200;
    const wait = (ms) => new Promise(r => setTimeout(r, ms)); //for Delay times
    let tc = this;
    return {
      width: size, height: size, data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd() {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d', { willReadFrequently: true });
      },


      // called once before every frame where the icon will be used
      render() {
        const duration = 2000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 4) * 0.7 * t + radius;
        const context = this.context;

        context.clearRect(0, 0, this.width, this.height);
        context.globalCompositeOperation = "lighter";

        //context.createRadialGradient(x0,y0,r0,x1,y1,r1);
        //let gradient = context.createRadialGradient(-this.width*3, -this.height*3, size/3, this.width, this.height, 100);
        let gradient = context.createRadialGradient(this.width / 2, this.height / 2, 10, this.width / 2, this.height / 2, (size / 3) + 5);
        gradient.addColorStop(0, 'rgba(250,250,255,0)');
        gradient.addColorStop(0.75, 'rgba(230,250,255,1.0)');
        gradient.addColorStop(1, 'rgba(0,0,255,0)');

        context.beginPath();
        //context.arc(x,y,r,sAngle,eAngle,counterclockwise);
        context.arc(this.width / 2, this.height / 2, size / 3, 0, Math.PI * 2);
        context.fillStyle = gradient;
        context.strokeStyle = outColor;
        context.setLineDash([30, 10]);
        context.lineDashOffset = -outerRadius;
        context.lineWidth = 8;
        context.fill();
        context.stroke();


        let centerX = this.width / 2;
        let centerY = this.height / 2;
        let angle = Math.PI * 0.80;

        context.font = '21pt Calibri';
        context.textAlign = 'center';
        context.fillStyle = 'black';
        context.strokeStyle = 'black';
        context.lineWidth = 4;

        tc.drawTextAlongArc(context, name, centerX, centerY, 80, angle);
        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        if (map)
          map.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      }
    };
  }

  public PulseShadow(map, inColor, outColor, size = 200) {
    return {
      width: size, height: size, data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd() {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d', { willReadFrequently: true });
      },

      // called once before every frame where the icon will be used
      render() {
        const duration = 1300;
        // Get time from 0 to 1 with the performance.now() relative with duration
        const t = (performance.now() % duration) / duration;
        const radius = (size / 2) * 0.1;
        let outerRadius = (size / 2) * 0.4 * t + radius;

        //if (t > 0.5)
        //  outerRadius = (size / 2) * 0.5 * (1-t) + radius;

        const context = this.context;
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();

        context.lineWidth = 4;
        context.shadowColor = outColor;
        context.shadowBlur = t * 15;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;

        context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);

        context.strokeStyle = outColor;
        context.globalAlpha = t > 0.4 ? (1.4 - t * 1.5) : 1;
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        if (map)
          map.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      }
    };
  }

  drawTextAlongArc(context, str, centerX, centerY, radius, angle, top = 1) {
    let len = str.length, s;
    context.save();
    context.translate(centerX, centerY);
    context.rotate(-1 * angle / 2);
    context.rotate(-1 * (angle / len) / 2);
    for (let n = 0; n < len; n++) {
      context.rotate(angle / len);
      context.save();
      context.translate(0, -1 * radius);
      s = str[n];
      context.fillText(s, 0, 0);
      context.restore();
    }
    context.restore();
  }

}

