import { FeatureInterface } from "./feature-interface";
import { MainMapInterface } from "../../main-map.interface";
import { DataSource } from "../dataSource";
import { inject } from "@angular/core";
import { MapService } from "../../map.service";
import { Data, dataSourceType, dataTypes } from "../data";
import { Layer, layerType } from "../layer";
import { getSource } from "../castMaplibreData";
import { AvailableHooks } from "../componentHooks";

export class GeofenceLineIndicatingFeature implements FeatureInterface {
  LAYER_NAME: string = 'GeofenceLineIndicatingLayer';
  SOURCE_NAME: string = 'GeofenceLineIndicatingSource';
  source: DataSource = null;
  layer = null;

  mapService = inject(MapService)
  constructor(public main: MainMapInterface){
    this.init();
  }

  init(){
    this.source = this.createDataSource();
    this.layer = this.createLayer(this.LAYER_NAME, this.SOURCE_NAME);

    this.load();
  }

  load(): void {

    this.main.on(AvailableHooks.onLoadLayer, (e) => {
      if (!this.main.map.getLayer(this.LAYER_NAME))
        this.main.map.addLayer(this.layer);
    });

    this.main.on(AvailableHooks.onLoadSource, (e) => {
      if (!this.main.map.getSource(this.SOURCE_NAME))
        this.main.map.addSource(this.SOURCE_NAME, this.source);
    });
  }

  createDataSource() {
    let source = <DataSource>{};
    source.data = <Data>{};
    source.type = dataSourceType.geojson;
    source.data.features = [];
    source.data.type = dataTypes.FeatureCollection;
    source["generateId"] = true;

    return source;
  }

  createLayer(name, source){
    let layer = new Layer(name);
    layer.source = source;
    layer.type = layerType.line;

    if (name == this.LAYER_NAME){
      layer.type = layerType.line;
      layer.layout["line-join"] = 'round';
      layer.layout["line-cap"] = 'butt';
      layer.paint["line-color"] = '#000';
      layer.paint["line-width"] = 1.5;

    }

    return layer;
  }

  addLineIndication(geofence, updateSource = true) {
    const lineFeature = (this.main as any).centerPoint.lineIndicatingHandlePoint(geofence);
    lineFeature.properties['id'] = geofence.id;
    //_ Adding the feature to the data Source ...
    this.source.data.features.push(lineFeature);
    if (updateSource)
      this.updateDataSource();
  }

  addOrUpdateLineFeature(geofence, updateSource = true) {
    const featureIndex = this.source.data.features.findIndex(feature =>
      feature.properties['id'] === geofence.id
    );
    if (featureIndex !== -1) {//Update
      this.updateLineFeature(featureIndex, geofence)
    } else { //Add
      this.addLineIndication(geofence, updateSource)
    }
  }

  updateLineIndication(geofence) {
    const featureIndex = this.source.data.features.findIndex(feature =>
      feature.properties['id'] === geofence.id
    );
    if (featureIndex !== -1) {
      this.updateLineFeature(featureIndex, geofence)
    }
  }

  deleteLineIndication(id) {
    this.source.data.features = this.source.data.features.filter(f => {
      const match = f.properties['id'] === id;
      return !match;
    });
    this.updateDataSource();
  }

  private updateLineFeature(featureIndex, geofence) {
    const lineFeature = (this.main as any).centerPoint.lineIndicatingHandlePoint(geofence);
    this.source.data.features[featureIndex].geometry.coordinates = lineFeature.geometry.coordinates;
    this.updateDataSource();
  }

  updateDataSource() {
    if (this.main.map) {
      if (this.main.map.getSource(this.SOURCE_NAME))
        getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
  }

  clearLines(){
    this.source = this.createDataSource();
    this.updateDataSource();
  }
  show: boolean;

}
