import { Component, OnInit } from "@angular/core";
import {
  LoadingController,
  ModalController,
  Platform,
  AlertController,
} from "@ionic/angular";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Constants } from "src/app/constants.enum";
import { StorageService as Storage } from "./../../../services/storage.service";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "src/app/app.service";
import { MediaApiService } from "src/app/components/media-managment/media-api.service";
import { CameraService } from "src/app/services/plugins/camera.service";
import { FileService } from "src/app/services/plugins/file.service";

@Component({
  selector: "app-user-img",
  templateUrl: "./user-img.component.html",
  styleUrls: ["./user-img.component.scss"],
})
export class UserImgComponent implements OnInit {
  userProfile = "";
  imageChangedEvent: any = "";
  croppedImage: any = "";
  uploadNewProfileImage: any;
  previousImage: any;
  showTakePhoto = false;
  constructor(
    private storage: Storage,
    public loadingCtrl: LoadingController,
    public modelCtrl: ModalController,
    public authService: AuthenticationService,
    public platform: Platform,
    private cameraService: CameraService,
    private fileService:FileService,
    private translate: TranslateService,
    private mediaApi: MediaApiService,
    private appService: AppService
  ) {}

  async ngOnInit() {
    this.userProfile = "assets/images/company/user.svg";
    this.authService.profileImgUrl.subscribe((img) => {
      this.userProfile = img;
      this.previousImage = img;
    });
    this.platform.ready().then(() => {
      if (this.platform.is("cordova") || this.platform.is("capacitor"))
      {
        this.showTakePhoto = true;
      }
    })
  }

  

  async dismiss() {
    await this.modelCtrl.dismiss({
      dismissed: true,
    });
  }

  async fileupload($event: any) {
    let userData = await this.storage.get(Constants.USER_DATA);
      if(userData.id == 3396){
        this.appService.showToast('', this.translate.instant('company.users.cannotAddUserText'), 3000, 'danger');
        return;
      }
    this.imageChangedEvent = $event;
  }

  requesting = false;
  async openCamera() {
    let userData = await this.storage.get(Constants.USER_DATA);
      if(userData.id == 3396){
        this.appService.showToast('', this.translate.instant('company.users.cannotAddUserText'), 3000, 'danger');
        return;
      }
    if (this.requesting) return true;
    this.requesting = true;
    let capturedPhoto = await this.cameraService.getPicture();
    this.requesting = false;
    const blob = await this.fileService.convertUriToBlob(capturedPhoto);
    // console.log('blob',blob)
    await this.saveImage(blob);

   
  }

  async saveImage(imgBlob) {
    // console.log('saveImage',imgBlob)
    const imgFile: any = await this.mediaApi
      .compressFile(imgBlob, {
        maxSizeMB: 1,
        useWebWorker: true,
        maxWidthOrHeight: 512,
      })
      .catch((e) => {
        console.log("Error trying to compress the file");
        this.appService.showToast(
          "",
          this.translate.instant('toast.errorCompressFile'),
          3000,
          "danger"
        );
        return null;
      });

    if (imgFile) {
      this.authService.updateProfileImage(imgFile).then((data) => {
        if (data.body.iconcustomimage) {
          this.authService.profileImg = data.body.iconcustomimage;
          this.authService.setProfileImageUrl();
          this.storage.get(Constants.USER_DATA).then((res) => {
            res.customer_img = data.body.iconcustomimage;
            this.storage.set(Constants.USER_DATA, res);
          });
        }
      });
    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped (event: ImageCroppedEvent) {
    this.userProfile = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  CropSuccess() {}

  async uploadImageToServer() {
    const loading = await this.loadingCtrl.create({
      message: this.translate.instant("user-img-com.please-wait"),
      translucent: true,
    });
    await loading.present();
    this.imageChangedEvent = "";

    const file = await this.mediaApi.base64ToFile(this.userProfile, 'image_profile');
    const imgFile = await this.mediaApi
      .compressFile(file, {
        maxSizeMB: 1,
        useWebWorker: true,
        maxWidthOrHeight: 1000,
      })
      .catch((e) => {
        console.log("Error trying to compress the file", e);
        this.appService.showToast(
          "",
          this.translate.instant("user-img-com.image-upload-error"),
          3000,
          "danger"
        );
        return null;
      });
    if (imgFile) {
      this.uploadNewProfileImage = imgFile;
      this.authService
        .updateProfileImage(this.uploadNewProfileImage)
        .then(async (data) => {
          if (data.body.iconcustomimage) {
            this.authService.profileImg = data.body.iconcustomimage;
            this.authService.setProfileImageUrl();
            this.storage.get(Constants.USER_DATA).then((res) => {
              res.customer_img = data.body.iconcustomimage;
              this.storage.set(Constants.USER_DATA, res);
              //console.log("hasphot vlaue is : ", this.hasPhoto)
            });
            this.appService.showToast(
              "",
              this.translate.instant("user-img-com.image-upload-success"),
              2000,
              "success"
            );
            loading.dismiss();
          }
        })
        .catch(async (error) => {
          this.appService.showToast(
            "",
            this.translate.instant("user-img-com.image-upload-error"),
            2000,
            "danger"
          );
          loading.dismiss();
        });
    }
  }

  restoreImage() {
    this.userProfile = this.previousImage;
    this.imageChangedEvent = "";
  }

  cordovaCameraExist: MutationObserver = null;
  stylylingCamera() {
    if (this.cordovaCameraExist) this.cordovaCameraExist.disconnect();

    this.cordovaCameraExist = new MutationObserver((mutations, obs) => {
      const cordovaDiv: any = document.querySelector(".cordova-camera-capture");
      if (cordovaDiv) {
        const captureButton = cordovaDiv.querySelector("button");
        const cancelButton = document.createElement("button");
        const newDiv = document.createElement("div");

        newDiv.append(cancelButton);
        newDiv.appendChild(captureButton);
        cancelButton.className = "cancel";
        cancelButton.innerHTML = "Cancel";
        captureButton.innerHTML = "Capture";

        cordovaDiv.append(newDiv);
        cancelButton.addEventListener("click", () => {
          const videoEl: any = cordovaDiv.querySelector("video");
          this.requesting = false;
          if (videoEl) {
            const tracks = videoEl.srcObject.getTracks();
            tracks.forEach((track) => track.stop());
          }
          cordovaDiv.remove();
        });

        obs.disconnect();
        return;
      }
    });

    this.cordovaCameraExist.observe(document, {
      childList: true,
      subtree: true,
    });
  }

  async removePhoto() {
    this.authService
    .updateUser({customer_img:null}, this.appService.user.id)
    .then(async (data) => {
      this.userProfile = "assets/images/company/user.svg"
      this.authService.profileImg = data.success.customer_img;
      this.authService.setProfileImageUrl();
      this.storage.get(Constants.USER_DATA).then((res) => {
      res.customer_img = data.success.customer_img;
      this.storage.set(Constants.USER_DATA, res);
      this.appService.showToast(
        "",
        this.translate.instant("profileImage.deleteSuccess"),
        2000,
        "success"
      );
    })})
  .catch(error => {
    this.appService.showToast(
      "",
      this.translate.instant("profileImage.errorDeleteImage"),
      2000,
      "danger"
    );
  });
  }

}
