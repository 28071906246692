import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';

export class TimelineDataSource<T> implements DataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);

  connect(): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
  }

  setData(data: T[]): void {
    this.dataSubject.next(data);
  }

  getData(): T[] {
    return this.dataSubject.value;
  }
}