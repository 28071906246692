<div>
  <ng-container *ngFor="let alert of alerts">
    <ion-card
      class="card slideDown"
      *ngIf="alert && alert.show"
    >
      <ion-card-header>
        <ion-button
          slot=""
          color="dark"
          shape="round"
          fill="clear"
          class="close-button"
          (click)="close(alert)"
        >
          <ion-icon
            class="close-button-icon"
            name="close-circle-outline"
          ></ion-icon>
        </ion-button>
      </ion-card-header>

      <img
        [src]="'assets/images/server-alert/' + alert.type + '.svg'"
        *ngIf="!alert.customImg"
      />
      <ion-icon
        [src]="alert.customImg"
        *ngIf="alert.customImg"
        color="primary"
        class="alert-img"
      ></ion-icon>
      <ion-card-content>
        <p
          style="white-space: pre-wrap"
          [innerHTML]="
            alert[messageKey] ? alert[messageKey] : (alert.message | sanitizeHtml)
          "
        ></p>

        <p *ngIf="currentStatus">{{ currentStatus.message }}</p>
        <ion-progress-bar *ngIf="alert?.id == -1" type="indeterminate">
        </ion-progress-bar>
      </ion-card-content>

      <ion-segment class="transparent">
        <ion-button color="primary" shape="round" (click)="continue(alert)">
          <ion-label>{{ "whatsnew.continueText" | translate }}</ion-label>
        </ion-button>

        <ion-button
          color="dark"
          shape="round"
          (click)="action(alert)"
          *ngIf="alert?.actionData"
          [disabled]="runningAction"
        >
          <ion-spinner
            color="light"
            *ngIf="runningAction"
            class="loading"
          ></ion-spinner>
          <ion-label>{{ alert?.actionData?.buttonName }}</ion-label>
        </ion-button>
      </ion-segment>
    </ion-card>
  </ng-container>
</div>
