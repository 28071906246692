import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  CanLoad,
  Router,
  Route,
} from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Storage } from '@ionic/storage-angular';
import { Constants } from '../constants.enum';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    public auth: AuthenticationService,
    private router: Router,
    private storage: Storage,
    private appService: AppService,
  ) {}

  // canActivate(r, state): Promise<boolean> {
  //   if(this.router.url.includes('stand-alone-page')){
  //     return Promise.resolve(true);
  //   }
  //   else if (!this.auth.isAuthenticated()){
  //     this.router.navigate(['/login'], { queryParams: { redirectUrl: state.url }});
  //   }

  //   // return this.auth.isAuthenticated();

  //   if (this.auth.isAuthenticated()) {
  //     this.appService.guardLoading.next(true);
  //     return this.isDataLoaded();
  //   }
  // }

  async canActivate(r, state): Promise<boolean | UrlTree> {
    if (this.router.url.includes('stand-alone-page')) {
      return true;
    } else if (state.url.includes('password-reset-advice')) {
      return true;
    } else if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/login'], {
        queryParams: { redirectUrl: state.url },
      });
    }
    // else if (appModes) {
      //const appModes: boolean = await this.storage.get(Constants.PET_MODE);
    //   console.warn('appModes in auth guard', appModes);
    //   this.router.navigate(['/pets/home'], { replaceUrl: true });
    //   return true;
    // }

    return this.auth.isAuthenticated();
  }

  CanLoad(route: Route): boolean {
    const redirect = '?redirect=' + route.path;
    if (this.auth.isAuthenticated()) {
      return true;
    }

    this.router.navigate(['/login' + redirect]);
    return false;
  }

  async isDataLoaded(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.appService.user && this.appService.userSettings) {
        this.appService.guardLoading.next(false);
        resolve(true);
      }

      combineLatest([
        this.appService._user,
        this.appService._userSettings,
      ]).subscribe(([user, userSettings]) => {
        console.log('USER And SETTINGS LOADED', { user, userSettings });
        this.appService.guardLoading.next(false);
        resolve(true);
      });
    });
  }

  // async canActivate() {
  //   let token = await this.auth.isAuthenticated()
  //   return await token
  // }
}
