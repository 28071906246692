import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ServerAlertComponent } from './server-alert.component';


@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      PipesModule.forRoot(),
      TranslateModule,
      DirectivesModule
    ],

    declarations: [ServerAlertComponent],
    exports: [ServerAlertComponent, PipesModule],
    
  })

  
  export class PajServerAlertModule {}