import { getSource } from "../castMaplibreData";
import { AvailableHooks } from "../componentHooks";
import { Data, dataSourceType, dataTypes } from "../data";
import { DataSource } from "../dataSource";
import { Geometry, GeometryTypes } from "../geometry";
import { IProperties } from "../Iproperties";
import { Layer, layerType } from "../layer";
import { addMapImage, GenerateUUID, getCssStyleVariable, svgToData } from "../mapUtil";
import { FeatureInterface } from "./feature-interface";
import { MainMapInterface } from "../../main-map.interface";
import { inject } from "@angular/core";
import { MapService } from "../../map.service";

//_ CLASS TYPES
export type SYMBOL_MARKER_OPTIONS = {
  image?: string,
  size?: number,
  rotate?: number,
  anchor?: string,
  textAnchor?: string,
  title?: string,
  color?: string,
  showCircle?: boolean,
  iconOffset?: [Number, Number]
}

export type PRELOAD_IMAGE_MODEL = {
  src: string,
  name: string,
  width: number, height: number
}

export class WifiHomeMarkers implements FeatureInterface {
    SOURCE_NAME: string = 'wifiHomeSource';
    LAYER_NAME: string = 'wifiHomeLayer'
    source: DataSource = null;
    layer = null;

    preloadedImages: Array<PRELOAD_IMAGE_MODEL> = [
      {
        src: 'assets/images/setup-wifi/wifi-home-pin.svg',
        name: 'wifi-home-marker',
        width: 82, height: 100
      },
    ];

    primaryColor = '#FF4E00';
    show: boolean = true;

    defaultLayerOptions: SYMBOL_MARKER_OPTIONS = {
      size: 0.45,
      anchor: 'bottom',
      textAnchor: 'top',
      rotate: 0,
      color: this.primaryColor,
      showCircle: true,
      iconOffset: [0, 0]
    }

    mapService = inject(MapService)
    constructor(public main: MainMapInterface){
        this.init();
    }

    init(){
        this.primaryColor = getCssStyleVariable('--ion-color-primary');

        this.source = this.createDataSource();
        this.layer = this.createLayer(this.LAYER_NAME, this.SOURCE_NAME);
        // this.circlesLayer = this.createLayer(this.CIRCLES_LAYER_NAME, this.SOURCE_NAME);

        this.load();
    }

    createDataSource() {
        let source = <DataSource>{};
        source.data = <Data>{};
        source.type = dataSourceType.geojson;
        source.data.features = [];
        source.data.type = dataTypes.FeatureCollection;
        source["generateId"] = true;

        return source;
    }

    createLayer(name, source){
        let layer = new Layer(name);
        layer.source = source;
        layer.type = layerType.symbol;

        if (name == this.LAYER_NAME){
            layer.type = layerType.symbol;
            layer.layout["icon-image"] = ["get", "image"];
            layer.layout["icon-size"] = ["get", "size"];
            layer.layout["icon-rotation-alignment"] = "viewport"; //could be 'viewport' ; used to rotate the icon with the viewport of the map ...

            layer.layout["icon-allow-overlap"] = true;
            layer.layout["icon-rotate"] = ["get", "rotate"];
            layer.layout["icon-anchor"] = ["get", "anchor"]; //"bottom";
            layer.layout["icon-offset"] = ["get", "iconOffset"];

            layer.layout["text-anchor"] = ["get", "textAnchor"]; //"top";
            layer.layout["text-field"] = ["get", "title"];
            layer.layout["text-offset"] = [0, -6]; //2
            layer.layout["text-allow-overlap"] = true;

            layer.maxzoom = 20;
            layer.minzoom = 4;

            layer["filter"] = [
              "all",
              ["==", ['get', "deviceshow"], 1], //_ Show only if deviceShow == 1
              // ['==', ['get', 'show'], true],
              [
                  'any',
                  ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
                  ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
              ]
          ];
        }

        return layer;
    }

    load(){
        // LOAD CUSTOM IMAGES TO USE IN THE MAP
        this.main.on(AvailableHooks.onLoadImages, (e) => {
            this.preloadedImages.forEach(img => {
              addMapImage(img.src, this.main.map, img.name, img.width, img.height);
            })
        });

        this.main.on(AvailableHooks.onLoadLayer, (e) => {
            if (!this.main.map.getLayer(this.LAYER_NAME))
                this.main.map.addLayer(this.layer, 'markers'); //_ Show symbolMarkers behind markers layer
        });

        this.main.on(AvailableHooks.onLoadSource, (e) => {
            if (!this.main.map.getSource(this.SOURCE_NAME))
                this.main.map.addSource(this.SOURCE_NAME, this.source);
        });

        this.main.on(AvailableHooks.onSetSliderView, (e) => {
          this.setSliderView(e);
        });

        this.main.mapReady.subscribe( r => {
            if (r){
                this.mouseEvents();
            }
        });


    }

  //_ imageName : name of symbol preload image
  //_ location as type array(number, number)
  //_ trigger update source on map
  //_ layerOptions to add custom layer options declared in the type and in the layer object
  public addMarker(location: [number, number], updateSource = true, layerOptions: SYMBOL_MARKER_OPTIONS = {}, extraData = {}, imageName='wifi-home-marker') {
    let name = GenerateUUID();
    let geometry = new Geometry();
    let properties = {} as IProperties;
    geometry.type = GeometryTypes.Point;
    geometry.coordinates = location;
    properties['image'] = imageName; // name of the image loaded in load map when started the component.
    properties["layerType"] = this.LAYER_NAME; //_ Type of feature
    properties["isSliderViewOpen"] = this.main.isSliderViewEnable;

    //_ Apply default layer options
    Object.keys(this.defaultLayerOptions).forEach(key => {
      properties[key] = this.defaultLayerOptions[key];
    })

    //_ Apply input layer options
    Object.keys(layerOptions).forEach(key => {
      properties[key] = layerOptions[key];
    })

    if (extraData) {
      Object.keys(extraData).forEach(key => {
        properties[key] = extraData[key];
      })
    }

    let pmarker = this.main._createFeature(geometry, properties, name);
    // console.log('NEW FEATURE', pmarker);
    //_ Adding the feature to the data Source ...
    this.source.data.features.push(pmarker);

    if (updateSource)
      this.updateDataSource();
  }

  //_ Add all wifis of the device | removeAll to remove existents wifi markers to not duplicate markers just in case.
  public addMarkersForDevice(device, removeAll = false, updateSource = true, layerOptions: SYMBOL_MARKER_OPTIONS = {}, extraData = {}, imageName='wifi-home-marker') {
    //_ Remove all
    if (removeAll && this.source)
      this.source.data.features = this.source.data.features.filter(
        (data) => data.properties["deviceId"] != device.id
      );

    device.wifiMarkers.forEach(wifi => {
      wifi.deviceshow = device.properties.deviceshow;
      this.addMarker([+wifi.lng, +wifi.lat], false, {}, wifi);
    });

    this.updateDataSource();
  }

  updateWifiMarker (deviceId, wifiIndex, lat, lng) {
    const featureIndex = this.source.data.features.findIndex(feature =>
      feature.properties['deviceId'] === deviceId &&
      feature.properties['wifiIndex'] === wifiIndex
    );

    if (featureIndex !== -1) {
      this.source.data.features[featureIndex].geometry.coordinates = [lng, lat];
      this.source.data.features[featureIndex].properties['lat'] = lat;
      this.source.data.features[featureIndex].properties['lng'] = lng;

      this.updateDataSource();
    }
  }

  //_ Update the device wifi locations
  updateDeviceWifiMarkers (device) {
    const deviceWifiMarkers = this.source.data.features.filter(
      (data) => data.properties["deviceId"] === device.id
    );
    const deletedMarkers = this.filterDeletedMarkers(device.wifiMarkers, deviceWifiMarkers);

    if (device.wifiMarkers.length > 0){
      device.wifiMarkers.forEach(wifi => {
        if (this.existsWifiMarker(device.id, wifi.wifiIndex)) {
          this.updateWifiMarker(device.id, wifi.wifiIndex, +wifi.lat, +wifi.lng);
        } else {
          wifi.deviceshow = device.properties.deviceshow;
          this.addMarker([+wifi.lng, +wifi.lat], false, {}, wifi);
        }
      });
      this.updateDataSource();
    }

    //_ Delete missed markers in device.wifiMarkers input
    deletedMarkers.forEach(feature => this.deleteWifiMarker(device.id, feature.properties.wifiIndex));
  }

  filterDeletedMarkers (newMarkers, loadedMarkers) {
    const newMarkerIds = new Set(newMarkers.map(marker => marker.wifiIndex));
    const deletedMarkers = loadedMarkers.filter(marker => !newMarkerIds.has(marker.properties.wifiIndex));

    return deletedMarkers;
  }

  deleteWifiMarker (deviceId, wifiIndex) {
    this.source.data.features = this.source.data.features.filter(f => {
      const match = f.properties['deviceId'] === deviceId && f.properties['wifiIndex'] === wifiIndex;
      return !match;
    });
    this.updateDataSource();
  }

  updateDeviceShow (deviceId, deviceShow) {
    this.source.data.features.forEach(f => {
      if (f.properties['deviceId'] === deviceId)
        f.properties['deviceshow'] = deviceShow;
    });
    this.updateDataSource();
  }

  existsWifiMarker (deviceId, wifiIndex) {
    return this.source.data.features.find(f => f.properties['deviceId'] === deviceId && f.properties['wifiIndex'] === wifiIndex);
  }

  mouseEvents() {
    const tc = this.main;
    (this.main.map as any).on("mouseenter", this.LAYER_NAME, function () {
      tc.map.getCanvas().style.cursor = "pointer";
    });
    (this.main.map as any).on("mouseleave", this.LAYER_NAME, function () {
      tc.map.getCanvas().style.cursor = "default";
    });
  }

  addSymbolImage (newImage: PRELOAD_IMAGE_MODEL) {
    this.preloadedImages.push(newImage);
    addMapImage(newImage.src, this.main.map, newImage.name, newImage.width, newImage.height);
  }


  updateDataSource() {
    if (this.main.map) {
      if (this.main.map.getSource(this.SOURCE_NAME))
        getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
  }

  clearMarkers(){
    this.source = this.createDataSource();
    this.updateDataSource();
  }

  setSliderView(ev) {
    //_ Filter source
    this.source.data.features.forEach((f) => {
      f.properties["isSliderViewOpen"] = ev.isEnable;
    });

    //_ If slider is close then needs to filter the sliderData features
    if (!ev.isEnable) {
      this.source.data.features = this.main.filterFeaturesWithoutSliderProps(this.source.data.features);
      this.source.data.features = this.main.cleanSliderFeatureProps(this.source.data.features);
      // this.source.data.features = this.source.data.features.filter(f => !this.mapService.mapMonitorMode.value);
    }

    //_ Update source
    if (this.main.map.getSource(this.SOURCE_NAME))
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
  }

}
