import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-geofenceinfopopup',
  templateUrl: './geofenceinfopopup.component.html',
  styleUrls: ['./geofenceinfopopup.component.scss'],
})
export class GeofenceinfopopupComponent implements OnInit {

  constructor(private popoverCtrl: PopoverController, private _translate: TranslateService) { }
  @Input() title = 'Title';
  @Input() subtitle = null;
  @Input() description = 'Description';
  @Input() image = null;
  @Input() template = null;
  @Input() useGif = false;
  ngOnInit() {}

  async close() {
    await this.popoverCtrl.dismiss();
  }
}
