export const getMostAccurateAddress = (addresses, input) => {
  return addresses
      .map((address) => {
          const { coordinates } = address.geometry;
          const properties = address.properties;

          // Define a scoring system
          let score = 0;

          // 1. Score proximity if coordinates are provided.
          if (input.coordinates) {
              const distance = getDistance(input.coordinates, coordinates);
              score += 100 / (distance + 1); // Inverse distance scoring (closer = higher score)
          }

          // 2. Match street names (if input contains a street).
          if (input.street && properties.street) {
              if (input.street.toLowerCase() === properties.street.toLowerCase()) {
                  score += 50; // Add points for matching street
              }
          }

          // 3. Bonus for completeness (e.g., presence of housenumber, postcode).
          if (properties.housenumber) score += 10;
          if (properties.postcode) score += 10;
          if (properties.locality) score += 5;

          // 4. Preference for specific types (e.g., "house" type).
          if (properties.type === 'house') score += 15;

          return { ...address, score };
      })
      .sort((a, b) => b.score - a.score) // Sort by score in descending order
      .shift(); // Return the address with the highest score
}

/**
* Calculate distance between two coordinates (latitude/longitude).
* @param {Array} coords1 - [longitude, latitude] of point 1.
* @param {Array} coords2 - [longitude, latitude] of point 2.
* @returns {number} - Distance in kilometers.
*/
function getDistance(coords1, coords2) {
  const [lon1, lat1] = coords1;
  const [lon2, lat2] = coords2;

  const R = 6371; // Radius of Earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}
