<ion-header [translucent]="true">
    <ion-toolbar color="transparent">
      <ion-buttons slot="end" >
        <ion-button (click)="dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <!-- <ion-title>{{"devicesSetup.titleText"| translate}}</ion-title> -->
    </ion-toolbar>
</ion-header>
  
<ion-content [fullscreen]="true" scrollY="false">
  <div class="content no-scrollbars">
    <ion-img class="image-header" src="assets/images/devices-setup/device-settings.png"></ion-img>
    <h2 class="ion-text-center">{{ 'devicesSetup.settingsList.chooseSetting' | translate }}</h2>
    <ion-list lines="none" class="settings-list ion-padding">
        <setup-device-name [device]="device" @fadeIn></setup-device-name>
        <ion-item color="light" button detail="false" (click)="openSetting('device_color')"> 
            <ion-icon src="assets/images/devices-setup/color-picker.svg" slot="start"></ion-icon>
            {{ 'devicesSetup.settingsList.deviceColor' | translate }}
        </ion-item>
        <ion-item color="light" button detail="false" (click)="openSetting('device_image')">
          <ion-icon name="camera-outline" slot="start"></ion-icon>
          {{ 'devicesSetup.settingsList.deviceImage' | translate }}
      </ion-item>
        <ion-item color="light" button detail="false" (click)="openSetting('device_alerts')">
            <ion-icon src="assets/images/devices-setup/alerts.svg" slot="start"></ion-icon>
            {{ 'devicesSetup.settingsList.alertSettings' | translate }}
        </ion-item>

        <ion-item color="light" button detail="false" (click)="openSetting('device_group')" *ngIf="deviceGroups?.length > 1">
          <ion-icon src="assets/images/devices-list/filters/group.svg" slot="start"></ion-icon>
          <ion-label class="ion-text-capitalize ion-text-wrap">{{ 'devicesSetup.settingsList.addToAGroup' | translate }}</ion-label>
        </ion-item>

        <ion-item color="light" button detail="false" (click)="openSetting('device_tracking')">
            <ion-icon src="assets/images/devices-setup/tracking.svg" slot="start"></ion-icon>
            <ion-label>{{ 'devicesSetup.settingsList.liveTracking' | translate }}</ion-label>
        </ion-item>
    </ion-list>
  </div>
</ion-content>