import { MapComponent } from "../../map.component";
import { getSource } from "./../castMaplibreData";
import { FeatureInterface } from "./feature-interface";
import { Geometry, GeometryTypes } from "./../geometry";
import { IProperties } from "./../Iproperties";
import { svgToData } from "./../mapUtil";
import { MapAddressPickerComponent } from "src/app/components/map-address-picker/map-address-picker.component";
import { MainMapInterface } from "../../main-map.interface";
import { AuthenticationService } from "src/app/services/authentication.service";
import { inject } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { MapService } from "../../map.service";
import { AvailableHooks } from "../componentHooks";

export const SpeedCamerasImages = {
    20: 'assets/images/map/markers/speed-cameras/20.svg',
    30: 'assets/images/map/markers/speed-cameras/30.svg',
    40: 'assets/images/map/markers/speed-cameras/40.svg',
    50: 'assets/images/map/markers/speed-cameras/50.svg',
    60: 'assets/images/map/markers/speed-cameras/60.svg',
    70: 'assets/images/map/markers/speed-cameras/70.svg',
    80: 'assets/images/map/markers/speed-cameras/80.svg',
    90: 'assets/images/map/markers/speed-cameras/90.svg',
    100: 'assets/images/map/markers/speed-cameras/100.svg',
    110: 'assets/images/map/markers/speed-cameras/110.svg',
    120: 'assets/images/map/markers/speed-cameras/120.svg',
    130: 'assets/images/map/markers/speed-cameras/130.svg',
    none: 'assets/images/map/markers/speed-cameras/none.svg'
}

export class SpeedCameras implements FeatureInterface{
    layer = null;
    source = null;
    bounds = null;
    show = true;

    SOURCE_NAME = "SpeedCamerasSource";
    LAYER_NAME = "speedCameras";
    MIN_ZOOM = 9;
    requesting = null;
    obs: any = {};
    showButtonSetting = false;
    authService = inject(AuthenticationService);
    modalCtrl = inject(ModalController);
    mapService = inject(MapService);
    constructor (public main: MainMapInterface, ){
        this.init();
    }

    //_ Declare source and layer for the map
    init(){
        this.source = this.main.createDataSource(this.SOURCE_NAME);
        this.layer = this.main.createLayer(this.LAYER_NAME, this.SOURCE_NAME);
        if (this.main.appService){
            this.obs['userSettings'] = this.main.appService._userSettings$.subscribe( (settings: any) => {
                // console.log('Settings', settings);
                this.showButtonSetting = settings.show_speed_cameras_btn == 1 ? true : false;
                this.showHide(this.show && this.showButtonSetting);
            });
        }
    }

    //_ Load source and layer on the map
    load(){
        if (!this.main.map.getSource(this.SOURCE_NAME))
            this.main.map.addSource(this.SOURCE_NAME, this.source);

        if (!this.main.map.getLayer(this.LAYER_NAME))
            this.main.map.addLayer(this.layer, 'markers');

        this.show = this.mapService.showSpeedCameras.value && this.showButtonSetting;

        this.main.on(AvailableHooks.onSetSliderView, (e) => {
            this.setSliderView(e);
          });

        this.main.on(AvailableHooks.onDestroy, (e) => {
          this.destroy();
        });

        this.loadEvents();
    }

    loadImages(){
      //_ LOAD CAMERA IMAGES ONLY ONECE for use in the layer
      Object.keys(SpeedCamerasImages).forEach(async (k) => {
        const img = SpeedCamerasImages[k];
        if (img != "") {
          const imgData: any = await svgToData(img, 75, 75); //_ Ratio image w/h
          await this.main.map.loadImage(imgData, (error, image) => {
            const imageName = img.replace(/^.*[\\\/]/, "");
            if (!this.main.map.hasImage(imageName) && !error)
              this.main.map.addImage(imageName, image);
          });
        }
      });
    }

    //_ Handle move event
    loadEvents(){
        (this.main.map as any).off('moveend', this.moveEnd);
        (this.main.map as any).on('moveend', this.moveEnd);
    }

    //_ Wrapper to let unsucribe the map events
    moveEnd = (ev) => {
        if (this.requesting)
            this.requesting.unsubscribe();
        this.getData(ev)
    }

    getData(ev){
        //_ Only request camera data when zoom is up to 9
        if (this.main.map.getZoom() < this.MIN_ZOOM || !this.show) return false;
        this.bounds = this.main.map.getBounds();
        const bounds = { lat1: this.bounds._ne.lat, lng1: this.bounds._sw.lng, lat2: this.bounds._sw.lat, lng2: this.bounds._ne.lng};
        this.requesting = this.authService.getSpeedCameras(bounds).subscribe( (res: any) => {
            if (res.success){
                this.clearData();
                res.success = this.reduceArrayData(res.success, 100);
                this.addData(res.success);
            }
        });
    }

    clearData(){
        this.source = this.main.createDataSource(this.SOURCE_NAME);
        if (this.main.map.getSource(this.SOURCE_NAME))
            getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }

    reduceArrayData(array, maxItems){
        const shuffled = [...array].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, maxItems);
    }

    //_ Loop the data received and add one by one feature in the source|map
    addData(data){
        data.forEach(sc => {
            this.addSpeedCameraFeature(sc);
        })
        if (this.main.map.getSource(this.SOURCE_NAME))
            getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);

    }

    addSpeedCameraFeature(speedCamera){
        let geometry = new Geometry();
        let properties = {} as IProperties;
        geometry.type = GeometryTypes.Point;
        geometry.coordinates = [+speedCamera.lng, +speedCamera.lat];
        const speedImage = SpeedCamerasImages[speedCamera.speed];
        properties.icon = speedImage ? speedImage.replace(/^.*[\\\/]/, "" ) : 'none';
        properties.size = 0.6;
        properties["layerType"] = "speedCameras"; //Type of feature
        properties["speedCameraId"] = speedCamera.id;
        properties["show"] = this.show;
        properties['isSliderViewOpen'] = this.main.isSliderViewEnable;

        let marker = this.main._createFeature(
            geometry,
            properties,
            "speedCamera-" + speedCamera.id
        );

        // Adding the feature to the data Source if not exist...
        if (!this.source.data.features.find((f) => marker.properties['speedCameraId'] == f.properties.speedCameraId))
            this.source.data.features.push(marker);
    }

    showHide(status) {
        this.show = status;
        this.source.data.features.forEach((f) => {
          f.properties["show"] = status;
        });

        // And then update the source in the map :)
        if (this.main.map) {
          if (this.main.map.getSource(this.SOURCE_NAME))
            getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
        }
    }

    //_ Set status of every feature in the source
    toggleFeature(showToast = false){
        this.show = !this.show;
        this.mapService.showSpeedCameras.next(this.show);
        this.showHide(this.show);

        //_ Set zoom level when enable and map is not in the required zoom level
        if (this.show){
            this.getData(null);
            if (this.main.map.getZoom() < this.MIN_ZOOM)
                this.main.map.setZoom(this.MIN_ZOOM);
        } else { //_ Unload source when feature is off
            this.source.data.features = [];
            if (this.main.map.getSource(this.SOURCE_NAME))
                getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
        }

        if (this.show == true && showToast) {
            this.main.appService.showToast('', this.main._translate.instant('fabbuttonToast.showCameraOn'), 2000, 'success');
        }
        else if (showToast) {
            this.main.appService.showToast('', this.main._translate.instant('fabbuttonToast.showCameraOff'), 2000, 'success');
        }
    }

    async showCamerasIfEnabled(value){
        this.show = value;
        this.mapService.showSpeedCameras.next(this.show);
        this.showHide(this.show);

        //_ Set zoom level when enable and map is not in the required zoom level
        if (this.show){
            this.getData(null);
            if (this.main.map.getZoom() < this.MIN_ZOOM && this.main.heatMapClass?.isEnabled)
                this.main.map.setZoom(this.MIN_ZOOM);
        }
    }

    hideCameras()
    {
        this.show = false;
        this.mapService.showSpeedCameras.next(this.show);
        this.showHide(this.show);
    }

    destroy(){
        Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
    }

    addSpeedCamera(ev){
        if (ev) ev.stopPropagation();
        this.openAddressPicker();
    }

    async openAddressPicker() {
        const center = this.main.map.getCenter();
        // console.log('CENTER', center);
        const modal = await this.modalCtrl.create({
          component: MapAddressPickerComponent,
          componentProps: {
            coords: center,
            markerTitle: '',
            address: '',
          }
        });

        await modal.present();
        const { data } = await modal.onWillDismiss();

        //_ data = { address, coords }
        if (data)
            this.storeSpeedCamera(data);
    }

    storeSpeedCamera(center){

    }

    setSliderView(ev) {
        //_ Filter source
        this.source.data.features.forEach((f) => {
          f.properties["isSliderViewOpen"] = ev.isEnable;
        });

        //_ If slider is close then needs to filter the sliderData features
        if (!ev.isEnable) {
          this.source.data.features = this.main.filterFeaturesWithoutSliderProps(this.source.data.features);
          this.source.data.features = this.main.cleanSliderFeatureProps(this.source.data.features);
        }
      //_ Update source
        if (this.main.map.getSource(this.SOURCE_NAME))
          getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
      }
}
