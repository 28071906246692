<!-- <ion-header class="ion-no-border" [scrollHide]="{ cssProperty: 'height', minSize: 56 }" [scrollContent]="content"> -->

  <ion-header
  class="ion-no-border draggable-header"
  [ngClass]="{ 'no-ion-header': hideDeviceListToolbar }"
  #header
  Gesture-Detect
  [listenFor]="['pan']"
  (eventFired)="headerGestures($event)"
  mode="ios"
>


  <!-- <ion-buttons
    class="ion-hide-md-up"
    style="justify-content: center; height: 18px"
  >
    <ion-button color="light" class="toggle-btn" fill="clear" (click)="enablePullup()">
      <ion-icon [class.rotate]="pullupBreakpoint == 'top'" name='chevron-up-outline'></ion-icon>
    </ion-button>
  </ion-buttons> -->

  <!-- SEARCH BAR -->
  <ion-toolbar
    class="ion-no-padding ion-no-margin"
    color="dark"
    #searchToolbar
    *ngIf="!hideDeviceListToolbar"
    mode="ios"
  >
    <div class="search-bar" *ngIf="groups" @fadeIn>
      <ion-icon
        name="search-outline"
        class="search"
        color="primary"
        @zoomIn
      ></ion-icon>
      <ion-input
        #searchTerm
        [autocomplete]="'nope'"
        debounce="500"
        [value]="filters.qsearch"
        placeholder="{{ 'devicesSidebar.searchPlaceholder' | translate }}"
        [id]="!hideDeviceListToolbar ? 'devicelist-search' : ''"
        (ionBlur)="searchHasFocus = false"
        (ionFocus)="focusSearch()"
        (ionInput)="searchByTerm($event)"
        tabindex="-1"
        type="text"
      >
      </ion-input>

      <ion-button
        *ngIf="searchTerm.value != ''"
        (click)="clearSearch()"
        slot="end"
        fill="clear"
        shape="round"
        @fadeIn
        class="ion-no-padding clear-btn"
      >
        <ion-icon
          name="close-circle"
          slot="icon-only"
          size="small"
          color="light"
        ></ion-icon>
      </ion-button>

      <devices-list-submenu-button
        *ngIf="!hideDeviceListToolbar"
        @zoomIn
        [devicesList]="devicesList"
        [content]="content"
        [dragMenuActive]="true"
        [numberOfGroups]="numberOfGroups"
        (onDragActiveChanged)="onDragChanged($event)"
      >
      </devices-list-submenu-button>
    </div>
  </ion-toolbar>

  <ion-toolbar
    class="ion-no-margin ion-no-padding filter-bar"
    color="dark"
    mode="ios"
    *ngIf="!hideDeviceListToolbar"
  >
    <ion-button
      slot="start"
      size="small"
      color="light"
      class="filters-button ion-no-padding"
      [class.withFilters]="appliedFilters > 0"
      (click)="openFilters()"
      *ngIf="groups"
      @fadeIn
      id="home-button-filters"
    >
      <ion-badge color="primary" *ngIf="appliedFilters > 0" slot="end">{{
        appliedFilters
      }}</ion-badge>
      <ion-icon
        name="filter-outline"
        [slot]="appliedFilters > 0 ? 'start' : ''"
      ></ion-icon>
    </ion-button>

    <div class="fx-column">
      <app-select-list
        [selectTemplete]="selectGroupTemplete"
        [data]="groups"
        *ngIf="groups"
        @fadeIn
        [itemTemplate]="groupItemView"
        (onSelect)="selectGroup($event)"
        [selectedItem]="selectedGroup"
      >
        <ng-template #selectGroupTemplete let-selectedItem="selectedItem">
          <ion-button
            shape="round"
            color="light"
            id="home-group-dropdown"
            class="selected-group"
            size="small"
          >
            <ion-label *ngIf="!selectedItem?.key" slot="start">{{
              selectedItem?.name
            }}</ion-label>
            <ion-label *ngIf="selectedItem?.key" slot="start">{{
              selectedItem?.key | translate
            }}</ion-label>
            <ion-badge color="light" slot="end" *ngIf="selectedItem?.devices">{{
              selectedItem?.devices.length
            }}</ion-badge>
            <ion-icon name="chevron-down-outline" slot="end"></ion-icon>
          </ion-button>
        </ng-template>

        <ng-template #groupItemView let-item="item" let-index="index">
          <ion-item button detail="false" class="select-item-view">
            <ion-icon
              slot="start"
              [color]="'primary'"
              name="radio-button-on-outline"
              *ngIf="
                selectedGroup.devices_groups_group_id ==
                item.devices_groups_group_id
              "
            ></ion-icon>
            <ion-icon
              name="ellipse-outline"
              slot="start"
              *ngIf="
                selectedGroup.devices_groups_group_id !=
                item.devices_groups_group_id
              "
            ></ion-icon>
            <ion-label *ngIf="!item.key" class="ion-text-wrap">{{
              item.name
            }}</ion-label>
            <ion-label *ngIf="item.key" class="ion-text-wrap">{{
              item.key | translate
            }}</ion-label>
            <ion-badge slot="end" color="light" *ngIf="item.devices">{{
              item.devices.length
            }}</ion-badge>
          </ion-item>
        </ng-template>
      </app-select-list>
    </div>
  </ion-toolbar>
</ion-header>
<div
[ngClass]="{ 'no-filter': hideDeviceListToolbar }"
*ngIf="isPullup"
></div>

<!-- <ion-content #content HideHeader [header]="header" -->
  <!-- <ion-content
  class="content"
  #content
  [HideHeader]="header"
  toolbarHeight="62"
  [hideToolbar]="searchToolbar ? searchToolbar : ''"
  [class.no-scroll]="!enableScroll"
  (topReached)="topReached($event)"
  (ionScroll)="ScrollingContent($event)"
  (ionScrollEnd)="scrollEnd()"
  [isEnabled]="
    !(devicesList?.dragActive || devicesList?.dragGroups) && enableScroll
  "
  [threshold]="68"
> -->
  
<ion-content
  class="content"
  #content
  [HideHeader]="header"
  toolbarHeight="62"
  [hideToolbar]="searchToolbar ? searchToolbar : ''"
  [class.no-scroll]="!enableScroll"
  (topReached)="topReached($event)"
  (onScrollStarts)="onScrollStarts($event)"
  (ionScroll)="ScrollingContent($event)"
  (ionScrollEnd)="scrollEnd()"
  [isEnabled]="
    !(devicesList?.dragActive || devicesList?.dragGroups) && enableScroll
  "
  [threshold]="68"
  [style.--offset-content-margin]="!hideDeviceListToolbar ? '135px' : '10px'"
  [style.--offset-content-margin-drag-enabled]="
    devicesList?.dragActive || devicesList?.dragGroups ? '10px' : '135px'"
  [hasDragableHeader]="true"
>
  <div 
    style="height: 100%; overflow: auto;"
    [ngStyle]="{ display: this.isSmallScreen && 'contents'}"
  >
    <!-- <ion-content #content HideHeaderFixed [header]="header"> -->
    <div class="fx-column" id="devicesList">
      <!-- <app-fab-list *ngIf="appliedFilters == 0" @fadeIn></app-fab-list> -->
      <!-- <mat-divider></mat-divider> -->
      <app-devices-list-new
        #devicesList
        [enableRearrangeDevices]="dragMenuActive"
        [hideGroupName]="hideDeviceListToolbar"
      ></app-devices-list-new>
    </div>
  </div>
</ion-content>
