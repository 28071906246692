import { Injectable, Input, NgZone } from "@angular/core";
import { BehaviorSubject, first } from "rxjs";
import { CarsService } from "src/app/members/logbook/devices/cars.service";
import { STORAGE_SETTINGS, StorageSetting } from "src/app/services/storage-settings";
import { StorageService } from "src/app/services/storage.service";

@Injectable({
  providedIn: 'root'
})

export class DevicesDashboardService {

  dashboardSidebarIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  requestLastRoutesDone: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //_ Variables to handle mobile open activity view instead handled by modal
  // public _openInMobiles = new BehaviorSubject<boolean>(false);
  openInMobiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldLoadMobileView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private storage: StorageService, private carsService: CarsService) {

  }

  async filterLogbookDevices (devices): Promise<[]> {
    return devices;
    //_ uncomment this in case need to filter again for logbook vehicles
    return new Promise ((resolve) => {
      const filter = (cars) => {
        const filtered = devices.filter(d => cars.some(c => c.device.id == d.id))
        resolve(filtered);
      }

      if (this.carsService.cars.length > 0)
        filter(this.carsService.cars);
      else{
        this.carsService.cars$.pipe(first()).subscribe(res => {
          filter(res)
        })
        this.carsService.loadCars();
      }
    })
  }

}
