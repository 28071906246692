import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StorageSetting, STORAGE_SETTINGS } from 'src/app/services/storage-settings';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public drawer;
  public deviceMarkers;
  //Events for GeoZones:
  //Add GeoZone
  private _addGeozone = new Subject<{}>();
  addGeozone$ = this._addGeozone.asObservable();

  //Select GeoZone
  private _selectGeozone = new Subject<{}>();
  selectGeozone$ = this._selectGeozone.asObservable();

  geofenceUpdated = new Subject();
  SHOCK_ALERT_MODELS = [9048]; //_ SHOCK ALERT MODELS
  DONT_ASK_AGAIN_SHOCK_ALERT_POPUP = new StorageSetting(this.storage, STORAGE_SETTINGS.ALERTS_NOT_SHOW_SHOCK_ALERT_POPUP, false);
  DONT_REMIND_AGAIN_SHOCK_ALERT_POPUP = new StorageSetting(this.storage, STORAGE_SETTINGS.ALERTS_NOT_SHOW_SHOCK_ALERT_INFO_POPUP, false);
  DONT_REMIND_AGAIN_REMOVAL_ALERT_POPUP = new StorageSetting(this.storage, STORAGE_SETTINGS.ALERTS_NOT_SHOW_REMOVAL_ALERT_INFO_POPUP, false);
  constructor(private authService: AuthenticationService, private storage: StorageService) { }

  selectGeoZone(data) { //Trigger event
    this._selectGeozone.next(data);
  }

  addGeoZone(data) { //Trigger event
    this._addGeozone.next(data);
  }

  updateGeofence(feature) {
    let type = 0, portColor;
    let dID = 0;
    dID = feature.id.split('_');
    switch (feature.geometry.type) {
      case 'Polygon':
        if (feature.properties.isCircle) {
          type = 1;
        } else {
          type = 3;
        }
        break;
      case 'Circle':
        type = 1;
        break;
      default:
        break;
    }
    let options = '{}';
    if (feature.properties.portColor) {
      portColor = feature.properties.portColor;
    }
    if (type === 1) {
      const centerArray = feature.properties.center;
      let centerCoordinates = '[';
      centerCoordinates = centerCoordinates.concat(centerArray.toString());
      centerCoordinates = centerCoordinates.concat(']');
      options = '{"color":"' + portColor +
        '","fillColor":"' + portColor + '","radius":"' + (feature.properties.radiusInKm * 1000) +
        '","center":"' + centerCoordinates + '","opacity": ' + feature.properties.opacity + '}';
      dID = feature.id.split('_');
      // this.authService.updateRadiusInfo(+ dID[1],
      //   feature.properties.radiusInKm * 1000,
      //   centerArray[1],
      //   centerArray[0]);
    } else {
      options = '{"color":"' + portColor +
        '","fillColor":"' + portColor + '","opacity": ' + feature.properties.opacity + '}';
    }


    return new Promise((resolve, reject) => {
      this.authService.updateGeofenceColor(dID[0], dID[1], options).then(res => {
        if (res) {
          if (res.success)
            resolve(res.success)
          else
            reject('Error');
        }
      }).catch(error => reject(error));
    })

  }
}
