import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { BackcloseDirectiveModule } from 'src/app/directives/backclose-directive.module';
import { ApiErrorPopupComponent } from './api-error-popup.component';
import { PajPopupTemplateModule } from '../paj-popup-template/paj-popup-template.module';


@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      TranslateModule,
      BackcloseDirectiveModule,
      PajPopupTemplateModule
    ],

    declarations: [ApiErrorPopupComponent],
    exports: [ApiErrorPopupComponent, PajPopupTemplateModule],
    
  })

  
  export class ApiErrorPopupModule {}