<!-- SLIDER BAR TO SEE THE DATAPOINTS LIKE A TIMELINE -->
<ion-card class="container-card" mode="ios" [class.margin-up]="!deviceMenuOpen">
  <!-- <div class="generating-animation ion-text-center" [hidden]="generatingAnimation === true">
    <ion-item mode="md" lines="none">


    </ion-item>
  </div> -->

  <div>
    <ng-template #SliderButtons>
      <ion-buttons class="grouped-buttons" mode="md"  [@transitionAll] >
          <ion-button mode="md"  [color]="enablePopup?'primary':'dark'" fill="solid" (click)="showPopup()" title="{{ 'map.slider.showPopupTooltip' | translate }}" *ngIf="controls.popup">
              <ion-icon [class.disabled]="!enablePopup" name="chatbox-outline"></ion-icon>
          </ion-button>
          <!-- <ion-button mode="md"  color="dark" fill="solid" (click)="cameraOptions($event)" title="{{ 'map.slider.showPopupTooltip' | translate }}" *ngIf="controls.popup">
            <ion-icon [src]="selectedCamera.icon"></ion-icon>
          </ion-button> -->
          <ion-button mode="md" @fadeInOut color="dark" fill="solid" (click)="setDuration()" *ngIf="controls.play">
              <b>{{durations[currentDurationIndex].title }}</b>
          </ion-button>
          <ion-button mode="md"  color="dark" fill="solid" (click)="back()" title="{{ 'map.slider.previousTooltip' | translate }}" *ngIf="controls.prev" class=" right-btn">
              <ion-icon name="play-skip-back-outline"></ion-icon>
          </ion-button>
          <ion-button  mode="md" [color]="playing?'primary':'dark'" class="play" fill="solid" (click)="play()" title="{{ 'map.slider.playPauseTooltip' | translate }}" *ngIf="controls.play" [disabled]="generatingAnimation === true || isManualRoute === true || disablePlay === true">
              <ion-icon [class.blink]="playing" [name]="playing ? 'pause-outline':'play-outline'"></ion-icon>
          </ion-button>
          <ion-button mode="md"  color="dark" fill="solid" (click)="next()" title="{{ 'map.slider.nextTooltip' | translate }}" *ngIf="controls.next">
              <ion-icon name="play-skip-forward-outline"></ion-icon>
          </ion-button>

          <ion-button mode="md"  fill="solid" color="dark"  title="{{ 'map.slider.close' | translate }}" (click)="hideSlider()" *ngIf="controls.close">
              <ion-icon name="close-outline"></ion-icon>
          </ion-button>

      </ion-buttons>
  </ng-template>

  <ng-template #kmHeader>
    <div slot="start" class="badge-wrapper">
        <ion-label class="ion-text-center generating-animation" @fadeInOut *ngIf="generatingAnimation || loadingSnapRoute">
          <span class="ion-text-wrap" *ngIf="loadingSnapRoute">{{ 'map.slider.optimizingRoutes' | translate }}</span>
          <span class="ion-text-wrap" *ngIf="generatingAnimation">{{ 'map.slider.generatingAnimation' | translate }}</span>
          <ion-spinner color="primary" size="xs"></ion-spinner>
        </ion-label>
        <ion-badge color="light">
            <ion-text>
                {{ sliderDevice?.dataPoints[stepSlider]?.dateunix | translateUnixDate: "DD.MM.YYYY HH:mm" }}
            </ion-text>
        </ion-badge>

        <ion-badge color="light">
            <ion-text>
                {{ sliderDevice?.dataPoints[stepSlider]?.speed | KmtoMiles: "1.0-0" }}/h
            </ion-text>
        </ion-badge>
        <ion-badge color="light">
            <ion-text>
                {{ sliderDevice?.dataPoints[stepSlider]?.distance | KmtoMiles: "1.1-2" }}
            </ion-text>
        </ion-badge>
    </div>
  </ng-template>



  <div class="icon_slider" [ngStyle]="{
    display: showSlider ? 'block' : 'none'
  }">
      <ng-container *ngIf="isMobile">
          <ng-container [ngTemplateOutlet]="kmHeader"></ng-container>
      </ng-container>


      <div class="relative">
          <div class="canvas-container">
              <div class="axis-y"></div>
              <canvas #chartCanvas></canvas>

          </div>
          <div class="range">
              <ion-range aria-label="slider" [min]="0" [max]="maxSliderPoints" step="1" mode="ios" pin="false" [(ngModel)]="stepSlider" (ionInput)="onSliderChanged($event)" (ionChange)="onRangeChange($event)" debounce="200">
                  <!-- <ion-icon size="large" color="primary" slot="start" name="none"></ion-icon> -->
                  <!-- dont remove this icon. it add the margin left properly -->
              </ion-range>

          </div>
      </div>
      <div class="flex-container">
          <ng-container *ngIf="!isMobile">
              <ng-container [ngTemplateOutlet]="kmHeader"></ng-container>
          </ng-container>
          <ng-container>
              <ng-container [ngTemplateOutlet]="SliderButtons"></ng-container>
          </ng-container>
      </div>

    </div>
  </div>


</ion-card>
