<paj-popup-template [headerTitle]="title" [contentTemplate]="Content">

</paj-popup-template>

<ng-template #Content>
  <ion-grid fixed class="ion-padding">
    <ion-row>
      <ion-col size="12" [innerHtml]="message" class="ion-text-center">
      <!-- <p>
        {{ "noDataPopUp.nodataContent" | translate }}
      </p> -->
      </ion-col>
      <ion-col size="12" class="ion-text-end">
        <ion-button
        (click)="onCancel()"
        color="light"
        shape="round"
      >
        OK
      </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>