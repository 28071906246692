import { notificationsMarkers } from "src/app/members/notifications/notificationsUtil";
import { MapComponent } from "../../map.component";
import { getSource } from "../castMaplibreData";
import { AvailableHooks } from "../componentHooks";
import { Data, dataSourceType, dataTypes } from "../data";
import { DataSource } from "../dataSource";
import { Geometry, GeometryTypes } from "../geometry";
import { IProperties } from "./../Iproperties";
import { Layer, layerType } from "../layer";
import { addMapImage, getCssStyleVariable, getDistance, svgToData } from "../mapUtil";
import { FeatureInterface } from "./feature-interface";
import { featureCollection, lineString, point } from "@turf/turf";
import { MainMapInterface } from "../../main-map.interface";
import { MapService } from "../../map.service";
import { inject } from "@angular/core";
import { DeviceService } from "src/app/public/stand-alone-page/device.service";
import { DeviceDataService } from "src/app/members/map/devicesData.service";

export class AlarmMarkers implements FeatureInterface {
  SOURCE_NAME: string = 'alarmsSource';
  LAYER_NAME: string = 'alarms'
  LINES_LAYER_NAME: string = 'alarms_lines';
  LINES_LAYER_SOURCE: string = 'alarms_linesSource'
  CIRCLES_LAYER: string = 'alarms_circle'


  source: DataSource = null;
  linesSource: DataSource = null;
  layer = null;
  linesLayer = null;
  images = notificationsMarkers;
  clusterAlarmBackground = null;
  clusterAlarmCount = null;
  clusterAlarmImage = null;
  defaultClusterMarkerImage = 'assets/images/map/markers/default-alarm-marker.svg';
  defaultClusterImageName = 'default-alarm-marker';
  circlesLayer = null;
  primaryColor = '#FF4E00';
  public show = true;
  mapService = inject(MapService)
  deviceService = inject(DeviceDataService)
  obs: any = {};

  constructor(public main: MainMapInterface) {
    this.init();
  }

  init() {
    this.primaryColor = getCssStyleVariable('--ion-color-primary');
    this.source = this.createDataSource();
    this.linesSource = this.createLinesSource();
    this.circlesLayer = this.createLayer(this.CIRCLES_LAYER);
    this.linesLayer = this.createLayer(this.LINES_LAYER_NAME); // its a  new layer added to the bottom of the alarms to show the alarm point
    this.clusterAlarmBackground = this.createLayer('clusterAlarmBackground');
    this.clusterAlarmCount = this.createLayer('clusterAlarmCount');
    this.clusterAlarmImage = this.createLayer('clusterAlarmImage');
    this.layer = this.createLayer(this.LAYER_NAME);
    this.load();
  }

  createDataSource() {
    let source = <DataSource>{};
    source.data = <Data>{};
    source.type = dataSourceType.geojson;
    source.data.features = [];
    source.data.type = dataTypes.FeatureCollection;
    source["generateId"] = true;

    source['cluster'] = true;
    source['clusterMaxZoom'] = 14;
    source['clusterRadius'] = 50;
    source['filter'] = [
      "all",
      ["==", ['get', "layerType"], "alarms"], // Select only markers features from the dataSource
      ["==", ['get', "deviceShow"], 1], // Show only if deviceShow == 1
      ["==", ['get', "showAlarms"], true], //_ Flag from user settings
      [
        'any',
        ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
        ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
      ],
      [
        'any',
        ['==', ['get', 'isMonitorModeEnable'], false], // OR condition: isSliderViewOpen == false
        ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
      ]
    ];
    return source;
  }

  createLinesSource() {
    let nsource: DataSource = <DataSource>{};
    nsource.data = <Data>{};
    nsource.type = dataSourceType.geojson;
    nsource.data.features = [];
    nsource.data.type = dataTypes.FeatureCollection;
    nsource["generateId"] = true;
    return nsource;
  }

  createLayer(name) {
    let layer = new Layer(name);
    layer.source = this.SOURCE_NAME;
    layer.type = layerType.symbol;

    if (name == this.LAYER_NAME) {
      layer.layout["icon-image"] = ["get", "icon"];
      layer.layout["icon-size"] = ["get", "size"];
      layer.layout["icon-anchor"] = "bottom";
      layer["paint"]["icon-color"] = ["get", "#ff0000"];
      layer.layout["icon-allow-overlap"] = true;
      layer.layout["icon-rotation-alignment"] = "viewport"; //could be 'viewport' ; used to rotate the icon with the viewport or the map ...
      //layer['slot'] = 'top';

      layer.maxzoom = 20;
      layer.minzoom = 4;
      layer['filter'] = ['!', ['has', 'point_count']];
    }

    if (name == 'clusterAlarmImage') {
      layer.layout["text-anchor"] = "top";
      layer.layout["icon-anchor"] = "bottom";
      layer.layout["text-field"] = ["get", "title"];
      layer.layout["text-offset"] = [0, 2]; //2
      layer.layout["icon-allow-overlap"] = true;
      layer.layout["text-allow-overlap"] = true;
      layer.layout["icon-rotation-alignment"] = "viewport";
      //layer['slot'] = 'top';

      layer.layout["icon-size"] = 0.6;
      layer.layout["icon-image"] = this.defaultClusterImageName;

      layer['filter'] = ['has', 'point_count'];
    }

    if (name == 'clusterAlarmBackground') {
      layer.type = layerType.symbol;
      layer.layout["icon-image"] = 'direction-marker-wifi';
      layer["paint"]["icon-color"] = this.primaryColor;
      // layer.paint["icon-halo-width"] = 0.5;
      // layer.paint["icon-halo-color"] = "#fff";
      layer.paint['icon-translate'] = [-25, -50];
      layer.paint['icon-translate-anchor'] = 'viewport';
      layer.layout["icon-allow-overlap"] = true;
      // layer.layout["text-allow-overlap"] = true;
      layer.layout["icon-size"] = 1;
      //layer['slot'] = 'top';

      layer['filter'] = ['has', 'point_count'];
    }

    if (name == 'clusterAlarmCount') {
      layer.paint['text-translate'] = [-25, -50];
      layer.paint['text-color'] = '#ffffff';
      layer.layout["text-field"] = '{point_count_abbreviated}';
      layer.layout["text-size"] = 13;
      layer.layout["text-allow-overlap"] = true;
      layer.paint['text-translate-anchor'] = 'viewport';
      // layer.paint['text-rotation-alignment'] = 'viewport';
      //layer['slot'] = 'top';

      layer['filter'] = ['has', 'point_count'];
    }

    if (name === this.LINES_LAYER_NAME) {
      layer.type = layerType.line;
      layer.source = this.LINES_LAYER_SOURCE;
      layer.layout["line-join"] = 'round';
      layer.layout["line-cap"] = 'round';
      layer.paint["line-color"] = this.primaryColor;
      layer.paint["line-width"] = 2;
    }
    if (name == this.CIRCLES_LAYER) {
      layer.type = layerType.symbol;
      layer.layout["icon-image"] = ["get", "icon-image"];
      layer.layout["icon-rotate"] = ["get", "rotate"];
      layer["paint"]["icon-color"] = ["get", "color"];
      layer.paint["icon-halo-color"] = "#fff";
      layer.layout["icon-allow-overlap"] = true;
      layer.paint["icon-halo-width"] = 1.5;
      layer.layout["icon-size"] = 0.5;
      layer.layout["icon-rotation-alignment"] = "map";

      //layer['slot'] = 'top';

      layer["filter"] = [
        "all",
        ['!', ['has', 'point_count']], // Select only markers features from the dataSource
      ];
    }

    return layer;
  }

  mouseEvents() {
    const tc = this.main;
    (this.main.map as any).on("mouseenter", this.LAYER_NAME, function () {
      tc.map.getCanvas().style.cursor = "pointer";
    });
    (this.main.map as any).on("mouseleave", this.LAYER_NAME, function () {
      tc.map.getCanvas().style.cursor = "default";
    });
  }

  load() {
    this.main.on(AvailableHooks.onLoadImages, async (e) => {
      addMapImage(this.defaultClusterMarkerImage, this.main.map, this.defaultClusterImageName, 91.5, 75);
      for (let i = 0; i < this.images.length; i++) {
        const img = this.images[i];
        const imageName = img.image.split('/').pop();
        await addMapImage(img.image, this.main.map, imageName, 91.5, 75);
      }
    });

    this.main.on(AvailableHooks.onLoadLayer, (e) => {

      if (!this.main.map.getLayer('clusterAlarmCount')){
        //this.main.map.addLayer(this.clusterAlarmCount, 'markers');
        this.main.map.addLayer(this.clusterAlarmCount, 'markers'); //_ Below of the cluster count of the pauses
      }

      if (!this.main.map.getLayer('clusterAlarmBackground'))
        this.main.map.addLayer(this.clusterAlarmBackground, 'clusterAlarmCount');
      if (!this.main.map.getLayer('clusterAlarmImage'))
        this.main.map.addLayer(this.clusterAlarmImage, 'clusterAlarmBackground');
      if (!this.main.map.getLayer(this.LAYER_NAME))
        this.main.map.addLayer(this.layer, 'clusterAlarmBackground');
      // if (!this.main.map.getLayer(this.LINES_LAYER_NAME))
      //   this.main.map.addLayer(this.linesLayer, 'markers');
      if (!this.main.map.getLayer(this.CIRCLES_LAYER))
        this.main.map.addLayer(this.circlesLayer, this.LAYER_NAME); //_ Show circle behind symbolMarkers layer
     });

    this.main.on(AvailableHooks.onLoadSource, (e) => {
      if (!this.main.map.getSource(this.SOURCE_NAME))
        this.main.map.addSource(this.SOURCE_NAME, this.source);
      // if (!this.main.map.getSource(this.LINES_LAYER_SOURCE))
      //   this.main.map.addSource(this.LINES_LAYER_SOURCE, this.linesSource);
    });

    this.main.on(AvailableHooks.onSetSliderView, (e) => {
      this.setSliderView(e);
    });

    this.obs['mapReady'] = this.main.mapReady.subscribe(r => {
      if (r) {
        this.mouseEvents();
        //this.lisentForMapZoom();
      }
    })

    this.obs['monitorMode'] = this.mapService.mapMonitorMode.subscribe(res => {
      this.updateMonitorMode(res);
    })

    this.main.on(AvailableHooks.onDestroy, (e) => {
      this.destroy();
    });
  }

  //____________________ HANDLING MARKER OVERLAPING ________________________
  lisentForMapZoom() {
    // Define marker collision rules
    (this.main.map as any).on('zoom', () => {
      const zoom = this.main.map.getZoom();
      if (zoom > 15) {
        let center: any = this.main.map.getCenter();
        center = this.main.map.project(center);
        const renderedFeatures = this.getQueryRenderfeatureOfViewPort();
        const alarmMarkers = renderedFeatures.filter(f => f.layer.id === this.LAYER_NAME);
        const deviceMarkers = renderedFeatures.filter(f => f.layer.id === 'markers');

        // console.log('MARKERS FOR COLLISION', { renderedFeatures, alarmMarkers, deviceMarkers })
        // Check for collisions and adjust positions as needed
        this.handleMarkerCollisions(alarmMarkers, deviceMarkers);
      } else {
        this.resetToOriginalPosition();
        this.updateLinesSource();
      }
    });
  }

  getQueryRenderfeatureOfViewPort(): Array<any> {
    return this.main.map.queryRenderedFeatures(null, { layers: [this.LAYER_NAME, 'markers'] });
  }

  handleMarkerCollisions(alarmMarkers, deviceMarkers) {
    let shouldUpdateLines = false;
    const COLLISION_THRESHOLD = 0.01;
    alarmMarkers.forEach(alarmMarker => {
      const alarmPoint = alarmMarker.geometry.coordinates;
      const collided = deviceMarkers.some(deviceMarker => {
        const dMarkerPoint = deviceMarker.geometry.coordinates;
        const distance = getDistance(alarmPoint[0], alarmPoint[1], dMarkerPoint[0], dMarkerPoint[1], "k");
        // console.log('DISTANCE BETWEEN', distance)
        if (distance < COLLISION_THRESHOLD) {
          this.moveAlarmMarker(alarmMarker, this.calculateOffset(alarmMarker, deviceMarker));
          shouldUpdateLines = true;
          return true;
        }
        return false;
      });

      //if (!collided) this.resetToOriginalPosition(alarmMarker);
    });

    if (shouldUpdateLines) {
      this.updateLinesSource();
    }
  }

  moveAlarmMarker(alarmMarker: any, offset: [number, number]) {
    const alarmsSourceData = this.source.data;
    let shouldUpdate = false;
    alarmsSourceData.features.forEach((f: any) => {
      if (f.properties.alarmId == alarmMarker.properties.alarmId && !f.properties.originalPosition) {
        f.properties.originalPosition = JSON.parse(JSON.stringify(f.geometry.coordinates));
        const newLng = f.geometry.coordinates[0] + offset[0];
        const newLat = f.geometry.coordinates[1] + offset[1];
        f.geometry.coordinates = [newLng, newLat];
        shouldUpdate = true;
      }
    })

    if (shouldUpdate) {
      this.source.data = alarmsSourceData;
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
  }

  resetToOriginalPosition() {
    const alarmsSourceData = this.source.data;
    let shouldUpdate = false;
    alarmsSourceData.features.forEach(f => {
      if (f.properties.originalPosition) { f.geometry.coordinates = JSON.parse(JSON.stringify(f.properties.originalPosition));
        delete f.properties.originalPosition;
        shouldUpdate = true;
      }
    })

    if (shouldUpdate) {
      this.source.data = alarmsSourceData;
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
  }

  calculateOffset(marker: any, overlappingMarker: any): [number, number] {
    const markerSize = 0.0004; // Modify this value based on the size of your markers

    // Calculate the distance between the markers
    const deltaX = overlappingMarker.geometry.coordinates[0] - marker.geometry.coordinates[0];
    const deltaY = overlappingMarker.geometry.coordinates[1] - marker.geometry.coordinates[1];
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    // Calculate the angle between the markers
    const angle = Math.atan2(deltaY, deltaX);

    // Calculate the offset to move the marker away from the overlapping marker
    const offsetX = Math.cos(angle) * (markerSize - distance);
    const offsetY = Math.sin(angle) * (markerSize - distance);

    // return [0.001, 0.001]
    return [offsetX, offsetY];
  }


  updateLinesSource() {
    const lineStrings = [];

    this.source.data.features.forEach((feature) => {
      if (feature.properties.originalPosition) {
        const originalPos = feature.properties.originalPosition;
        const coordinates = feature.geometry.coordinates;

        const start = point(originalPos);
        const end = point(coordinates);

        const lineStringFeature = lineString([start.geometry.coordinates, end.geometry.coordinates]);

        lineStrings.push(lineStringFeature);
      }
    });

    const lineStringsCollection = featureCollection(lineStrings);
    // Update the data of your linesSource with the new FeatureCollection
    getSource(this.main.map, this.LINES_LAYER_SOURCE).setData(lineStringsCollection);
  }
  //_______________________________________________________________

  updateDeviceData(device, show) {
    this.source.data.features.forEach((f) => {
      if (f.properties["deviceId"] == device.id)
        f.properties["deviceShow"] = show ? 1 : 0;
    });

    if (this.main.map?.getSource(this.SOURCE_NAME))
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
  }



  _addAlarms(device, removeAll = false, layerOptions = {}) {
    if (removeAll && this.source)
      this.source.data.features = this.source.data.features.filter(
        (data) => data.properties["deviceId"] != device.id
      );

    //this.dataSource.data.features = this.dataSource.data.features.filter(function( f ) {
    //  return !(f.properties['layerType'] == 'pause' && f.properties['deviceId'] == device.id);
    //});

    device.notifications.forEach(async (not, i) => {
      let geometry = new Geometry();
      let properties = {} as IProperties;
      geometry.type = GeometryTypes.Point;
      geometry.coordinates = [not.lng, not.lat];
      properties.icon = this.images.find(img => img.id == not.meldungtyp).image.replace(
        /^.*[\\\/]/,
        ""
      );
      properties.size = 0.6;
      properties["deviceShow"] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the pause marker
      properties["layerType"] = "alarms"; //Type of feature
      properties["alarmId"] = not.id;
      properties["deviceId"] = device.id;
      properties["showAlarms"] = this.show;
      properties["isSliderViewOpen"] = this.main.isSliderViewEnable;
      properties['isMonitorModeEnable'] = this.mapService.mapMonitorMode.value;

      properties['color'] = device.properties.spurfarbe;
      let direction = device.dataPoints.find((datapoint) => {
        return datapoint.dateunix == not.dateunix;
      })?.direction;
      if (direction) {
        properties["rotate"] = direction;
      }
      properties["icon-image"] = direction ? 'direction-marker' : 'direction-marker-wifi';

      //_ Add custom layer|propertie options
      Object.keys(layerOptions).forEach(key => {
        properties[key] = layerOptions[key];
      })

      let notMarker = this.main._createFeature(
        geometry,
        properties,
        "alarm-" + not.id
      );

      // Adding the feature to the data Source if not exist...
      if (!this.source.data.features.find((f) => notMarker.properties['alarmId'] == f.properties['alarmId']))
        this.source.data.features.push(notMarker);

      // if (not) this.findSimilarData(not.lng, not.lat)
    });

    if (this.main.map?.getSource(this.SOURCE_NAME))
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);

  }


  showHideAlarms(show) {
    this.show = show;
    this.source.data.features.forEach((f) => {
      f.properties["showAlarms"] = show;
    });

    // And then update the source in the map :)
    if (this.main.map) {
      if (this.main.map.getSource(this.SOURCE_NAME))
        getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
  }

  async hideAlarms() {
    this.show = false;
    this.mapService.showNotifications.next(this.show);
  }

  async showAlarmsIfEnabled() {
    let value = await this.main.storage.get('showNotifications');
    this.show = value ? value : false;
    this.mapService.showNotifications.next(this.show);
  }

  async toggleAlarms(ev = null, showToast = true) {
    if (ev) {
      ev.stopPropagation();
      ev.preventDefault();
    }
    this.show = !this.show;
    this.mapService.showNotifications.next(this.show);
    if (this.show == true && showToast) {
      this.main.appService.showToast('', this.main._translate.instant('fabbuttonToast.showAlertOn'), 2000, 'success');
    }
    else if (showToast) {
      this.main.appService.showToast('', this.main._translate.instant('fabbuttonToast.showAlertOff'), 2000, 'success');
    }
  }

  clearData() {
    this.source = this.createDataSource();
    try {
      getSource(this.main.map, "alarmsSource").setData(this.source.data);
    } catch { }
  }

  setSliderView(ev) {
    //_ Filter source
    this.source.data.features.forEach((f) => {
      f.properties["isSliderViewOpen"] = ev.isEnable;
    });

    //_ If slider is close then needs to filter the sliderData features
    if (!ev.isEnable) {
      this.source.data.features = this.main.filterFeaturesWithoutSliderProps(this.source.data.features);
      this.source.data.features = this.main.cleanSliderFeatureProps(this.source.data.features);
      this.source.data.features = this.source.data.features.filter(f => !this.mapService.mapMonitorMode.value);
    }

    //_ Update source
    if (this.main.map.getSource(this.SOURCE_NAME))
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
  }

  updateMonitorMode (isEnable) {
    this.source.data.features = this.source.data.features.filter(f => !this.mapService?.mapMonitorMode.value);
    //_ Update source
    if (this.main.map?.getSource(this.SOURCE_NAME))
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
  }

  destroy () {
    Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
  }

}
