import { Injectable } from '@angular/core';
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { AuthenticationService } from './authentication.service';

import { CsPopoverComponent } from '../components/cspopover/cspopover.component';
import { PopoverController } from '@ionic/angular';
import { StorageService as Storage } from './storage.service';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AppService } from '../app.service';
import {
  BehaviorSubject,
  first,
  firstValueFrom,
  lastValueFrom,
  map,
  of,
  Subject,
} from 'rxjs';
import { Constants } from '../constants.enum';
import { translationsKeys } from '../members/notifications/notificationsUtil';
import { MessagingService } from './messaging.service';
import { GlobalEventService } from 'src/app/services/globa-event.service';
import { environment as ENV } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FcmService } from './plugins/fcm.service';
import { Capacitor } from '@capacitor/core';
import { StartupService } from './startup.service';
import { Router } from '@angular/router';
import { UserActivityService } from './user-activity.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  registerToken = new Event('registerToken');
  _notificationCount = null;
  notifications = new BehaviorSubject<[]>([]);
  changeUnreadNots = new BehaviorSubject(0);
  notifyChanged = new Subject();
  counterChanged = new BehaviorSubject(0);
  notifyNew = new Subject();

  settingsDeleteAll = new Subject(); //_ Used in notifications settings
  settingsMarkAll = new Subject(); //_ Used in notifications settings
  language = 'en';
  timerUpdate = null;
  lastId = 0;
  cancelRequest = new Subject();
  viewCheck = '';
  isMobileApp = false;
  deletedNots: Array<number> = [];
  readNots: Array<number> = [];
  unreadNots: Array<any> = [];

  //_ Used to send to all requests | for now used for sharelink
  extraProps = {};
  isInitialized = false;
  lastTimeRequest = null;
  constructor(
    private platform: Platform,
    private authService: AuthenticationService,
    private popoverCtrl: PopoverController,
    private storage: Storage,
    private api: ApiService,
    private translate: TranslateService,
    private appService: AppService,
    private toast: ToastController,
    private messagingService: MessagingService,
    private globalEventService: GlobalEventService,
    private http: HttpClient,
    private fcmService: FcmService,
    private startupService: StartupService,
    private router: Router,
    private userActivity: UserActivityService,
    // private fcm: FCM
  ) {
    this.isMobileApp =
      Capacitor.isNativePlatform() &&
      (Capacitor.getPlatform() == 'ios' ||
        Capacitor.getPlatform() == 'android');
    //_ Load notifications when appService change user observable
    this.appService.user$.subscribe((user: any) => {
      if (
        !this.router.url.includes('stand-alone-page') &&
        !this.router.url.includes('/share')
      )
        this.initialize();
      // if (window['PushMessageReceived']) {
      //   window['PushMessageReceived'].subscribe((data)=>{
      //     console.log('PWA BG Message ',data)
      //   })
      // }
    });

    this.appService.language$.subscribe((language) => {
      setTimeout(() => {
        // console.log('DEBUG - notif', { thisLanguage: this.language, language, isInitialized: this.isInitialized })
        if (this.isInitialized && this.language != language) {
          // console.log('DEBUG - trigger loadFromserver');
          this.language = language;
          this.translate.use(language);
          moment.locale(language);
          this.loadFromServer(this.viewCheck);
        }
      }, 1000);
    });
  }

  startReceivingNotification() {
    this.lastId = 0;
    if (this.timerUpdate) {
      this.stopReceivingNotification();
      this.timerUpdate = undefined;
    }

    this.loadFromServer(this.viewCheck);
    this.timerUpdate = setInterval((x) => {
      if (
        this.userActivity.platform.isDesktopBrowser() &&
        this.userActivity.getAppInactiveTime() <
          this.userActivity.TIME_TO_PAUSE_ACTIVITY
      ) {
        this.loadFromServer(this.viewCheck);
      } else if (!this.userActivity.platform.isDesktopBrowser()) {
        this.loadFromServer(this.viewCheck);
      }
    }, 30000);
  }

  stopReceivingNotification() {
    //_ Stop previous request
    if (this.obsLoadingNotifications)
      this.obsLoadingNotifications.unsubscribe();
    clearInterval(this.timerUpdate);
    this.lastId = 0;
  }

  async initialize(viewCheck = null, props = {}) {
    this.viewCheck = viewCheck;
    this.extraProps = props;
    this.isInitialized = true;

    this.language = await this.storage.get(Constants.LANGUAGE);
    moment.locale(this.language);

    this.startReceivingNotification();


    //_ Fix to no load FCM plugin methods on no mobiles devices
    if (!this.isMobileApp) {
      if (!window['isSafari']) {
        await this.requestPermission();
        await this.listenForMessages();
      }
    } else {
      console.log('requestFcmPermission');
      // const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
      // if (isPushNotificationsAvailable) {
      this.createChannels();
      await this.fcmService.initPushNotifications(this);

      //  }
    }

    this.globalEventService.getMessageCode().subscribe((data) => {
      if (data == 'STOP_BACKGROUND_API') {
        this.stopReceivingNotification();
      }
    });
  }

  async getNotificationbyId(id) {
    //let nots = await this.getNotifications();
    //let not = null;
    //nots.forEach(n => {
    //  if (n.id == id) { not = n; return 0; }
    //});
    //return not;
    return this.notifications.value.find((n: any) => n.id == id);
  }

  async createChannels() {
    console.log('createChannels');
    if (!this.isMobileApp) return;
    await this.fcmService.createChannel({
      id: 'sound1',
      name: 'sound1',
      description: 'Notification type  1',
      importance: 'high',
      visibility: 'public',
      sound: 'sound1',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound2',
      name: 'sound2',
      description: 'Notification type  2',
      importance: 'high',
      visibility: 'public',
      sound: 'sound2',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound3',
      name: 'sound3',
      description: 'Notification type  3',
      importance: 'high',
      visibility: 'public',
      sound: 'sound3',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound4',
      name: 'sound4',
      description: 'Notification type  4',
      importance: 'high',
      visibility: 'public',
      sound: 'sound4',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound5',
      name: 'sound5',
      description: 'Notification type  5',
      importance: 'high',
      visibility: 'public',
      sound: 'sound5',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound6',
      name: 'sound6',
      description: 'Notification type  6',
      importance: 'high',
      visibility: 'public',
      sound: 'sound6',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound7',
      name: 'sound7',
      description: 'Notification type  7',
      importance: 'high',
      visibility: 'public',
      sound: 'sound7',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound8',
      name: 'sound8',
      description: 'Notification type  8',
      importance: 'high',
      visibility: 'public',
      sound: 'sound8',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound9',
      name: 'sound9',
      description: 'Notification type  9',
      importance: 'high',
      visibility: 'public',
      sound: 'sound9',
      lights: true,
      vibration: true,
    });
    await this.fcmService.createChannel({
      id: 'sound10',
      name: 'sound10',
      description: 'Notification type  10',
      importance: 'high',
      visibility: 'public',
      sound: 'sound10',
      lights: true,
      vibration: true,
    });
  }

  async showPopup(notification: any) {
    let popup = await this.popoverCtrl.create({
      componentProps: {
        title: notification.title,
        description: notification.body,
        image: notification.image,
      },
      component: CsPopoverComponent,
      cssClass: 'details-popup',
      translucent: true,
    });

    await popup.present();
    popup = null;
  }

  /********************************************************* */
  /* NOTIFICATIONS MANAGER GET, SET, DELETE, MARK ... COUNT  */
  /* ________________________________________________________*/
  async getNotifications() {
    return this.notifications.value;
  }

  getUnreadNotifications() {
    //let nots = []; let ret = [];
    return this.notifications.value.filter((i: any) => i.isread == 0);
  }

  async setNotifications(notifications) {
    //await this.storage.set('notifications-list', nots);
    //this.events.publish('notifications:changed', nots);
    // var oldNots = this.notifications['_value'];
    notifications = [...notifications, ...[]]; // updating the last array with []
    this.notifications.next(notifications);
  }

  async updateNotifications() {
    this.startReceivingNotification();
    return await this.loadFromServer(this.viewCheck);
  }

  async getTranslation(key: string, values = null) {
    if (!values) values = {};
    await new Promise((resolve, reject) => {
      this.translate.get(key, values).subscribe(
        (r: string) => {
          key = r;
          resolve(key);
        },
        (error) => {
          resolve(key);
        },
      );
    });
  }

  obsLoadingNotifications = null;
  async loadFromServer(viewCheck = null) {
    if (this.obsLoadingNotifications)
      this.obsLoadingNotifications.unsubscribe();

    // console.warn('DEBUG - loadFromServer', this.viewCheck)
    let url = '';
    if (viewCheck) {
      const queryEncodeParams = new URLSearchParams(this.extraProps).toString();
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        'notifications/shared/' +
        viewCheck +
        '/' +
        this.lastId +
        '?' +
        queryEncodeParams; //GET CUSTOMER DATA IF IS IN GHOST MODE
    } else {
      url =
        ENV.ROUTE +
        ENV.API_VERSION +
        'notifications/' +
        (await this.storage.get(Constants.USER_ID)) +
        '/' +
        this.lastId; //GET CUSTOMER DATA IF IS IN GHOST MODE
      if ((await this.storage.get(Constants.SUPER_USER)) == 1) {
        url =
          ENV.ROUTE +
          ENV.API_VERSION +
          'a/notifications/' +
          (await this.storage.get(Constants.USER_ID)) +
          '/' +
          this.lastId;
      }
    }

    //_ If lastId === 0 then notifications from startup data service
    if (this.lastId === 0 && !viewCheck && !this.lastTimeRequest) {
      // console.log('DEBUG REQUESTING NOTIF 1', { url, lastId: this.lastId })
      return new Promise((resolve, reject) => {
        this.obsLoadingNotifications = this.startupService.notifications$
          .pipe(first())
          .subscribe(
            async (res) => {
              this.lastTimeRequest = new Date().getTime();
              resolve(await this.loadNotifications(res));
            },
            (error) => {
              reject(error);
            },
          );
      });
    } else {
      // console.log('DEBUG REQUESTING NOTIF 2', { url, lastId: this.lastId })
      return new Promise((resolve, reject) => {
        this.obsLoadingNotifications = this.http.get(url).subscribe(
          async (res) => {
            this.lastTimeRequest = new Date().getTime();
            resolve(await this.loadNotifications(res));
          },
          (error) => {
            reject(error);
          },
        );
      });
    }
  }

  async loadNotifications(res) {
    let all = this.lastId == 0 ? [] : this.notifications['_value'];
    const notsIds = [];
    let devices = await this.getDevices();
    if (res) {
      if (res.success.length) {
        this.lastId = res.success[0]['id'];
      }

      res.success = res.success.sort((a, b) => b.dateunix - a.dateunix);
      res.success.forEach(async (not: any) => {
        let newNot = {
          id: not.id,
          speed: not.speed,
          speederlaubt: not.speederlaubt,
          bezeichnung: '',
          dateunix: not.dateunix,
          deviceName: '',
          meldungtyp: 0,
          isread: not.isread,
          iddevice: not.iddevice,
          meldung: '',
          dateStr: '',
          lat: not.lat,
          lng: not.lng,
        };

        newNot.bezeichnung = translationsKeys.find(
          (x) => x.id == not.meldungtyp,
        )?.title;
        newNot.meldung = translationsKeys.find(
          (x) => x.id == not.meldungtyp,
        )?.description;

        if ((not.meldungtyp == 10 || not.meldungtyp == 11) && !not?.name) {
          newNot.meldung = newNot.meldung + 'NM';
        }

        newNot.dateStr = moment
          .unix(not.dateunix)
          .format('DD MMMM YYYY, HH:mm');
        const index = devices.findIndex((obj) => obj.id === not.iddevice);
        if (index !== -1) {
          newNot.deviceName = devices[index].name;
          newNot.meldungtyp = not.meldungtyp;
          all.push(newNot);
          notsIds.push(newNot.id);
        }
      });

      if (this.readNots.length) {
        all = all.map((n: any) => {
          if (this.readNots.includes(n.id)) {
            return { ...n, isread: 1 };
          } else {
            return n;
          }
        });
        this.readNots = [];
      }

      if (this.unreadNots.length) {
        all = all.map((n: any) => {
          if (this.unreadNots.map((un) => un.id).includes(n.id)) {
            return { ...n, isread: 0 };
          } else {
            return n;
          }
        });

        const unreadAdded = [];
        this.unreadNots.forEach((n) => {
          if (!notsIds.includes(n.id)) {
            all.push(n);
            unreadAdded.push(n.id);
          }
        });
        this.unreadNots = this.unreadNots.filter(
          (un) => !unreadAdded.includes(un.id),
        );
      }

      if (this.deletedNots.length) {
        all = all.filter((n: any) => !this.deletedNots.includes(n.id));
      }

      this.setNotifications(all); //_ Update notification in storage local
      this.updateCounter();

      return all;
    }
  }

  async loadMoreFromServer(obj, viewCheck = null) {
    let all = [];
    let data = {
      length: 0,
      data: [],
    };
    await this.api
      .getMoreNotifications(obj, viewCheck, this.extraProps)
      .then(async (res) => {
        let devices = await this.getDevices();
        if (res) {
          let totalNots = 0;

          Object.entries(res.response?.grouped).forEach(
            ([key, value]: [key: any, value: any]) => {
              const nots = [];

              value.forEach(async (not: any) => {
                let newNot = {
                  id: not.id,
                  speed: not.speed,
                  speederlaubt: not.speederlaubt,
                  bezeichnung: '',
                  dateunix: not.dateunix,
                  deviceName: '',
                  meldungtyp: 0,
                  isread: not.isread,
                  iddevice: not.iddevice,
                  meldung: '',
                  dateStr: '',
                  lat: not.lat,
                  lng: not.lng,
                };

                newNot.bezeichnung = translationsKeys.find(
                  (x) => x.id == not.meldungtyp,
                )?.title;
                newNot.meldung = translationsKeys.find(
                  (x) => x.id == not.meldungtyp,
                )?.description;

                if (
                  (not.meldungtyp == 10 || not.meldungtyp == 11) &&
                  !not?.name
                ) {
                  newNot.meldung = newNot.meldung + 'NM';
                }

                newNot.dateStr = moment
                  .unix(not.dateunix)
                  .format('DD MMMM YYYY, HH:mm');
                const index = devices.findIndex(
                  (obj) => obj.id === not.iddevice,
                );
                if (index !== -1) {
                  newNot.deviceName = devices[index].name;
                  newNot.meldungtyp = not.meldungtyp;
                  nots.push(newNot);
                  totalNots += 1;
                }
              });

              all.push({
                date: key,
                nots,
              });
            },
          );

          const dataSorted = all.sort((a, b) => b.date - a.date);

          /*
          res.data = res.data.sort((a, b) => b.dateunix - a.dateunix);
          res.data.forEach(async (not: any) => {
            let newNot = {
              id: not.id,
              speed: not.speed,
              speederlaubt: not.speederlaubt,
              bezeichnung: "",
              dateunix: not.dateunix,
              deviceName: "",
              meldungtyp: 0,
              isread: not.isread,
              iddevice: not.iddevice,
              meldung: "",
              dateStr: "",
              lat: not.lat,
              lng: not.lng,
            };

            newNot.bezeichnung = translationsKeys.find(x => x.id == not.meldungtyp)?.title;
            newNot.meldung = translationsKeys.find(x => x.id == not.meldungtyp)?.description;

            newNot.dateStr = moment
              .unix(not.dateunix)
              .format("DD MMMM YYYY, HH:mm");
            const index = devices.findIndex((obj) => obj.id === not.iddevice);
            if (index !== -1) {
              newNot.deviceName = devices[index].name;
              newNot.meldungtyp = not.meldungtyp;
              all.push(newNot);
            }


          });

          // this.setNotifications(all); //_ Update notification in storage local
          */

          this.updateCounter();
          data = {
            length: totalNots,
            data: dataSorted,
          };

          return data;
        }
      })
      .catch((e) => {
        return data;
      });
    return data;
  }

  async loadNotificationType(data, viewCheck = null) {
    var types = [];
    await this.api.getNotificationTypes(data, viewCheck).then(async (res) => {
      if (res) {
        types = res['success'];
        return types;
      }
    });
    return types;
  }

  async getDevices() {
    let ret: any = [];
    let deviceData;

    //_ If is for shared view make the request; otherwise use the devices from appService
    if (!this.viewCheck) {
      await this.startupService.devices$
        .pipe(first())
        .toPromise()
        .then((res) => (ret = res.success));
      // ret = ret.success;
    } else {
      await this.api
        .getDevices(this.viewCheck)
        .then((r) => {
          deviceData = r;
          ret = deviceData.data;
        })
        .catch((error) => {
          console.log('ERROR: ', error);
          return [];
        });
    }

    return ret;
  }

  async markAll(notifications, status, all = false) {
    let unreadNots = 0;
    if (!all) {
      notifications.map((not) => {
        if (!not.isread) {
          unreadNots--;
        } else if (not.isread) {
          unreadNots++;
        }
      });
    }

    const notificationsObj = notifications;
    if (all) notifications = null;
    else notifications = notifications.map((n) => n.id);
    return await this.api
      .markNotifications(this.appService.user.id, notifications, status)
      .then((r) => {
        this.changeUnreadNots.next(unreadNots);
        if (r.success) {
          if (all || status == 0) {
            this.startReceivingNotification();
          } else {
            this.markNotif(
              notifications.map((n) => {
                return { id: n };
              }),
              status,
            );
          }

          //_ Change the status of all notifications in the service
          if (all) {
            this.markNotif(this.notifications.value, status);
          }

          return true;
        }
      })
      .catch((error) => {
        console.log('ERROR: ', error);
        return false;
      });
  }

  async markNotif(notifications, status) {
    //let all = [];
    //await this.getNotifications().then( r=> { all = r; });
    //all.forEach(nall => {
    //  notifications.forEach(n => {
    //    if (nall.id == n.id) nall.isread = isread;
    //  });
    //});

    //await this.setNotifications(all);
    //this.updateCounter();
    this.notifications.value.forEach((not: any) => {
      notifications.forEach((newNot) => {
        if (not.id == newNot.id) {
          not.isread = status;
          if (status == 1) {
            this.readNots.push(not.id);
          } else if (status == 0) {
            this.unreadNots.push(not);
          }
          this.notifyChanged.next(newNot);
        }
      });
    });

    this.updateCounter();
  }

  //Update unread Counter
  async updateCounter() {
    //let unread = [];
    //await this.getUnreadNotifications().then( r => unread = r);
    //this.events.publish('notifications:updatedCounter', unread.length>0?unread.length:0);
    const unread = this.getUnreadNotifications();
    // console.log('unread',unread)
    this.counterChanged.next(unread.length);
  }

  deleteNotification(notifications, all) {
    //let allNots = [];
    //await this.getNotifications().then( r=> { allNots = r; });
    //let ret = allNots.filter(ar => !notifications.find(nx => (nx.id === ar.id) ));

    //allNots = all?[]:ret;
    //await this.setNotifications(allNots);

    let allNots: any = this.notifications.value;
    let ret = allNots.filter(
      (ar: any) => !notifications.find((nx) => nx.id === ar.id),
    );

    allNots = all ? [] : ret;
    this.setNotifications(allNots);
    this.updateCounter();
  }

  async removeNotification(notification) {
    let unreadCount = 0;
    const newNotifications = this.notifications.value.filter((n: any) => {
      if (n.id == notification.id) {
        if (notification.isread == 0) {
          unreadCount--;
        }
      }
      return n.id !== notification.id;
    });
    this.deletedNots.push(notification.id);
    this.setNotifications(newNotifications);
    await this.updateCounter();
  }

  async delete(userId) {
    return await this.api
      .deleteNotifications(userId)
      .then((r) => {
        if (r.success) {
          this.notifications.value.forEach((not: any) => {
            this.deletedNots.push(not.id);
          });
          this.startReceivingNotification();
        }
        return r;
      })
      .catch((error) => {
        return null;
      });
  }

  async deleteSingleNotification(notId, requestAgain = true) {
    return new Promise((resolve, reject) => {
      this.api
        .deleteNotification(notId)
        .then(() => {
          if (requestAgain) this.startReceivingNotification();

          this.updateCounter();
          let text = this.translate.instant('notifications.deleteSuccess');
          this.showToast('', text, 2000, 'success');
          resolve(true);
        })
        .catch((error) => {
          let text = this.translate.instant('notifications.deleteError');
          this.showToast('', text, 2000, 'danger');
          reject(false);
        });
    });
  }

  async restoreSingleNotification(notificationID) {
    return await this.api
      .restoreSingleNotification(notificationID)
      .then((r) => {
        if (r.success) {
          let unreadCount = 0;
          if (r.success?.alarm?.isread == 1) {
            this.readNots.push(r.success?.alarm.id);
          } else if (r.success?.alarm?.isread == 0) {
            this.unreadNots.push(r.success?.alarm);
            unreadCount++;
          }

          this.startReceivingNotification();
          let text = this.translate.instant('notifications.restoreSuccess');
          this.showToast('', text, 2000, 'success');
          if (unreadCount > 0) {
            this.changeUnreadNots.next(unreadCount);
          }
        }
      })
      .catch(() => {
        let text = this.translate.instant('notifications.restoreError');
        this.showToast('', text, 2000, 'danger');
      });
  }

  async getTrashedNotifications() {
    return await this.api.getTrashedNotifications().then((r) => {
      if (r.success) {
        const map = new Map<string, Array<any>>(Object.entries(r.success));
        /*
          let data = [];
          map.forEach((value, key) => {
            if (value?.length) {
              value.forEach(v => data.push(v));
            }
          })
          return data;
          */
        return r.success;
      }
    });
  }

  async getMoreTrashedNotifications(skip: number = 0) {
    return await this.api.getMoreTrashedNotifications(skip).then((r) => {
      if (r.success) {
        const map = new Map<string, Array<any>>(Object.entries(r.success));
        return r.success;
      }
    });
  }

  async deleteTrashedNotifications() {
    return await this.api
      .deleteTrashedNotifications()
      .then((r) => {
        if (r.success) {
          return r;
        }
      })
      .catch((e) => {
        console.log('catch error ::: ', e);
      });
  }

  async getNotificationsCount(data) {
    // console.warn('DEBUG - notif count', data);
    if (
      this.userActivity.platform.isDesktopBrowser() &&
      this.userActivity.getAppInactiveTime() <
        this.userActivity.TIME_TO_PAUSE_ACTIVITY
    ) {
      return this.getNotificationsRequest(data);
    } else if (!this.userActivity.platform.isDesktopBrowser()) {
      return this.getNotificationsRequest(data);
    }

    return Promise.reject('Disabled - no user activity');
  }

  async getNotificationsRequest(data) {
    return await this.api.getNotificationsCount(data).then((r) => {
      return r;
    });
  }

  myToast: any = null;

  async showToast(title, text, time, colorType) {
    if (this.myToast) this.myToast.dismiss();

    this.myToast = await this.toast.create({
      message: text,
      duration: time,
      color: colorType,
      position: 'bottom',
      header: title,
      cssClass: 'centerToast',
    });

    this.myToast.present();
  }
  async listenForMessages() {
    if (window['isSafari']) {
      return;
    }
    // await this.messagingService.getMessages().subscribe(async (msg: any) => {
    //   const toast = await this.toast.create({
    //     message: msg.notification.title,
    //     duration: 2000,
    //   });
    //   toast.present();
    // });
  }
  async requestPermission() {
    if (window['isSafari']) {
      return;
    }
    await this.messagingService.requestPermission().subscribe(
      async (token) => {
        //console.log("[Info] messagingService token", token)
        this.authService.registerFBToken(token).catch((e) => {
          console.log('Error registering FB token');
        });
      },
      async (error) => {
        console.log('ERROR: ', error);
      },
    );
  }
}
