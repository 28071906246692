import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PajDaterangeModule } from 'src/app/components/date-range/paj-daterange.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ExportRouteComponent } from './export-route/export-route.component';
import { RouteComponent } from './route.component';
import { RouterModule } from '@angular/router';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translations/", ".json");
}

@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      ReactiveFormsModule,
      PajDaterangeModule,
      PipesModule.forRoot(),
      RouterModule,
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
    ],

    declarations: [RouteComponent, ExportRouteComponent],
    exports: [RouteComponent, ExportRouteComponent, PipesModule, PajDaterangeModule],
    
  })

  export class PajDevicesRouteModule {}