import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IonNav, MenuController } from "@ionic/angular";
import { AppService } from "src/app/app.service";
// import { DeviceListComponent } from '../device-sidebar/device-list/device-list.component';
import { DeviceListService } from "./devices-list/device-list.service";
// import { VirtualListComponent } from '../device-sidebar/device-list/virtual-list/virtual-list.component';
import { DevicesListHomeComponent } from "./home/home.component";
import { DevicesSidebarService } from "./devices-sidebar.service";
import { DeviceItemComponent } from "./device-item/device-item.component";

@Component({
  selector: "app-devices-sidebar",
  templateUrl: "./devices-sidebar.component.html",
  styleUrls: ["./devices-sidebar.component.scss"],
})
export class DevicesSidebarComponent implements OnInit {
  @ViewChild("nav", { static: false }) nav: IonNav;
  devices = null;
  @Input() map;
  @Input() isAnimatingMenu = false;
  showShadow = false;
  @Output() public onMenuChange = new EventEmitter<{}>();
  isLoaded = false;
  constructor(
    private devicesSidebarService: DevicesSidebarService,
    private appService: AppService,  private deviesSidebar: DevicesSidebarService
  ) {}

  async ngOnInit() {
    // if (this.deviceListService.deviceData) {
    //   this.devices = this.deviceListService.deviceData;
    // }
    // else {
    //   this.deviceListService._devicesReady.subscribe(devices => {
    //     this.devices = devices;
    //   });
    // }
  }

  loadView() {
    let device_length = this.appService.devices.length;
    if (device_length === 1) {
      let item = this.appService.devices[0]
      this.devicesSidebarService.navigation = this.nav;
      this.nav.setRoot(DeviceItemComponent, {
        navigation: this.nav,
        isPullup: false,
        itemList: {...item, device_id:item.id},
        user_id: this.appService.user.id,
      });
    this.isLoaded = true;

    } else {
      this.devicesSidebarService.navigation = this.nav;
      this.nav.setRoot(DevicesListHomeComponent, {
        navigation: this.nav,
        isPullup: false,
        data: this.appService.devices,
        user_id: this.appService.user.id,
      });
    }
    this.isLoaded = true;
  }

  async open() {
    //_ used to hide box-shadow of the side menu while is animating
    this.showShadow = true;

    if (!this.isLoaded) this.loadView();

    this.appService.deviceMenuisOpen.next(true);
    this.devicesSidebarService.isOpen.set(true);

    setTimeout( () => {
      //_ This only helps to navigate to selected device when switch from small screen size to large size -> when open automaticly devices sidebar
      //_ Not should trigger when click on map feature (marker) -> for prevent that is using a little timeout to fire it after 1 sec
      if(this.devicesSidebarService.selectedDevice.value != null && this.devicesSidebarService.activePage == "APP-DEVICES-LIST-HOME"){
        console.log('this.devicesSidebarService.selectedDevice',this.devicesSidebarService.selectedDevice.value)
        let item = { device_id: this.devicesSidebarService.selectedDevice.value, deviceshow: true };

        this.devicesSidebarService.navigation.setPages(
          [
            { component: DevicesListHomeComponent, componentProps: {} },
            {
              component: DeviceItemComponent,
              componentProps: { itemList: item, callBack: null },
            },
          ],
          {});
      }
    }, 1000);
  }

  async close() {
    this.appService.deviceMenuisOpen.next(false);
    this.devicesSidebarService.isOpen.set(false);
  }

  openStatus(status) {
    // console.log('openStatus',status)
    this.onMenuChange.next({ open: status });
    this.showShadow = false;
  }
}
