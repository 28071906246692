import { Injectable } from '@angular/core';
import { Haptics } from '@capacitor/haptics';

@Injectable({
  providedIn: 'root'
})
export class HapticsService {

  constructor() {

   }

   async vibrate (duration:number)  {
    await Haptics.vibrate({duration:duration});
  };
  
}
