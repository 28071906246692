<ion-header>
  <ion-toolbar [color]="headerColor" [class.mini-popover]="showInPopover">
    <ion-buttons slot="start" *ngIf="!showInPopover">
      <ion-back-button
        (click)="close()"
        style="display: block !important"
        back-close
        back-locale
      >
      </ion-back-button>
    </ion-buttons>
    <ion-title *ngIf="!showInPopover">{{
      "dashboard.dateRange.selectDateRange" | translate
    }}</ion-title>
    <ion-label *ngIf="showInPopover">{{
      "dashboard.dateRange.selectDateRange" | translate
    }}</ion-label>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card-header
    color="light"
    *ngIf="!showInPopover && !singleSelection"
    class="ion-no-padding"
  >
    <ion-toolbar mode="ios">
      <ion-segment
        mode="ios"
        (ionChange)="segmentChanged($event)"
        ([ngModel])="(days)"
        scrollable
        class="no-scrollbars"
      >

      <ng-container *ngFor="let option of dateSegmentButtons">
        <ion-segment-button
          [value]="option.days"
          layout="icon-start"
          [class.segment-button-checked]="days === option.days"
        >
          <ion-label *ngIf="option.days === 1">{{
            "dashboard.dateRange.yesterday" | translate
          }}</ion-label>
          <ion-label *ngIf="option.days === 0">{{ "dashboard.dateRange.today" | translate }}</ion-label>

          <ion-label *ngIf="option.days > 1">{{
            "dashboard.dateRange.nDays" | translate : { n: option.days+1 }
          }}</ion-label>
        </ion-segment-button>
      </ng-container>
      </ion-segment>
    </ion-toolbar>
  </ion-card-header>

  <ion-list style="margin-top: 20px" class="ion-padding" *ngIf="showInPopover">
    <br />
    <ion-label *ngIf="showInPopover">{{ "logBook.startDate" | translate }}</ion-label>
    <ion-item
      class="ion-no-padding"
      lines="none"
      color="light"
      id="startDateItem"
      button
      detail="false"
    >
      <ion-icon name="calendar-outline" color="primary" slot="start"></ion-icon>

      <!-- <ion-datetime #dtStart [displayFormat]="dateTime?'MMM DD, YYYY HH:mm':'DD/MM/YYYY'" [(ngModel)]="startDate"
              [pickerFormat]="dateTime?'MMM DD, YYYY HH:mm':'DD MMM YYYY'" name="startDate"
              [monthShortNames]="monthsShortNames" [monthNames]="monthsNames" [dayNames]="daysNames"
              [max]="endDate?endDate:today" min="2020-01-01">
          </ion-datetime> -->
      <ion-label>{{
        startDate
          | humanizeTime
            : "date"
            : (dateTime ? "MMM DD, YYYY HH:mm" : "DD MMM YYYY")
      }}</ion-label>
      <ion-popover
        trigger="startDateItem"
        mode="ios"
        size="auto"
        class="datetime-popover"
        #startPopover
      >
        <ng-template>
          <ion-content>
            <ion-datetime
              presentation="date"
              [max]="endDate ? endDate : today"
              min="2020-01-01"
              [locale]="timeZoneLocale"
              (ionChange)="startDate = $event.detail.value"
              [value]="startDate"
            >
              <!-- <ion-buttons slot="buttons">
                          <ion-button color="primary" (click)="startPopover.dismiss()">Accept</ion-button>
                      </ion-buttons> -->
            </ion-datetime>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-item>

    <ion-label *ngIf="showInPopover">{{ "logBook.endDate" | translate }}</ion-label>
    <ion-item
      class="ion-no-padding"
      lines="none"
      color="light"
      id="endDateItem"
      button
      detail="false"
    >
      <ion-icon name="calendar-outline" color="primary" slot="start"></ion-icon>

      <!-- <ion-datetime #dtEnd [displayFormat]="dateTime?'MMM DD, YYYY HH:mm':'DD/MM/YYYY'" [(ngModel)]="endDate"
              [pickerFormat]="dateTime?'MMM DD, YYYY HH:mm':'DD MMM YYYY'" name="endDate"
              [monthShortNames]="monthsShortNames" [monthNames]="monthsNames" [dayNames]="daysNames"
              [min]="startDate?startDate:'2020-01-01'" [max]="today">
          </ion-datetime> -->
      <ion-label>{{
        endDate
          | humanizeTime
            : "date"
            : (dateTime ? "MMM DD, YYYY HH:mm" : "DD MMM YYYY")
      }}</ion-label>
      <ion-popover
        trigger="endDateItem"
        mode="ios"
        size="auto"
        class="datetime-popover"
        #endPopover
      >
        <ng-template>
          <ion-content>
            <ion-datetime
              presentation="date"
              [max]="endDate ? endDate : today"
              min="2020-01-01"
              [locale]="timeZoneLocale"
              (ionChange)="endDate = $event.detail.value"
              [value]="endDate"
            >
              <!-- <ion-buttons slot="buttons">
                          <ion-button color="primary" (click)="endPopover.dismiss()">Accept</ion-button>
                      </ion-buttons> -->
            </ion-datetime>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-item>
  </ion-list>

  <!-- MODAL DATE PICKER content -->
  <!--ion-row style="margin-top: 10px" [hidden]="showInPopover"-->
  <ion-card-content class="ion-no-padding" [hidden]="showInPopover">
    <!--ion-card-title class="ion-text-center" style="font-size: 16px;">{{ 'dashboard.dateRange.selectCustomDate' | translate }}</ion-card-title-->

    <div
      class="ion-text-center"
      style="min-width: 260px; max-width: 300px; margin: 15px auto; position: relative;"
    >
      <mat-calendar
        #calendar
        [selected]="range"
        [startAt]="startDate"
        (selectedChange)="selectedChange($event)"
        [minDate]="minDate"
        [maxDate]="showFutureDate? null:maxDate"
        *ngIf="!singleSelection"
      >
      </mat-calendar>

      <mat-calendar
        #calendar
        [selected]="startDate"
        [startAt]="startDate"
        (selectedChange)="selectedChange($event)"
        [minDate]="minDate"
        [maxDate]="maxDate"
        *ngIf="singleSelection"
      >
      </mat-calendar>

      <!--input matInput [matDatepicker]="picker"-->
    </div>
    <ion-row class="time-picker">
      <ion-col size-md="6" size-xs="6" class="ion-no-padding">
        <ion-label class="ion-text-center"
          >{{ "route.fromText" | translate }}
          <p style="color: var(--ion-color-light-contrast)">{{ range.start | humanizeTime : "date" : "DD.MM.YYYY" }}</p>
        </ion-label>
      </ion-col>
      <ion-col size-md="6" size-xs="6" class="ion-no-padding">
        <ion-label class="ion-text-center" *ngIf="!singleSelection"
          >{{ "route.toText" | translate }}
          <p style="color: var(--ion-color-light-contrast)">{{ range.end | humanizeTime : "date" : "DD.MM.YYYY" }}</p>
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row class="time-picker">
      <ion-col size-md="6" size-xs="6" class="ion-no-padding">
        <ion-label *ngIf="!singleSelection">{{
          "dashboard.dateRange.startTime" | translate
        }}</ion-label>
        <ion-label *ngIf="singleSelection">{{
          "dashboard.dateRange.time" | translate
        }}</ion-label>
          <custom-time-picker
            labelTitle='{{"route.fromText" | translate}}'
            uniqueId="start"
            (timeChanged)="onChangeTime($event)"
            initialHour="{{times.start}}"/>
            <ion-button (click)="onChangeTime({time:'00:00', start: true})" fill="clear" shape="round"><ion-icon slot="icon-only" name="refresh-outline" class="no-margin"></ion-icon></ion-button>
          <!--
            <ion-item *ngIf="!showAllMinutes" lines="none" color="light" class="ion-text-center">
            <ion-icon
            slot="start"
            src="assets/images/map/popup/clock.svg"
            color="primary"
            ></ion-icon>
            <ion-select
              interface="popover"
              mode="ios"
              color="light"
              [interfaceOptions]="{ cssClass: 'nice-select-popover' }"
              class="ion-text-center"
              [(ngModel)]="times.start"
              (ionChange)="updateDatePicker(range?.start, range?.end)"
            >
              <ion-select-option [value]="t" *ngFor="let t of timesValues">{{
                t
              }}</ion-select-option>
            </ion-select>
          </ion-item>
          !-->
          <!-- <ion-item lines="none" color="transparent">

                    </ion-item> -->
        <!--
        <ion-item *ngIf="showAllMinutes" lines="none" color="light" class="ion-text-center" (click)="showTimePicker('start', $event)">
          <ion-icon slot="start" src="assets/images/map/popup/clock.svg" color="primary"></ion-icon>
          <span>{{ times.start }}</span>
          <ion-icon size="small" slot="end" name="caret-down-outline"></ion-icon>
        </ion-item>
        !-->
      </ion-col>

      <ion-col
        size-md="6"
        size-xs="6"
        class="ion-no-padding"
        *ngIf="!singleSelection"
      >
        <ion-label>{{ "dashboard.dateRange.endTime" | translate }}</ion-label>
          <!--
            <ion-item *ngIf="!showAllMinutes" lines="none" color="light" class="ion-text-center">
            <ion-icon
            slot="start"
            src="assets/images/map/popup/clock.svg"
            color="primary"
            ></ion-icon>
            <ion-select
              interface="popover"
              mode="ios"
              color="light"
              [interfaceOptions]="{ cssClass: 'nice-select-popover' }"
              class="ion-text-center"
              [(ngModel)]="times.end"
              (ionChange)="updateDatePicker(range.start, range.end)"
            >
              <ion-select-option [value]="t" *ngFor="let t of timesValues">{{
                t
              }}</ion-select-option>
            </ion-select>
          </ion-item>
          !-->
          <custom-time-picker
            labelTitle='{{"route.toText" | translate}}'
            uniqueId="end"
            (timeChanged)="onChangeTime($event)"
            initialHour="{{times.end}}" />
            <ion-button (click)="onChangeTime({time:'23:59', start: false})" fill="clear" shape="round"><ion-icon slot="icon-only" name="refresh-outline" class="no-margin"></ion-icon></ion-button>
          <!-- <ion-item lines="none" color="transparent">

                    </ion-item> -->

        <!--
        <ion-item *ngIf="showAllMinutes" lines="none" color="light" class="ion-text-center" (click)="showTimePicker('end', $event)">
          <ion-icon slot="start" src="assets/images/map/popup/clock.svg" color="primary"></ion-icon>
          <span>{{ times.end }}</span>
          <ion-icon size="small" slot="end" name="caret-down-outline"></ion-icon>
        </ion-item>
        !-->
      </ion-col>
    </ion-row>
  </ion-card-content>
  <!--/ion-row-->
</ion-content>

<ion-footer class="ion-padding">
  <div class="ion-text-center">
    <ion-button
      (click)="close()"
      shape="round"
      fill="solid"
      color="light"
      *ngIf="!showInPopover"
    >
      <ion-label>{{ "dashboard.cancel" | translate }} </ion-label>
    </ion-button>
    <ion-button
      (click)="dismiss()"
      shape="round"
      fill="solid"
      [disabled]="!rangeOk || !rangeTimeOk"
    >
      <!-- <fa-icon icon="calendar-check" slot="start"></fa-icon> -->
      <ion-label>{{ "dashboard.accept" | translate }}</ion-label>
    </ion-button>
  </div>
</ion-footer>
