<paj-popup-template [headerTitle]="title" [contentTemplate]="Content">

</paj-popup-template>

<ng-template #Content>
  <ion-grid fixed class="ion-padding">
    <ion-row>
      <ion-col size="12">
        <h3 class="ion-text-center">{{ 'map.petPopup.headChargeFinder' | translate }}</h3>
        <ion-img [src]="'assets/images/battery-charging' + (appService.darkMode ? '-dark' : '') + '.gif'"></ion-img>
        <ion-label class="ion-text-wrap">
          {{ 'map.petPopup.chargeFinderDetails' | translate }}
        </ion-label>

        <h3 class="ion-text-center">{{ 'map.petPopup.headLeds' | translate }}</h3>
        <ion-img src="assets/images/battery-big-leds.gif"></ion-img>
        <ion-label class="ion-text-wrap">
          {{ 'map.petPopup.headLedsDetails' | translate }}
        </ion-label>

      </ion-col>
      <ion-col size="12" class="ion-text-end">
        <ion-button
        (click)="onCancel()"
        color="light"
        shape="round"
      >
        OK
      </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>
