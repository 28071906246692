<!-- <div class="details-popup" >
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end" >
      <ion-button color="primary" (click)="close()" >
        <ion-icon name="close"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="12">
      <div class="center-content" style="width: 100%">
        <img
          src="{{ image }}"
          style="height: 100px"
          *ngIf="!(image | checkExtension: '.svg')"
        />
        <ion-icon
          src="{{ image }}"
          *ngIf="image | checkExtension: '.svg'"
        ></ion-icon>
      </div>
      <ion-card-title
        mode="md"
        class="ion-padding ion-text-center"
        style="font-size: large;"
        color="primary"
        >{{ title }}</ion-card-title
      >
      <ion-card-subtitle style="font-size: large;" class="ion-padding ion-text-center" *ngIf="subtitle">{{
        subtitle
      }}</ion-card-subtitle>

      <ion-card-content
        class="ion-no-padding ion-padding-horizontal ion-text-center"
      >
        <ion-text *ngIf="description">
          {{ description }}
        </ion-text>
      </ion-card-content>

      <div class="center-content">
        <ion-button
          fill="clear"
          mode="md"
          color="primary"
          expand="block"
          (click)="close()"
          fill="solid"
          shape="round"
        >
          OK</ion-button
        >
      </div>
    </ion-col>
  </ion-row>
</ion-content>
</div> -->
<ion-card class="ion-no-margin logbook-popover-desktop" style="box-shadow: none">
  <ion-button
    color="light"
    shape="round"
    fill="solid"
    class="close-button"
    size="small"
    (click)="close()"
  >
    <ion-icon name="close" slot="icon-only"></ion-icon>
  </ion-button>
  <div class="ion-text-center ion-margin-top" *ngIf="!useGif">
    <ion-icon
      *ngIf="!image?.endsWith('.svg')"
      name="information-circle"
      style="font-size: 2.5rem"
      color="primary"
    >
    </ion-icon>
    <ion-icon
      *ngIf="image?.endsWith('.svg')"
      [src]="image"
      color="primary"
      style="font-size: 2.5rem"
    ></ion-icon>

    <!-- <img
      src="{{ image }}"
      style="height: 50px"
      *ngIf="!(image | checkExtension: '.svg')"
    />
    <ion-icon
      src="{{ image }}"
      *ngIf="image | checkExtension: '.svg'"
    ></ion-icon> -->
  </div>
  <ion-card-header *ngIf="subtitle">
    <ion-card-subtitle class="ion-text-center"
      >{{ subtitle }}
    </ion-card-subtitle>
  </ion-card-header>

  <ion-card-content class="ion-no-padding cspopover-ion-card-content overscroll">
    <img *ngIf="useGif" [src]="image"/>
    <p class="ion-text-center"
        style="white-space: pre-wrap; color: var(--ion-color-light-contrast);"
        *ngIf="description"
        [innerHTML]="description">
    </p>
    <!-- <p style="font-size: 12px" >{{ description }}</p> -->
  </ion-card-content>

  <!-- <ion-item
    color="primary"
    lines="none"
    detail="false"
    button
    (click)="close()"
  >
    <ion-label class="ion-text-center"><h3>{{ 'deviceManagement.deviceSettings.popoverClose' | translate }}</h3></ion-label>
  </ion-item> -->
</ion-card>
