import { Component, EventEmitter, Input, OnInit, Output, Sanitizer } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeviceImgComponent } from '../../device-management/components/device-img/device-img.component';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from '../../map/devicesData.service';
import { DeviceImagePipe } from 'src/app/pipes/deviceImagePipe';
import { environment as ENV } from "src/environments/environment";
import { DomSanitizer } from '@angular/platform-browser';
import { SetupDeviceService } from '../device-setup-guide/device-setup.service';
import { CommunicationService } from '../communitaction.service';
@Component({
  selector: 'setup-device-image',
  templateUrl: './device-image.page.html',
  styleUrls: ['./device-image.page.scss'],
})
export class DeviceImagePage implements OnInit {
  @Input() device = null;
  @Output() onImageChange = new EventEmitter();
  url: string = ENV.ROUTE;
  iconUrl: any = '';
  constructor(private modalCtrl: ModalController, public appService: AppService, private devicesService: DeviceDataService,
             private deviceImagePipe: DeviceImagePipe, private sanitizer: DomSanitizer, private setupDeviceService: SetupDeviceService, private communicationService: CommunicationService) { }

  ngOnInit() {
  }

  async DeviceImg() {
  const md = await this.modalCtrl.create({
    component: DeviceImgComponent,
    cssClass: 'device-guide-setup',
    componentProps: {
      id: this.device.id,
      iconname: this.device.iconname,
      iconusecustom: this.device.iconusecustom,
      url: this.url,
      user_id: this.appService.user.id,
      iconcustomimage: this.device.iconcustomimage,
      device: this.device,
      showBackButton: true,
      customPopupClass: 'device-guide-setup'
    },
  });
  await md.present();
  const { data } = await md.onWillDismiss();

  if (data) {
    if (data.iconname || data.iconcustomimage || data.threedObj) {
      this.device.iconname = data.iconname;
      this.device.iconusecustom = data.iconusecustom;
      this.device.iconcustomimage = data.iconcustomimage;

      if (data.threedObj)
        this.device.threedModel_name = data.threedObj.name;

      this.device = Object.assign({}, this.device);
      this.deviceImagePipe.transform(this.device, this.appService.user.id)
        .subscribe((iconUrl) => {
          this.iconUrl = this.sanitizer.bypassSecurityTrustUrl(iconUrl);
        });

      this.onImageChange.emit(this.device);
      this.setupDeviceService.onDeviceChange.next({ device: this.device, stepName: 'image' });
    }
  }
}

  nextSetting(){
    this.communicationService.emitEvent("device_alerts")    
  }
}
