<div
  id="mini-map-{{ id }}"
  [style.width]="width"
  [style.height]="height"
  class="main-map"
  [class.dark-style-bg]="mapStyle != 'pajLite' && isDarkmode"
></div>
<canvas
  id="cv-container"
  width="857"
  height="480"
  style="display: none"
></canvas>
