import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { HapticsService } from '../services/plugins/haptics.service';

@Directive({
  selector: '[vibrate-on-toggle]'
})
export class VibrateOnToggleDirective {
    @Input() vibrateOn = true;
    @Input() vibrateAlways = false;
    @Input() duration = 300;
    @Input() enabled = true;
    constructor(private el: ElementRef, private hapticService: HapticsService) {}

    @HostListener('ionChange', ['$event.detail.checked'])
    onToggleStatusChange(checked: boolean) {
        if ((this.vibrateAlways || checked == this.vibrateOn) && this.enabled){
            this.hapticService.vibrate(this.duration);
        }
        
    }
}