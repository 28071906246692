import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PajMapAdressPickerModule } from 'src/app/components/map-address-picker/paj-map-address-picker.module';
import { PajMapMinModule } from 'src/app/members/generate-video/map-min/paj-map-min.module';
import { AlarmClockComponent } from './alarm-clock.component';



@NgModule({
  declarations: [AlarmClockComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    PajMapMinModule,
    PajMapAdressPickerModule
  ]
})
export class AlarmClockModule { }
