import { AfterViewInit, Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-api-error-popup',
  templateUrl: './api-error-popup.component.html',
  styleUrls: ['./api-error-popup.component.scss'],
})
export class ApiErrorPopupComponent implements OnInit, AfterViewInit {
  isSad = false;
  constructor(private storage: StorageService) { }

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => this.isSad = true, 500);
  }

  onCancel(){
    // this.storage.clear();
    window.location.reload();
  }
}
