export const environment = {
  hideLogs: true,
  production: true,
  firebase: {
    databaseURL: "",
    apiKey: "AIzaSyDuXlWTuXytXuDb-3xAxXHJZ8E-JDBt6_0",
    authDomain: "pajgpsv2.firebaseapp.com",
    projectId: "pajgpsv2",
    storageBucket: "pajgpsv2.appspot.com",
    messagingSenderId: "86625040821",
    appId: "1:86625040821:web:845bcab1eac378453581fc",
    measurementId: "G-B37KVJX6B6",
    vapidKey: "BDkd4yyNof2jBJy95JOUUnv0UqIEw68SZqUmqfrDcd2d8Z-6xaSBGFxvRLJhRDowf-yHPFNW0mLiUYfMaPiL4Ak"
  },
  mediaServer: "https://connect.paj-gps.de",
  ROUTE: 'https://connect.paj-gps.de', //live
  FILE_ROUTE: 'https://api.paj-gps.com', // For storing file on only 1 server as it will not through timeout
  API_VERSION: '/api/v1/',
  ROUTE_V2: 'https://v2.finder-portal.com', //live
  SHARE_URL: 'https://v2.finder-portal.com', //dev
  MODELS_FOR_LOGBOOK: [9020, 9022, 9044, 9045, 9047, 9048, 9049, 9075, 9072, 9070],
  GRANT_LOGBOOK_ACCESS_FOR: [88333, 83507], //_ Users ids
  MODELS_100: [1010, 1020, 9041, 9042, 9043, 9050, 9069, 9096, 9504, 10111, 10102, 9068, 9081],
  MODELS_NOT_100: [9016, 9017, 9018, 9025, 9019, 9021, 9024, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10015, 9066, 9046],
  CONNECTED_BATTERY_MODELS: [9044, 9020, 9021, 9022, 9023, 9045, 9047, 9049, 9070, 10001, 10007, 10008, 10101, 10103, 10104, 10105, 10108, 1000, 8000, 9072, 9075],
  ALL_MODELS: [1010, 1020, 9016, 9030, 9040, 9041, 9042, 9043, 9044, 9050, 9069, 9096, 9504, 10111, 10102, 9020, 9021, 9022, 9023, 9030, 9045, 9047, 9070, 10001, 10007, 10008, 10101, 10103, 10108, 1000, 8000, 9072, 9068, 9081],
  PET_MODELS: [9068, 9069, 10015],
  PET_MODELS_WITH_DIFF_CHARGER: [9068],
  FREE_FINDER:1020,
  CAR_MODELS: [9016, 9017, 9020, 9021, 9022, 9023, 9025, 9030, 9040, 9042, 9044, 9045, 9046, 9047, 9049, 9055, 9060, 9066, 9070, 9072, 9073, 9075, 10001, 10002, 10003, 10004, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10012, 10013, 10014, 10102, 10103, 10108, 10111],
  PEOPLE_MODELS: [9016, 9018, 9019, 9043, 9066, 9081],
  ALL_LANGUAGES: ['DE', 'EN', 'ES', 'IT', 'FR', 'PT', 'PL', 'EL'],
  SUPPORT_URL_LANGUAGES: ['de', 'en', 'es', 'it', 'fr', 'pt', 'au', 'in'],
  BRAND: 0,
  PRIVACY_POLICY_URL: 'https://start.finder-portal.com/privacy-policy',
  IMPRINT_URL: 'https://start.finder-portal.com/privacy-policy',
  PAGE_TITLE: 'PAJ-Portal | PAJ GPS',
  DEFAULT_SUPPORT_URL: 'https://www.paj-gps.com/support/',
  DEFAULT_MANUALS_URL: 'https://www.paj-gps.com/finder-portal-instruction/',
  DEFAULT_DATE_FORMAT: "DD.MM.YYYY HH:mm",

  // Countly - METRICS
  COUNTLY_URL: 'https://treasuretracker.littlebigfinder.com/i',
  COUNTLY_KEY: '670c657ae8e70e61b6227e1bee0726bfd35d580f',
  MAPBOX_TOKEN: 'pk.eyJ1IjoicGFqZ3BzIiwiYSI6ImNsc29tdXJ6aDBpNHgyaW85YTRkMWVkYjgifQ.hnqBznQm1fBelNxBlUarlQ'
};
