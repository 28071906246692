import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Constants } from 'src/app/constants.enum';
import { StorageService } from 'src/app/services/storage.service';
import { App_Languages } from './util';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit {
  languages = App_Languages;
  @Input() selectedLan;
  @Input() title = null;
  constructor(private popoverCtrl: PopoverController, private storage: StorageService) { }

  ngOnInit() {
    if (!this.selectedLan){
      this.storage.get(Constants.LANGUAGE).then( r => {
        this.selectedLan = this.languages.find(l => l.id == r);
      })
      
    }
  }

  selectLanguage(l){
    this.selectedLan = l;
    this.popoverCtrl.dismiss({ selectedLan: this.selectedLan });
  }

}
