
export const FlatToGrouped = (flatArray) => {
    let GroupedArray = [];
    flatArray.forEach(item => {
        if (item.isHeader){
            item['devices'] = [];
            GroupedArray.push(item);
        }
        else
            if (GroupedArray.length > 0)
              GroupedArray[GroupedArray.length -1]['devices'].push(item);
    });
    return GroupedArray;
}

export const GroupedToFlat = (array) => {
  array.forEach(item => {
    item['devices'].forEach(group_device => {
      group_device['group_position'] = item['group_position'];
    });
  });
  const rightOrder = array.flatMap((group, index) => [
    {
      isHeader: true,
      index: index,
      open: group.open,
      id: group.id,
      devices_groups_group_id: group.devices_groups_group_id,
      group_position: group.group_position,
      group_name: group.group_name ,
      devices: group.devices
    },
    ...group.devices
  ]);
  return rightOrder;
}

export const GetDeviceFromGroup = (groupedArray, deviceId) => {
  let returnData = null;
  groupedArray.forEach(group => {
    if (returnData) return returnData;
    group.devices.forEach( (d, index) => {
      if (d.id == deviceId){
        returnData = { index: index+1, device: d };
        return returnData;
      }
    });
  });

  return returnData;
};

export const isElementInViewport = (el) => {
  if (!el) return false;
  var rect = el.getBoundingClientRect();

  return rect.bottom > 0 &&
      rect.right > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
}

export const moveArrayItem = (from: number, to: number, array: Array<any>) => {
  const tempList = [...array];
  const element = tempList.splice(from, 1)[0];
  tempList.splice(to, 0, element);

  // console.warn('ARRAY ITEM UPDATED', { from, to, array: [...array], tempList: [...tempList] })
  return tempList;
}

//_
export const UpdateGroupsTotal = (currentGroups, listWithPagination, group_id, device_id, device_position, deviceItem) => {
  currentGroups.forEach(gr => {
    if (gr.devices_groups_group_id) {
      const deviceExist = gr.devices.find(item => item.device_id == device_id);
      if (!deviceExist){
        gr.devices.splice(device_position, 0, {...deviceItem, group_id: group_id});
        gr['device_groups'] = JSON.parse(JSON.stringify(gr.devices));
      }
    }
  });

   currentGroups.forEach(gr => {
    if (gr.devices_groups_group_id != group_id)
      gr.devices = gr.devices.filter(item => item.device_id !== device_id);
      gr['device_groups'] = JSON.parse(JSON.stringify(gr.devices));
  })

  return currentGroups;
}

export const updateGroupsCounterAfterDelete = (currentGroups, deletedGroupId) => {
  const deletedGroupIndex = currentGroups.findIndex(gr => gr.id === deletedGroupId.id);
  const groupZeroIndex = currentGroups.findIndex(gr => gr.group_position === -1);

  // console.log('DELETING GROUP 1', { currentGroups: {...currentGroups}, deletedGroupId: {...deletedGroupId}})
  if (groupZeroIndex !== -1) {
    const devices = currentGroups[groupZeroIndex].devices;
    currentGroups[groupZeroIndex].devices = [...devices, ...deletedGroupId.devices];
    currentGroups[groupZeroIndex]['device_groups'] = [...devices, ...deletedGroupId.devices];
  }
  if (deletedGroupIndex !== -1)
    currentGroups.splice(deletedGroupIndex, 1);

  // console.log('DELETING GROUP 2', { currentGroups: {...currentGroups}, deletedGroupId: {...deletedGroupId}, groupZeroIndex, deletedGroupIndex})
  return JSON.parse(JSON.stringify(currentGroups));
}

export class CustomIonReorderEvent {
  detail = { from: 0, to: 0, complete: (list) => this.complete(list) };
  isCustom = true;

  constructor (from, to){
    this.detail.from = from;
    this.detail.to = to;
  }

  complete (list) {
    if (list) {
      const clonedList = JSON.parse(JSON.stringify(list));
      return moveArrayItem(this.detail.from, this.detail.to, clonedList);
    }

    return list;
  }
}

export const getItemAtPosition = (viewport, list, itemHeights: number[], x: number, y: number): any => {
  const scrollOffset = viewport.measureScrollOffset();
  const viewportRect = viewport.elementRef.nativeElement.getBoundingClientRect();

  // Calculate the relative position within the viewport
  const relativeY = y - viewportRect.top + scrollOffset;

  // Find the item index by iterating through the item heights
  let currentOffset = 0;
  let index = -1;

  for (let i = 0; i < itemHeights.length; i++) {
    currentOffset += itemHeights[i];
    if (relativeY < currentOffset) {
      index = i;
      break;
    }
  }

  // Check if the index is within bounds
  if (index >= 0 && index < itemHeights.length) {
    return { element: list[index], index };
  } else {
    return null; // Return null if the position is out of bounds
  }
};
