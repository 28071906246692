import { calculateHandlePoint } from "../geofence_center";

declare var window: any;
export class CustomDirectMode {
  customMode = require('./../custom-drawer-libs/mapbox-gl-draw-circle/lib/modes/DirectModeOverride');
  turf = require('@turf/turf');

  constructor(private centerPoint: any) {
    const tc = this;
    //_
    //_ onDrag when is selected a point of the geofence and drag this point
    //_ Fired to center marker used to move the geofence
    //_
    tc.customMode.onDrag = function (state, e) {
      //_ Drag Vertext Features
      const canModify = Boolean(this._ctx.api.getSelected().features[0].properties['devicePermissions']?.modify) ?? true;
      tc.centerPoint.dragVertex.next({ state, e });
      if (state.canDragMove !== true || !canModify) return;

      state.dragMoving = true;

      const delta = {
        lng: e.lngLat.lng - state.dragMoveLocation.lng,
        lat: e.lngLat.lat - state.dragMoveLocation.lat
      };

      if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
      else return;

      state.dragMoveLocation = e.lngLat;
      if (this._ctx.api.getSelectedIds() && this._ctx.api.getSelectedIds()[0]) {
        let feature = this._ctx.api.getSelected().features[0]
        let polygon = tc.turf.polygon(feature.geometry.coordinates);
        //let centroid = tc.turf.centroid(polygon);
        let centroid = calculateHandlePoint(feature);

        // Move the center marker and the popup
        window[feature.id].setLngLat({ lng: centroid.geometry.coordinates[0], lat: centroid.geometry.coordinates[1] });
      }
    };

    tc.customMode.onTouchEnd = tc.customMode.onMouseUp = function (state) {
      const canModify = Boolean(this._ctx.api.getSelected().features[0].properties['devicePermissions']?.modify) ?? true;
      if (!canModify)
        return;

      this.isVertexCircleClicked = false;
      // if(window['radiusPopup'].isOpen()){
      //   //window['radiusPopup'].remove();
      // }
      if (state.dragMoving) {
        this.fireUpdate();
      }
      this.stopDragging(state);
      tc.centerPoint.dragVertexEnd.next({ state });
    };

    tc.customMode.onTrash = function (state) {
      this.trash(state);
      tc.centerPoint.dragVertexEnd.next({ state });
    }
  }
}
