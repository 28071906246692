import Supercluster from 'supercluster';
export interface clusterResponse {
    features, isSame?, needUpdate
}

export class ClusterDataSource {
    private supercluster: Supercluster;
    private currentBBOX = null;
    private currentZoom = 0;
    private lastFoundFeatures = [];
    private isSourceUpdated = false;
    public forceUpdate = false;
    constructor (datasource){
        this.supercluster = new Supercluster({
            radius: 30,
            maxZoom: 20,
            minZoom: 14,
            extent: 512,
            nodeSize: 32,
        });

        const features = datasource?.data?.features ?? []
        this.supercluster.load(features);
    }

    setData (newData) {
        this.supercluster.load(newData.data.features);
        this.isSourceUpdated = true;
    }

    getFeatures (bounds, zoom) : clusterResponse {
        const bbox: any = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
        //_ Go out when zoom is not close and trigger updated
        if (zoom < 9)
            return { features: [], needUpdate: false };

        //_ Go out when bbox is same like before or is an inner bbox
        //_ Check isSourceUpdated to force query new 
        const isSameBBox = JSON.stringify(this.currentBBOX) == JSON.stringify(bbox) || this.isBboxInside(bbox);
        if (isSameBBox && !this.isSourceUpdated && !this.forceUpdate)
            return { features: this.lastFoundFeatures, needUpdate: false };

        this.currentBBOX = [...bbox];
        this.currentZoom = zoom*1;

        const clusterData = this.supercluster.getClusters(bbox, zoom);
        let features = [];
        clusterData.forEach((cd: any) => {
            try {
                if (typeof cd.id === 'number') {
                    const leaves = this.supercluster.getLeaves(cd.id);
                    features = features.concat(leaves)
                }
                else
                    features.push(cd);
            } catch (e) {}
        })

        //_ Rollback value of isSourceUpdated
        this.isSourceUpdated = false;
        this.forceUpdate = false;
        this.lastFoundFeatures = [...features];
        return { features, needUpdate: features.length > 0 };
    }

    //_ Reset saved variables to be able to request again data
    resetVars () {
        this.currentBBOX = null;
        this.currentZoom = null;
    }

    //_ Check if a bbox is inside the Current Bbox
    isBboxInside(innerBbox) {
        if (!this.currentBBOX)
            return false;

        return innerBbox[0] >= this.currentBBOX[0] && innerBbox[1] >= this.currentBBOX[1] &&   
               innerBbox[2] <= this.currentBBOX[2] && innerBbox[3] <= this.currentBBOX[3];
    }

}