import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.page.html',
  styleUrls: ['./whatsnew.page.scss'],
})
export class WhatsnewPage implements OnInit {
  @Input() alert: any;
  @Input() messageKey: any;
  alertArray: Array<any> = []

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.alertArray = this.alert ? this.alert[this.messageKey].split('<br>') : [];
  }

  dismiss() {
    this.modalController.dismiss(this.alert)
  }




}
