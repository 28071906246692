import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ResizeService } from 'src/app/services/resize.service';

@Component({
  selector: 'app-select-modal',
  templateUrl: './select-modal.component.html',
  styleUrls: ['./select-modal.component.scss'],
})
export class SelectModalComponent implements OnInit {

  @Input() data;
  @Input() selectedItems = [];
  @Input() multiSelection = false;
  @Input() selectedItem = null;
  @Input() selectTemplete: TemplateRef<any> = null;
  @Input() itemTemplate: TemplateRef<any> = null;
  @Input() headerTemplete: TemplateRef<any> = null;
  @Input() footerTemplate: TemplateRef<any> = null;
  
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {

  }

  selectItem(item, index){
    if (this.multiSelection){
      if (item.checked){
        item.checked = false;
        this.selectedItems.splice( this.selectedItems.map(i=>i.id).indexOf(item.id), 1);
      }
      else{
        item.checked = true;
        this.selectedItems.push(item);
      }
    }
    else{
      this.selectedItems = [item];
      this.selectItem = item;
      this.modalCtrl.dismiss(item);
    }
  }

  accept(){
    this.modalCtrl.dismiss({ selectedItems: this.selectedItems });
  }

}
