<ion-header class="ion-no-border" *ngIf="devices?.length > 0">
    <ion-toolbar class="device-toolbar" mode="md" color="light">
      <ion-buttons slot="end" class="device-toolbar-button">
        <app-selectable class="device-toolbar-selectable"
          [items]="devices"
          [selItem]="device"
          tagId="id"
          tagName="name"
          color="light"
          [header]="false"
          icon="deviceImage"
          headerColor="light"
          [selectColor]="'secondary'"
          [canSearch]="devices?.length > 6 ? true : false"
          [showInPopover]="true"
          (onItemChanged)="deviceChanged($event)"
        ></app-selectable>
      </ion-buttons>
    </ion-toolbar>
</ion-header>

<!-- SEGMENTS to select last or todays data-->
<ion-header class="segment-header ion-no-border" style="--ion-safe-area-top: 0px;">
    <ion-toolbar mode="ios" color="transparent">
    <ion-segment [value]="activitySegment">
      <ion-segment-button value="last" (click)="changeActivitySegment('last')">
        {{"timeLine.lastActivity"| translate}}
      </ion-segment-button>
      <ion-segment-button value="today" (click)="changeActivitySegment('today')">
        {{"timeLine.todaysActivity"| translate}}
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<div class="content-card">
  <ng-container [ngTemplateOutlet]="devices.length > 0 ? DASHBOARD_CONTENT : NO_DEVICES"></ng-container>
</div>

<ng-template #NO_DEVICES>
  <ion-item class="no-devices" mode="md" lines="none" color="transparent">
    <ion-label class="ion-text-wrap ion-text-center">
      <ion-img src="assets/images/sidebar/gps.svg"></ion-img>
      <h1>{{ "selectSearchable.noDeviceText" | translate }}</h1>
    </ion-label>
  </ion-item>
</ng-template>

<ng-template #DASHBOARD_CONTENT>
  <app-last-logbook-routes [device]="device" [activityType]="activitySegment" [language]="language"></app-last-logbook-routes>
  <app-last-alarms [device]="device" [activityType]="activitySegment" (onShowRouteOnMap)="dismiss()" [language]="language"></app-last-alarms>
</ng-template>
