import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { SetupDeviceService } from '../device-setup-guide/device-setup.service';

@Component({
  selector: 'setup-device-name',
  templateUrl: './device-name.page.html',
  styleUrls: ['./device-name.page.scss'],
})
export class DeviceNamePage implements OnInit {
  @Input() device: any = null;
  isEdited: boolean = false;

  constructor(public appService: AppService, private setupDeviceService: SetupDeviceService) { }

  ngOnInit() {

  }

  toggleEdited(value: boolean){
    this.isEdited = value;
  }

  getItemColor() {
    return this.isEdited ? 'primary' : 'light';
  }

  changeName(ev) {
    this.device.name = ev.detail.value;
    this.setupDeviceService.onDeviceChange.next({ device: this.device, stepName: 'name' });
    this.toggleEdited(false);
  }

}
