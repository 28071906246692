
import { AvailableHooks } from "../componentHooks";
import { FeatureInterface } from "./feature-interface";
import { MainMapInterface } from "../../main-map.interface";
import { MapService } from "../../map.service";
import { inject } from "@angular/core";
import { MapStylesList } from "../mapInterfaces";
import { debounce } from 'lodash';
import { StorageService as Storage } from "../../../../../../services/storage.service";
import { Constants } from "src/app/constants.enum";

//_ ALL COMMENTED LINES ARE FOR 3D BUILDINGS
export class Terrain3D implements FeatureInterface {
    SOURCE_NAME: string = 'mapbox-3d-terrain';
    LAYER_NAME: string = 'dummy_layer_name'
    BUILDINGS_SOURCE_NAME: string = 'buildings-source';
    BUILDINGS_LAYER_NAME: string = 'add-3d-buildings';
    buildersSource = null;
    source = null;
    layer = null;
    buildersLayer = null;

    mapService = inject(MapService);
    show = false;
    obs = {};
    userSettings = null;
    storage = inject(Storage)

    constructor(public main: MainMapInterface){
        this.init();
    }

    init(){
        this.source = this.createSource();
        this.load();
        this.storage.get(Constants.USER_SETTINGS).then(res => {
          this.userSettings = res;
        })
    }

    createSource(){
      return {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      };
    }

    //_ Listen for Component hooks to load data
    load(){
        const debouncedEnable = debounce((dimension) => this.enableTerrain(dimension), 400)
        this.main.on(AvailableHooks.onLoadSource, (e) => {
          // this.enableTerrain(this.main.dimension);
          debouncedEnable(this.main.dimension);
        });

        //_ Check again if is dark mode; after map load all things
        this.main.HOOKS.on(AvailableHooks.onReady, () => {
            // this.enableTerrain(this.main.dimension);
            debouncedEnable(this.main.dimension);
        });

        //_ On destroy stop listening for changes
        this.main.HOOKS.on(AvailableHooks.onDestroy, () => {
            Object.keys(this.obs).forEach( (o: any) => this.obs[o].unsubscribe() );
        });

        this.obs['mapDimension'] = this.mapService.mapStyleDimension.subscribe(dimension => {
             this.enableTerrain(dimension);
            debouncedEnable(this.main.dimension);
        });
    }

    //_ Check if is dimension 3d to enable otherwise will unload the source
    enableTerrain(dimension){
      if (!this.main.map) return;

      if (dimension === '3d') {
        if(this.userSettings?.show_terrain_layer === 1 || this.userSettings?.show_terrain_layer === true){
          if (!this.main.map.getSource(this.SOURCE_NAME) && this.enableForMapStyle(this.main.mapStyle)) {
            console.log('ADDING TERRAIN')
            this.main.map?.addSource(this.SOURCE_NAME, this.source);
          }

          if (this.main.map.getSource(this.SOURCE_NAME))
            this.main.map.setTerrain({ source: this.SOURCE_NAME, exaggeration: 1.5 });
        }
      } else if (this.main.map.getSource(this.SOURCE_NAME)) {
        console.log('REMOVING TERRAIN');
        this.main.map.setTerrain(null);
        setTimeout(() => this.main.map.removeSource(this.SOURCE_NAME), 300);
      }
    }

    //_ Enable terrain only for those mapstyles
    enableForMapStyle(mapStyle){
      const enableMapStyles = ['mapbox_satellite_streets_v11', 'mapbox_outdoor_v11', 'mapbox_standard'];
      return enableMapStyles.some(style => style === mapStyle);
    }
}
