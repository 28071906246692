import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PulseMeasurementComponent } from './pulse-measurement.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PajMapAdressPickerModule } from 'src/app/components/map-address-picker/paj-map-address-picker.module';
import { PajMapMinModule } from 'src/app/members/generate-video/map-min/paj-map-min.module';
import { HeartBeatModule } from 'src/app/members/dashboard/components/heart-beat/heart-beat.module';



@NgModule({
  declarations: [PulseMeasurementComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    PajMapMinModule,
    PajMapAdressPickerModule,
    HeartBeatModule
  ]
})
export class PulseMeasurementModule { }
