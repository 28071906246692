import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { animations } from 'src/app/animations/animations';
import { NativesettingsService } from 'src/app/services/plugins/nativesettings.service';
import { AvailablePermissions, IPermission, PermissionList } from './permissions';
import { Capacitor } from "@capacitor/core";

@Component({
  selector: 'app-request-permissions',
  templateUrl: './request-permissions.component.html',
  styleUrls: ['./request-permissions.component.scss'],
  animations: animations
})
export class RequestPermissionsComponent implements OnInit {
  permissionEnabled = false;
  @Input() permissionName: AvailablePermissions;
  @Input() requestFn: any = null;
  @Input() callback: any = null;
  @Input() permissionCheckFn: any = null;
  @Input() permissionItem: IPermission = null;;
  obsPlatform = null;
  isIos = false;
  @ViewChild('backdrop', { read: ElementRef }) backdrop: ElementRef;
  constructor(private popoverCtrl: PopoverController, private nativeSettings: NativesettingsService, 
              private aPermissions: AndroidPermissions, private ngZone: NgZone, private platform: Platform,
              private translate: TranslateService) { }

  async ngOnInit() {
    this.isIos = Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios';
    this.permissionItem = PermissionList(this.translate)[this.permissionName];

    //_ Assign request, callback and permissionCheck custom Functions to the permission
    if (this.requestFn) 
      this.permissionItem.request = this.requestFn;
    if (this.callback) 
      this.permissionItem.callback = this.callback;
    if (this.permissionCheckFn)
      this.permissionItem.permissionCheckFn = this.permissionCheckFn;

    await this.requestPermission();
    this.checkPermission();
    this.obsPlatform = this.platform.resume.subscribe(() => this.ngZone.run(() => {
      this.checkPermission();
    }));
  }

  close() {
    this.popoverCtrl.dismiss( { hasPermission: this.permissionEnabled });
  }

  async requestPermission(){
    if (this.permissionItem.request)
      return this.permissionItem.request();
    else
      return this.aPermissions.requestPermission(this.permissionItem.permission);
  }

  //_ Check permission for ios and android; in case is enable then automaticly close the popup
  async checkPermission(){
    if (this.permissionItem.permissionCheckFn){
      this.permissionEnabled = await this.permissionItem.permissionCheckFn();
      if (this.permissionEnabled) this.close();
    } else {
      this.aPermissions.checkPermission(this.aPermissions.PERMISSION[this.permissionItem.permission]).then( async s => {
        this.permissionEnabled = s.hasPermission;
        if (this.permissionEnabled) this.close();
      });
    }
  }

  async openSettings(){
    const settingName = this.isIos ? this.permissionItem.iosOpenSetting : this.permissionItem.openSetting;
    await this.nativeSettings.open(settingName).then( r => {
    }).catch( e => {
    });

    this.checkPermission();
  }

  ngOnDestroy() {
    this.obsPlatform.unsubscribe()
  }
}
