import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewPullupService {
  setBreakPoint = new Subject();
  breakPointChanged = new Subject();
  startDragging = new Subject();
  endDragging = new Subject();
  currentBreakPoint = new BehaviorSubject('middle');
  onDragging = new BehaviorSubject(false);
  onDidDismiss = new Subject();
}