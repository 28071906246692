export class StackManager {
    stack: StackObject[] = [];
    
    constructor (private prefixKey = 'k'){

    }

    push (fn): StackObject{
        const id = this.generateId(this.prefixKey);
        const newProc = {
            id, fn, completed: false, priority: this.stack.length
        };

        this.stack.push(newProc);
        return this.stack[this.stack.length-1];
    }

    get (id){
        return this.stack.find(p => p.id == id) || null;
    }

    kill (id){
        const index = this.stack.map(p => p.id).indexOf(id);
        delete this.stack[index];
    }

    run (id){
        this.get(id).fn();
    }

    runByIndex (index){
        this.stack[index].fn();
    }

    indexOf (id){
        return this.stack.map(p => p.id).indexOf(id);
    }

    complete (id){
        // console.log('Proc completed', id);
        this.stack[this.indexOf(id)].completed = true;
    }

    setTopPriority(id){
        // const index = this.stack.map(p => p.id).indexOf(id);
        // this.stack[index].priority = 1000;
    }

    existProccessId(id){
        return this.indexOf(id);
    }

    generateId(prefix) {
        return prefix + Math.floor(Math.random() * Date.now())
    }

    getNextUncompleteProccess(){
        //_ Get uncomplete proccess
        let filteredArray = this.stack.filter(obj => obj.completed === false);
        if (filteredArray.length > 0){
            const nextProc = filteredArray.reduce((a,b) => a.priority > b.priority ? a : b)
            //_ return max priority proccess to next
            // console.log('NEXT PROCCESS', { stack: [...this.stack], next: {...nextProc}});
            return nextProc;
        }
        else 
            return null;
    }

    get maxItems (){
        return this.stack.length;
    }

}

export interface StackObject {
    id:any, 
    completed: boolean,
    fn: any,
    priority: number
}