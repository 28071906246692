import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { filter, first } from "rxjs/operators";
import { ModalController, PopoverController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-nontracking-devicelist',
  templateUrl: './nontracking-devicelist.component.html',
  styleUrls: ['./nontracking-devicelist.component.scss'],
})
export class NontrackingDevicelistComponent implements OnInit {
  @Input() devices:Array<any>;
  user = null;
  constructor(
    private appService: AppService,
    private _devicesService: DeviceDataService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private authService: AuthenticationService,
    private devicesService: DeviceDataService
  ) { }

  ngOnInit() {
    //this.showNonTrackingMessage();
    this.user = this.appService.user;


  }
  showNonTrackingMessage() {
    const getDevices = (d) => {
      this.devices = d.filter(d => d.privacy_mode);
    };
    this._devicesService.deviceUpdated$.subscribe(devices => {
      getDevices(this._devicesService.devices.map(d => d.properties))
    });
    if (this.appService.devices?.length > 0) {
      getDevices(this.appService.devices);
    }
  }
  dismissModal() {
    this.popoverController.dismiss();
  }
  turnoffNontracking(item) {
    item.privacy_mode = 0;
    let currentDevice = this._devicesService.devices[item.id];
    currentDevice.properties.privacy_mode = 0
    let data = {
      'privacy_mode': currentDevice.properties.privacy_mode
    }

    //_ Update the device in devices service
    this.authService.updateDevice(currentDevice.id, data).then(r => {
      this.devicesService.updateDeviceInfo(currentDevice);
    })

  let devices = this.devices.filter(device => device.privacy_mode == 1);
    if (devices.length <= 0) {
      this.dismissModal()
    }
    //this.showNonTrackingMessage();
  }
}
