import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PlatformUtils } from './plugins/platform-utils';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  private visibilitySubject = new BehaviorSubject<boolean>(true);
  private lastAppPausedTime: number | null = null;
  private elapsedTime: number | null = 0;
  public platform = PlatformUtils;

  isAppPaused = false;
  TIME_TO_PAUSE_ACTIVITY = 60; //_ 1 min
  visibility$: Observable<boolean> = this.visibilitySubject.asObservable();

  constructor(private ngZone: NgZone, private sPlatform: Platform) {
    this.initPageVisibilityListener();
    this.initAppStateCheck();
  }

  //_ CHECK BROWSER PLATFORM
  private initPageVisibilityListener() {
    document.addEventListener('visibilitychange', () => {
      this.ngZone.run(() => {
        const isVisible = document.visibilityState === 'visible';
        if (isVisible && this.isAppPaused) {
          this.appResume();
        } else {
          this.appPaused();
        }
      });
    });
  }

  //_ CHECK MOBILE PLATFORMS
  private initAppStateCheck() {
    this.sPlatform.resume.subscribe(async () => {
      // console.log('APP RESUMEd was paused =', this.appWasPaused)
      if (this.isAppPaused) {
        this.appResume();
      }
    });

    this.sPlatform.pause.subscribe(async () => {
      this.appPaused();
    });
  }

  private appPaused () {
    this.lastAppPausedTime = Date.now();
    this.isAppPaused = true;
    // console.log('USER LEAVES THE PAGE OPEN - leavesTime ', this.entryTime);
    this.visibilitySubject.next(false);
  }

  private appResume () {
    this.isAppPaused = false;
    if (this.lastAppPausedTime) {
      this.elapsedTime = Date.now() - this.lastAppPausedTime;
    }
    this.visibilitySubject.next(true);
  }

  isPageVisible () {
    return this.visibilitySubject.value;
  }

  getAppInactiveTime(): number | null {
    if (this.lastAppPausedTime && this.isPageVisible() === false)
      this.elapsedTime = Date.now() - this.lastAppPausedTime;
    else
      this.elapsedTime = 0;

    // console.log('TIMES', { elapsedTime: this.elapsedTime, entryTime: this.entryTime });
    if (this.elapsedTime > 0)
      return this.elapsedTime/1000;
    else
      return 0
  }
}
