import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PajDeviceAlertModule } from './alert/paj-device-submenu-alert.module';
import { PajDeviceCommandModule } from './command/paj-device-command.module';
import { DeviceSubmenuComponent } from './device-submenu.component';
import { PajDevicesInfoModule } from './info/paj-device-info.module';
import { PajDevicesRouteModule } from './route/paj-device-route.module';
import { PajDeviceSettingsTabModule } from "../../../../../components/device-settings-tab/paj-device-settings-tab.module";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translations/", ".json");
}

@NgModule({
    declarations: [DeviceSubmenuComponent],
    exports: [
        DeviceSubmenuComponent,
        PajDeviceAlertModule,
        PajDeviceCommandModule,
        PajDevicesInfoModule,
        PajDevicesRouteModule,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        //   PipesModule.forRoot(),
        PajDeviceAlertModule,
        PajDeviceCommandModule,
        PajDevicesInfoModule,
        PajDevicesRouteModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PajDeviceSettingsTabModule
    ]
})

  export class PajDeviceSubmenuModule {}