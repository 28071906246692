import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupWifiComponent } from './setup-wifi.component';
import { WifiListComponent } from './wifi-list/wifi-list.component';
import { HomeAddressPickerComponent } from './home-address-picker/home-address-picker.component';
import { IonicModule } from '@ionic/angular';
import { GuidedSetupComponent } from './guided-setup/guided-setup.component';
import { TranslateModule } from '@ngx-translate/core';
import { PajMapAdressPickerModule } from "../../../../../../../components/map-address-picker/paj-map-address-picker.module";
import { PajMapMinModule } from 'src/app/members/generate-video/map-min/paj-map-min.module';
import { FormsModule } from '@angular/forms';



@NgModule({
    declarations: [
        SetupWifiComponent,
        WifiListComponent,
        HomeAddressPickerComponent,
        GuidedSetupComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        TranslateModule,
        PajMapMinModule,
        PajMapAdressPickerModule
    ]
})
export class SetupWifiModule { }
