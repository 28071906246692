
import { MapComponent } from "../../map.component";
import { AvailableHooks } from "../componentHooks";
import { Layer, layerType } from "../layer";
import { FeatureInterface } from "./feature-interface";
import { getSource } from "../castMaplibreData";
import { MainMapInterface } from "../../main-map.interface";
import { MapService } from "../../map.service";
import { inject } from "@angular/core";

export class DeviceNameMarkers implements FeatureInterface {
    SOURCE_NAME: string = 'markersSource';
    LAYER_NAME: string = 'deviceNameMarkerLayer'
    source = null;
    layer = null;

    mapService = inject(MapService);
    show = this.mapService.showDeviceNameMarker.value;
    DEFAULT_TEXT_Y_OFFSET = -6;

    textFontSize = this.mapService.deviceNamefontSize.value;
    currentTextOffset = [0, this.DEFAULT_TEXT_Y_OFFSET];
    currentTextColor = { text: '#000', halo: '#fff' };
    obs = {};

    constructor(public main: MainMapInterface){
        this.init();
    }

    init(){
        this.source = this.main.markersSource;
        this.createLayer();
        this.load();
    }

    createLayer(){
        const shouldApplyDarkColors = this.main.appService.darkMode && this.main.mapStyle != 'pajLite';
        this.currentTextColor = shouldApplyDarkColors ? { text: '#fff', halo: '#000' } : { text: '#000', halo: '#fff' }
        this.currentTextOffset = this.calcTextOffset(this.textFontSize);

        this.layer = new Layer(this.LAYER_NAME);
        this.layer.source = this.SOURCE_NAME;
        this.layer.type = layerType.symbol;
        this.layer.layout["text-anchor"] = "top";
        this.layer.layout["text-field"] = ["get", "name"];
        this.layer.layout["text-offset"] = this.currentTextOffset; //-6
        this.layer.layout["text-allow-overlap"] = false;
        this.layer.layout["icon-rotation-alignment"] = "viewport"; //could be viewport|map
        this.layer.layout['text-font'] = ['Open Sans Regular'];
        this.layer.layout['text-size'] = this.textFontSize;
        this.layer.layout["icon-anchor"] = "bottom";
        this.layer.layout["icon-rotation-alignment"] = "viewport"; //could be viewport|map

        this.layer.paint['text-color'] = this.currentTextColor.text;
        this.layer.paint['text-halo-color'] = this.currentTextColor.halo;
        this.layer.paint['text-halo-width'] = 2;

        this.layer["filter"] = [
            "all",
            ["==", ['get', "deviceShow"], 1], //_ Show only if deviceShow == 1
            ['==', ['get', 'show'], true],
            [
                'any',
                ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
                ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
            ]
        ];
    }

    //_ Listen for Component hooks to load data
    load(){
        this.main.on(AvailableHooks.onLoadLayer, (e) => {
            if (!this.main.map.getLayer(this.LAYER_NAME))
                this.main.map.addLayer(this.layer, 'onlineDot');
        });

        this.main.on(AvailableHooks.onLoadSource, (e) => {
            if (!this.main.map.getSource(this.SOURCE_NAME))
                this.main.map.addSource(this.SOURCE_NAME, this.source);
        });

        this.main.on(AvailableHooks.onMarkerAdded, async (data) => {
            if (!data.device) return;
            //_ Prevent make this proccess if feature is not shown
            const device = data.device;
            const sourceIndex = this.main.markersSource.data.features.map((x: any) => x.properties['deviceId']).indexOf(device.id);
            const sourceFeature = this.main.markersSource.data.features[sourceIndex];

            sourceFeature.properties['name'] = (device.properties.name)?.slice(0, 40); //_ limit to 40 characters the name
            sourceFeature.properties['show'] = this.show;

            this.main.markersSource.data.features[sourceIndex] = sourceFeature;

            if (this.main.map.getSource(this.SOURCE_NAME) && data.updateMap)
                getSource(this.main.map, this.SOURCE_NAME).setData(this.main.markersSource.data);
            return ;
        });

        //_ Check again if is dark mode; after map load all things
        this.main.HOOKS.on(AvailableHooks.onReady, () => {
            this.checkDarkMode(this.main.appService.darkMode);
        });

        this.main.on(AvailableHooks.onSetSliderView, (e) => {
            this.setSliderView(e);
        });

        //_ On destroy stop listening for changes
        this.main.HOOKS.on(AvailableHooks.onDestroy, () => {
            Object.keys(this.obs).forEach( (o: any) => this.obs[o].unsubscribe() );
        });

        this.obs['darkMode'] = this.main.appService.darkMode$.subscribe(r => {
            this.checkDarkMode(r);
        });

        //_ in case set the map style and prev it was rendered using white colors; then update the color of the text
        this.obs['mapStyle'] = this.mapService.style$.subscribe((s) => {
            setTimeout( () => this.checkDarkMode(this.main.appService.darkMode), 1000);
        });

        //_ Listen for show/hide the layer
        this.obs['showDeviceMarker'] = this.mapService.showDeviceNameMarker.subscribe( status => {
            this.enableDisable(status);
            setTimeout(() => this.setFontSize(this.textFontSize), 300);
        });

        //_ Listen for fontSize changes
        this.obs['deviceFontSize'] = this.mapService.deviceNamefontSize.subscribe( fontSize => {
            this.setFontSize(fontSize);
            this.textFontSize = fontSize
        });
    }

    async uploadImage(name, dataImage){
        return new Promise ( async (resolve, reject) => {
            await this.main.map.loadImage(dataImage, async (error, image) => {
                if (error){
                    console.log("ERROR: ", error);
                    reject();
                }
                else{
                    if (!this.main.map.hasImage(name)) {
                        await this.main.map.addImage(name, image);
                        this.main.markersImages.push({ name: name, url: dataImage });
                        resolve(true);
                    }
                }
            });
        });
    }

    enableDisable(status){
        if (this.main.markersSource && this.main.map){
            this.show = status;
            this.main.markersSource.data.features.forEach((f) => {
                f.properties["show"] = status;
            });

            // And then update the source in the map :)
            if (this.main.map.getSource(this.SOURCE_NAME))
                getSource(this.main.map, this.SOURCE_NAME).setData(this.main.markersSource.data);
        }
    }

    checkDarkMode(isDark = false){
        if (isDark && this.main.mapStyle != 'pajLite')
            this.setTextColor('#fff', '#000');
        else
            this.setTextColor('#000', '#fff');
    }

    setFontSize(fontSize=12){
        if (this.textFontSize == fontSize) return;
        const newOffset = this.calcTextOffset(fontSize);

        if (this.main.map && this.main.map.getLayer(this.LAYER_NAME)){
            this.main.map.setLayoutProperty(this.LAYER_NAME, "text-size", fontSize);
            this.main.map.setLayoutProperty(this.LAYER_NAME, "text-offset", newOffset);
            this.layer.layout['text-size'] = fontSize;
            this.layer.layout["text-offset"] = newOffset;
            this.textFontSize = fontSize;
            this.currentTextOffset = newOffset;
        }
    }

    setTextColor(textColor, haloColor){
        if (this.currentTextColor.text == textColor && this.currentTextColor.halo == haloColor && this.main?.map && this.main?.map.getLayer(this.LAYER_NAME) )
            return

        if (this.main.map && this.main.map.getLayer(this.LAYER_NAME)){
            console.log('SEtting textColor', { textColor, haloColor });
            this.main.map.setPaintProperty(this.LAYER_NAME, "text-color", textColor);
            this.main.map.setPaintProperty(this.LAYER_NAME, "text-halo-color", haloColor);
            this.layer.paint['text-color'] = textColor;
            this.layer.paint['text-halo-color'] = haloColor;
            this.currentTextColor = { text: textColor, halo: haloColor };
            this.main.map.removeLayer(this.LAYER_NAME);

            if (!this.main.map.getLayer(this.LAYER_NAME))
              this.main.map.addLayer(this.layer);
            // this.main.map.style._updateLayer(this.main.map.getLayer(this.LAYER_NAME) as any); //_ Workaround to update the layer
        }
    }

    calcTextOffset(fontSize){
        let y = (fontSize-12) * 0.25;
        if (y > 3 ) y = 3;
        return  [0, this.DEFAULT_TEXT_Y_OFFSET + y];
    }

    updateName(device){
        const sourceIndex = this.main.markersSource.data.features.map((x: any) => x.properties['deviceId']).indexOf(device.id);
        const sourceFeature = this.main.markersSource.data.features[sourceIndex];

        if (sourceFeature) {
            sourceFeature.properties['name'] = (device.properties.name)?.slice(0, 40); //_ limit to 40 characters the name
            this.main.markersSource.data.features[sourceIndex] = sourceFeature;

            if (this.main.map.getSource(this.SOURCE_NAME))
            getSource(this.main.map, this.SOURCE_NAME).setData(this.main.markersSource.data);
        }

    }

    setSliderView (ev) {
        // NO NEED THIS BECAUSE USES SAME MARKERS SOURCE FROM MAIN MAP
        // _ Filter source
        // this.source.data.features.forEach((f) => {
        //   f.properties["isSliderViewOpen"] = ev.isEnable;
        // });

        // //_ Update source
        // if (this.main.map.getSource(this.SOURCE_NAME))
        //   getSource(this.main.map, "markersSource").setData(this.source.data);
    }
}
