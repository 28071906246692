import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BackcloseDirectiveModule } from 'src/app/directives/backclose-directive.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PajMapAdressPickerModule } from 'src/app/components/map-address-picker/paj-map-address-picker.module';
import { PajMapMinModule } from 'src/app/members/generate-video/map-min/paj-map-min.module';
import { TextInputComponent } from './text-input.component';



@NgModule({
  declarations: [TextInputComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    PajMapMinModule,
    PajMapAdressPickerModule
  ]
})
export class TextInputModule { }
