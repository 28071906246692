import { Directive, Input, ElementRef, Renderer2, OnChanges, SimpleChanges, NgModule } from '@angular/core';
import { MapStylesList } from '../members/map/components/map/class/mapInterfaces';

@Directive({
  selector: '[mapStyleBackground]'
})
export class MapBackgroundDirective implements OnChanges {
  @Input('mapStyleBackground') selectedStyle: string = 'paj';
  @Input() isDark: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(): void {
    this.updateBackground();
  }

  private updateBackground() {
    const selectedStyle = MapStylesList[this.selectedStyle];
    console.log('SELECTED STYLE =', { inputSTyle: this.selectedStyle, selectedList: selectedStyle})
    if (selectedStyle) {

      const backgroundColor = selectedStyle.backround || '#61c7f8';
      this.renderer.setStyle(this.el.nativeElement, 'background-color', backgroundColor);
    }
  }
}

@NgModule({
  declarations: [
    MapBackgroundDirective
  ],
  imports: [],
  exports: [
    MapBackgroundDirective
  ],
})
export class MapBackgroundDirectiveModule {}
