export class Style {
    version: number = 8;
    sources = { 'raster-tiles': new rasterTiles() };
    layers = [];
    glyphs = "https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=8f9eoaM4M6SVG4wDczub";
}

export class rasterTiles {
    type: string = 'raster';
    tiles = [];
    tileSize: number = 256;
    attribution: string = 'Map tiles, Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>';
}
