import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-shock-alert-popup',
  templateUrl: './shock-alert-popup.component.html',
  styleUrls: ['./shock-alert-popup.component.scss'],
})
export class ShockAlertPopupComponent implements OnInit {
  @Input() deviceId = null;

  constructor(private popoverCtrl: PopoverController, private authService: AuthenticationService,
              private appService: AppService, private translate: TranslateService, private alertService: AlertService) { }

  ngOnInit() {}

  dismiss(){
    this.popoverCtrl.dismiss();
  }

  //_ @values = { setupBattery: boolean, dontShow: boolean } 
  async accept(values){
    if (values.setupBattery){
      const updateData = { alarm_volt: 1, alarm_volt_value: '11.3' };
      this.authService.updateDevice(this.deviceId, updateData)
      .then( res => {
        this.appService.showToast(null, this.translate.instant('alerts.alertUpdatedMessage'), 2000, 'success');
      }).catch(e => {
        this.appService.showToast(null, this.translate.instant('alerts.alertNotUpdatedMessage'), 3000, 'danger');
      });
    }

    if (values.dontShow){
      this.alertService.DONT_ASK_AGAIN_SHOCK_ALERT_POPUP.set(true);
    }

    this.popoverCtrl.dismiss(values);
  }
}
