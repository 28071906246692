import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { StorageService as Storage } from './../../services/storage.service';
import { AlertController, PopoverController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { StepOptions, TourOptions } from './tour.component';

@Injectable({
  providedIn: 'root'
})

export class TourService {

  _currentStep = new Subject();
  _showStep = new Subject();
  _skip = new Subject();
  _end = new Subject();
  _stop = new Subject();
  _updateDomHole = new Subject<string>();
  _tourVisible = new BehaviorSubject(false);
  _buttonPressed = new BehaviorSubject('');

  maxSteps = 0;
  stepIndex = 0;
  steps = [];
  setTourSettings = new Subject<TourOptions>();
  tourStarts = new Subject<StepOptions[]>();
  constructor(private storage: Storage, private popover: PopoverController, private translate: TranslateService) {
    this._showStep.subscribe(step => this._currentStep.next(step));
  }

  start(_steps: StepOptions[]) {
    this.tourStarts.next(_steps);
    this.steps = _steps;
    this.maxSteps = this.steps.length - 1;
    this.stepIndex = 0;

    if (_steps[0]?.tour){
      this.setTourSettings.next(_steps[0].tour);
    }
      
    this._showStep.next(this.steps[this.stepIndex]);
    this._tourVisible.next(true);
    return this._currentStep;
  }

  next() {
    if (this.stepIndex == this.steps.length - 1)
      this._end.next(true);
    else {
      this.stepIndex += 1;
      this._showStep.next(this.steps[this.stepIndex]);
    }
  }

  back() {
    if (this.stepIndex == 0)
      this._end.next(true);
    else {
      this.stepIndex -= 1;
      this._showStep.next(this.steps[this.stepIndex]);
    }
  }

  end() {
    this._tourVisible.next(false);
    this._end.next(true);
  }

  skip() {
    this._skip.next(true);
  }

  stop() {
    this._stop.next(true);
  }
}