import { TourOptions } from "src/app/components/tour/tour.component";
import { Constants } from "src/app/constants.enum";
import { devicesListTour, deviceTour } from "src/app/members/guide-tour/devices-list";
import { DeviceItemComponent } from "../../device-item/device-item.component";
import { DevicesListComponent } from "./list.component";

export const startDeviceTour = async (deviceItemClass: DeviceItemComponent) => {
  let tour: TourOptions = { name: "tour.new-devices-list" };
  await deviceItemClass.storage.get(tour.name).then(async(r) => {
    if (!r) {
      if (deviceItemClass.tourSubscriber) {
        deviceItemClass.tourSubscriber.unsubscribe();
      }
      
      let prevStep: any = { name: '' };
      const TourSteps: any = deviceTour(tour, deviceItemClass._translate)
      if (await deviceItemClass.storage.get('SHOULD_IGNORE_FIRST_STEP') == true) {
        TourSteps.shift();
      }
        
      deviceItemClass.tourSubscriber = deviceItemClass.tourService.start(TourSteps).
      subscribe(async (step: any) => {        
        setTimeout( async (r) => {
          if (
            deviceItemClass.router.url == "/map" ||
            deviceItemClass.router.url == "/map/start-devices-tour" ||
            deviceItemClass.router.url == "/tabs/map"
          ) {
            
            if (step.title == "")
              deviceItemClass.deviceListService._deviceExpandSource.next({
              deviceID: deviceItemClass.device.id,
              expand: false,
            });
            
            if (step.name == "submenu-info") {              
              try{
                if(document.documentElement.clientWidth < 769)
                  deviceItemClass.drawerService.setBreakPoint.next('top')
              }catch{}
              
              deviceItemClass._deviceSubmenuService.selectSubmenuItem("info");                           
            }

            if (step.name == "submenu-route") {      
              deviceItemClass._deviceSubmenuService.selectSubmenuItem("route");
            }
        
            if (step.name == "submenu-alert") {
              deviceItemClass._deviceSubmenuService.selectSubmenuItem("alert");
            }
        
            if (step.name == "submenu-alert-geofence-relocate") {
              document.getElementById('device-view-tab-content-geofence')?.scrollIntoView();
            }
        
            if (step.name == "submenu-alert-geofence-resize") {
              document.getElementById('device-view-tab-content-geofence')?.scrollIntoView();
            }
        
            if (step.name == "submenu-settings") {
              deviceItemClass._deviceSubmenuService.selectSubmenuItem("settings");
              document.getElementById('device-list-device-submenu-settings')?.scrollTo({ top: 0 });
            }
          }
        }, 500);
      });
    
      
      if (deviceItemClass.stopTourSubscriber)
        deviceItemClass.stopTourSubscriber.unsubscribe();
    
      //_ END THE TOUR
      deviceItemClass.stopTourSubscriber = deviceItemClass.tourService._end.subscribe((r) => {
        if (deviceItemClass.tourService.steps[0].tour.name == tour.name) {
          deviceItemClass.tourSubscriber.unsubscribe();
          deviceItemClass.storage.set(tour.name, true);
        }
      });      

    }
  })  
}

export const startDevicesListTour = async (devicesListClass: DevicesListComponent) => {
  if (devicesListClass.flatList.length > 0) {
    let tour: TourOptions = { name: "tour.new-devices-list" }; //_ Feature no fully implemented, just for end tour works now.
    await devicesListClass.storage.get(tour.name).then((r) => {
      if (!r) {
        setTimeout( async (r) => {
          if (
            devicesListClass.router.url == "/map" ||
            devicesListClass.router.url == "/map/start-devices-tour" ||
            devicesListClass.router.url == "/tabs/map"
          ) {
            //_ Stop previous subscriptions
            if (devicesListClass.tourSubscriber) {
              devicesListClass.tourSubscriber.unsubscribe();
            }

            //_ Fix show tour on login screen
            const firstDevice = devicesListClass.flatList.filter(g => !g.isHeader)[0];
            let prevStep: any = { name: '' };
            //_ Go back to root the nav
            devicesListClass.devicesSidebarService.navigation.popToRoot();
            const TourSteps: any = devicesListTour(tour, devicesListClass.translate, firstDevice.device_id);
            
            //_ Flag to bypass first step of Devices Tour
            if (await devicesListClass.storage.get('SHOULD_IGNORE_FIRST_STEP') == true) {
               TourSteps.shift();
            }

            devicesListClass.tourSubscriber = devicesListClass.tourService.start(TourSteps)
              .subscribe(async (step: any) => {
                if (step.title == "")
                  devicesListClass._deviceListService._deviceExpandSource.next({
                    deviceID: firstDevice.device_id,
                    expand: false,
                  });

                if (step.name == 'deviceList-search' ) {
                  if (prevStep.name == 'menu-options-button')
                    devicesListClass.devicesSidebarService.openMenuOptions.next(false);
                    
                  if (devicesListClass.devicesSidebarService.isOpeninPullup)
                    devicesListClass.drawerService.setBreakPoint.next('top')
                }

                //_ Open menu options in the devices list
                if (step.name == 'menu-options-button') {
                  devicesListClass.devicesSidebarService.openMenuOptions.next(false);
                  const domEl = document.getElementById('dl-options');
                  if (domEl) domEl.click();
                }

                if (step.name == 'deviceList-filters-button') {
                    devicesListClass.devicesSidebarService.openMenuOptions.next(false);
                }

                if (step.name == 'showhide-device') {
                  //_ Open the group of the first device
                  if (!firstDevice.open) {
                    devicesListClass.flatList.forEach(d => {
                      if (d.isHeader) {
                        if (d.devices_groups_group_id == firstDevice.group_id)
                          d.open = true;
                      }
                      else if (d.group_id == firstDevice.group_id) {
                        d.open = true;
                      }
                    });
                  }
                  devicesListClass._deviceListService.selectDevice(firstDevice.device_id);
                }

                if (step.name == 'eye-button') {
                  if (prevStep.name == 'showhide-device')
                    devicesListClass.devicesSidebarService.openMenuOptions.next(false);

                  if (devicesListClass.devicesSidebarService.isOpeninPullup)
                    devicesListClass.drawerService.setBreakPoint.next('top')
                  devicesListClass._deviceListService.selectDevice(firstDevice.device_id);
                }

                if (step.name == "tap-click") {
                  const device: any = devicesListClass.devicesService.devices[firstDevice.device_id];
                  device.properties.deviceshow = firstDevice.deviceshow = true;
                  devicesListClass.devicesService.enableDevice(firstDevice.device_id);
                  if (prevStep.name == 'swipe-device')
                    devicesListClass.devicesSidebarService.navigation.pop();
                  else
                    devicesListClass.devicesSidebarService.openMenuOptions.next(false);

                  if (devicesListClass.devicesSidebarService.isOpeninPullup)
                    devicesListClass.drawerService.setBreakPoint.next('top')
                  devicesListClass._deviceListService.selectDevice(firstDevice.device_id);
                }

                if (step.name == 'swipe-device'){
                  if (prevStep.name == 'tap-click')
                    navigateTo(firstDevice.device_id, devicesListClass);

                  devicesListClass._deviceSubmenuService.selectSubmenuItem("info");
                  //_ Wait a bit until navigateTo finish
                  setTimeout(() => devicesListClass.swipeFromTour(), 300);
                }

                if (step.name == "submenu-info") {
                  // if (prevStep.name == 'swipe-device')
                  //   navigateTo(firstDevice.device_id, devicesListClass);

                  // devicesListClass._deviceSubmenuService.selectSubmenuItem("info");
                }

                if (step.name == "submenu-route") {
                  devicesListClass._deviceSubmenuService.selectSubmenuItem("route");
                }

                if (step.name == "submenu-alert") {
                  devicesListClass._deviceSubmenuService.selectSubmenuItem("alert");
                }

                if (step.name == "submenu-alert-geofence-relocate") {
                  document.getElementById('device-view-tab-content-geofence')?.scrollIntoView();
                }

                if (step.name == "submenu-alert-geofence-resize") {
                  document.getElementById('device-view-tab-content-geofence')?.scrollIntoView();
                }

                if (step.name == "submenu-settings") {
                  devicesListClass._deviceSubmenuService.selectSubmenuItem("settings");
                  document.getElementById('device-list-device-submenu-settings')?.scrollTo({ top: 0 });
                }

                prevStep = step;
              });
          }
        }, 500);

        if (devicesListClass.stopTourSubscriber)
          devicesListClass.stopTourSubscriber.unsubscribe();

        //_ END THE TOUR
        devicesListClass.stopTourSubscriber = devicesListClass.tourService._end.subscribe((r) => {
          if (devicesListClass.tourService.steps[0].tour.name == tour.name) {
            devicesListClass.tourSubscriber.unsubscribe();
            devicesListClass.storage.set(tour.name, true);
          }

        });
      }
    });
  }
}

export const navigateTo = async (deviceId, devicesListClass) => {
  await devicesListClass.storage.set(Constants.SELECTED_DEVICE, deviceId);
  let item = { device_id: deviceId, deviceshow: true };
  const itemData = (itemList) => { item = itemList; }
  devicesListClass.devicesSidebarService.navigation.push(DeviceItemComponent, { itemList: item, callBack: itemData });
}