import * as turf from '@turf/turf';
// import {
  // bearing as Tbearing,
  // centroid as Tcentroid,
  // destination as Tdestination,
  // distance as Tdistance,
  // MultiLineString,
  // point as Tpoint,
  // polygon as Tpolygon,
  // Properties,
  // rhumbBearing,
  // rhumbDistance,
  // transformTranslate
// } from '@turf';

// import { Marker } from 'mapbox-gl';
import { StorageService as Storage } from './../../../../../services/storage.service';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { Injectable } from '@angular/core';
import { DeviceDataService } from '../../../devicesData.service';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { HapticsService } from 'src/app/services/plugins/haptics.service';
import { TranslateService } from '@ngx-translate/core';
import { Feature, LineString } from "geojson";
import { Platform } from '@ionic/angular';
import { MainMapInterface } from '../main-map.interface';

declare var window: any;

@Injectable({ providedIn: 'root' })
export class CenterPoint {
  wasMoved = false;
  map: any;
  drawer: any;
  user: any;

  //_ Center point observables
  mouseDown = new Subject();
  mouseUp = new Subject();
  mouseMove = new Subject();

  //_ Vertex features observables
  dragVertex = new Subject();
  dragVertexEnd = new Subject();
  isMapMoving = false;
  isMapZooming = false;
  geoMarkerFeatures = [];
  isCenterActive = [];
  constructor(public authService: AuthenticationService, private storage: Storage, private devicesService: DeviceDataService, private appService: AppService,
    private hapticService: HapticsService, private translate: TranslateService, private platform: Platform,) {
  }

  generateCenterPoint(geofenceFeatures, map, draw, deviceMarkers) {
    geofenceFeatures.forEach((feature) => {
      this.createSinglePoint(feature, map, draw, deviceMarkers);
    });
  }

  createSinglePoint(feature, map, draw, deviceMarkers) {
    this.map = map;
    this.user = this.map.appService.user;

    this.drawer = draw;
    const that = this;

    var polygon = turf.polygon(feature.geometry.coordinates);
    var centroid: any = turf.centroid(polygon);
    var hPoint = calculateHandlePoint(feature);
    var el = document.createElement('div');
    el.className = 'geofence-marker';
    el.style.backgroundImage = 'url(../../../assets/images/map/markers/move.svg)';

    this.map.geoVertexMouseUp.subscribe(vertex => {
      if (vertex?.state?.featureId === feature.id) {
        console.log('VERTEXT UP ',  vertex);
        that.isCenterActive[feature.id] = true;
        window[feature.id].setDraggable(true);
      }
    })

    // el.setAttribute('[long-press]', 'true');

    // Delete the point is alreading existing in map
    this.removeCenterPoint(feature.id, false);

    window[feature.id] = new this.map.Marker(el, {
      //draggable: Boolean(feature.properties['devicePermissions']?.modify) //_ check device permission to enable draggable
      draggable: Boolean(false) //_ Hotfixed: Geofence Handle was movable without editing mode enabled on touch devices.
    }).setLngLat(hPoint.geometry.coordinates)
      .addTo(map.map);

    this.geoMarkerFeatures.push(feature);

    let touchStartTime;
    let longPressThreshold = 1500;
    let timerRef = null;
    let keepPressedRef = null;

    /* REMOVE THIS CODE
    function onUp(event) {
      if (timerRef) clearInterval(timerRef)
      if (keepPressedRef) clearImmediate(keepPressedRef);

      feature = draw.get(feature.id);
      var polygon = turf.polygon(feature.geometry.coordinates);
      var centroid = turf.centroid(polygon);

      // Unbind mouse/touch events
      map.map.off('mousemove', onMove);
      map.map.off('touchmove', onMove);

      draw.changeMode('simple_select')
      feature = draw.get(feature.id);



      //_ Show popup in the center
      var polygon = turf.polygon(feature.geometry.coordinates);
      var centroid = turf.centroid(polygon);
      event.lngLat.lng = centroid.geometry.coordinates[0];
      event.lngLat.lat = centroid.geometry.coordinates[1];
      that.mouseUp.next({ feature, polygon, centroid, event });

      if (this.wasMoved)
        drawUpdate(feature);

      this.wasMoved = false;
      //_ Enable edit mode for geofences only if device has modify permission
      if (Boolean(feature.properties['devicePermissions']?.modify)) {
        map.map.dragPan.enable();
        if (window[feature.id].isDraggable()) {
          //console.log("Center Active", {isCenterActive : isCenterActive[feature.id]})
          draw.changeMode('direct_select', { featureId: feature.id })
        }
      }

      // var polygon = turf.polygon(feature.geometry.coordinates);
      // var centroid = turf.centroid(polygon);
      var hPoint = calculateHandlePoint(feature);
      window[feature.id].setLngLat({ lng: hPoint.geometry.coordinates[0], lat: hPoint.geometry.coordinates[1] });
    }

    function onMove(event) {
      if (!window[feature.id]) return;

      if (that.isCenterActive[feature.id] === false){
      // if (window[feature.id].isDraggable() === false){
        if (timerRef) clearInterval(timerRef);
        return;
      }

      that.mouseMove.next({ feature, polygon, centroid, event });
      that.wasMoved = true;
    }

    function drawUpdate(feature) {
      that.updateFeature(feature);
    }

    */

    if (that.platform.is('mobile'))
      that.isCenterActive[feature.id] = false;
    else
      that.isCenterActive[feature.id] = true;
  }

  setupMapListeners (mapCmp: MainMapInterface | any, drawer) {
    this.map = mapCmp;
    const that = this;
    this.drawer = drawer;

    let touchStartTime;
    let longPressThreshold = 1500;
    let timerRef = null;
    let keepPressedRef = null;

    mapCmp.map.on('move', () => {
      // console.warn('[DEBUG] MAP MOVING')
      that.isMapMoving = true;
      clearTimeout(window['geofence_mapt_rotated']);
      window['geofence_mapt_rotated'] = setTimeout(() => {
        that.isMapMoving = false
      }, 200);
    });

    mapCmp.map.on('pitch', () => {
      // console.warn('[DEBUG] MAP PITCH')
      that.isMapZooming = true;
        clearTimeout(window['geofence_map_piched']);
        window['geofence_map_piched'] = setTimeout(() => {
          that.isMapZooming = false
        }, 200);
    });

    // mapCmp.map.on('render', function () {
    //   // if (!window[feature.id]) return;
    //   that.geoMarkerFeatures.forEach(feature => {
    //     var lngLat = window[feature.id].getLngLat();
    //     const minZoomLevel = 12;
    //     const isVisible = mapCmp.map.getBounds().contains(lngLat) ??  false;
    //     //_ Set opacity based on zoom and viewport
    //     window[feature.id].getElement().style.opacity = (isVisible && mapCmp.map.getZoom() > minZoomLevel) ? 0.3 : 0;
    //   })

    // });

    mapCmp.map.on('mousedown', function (e) {
      // if (!window[feature.id]) return;

      that.geoMarkerFeatures.forEach(feature => {
        //_ Run this when is not a mobile device
        if (e.originalEvent.target == window[feature.id].getElement() && !that.platform.is('mobile')) {
          console.log('[DEBUG] MOUSE DOWN EVENT -> ', { e, isMobile: that.platform.is('mobile'), feature });
          // console.log('MOUSE STARTED DOWN', window[feature.id].getElement()) this

          that.isCenterActive[feature.id] = true;
          window[feature.id].setDraggable(true);

          feature = drawer.get(feature.id);
          console.log('[DEBUG] MOUSE DOWN FEATURE DRAWER -> ', { feature });

          var polygon = turf.polygon(feature.geometry.coordinates);
          var centroid = turf.centroid(polygon);

          that.mouseDown.next({ feature, polygon, centroid, event: e });
          const el = window[feature.id].getElement();

          el.classList.add('active');

          //_ Check if has permission to modify to not allow DrageMode
          if (!Boolean(feature.properties['devicePermissions']?.modify)){
            mapCmp.map.once('mouseup', onUp.bind(that, feature));
            return ;
          }

          drawer.changeMode('simple_select');
          drawer.changeMode('DrageMode', { featureIds: feature.id, event: e, draw: drawer, centerPoint: that });
          // Prevent the default map drag behavior.
          e.preventDefault();

          // console.warn('[DEBUG] EVENT ON MOUSE DOWN', { e, feature, permission: feature.properties['devicePermissions'], draggable: window[feature.id].isDraggable() })
          mapCmp.map.on('mousemove', onMove.bind(that, feature));
          mapCmp.map.once('mouseup', onUp.bind(that, feature));
        }
        //_ Remove active class for other center_markers
        else if (e.originalEvent.target !== window[feature.id].getElement()) {
          //_ MOVED THIS LOGIC TO MAP COMPONENT
          // const el = window[feature.id].getElement();
          // if (el && drawer.getMode() === 'direct_select')
          //   el.classList.remove('active');
        }
      })
    });

    mapCmp.map.on('touchstart', function (e) {
      // if (!window[feature.id]) return;

      that.geoMarkerFeatures.forEach(feature => {
        if (e.originalEvent.target == window[feature.id].getElement()) {
          // if (!window[feature.id].isDraggable())
          if (!that.isCenterActive[feature.id])
            keepPressedRef =  setTimeout(() => that.appService.showToast(null, that.translate.instant('geofence.keepPressingToEnable'), 1000, 'warning', 'top'), 300);

          window[feature.id].setDraggable(that.isCenterActive[feature.id]);

          //_ Enable drag and simple_selection feature mode
          const enableDrag = () => {
            const currentTime = new Date().getTime();
            console.log('DIFF TIME', { centerActive: that.isCenterActive[feature.id], currentTime, touchStartTime, longPressThreshold, isCenterActive: that.isCenterActive[feature.id], feature })
            if (currentTime - touchStartTime >= longPressThreshold || that.isCenterActive[feature.id] == true) {
            // if (window[feature.id].isDraggable() == true || currentTime - touchStartTime >= longPressThreshold) {

              //_ Show Toast message when is disabled
              // if (!window[feature.id].isDraggable()) {
              if (!that.isCenterActive[feature.id]) {
                that.appService.showToast(null, that.translate.instant('geofence.editModeEnable'), 1000, 'success', 'top');
                that.hapticService?.vibrate(300);
              }

              that.isCenterActive[feature.id] = true;
              window[feature.id].setDraggable(true);
              feature = drawer.get(feature.id);
              // console.warn('DEBUG - enableDrag', { feature, drawer, geoMarkers: this.geoMarkerFeatures })
              var polygon = turf.polygon(feature.geometry.coordinates);
              var centroid = turf.centroid(polygon);

              that.mouseDown.next({ feature, polygon, centroid, event: e });
              const el = window[feature.id].getElement();
              el.classList.add('active');

              //_ Check if has permission to modify to not allow DrageMode
              if (!Boolean(feature.properties['devicePermissions']?.modify)){
                mapCmp.map.once('touchend', onUp.bind(that, feature));
                return ;
              }

              drawer.changeMode('simple_select');
              drawer.changeMode('DrageMode', { featureIds: feature.id, event: e, draw: drawer, centerPoint: that });

              if (e.points.length !== 1) return;

              // Prevent the default map drag behavior.
              e.preventDefault();

              mapCmp.map.on('touchmove', onMove.bind(that, feature));
              mapCmp.map.once('touchend', onUp.bind(that, feature));
            }
          };

          touchStartTime = new Date().getTime();
          console.log('Touch start time', { centerActive: that.isCenterActive[feature.id], feature, touchStartTime, draggable: window[feature.id].isDraggable()});
          //_ Set dragable to true if is already active
          window[feature.id].setDraggable(that.isCenterActive[feature.id]);

           if (!that.isCenterActive[feature.id]) {
          //if (!window[feature.id].isDraggable()) {
            const timeToTrigger = that.isCenterActive[feature.id] ? 0 : longPressThreshold;
            // const timeToTrigger = window[feature.id].isDraggable() ? 0 : longPressThreshold;

            timerRef = setTimeout(() => {
              enableDrag();
            }, timeToTrigger);
          } else {
            enableDrag();
          }
        } else {

          const disableGeoCenter = () => {
            feature = drawer.get(feature.id);
            // console.log('[DEBUG] FEATURE TOUCH EVENT -> ', { length: e.points.length});
            //_ Prevent disable geofence in case receive two touch points
            if (e.points.length > 1) return;

            //_ For removed center-point keeps checking removed features
            if (!feature) return;

            const clickInsideFeature = that.isPointInsidePolygon([[e.lngLat.lng, e.lngLat.lat]], feature);

            if (that.isCenterActive[feature.id] === true && !clickInsideFeature && !that.isMapMoving && !that.isMapZooming) {
              // console.log('DISABLING CENTER ACTIVE');
              that.isCenterActive[feature.id] = false;
              window[feature.id].setDraggable(false);
            }
          }

          //_ Wait a bit in case two finger are used to touch the map (it means is rotating or is zooming the map)
          clearTimeout(window[feature.id + '_touchStart']);
          window[feature.id + '_touchStart'] = setTimeout(() => disableGeoCenter(), 150);
        }
        //_ END Feature condition

        //_ Disable timer ref
        mapCmp.map.once('touchend', () => {
          if (timerRef) clearInterval(timerRef);
        });

        mapCmp.map.once('move', () => {
          if (timerRef) clearInterval(timerRef);
          if (keepPressedRef) clearInterval(keepPressedRef);
        });
      })
    }); //_ End touch event

    //_ SUB EVENTS
    function onUp(feature, event) {
      console.warn('DEBUG - onUP', { event, feature })
      if (timerRef) clearInterval(timerRef)
      if (keepPressedRef) clearImmediate(keepPressedRef);

      feature = drawer.get(feature.id);
      var polygon = turf.polygon(feature.geometry.coordinates);
      var centroid = turf.centroid(polygon);

      // Unbind mouse/touch events
      mapCmp.map.off('mousemove', onMove.bind(that, feature));
      mapCmp.map.off('touchmove', onMove.bind(that, feature));
      drawer.changeMode('simple_select')
      feature = drawer.get(feature.id);

      //_ Show popup in the center
      var polygon = turf.polygon(feature.geometry.coordinates);
      var centroid = turf.centroid(polygon);
      event.lngLat.lng = centroid.geometry.coordinates[0];
      event.lngLat.lat = centroid.geometry.coordinates[1];
      that.mouseUp.next({ feature, polygon, centroid, event });

      if (that.wasMoved)
        drawUpdate(feature);

      that.wasMoved = false;
      //_ Enable edit mode for geofences only if device has modify permission
      if (Boolean(feature.properties['devicePermissions']?.modify)) {
        mapCmp.map.dragPan.enable();
        if (window[feature.id].isDraggable()) {
          //console.log("Center Active", {isCenterActive : isCenterActive[feature.id]})
          drawer.changeMode('direct_select', { featureId: feature.id })
        }
      }

      // var polygon = turf.polygon(feature.geometry.coordinates);
      // var centroid = turf.centroid(polygon);
      var hPoint = calculateHandlePoint(feature);
      window[feature.id].setLngLat({ lng: hPoint.geometry.coordinates[0], lat: hPoint.geometry.coordinates[1] });
    }

    function onMove(feature, event) {
      if (!window[feature.id]) return;

      if (that.isCenterActive[feature.id] === false){
      // if (window[feature.id].isDraggable() === false){
        if (timerRef) clearInterval(timerRef);
        return;
      }

      var polygon = turf.polygon(feature.geometry.coordinates);
      var centroid: any = turf.centroid(polygon);
      that.mouseMove.next({ feature, polygon, centroid, event });
      that.wasMoved = true;
    }

    function drawUpdate(feature) {
      that.updateFeature(feature);
    }
  }

  updateFeature(feature) {
    if (feature.type === 'Feature') {
      let type, coordinates, stringCoordinates, options;
      switch (feature.geometry.type) {
        case 'Polygon':
          if (feature.properties.isCircle) {
            type = 1;
          } else {
            type = 3;
          }
          break;
        case 'Circle':
          type = 1;
          break;
        default:
          break;
      }
      stringCoordinates = '[';
      let index = 0;
      options = '{}';
      coordinates = feature.geometry.coordinates;
      const dID = feature.id.split('_');
      coordinates.forEach(coordinateArray => {
        coordinateArray.forEach(coordinate => {
          stringCoordinates = stringCoordinates.concat('[');
          stringCoordinates = stringCoordinates.concat(coordinate.toString());
          if (index === coordinateArray.length - 1) {
            stringCoordinates = stringCoordinates.concat(']');
          } else {
            stringCoordinates = stringCoordinates.concat('],');
          }
          index = index + 1;
        });
      });

      stringCoordinates = stringCoordinates.concat(']');
      const opacity = feature.properties.opacity ?? 0.25;
      if (feature.properties.portColor) {
        var portColor = feature.properties.portColor;
      }
      if (type === 1) {
        const centerArray = feature.properties.center;
        let centerCoordinates = '[';
        centerCoordinates = centerCoordinates.concat(centerArray.toString());
        centerCoordinates = centerCoordinates.concat(']');
        options = '{"color":"' + portColor +
          '","fillColor":"' + portColor + '","radius":"' + (feature.properties.radiusInKm * 1000) +
          '","center":"' + centerCoordinates + '", "opacity": ' + opacity + '}';
        const dID = feature.id.split('_');
        // this.authService.updateRadiusInfo(+ dID[1],
        //   feature.properties.radiusInKm * 1000,
        //   centerArray[1],
        //   centerArray[0]);
      } else {
        options = '{"color":"' + portColor +
          '","fillColor":"' + portColor + '", "opacity": ' + opacity + '}';
      }
      // update handle point
      const  hPoint = calculateHandlePoint(feature)
      window[feature.id].setLngLat({lng: hPoint.geometry.coordinates[0], lat: hPoint.geometry.coordinates[1]});
      this.map.geofenceLineIndicatingFeature.updateLineIndication(feature);

      this.authService.updateGeofence(+ dID[0], + dID[1], stringCoordinates, type, options, feature.properties.name).then(res => {
        if (res) {
          if (res.success) {
            res = res.success;
            this.devicesService.setGeofence(res);
          }
        }
      });

    }
  }

  isCenterPointDragMode(id) {
    if (window[id] && window[id] != undefined && window[id] != 'undefined') {
      return window[id].isDraggable();
    } else return false
  }

  setCenterPointActive(id) {
    if (window[id] && window[id] != undefined && window[id] != 'undefined') {
      const el = window[id].getElement();
      el.classList.add('active');
    }
  }

  getHPointOfFeature(id) {
    if (window[id] && window[id] != undefined && window[id] != 'undefined') {
      const lnglat = window[id].getLngLat();
      return  turf.point([lnglat.lng, lnglat.lat]);
    }
  }

  lineIndicatingHandlePoint(feature) {
    if (window[feature.id] && window[feature.id] != undefined && window[feature.id] != 'undefined') {
      const lnglat = window[feature.id].getLngLat();
      const handlePoint  = turf.point([lnglat.lng, lnglat.lat]);
      feature  = this.drawer.get(feature.id);
      if (feature.properties.isCircle) {
        //_ transform the polygon to a line
        const polyToLine = turf.polygonToLine(feature);
        //_ find nearest point from handle point to the polygon
        const pointOnLine = turf.nearestPointOnLine(polyToLine as any, handlePoint as any);
        return turf.lineString(
          [pointOnLine.geometry.coordinates, handlePoint.geometry.coordinates]);
      } else {
        const polygon = turf.polygon(feature.geometry.coordinates);
        // find center of the polygon
        const center = turf.centroid(polygon);
        //_ line center to handle point
        const lineCenterToHPoint = turf.lineString(
          [
            center.geometry.coordinates,
            handlePoint.geometry.coordinates
          ]);
        //_ find intersecting point between the polygon and the line
        const interSectPoint = turf.lineIntersect(polygon, lineCenterToHPoint);
        // console.warn('DEBUG - lineIndicatingHandlePoint', { interSectPoint,  handlePoint, feature });
        if (interSectPoint.features.length === 0) {
          const polyToLine = turf.polygonToLine(feature);
          const pointOnLine = turf.nearestPointOnLine(polyToLine as any, handlePoint as any);
          return turf.lineString([pointOnLine.geometry.coordinates, handlePoint.geometry.coordinates]);
        } else {
          return turf.lineString(
            [interSectPoint.features[0].geometry.coordinates, handlePoint.geometry.coordinates]
          );
        }
      }
    }
  }

  removeCenterPoint(id, deleteLineIndicationFeature = true) {
    if (window[id] && window[id] != undefined && window[id] != 'undefined') {
      window[id].remove();
      delete window[id];
      this.geoMarkerFeatures = this.geoMarkerFeatures.filter(gf => gf.id !== id);
      if (deleteLineIndicationFeature)
        this.map?.geofenceLineIndicatingFeature.deleteLineIndication(id);
    }
  }

  getShapeFeature(geofence, geometryShape) {
    const feature = {
      id: geofence.id.toString() + '_' + geofence.iddevice.toString(),
      type: 'Feature',
      properties: {},
      geometry: geometryShape
    };
    const obj = JSON.parse(geofence.options);
    let properties;
    switch (geofence.shape_type) {
      case 1: // draw circle geomtry
        const lnglat = [0, 0];
        const circleProperties = { center: [], isCircle: true, radiusInKm: 0 };
        const options = JSON.parse(geofence.options);

        let cc = options.center.split('[');
        cc = cc[1].split(',');
        lnglat[0] = +cc[0];
        cc = cc[1].split(']');
        lnglat[1] = +cc[0];
        circleProperties.center = lnglat;
        circleProperties.radiusInKm = + options.radius;
        circleProperties.radiusInKm = circleProperties.radiusInKm / 1000; // in kilometers
        properties = circleProperties;
        break;
      case 2: // draw rectangle
        properties = {};
        break;
      case 3: // draw polygon
        properties = {};
        break;
      default:
        break;
    }
    feature.properties = properties;
    return feature;
  }

  getGeometryShape(geofence) {
    const coordinatesArray = JSON.parse('[' + geofence.coordinates + ']');
    return { type: 'Polygon', coordinates: coordinatesArray };
  }

  isPointInsidePolygon(pointCoordinates: number[][], polygonFeature: any): boolean {
    for (const coords of pointCoordinates) {
        const point = turf.point(coords);
        const isInside = turf.booleanPointInPolygon(point, polygonFeature);
        if (isInside) {
            return true;
        }
    }
    return false;
  }

}

export const calculateHandlePoint = (feature) => {
  if (feature.properties.isCircle) {
   return handlePointWhenFeatureIsACircle(feature, 1);
  } else {
    return handlePointWhenFeatureIsAPolygon(feature, 0.3);
  }
}

const handlePointWhenFeatureIsAPolygon = (feature, distanceFactor) => {
  // Get  bounding box of the polygon
  const bbox = turf.bbox(feature);
  const minLon = bbox[0];
  const minLat = bbox[1];
  const maxLon = bbox[2];
  const maxLat = bbox[3];

  // Calculate a reduced distance based on reduction factor
  const width = turf.distance(turf.point([minLon, minLat]), turf.point([maxLon, minLat]));
  const height = turf.distance(turf.point([minLon, minLat]), turf.point([minLon, maxLat]));
  const reducedDistance = Math.min(width, height) * distanceFactor;

  // right point (Est)
  const rPoint = turf.destination(turf.point([maxLon, maxLat]), reducedDistance, 90);

  // down point (south)
  const dPoint = turf.destination(turf.point([maxLon, minLat]), reducedDistance, 0);

  // Combine both points
  return  turf.point([rPoint.geometry.coordinates[0], dPoint.geometry.coordinates[1]]);
}

export const boxPolygon = (feature, distance) => {
  const buffer =  turf.buffer(feature, distance);
  return turf.bboxPolygon(turf.bbox(buffer));
}

export const centerMapOnGeofence = (feature, distance) => {
  // Calculate the bounding box of the geofence
  const bounds = turf.bbox(feature); // [minLng, minLat, maxLng, maxLat]

  // Extend the bounds by distance
  const extendByDegrees = turf.convertLength(distance, 'kilometers', 'degrees'); // Convert km to degrees

  // Extend the bounds
  return  [
    bounds[0] - extendByDegrees, // minLng - margin
    bounds[1] - extendByDegrees, // minLat - margin
    bounds[2] + extendByDegrees, // maxLng + margin
    bounds[3] + extendByDegrees, // maxLat + margin
  ];
}

const handlePointWhenFeatureIsACircle = (feature, reductionFactor) => {
  const distance = feature.properties.radiusInKm * reductionFactor
  const center = feature.properties.center;
  const rPoint = turf.destination(center, distance, 90);
  const bPoint = turf.destination(center, distance, 180);
  return  turf.point([rPoint.geometry.coordinates[0] , bPoint.geometry.coordinates[1]]);
}

export class StaticMode {

  onSetup() {
    this.setActionableState(); // default actionable state is false for all actions
    return {};
  }

  toDisplayFeatures(state, geojson, display) {
    display(geojson);
  };
  setActionableState() {

  }
}


export class CenterDragMode {
  onSetup(opts) {
    // turn the opts into state.
    const state = {
      dragMoveLocation: null,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      dragMoveing: true,
      canDragMove: true,
      selectedFeature: opts.featureIds || false,
      lastMouseDownLngLat: false,
      originalCenter: false,
      mode: 'drag' || false,

    };
    this.onMouseDown(state, opts.event);
    return state;
  }
  toDisplayFeatures(state, geojson, display) {
    display(geojson);
  };


  onMouseDown(state, e) {
    if (e.featureTarget) {
      if (state.draw.get(e.featureTarget.properties.id)) {
        e.target['dragPan'].disable();
        state.selectedFeature = state.draw.get(e.featureTarget.properties.id);
        state.originalCenter = turf.centroid(e.featureTarget);
        state.originalFeature = e.featureTarget;
      }
    }
    return state;


  }
  onDrag(state, e) {
    if (state.selectedFeature) {
      state.lastMouseDownLngLat = { lng: e.lngLat.lng, lat: e.lngLat.lat };
      var draggedBearing = turf.bearing(state.originalCenter, [e.lngLat.lng, e.lngLat.lat]);
      var dragCoords = [];
      var polyCoords = [];

      var poly = turf.polygon(state.originalFeature.geometry.coordinates);
      //calculates the centroid of the polygon
      var center = turf.centroid(poly);

      var from = turf.point(center.geometry.coordinates);

      var to = turf.point([e.lngLat.lng, e.lngLat.lat]);

      //finds the bearing angle between the points
      var bearing = turf.rhumbBearing(from, to);
      //calculates the distance between the points
      var distance = turf.rhumbDistance(from, to);

      //moves the polygon to the distance on the direction angle.
      var translatedPoly = turf.transformTranslate(poly, distance, bearing)


      state.originalFeature.geometry.coordinates[0].forEach(function (coords, index) {
        var distanceFromCenter = turf.distance(state.originalCenter, coords);
        var bearingFromCenter = turf.bearing(state.originalCenter, coords);
        var newPoint = turf.destination(state.originalCenter, distanceFromCenter, bearingFromCenter + draggedBearing);
        polyCoords.push(newPoint.geometry.coordinates);
      })
      dragCoords.push(polyCoords);

      var newFeature = state.selectedFeature;
      newFeature.geometry.coordinates = translatedPoly.geometry.coordinates;
      var thisFeat = state.draw.add(newFeature);


    }
  }
  onMouseUp(state, e) {
    e.target['dragPan'].enable();

    state.selectedFeature = false;
    state.lastMouseDownLngLat = false;
    state.originalCenter = false;
    state = {}
    return state;
  }
}

