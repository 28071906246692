import { Feature } from './feature';
import { Geometry } from './geometry';
import { IProperties } from './Iproperties';

export enum dataTypes { FeatureCollection = 'FeatureCollection', Feature = 'Feature', };
export enum dataSourceType { geojson = 'geojson' };
export interface Data {
    type: dataTypes;
    properties: IProperties;// = <IProperties>{};
    geometry: Geometry;
    features: Feature[];// = [];


}