import { Component, Input, OnInit } from '@angular/core';
import { SetupDeviceService } from '../device-setup-guide/device-setup.service';
import { ApiService } from 'src/app/services/api.service';
import { DevicesSidebarService } from '../../map/devices-sidebar/devices-sidebar.service';
import { FlatToGrouped, UpdateGroupsTotal } from '../../map/devices-sidebar/devices-list/list/list-helper';

@Component({
  selector: 'app-device-group-selection',
  templateUrl: './device-group-selection.component.html',
  styleUrls: ['./device-group-selection.component.scss']
})
export class DeviceGroupSelectionComponent implements OnInit {
  @Input() deviceGroups = [];
  @Input() device = null;
  
  constructor(private setupDeviceService: SetupDeviceService, private apiService: ApiService,
              private devicesSidebarService: DevicesSidebarService){}

  ngOnInit(): void {
  }

  selectGroup(group) {
    this.device['selectedGroup'] = group;
    this.updateGroupInBackend(group);
    
  }

  updateGroupInBackend(group) {
    let postData = { device_id: this.device.id, group_id: group.devices_groups_group_id, device_position: 1 };
    this.apiService.updateOrder(postData).then(
      async (res) => { 
        // console.log('GROUP UPDATED', res)
        // const updatedGroups = UpdateGroupsTotal(this.devicesSidebarService.groups.get().value, FlatToGrouped(this.flatList), group_id, device_id, device_position, deviceItem.device);
        // this.devicesSidebarService.groupsUpdated.next( updatedGroups );
        this.setupDeviceService.onDeviceChange.next({ device: this.device, stepName: 'color' });
      },
      async (error) => {
        console.log("ERROR: ", error);
      }
    );
  }
}
