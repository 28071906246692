import { Injectable } from '@angular/core';
import { FileOpener, FileOpenerOptions } from "@capacitor-community/file-opener";

@Injectable({
  providedIn: 'root'
})
export class FileOpenerService {

  constructor() { }

 async openFile(file) {
    let options: FileOpenerOptions = {
      filePath: file
    }
   await FileOpener.open(options)
  }
  async  showOpenWithDialog(file, fileType) {
    let options: FileOpenerOptions = {
      filePath: file,
      contentType: fileType,
      openWithDefault:false
    }
    await  FileOpener.open(options)
  }
}
