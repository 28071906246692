import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { BackcloseDirectiveModule } from 'src/app/directives/backclose-directive.module';
import { CsPopoverComponent } from './cspopover.component';


@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      BackcloseDirectiveModule
    ],

    declarations: [CsPopoverComponent],
    exports: [CsPopoverComponent],
    
  })

  export class PajInfoPopupModule {}