import { GeoJSONSource } from "maplibre-gl";

//_ None generic specific GEO Json type
export const getGeoJsonSource = (map: any, sourceName: string) => {
    const source: GeoJSONSource = map.getSource(sourceName);
    return source;
}

//_ Generic casting
export const getSource = (map: any, sourceName: string) => {
    const source: any = map.getSource(sourceName);
    return source;
}