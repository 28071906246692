import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-setting',
  templateUrl: './alert-setting.component.html',
  styleUrls: ['./alert-setting.component.scss'],
})
export class AlertSettingComponent implements OnInit {
  @Input() deviceID;
  @Input() data;
  @Input() map;
  device: any = {};
  constructor(
    public modelCtrl: ModalController
  ) { }

  ngOnInit() {
    this.device =  this.data.find(x=>x.id == this.deviceID);
  }

  async dismiss() {
    await this.modelCtrl.dismiss({});
  }
}
