<ion-content>
  <ion-list lines="none">
    <ion-radio-group [value]="selectedItem">
      <ion-item button [detail]="false" (click)="selectItem('center')">
        <ion-label>Center</ion-label>
        <ion-icon src="assets/images/map/slider/focus.svg" slot="start"></ion-icon>
        <ion-radio [value]="'center'" slot="end"></ion-radio>
      </ion-item>
      <ion-item button [detail]="false" (click)="selectItem('inBounds')">
        <ion-label>Into Bounds</ion-label>
        <ion-icon src="assets/images/map/slider/in-bounds.svg" slot="start"></ion-icon>
        <ion-radio [value]="'inBounds'" slot="end"></ion-radio>
      </ion-item>
      <ion-item button [detail]="false" (click)="selectItem('navigation')">
        <ion-label>Navigation</ion-label>
        <ion-icon src="assets/images/map/slider/navigation1.svg" slot="start"></ion-icon>
        <ion-radio [value]="'navigation'" slot="end"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
