export const App_Currency = [
  {
      id: '$',
      shortCode: 'USD',
      name: 'American Dollar',
      img: 'assets/images/currency-picker/dollar.svg'
  },
  {
      id: '€',
      shortCode: 'EUR',
      name: 'Euro',
      img: 'assets/images/currency-picker/euro.svg'
  },  
  {
      id: '£',
      shortCode: 'GBP',
      name: 'Pound Sterling',
      img: ''
  },
  {
      id: 'zł',
      shortCode: 'PLN',
      name: 'Zloty',
      img: 'assets/images/currency-picker/zloty.svg'
  },  
  {
      id: 'Q',
      shortCode: 'GTQ',
      name: 'Quetzal',
      img: 'assets/images/currency-picker/quetzal.svg'
  },  
  {
      id: '$',
      shortCode: 'MXN',
      name: 'Peso mexicano',
      img: 'assets/images/currency-picker/peso.svg'
  },
  {
    id: "₹",
    shortCode: "INR",
    name: "Rupee",
    img: 'assets/images/currency-picker/rupee.svg' 
  },
  {
    id: "$",
    shortCode: "AUD",
    name: "Australian dollar",
    img: 'assets/images/currency-picker/rupee.svg' 
  },
  {
    id: "$",
    shortCode: "CAD",
    name: "Canadian dollar",
    img: 'assets/images/currency-picker/rupee.svg' 
  },
  {
    id: "kr",
    shortCode: "SEK",
    name: "Svenska kronan",
    img: 'assets/images/currency-picker/rupee.svg' 
  }, 
  {
    id: "₺",
    shortCode: "TRY",
    name: "Türk Liras",
    img: 'assets/images/currency-picker/rupee.svg' 
  },
  {
    id: "₣",
    shortCode: "CHF",
    name: "Swiss franc",
    img: 'assets/images/currency-picker/rupee.svg' 
  }
];
