import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
  selector: 'app-testaccount-message',
  templateUrl: './testaccount-message.component.html',
  styleUrls: ['./testaccount-message.component.scss'],
})
export class TestaccountMessageComponent  implements OnInit {
  isOpen:boolean = false
  constructor(private popoverController: PopoverController, private logoutService: LogoutService, public appService:AppService) { }

  ngOnInit() {
    this.isOpen = this.appService.user.isTestAccount;
  }

  dismissPopup() {
    this.isOpen = false
  }
  logout(){
    this.logoutService.logout();
    this.isOpen = false
  }

}
