<ion-content class="ion-no-padding">
  <!-- Close button -->
  <ion-button
    class="close-btn ion-no-margin ion-no-padding"
    fill="clear"
    shape="round"
    (click)="close()"
  >
    <ion-icon name="close-circle-outline"></ion-icon>
  </ion-button>

  <ion-card color="transparent">
    <ion-item class="ion-text-wrap ion-text-center ion-no-padding" lines="none">
      <ion-label  class="title ion-text-center ion-text-wrap"
        ><h1>{{ "guidedTour.chooseTour" | translate }} </h1>
      </ion-label>
    </ion-item>
    <ion-item lines="none" color="transparent">
      <ion-label class="ion-text-center ion-text-wrap">
        <ion-icon
          src="assets/images/tour/tooltip.svg"
          style="height: 50px; width: 50px"
        >
        </ion-icon>
        <p>
          {{ "guidedTour.doYouWantToTake" | translate
          }}<b>{{ "guidedTour.guidedTour" | translate }}</b>
          {{ "guidedTour.learnFunctionOfApp" | translate
          }}
          {{
            "guidedTour.application" | translate
          }}?
          <!-- <ion-note>{{
            "guidedTour.application" | translate
          }}</ion-note
          >? -->
        </p>
      </ion-label>
    </ion-item>

    <!-- Tour list -->
    <ion-card-content class="ion-no-margin">
      <div class="tour-list">
        <ion-item
          lines="none"
          button
          class="ion-no-padding"
          mode="md"
          (click)="close('map')"
        >
          <ion-label class="ion-text-wrap ion-text-center" style="padding-bottom: 5px">
            <ion-img src="assets/images/tour/map-tour.png"> </ion-img>
            {{ "guidedTour.mapPage" | translate }}
          </ion-label>
        </ion-item>

        <ion-item
          lines="none"
          button
          class="ion-no-padding"
          mode="md"
          (click)="close('devicesList')"
        >
          <ion-label class="ion-text-wrap ion-text-center" style="padding-bottom: 5px">
            <ion-img src="assets/images/tour/device-tour.png"></ion-img>
            {{ "guidedTour.devicesList" | translate }}
          </ion-label>
        </ion-item>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
