import { Injectable } from "@angular/core";
import { Constants } from "../../constants.enum";
import { StorageService as Storage } from "./../../services/storage.service";
import { ApiService } from "src/app/services/api.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DeviceManagmentService {
  imageUpdated = new Subject();
  constructor(storage: Storage) {
  }

  updateImage(iconname, iconusecustom, iconcustomimage) {
    this.imageUpdated.next({ iconname, iconusecustom, iconcustomimage });
  }
}