import { Injectable } from "@angular/core";
import {
  Camera,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera";

@Injectable({
  providedIn: "root",
})
export class CameraService {
  constructor() {}

  public async getPicture() {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri, // file-based data; provides best performance
      source: CameraSource.Camera, // automatically take a new photo with the camera
      quality: 50, // highest quality (0 to 100)
    });
    return await capturedPhoto;
  }


}
