import { Injectable } from '@angular/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PwaUpdateComponent } from 'src/app/components/pwa-update/pwa-update.component';
import { STORAGE_SETTINGS, StorageSetting } from '../storage-settings';
import { StorageService } from '../storage.service';
import { AlertService } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/alert/alert.service';
import { PwaUpdateService } from '../pwa-update.service';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class AppupdateService {
  updatePopup = null;
  lastUpdateAlert = new StorageSetting(this.storage, STORAGE_SETTINGS.LAST_UPDATE_ALERT, null);
  LAST_AVAILABLE_RELEASE_DATE = 'LAST_AVAILABLE_RELEASE_DATE';
  constructor(
    private alertController: AlertController,
    private _translate: TranslateService,
    private modalCtrl: ModalController,
    private storage: StorageService,
    private platform: Platform,
    private updateService: PwaUpdateService
    ) { }

  async checkForUpdate() {
    if ((this.platform.is('ios') || this.platform.is('android')) && this.platform.is('capacitor'))
      await this.getAvailableAppVersion()
  }

  //_ Get available version from play store
  //_ Save the last Realease versin to compare in case new update is available with same version number
  async getAvailableAppVersion() {
    const result = await AppUpdate.getAppUpdateInfo();
    const availableReleaseDate = moment(result.availableVersionReleaseDate);
    const storageReleaseDate = await this.storage.get(this.LAST_AVAILABLE_RELEASE_DATE);

    //_ Compare version greater than current and also check the date of last release version
    const releaseDateIsAfter = storageReleaseDate ? availableReleaseDate.isAfter( moment(storageReleaseDate) ) : false;
    const existNewUpdate = this.availableVersionIsGreater(result.currentVersionName, result.availableVersionName) || releaseDateIsAfter;

    //_ Show the popup and save the available release date
    if (existNewUpdate) {
      this.storage.set(this.LAST_AVAILABLE_RELEASE_DATE, result.availableVersionReleaseDate);
      await this.showAppUpdateAlert()
    }
  };

  //_ Simple popup
  async openAppStore() {
    const alert = await this.alertController.create({
      header: this._translate.instant("appUpdate.header"),
      message: this._translate.instant("appUpdate.message"),
      mode: "ios",
      buttons: [
        {
          text: this._translate.instant("appUpdate.appStore"),
          handler: async () => {
            await AppUpdate.openAppStore();
          },
        },
        {
          text: this._translate.instant("appUpdate.notNow"),
          handler: async () => {
          },
        },
      ],
    });
    await alert.present();
  }

  //_ Nice popup :)
  async showAppUpdateAlert() {
    if (this.updatePopup) this.modalCtrl.dismiss();

    //_If there is no storeged alert then, should not show the popup
    if (!this.lastUpdateAlert.get().value) 
      return;

    const onClickUpdate = async () => {
      // this.lastUpdateDone.set(moment().unix());
      await AppUpdate.openAppStore();
    }

    this.updatePopup = await this.modalCtrl.create( {
      component: PwaUpdateComponent,
      cssClass: 'pwa-popup',
      componentProps: {
        showWhatsNew: true,
        alert: this.lastUpdateAlert.get().value,
        onUpdate: onClickUpdate
      }
    });

    //_ Only show in browsers not in native mobile apps.
    await this.updatePopup.present();
    await this.updatePopup.onWillDismiss();
    this.updatePopup = null;
  }

  //_ Compare version string numbers
  availableVersionIsGreater(currentVersion, availableVersion) {
    const current = currentVersion.split('.').map(Number);
    const available = availableVersion.split('.').map(Number);
  
    for (let i = 0; i < current.length; i++) {
      if (current[i] < available[i]) {
        return true;
      } else if (current[i] > available[i]) {
        return false;
      }
    }
  
    return false;
  }

}
