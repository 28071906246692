import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceSubmenuService {

  public deviceMarkers;
  public deviceData;
  public selectedMenuItem = 'info';
  public map;


  //Menu Item:
  private _submenuItemSelectedSource = new Subject<string>();
  submenuItemSelected$ = this._submenuItemSelectedSource.asObservable();

  //Device:
  private _submenuDeviceIDSelectedSource = new Subject<string>();
  submenuDeviceIDSelected$ = this._submenuDeviceIDSelectedSource.asObservable();

  private lastSelectedDevice;
  constructor() { }
  getDeviceData() {
    return this.deviceData;
  }
  selectSubmenuItem(selectedItem) {
    this.selectedMenuItem = selectedItem;
    this._submenuItemSelectedSource.next(selectedItem);
  }

  selectDevice(deviceID) {
    if (this.lastSelectedDevice != deviceID)
      this._submenuDeviceIDSelectedSource.next(deviceID);
  }

  getSelectedSubmenuItem() {
    return this.selectedMenuItem;
  }
}
