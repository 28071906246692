import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
@Injectable({
  providedIn: "root",
})
export class DeepLinkService {
  constructor(private router: Router, private zone: NgZone) { }

  initializeDeepLink() {
    //console.log("initializeDeepLink");
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      //console.log('appUrlOpen', event);
      this.zone.run(() => {
        //console.log('event.url', event.url);
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        //console.log("initializeDeepLink", event);
        const slug = event.url.split(".com").pop();
        //console.log('slug', slug);
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
    App.addListener('appStateChange', ({ isActive }) => {
      //console.log('App state changed. Is active?', isActive);
    });
    App.addListener('appRestoredResult', data => {
      //console.log('Restored state:', data);
    });
    const checkAppLaunchUrl = async () => {
      const { url } = await App.getLaunchUrl();
      //console.log('App opened with URL: ' + url);
    };
  }
}
