import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PopoverController, IonCheckbox, ModalController  } from '@ionic/angular';
import { IonPopover } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { STORAGE_SETTINGS, StorageSetting } from 'src/app/services/storage-settings';
import { StorageService } from 'src/app/services/storage.service';

import { saveAlertIsRead } from 'src/app/components/server-alert/util';
import { Constants } from 'src/app/constants.enum';
import { DEFAULT_SURVEY_CONTENT } from './survey-default-content';

@Component({
  selector: 'app-survey-popup',
  templateUrl: './survey-popup.component.html',
  styleUrls: ['./survey-popup.component.scss'],
})
export class SurveyPopupComponent  implements OnInit {
  @Input() alert = null;
  selectedOption: number;
  uptoDateEmail: false;
  alertContent = 'No Content';
  languageKey = 'message';

  constructor(
    private popoverController: PopoverController, private storage: StorageService,
    private modalCtrl: ModalController, private authService: AuthenticationService,
    public appService: AppService, private _translate: TranslateService
  ) { }

  ngOnInit() {
    // console.log('ALERT SURVEY', this.alert);

    if (this.alert) {
      this.getAlertMessage();
    }
  }

  async getAlertMessage () {
    return this.storage.get(Constants.LANGUAGE).then(language => {
      this.languageKey = !language.startsWith('en') && language != null ? 'message_' + language : 'message';
      this.languageKey = language == 'pt' ? 'message_pr' :  this.languageKey;


      this.alertContent = this.alert[this.languageKey] ?? this.alert['message'];
      console.warn('[DEBUG] getAlertMessage ', { languageKey: this.languageKey, alert: this.alert, language, alertContent: this.alertContent })
      // console.log('ALERT MESSAGE', { language, languageKey: this.languageKey, alertContent: this.alertContent })
    });
  }

  dismissPopup() {
    this.popoverController.dismiss();
  }

  selectOption(option: number) {
    this.selectedOption = option;
    let data = { survey_response: this.selectedOption, uptodate_via_email: this.uptoDateEmail ?? false }
    // console.log("data iss : ", data);

    this.appService.showToast('', this._translate.instant("survey.success"), 2000, 'success');

    //_ Async update the setting in backend
    this.authService.updateUserSettings(this.appService.user.id, data).then((r: any) => {
      //_ set read state of the alert
      if (this.alert)
        saveAlertIsRead(this.alert, this.storage);
    })
    // .catch(error => {
    //   console.log("ERROR: ", error);
    //   this.appService.showToast('', this._translate.instant("survey.error"), 3000, 'danger');
    // });

    this.dismissPopup();
  }
}
