import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'map-battery-info-popup',
  templateUrl: './battery-info-popup.component.html',
  styleUrls: ['./battery-info-popup.component.scss'],
})
export class BatteryInfoPopupComponent implements OnInit {
  @Input() title = '';
  @Input() message = null;
  constructor(private popup: PopoverController, public appService: AppService) { }

  ngOnInit() {
    // this.title = this.translate.instant(this.title);
    // this.message = this.translate.instant(this.message);
  }

  onCancel() {
    this.popup.dismiss(null)
  }

}
