import * as Mgrs from 'mgrs';
import { AppService } from '../app.service';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { StorageService } from '../services/storage.service'
import { Constants } from '../constants.enum';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'latLngToMgrs',
  standalone: true
})
export class LatLngToMgrsPipe implements PipeTransform {
  constructor(private appService: AppService, private decimalPipe: DecimalPipe, private storage: StorageService) {}

  async transform (latitude: any, longitude: any): Promise<string> {
    return this.storage.get(Constants.USER_SETTINGS).then( settings => {
        // console.log('USER SETTINGS', settings)
        if (settings?.use_mgrs_coords) {
        const mgrsString = Mgrs.forward([+longitude, +latitude]);
        return mgrsString;
        }
        else {
            return this.decimalPipe.transform(+latitude, '0.0-8') + ', ' + this.decimalPipe.transform(+longitude, '0.0-8');
        }
    })
    
  }
}