<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-buttons>
        <ion-back-button
          (click)="dismiss()"
          style="display: block !important"
          back-locale
        ></ion-back-button>
      </ion-buttons>
    </ion-buttons>
    <ion-title>{{ "profileImage.Name" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <img
    class="deafultIcon profile"
    [src]="userProfile | secureMedia : false : 'assets/images/company/user.svg' | async"
    onerror="this.src='assets/images/company/user.svg'"
    *ngIf="!imageChangedEvent"
  />
  <div class="ion-text-center" *ngIf="userProfile !== 'assets/images/company/user.svg' && !imageChangedEvent">
    <ion-button (click)="removePhoto()" size="small" shape="round">
      <ion-icon slot="start" name="trash-outline"></ion-icon>
      {{ "profileImage.removeImage" | translate }}
    </ion-button>
  </div>

  <div *ngIf="imageChangedEvent" class="cropper-main-div">
    <label for="">{{ "user-img-com.crop-image" | translate }}</label>
    <ion-icon
      class="flaticon-close-button pointer"
      (click)="restoreImage()"
    ></ion-icon>
    <ion-button color="primary" (click)="uploadImageToServer()">
      <ion-icon slot="start" name="crop-outline"></ion-icon>
      <ion-label>{{ "profileImage.CropImage-text" | translate }}</ion-label>
    </ion-button>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      (roundCropper)="(true)"
      output="base64"
    ></image-cropper>
  </div>

  <input
    type="file"
    style="display: none"
    accept="image/*"
    (change)="fileupload($event)"
    #fileinput
  />
</ion-content>


<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col  (click)="openCamera()" class="center-align pointer">
        <ion-icon class="bottom-icon" name="camera-outline"></ion-icon>
        <ion-label color="primary" class="bottom-label"
          >{{ "deviceManagement.deviceImg.takePhotoText" | translate }}
        </ion-label>
      </ion-col>
      <ion-col (click)="fileinput.click()" class="center-align pointer">
        <ion-icon class="bottom-icon" name="cloud-upload-outline"></ion-icon>
        <ion-label color="primary" class="bottom-label"
          >{{ "deviceManagement.deviceImg.selectImgText" | translate }}
        </ion-label>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
