<paj-popup-template [headerTemplate]="customHeader" [contentTemplate]="Content" cssContentClass="fixed-height">

</paj-popup-template>

<ng-template #customHeader>
    <ion-header mode="md" class="ion-no-border headerposition">
      <ion-toolbar color="dark">
        <ion-buttons slot="end">
          <ion-button (click)="dismissModal()">
            <ion-icon
              slot="icon-only"
              color="medium"
              name="close-circle-outline"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ "nonTracking.nonTrackingDevices" | translate }}</ion-title>
      </ion-toolbar>
    </ion-header>
</ng-template>

<ng-template #Content>
  <ion-list lines="none" mode="md">
    <div *ngFor="let item of devices; let i = index">
      <ion-item *ngIf="item.privacy_mode == 1">
        <ion-avatar style="--border-radius: 0" slot="start">
          <img
            style="object-fit: contain"
            [src]="item | DeviceImageSanitizer: user?.id | async"
          />
        </ion-avatar>
        <ion-label class="ion-text-wrap"><h3>{{ item.name }}</h3></ion-label>
        <ion-toggle
          mode = "ios" class="ion-custom"
          [checked]="item.privacy_mode"
          (ionChange)="turnoffNontracking(item)"
          aria-label=""
          slot="end"
        ></ion-toggle>
      </ion-item>
    </div>
  </ion-list>
</ng-template>
