import * as moment from 'moment';
import { getDistance } from '../map/class/mapUtil';
let primaryColor = '#F05123';
let lightColor = '#000';

export const createConfig: any = (data, title = null, distance_unit:any, translatedSpeedLabel?:any) => {
  let style = getComputedStyle(document.body);
  primaryColor = style.getPropertyValue('--ion-color-primary');
  lightColor = style.getPropertyValue('--ion-color-light-contrast');

  return {
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: false, // test if resolve rendering issue
      plugins: {
        title: {
          display: title ? true : false,
          text: title
        },
        legend:{
          display:false
        },
        tooltip: {
          enabled: false,
        },
      },
      interaction: {
        intersect: false,
      },
      scales: {
        x: {

          display: false,
          title: {
            display: false
          },
          grid: {
            display: false
          }
        },
        y: {
          // backgroundColor: 'yellow',
          ticks: {
              beginAtZero: true,
            font: {
                    size: 10,
                },
            color: lightColor,
            callback: function(value) {
                return value + ' ' + distance_unit;
            }
          },



          display: true,
          title: {
            display: false,
            text: translateLabel(translatedSpeedLabel, "Speed")
          },
          grid: {
            display: false
          },
          suggestedMin: 0,
          //suggestedMax: 100,
          //min: 0,
          //max: 100,
          //ticks: {
          //stepSize: 20
          //}
        }
      }
    },
  };
}

export const createData = (datapoints, color, trSpeedLabel?, trDistanceLabel?) => {
  //const labels = datapoints.map( d => moment.unix(d.dateunix).format('DD-MM-YYYY HH:mm'));
  const labels = datapoints.map(d => moment.unix(d.dateunix).format('HH:mm'));
  const speeds = datapoints.map(d => d.speed);
  let distances = datapoints.map(d => d.distance);

  return {
    labels: labels,
    datasets: [
      {
        type: 'bar',
        label: translateLabel(trSpeedLabel, 'Speed'),
        data: speeds,
        borderColor: primaryColor,
        backgroundColor: primaryColor,
        fill: true,
        //cubicInterpolationMode: 'monotone',
        tension: 0.1,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderRadius: 0,
      },
      //_ Add distance chard below the speed bars dataset
      // {
      //   type: 'line',
      //   label: translateLabel(trDistanceLabel, 'Distance'),
      //   // data: distances,
      //   fill: true,
      //   //cubicInterpolationMode: 'monotone',
      //   // tension: 0.1, borderColor: '#00ff00',
      //   backgroundColor: '#00000088',
      //   pointRadius: 0,
      //   //pointHoverRadius: 0,
      //   //borderRadius: 10
      // }
    ]
  };
}

export const calcDistance = (p1, p2) => {
  const d = getDistance(p1.lat, p1.lng, p2.lat, p2.lng, 'K');
  return d ? d : 0;
}

const translateLabel = (translatedLabel: string, originalLabel: string) =>
   translatedLabel ? translatedLabel : originalLabel;



