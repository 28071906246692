<paj-popup-template headerTitle="{{ 'alerts.shockPopup.title' | translate }}" [contentTemplate]="Content">

</paj-popup-template>

<ng-template #Content>
  <ion-grid fixed class="ion-padding">
    <ion-row>
      <ion-col size="12" class="ion-no-padding">
        <ion-list lines="none">
          <ion-item>
            <ion-text slot="start" class="ion-text-wrap">
              {{ infoText | translate }}
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-checkbox slot="start" #dontRemind></ion-checkbox>
            <ion-label class="ion-text-wrap">{{ 'alerts.shockPopup.dontShowAgain' | translate }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-no-padding ion-text-center">
        <ion-button shape="round" color="light" (click)="accept(dontRemind.checked)">
          <ion-label>{{ 'deviceManagement.okButton' | translate }}</ion-label>
        </ion-button>
        <!-- <ion-button shape="round" color="light" (click)="dontRemind.checked = !dontRemind.checked">
          <ion-label>{{ 'alerts.shockPopup.dontShowAgain' | translate }}</ion-label>
        </ion-button> -->
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>

