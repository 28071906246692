<ion-content>
  <ion-list>
    <ion-radio-group [value]="selectedItem.type">
      <ion-item
        button
        (click)="select(type)"
        *ngFor="let type of geoTypes"
        lines="none"
        mode="md"
      >
        <ion-label>{{ type.name }}</ion-label>
        <ion-icon slot="start" src="{{ type.image }}" color="primary">
        </ion-icon>
        <ion-radio [value]="type.type" slot="end" mode="ios"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
