import { BehaviorSubject } from "rxjs";
import { StorageService } from "./storage.service";

//_ Wrote by  :)
export enum STORAGE_SETTINGS {
  ISGHOST = "ISGHOST",
  ADMIN_ID = "ADMIN_ID",

  //_ PWA UPDATE SERVICE
  LAST_UPDATE_DONE = "LAST_UPDATE_DONE",
  LAST_UPDATE_ALERT = "LAST_UPDATE_ALERT",

  //_ DEVICES SIDEBAR
  DEVICES_SIDEBAR_STATUS = "DEVICES_SIDEBAR_STATUS",
  DEVICES_FAB_LIST = "DEVICES_FAB_LIST",
  DEVICES_SIDEBAR_FILTERS = "DEVICES_SIDEBAR_FILTERS",
  DEVICES_SIDEBAR_GROUPS = "DEVICES_SIDEBAR_GROUPS",
  DEVICES_SIDEBAR_LIST = "DEVICES_SIDEBAR_LIST",
  DEVICES_SIDEBAR_USER_PERMISSIONS = "DEVICES_SIDEBAR_USER_PERMISSIONS",

  //_ ALERTS SETTINGS
  ALERTS_NOT_SHOW_SHOCK_ALERT_POPUP = "ALERTS_NOT_SHOW_SHOCK_ALERT_POPUP",
  ALERTS_NOT_SHOW_SHOCK_ALERT_INFO_POPUP = "ALERTS_NOT_SHOW_SHOCK_ALERT_INFO_POPUP",
  ALERTS_NOT_SHOW_REMOVAL_ALERT_INFO_POPUP = "ALERTS_NOT_SHOW_REMOVAL_ALERT_INFO_POPUP",

  //_ MAP SETTINGS
  DONT_SHOW_AGAIN_PERFORMANCE_POPUP = "DONT_SHOW_AGAIN_PERFORMANCE_POPUP",
  PET_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED = "PET_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED",
  USB_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED = "USB_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED",

  //_ DASHBOARD
  DASHBOARD_DATE_RANGE = "DASHBOARD_DATE_RANGE",

  //_ DEVICE ACTIVITY
  DEVICE_ACTIVITY_SELECTED_DEVICE = "DEVICE_ACTIVITY_SELECTED_DEVICE",
  DEVICE_ACTIVITY_TYPE = "DEVICE_ACTIVITY_TYPE",

  //_ DEVICES TIMELINE
  DEVICES_TIMELINE_LAST_DATE_RANGE = "DEVICES_TIMELINE_LAST_DATE_RANGE",

  //_USER MESSAGE IN LOGBOOK
  DONT_SHOW_USER_MSG = "DONT_SHOW_USER_MSG",

  //_CARID IN LOGBOOK
  CAR_ID = "CAR_ID"
}

export interface IStorageSetting {
  get() : BehaviorSubject<any>;
  value : any;
  set(value) : any;
  remove() : any;
}

export class StorageSetting implements IStorageSetting{
  setting = new BehaviorSubject<any>(null);
  value = this.setting.value;
  debug = false;
  constructor (private storage: StorageService, private type: STORAGE_SETTINGS, public InitialValue = null){
    this.storage?.storageReady.subscribe( async r => {
      if (r){
        //_ SET VALUE FROM STORAGE
        this.setting.subscribe(s => {
          if (s != null){
            this.storage.set(type, s);
          }
        });

        //_ GET VALUE FROM STORAGE
        await this.storage.get(type).then( s => {
          if (s == null && InitialValue != null)
            this.setting.next(this.InitialValue);
          else
            this.setting.next(s);
        });
      }
    });
  }

  get () {
    return this.setting;
  }

  set(value) {
    this.setting.next(value);
  }

  remove(){
    this.storage.remove(this.type);
    this.setting.next(this.InitialValue);
  }
}

export { StorageService };
