import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { InvertColor } from 'src/app/directives/colorHelper';
import { SetupDeviceService } from '../device-setup-guide/device-setup.service';
import { ModalController } from '@ionic/angular';
import { MapMinComponent } from '../../generate-video/map-min/map-min.component';
import { CommunicationService } from '../communitaction.service';

export interface ColorInterface { selected: boolean, color: string, isCustom?: boolean, iconColor?: string };
@Component({
  selector: 'setup-device-color',
  templateUrl: './device-color.page.html',
  styleUrls: ['./device-color.page.scss'],
})
export class DeviceColorPage implements OnInit, AfterViewInit {
  @Input() device;
  colors: Array<ColorInterface> = [];
  selectedColor: ColorInterface = null;
  stringPath = 'ezu|cBug{uN_sDs@keAU_pECi{@Yea@SsNMwoA{Ema@uBea@mFyJsB_LeDcVmJwJcFiJcG_HwEyIqHkFiEe`@qa@ow@e~@qfA_pA_r@kx@uQkW{[}o@}l@oyAyi@miAwCkGcAwB{I}Q_gAcvB{BsEyJ_SsA{CNq@Ls@Dw@@w@Cw@Gw@Ms@Qm@Wk@Ya@_@]c@Uc@Ic@?e@Ha@T_@\\]f@sEqIoGqLgLiTcPoZoJiPkAwB}Pg[uT_]gH}GoLgMwY_Z{EeEaTiTcOgOsf@kj@_IaI}C}AsCiAiC{@qDq@uHaAsYeAam@sD_KcBiGcDiJkLmOqRaDiE_DcEcNcZgQs_@_EaK_BjBwOhIyEhCcB|AcFlE}WlUkHbMeN|XmFvHaC|AoC}KeEeM}BiFaImO}DoG_CaEiTg_@kNsVmFiJcJoQ_CwDsFmDqFiAuLHcOHy\\PeNnAch@rEmz@nJ}Il@_LfCoKrEoHlIka@vd@cLhQsDiKsHyv@xCkGhf@gb@|Rb_@';
  constructor(private setupDeviceService: SetupDeviceService, public modalCtrl: ModalController, private communicationService: CommunicationService) { }

  ngOnInit() {
    let style = getComputedStyle(document.body);
    
    this.colors= [
      { selected: false, color: style.getPropertyValue('--ion-color-primary').trim() },
      { selected: false, color: '#0788FF' },
      { selected: false, color: style.getPropertyValue('--ion-color-dark').trim() },
      { selected: false, color: style.getPropertyValue('--ion-color-warning').trim() },
      { selected: false, color: style.getPropertyValue('--ion-color-danger').trim() },
      { selected: false, color: style.getPropertyValue('--ion-color-success').trim() },
      { selected: false, color: 'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);', isCustom: true, iconColor: '#fff' }
    ];

    //_ select default color
    this.colors.forEach(c => {
      if (c.color == this.device.spurfarbe){
        this.selectedColor = c;
        c.selected = true;
      }
    });

    //_ Otherwise select custom
    if (!this.selectedColor){
      this.selectedColor = this.colors[this.colors.length-1]
      this.selectedColor.selected = true;
      this.selectedColor.color = this.device.spurfarbe;
      this.selectedColor.iconColor = InvertColor(this.selectedColor.color, true);
    }
  }

  ngAfterViewInit(): void {
    
  }

  selectColor(color) {
    //_ Ignore click when click on color input 
    if (color.isCustom && color.selected)
      return;
    else{
      color.selected = !color.selected;
      this.selectedColor = color.selected ? color : null;
    }
    
    
    this.colors.forEach(c => {
      if (c.color != color.color)
        c.selected = false;
    });

    this.device.spurfarbe = this.selectedColor.color;
    this.setupDeviceService.onDeviceChange.next({ device: this.device, stepName: 'color' });
  }

  addColor(ev) {
    this.selectedColor.color = ev.srcElement.value;
    this.selectedColor.iconColor = InvertColor(this.selectedColor.color, true);
    this.device.spurfarbe = this.selectedColor.color;
    this.setupDeviceService.onDeviceChange.next({ device: this.device, stepName: 'color' });
  }

  nextSetting(){
    this.communicationService.emitEvent("device_image")
  }
}
