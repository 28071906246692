<ion-header>
  <ion-toolbar color="light">
    <ion-title>{{"password-reset.headerText"|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="content-padding">
    <h1 class="ion-text-center">{{ 'password-reset.adviceTitle1' | translate }} <br/><small>{{ 'password-reset.adviceTitle2' | translate }}</small></h1>
    <img class="reset-image" src="assets/images/reset-password.svg" />
    <p class="ion-text-center ion-text-wrap" style="margin: 20px 0">
      {{ 'password-reset.message' | translate }}
    </p>
    <p class="ion-text-center ion-text-wrap" style="margin: 20px 0" *ngIf="isBrowser">
      <b>{{ 'password-reset.update-app-note' | translate }}.</b>
    </p>

    <div class="ion-text-center">
      <ion-button (click)="requestPasswordEmail()" *ngIf="!emailSent" shape="round">
        <ion-label class="ion-text-wrap">{{ 'password-reset.send-button' | translate }}</ion-label>
      </ion-button>
    </div>


    <ion-item class="flex-email" lines="none" mode="ios" color="success" *ngIf="emailSent">
      <ion-icon src="assets/images/wrong-password.svg" slot="start"></ion-icon>
      <ion-label class="ion-text-wrap" *ngIf="!emailAlreadSent">{{ 'password-reset.email-sent' | translate }}</ion-label>
      <ion-label class="ion-text-wrap" *ngIf="emailAlreadSent">{{ 'password-reset.email-already-sent' | translate }}</ion-label>
      <!-- <img src="assets/images/wrong-password.svg" />
      <p>An email has been sent to reset your passsword.</p> -->
    </ion-item>

    <ion-item class="flex-email" lines="none" mode="ios" *ngIf="emailSent">
      <ion-label class="ion-text-wrap ion-text-center">
        {{ 'password-reset.email-sent-back-login' | translate }}
        <br>
        <ion-button size="md" shape="round" (click)="goToLogin()">
          <ion-label class="ion-text-wrap">{{ 'resetPassword.backToLogin' | translate }}</ion-label>
        </ion-button>
      </ion-label>
    </ion-item>

    <ion-item class="flex-email" lines="none" mode="ios" *ngIf="emailSent || emailAlreadSent || emailNotReceived">
      <ion-label class="ion-text-wrap ion-text-center">
        {{ 'password-reset.email-not-received' | translate }}
        <br>
        <ion-button size="md" shape="round" (click)="requestEmailFromAccountManager()">
          <ion-label class="ion-text-wrap">{{ 'password-reset.requestEmailFromManager' | translate }}</ion-label>
        </ion-button>
      </ion-label>
    </ion-item>
  </div>
</ion-content>
