import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalEventService {

    private message_code = new Subject<any>();

    publishMessageCode(data: any) {
        this.message_code.next(data);
    }

    getMessageCode(): Subject<any> {
        return this.message_code;
    }
}