import { notificationsMarkers } from "src/app/members/notifications/notificationsUtil";
import { MapComponent } from "../../map.component";
import { getSource } from "../castMaplibreData";
import { AvailableHooks } from "../componentHooks";
import { Data, dataSourceType, dataTypes } from "../data";
import { DataSource } from "../dataSource";
import { Geometry, GeometryTypes } from "../geometry";
import { IProperties } from "../Iproperties";
import { Layer, layerType } from "../layer";
import { addMapImage, GenerateUUID, getCssStyleVariable, svgToData } from "../mapUtil";
import { FeatureInterface } from "./feature-interface";
import { MainMapInterface } from "../../main-map.interface";

//_ CLASS TYPES
export type SYMBOL_MARKER_OPTIONS = {
  image?: string,
  size?: number,
  rotate?: number,
  anchor?: string,
  textAnchor?: string,
  title?: string,
  color?: string,
  showCircle?: boolean,
  iconOffset?: [Number, Number]
}

export type PRELOAD_IMAGE_MODEL = {
  src: string,
  name: string,
  width: number, height: number
}

export enum AVAILABLE_SYMBOL_MARKERS {
  'start-marker' = 'start-marker',
  'end-marker' = 'end-marker',
}
export class SymbolMarkers implements FeatureInterface {
    SOURCE_NAME: string = 'symbolsSource';
    LAYER_NAME: string = 'symbolsLayer'
    CIRCLES_LAYER_NAME: string = 'symbolsCirclesLayer'
    source: DataSource = null;
    layer = null;
    circlesLayer = null;

    preloadedImages: Array<PRELOAD_IMAGE_MODEL> = [
      {
        src: 'assets/images/render-video/start-marker.svg',
        name: 'start-marker',
        width: 82, height: 100
      },
      {
        src: 'assets/images/render-video/end-marker.svg',
        name: 'end-marker',
        width: 84, height: 100
      },
    ];

    primaryColor = '#FF4E00';
    show: boolean = true;

    defaultLayerOptions: SYMBOL_MARKER_OPTIONS = {
      size: 0.35,
      anchor: 'bottom',
      textAnchor: 'top',
      rotate: 0,
      color: this.primaryColor,
      showCircle: true,
      iconOffset: [0, 0]
    }

    constructor(public main: MainMapInterface){
        this.init();
    }

    init(){
        this.primaryColor = getCssStyleVariable('--ion-color-primary');

        this.source = this.createDataSource();
        this.layer = this.createLayer(this.LAYER_NAME, this.SOURCE_NAME);
        this.circlesLayer = this.createLayer(this.CIRCLES_LAYER_NAME, this.SOURCE_NAME);

        this.load();
    }

    createDataSource() {
        let source = <DataSource>{};
        source.data = <Data>{};
        source.type = dataSourceType.geojson;
        source.data.features = [];
        source.data.type = dataTypes.FeatureCollection;
        source["generateId"] = true;

        return source;
    }

    createLayer(name, source){
        let layer = new Layer(name);
        layer.source = source;
        layer.type = layerType.symbol;

        if (name == this.LAYER_NAME){
            layer.type = layerType.symbol;
            layer.layout["icon-image"] = ["get", "image"];
            layer.layout["icon-size"] = ["get", "size"];
            layer.layout["icon-rotation-alignment"] = "viewport"; //could be 'viewport' ; used to rotate the icon with the viewport of the map ...
            // layer.paint['icon-translate-anchor'] = 'map';
            // layer.layout['icon-pitch-alignment'] = 'map';

            layer.layout["icon-allow-overlap"] = true;
            layer.layout["icon-rotate"] = ["get", "rotate"];
            layer.layout["icon-anchor"] = ["get", "anchor"]; //"bottom";
            layer.layout["icon-offset"] = ["get", "iconOffset"];

            layer.layout["text-anchor"] = ["get", "textAnchor"]; //"top";
            layer.layout["text-field"] = ["get", "title"];
            layer.layout["text-offset"] = [0, -6]; //2
            layer.layout["text-allow-overlap"] = true;
        }

        if (name == this.CIRCLES_LAYER_NAME) {
          layer.type = layerType.circle;
          layer.paint["circle-radius"] = 7;
          layer.paint["circle-color"] = ["get", "color"];
          layer.paint["circle-opacity"] = 1.0;
          layer.paint["circle-pitch-alignment"] = "map";

          layer["filter"] = [
            "all",
            ["==", "showCircle", true], // Only show if deviceShow == 1
          ];
        }

        // layer.maxzoom = 22;
        // layer.minzoom = 4;

        return layer;
    }

    load(){
        // LOAD CUSTOM IMAGES TO USE IN THE MAP
        this.main.on(AvailableHooks.onLoadImages, (e) => {
            this.preloadedImages.forEach(img => {
              addMapImage(img.src, this.main.map, img.name, img.width, img.height);
            })
        });

        this.main.on(AvailableHooks.onLoadLayer, (e) => {
            if (!this.main.map.getLayer(this.LAYER_NAME))
                this.main.map.addLayer(this.layer, 'markers'); //_ Show symbolMarkers behind markers layer
            if (!this.main.map.getLayer(this.CIRCLES_LAYER_NAME))
              this.main.map.addLayer(this.circlesLayer, this.LAYER_NAME); //_ Show circle behind symbolMarkers layer
        });

        this.main.on(AvailableHooks.onLoadSource, (e) => {
            if (!this.main.map.getSource(this.SOURCE_NAME))
                this.main.map.addSource(this.SOURCE_NAME, this.source);
        });

        this.main.mapReady.subscribe( r => {
            // if (r){
            //     this.mouseEvents();
            // }
        })
    }

  //_ imageName : name of symbol preload image
  //_ location as type array(number, number)
  //_ trigger update source on map
  //_ layerOptions to add custom layer options declared in the type and in the layer object
  public addMarker(imageName, location: [number, number], updateSource = true, layerOptions: SYMBOL_MARKER_OPTIONS = {}, dataPoint = null) {
    let name = GenerateUUID();
    let geometry = new Geometry();
    let properties = {} as IProperties;
    geometry.type = GeometryTypes.Point;
    geometry.coordinates = location;
    properties['image'] = imageName; // name of the image loaded in load map when started the component.
    properties["layerType"] = "symbolsLayer"; //_ Type of feature

    //_ Apply default layer options
    Object.keys(this.defaultLayerOptions).forEach(key => {
      properties[key] = this.defaultLayerOptions[key];
    })

    //_ Apply input layer options
    Object.keys(layerOptions).forEach(key => {
      properties[key] = layerOptions[key];
    })

    if (imageName == AVAILABLE_SYMBOL_MARKERS['start-marker'] || imageName == AVAILABLE_SYMBOL_MARKERS['end-marker']) {
      if (dataPoint) {
        properties["speed"] = dataPoint.p.speed;
        properties["directionId"] = dataPoint.p.id;
        properties["origin"] = dataPoint.p.wifi ? "wifi" : "gps";
        properties["deviceId"] = dataPoint.d.id
      }
    }

    let pmarker = this.main._createFeature(geometry, properties, name);
    //_ Adding the feature to the data Source ...
    this.source.data.features.push(pmarker);

    if (updateSource)
      this.updateDataSource();
  }

  addSymbolImage (newImage: PRELOAD_IMAGE_MODEL) {
    this.preloadedImages.push(newImage);
    addMapImage(newImage.src, this.main.map, newImage.name, newImage.width, newImage.height);
  }


  updateDataSource() {
    if (this.main.map) {
      if (this.main.map.getSource(this.SOURCE_NAME))
        getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
  }

  clearMarkers(){
    this.source = this.createDataSource();
    this.updateDataSource();
  }

}
