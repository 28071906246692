import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { catchError, filter, first, of, timeout } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { StartupService } from 'src/app/services/startup.service';

@Component({
  selector: 'app-startup-loading',
  templateUrl: './startup-loading.component.html',
  styleUrls: ['./startup-loading.component.scss'],
})
export class StartupLoadingComponent  implements AfterViewInit {

  @ViewChild('loadingDiv') loadingDiv!: ElementRef;
  dontWaitStartupDataRoutes = ['login', 'stand-alone-page', 'share', 'reports/logbook', 'reset-password'];
  startupCompleted = false;
  constructor(private startupService: StartupService, private router: Router, private devicesService: DeviceDataService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.startupService.hideLoadingScreen$.subscribe(res => {
      // console.log('DEBUG - hide splashscreen');
      this.hideLoading();
    })
  }

  ngAfterViewInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const currentRoute = this.router.url;
      // console.log('[DEBUG] currentRoute', { currentRoute })
      if (this.dontWaitStartupDataRoutes.some(route => currentRoute.includes(route))) {
        this.hideLoading();
      } else {
        this.waitStartupReady();
      }
    });
  }

  waitStartupReady () {
    this.startupService.startupDataReady$.pipe(first(res => res === true)).subscribe(res => {
      // console.log('DEBUG startupDAtaReady', res)
      if (res)
        // this.hideLoading
        this.checkMapRoute()
    });
  }

  checkMapRoute() {
    // console.log('DEBUG CHECKING ROUTE ')
    const currentRoute = this.router.url;
    // console.log('[DEBUG] CHECKING ROUTE currentRoute', { currentRoute })
    if (currentRoute.includes('tabs/map')) {
      this.devicesService._initializeMap.pipe(
        first(),
        timeout(1000*60),  // Timeout after 1 minute
        catchError(error => {
          console.error('StartupLoading ERROR - _initializeMap timeout:', error);
          this.hideLoading();
          return of(null);
        })).subscribe((r) => {
          if (r) {
            // console.log('DEBUG - devices last positions')
            this.hideLoading();
          }
      });
    } else {
      this.hideLoading();
    }
  }

  hideLoading () {
    // console.log('DEBUG ** Hide loading');
    this.loadingDiv.nativeElement.classList.add('fade-out');

    this.loadingDiv.nativeElement.addEventListener('animationend', () => {
      this.startupCompleted = true;
      this.loadingDiv.nativeElement.remove();
    });
  }

}
