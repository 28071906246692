import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { MenuController, Platform, ModalController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "src/app/app.service";
import { Constants } from "src/app/constants.enum";
import { DeviceListService } from "src/app/members/map/devices-sidebar/devices-list/device-list.service";
import { DevicesSidebarService } from "src/app/members/map/devices-sidebar/devices-sidebar.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { GlobalEventService } from "src/app/services/globa-event.service";
import { MessagingService } from "src/app/services/messaging.service";
import { AppInfoService } from "src/app/services/plugins/app-info.service";
import { BrowserService } from "src/app/services/plugins/browser.service";
import { FcmService } from "src/app/services/plugins/fcm.service";
import { GeocoderService } from "src/app/services/plugins/geocoder.service";
import { GeolocationService } from "src/app/services/plugins/geolocation.service";
import { STORAGE_SETTINGS } from "src/app/services/storage-settings";
import { SupportLinkService } from "src/app/services/support-link.service";
import { sidebarService } from "./side-bar.service";
import { StorageService as Storage } from "./../../services/storage.service";

export class PajSidebarMixin {
    isNative: boolean = false;
    menuOpenCount = false;
    fName: string;
    lName: string;
    userProfile = "assets/images/company/user.svg";
    language: string;
    constructor(
        public authService: AuthenticationService,
        public menu: MenuController,
        public storage: Storage,
        public _translate: TranslateService,
        public router: Router,
        public platform: Platform,
        public appService: AppService,
        public globalEventService: GlobalEventService,
        public _deviceListService: DeviceListService,
        public messagingService: MessagingService,
        public fcmService: FcmService,
        public devicesSidebarService: DevicesSidebarService,
      ) {
        this.isNative = this.platform.is('capacitor')
      }
      
    async logout() {
      console.log('logout')
        let firebaseToken: any = null;
       
        const isMobileApp = Capacitor.isNativePlatform() && (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android');
        if (isMobileApp) {
          firebaseToken = await this.fcmService.getFcmToken();
        } else {
          if (!window["isSafari"]) {
            this.messagingService.getafMessagingtoken().subscribe((token) => {
              firebaseToken = token;
              this.messagingService.deleteToken();
            });
          }
        }
        if (firebaseToken) {
          this.authService.logOut(firebaseToken);
          this.fcmService.deleteFcm()
        }
      await   this.clearOnLogout()
      await  this.router.navigate(["login"], { replaceUrl: true });
      }
    
      async clearOnLogout() {
        this.globalEventService.publishMessageCode("STOP_BACKGROUND_API");
        let darkMode = await this.storage.get("darkMode");
        await this.menu.close();
        await this.storage.set(Constants.UPDATE_MAP, 1);
        await this.authService.logoutStatus.next(true);
    
        this.menuOpenCount = false;
        // this.appPages.splice(7, 1);
        // this.appPages.splice(7, 1);
        this.fName = "";
        this.lName = "";
        this.userProfile = "";
        await this.storage.get(Constants.LANGUAGE).then((res) => {
          if (res) {
            this.language = res;
          } else {
            this.language = "en";
          }
        });
        //this.storage.set(Constants.LANGUAGE, this.language);
        await this.clearStorageData();
        //await this.storage.set('darkMode', darkMode);
        await this.authService.logout();
        this.appService.user = null;
        this.appService.devices = null;
        this._deviceListService._customDeviceGroups = []; //clear the reorder device list while logout
        this._deviceListService.deviceData = []; //clear the viertual list device list while logout
        // this.router.navigate(["login"], { replaceUrl: true });
        // window.location.href = '/login';
        // setTimeout(() => location.reload(), 500);
      }
    
      async clearStorageData() {
        this.storage.remove(Constants.USER_DATA);
        this.storage.remove(Constants.USER_ID);
        this.storage.remove(Constants.TOKEN_KEY);
        this.storage.remove(Constants.UPDATE_LOGIN);
        this.storage.remove(Constants.SUPER_USER);
        this.storage.remove(Constants.NOTIFICATIONS_LIST);
        this.storage.remove(Constants.DEVICE_DATA);
        this.storage.remove(Constants.LASTPOINTS);
        
        this.appService.adminId.remove();
        this.appService.ghostMode.remove();
        this.devicesSidebarService.devicesFlatList.remove();
        this.devicesSidebarService.groups.remove();
        this.devicesSidebarService.userPermissions.remove();
        this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_SELECTED_DEVICE);
        this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_TYPE);
        // this.devicesSidebarService.filters.remove();
        this.devicesSidebarService.resetFilters();
        this.storage.remove(Constants.PET_MODE);
        // console.log('Storaged cleared');
      }
    
}