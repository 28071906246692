import { NgZone, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DashboardTimelineService } from "./timeline-service";
import { Observable } from "rxjs";

export type MAP_RENDER_IMAGE_OPTIONS = {
    renderMarker?: boolean,
    renderStartEndFlag?: boolean
}
@Pipe({
  name: 'RenderRouteInMap'
})
export class MapRenderImagePipe implements PipeTransform {
    whitePicture = ('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');

    constructor(private timelineService: DashboardTimelineService, private zone: NgZone) {
    }

    transform(device, points, options: MAP_RENDER_IMAGE_OPTIONS): Observable<string> {
        return new Observable(observer => {
            observer.next(this.whitePicture);
            this.zone.run(() => {
                // console.log('Pipe device and points', { points, device });
                const proc = this.timelineService.renderRouteInMap(device, points, options);
                // console.log('PROC data', proc);

                this.timelineService.resolveImage.subscribe( (res: any)=> {
                    if (res.id == proc.id){
                        // console.log('Proc ended: ' + proc.id);
                        observer.next(res.img);
                    }
                })
            }, error => {
                //_ Return error image
                // observer.next(this.defaultDeviceImage);
                return;
            });
            return { unsubscribe() { } };
        });
    }

    getAlarmImage(device, isInViewPort = true): Observable<string> {
        return new Observable(observer => {
            observer.next(this.whitePicture);
            this.zone.run(() => {
                // console.log('Pipe device and points', { device });
                const proc: any = this.timelineService.renderAlarmMarker(device);
                // console.log('PROC data', proc);

                this.timelineService.resolveImage.subscribe( (res: any)=> {
                    if (res.id == proc.id){
                        // console.log('Proc ended: ' + proc.id);
                        observer.next(res.img);
                    }
                })
            }, error => {
                //_ Return error image
                // observer.next(this.defaultDeviceImage);
                return;
            });
            return { unsubscribe() { } };
        });
    }
}