<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-back-button
        (click)="modelCtrl.dismiss()"
        style="display: block !important"
        back-close
        back-locale
      >
      </ion-back-button>
    </ion-buttons>
    <ion-title
      >{{ "deviceManagement.alertSettingsText" | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card>
    <ion-card-content>
      <div>
        <h2 class="device-name">
          {{device.name}}
        </h2>
      </div>
      <app-alert
        #deviceAlert
        class="deviceInfo"
        [deviceID]="deviceID"
        [data]="data"
        [map]="map"
        [deviceMarkers]="null"
        [isOnDeviceList]="false"
      ></app-alert>
    </ion-card-content>
  </ion-card>
</ion-content>
