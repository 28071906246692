import { Injectable, Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommandsService } from '../commands.service';

@Injectable({
  providedIn: 'root'
})
export class SetupWifiService {
  public nav:any = null;
  public selectedCoords = { lat: 0, lng: 0 };
  public deviceId = null;
  public currentWifi = '';
  public wh1_lat = '';
  public wh1_lng = '';
  public wh2_lat = '';
  public wh2_lng = '';
  public deviceWifi = new BehaviorSubject<any>(null);
  public sendCommand$ = new BehaviorSubject<any>(null);

  private cmdWifi1 = '64ae75af3ddaaa0f9d1df7f7';
  private cmdWifi2 = '661cfb2ead6c546c277c76eb';
  public item:any = null;
  openEditWifiModal = new Subject();

  constructor(
    private authService: AuthenticationService,
    private appService: AppService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private devicesService: DeviceDataService,
    private commandsService: CommandsService
  ){}

  public onDone = new BehaviorSubject<any>(null);
  public onSetupDone$ = new Subject();

  async openModal (Component: any, componentProps?: any) {
    let modal: any = await this.modalCtrl.create({
      component: Component,
      //cssClass: "wifi-setup-modal",
      cssClass: "device-guide-setup",
      mode: "ios",
      id: 'wifi-setup-modal',
      componentProps: {

      }
    });

    await modal.present();
    return modal.onWillDismiss();
  }

  async closeModal(){
    this.onDone.next({proccess: 'done'});
    return this.modalCtrl.dismiss();
  }

  async initialRoot(Component: any, componentProps?: any, nav?: any){
    this.nav = nav;
    await this.nav.setRoot(Component, componentProps);
  }

  async navigateTo(Component: any, componentProps?: any){
    await this.nav.push(Component, componentProps)
  }


  wifiConfig(numberWifi){
    this.currentWifi = numberWifi;
  }

  setWifiSetup(wifiSetup: any){
    if(wifiSetup){
      this.wh1_lat = wifiSetup.wh1_lat;
      this.wh1_lng = wifiSetup.wh1_lng;
      this.wh2_lat = wifiSetup.wh2_lat;
      this.wh2_lng = wifiSetup.wh2_lng;

      this.deviceWifi.next({
        iddevice: wifiSetup.iddevice,
        wh1_lat: wifiSetup.wh1_lat,
        wh1_lng: wifiSetup.wh1_lng,
        wh2_lat: wifiSetup.wh2_lat,
        wh2_lng: wifiSetup.wh2_lng
      })
    }
  }

  sendCommand(){
    this.item = {... this.item, id: this.currentWifi == 'wifi1' ? this.cmdWifi1 : this.cmdWifi2 };
    this.commandsService.sendCommand(this.item);
  }

  saveWifiAddress(lat :string, lng: string){
    if(this.currentWifi == 'wifi1'){
      this.wh1_lat = lat;
      this.wh1_lng = lng;
    }else if(this.currentWifi == 'wifi2'){
      this.wh2_lat = lat;
      this.wh2_lng = lng;
    }
  }

  async onSetupDone(){
    if(this.deviceId){
      this.closeModal();
      this.authService.saveWifiAddress(
        this.deviceId,
        {
          wifiNumber: this.currentWifi === 'wifi1' ? 1 : 2,
          lat: this.currentWifi === 'wifi1' ? this.wh1_lat : this.wh2_lat,
          lng: this.currentWifi === 'wifi1' ? this.wh1_lng : this.wh2_lng
        }
      ).subscribe(async (r: any) => {
        console.log("response in save address ::: ", r)
        if(r.success){
          //_ Update device wifiMarkers and trigger update device markers in map
          const newDeviceInfo = this.devicesService.devices[this.deviceId];
          newDeviceInfo.parseAndStoreWifis(r.success);
          this.devicesService.updateDeviceInfo(newDeviceInfo);

          this.appService.showToast(null, this.translate.instant('commandsTab.SetupWifi.alertUpdateLocationSuccess'),
          2000,
          "success");
          this.onSetupDone$.next(true);
        }else{
          this.appService.showToast(null, this.translate.instant('commandsTab.SetupWifi.alertUpdateLocationError'),
          2000,
          "error")
        }

        // await this.closeModal();
      });
    }
  }
}
