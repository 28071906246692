import {
  Injectable,
  Inject,
  Renderer2,
  RendererFactory2,
  HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
import { KeyboardResize, Keyboard } from "@capacitor/keyboard";
//_ Clase to fix keyboard behaiviour for ionic 5.x
//_ Change the ion-content hight decresean the mobile keyboard hight
@Injectable({
  providedIn: "root",
})
export class KeyboardTrickService {
  lastInputFocus = null;
  private renderer: Renderer2;
  lastScrollerContent = null;
  constructor(
    private rendererFactory: RendererFactory2,
    private router: Router
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    // this.init();
  }

  init() {
    return;
    // this.keyboard.setResizeMode(KeyboardResizeMode.Native);
    console.log("KEYBOARD PLUGIN", Keyboard);

    Keyboard.addListener("keyboardWillShow", (info) => {
      console.log("keyboard will show with height:", info.keyboardHeight);
    });
    Keyboard.addListener("keyboardWillHide", () => {
      console.log("keyboard will hide");
    });
    // Keyboard.onKeyboardWillShow().subscribe(k => {
    //     console.log("LGINPAGE", this.router.url)
    //     if (!this.router.url.includes('login')) {
    //         this.lastInputFocus = k.srcElement.document.activeElement || null;
    //         if (this.lastInputFocus) {
    //             let parentContent = this.lastInputFocus.closest('ion-content');
    //             const isPullupParent = this.lastInputFocus.closest('app-devices-list-home');
    //             const pullupTop = 0; //(isPullupParent && this.lastInputFocus.id != 'devicelist-search') ? 49 : 0;
    //             parentContent = isPullupParent != null ? isPullupParent.querySelector('ion-content') : parentContent;
    //             console.log('PARENT CONTENT', {parentContent, isPullupParent});
    //             console.log('LAST INPUT ', this.lastInputFocus);
    //             if (parentContent) {
    //                 this.lastScrollerContent = parentContent.shadowRoot.querySelector('.inner-scroll');
    //                 // console.log('SCroller', this.lastScrollerContent);
    //                 if (this.lastScrollerContent) {
    //                     this.setHeight(this.lastScrollerContent, 'calc(100% + ' + -(k.keyboardHeight + pullupTop) + 'px)');
    //                     const wasInView = this.isInViewPort(this.lastInputFocus, (k.keyboardHeight + pullupTop));
    //                     this.lastInputFocus.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    //                     // console.log('SCROLLTOP', this.lastScrollerContent.scrollTop)

    //                     // this.lastInputFocus.style.setProperty('background', wasInView ? '#00ff00' : '#ff0000');
    //                     if (!wasInView) {
    //                         this.lastScrollerContent.scrollTop += 50;
    //                     }
    //                 }

    //             }
    //         }
    //     }

    //     console.log('KEYBOARD OPEN', { input: this.lastInputFocus, ev: k });

    // });

    // this.keyboard.onKeyboardWillHide().subscribe(k => {
    //     // const input = k.srcElement.document.activeElement;
    //     console.log('KEYBOARD CLOSED', k);
    //     if (!this.router.url.includes('login')) {
    //         if (this.lastScrollerContent) {
    //             this.setHeight(this.lastScrollerContent, 'initial');
    //         }
    //     }
    // });
  }

  setHeight(el, newHeight) {
    this.renderer.setStyle(el, "height", newHeight);
  }

  isInViewPort(element, keyboardHeight) {
    const bounding = element.getBoundingClientRect();
    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <=
        (window.innerHeight - keyboardHeight ||
          document.documentElement.clientHeight - keyboardHeight)
    )
      return true;
    else return false;
  }
}
