import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  public deviceMarkers;
  public deviceData;

  constructor() { }

  getDeviceData() {
    return this.deviceData;
  }
}

export enum DeviceStatusEnum  {
  Undefined,
  Online,
  Offline,
  Online_Low_Signal
};

export const DeviceStatusInfo = {
  Undefined: { 
    img: 'assets/images/devices-list/info/status_offline.svg', 
    color: 'var(--ion-color-light-contrast)', 
    textKey: 'info.statusUndefined' 
  },
  Online: { 
    img: 'assets/images/devices-list/info/status_online.svg', 
    color: 'var(--ion-color-success)',
    textKey: 'info.statusOnline' 
  },
  Offline: { 
    img: 'assets/images/devices-list/info/status_offline.svg', 
    color: 'var(--ion-color-light-contrast)',
    textKey: 'info.statusOffline' 
  },
  Online_Low_Signal: { 
    img: 'assets/images/devices-list/info/status_low_signal.svg', 
    color: 'var(--ion-color-warning)',
    textKey: 'info.statusLowSignal' 
  }
}