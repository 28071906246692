import { PopoverController } from "@ionic/angular";
import { zoomInAnimation } from "./animations/ionic-animations";
import { LanguagePickerComponent } from "./components/language-picker/language-picker.component";
import { CurrencyPickerComponent } from "./components/currency-picker/currency-picker.component";

//_ Language picker to use everywhere
export const showLanguagePicker = (popoverCtrl: PopoverController, title, ev = null, selectedLanguage = null, dropdown) => {
  return new Promise(async (resolve, reject) => {
    let pop = await popoverCtrl.create({ 
      componentProps: { title, selectedLan: selectedLanguage },
      component: LanguagePickerComponent,
      cssClass: dropdown? 'language-dropdown-popover': 'language-popover',
      event: ev,
      mode: "ios",
      translucent: false,
      backdropDismiss: true,
      showBackdrop: true,
      arrow: dropdown,
      enterAnimation: ev ? null : zoomInAnimation
    });

    await pop.present();
    const { data } = await pop.onWillDismiss();
    if (data)
      resolve(data.selectedLan);
    else
      resolve(null);
  })
}

// Currency picker to use everywhere
export const showCurrencyPicker = (popoverCtrl: PopoverController, title, ev = null, selectedCurrency = null, dropdown) => {
  return new Promise(async (resolve, reject) => {
    let pop = await popoverCtrl.create({ 
      componentProps: { title, selectedCurrency: selectedCurrency },
      component: CurrencyPickerComponent,
      cssClass: dropdown? 'language-dropdown-popover': 'language-popover',
      event: ev,
      mode: "ios",
      translucent: false,
      backdropDismiss: true,
      showBackdrop: true,
      arrow: dropdown,
      enterAnimation: ev ? null : zoomInAnimation
    });

    await pop.present();
    const { data } = await pop.onWillDismiss();
    if (data)
      resolve(data.selectedCurrency);
    else
      resolve(null);
  })
}
