import { getGraphhopperLegs } from '../../../map/class/mapUtil';
import polyline from '../../../map/class/polylines';

export const GET_LEGS = (vallResponse, length, useGraphhopper) => {
    // console.log('VALHALLA Response', vallResponse);
        // console.log('Trips null', vallResponse.trips.filter(v => v.trip == null));
        // Shapes of str Lines
        let shapes = [];
        if (vallResponse.trips) {
            if (useGraphhopper == 0) {
                vallResponse.trips.forEach(trip0 => {
                    if (trip0.trip) {
                        let trip1 = trip0.trip;
                        // If distance of the trip is > length 
                        if (trip1.trip) {
                            trip1.trip.legs.forEach(leg => {
                                // If distance of the leg is > length then add the shape to the return shape variable
                                if (leg.summary.length > length) {
                                    shapes.push(leg.shape);
                                }
                            });
                        }
                        else {
                            //_ Autogenerate string legs for missing valhalla response path
                            //_ Invert lng, lat > lat, lng
                            let coors = trip0.coordinates.map(c => [c[1], c[0]]);
                            // coors = this.getPointsRange(coors[0], coors[coors.length-1], deviceId);
                            const newShapeStr = polyline.encode(coors, 6);
                            shapes.push(newShapeStr);
                        }
                    }
                    else {
                        //_ Need more test, because some times line starts before|after prevous snaped segment line
                        // console.log('Trip dont have sub trip', trip0);
                        let coors = trip0.coordinates.map(c => [c[1], c[0]]);
                        const newShapeStr = polyline.encode(coors, 6);
                        shapes.push(newShapeStr);
                    }
    
                })
            }
            else if (useGraphhopper == 1) {
                const graphData = getGraphhopperLegs(vallResponse);
                shapes = graphData.shapes;
            }
        }

        return { shapes, totalDistance: vallResponse.optimized_distance };
}