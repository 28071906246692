import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SetupWifiService } from '../setup-wifi.service';
import { HomeAddressPickerComponent } from '../home-address-picker/home-address-picker.component';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, interval, switchMap, takeUntil, takeWhile } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { animations } from 'src/app/animations/animations';

@Component({
  selector: 'app-wifi-list',
  templateUrl: './wifi-list.component.html',
  styleUrls: ['./wifi-list.component.scss'],
  //animations: animations
})
export class WifiListComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public initSearch: boolean = false;

  iddevice: number | null;
  wh1_lat:string | null = null
  wh1_lng:string | null = null
  wh2_lat:string | null = null
  wh2_lng:string | null = null

  componentDestroyed$ = new Subject();
  wifiHomeCoords = null;
  constructor(
    private setupWifiService: SetupWifiService,
    public translate: TranslateService,
    private authService: AuthenticationService,
    private devicesService: DeviceDataService
  ){}

  ngOnInit(): void {    }

  searchWifi(){
    this.initSearch = true;
    this.wifiHomeCoords = null;

    this.subscription = interval(5000)
      .pipe(
        switchMap(()=> this.authService.getDeviceWifis(this.setupWifiService.deviceId)),
        takeUntil(this.componentDestroyed$),
        takeWhile((response: any) => {
          if(response.success[0]) return true
        })
      ).subscribe((r: any) => {
        if(r.success && r.success[0]){
          this.setupWifiService.setWifiSetup(r.success[0]);
          this.cancelPrevSubscription();

          if(r.success[0]){
            const response = r.success[0]
            this.iddevice = response.iddevice;
            this.wh1_lat = response.wh1_lat;
            this.wh1_lng = response.wh1_lng;
            this.wh2_lat = response.wh2_lat;
            this.wh2_lng = response.wh2_lng;

            // setTimeout(() => {
            //   this.initSearch = false;
            // }, 8000);
            this.initSearch = false;

            let coords = null;
            if(this.setupWifiService.currentWifi == 'wifi1'){
              if(response.wh1_lat !== 0 && response.wh1_lat !== '' &&  response.wh1_lng !== 0 && response.wh1_lng !== ''){
                coords = {
                  lat: response.wh1_lat,
                  lng: response.wh1_lng
                }
              }
            }else if(this.setupWifiService.currentWifi == 'wifi2'){
              if(response.wh2_lat !== 0 && response.wh2_lat !== '' &&  response.wh2_lng !== 0 && response.wh2_lng !== ''){
                coords = {
                  lat: response.wh2_lat,
                  lng: response.wh2_lng
                }
              }
            }

            //_ Fix device location for the map coordinates if wifi location is null
            if (coords.lat === null || coords.lng === null || coords.lat === 0 || coords.lng === 0) {
              const deviceLastPoint = this.devicesService.devices[this.iddevice].lastPoint;
              // console.log('[DEBUG] DEVICE LAST POINT', deviceLastPoint);
              coords = { lat: deviceLastPoint.lat, lng: deviceLastPoint.lng };
            }

            this.wifiHomeCoords = coords;
            this.setupWifiHomeAddress();
            // console.log('[DEBUG] COORS BEFORE NAVIGATE', {coords, response});
            //if has data go to home address picker.
            // this.setupWifiService.navigateTo(HomeAddressPickerComponent, { coords });
          }
        }
      });
  }

  async goToFirstMenu(){
    await this.setupWifiService.closeModal();
  }

  goToStep2(){
    if (this.wifiHomeCoords) {
      this.setupWifiHomeAddress();
    } else {
      this.setupWifiService.sendCommand();
      this.cancelPrevSubscription();
      this.searchWifi();
      //this.searchWifiAndProceed(); // Call the new method here
    }
  }
  // searchWifiAndProceed() {
  //   this.searchWifi();
  //   setTimeout(() => {
  //     if (this.wifiHomeCoords) {
  //       this.setupWifiHomeAddress();
  //     }
  //   }, 12000);
  // }

  setupWifiHomeAddress () {
    if (this.wifiHomeCoords)
      this.setupWifiService.navigateTo(HomeAddressPickerComponent, { coords: this.wifiHomeCoords });
  }

  ngOnDestroy(): void {
    this.cancelPrevSubscription();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();

  }

  cancelPrevSubscription () {
    if(this.subscription){
      this.subscription.unsubscribe()
    }
  }
}
