import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  Type,
  ViewChild,
} from "@angular/core";

import { AppService } from "src/app/app.service";

// import { notificationsMarkers } from "src/app/members/notifications/notificationsUtil";
import { DeviceImageService } from "src/app/pipes/deviceImagePipe";
import { MediaApiService } from "src/app/components/media-managment/media-api.service";
import {
  IonFab,
  ModalController,
  Platform,
  PopoverController,
} from "@ionic/angular";
import { BehaviorSubject, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { first } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { distance as Tdistance, lineString, along } from "@turf/turf";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/constants.enum";
import { DeviceSettingComponent } from "src/app/members/device-management/components/device-setting/device-setting.component";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { Location } from "@angular/common";
import { App } from "@capacitor/app";
import * as moment from "moment";
import { animations } from "src/app/animations/animations";
import { Capacitor } from "@capacitor/core";
import * as turf from "@turf/turf";
import { CsPopoverComponent } from "src/app/components/cspopover/cspopover.component";
import { ApiService } from "src/app/services/api.service";
import { DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS, MapService } from "src/app/members/map/components/map/map.service";
import { DataSource } from "src/app/members/map/components/map/class/dataSource";
import { IProperties } from "src/app/members/generate-video/map-min/class/Iproperties";
import { dataSourceType, dataTypes } from "src/app/members/generate-video/map-min/class/data";
import { Effects } from "src/app/members/generate-video/map-min/class/effects";
import { Layer, layerType } from "src/app/members/generate-video/map-min/class/layer";
import { mapUtil } from "src/app/members/generate-video/map-min/class/mapUtil";
import { ClusterDataSource } from "src/app/members/map/components/map/class/ClusterDataSource";
import { getSource } from "src/app/members/map/components/map/class/castMaplibreData";
import { MapComponentEvents, AvailableHooks } from "src/app/members/map/components/map/class/componentHooks";
import { applyDarkStyle } from "src/app/members/map/components/map/class/darkMode";
import { ThreedHelper } from "src/app/members/map/components/map/class/features/3dmodelHelper";
import { PanFeature } from "src/app/members/map/components/map/class/features/PanFeature";
import { AlarmMarkers } from "src/app/members/map/components/map/class/features/alarmMarkers";
import { ClientLocation } from "src/app/members/map/components/map/class/features/clientLocation";
import { DeviceNameMarkers } from "src/app/members/map/components/map/class/features/deviceNameMarkers";
import { HighSpeedPolylines } from "src/app/members/map/components/map/class/features/highSpeedPolylines";
import { PauseMarkers } from "src/app/members/map/components/map/class/features/pauseMarkers";
import { SpeedCameras } from "src/app/members/map/components/map/class/features/speed-cameras";
import { SubAccountsMarkers } from "src/app/members/map/components/map/class/features/subAccountsMarkers";
import { SymbolMarkers } from "src/app/members/map/components/map/class/features/symbolMarkers";
import { Watermark } from "src/app/members/map/components/map/class/features/watermark";
import { ImageStackManagament } from "src/app/members/map/components/map/class/imageStackManagament";
import { DARK_ATMOSPHERE, MapStylesList, directionMarker } from "src/app/members/map/components/map/class/mapInterfaces";
import { resizeImage, getDeviceImageName, stringToPoints, cloneObject, GenerateUUID } from "src/app/members/map/components/map/class/mapUtil";
import { MainMapInterface } from "src/app/members/map/components/map/main-map.interface";
import { StorageService } from "src/app/services/storage.service";
import { Style } from "src/app/members/map/components/map/class/style";
import { Geometry, GeometryTypes } from "src/app/members/map/components/map/class/geometry";
import { Feature } from "src/app/members/map/components/map/class/feature";
import { environment } from "src/environments/environment";
import { DynamicMapLib } from "src/app/members/map/components/map/dinamic-map-lib";


declare var window: any;

//_ Animation compatible classes for browsers
var requestAnimationFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame;
var cancelAnimationFrame =
  window.cancelAnimationFrame ||
  window.webkitCancelRequestAnimationFrame ||
  window.webkitCancelAnimationFrame ||
  window.mozCancelRequestAnimationFrame ||
  window.mozCancelAnimationFrame ||
  window.oCancelRequestAnimationFrame ||
  window.oCancelAnimationFrame ||
  window.msCancelRequestAnimationFrame ||
  window.msCancelAnimationFrame;

@Component({
  selector: "paj-main-map-lite",
  templateUrl: "./main-map-lite.component.html",
  styleUrls: ["./main-map-lite.component.scss"],
  animations: animations,
})
export class MainMapLiteComponent extends DynamicMapLib implements MainMapInterface, OnInit, OnDestroy {
  @ViewChild("fab") fab: IonFab;
  @ViewChild("FabSubmenu") FabSubmenu: IonFab;
  @ViewChild("graphhopperLegend", { read: ElementRef}) graphHopperLegendRef:  ElementRef
  @ViewChild("mapMonitorLegend", { read: ElementRef}) mapMonitorLegendRef:  ElementRef

  public map: any;
  @Input() height = "100vh";
  @Input() width = "100%";
  @Input() pitch = 55;
  @Input() bearing = 0;
  @Input() interactive = true;
  @Input() fakeUser = null;
  @Input() hideFabButton = false;
  @Input() animatedRoute: boolean = false;
  @Input() dragableRouteMarkers: boolean = false;
  @Input() enableSpeedCameras: boolean = true;
  @Input() timerTemplate: TemplateRef<any> = null;
  @Input() sliderTemplate: TemplateRef<any> = null;
  @Input() alwaysShowPolylines: boolean = false;
  @Input() alwaysShowDirections: boolean = false;
  @Input() hasTabBar: boolean = false;
  @Input() markerSize = null;
  @Input() filterSource = { directions: true };
  @Input() hideLegends = false;
  @Input() customerSettings = null;
  @Input() performanceSettings = {};
  @Input() BottomButtons = { 'first-section': false, 'second-section': false };
  @Input() useMapbox = false;

  //_ Outputs / hooks for features
  @Output() public onClickFeature = new EventEmitter<{}>();
  @Output() public onClickMap = new EventEmitter<{}>();
  @Output() public onMouseEventMap = new EventEmitter<{}>();
  @Output() public onClickRoute = new EventEmitter<{}>();
  @Output() public onMarkerMoved = new EventEmitter<{}>();
  @Output() public onDragMap = new EventEmitter<{}>();

  @Output() public onAnimationEnd = new EventEmitter<{}>();
  @Output() public onAnimationStops = new EventEmitter<{}>();

  HOOKS = new MapComponentEvents();
  currentBearing = -0;

  //_ FEATURES SHARED VARS
  showDeviceNameMarker: boolean = false;

  devicesSource: any = null;

  routeLayer: any = null;
  markerLayer: any = null;

  infoLayer: any = null;

  primaryColor: string = "#FF4E00";
  id: any = "";
  markers = [];

  /******************************************************** */
  mapStyle = "paj";
  dataSource = null; //Use for all features
  directionsSource = null;
  markersSource = null;

  scrollDirectionsSource = null;
  markersImages = []; // Used to reaload images after change map style
  setStyle = false;
  panFeature: PanFeature = null;
  Features = null;
  markersLayer = null;
  routesLayer = null;
  directionsLayer = null;
  wifiDirectionsLayer = null;
  scrollDirectionsLayer = null; // to show the direction marker on top of all layers
  effectsLayer = null;
  OnlineLayer = null;
  userSettings;
  // pausesLayer = null;
  circlesLayer: any = null;
  delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  user;

  timer: any;
  mapReady = new BehaviorSubject(false);
  isDarkmode: boolean = false;

  clusterBgMarkers = null;
  clusterCountMarkers = null;
  clusterMarkers = null;

  onlineDotLayer = null;

  performance = {
    clusterMarkers: false,
    defaultImage: false,
    showDotEffect: true,
    noAnimatedMarker: false,
    use3dMarkers: false,
  };
  defaultDeviceImage = "assets/device_markers/paj_iconset_logo.svg";
  thickness = 5;
  mapType = "";
  animations = [];
  isArfRunning = false;
  timerCheckFrame = null;

  threedMarkers = null;
  threedHelper = new ThreedHelper();
  threedLayer = null;
  dimension = "2d";
  stopListeners = new Subject();

  deviceMenuOpen = false;
  mainMenuOpen = false;
  speedCamerasClass = null; // new SpeedCameras(this);
  subAccountsMarkersClass = null; // new SubAccountsMarkers(this);
  clientLocationClass = null; // new ClientLocation(this);
  alarmMarkersClass = null; // new AlarmMarkers(this);
  pauseMarkersClass = null; // new PauseMarkers(this);
  symbolMarkersClass = new SymbolMarkers(this);
  waterMarkClass = null; // new Watermark(this);
  deviceNameMarkers = null; // new DeviceNameMarkers(this);
  HighSpeedPolylinesClass = null; // new HighSpeedPolylines(this);
  isBrowser = false;

  gpsWatcher: any = null;
  currentLocation: any = null;
  clickedOrTaped = false;
  dashboardMenuIsOpen = false;
  isIos = Capacitor.isNativePlatform() && Capacitor.getPlatform() == "ios";
  isSliderOpen = false;
  activateFab = false;
  fabValue = false;
  rescaleImageManagament = new ImageStackManagament();

  graphhopperLegendIsOpen = false;
  mapMonitorLegendIsOpen = false;
  mapZooming = false;
  savedPitch: number = 0;
  isSliderViewEnable = false;
  connectWithServices: { mapService: boolean, } = { mapService: false, };
  constructor(
    private api: ApiService,
    public platform: Platform,
    public appService: AppService,
    private imageService: DeviceImageService,
    private mediaApi: MediaApiService,
    private http: HttpClient,
    public storage: StorageService,
    public authService: AuthenticationService,
    public _translate: TranslateService,
    public modalCtrl: ModalController,
    private route: ActivatedRoute,
    private location: Location,
    public apiService: ApiService,
    private rendered: Renderer2,
    private popoverController: PopoverController,
    private router: Router,
    public mapService: MapService
  ) {
    //_ Init DynamicMapLib class
    super();

    this.id = GenerateUUID();
    // this.checkArf();
    this.isBrowser = this.platform.is("desktop");
  }

  async ngOnInit() {
    let style = getComputedStyle(document.body);
    this.primaryColor = style.getPropertyValue("--ion-color-primary");
    this.userSettings = this.customerSettings ?? await this.storage.get(Constants.USER_SETTINGS);
    //_ Load performance vars before create sources or layers
    this.storage.storageReady.subscribe((r) => {
      if (r) {
        //_ Apply performance setting sent
        Object.keys(this.performanceSettings).forEach(key => {
          this.performance[key] = this.performanceSettings[key];
        })

        this.markersSource = this.createDataSource("markersSource");
        this.dataSource = this.createDataSource("dataSource");

        this.directionsSource = this.createDataSource("directionsSource");
        this.scrollDirectionsSource = this.createDataSource(
          "scrollDirectionsSource"
        );

        this.effectsLayer = this.createLayer("effects", "markersSource");
        this.markersLayer = this.createLayer("markers", "markersSource");
        this.routeLayer = this.createLayer("route", "dataSource");
        // this.pausesLayer = this.createLayer("pauses", "dataSource");
        this.directionsLayer = this.createLayer(
          "directions",
          "directionsSource"
        );
        this.scrollDirectionsLayer = this.createLayer(
          "scrollDirections",
          "scrollDirectionsSource"
        );

        // this.alarmsLayer = this.createLayer("alarms", "alarmsSource");
        this.circlesLayer = this.createLayer("circles", "markersSource");
        this.wifiDirectionsLayer = this.createLayer(
          "wifiDirections",
          "directionsSource"
        );

        this.clusterBgMarkers = this.createLayer(
          "clusterBgMarkers",
          "markersSource"
        );
        this.clusterCountMarkers = this.createLayer(
          "clusterCountMarkers",
          "markersSource"
        );
        this.clusterMarkers = this.createLayer(
          "clusterMarkers",
          "markersSource"
        );

        this.onlineDotLayer = this.createLayer("onlineDot", "markersSource");
        this.threedLayer = this.threedHelper.createLayer("markersSource");
        1;

        this.isDarkmode = this.appService.darkMode;
        this.observables["darkMode"] = this.appService.darkMode$.subscribe(
          (r) => {
            if (r != this.isDarkmode) {
              this.isDarkmode = r;
              if (this.map) this.setMapStyle(this.mapStyle, this.dimension, null);
            }
          }
        );

        //set map style after map is ready and usersettings loaded from server
        this.mapReady.subscribe((status) => {
          if (!status) return;
        });

        // this.isHeatMapMode = this.appService.heatMapMode;
        // this.observables["heatMapMode"] =
        //   this.appService.heatMapMode$.subscribe((r) => {
        //     if (this.isHeatMapMode != r) {
        //       this.isHeatMapMode = r;
        //       this.setMapStyle(this.mapStyle, this.dimension, null);
        //     }
        //   });

        if (this.appService.user && !this.fakeUser) {
          this.user = this.appService.user;
          this.createMap(this.appService.user);
          return;
        }

        if (!this.fakeUser && !this.user) {
          this.appService.user$.pipe(first()).subscribe((u: any) => {
            this.createMap(u);
          });
        } else {
          this.createMap(this.fakeUser);
        }
      }
    });

    if (!this.fakeUser && this.appService.user) {
      const now = new Date();
      let data = {
        last_app_activity: now.toISOString().replace("Z", "").replace("T", " "),
      };

      if (this.appService.user.id != -1)
        this.api.updateCustomer(data, this.appService.user.id);
    }

    this.isArfRunning = (await App.getState()).isActive;
    App.addListener("appStateChange", (status) => {
      this.isArfRunning = status.isActive;
      if (!status.isActive) this.stopArf();
    });
    this.appService._userSettings$.subscribe(async (data) => {
      this.userSettings = await this.storage.get(Constants.USER_SETTINGS);
    });
  }

  alreadyCreated = false;
  async createMap(user) {
    // if (!this.alreadyCreated) return;

    // this.alreadyCreated = true;
    // this.delay(200);
    this.user = user;
    this.mapStyle = user.map;
    this.useMapbox = this.shouldUseMapboxLib(this.mapStyle).useMapbox;
    await this.setupMapLibrary(this.useMapbox);

    this.buildMap();
    this.loadListeners();

    if (user.map == "3d") {
      setTimeout(
        () => this.map.easeTo({ pitch: 70, bearing: this.map?.getBearing() }),
        500
      );
    }

    this.appService.language$.subscribe((language) => {
      this.mapboxControlsTranslation();
    });
  }
  // setupHeatMap() {
  //   this.storage.get("heatMapMode").then(async (res) => {
  //     this.isHeatMapMode = res;
  //     if (res) {
  //       this.addHeatMapLayer();
  //       this.hideAlarms();
  //       this.hidePauses();
  //       this.hidePolylines();
  //       this.hideDirectionMarkers("gps");
  //       this.speedCamerasClass.hideCameras();
  //     } else {
  //       this.removeHeatMapLayer();
  //       this.showAlarmsIfEnabled();
  //       this.showPausesIfEnabled();
  //       this.showPolylinesIfEnabled();
  //       this.showDirectionMarkersIfEnabled("gps");
  //       let value = await this.storage.get("showSpeedCameras");
  //       this.speedCamerasClass.showCamerasIfEnabled(value ? value : false);
  //     }
  //   });
  // }

  ngOnDestroy() {
    //console.log('DESTROYIING MAP COMPONENT ... >>>>>>>>>>');
    Object.keys(this.observables).forEach((k) =>
      this.observables[k].unsubscribe()
    );
    window.clearInterval(this.timer);
    // this.stopListeners.complete();
    this.HOOKS.onDestroy.emit({});
    this.HOOKS.offAll();
    this.clientLocationClass?.stopClientLocation();
    this.panFeature?.destroy();

    this.map.remove();
  }

  showSlider = false;
  observables = {};
  loadListeners() {
    if (this.connectWithServices.mapService === true) {
      this.observables["styleChanged"] = this.mapService.style$.subscribe((s) => {
        this.user.map = s;
        this.dimension = this.mapService.mapStyleDimension.value;
        this.setMapStyle(s, this.dimension, null);
      });

      this.user.map = this.mapService.mapStyle.value;
      this.setMapStyle(this.mapService.mapStyle.value, this.dimension, null);
    }
  }

  getId() {
    return this.id;
  }

  async buildMap() {
    await this.delay(200);
    let dragRotate = this.mapStyle == "3d" ? true : false;
    let touchRotate = this.mapStyle == "3d" ? true : false;
    let style = await this.createMapStyle(this.mapStyle);

    window.devicePixelRatio = Constants.MAP_PIXEL_RATIO;

    const mapOptions = {
      container: "map-" + this.id,
      style: style,
      zoom: 0,
      maxZoom: 18,
      center: [13.404954, 52.520008], //Berlin, Germany Lat and Lng
      pitch: this.pitch, // pitch in degrees
      bearing: this.bearing,
      interactive: this.interactive,
      pitchWithRotate: true,
      dragRotate,
      //_ Search how to wrap this in new maplibre lib
      // touchRotate,
      renderWorldCopies: false,
      antialias: false, //_ Cause performance issue activate this
      // pixelRatio: 1,
      maxTileCacheSize: 1,
      projection: 'mercator'
    }

    //_ Remove cache tiles for ios
    if (window['isSafari'])
      mapOptions['maxTileCacheSize'] = 0;

    //_ CREATE MAP
    this.map = new this.Map(mapOptions);

    let tc = this;
    this.panFeature = new PanFeature(this.map);
    // First load
    this.map.on("load", async () => {
      // Add an event listener to track changes in pitch
      this.map.on('pitch', () => {
       tc.savedPitch = this.map.getPitch();
         // Save the pitch to localStorage
          localStorage.setItem('savedPitch', this.savedPitch.toString());
      });

      //_ Post event for metrics
      // this.authService.countlyMetrics({ key: 'Map Init ' + (this.useMapbox ? 'mapbox' : 'maplibre')  }).subscribe();

      tc.map.resize();

      tc.firstLoad();
      tc.onClickMarkers();

      // this.updateAccordingToAppModes();

      // Load all data after change the style of the map to 3D
      tc.map.on("style.load", async () => {
        tc.firstLoad();
      });

      // trigger styledata when change to rasted style
      tc.map.on("styledata", async (e) => {
        if (tc.setStyle && tc.mapStyle != "3d") {
          // Wait one second to load all layers again
          // Fix trick: load all layers after drawer layers were loaded
          await setTimeout(() => {
            tc.setStyle = false;
            tc.firstLoad(false);
          }, 1000);
        }
      });

      this.map.once("idle", () => {
        // tc.clusterize(tc.performance.clusterMarkers, 'Idle');
        tc.mapReady.next(true);
        // tc.setupHeatMap();
        tc.HOOKS.onReady.emit(true);
        this.map.on("zoom", () => {
          const currentZoom = tc.map.getZoom();
        });
        if(this.dimension== '3d'){
          this.setMapStyle(this.mapStyle,this.dimension,null)
        }
      });

      // Fix toggle click info mapboox bottom right corner
      this.fixMapInfo();
    });

    this.map.addControl(
      new this.NavigationControl({
        showCompass: false,
        showZoom: true,
        visualizePitch: false,
      }),
      "top-left"
    );

    // this.geoTrackerCap = new geolocationControlForCapacitor();
    // this.map.addControl(this.geoTrackerCap, "bottom-right");

    this.geoTracker = new this.GeolocateControl({
      positionOptions: { enableHighAccuracy: true },
      trackUserLocation: true,
    });
    this.map.addControl(this.geoTracker, "bottom-right");

    //_ Enable pitch first load
    //_ Search how to do this in new maplibre
    // this.map["dragRotate"]._mousePitch._enabled = true;

    //_ Initialize 3dMarers class
    if (this.performance.use3dMarkers) this.threedHelper.init(this);
  }

  geoTracker = null;
  geoTrackerCap = null;
  // Use for first time load
  // And used after change the style to realod all sources, images and layers
  async firstLoad(loadImages = true) {
    // Load images
    // Load markers images when change the style
    if (loadImages) {
      if (this.markersImages.length > 0) {
        this.markersImages.forEach(async (mi) => {
          if (mi.isEffect) {
            if (!this.map.hasImage(mi.name)) {
              this.map.addImage(mi.name, mi.url);
            }
          } else
            await this.map.loadImage(mi.url, (error, image) => {
              if (!this.map.hasImage(mi.name) && !error)
                this.map.addImage(mi.name, image);
            });
        });
      }

      // LOAD DIRECTION IMAGE ONLY ONECE for use in Direction layer
      if (this.user) {
        await this.map.loadImage(
          directionMarker[this.user.route_icons],
          (error, image) => {
            if (!this.map.hasImage("direction-marker") && !error)
              this.map.addImage("direction-marker", image, { sdf: true });
          }
        );
      }

      // LOAD DOT IMAGE ONLY ONECE for use in onlineDotLayer
      if (this.user) {
        await this.map.loadImage(
          "assets/images/map/dot.png",
          (error, image) => {
            if (!this.map.hasImage("dot-marker") && !error)
              this.map.addImage("dot-marker", image, { sdf: true });
          }
        );
      }

      //_ Load wifi marker image
      await this.map.loadImage(directionMarker["wifi"], (error, image) => {
        if (!error)
          this.map.addImage("direction-marker-wifi", image, { sdf: true });
      });

      //_ Load wifi marker image
      const img: any = await resizeImage(
        "assets/device_markers/paj_iconset_logo.svg",
        122,
        170,
        "white",
        false
      );
      await this.map.loadImage(img, (error, image) => {
        if (!error)
          this.map.addImage("default-device-marker", image, { sdf: false });
      });

      if (this.enableSpeedCameras) this.speedCamerasClass?.loadImages();

      this.HOOKS.onLoadImages.emit({});
    }

    //_ Adding Sources to the map
    if (!this.map.getSource("markersSource")) {
      this.markersSource.cluster = this.performance.clusterMarkers;
      this.map.addSource("markersSource", this.markersSource);
    }

    if (!this.map.getSource("dataSource"))
      this.map.addSource("dataSource", this.dataSource);
    if (!this.map.getSource("directionsSource"))
      this.map.addSource("directionsSource", {
        ...this.directionsSource,
        data: { features: [] },
      });
    if (!this.map.getSource("scrollDirectionsSource"))
      this.map.addSource("scrollDirectionsSource", this.scrollDirectionsSource);

    this.HOOKS.onLoadSource.emit({});

    //_ Adding Layers to the map
    //_ The second parameter is to show behind other layer like the z-index works

    if (!this.map.getLayer("clusterCountMarkers"))
      this.map.addLayer(this.clusterCountMarkers); // This layers is on top of all others !!

    if (!this.map.getLayer("clusterBgMarkers"))
      this.map.addLayer(this.clusterBgMarkers, "clusterCountMarkers");

    if (!this.map.getLayer("clusterMarkers"))
      this.map.addLayer(this.clusterMarkers, "clusterBgMarkers");

    if (!this.map.getLayer("scrollDirections"))
      this.map.addLayer(this.scrollDirectionsLayer, "clusterBgMarkers");

    if (!this.map.getLayer("onlineDot"))
      this.map.addLayer(this.onlineDotLayer, "clusterMarkers");

    if (!this.map.getLayer("markers") && !this.performance.use3dMarkers)
      this.map.addLayer(this.markersLayer, "onlineDot");
    else if (this.performance.use3dMarkers && !this.map.getLayer("markers")) {
      this.map.addLayer(this.threedLayer, "onlineDot");
    }

    if (!this.map.getLayer("effects"))
      this.map.addLayer(this.effectsLayer, "markers");

    if (!this.map.getLayer("route"))
      this.map.addLayer(this.routeLayer, "markers");

    if (!this.map.getLayer("circles"))
      this.map.addLayer(this.circlesLayer, "markers");

    if (!this.map.getLayer("directions"))
      this.map.addLayer(this.directionsLayer, "markers");

    if (!this.map.getLayer("wifiDirections"))
      this.map.addLayer(this.wifiDirectionsLayer, "markers");

    if (this.enableSpeedCameras) this.speedCamerasClass?.load();

    this.HOOKS.onLoadLayer.emit({});
    this.filterDirectionsMarkers(true);
  }

  // Use for change style, to generate style with layer and source
  async createMapStyle(mapStyle) {
    let style: any = new Style();
    //_ Only used for raster tiles
    if (mapStyle == "pajLite") {
      style = new Style();
      let layer = new Layer("simple-tiles");

      //Maptiler Satellite Tiles test
      // if (this.user.isadmin == 1) {
      //   style.sources["raster-tiles"].tiles.push(urlStyles['mtsatellite']);
      // }else{
      //   style.sources["raster-tiles"].tiles.push(urlStyles[mapStyle]);
      // }

      style.sources["raster-tiles"].tiles.push(MapStylesList[mapStyle].url);

      layer.type = layerType.raster;
      layer.source = "raster-tiles";
      style.layers.push(layer);
    }
    //_ Used for vector tiles
    else {
      // if (this.dimension == "3d" && !this.isMapboxStyle(mapStyle)) {
      //   style = MapStylesList["3d"].url;
      // } else {
        const newStyle = MapStylesList[mapStyle.toLowerCase()]?.url;
        style = newStyle ?? MapStylesList.paj.url;
      // }
      //_ If app is in Darkmode, it should exchange layers colors applyDarkStyle
      // const mapboxNoSopportOfDakrStyle = ['mapbox_standard', 'mapbox_outdoor_v11'];
      if ((this.isDarkmode)) {
        //_ Mapbox standard style uses light presets so we can use night to show as dark mode :)
        if (this.mapStyle === 'mapbox_standard') {
          setTimeout(() => this.map.setConfigProperty('basemap', 'lightPreset', 'night'), 300);
        } else if (this.mapStyle === 'mapbox_outdoor_v11') {
          style = Constants.MAP_TILES_MAPBOX_DARK_V10;
        } else if (!this.mapStyle.startsWith('mapbox')){
          style = await new Promise ((resolve) => {
            this.http.get(style).subscribe((res) => {
              resolve(res)
            });
          });

          style = applyDarkStyle(style, mapStyle);
        }
      }
    }

    console.log('NEW STYLE ', style)
    return style;
  }

  isMapboxStyle (styleName) {
    console.log('STYLE NAME', styleName)
    return styleName?.toLowerCase().startsWith('mapbox');
  }

  public async _addMarker(device, _image = null, updateMap = false, size = { width: 122, height: 170 }) {
    //_ if use3dMarkers should only add a 3d marker no custom layers markers
    if (this.performance.use3dMarkers) {
      this.add3dMarker(device, updateMap);
      return;
    }

    //_ Prevent not duplicate the marker when already exist and call more than 1 times addMarker
    let featureIndex = this.markersSource.data.features
      .map((x: any) => x.properties["deviceId"])
      .indexOf(device.id);
    if (featureIndex != -1) {
      this._moveMarker(device, updateMap);
      return;
    }

    if (this.performance.defaultImage) _image = this.defaultDeviceImage;

    // if no pass image parameter then get device image from mapUtil function
    if (_image) this.addMarker(device, _image, true, updateMap, size);
    else {
      //_ Get image from image service
      this.imageService
        .getImage(device.properties, this.user.id)
        .subscribe(async (r) => {
          if (r != this.mediaApi.whitePicture)
            this.addMarker(device, r, false, updateMap, size);
        }); //getDeviceImage(device, this.user.id);
    }
  }

  add3dMarker(device, updateMap = false, layerOptions = {}) {
    let name = "marker-" + device.id;
    let geometry = new Geometry();
    let properties: any = {} as IProperties;

    const iconSize = this.markerSize ?? { width: 50, hight: 50 };

    geometry.type = GeometryTypes.Point;
    geometry.coordinates = device.lastLocation;
    properties.icon = name;
    properties["image"] = ""; //_ name of the image to show instead icon
    properties.size = (iconSize.width * 100) / 170 / 100;
    properties["deviceShow"] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker ( 1 or 0 fix when have true or false value deviceshow)
    properties["layerType"] = "marker"; //Type of feature
    properties["deviceId"] = device.id;
    properties["online"] = device.online;
    properties["deviceName"] = device.properties.name;
    properties["deviceColor"] = device.properties.spurfarbe;
    properties["lastPoint"] = device.lastPoint;
    properties["showBlinking"] = !this.performance.showDotEffect;
    properties["modelName"] = device.properties.threedModel_name;
    properties.color = device.color;
    properties["isSliderViewOpen"] = this.isSliderViewEnable;

    Object.keys(layerOptions).forEach(key => {
      properties[key] = layerOptions[key];
    });

    //_ Only add feature to markersSource to store that and use for animation and motion
    //_ Not update the marker in the map if performance use3dMarkers is enabled
    let fmarker = this._createFeature(
      geometry,
      properties,
      "marker-" + device.id
    );
    this.markersSource.data.features.push(fmarker);

    if (this.map.getSource("markersSource") && updateMap)
      getSource(this.map, "markersSource").setData(this.markersSource.data);
    if (device.lastPoint) this.threedHelper.addObject(fmarker);
  }

  async addMarker(device, img, isDefault = false, updateMap = false, size = { width: 122, height: 170 }, layerOptions = {}) {
    //_ Not add marker if location is null or undefined
    if (
      device.lastLocation[0] == undefined ||
      device.lastLocation[1] == undefined
    )
      return;
    let name = "marker-" + device.id;

    //_ Set the name of the image to reuse this one.
    let imageName = isDefault
      ? img.split("/")[2]
      : getDeviceImageName(device);
    if (
      this.markersSource.data.features
        .map((x) => x.properties.icon)
        .indexOf(name) == -1
    ) {
      if (img.endsWith(".svg"))
        //_ Change aspect ratio if is a paj_iconset svg image
        img = await this.rescaleImageManagament.resizeImage(img, size.width, size.height, "white", false, false, null, imageName);
      else img = await this.rescaleImageManagament.resizeImage(img, 170, 170, "white", false, true, null, imageName);
    }

    //_ Verify if exist the image in other icon property of the marker
    let imageAdded = !(
      this.markersSource.data.features
        .map((x) => x.properties.image)
        .indexOf(imageName) == -1
    );

    if (!imageAdded) {
      imageAdded = await this.loadImage(device, imageName, img); //.then(
      //   (r: boolean) => (imageAdded = r)
      // );
    }

    if (imageAdded) {
      let geometry = new Geometry();
      let properties = {} as IProperties;
      geometry.type = GeometryTypes.Point;
      geometry.coordinates = device.lastLocation;
      properties.icon = name;
      properties["image"] = imageName; //_ name of the image to show instead icon

      // This is a porcentage of the selected width in settings
      const iconSize = this.markerSize ?? { width: 50, hight: 50 };
      properties.size = (iconSize.width * 100) / 170 / 100;
      properties["deviceShow"] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker ( 1 or 0 fix when have true or false value deviceshow)
      properties["layerType"] = "marker"; //Type of feature
      properties["deviceId"] = device.id;
      properties["online"] = device.online;
      properties["showBlinking"] = !this.performance.showDotEffect;
      properties["icon-offset"] = [0, 0];
      properties["isSliderViewOpen"] = this.isSliderViewEnable;

      Object.keys(layerOptions).forEach(key => {
        properties[key] = layerOptions[key];
      });

      // properties['subImage'] = '';
      properties.color = device.color;

      //_ If is online should load online pulse image
      if (device.online && !this.performance.showDotEffect) {
        const effectId = "effect-" + device.id;
        properties["effect"] = effectId;
        let imgEffect = new Effects().PulseShadow(
          this.map,
          "#000",
          device.color,
          200
        );

        if (!this.map.hasImage(effectId)) {
          this.map.addImage(effectId, imgEffect, { pixelRatio: 2 });
          this.markersImages.push({
            name: effectId,
            url: imgEffect,
            isEffect: true,
          });
        }
      }

      //_ Prevent not duplicate the marker when already exist and call more than 1 times addMarker
      let fmarker = this._createFeature(
        geometry,
        properties,
        "marker-" + device.id
      );
      this.markersSource.data.features.push(fmarker);
      // }

      if (this.map.getSource("markersSource") && updateMap)
        getSource(this.map, "markersSource").setData(this.markersSource.data);
      this.HOOKS.onMarkerAdded.emit({
        feature: fmarker,
        device: device,
        updateMap,
      });
    }
  }

  public async addLayerMarkerUsingExistentImage(name, lng, lat, size, title, data = null, alignment = 'viewport', props, updateMap = true) {
    let marker = this.createFeature([lng, lat], name, title, size, 'center', data, alignment);
    marker.properties["image"] = name; //_ name of the image to show instead icon
    marker.properties.size = size;
    marker.properties["deviceShow"] = 1; // Use to filter and hide the device marker ( 1 or 0 fix when have true or false value deviceshow)
    marker.properties["layerType"] = "marker"; //Type of feature

    //_ Apply custom props to the marker
    Object.keys(props).forEach(key => {
      marker.properties[key] = props[key];
    })

    this.markersSource.data.features.push(marker);
    if (getSource(this.map, 'markersSource')) {
      getSource(this.map, 'markersSource').setData(this.markersSource.data);
      this.HOOKS.onMarkerAdded.emit({
        feature: marker,
        device: null,
        updateMap
      });
    }
  }

  imagesLoadingInMap = {};
  async loadImage(device, name, src) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!this.map.hasImage(name) && !this.imagesLoadingInMap[name]) {
        this.imagesLoadingInMap[name] = name;
        this.map.loadImage(src, async (error, image) => {
          // If the image cant be loaded, call this function with a default icon
          if (error) {
            this._addMarker(device, "assets/device_markers/paj_iconset_logo.svg"); //throw error;
            console.log("ERROR: ", error);
            resolve(false);
          } else {
            if (!this.map.hasImage(name) && !this.markersImages.some(mimg => mimg.name == name )) {
              await this.map.addImage(name, image);
              this.markersImages.push({ name: name, url: src });
            }
            resolve(true);
          }
        });
      }
      else
        resolve(true);
    });
  }

  public async _moveMarker(device, updateMap = false) {
    let name = "marker-" + device.id;

    this.cancelAnimation(device);
    // Search the marker Feature by yname
    let feature = this.markersSource.data.features.find((f) => f.id == name);
    if (feature) {
      let index = this.markersSource.data.features.indexOf(feature);
      //Get the index of the feature
      if (index > -1) {
        this.markersSource.data.features[index].geometry.coordinates =
          device.lastLocation;
        //_ Move the 3d model if use3dMarkers is tru
        if (this.performance.use3dMarkers)
          this.threedHelper.moveObject(device.id, device.lastLocation);
        // Only update datasource if updateMap flag is true
        if (getSource(this.map, "markersSource") && updateMap)
          getSource(this.map, "markersSource").setData(this.markersSource.data);
      }
    }
  }

  public async animateMarker(device) {
    let name = "marker-" + device.id;

    this.cancelAnimation(device);

    const JUST_MOVE_THE_MARKER = () => {
      // this._addDataPoints(device, false, true, false);
      if (this.performance.use3dMarkers) {
        this.threedHelper.moveObject(device.id, device.lastLocation);
      } else {
        this._moveMarker(device, true);
      }
    };

    //_ No animate the marker if is not running Arf
    if (this.performance.noAnimatedMarker || !this.isArfRunning) {
      //_ Should move the 3dMarker and exit of the method if performance.use3dMarkers is enabled
      JUST_MOVE_THE_MARKER();
      return;
    }

    // Search the marker Feature by yname
    let feature = this.markersSource.data.features.find((f) => f.id == name);
    if (feature) {
      let index = this.markersSource.data.features.indexOf(feature);
      //Get the index of the feature
      if (index > -1) {
        //_ Save first and last point to stop the animation if lastPoint changed
        // const previousLastPoint = this.markersSource.data.features[index].properties['animation'].lastPoint;
        let firstPoint = feature.geometry.coordinates;
        let lastPoint = device.lastLocation;
        this.markersSource.data.features[index].properties["animation"] = {
          firstPoint,
          lastPoint,
          pointData: device.lastPoint,
          animatingPoints: [...device.newDirectionMarkers],
        };

        //_ get distance between two points
        let distanceBetweenPoints = 0;
        if (firstPoint && lastPoint) {
          distanceBetweenPoints = Math.abs(
            Tdistance(firstPoint, lastPoint, { units: "meters" })
          );
        }
        if (
          device.newSnapedPoints.length > 0 &&
          distanceBetweenPoints < this.MAX_DISTANCE_TO_SPLIT_SEGMENTS
        ) {
          //_ Animate the marker using snaped points
          let points = device.newSnapedPoints;
          const useGraphhopper = this.appService?.userSettings?.use_graphhopper ?? this.customerSettings?.useGraphhopper;
          if (!useGraphhopper)
            points = this.getPointsFromStringLegs(device.newSnapedPoints);
          else
            points = this.generatePointsForPath(device.newSnapedPoints);
          // points.push(device.lastLocation);
          // console.log('MAKE AIMATION 1');
          this.makeAnimation(0, points, index, device, true);
        } else {
          //_ Animate the marker & generating points
          if (firstPoint && lastPoint) {
            if (distanceBetweenPoints > this.MAX_DISTANCE_TO_SPLIT_SEGMENTS) {
              // console.log('MAKE AIMATION 2');
              JUST_MOVE_THE_MARKER();
              return;
            } else {
              //_ Generate points between new points (no snaped)
              let points = this.generatePointsForPath(device.newRealtimePoints);
              if (points.length) {
                // console.log('MAKE AIMATION 3');
                this.makeAnimation(0, points, index, device, false);
              }
            }
          } else {
            // this.markersSource.data.features[index].geometry.coordinates = device.lastLocation;
            // getSource(this.map, "markersSource").setData(this.markersSource.data);
            JUST_MOVE_THE_MARKER();
          }
        }
      }
    }
  }

  generatePointsForPath (points) {
    let generatedPoints = [];
    points.forEach((p, index) => {
      if (index > 0) {
        let pointA = null;
        let pointB = null;
        if (Array.isArray(p)){
          pointA = { lng: p[0], lat: p[1] };
          pointB = { lng: points[index-1][0], lat: points[index-1][1] };
        }
        else {
          pointA = p;
          pointB = points[index-1];
        }

        //_ [lng, lat];
        generatedPoints = generatedPoints.concat(
          this.generatePointsBetweenTwoPoints([pointB.lng, pointB.lat], [pointA.lng, pointA.lat])
        );
      }
    });

    return generatedPoints;
  }

  //_ Check if a point is in the viewport of the map
  pointInView(point) {
    return this.map ? this.map.getBounds().contains(point) : false;
  }

  async makeAnimation(
    animIndex = 0,
    points,
    animFeature,
    device,
    areSnapedPoints,
    timestamp = null,
    time = { start: null, lastTime: null }
  ) {
    const feature = this.markersSource.data.features[animFeature];
    // device['id'] == 163189 ? console.log(this.markersSource.data.features[animFeature].properties.animation.animatingPoints): '';
    //_ Move the marker and add polyline
    const moveTo = (point, index, animationEnds = false) => {
      this.markersSource.data.features[animFeature].geometry.coordinates =
        point;
      if (animationEnds) {
        const slicedPoints = points.slice((points.length - 1 - index) * -1);
        // console.log('Ending animation', { points: {...points}, leftPoints: slicedPoints, index });
        slicedPoints.forEach((p, indexP) => {
          // console.log('index inner', indexP);
          //_ Only add the route point if monitor mode is disabled
          this.addSinglePoint(
            device,
            "realtime-route-" + device.id,
            p,
            indexP == slicedPoints.length - 1
          );
        });
      } else {
        //_ Only add the route point if monitor mode is disabled
        this.addSinglePoint(
          device,
          "realtime-route-" + device.id,
          point,
          true,
          index == 0
        );
      }

      //_ Move focus only when animation ends or is near from last point of animation
      if (animationEnds || (index >= points.length - 4 && index <= points.length)){
        if (device.autoFocus === true) {
          // console.log('Doing autofocus');
          this.map.flyTo({
            center: [points[points.length - 1][0], points[points.length - 1][1]],
          });
        }
      }

      //_ Start animating 3dmodel
      if (this.performance.use3dMarkers)
        this.threedHelper.moveObject(device.id, point);

      if (this.map.getSource("markersSource"))
        getSource(this.map, "markersSource").setData(this.markersSource.data);
    };

    // animIndex++;
    let elapsed = 0;
    if (timestamp) {
      //_ Check prev time exists
      if (time.lastTime) elapsed = timestamp - time.lastTime;

      if (!time.start) time.start = timestamp;
      const lapsed = timestamp - time.start;
      //_ Fix last time when passed 100 ms
      if (elapsed >= 200 || !time.lastTime) time.lastTime = timestamp;

      const timeToNext = Math.round(10000 / points.length);
      const relativeIndex = Math.round(lapsed / timeToNext);
      animIndex = relativeIndex;
      // console.log('ELAPSED TIME', { timestamp, elapsed, time, timeToNext });
    }

    //_ Draw next point in map only if elapsed time is greater than 100 ms
    if (elapsed >= 200 || !time.lastTime) moveTo(points[animIndex], animIndex);

    //_ Break animation if ARF is not running
    if (animIndex < points.length - 1 && this.isArfRunning) {

      return (this.animations[device.id] = {
        animation: requestAnimationFrame((_timestamp) =>
          this.makeAnimation(
            animIndex++,
            points,
            animFeature,
            device,
            areSnapedPoints,
            _timestamp,
            time
          )
        ),
        feature: animFeature,
        cancelAnimation: () => {
          // console.log('CANCEL Animation index is: ', device.properties.name);
          if (animIndex < points.length - 1) {
            // console.log('Animation index is less than the length of points', {animIndex, pointsLength: points.length-1});
            moveTo(points[points.length - 1], animIndex++, true);
            device.newDirectionMarkers =
              feature.properties.animation.animatingPoints;
            //_ Dont forget to add the route markers when animation ends
            this._addDirectionMarkers(device, false, true, false);
            // this.onAnimationStops.emit({ device, lastPointIndex: animIndex, points, areSnapedPoints });
          }
          // console.log('Canceled: ', device.properties.name);
        },
      });

      // return;
    }
    //_ Dont forget to add the route markers when animation ends
    device.newDirectionMarkers = feature.properties.animation.animatingPoints;
    this._addDirectionMarkers(device, false, true, false);
  }

  cancelAnimation(device) {
    if (this.animations[device.id]) {
      this.animations[device.id].cancelAnimation();
      cancelAnimationFrame(this.animations[device.id].animation);
      this.animations[device.id] = undefined;
    }
  }

  //_ Used in animation knowing the featureId this method add new polyline point
  addSinglePoint(
    device,
    featureId,
    point,
    updateMap = true,
    createNewFeature = false
  ) {
    let isNew = false;
    let routeFeatures = this.dataSource.data.features.filter(
      (d) => d.properties.id == featureId
    ); //this.searchFeatureById(this.dataSource, featureId);

    let distancePrevPoint = 0;
    if (createNewFeature && routeFeatures.length > 0) {
      distancePrevPoint = Tdistance(
        routeFeatures[routeFeatures.length - 1].geometry.coordinates[
          routeFeatures[routeFeatures.length - 1].geometry.coordinates.length -
            1
        ],
        point,
        { units: "meters" }
      );
    }

    let routeFeature = null;
    //_ Check in case some last point is not near to the new point; then use same feature otherwise create new one
    if (
      routeFeatures &&
      distancePrevPoint < this.MAX_DISTANCE_TO_SPLIT_SEGMENTS
    )
      routeFeature = routeFeatures[routeFeatures.length - 1];

    if (routeFeature) {
      // console.log('Feature realtime animation found', { distancePrevPoint, polyPoint: routeFeature.geometry.coordinates[routeFeature.geometry.coordinates.length-1].concat(point) });
      routeFeature.properties["deviceShow"] = device.properties.deviceshow
        ? 1
        : 0;

      routeFeature.geometry.coordinates.push(point);
    } else {
      // console.log('Feature realtime animation NOT FOUND');
      let properties = {} as IProperties;
      let geometry = new Geometry();
      isNew = true;
      geometry.type = GeometryTypes.LineString;
      geometry.coordinates.push(point);
      properties.color = device.color;
      properties["layerType"] = "route"; //Type of feature
      properties["deviceShow"] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker
      properties.size = this.thickness;
      properties["border-color"] = "#000";
      properties["border-width"] = 0;
      properties["id"] = featureId;
      properties["deviceId"] = device.id;
      routeFeature = this._createFeature(geometry, properties);
      routeFeature["generateId"] = true;
      properties["showPolylines"] = this.showPolylines;
    }

    if (!isNew) {
      let index = this.dataSource.data.features.indexOf(routeFeature);
      // Remove the feature and add new route feature
      this.dataSource.data.features.slice(index, 1, routeFeature);
    } else this.dataSource.data.features.push(routeFeature);

    if (this.map.getSource("dataSource") && updateMap && this.showPolylines)
      getSource(this.map, "dataSource").setData(this.dataSource.data);
  }

  getPointsFromStringLegs(legs) {
    let points = [];
    legs.forEach((l) => {
      let lastP = null;

      stringToPoints(l, 6).forEach((np) => {
        if (lastP) {
          let genPoints = this.generatePointsBetweenTwoPoints(lastP, np);
          points = points.concat(genPoints);
        }
        lastP = np;
        // points.push(np);
      });
    });
    return points;
  }

  //_ Generate points for the animation
  generatePointsBetweenTwoPoints(pA, pB, length = 100) {
    const line = lineString([pA, pB]);
    const options: any = { units: "meters" };
    const dist = Tdistance(pA, pB, options);
    const distStep = 2; //d5st < 200 ? 0.5 : 1; //dist/length;

    if (dist < 2) return [pA, pB]; //_ Return same points if distance is less than 2 meters

    let rPoints = [];
    for (let i = 0; i <= dist; i += distStep) {
      const p = along(line, i, options);
      rPoints.push(p.geometry.coordinates);
    }

    rPoints.push(pB);
    return rPoints;
  }

  //_ Check Arf
  shouldCheckArf = true;
  checkingArf = null;

  //_ Stop the loop to check if ARF is running
  async stopArf() {
    if (this.shouldCheckArf) {

      this.isArfRunning = false;
      // this.checkingArf = null;

      //_ Cancel all animations from the array
      for (const k of Object.keys(this.animations)) {
        // console.log('Canceling all animations', k);
        if (this.animations[k]?.animation) {
          // console.time('Canceling animation frame');
          cancelAnimationFrame(this.animations[k].animation);
          //_ wait a bit to prevent draw a wrong polyline from the index of the animated point
          await this.delay(200);
          // console.timeEnd('Canceling animation frame');
          this.animations[k] ? this.animations[k].cancelAnimation() : "";
          // console.log('Called method to cancel');
        }
      }
      this.animations = [];
    }
  }

  //_ 250km/h = 70mt/sec so in (30sec = 2100mt) but depends of the delay if the device send location after more than 30 sec and car is in highway, distance will be greether than 2100 mt
  MAX_DISTANCE_TO_SPLIT_SEGMENTS = DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS;
  MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT = 300;
  // Function to add Datapoints, Snaped points and Realtime points
  // Manage differente features for datapoints/snaped points got it in first load
  // And create other feature to show the realtime points
  public async _addDataPoints(
    device,
    removeAll = false,
    realTime = false,
    updateMap = false,
    layerOptions = {}
  ) {
    if (this.dataSource) {
      // If removeAll = true then search the feature of this device, and update all points.
      // propertieId is the name of the feature, when is realtime or route loaded on scrollbar or on first time
      let propertieId = realTime
        ? "realtime-route-" + device.id
        : "route-" + device.id;

      //_ Get device route features
      let routeFeatures = this.searchFeaturesByDeviceId(
        this.dataSource,
        device.id
      );
      // console.log('Route features', { routeFeatures: {...routeFeatures}, removeAll, deviceName: device.properties.name });

      let newFeature: any = null;
      const propertieIdRT = "realtime-route-" + device.id;
      //_ filter to delete the features (realtime or normal route)
      if (removeAll && routeFeatures) {
        if (layerOptions['isSliderData']) {
          routeFeatures = routeFeatures.filter(
            (f) => f.properties['isSliderData'] !== true
          );
        }
        else {
          routeFeatures = routeFeatures.filter(
            (f) => f.properties.id != propertieId && f.properties.id != propertieIdRT
          );
        }
      }

      //_ Base feature data
      let properties = {} as IProperties;
      let geometry = new Geometry();
      geometry.type = GeometryTypes.LineString;
      properties.color = device.color;
      properties["layerType"] = "route"; //Type of feature
      properties["deviceShow"] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker
      properties.size = this.thickness;
      properties["id"] = propertieId;
      properties["deviceId"] = device.id;
      newFeature = this._createFeature(geometry, properties);
      newFeature["generateId"] = true;
      properties["showPolylines"] = this.showPolylines;
      properties["isSliderViewOpen"] = this.isSliderViewEnable;

      Object.keys(layerOptions).forEach(key => {
        properties[key] = layerOptions[key];
      });

      newFeature.properties["deviceShow"] = device.properties.deviceshow
        ? 1
        : 0;
      let deviceFeatures: any = routeFeatures;

      //_ Add polylines when device has new Data Points

      if (device.newDataPoints.length > 0) {
        // && !realTime
        const newFeatures = this.createFeaturesFromDataPoints(
          newFeature,
          device.newDataPoints
        );
        deviceFeatures = deviceFeatures.concat(newFeatures);
      }

      //_ Pushing new realtime points
      if (
        device.newRealtimePoints.length > 0 &&
        realTime &&
        device.newSnapedPoints.length == 0
      ) {
        //
        const newFeatures = this.createFeaturesFromDataPoints(
          newFeature,
          device.newRealtimePoints
        );
        deviceFeatures = deviceFeatures.concat(newFeatures);
      }

      // Add realtime polylines when device has new realtime Points
      // Only if is realtime flag true
      if (realTime && device.newSnapedPoints.length == 0) {
      }

      // Add polylines when device has new Snaped Points
      let snapedArray: any = [];
      const useGraphhopper = this.appService?.userSettings?.use_graphhopper ?? this.customerSettings?.useGraphhopper;
      device.newSnapedPoints.forEach((p) => {
        // Iterate array of new points returned after convert the str polilynes to coordinates
        //_ Graphhopper implementation :: remove realTime check in condition
        //_ when is implemented graphhopper for renderPoints method in backend
        // this.appService.userSettings.use_graphhopper = 1;
        if (!useGraphhopper)
          stringToPoints(p, 6).forEach((np) => {
            snapedArray.push({ lng: np[0], lat: np[1], wifi: null });
          });
        else {
          snapedArray.push({ lng: p[0], lat: p[1], wifi: null });
        }
      });

      //_ Add snaped features to the Device Features array
      if (snapedArray.length > 0) {
        const newFeatures = this.createFeaturesFromDataPoints(
          newFeature,
          snapedArray,
          true
        );
        deviceFeatures = deviceFeatures.concat(newFeatures);
      }

      //_ Add new segmented features
      //_ Filter to get only all route features of the other devices not for this device
      //_ Then merge current Datasource too new Device Source Data
      let deviceSourceData = this.dataSource.data.features.filter(
        (d) =>
          d.properties.deviceId != device.id &&
          d.properties.layerType == "route"
      );

      deviceSourceData = deviceSourceData.concat(deviceFeatures);
      this.dataSource.data.features = deviceSourceData;

      // Added new feature to the dataSource
      // Only update datasource if updateMap flag is true
      if (
        this.map?.getSource("dataSource") &&
        updateMap &&
        this.showPolylines
      ) {
        getSource(this.map, "dataSource").setData(
          this.simplifyPolylines(this.dataSource)
        );
        this.HOOKS.onAddPolylines.emit({
          source: this.dataSource,
          name: "dataSource",
        });
      }
    }
  }

  filterDataSource() {
    let filteredFeatures = [...this.dataSource.data.features];
    if (!this.showPolylines) {
      filteredFeatures = filteredFeatures.filter(
        (f) => f.properties.layerType != "route"
      );
    }

    // const oldSize = this.getVariableSizeInMB(this.dataSource.data);
    // const size = this.getVariableSizeInMB(filteredFeatures);
    // console.warn('Polylines source data', { dataSource: this.dataSource.data, oldSize: oldSize, newDataSource: filteredFeatures, size: size });
    return { ...this.dataSource.data, features: filteredFeatures };
  }

  simplifyPolylines(source) {
    const tolerance = 0.01;
    //_ delete extra properties to reduce the size of data source
    if (source.data.features?.length > 0) {
      source.data.features.map((f) => {
        if (f.properties.lastPointData) delete f.properties.lastPointData;
        return f;
      });

      //_ Simplify gometry coordinates
      source.data.features.map((f) => {
        try {
          const simplify = turf.simplify(f, { tolerance });
          return simplify;
        } catch (error) {
          return f;
        }
      });
    }

    return source.data;
  }
  createFeaturesFromDataPoints(
    initialFeature,
    dataPoints,
    isSnapPoints = false
  ) {
    const featureData = cloneObject(initialFeature);
    let lastLocation: any = null;
    let secondLastLocation: any = null;
    let lastFeature: any = null;
    let newFeatures: any = [];

    dataPoints.forEach((p, index) => {
      // console.log('POINT ID', p.id);
      if (p.wifi == null || p.wifi == undefined) {
        //_ No take the wifi points for the polylines{
        //_ First get lastLocation from lastFeature
        if (lastFeature) {
          lastLocation =
            lastFeature.geometry.coordinates[
              lastFeature.geometry.coordinates.length - 1
            ];
          if (lastFeature.geometry.coordinates.length > 1) {
            secondLastLocation =
              lastFeature.geometry.coordinates[
                lastFeature.geometry.coordinates.length - 2
              ];
          }
        }

        if (lastLocation) {
          let distanceLastLocToNewLoc = Math.abs(
            Tdistance(lastLocation, [p.lng, p.lat], { units: "meters" })
          );

          let distanceSecondLastLocToNewLoc = 0;
          if (secondLastLocation) {
            distanceSecondLastLocToNewLoc = Math.abs(
              Tdistance(secondLastLocation, [p.lng, p.lat], { units: "meters" })
            );
          }

          //_ Then check if last feature coordinate and new coordinate is longer than X meters
          //_ And split in new feature to cut the line into two or more segments
          //_ Also check the time diff in seconds for everypoint
          //************** CHECK TIME DIFF -> but need to fix for valhalla points  */
          let diffSeconds = 30;
          if (lastFeature.properties["lastPointData"] && p.dateunix) {
            const startDate = moment.unix(
              lastFeature.properties["lastPointData"].dateunix
            );
            const endDate = moment.unix(p.dateunix);
            diffSeconds = Math.abs(startDate.diff(endDate, "seconds"));
          }

          let MAX_DISTANCE_PER_SECONDS = Math.abs(
            (this.MAX_DISTANCE_TO_SPLIT_SEGMENTS / 30) * diffSeconds
          );

          //_ Debug distances and time diff
          // if (distanceLastLocToNewLoc > 1000){
          //   console.log('DATA', { distanceLastLocToNewLoc, MAX_DISTANCE_TO_SPLIT_SEGMENTS: this.MAX_DISTANCE_TO_SPLIT_SEGMENTS, diffSeconds, points: { lastLocation, p }});
          // }

          //_ For snap points max lengh not can be calculated by the dateunix so use an small max distance to break the line
          if (isSnapPoints)
            MAX_DISTANCE_PER_SECONDS =
              this.MAX_DISTANCE_TO_SPLIT_SEGMENTS * 5;

          p["last_loc_distance"] = distanceLastLocToNewLoc;
          p["second_last_loc_distance"] = distanceSecondLastLocToNewLoc;
          //_ Added condition to check max diff in seconds from previous point and distance driven in that time
          //_ Conditionnally to distance < MAX_DISTANCE_PER_SECONDS and diffSeconds < MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT
          if (
            distanceLastLocToNewLoc < MAX_DISTANCE_PER_SECONDS &&
            diffSeconds < this.MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT
            // || isSnapPoints
          ) {
            //_ Previous check
            // if (distanceLastLocToNewLoc < this.MAX_DISTANCE_TO_SPLIT_SEGMENTS){
            // console.log('Distance in meters < MAX_DISTANCE_PER_SECONDS', {distanceLastLocToNewLoc, MAX_DISTANCE_PER_SECONDS, MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT: this.MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT, diffSeconds, });
            if (
              p["second_last_loc_distance"] > 0 &&
              p["last_loc_distance"] > p["second_last_loc_distance"]
            ) {
              // Removed point if the distance from last location is greater than second last location
              // Logically the last point is close to the current point than the second last point
              lastFeature.geometry.coordinates.pop();
            }
            lastFeature.geometry.coordinates.push([p.lng, p.lat]);
            lastFeature.properties["lastPointData"] = p; //isSnapPoints ? p : null;
          }
          //_ push new lastFeature and create new feature for next segment
          else {
            // console.log('Distance in meters > MAX_DISTANCE_PER_SECONDS', {distanceLastLocToNewLoc, MAX_DISTANCE_PER_SECONDS, MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT: this.MAX_TIME_IN_SECS_TO_PUSH_INTO_PREVIOUS_SEGMENT});
            // console.log('Spliting polyline', newFeatures.length + 1);
            lastFeature.properties["lastPointData"] = p; //isSnapPoints ? p : null;
            newFeatures.push(cloneObject(lastFeature));
            const newSegment = cloneObject(featureData);
            newSegment.geometry.coordinates.push([p.lng, p.lat]);
            lastFeature = cloneObject(newSegment);
          }
        } else {
          // console.log('Creating First Point');
          initialFeature.geometry.coordinates.push([p.lng, p.lat]);
          initialFeature.properties["lastPointData"] = p; //isSnapPoints ? p : null;
          lastFeature = cloneObject(initialFeature);
          // newFeatures.push(newFeature);
        }
      }

      //_ when finish the looop add lastFeature in case is not added and has some coordinates
      if (
        index == dataPoints.length - 1 &&
        lastFeature.geometry.coordinates.length > 0
      )
        newFeatures.push(lastFeature);
    });

    return newFeatures;
  }

  // Add direction feature on top for use in scroll features
  // Add directions markers features
  public async _addScrollDirection(feature, removeAll = false) {
    if (removeAll)
      // check flag to delete all features
      this.scrollDirectionsSource.data.features = [];

    if (feature)
      // check if feature is not null
      this.scrollDirectionsSource.data.features.push(feature);

    if (this.map.getSource("scrollDirectionsSource"))
      //Update feature in the source of the map
      getSource(this.map, "scrollDirectionsSource").setData(
        this.scrollDirectionsSource.data
      );
  }

  // Add directions markers points
  public async _addDirectionMarkers(
    device,
    removeAll = false,
    realTime = false,
    updateMap = false,
    layerOptions = {}
  ) {
    if (removeAll) {
      // Remove all features that start with 'direction-' propertie id
      // No realtime directions
      const propertieIdRT = "realtime-direction-" + device.id;
      if (realTime)
        this.directionsSource.data.features =
          this.directionsSource.data.features.filter((f) => {
            return !f.id.startsWith("realtime-direction-" + device.id);
          });
      else
        this.directionsSource.data.features =
          this.directionsSource.data.features.filter((f) => {
            return !f.id.startsWith("direction-" + device.id) && !f.id.startsWith(propertieIdRT);
          });
    }

    device.newDirectionMarkers.forEach(async (p) => {
      // name is the propertie id, to disting between realtime and no realtime directions markers
      let name = realTime
        ? "realtime-direction-" + device.id + "-" + p.id
        : "direction-" + device.id + "-" + p.id;

      let geometry = new Geometry();
      let properties = {} as IProperties;
      geometry.type = GeometryTypes.Point;
      geometry.coordinates = [
        Number(p.lng?.toFixed(6)),
        Number(p.lat?.toFixed(6)),
      ];
      if (p.speed <= 2) {
        // This is done using  driving data managament
        // properties.icon = "direction-marker-wifi";
        properties["showDirections"] = this.showDirections;
      } else {
        // properties.icon = "direction-marker";
        properties["showDirections"] = this.showDirections;
      }

      // properties.size = 0.5;
      properties["deviceId"] = device.id;
      properties["id"] = name;
      properties["layerType"] = "directions"; //Type of feature
      properties["deviceShow"] = device.properties.deviceshow ? 1 : 0; // Use to filter and hide the device marker
      properties["color"] = device.color;
      // properties["invertColor"] = "#fff"; //invert(device.color, true);
      properties["rotate"] = p.direction;
      properties["speed"] = p.speed;
      properties["directionId"] = p.id;

      properties["origin"] = p.wifi ? "wifi" : "gps";
      properties["isSliderViewOpen"] = this.isSliderViewEnable;
      // properties["isSliderData"] = true;

      Object.keys(layerOptions).forEach(key => {
        properties[key] = layerOptions[key];
      });

      let fdirection = this._createFeature(geometry, properties, name);
      this.directionsSource.data.features.push(fdirection);
    });

    // Update datasource to update the map layers
    // Only update datasource if updateMap flag is true
    if (this.map) {
      if (this.filterSource.directions)
        this.DirectionsClusterSource.setData(this.directionsSource);
      else if (this.map.getSource("directionsSource") && updateMap) {
        getSource(this.map, "directionsSource").setData(
          this.directionsSource.data
        );
      }
    }
  }

  DirectionsClusterSource = new ClusterDataSource(this.directionsSource);
  filterDirectionsMarkers(forceReload = false) {
    //_ If showDirections Toggle button is off
    if (!this.showDirections) return;

    if (this.FilterDirectionsVars.filterTimer)
      clearTimeout(this.FilterDirectionsVars.filterTimer);

    this.FilterDirectionsVars.filterTimer = setTimeout(() => {
      const bounds = this.map.getBounds();
      // const size = this.getVariableSizeInMB(this.directionsSource.data);
      const reducedDirectionsSource = this.DirectionsClusterSource.getFeatures(
        bounds,
        this.map.getZoom()
      );
      // const reducedSize = this.getVariableSizeInMB(reducedDirectionsSource);

      //_ Check if is loaded the source AND if features needUpdate AND mouse is not draggin AND if showDirections is true
      const shouldUpdateMapSource =
        this.map.getSource("directionsSource") &&
        reducedDirectionsSource.needUpdate &&
        this.showDirections

      if (shouldUpdateMapSource || forceReload) {
        getSource(this.map, "directionsSource")?.setData({
          features: reducedDirectionsSource.features,
        });
        // console.warn('UPDATING SOURCE MAP ', { old: this.directionsSource.data, oldSize: size + ' MB', reducedDirections: reducedDirectionsSource, reducedSize: reducedSize + ' MB' })
      }
    }, 200);
  }

  getVariableSizeInMB(variable) {
    var jsonString = JSON.stringify(variable);
    var bytes = new Blob([jsonString]).size;
    var megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
  }

  // Improve the render time to only update datasource onece each time has new data
  // Can update only which data source you want to update
  updatingDataSources = null;
  updateDataSources(which = { directions: true, markersAndRoutes: true }) {
    if (!this.map) return;
    //_ Prevent multiple calls to update source
    if (this.updatingDataSources) clearTimeout(this.updatingDataSources);

    this.updatingDataSources = setTimeout(() => {
      if (which.directions) {
        this.DirectionsClusterSource.setData(this.directionsSource);
        this.filterDirectionsMarkers(true);
      }
      if (which.markersAndRoutes) {
        if (this.map.getSource("dataSource")) {
          getSource(this.map, "dataSource").setData(this.filterDataSource());
        }

        if (this.map.getSource("markersSource"))
          getSource(this.map, "markersSource").setData(this.markersSource.data);
      }
    }, 100);
  }

  /***************** CREATE|MOVE ONLINE EFFECT LAYER *********************** */
  async _onlineEffect(device, effect = null, updateMap = false) {
    let id = "marker-" + device.id;
    let img = !effect
      ? new Effects().PulseShadow(
          this.map,
          "#000",
          device.properties.spurfarbe,
          this.performance.use3dMarkers ? 300 : 200
        )
      : effect;
    let index = this.markersSource.data.features
      .map((x) => x.properties.icon)
      .indexOf(id);

    if (index != -1) {
      const effectId = "effect-" + device.id;
      this.markersSource.data.features[index].properties.effect = effectId;
      this.markersSource.data.features[index].properties.online = true;

      if (!this.map.hasImage(effectId)) {
        this.map.addImage(effectId, effect, { pixelRatio: 2 });
        this.markersImages.push({
          name: effectId,
          url: effect,
          isEffect: true,
        });
      }
    }

    if (this.map.getSource("markersSource") && updateMap)
      getSource(this.map, "markersSource").setData(this.markersSource.data);
  }

  async _deleteEffect(device) {
    const id = "marker-" + device.id;
    let index = this.markersSource.data.features
      .map((x) => x.properties.icon)
      .indexOf(id);

    if (index != -1) {
      const effectId = "effect-" + device.id;
      if (this.map.hasImage(effectId)) this.map.removeImage(effectId);

      this.markersImages.splice(
        this.markersImages.map((x) => x.id).indexOf(effectId),
        1
      );
      this.markersSource.data.features[index].online = false;
    }

    if (this.map?.getSource("markersSource"))
      getSource(this.map, "markersSource").setData(this.markersSource.data);
  }

  /************************************************************************************ */

  // The response from api for pauseMarkers are
  // data = End position
  // pause_start = startPosition
  // pause_display = marker position
  // device
  public async _addPauseMarker(
    device,
    deleteDevicePauses = false,
    realtime = false,
    layerOptions = {}
  ) {
    this.pauseMarkersClass?.addPauseMarkers(
      device,
      deleteDevicePauses,
      realtime,
      layerOptions
    );
  }

  // Search feature by propertie Id
  searchFeatureById(datasource, id) {
    return datasource.data.features.find((d) => d.properties.id == id);
  }

  // Search feature by propertie Id
  searchFeaturesByDeviceId(datasource, deviceId, layerName = "route") {
    return datasource.data.features.filter(
      (d) => d.properties.deviceId == deviceId
    );
  }

  removeArrayItem(arr, name) {
    return arr.filter((e) => {
      return e.properties.icon !== name;
    });
  }

  //Marks in divs thats not recorded by recordRTC in canvas
  public async removeMarkers() {
    this.markers.forEach((marker) => {
      marker.remove(this.map);
    });
    this.markers = [];
  }

  public async fitBounds(
    points,
    Xpadding: any = 20,
    Xspeed = 1.2,
    linear = true,
    event = null
  ) {
    points = points.filter(function (el) {
      return el[0] != null && el[1] != null;
    });
    var bounds = points.reduce(function (bounds, coord) {
      return bounds.extend(coord);
    }, new this.LngLatBounds(points[0], points[0]));

    if (points.length > 0) {
      const delay = this.performance.use3dMarkers ? 800 : 0;
      setTimeout(() => {
        this.map.fitBounds(
          bounds,
          { duration: 0, padding: Xpadding },
          //{ padding: Xpadding, speed: Xspeed, linear: linear },
          event
        );
      }, delay);
    }
  }

  public async flyTo(
    x,
    y,
    Xzoom = 10,
    Xspeed = 1,
    pitch = this.pitch,
    bearing = this.bearing
  ) {
    await this.map.flyTo({
      center: [x, y],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      curve: Xzoom,
      speed: Xspeed,
      pitch: pitch,
      bearing: bearing,
      maxDuration: Xspeed,
    });

    return 0;
  }

  public async _flyTo(x, y, zoom) {
    await this.map.flyTo({
      center: [x, y],
      zoom: zoom,
    });
  }

  public async centerTo(x, y, Xzoom = 10, Xspeed = 1) {
    await this.map.flyTo({
      center: [x, y],
      essential: false, // this animation is considered essential with respect to prefers-reduced-motion
      zoom: Xzoom,
      duration: Xspeed,
    });
  }

  public createDataSource(which = "route") {
    let nsource: DataSource = <DataSource>{};
    (nsource as any).data = <Data>{};
    nsource.type = dataSourceType.geojson;
    nsource.data.features = [];
    nsource.data.type = dataTypes.FeatureCollection;
    nsource["generateId"] = true;

    if (which == "markersSource") {
      nsource["cluster"] = this.performance.clusterMarkers; //_ Take care this value is not loaded at startup
      nsource["clusterMaxZoom"] = 12; //_ Max zoom to cluster join features
      nsource["clusterRadius"] = 50; //_ Radius of each cluster when clustering features
    }

    if (which == "directionsSource") {
      nsource["cluster"] = true; //_ Take care this value is not loaded at startup
      nsource["clusterMaxZoom"] = 9; //_ Max zoom to cluster join features (hide direction markers)
      nsource["clusterRadius"] = 25; //_ Radius of each cluster when clustering features
      nsource["buffer"] = 0;
    }

    return nsource;
  }

  public _createFeature(geometry, properties, id = null) {
    let feature = new Feature();
    feature.geometry = geometry;
    feature.properties = properties;

    if (id) feature.id = id;
    return feature;
  }

  public createFeature(
    points,
    iconName,
    title,
    size = 0.25,
    justify = "center",
    data = null,
    alignment = "auto",
    colors = {
      fill: this.primaryColor,
      border: 0,
      "border-color": this.primaryColor,
    },
    geometryType: GeometryTypes = GeometryTypes.Point
  ) {
    let feature = new Feature();
    feature.geometry.type = geometryType;
    feature.geometry.coordinates = points;
    feature.properties.title = title;
    feature.properties.icon = iconName;
    feature.properties["image"] = iconName;
    feature.properties.size = size;
    feature.properties.justify = justify;
    feature.properties.alignment = alignment;
    feature.properties.color = colors.fill;
    feature.properties["border-color"] = colors["border-color"];
    feature.properties["border-width"] = colors.border;
    if (data) feature.properties.data = data;
    return feature;
  }

  public createLayer(id, source) {
    let layer = new Layer(id);
    layer.source = source;

    if (id == "route") {
      layer.type = layerType.line;
      layer.layout["line-join"] = "round";
      layer.layout["line-cap"] = "round";
      layer.paint["line-color"] = ["get", "color"];
      layer.paint["line-width"] = ["get", "size"];
      // [
      //   "case",
      //   ["boolean", ["feature-state", "hover"], false],
      //   8,
      //   ["get", "size"],
      // ];

      layer["filter"] = [
        "all",
        ["==", ['get', "layerType"], "route"], // Select only markers features from the dataSource
        ["==", ['get', "deviceShow"], 1], // Only show if deviceShow == 1
        ["==", ['get', "showPolylines"], true],
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
        ]
      ];

      layer.maxzoom = 20;
      layer.minzoom = 4;
    }

    // To show circles in the last point behind the marker
    if (id == "circles") {
      layer.type = layerType.circle;
      layer.paint["circle-radius"] = 7;
      layer.paint["circle-color"] = ["get", "color"];
      layer.paint["circle-opacity"] = 1.0;
      layer.paint["circle-pitch-alignment"] = "map";

      layer["filter"] = [
        "all",
        ["==", ['get', "layerType"], "marker"], // Select only markers features from the dataSource
        ["==", ['get', "deviceShow"], 1], // Only show if deviceShow == 1
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
        ]
      ];
    }

    if (id == "markers" || id == "effects" || id == "clusterMarkers") {
      layer.type = layerType.symbol;
      layer.layout["icon-image"] = ["get", "image"];
      layer.layout["icon-rotate"] = ["get", "rotate"];
      layer.layout["text-anchor"] = "top";
      layer.layout["icon-size"] = ["get", "size"];
      layer.layout["icon-anchor"] = "bottom";
      layer.layout["text-field"] = ["get", "title"];
      layer.layout["text-offset"] = [0, -6]; //2
      layer.layout["icon-offset"] = ["get", "icon-offset"];
      layer.layout["icon-allow-overlap"] = true;
      layer.layout["text-allow-overlap"] = true;
      layer.layout["icon-rotation-alignment"] = "viewport"; //could be viewport|map
      layer["filter"] = [
        "all",
        ["==", ['get', "layerType"], "marker"], //_ Select only markers features from the dataSource
        ["==", ['get', "deviceShow"], 1], //_ Show only if deviceShow == 1
        // ['==', 'point_count', 0] //_ Only show if doesnt has more clusters points
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
        ]
      ];

      // Show only if the device is online - Online effect
      if (id == "effects") {
        layer.layout["icon-size"] = 1;
        layer.layout["icon-image"] = ["get", "effect"];
        layer.layout["icon-anchor"] = "center"; // center effect image
        layer.layout["icon-rotation-alignment"] = "map";
        layer["filter"] = [
          "all",
          ["==", ['get', "online"], true],
          ["==", ['get', "deviceShow"], 1], // Only show if deviceShow == 1
          ["==", ['get', "showBlinking"], true],
          [
            'any',
            ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
            ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
          ]
        ];

        // layer.maxzoom = 20;
        // layer.minzoom = 4;
      }
    }

    if (
      id == "directions" ||
      id == "scrollDirections" ||
      id == "wifiDirections"
    ) {
      layer.type = layerType.symbol;
      // layer.layout["icon-image"] = ["get", "icon"]; // direction-marker-wifi
      layer.layout["icon-image"] = [
        "case",
        ["<=", ["get", "speed"], 0],
        "direction-marker-wifi",
        "direction-marker",
      ];
      layer.layout["icon-rotate"] = ["get", "rotate"];
      layer["paint"]["icon-color"] = ["get", "color"];
      layer.paint["icon-halo-color"] = "#fff";
      layer.paint["icon-halo-width"] = 1.5;
      layer.layout["icon-size"] = 0.5;

      // This propertie allow to show always all the markers
      layer.layout["icon-allow-overlap"] = this.dragableRouteMarkers;
      layer.layout["icon-rotation-alignment"] = "map"; //['get', 'alignment']; //could be 'viewport' ; used to rotate the icon with the viewport or the map ...
      layer.maxzoom = 20;
      layer.minzoom = 4;

      layer["filter"] = [
        "all",
        ["==", ['get', "layerType"], "directions"], // Select only markers features from the dataSource
        ["==", ['get', "deviceShow"], 1], // Only show if deviceShow == 1
        ["==", ['get', "showDirections"], true],
        ["==", ['get', "origin"], "gps"],
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
        ]
      ];

      if (id == "wifiDirections") {
        layer.layout["icon-image"] = "direction-marker-wifi";
        layer.paint["icon-halo-color"] = "#00aaff";
        layer["filter"][4] = ["==", ["get", "origin"], "wifi"];
        layer.maxzoom = 20;
        layer.minzoom = 4;
      }

      // show on top of all the others features
      if (id == "scrollDirections") {
        layer.paint["icon-halo-color"] = "red";
        layer.layout["icon-allow-overlap"] = true;
        layer.maxzoom = 20;
        layer.minzoom = 4;
      }
    }

    if (id == "alarms" || id == "speedCameras") {
      layer.type = layerType.symbol;
      layer.layout["icon-image"] = ["get", "icon"];
      layer.layout["icon-size"] = ["get", "size"];
      layer.layout["icon-anchor"] = "bottom";
      layer["paint"]["icon-color"] = ["get", "#ff0000"];
      layer.layout["icon-allow-overlap"] = false;
      layer.layout["icon-rotation-alignment"] = "viewport"; //could be 'viewport' ; used to rotate the icon with the viewport or the map ...
      layer.maxzoom = 20;
      layer.minzoom = 4;

      layer["filter"] = [
        "all",
        ["==", "layerType", "alarms"], // Select only markers features from the dataSource
        ["==", "deviceShow", 1], // Show only if deviceShow == 1
        ["==", "showAlarms", true], //_ Flag from user settings
        // ['==', 'isRead', 1],  //_ Flag from user settings show unread/read alarms :: custom condition
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
        ]
      ];

      if (id == "speedCameras") {
        layer.maxzoom = 20;
        layer.minzoom = 9;
        layer.layout["icon-allow-overlap"] = true;
        // layer.layout["icon-ignore-placement"] = true;
        layer["filter"] = [
          "all",
          ["==", ['get', "layerType"], "speedCameras"], // Select only markers features from the dataSource
          ["==", ['get', "show"], true], //_ Flag from user settings
          [
            'any',
            ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
            ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
          ]
        ];
      }
    }

    if (id == "clusterMarkers") {
      layer.layout["icon-size"] = 0.35;
      layer.layout["icon-image"] = "default-device-marker";

      layer["filter"] = ["has", "point_count"];
    }

    if (id == "clusterBgMarkers") {
      layer.type = layerType.symbol;
      layer.layout["icon-image"] = "direction-marker-wifi";
      layer["paint"]["icon-color"] = this.primaryColor;
      layer.paint["icon-translate"] = [-25, -50];
      layer.paint["icon-translate-anchor"] = "viewport";
      layer.layout["icon-allow-overlap"] = true;
      layer.layout["icon-size"] = 1;

      layer["filter"] = ["has", "point_count"];
    }

    if (id == "clusterCountMarkers") {
      layer.type = layerType.symbol;
      layer.paint["text-translate"] = [-25, -50];
      layer.paint["text-color"] = "#ffffff";
      layer.layout["text-field"] = "{point_count_abbreviated}";
      layer.layout["text-size"] = 13;
      layer.layout["text-allow-overlap"] = true;
      layer.paint["text-translate-anchor"] = "viewport";

      layer["filter"] = ["has", "point_count"];
    }

    // To show circles in the last point behind the marker
    if (id == "onlineDot") {
      layer.type = layerType.symbol;
      layer.layout["icon-image"] = "dot-marker";
      layer["paint"]["icon-color"] = "#00ee6e";
      layer.paint["icon-translate"] = [5, -20];
      layer.paint["icon-translate-anchor"] = "viewport";
      layer.layout["icon-allow-overlap"] = true;
      layer.layout["icon-size"] = 0.2;

      layer["filter"] = [
        "all",
        ["==", ['get', "online"], true], // Select only markers features from the dataSource
        // ["==", "enable", !this.performance.blinkEffect], // Select only markers features from the dataSource
        ["==", ['get', "deviceShow"], 1], // Only show if deviceShow == 1
        ["==", ['get', "showBlinking"], false],
        [
          'any',
          ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
          ['==', ['get', 'isSliderData'], true] // OR condition: isSliderDevice == true
        ]
      ];
    }

    return layer;
  }

  public stringToCoordinates(str, precision) {
    let mu = new mapUtil(this.api);
    return mu.stringToCoordinates(str, precision);
  }

  currentZoom = 0;
  FilterDirectionsVars = {
    zoomingTimer: null,
    mouseIsReleased: true,
    mouseUpTimer: null,
    filterTimer: null,
  };
  userHasInteracted = false;
  async onClickMarkers() {
    let tc = this;
    let mapMoved = false;

    // Set cursor for mouse enter and mouse leave for markers layers
    this.map.on("mouseenter", "markers", function () {
      tc.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "markers", function () {
      tc.map.getCanvas().style.cursor = "default";
    });

    this.map.on("mouseleave", "directions", function () {
      tc.map.getCanvas().style.cursor = "default";
    });

    this.map.on("mouseenter", "directions", function () {
      tc.map.getCanvas().style.cursor = tc.dragableRouteMarkers
        ? "move"
        : "pointer";
    });

    this.map.on("mouseleave", "wifiDirections", function () {
      tc.map.getCanvas().style.cursor = "default";
    });

    this.map.on("mouseenter", "wifiDirections", function () {
      tc.map.getCanvas().style.cursor = "pointer";
    });

    //_ Dragable markers
    const dragging = tc.dragMarker.bind(this);
    this.dragonDown = async (e) => {
      // Prevent drag marker when two touches is detected
      if (e.originalEvent?.touches?.length > 1)
        return;

      e.preventDefault();
      let features = tc.map.queryRenderedFeatures(e.point);
      tc.dragingCoordinates = e.lngLat;
      tc.dragingFeature = features.length > 0 ? features[0] : null;
      tc.map.getCanvas().style.cursor = "grab";

      tc.map.on("mousemove", dragging);
      tc.map.on("touchmove", dragging);

      // if (window.innerWidth > 400) {
      tc.map.once("mouseup", (e) => {
        if (!tc.dragingFeature) return;
        const coors = e.lngLat;
        tc.map.getCanvas().style.cursor = "default";
        tc.map.off("touchmove", dragging);
        tc.map.off("mousemove", dragging);
        if (
          coors.lat != tc.dragingCoordinates.lat ||
          coors.lng != tc.dragingCoordinates.lng
        )
          tc.onMarkerMoved.emit({
            feature: tc.dragingFeature,
            coordinates: e.lngLat,
            e,
          });

        tc.dragingFeature = null;
      });
      // } else {
      tc.map.once("touchend", (e) => {
        if (!tc.dragingFeature) return;
        const coors = e.lngLat;
        tc.map.off("mousemove", dragging);
        tc.map.off("touchmove", dragging);
        if (
          coors.lat != tc.dragingCoordinates.lat ||
          coors.lng != tc.dragingCoordinates.lng
        )
          tc.onMarkerMoved.emit({
            feature: tc.dragingFeature,
            coordinates: e.lngLat,
            e,
          });
        tc.dragingFeature = null;
      });
      // }
    };

    if (this.dragableRouteMarkers == true) {
      this.map.on("mousedown", "directions", this.dragonDown);
      this.map.on("touchstart", "directions", this.dragonDown);
    }

    this.map.on("mouseenter", "directions", function () {
      tc.map.getCanvas().style.cursor = this.dragableRouteMarkers
        ? "move"
        : "pointer";
    });

    this.map.on("mouseenter", "scrollDirections", function () {
      tc.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", "scrollDirections", function () {
      tc.map.getCanvas().style.cursor = "default";
    });

    /***************************************************************** */
    // Configure MAP click triggers
    // When click over a feature, send the feature depends if is marker, direction or pause
    this.map.on("click", function (e) {
      if (this.clickedOrTaped) return;
      this.clickedOrTaped = true;
      setTimeout(() => (this.clickedOrTaped = false), 260);

      let features = tc.map.queryRenderedFeatures(e.point, { layers: ['markers', 'pauses', 'alarms', 'scrollDirections', 'directions', 'wifiDirections', 'symbolsLayer']});
      console.log('CLICK EVENT', features);
      if (features.length > 0) {
        if (
          features[0].layer.id == "markers" ||
          features[0].layer.id == "directions" ||
          features[0].layer.id == "pauses" ||
          features[0].layer.id == "scrollDirections" ||
          features[0].layer.id == "alarms" ||
          features[0].layer.id == "wifiDirections" ||
          features[0].layer.id == "symbolsLayer"
        ) {
          tc.onClickFeature.emit({
            e: e,
            feature: features[0],
            features: features,
            screenBounds: tc.getBoundingMarker(features[0]),
          });
        } else tc.onClickMap.emit(e);
      } else tc.onClickMap.emit(e);
    });

    // For mobile devices touch event
    this.map.on("touchend", function (e) {
      //_ ignore tap event if was fired before by click event
      if (this.clickedOrTaped) return;
      this.clickedOrTaped = true;
      setTimeout(() => (this.clickedOrTaped = false), 260);
       console.log('TOUCH EVENT', e);
       let features = tc.map.queryRenderedFeatures(e.point, { layers: ['markers', 'pauses', 'alarms', 'scrollDirections', 'directions', 'wifiDirections', 'symbolsLayer']});
       if (features.length > 0 && !mapMoved) {
         if (
           features[0].layer.id == "markers" ||
           features[0].layer.id == "directions" ||
           features[0].layer.id == "pauses" ||
           features[0].layer.id == "scrollDirections" ||
           features[0].layer.id == "alarms" ||
           features[0].layer.id == "symbolsLayer"
         ) {
           tc.onClickFeature.emit({
             e: e,
             feature: features[0],
             features: features,
             screenBounds: tc.getBoundingMarker(features[0]),
           });
         } else if (!mapMoved) tc.onClickMap.emit(e);
       } else if (!mapMoved) tc.onClickMap.emit(e);
       mapMoved = false;
    });

    //_ Handler output for drag/pan map
    this.map.on("drag", (e) => {
      mapMoved = true;
      // tc.FilterDirectionsVars.mouseIsReleased = false;
      tc.onDragMap.emit(e);
    });

    //_ TRIGGERS TO FILTER DIRECTIONS MARKERS AND LOAD ON THE MAP
    if (this.filterSource.directions) {
      this.map.on("idle", (e) => {
        if (tc.userHasInteracted) {
          tc.filterDirectionsMarkers();
          tc.userHasInteracted = false;
        }
      });

      this.map.on("dragstart", () => {
        if (tc.FilterDirectionsVars.filterTimer)
          clearTimeout(tc.FilterDirectionsVars.filterTimer);
        tc.userHasInteracted = true;
      });

      //_ Add event listener for map zoom AND only trigger filterDirections when map zoom out
      this.map.on("zoom", (e) => {
        // console.log('ZOOMING', this.map.getZoom());
        tc.mapZooming = true;
        // console.log('ZOOM level', this.map.getZoom())
        if (tc.FilterDirectionsVars.filterTimer)
          clearTimeout(tc.FilterDirectionsVars.filterTimer);
        tc.userHasInteracted = true;

        setTimeout(() => tc.mapZooming = false, 2000);
      });
    }

    //_____________________ END TRIGGERS TO FILTER DIRECTIONS ______________________________________
  }

  //_ Dragable marker Methods
  dragingFeature = null;
  dragingCoordinates = null;
  dragonDown = null; //_ Method to start dragin on mouse down
  dragMarker(e) {
    if (this.dragingFeature && this.dragableRouteMarkers) {
      let features = this.map.queryRenderedFeatures(e.point);
      const coords = e.lngLat;

      this.map.getCanvas().style.cursor = "grabbing";

      // Update the Point feature in `geojson` coordinates
      this.directionsSource.data.features.forEach((f) => {
        if (
          f.properties["directionId"] ==
          this.dragingFeature.properties.directionId
        )
          f.geometry.coordinates = [coords.lng, coords.lat];
      });

      getSource(this.map, "directionsSource").setData(
        this.directionsSource.data
      );
    }
  }

  disableDragableDirections() {
    if (this.map) {
      this.map.off("mousedown", "directions", this.dragonDown);
      this.map.off("touchstart", "directions", this.dragonDown);
      this.dragableRouteMarkers = false;
    }
  }

  enableDragableDirections() {
    if (this.map) {
      this.map.on("mousedown", "directions", this.dragonDown);
      this.map.on("touchstart", "directions", this.dragonDown);
      this.dragableRouteMarkers = true;
    }
  }

  setShowPropertie(device, show) {
    // Update markers
    this.markersSource.data.features.forEach((f) => {
      if (f.properties["deviceId"] == device.id)
        f.properties["deviceShow"] = show ? 1 : 0;
      if (this.performance.use3dMarkers) {
        this.threedHelper.showHide(device.id, show);
      }
    });

    // Update route features
    this.dataSource.data.features.forEach((f) => {
      if (f.properties["deviceId"] == device.id)
        f.properties["deviceShow"] = show ? 1 : 0;
    });

    // Update directions features
    this.directionsSource.data.features.forEach((f) => {
      if (f.properties["deviceId"] == device.id)
        f.properties["deviceShow"] = show ? 1 : 0;
    });

    // Update Alarms features
    // this.alarmMarkersClass.updateDeviceData(device, show ? 1 : 0);

    if (this.map.getSource("markersSource"))
      getSource(this.map, "markersSource").setData(this.markersSource.data);

    if (this.map.getSource("dataSource"))
      getSource(this.map, "dataSource").setData(this.filterDataSource());

    if (this.map.getSource("directionsSource")) {
      if (this.filterSource.directions) {
        this.DirectionsClusterSource.setData(this.directionsSource);
        this.filterDirectionsMarkers();
      } else
        getSource(this.map, "directionsSource").setData(
          this.directionsSource.data
        );
    }

    this.pauseMarkersClass?.updateDeviceData(device, show ? 1 : 0);
  }

  // Set the style parsing a key of urlStyles Interface
  // osm, paj, pajHills, 3d = style
  async setMapStyle(style, dimension, event) {
    const savedPitch = localStorage.getItem('savedPitch');
    if (event) {
      event.stopPropagation();
    }
    this.dimension = dimension;
    this.setStyle = true; // Flag to only update one time with styledata event
    this.mapStyle = style;
    if (dimension == "3d" && style == "pajLite") {
      this.mapStyle = "paj";
    }
    this.map.setStyle(await this.createMapStyle(this.mapStyle));

    if (dimension == "3d") {
      this.map["dragRotate"].enable();
      this.map["touchPitch"].enable();
      this.map["touchZoomRotate"].enable();
      //this.map.easeTo({ pitch: 70, bearing: this.map?.getBearing() });
      this.map.easeTo({ pitch: (+savedPitch) === 0 ? 70 : +savedPitch , bearing: this.map?.getBearing() });
    } else {
      this.map["dragRotate"].disable();
      //_ Search how to do this in maplibre
      // this.map["dragRotate"]._mousePitch._enabled = true;
      this.map.easeTo({ pitch: 0, bearing: 0 });
    }
  }

  resetBearing(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.platform.width() > 678) {
      this.map.easeTo({ bearing: -0 });
      this.currentBearing = -0;
    } else {
      switch (this.currentBearing) {
        case -0:
          this.currentBearing = -90;
          break;
        case -90:
          this.currentBearing = -180;
          break;
        case -180:
          this.currentBearing = -270;
          break;
        case -270:
          this.currentBearing = -360;
          break;
        case -360:
          this.currentBearing = -0;
          break;

        default:
          this.currentBearing = -0;
          break;
      }
      // this.currentBearing == -0 ? this.currentBearing = -90 : this.currentBearing = -0;

      this.map.easeTo({ bearing: this.currentBearing });
    }
  }

  preventDefault(event) {
    if (window.innerWidth > 678) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.resetBearing(event);
    }
  }

  preventDefaultSColorGradient(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  async setBearing(rad, event) {
    event.stopPropagation();
    this.currentBearing += rad;
    //  this.map.easeTo({this.currentBearing:0});
    this.map.easeTo({ bearing: this.currentBearing });
  }

  // Activate / Dissable directions markers
  showDirections = true;
  showWifiMarkers = true;
  toggleDirectionMarkers(event = null, type = "gps", showToast = true) {
    if (event) {
      event.stopPropagation();
      this.fab.activated = true;
    }
    if (type == "gps") {
      this.showDirections = !this.showDirections;
      if (this.showDirections == true && showToast) {
        this.appService.showToast(
          "",
          this._translate.instant("fabbuttonToast.routeDirectionOn"),
          2000,
          "success"
        );

        //_ Update directions source data or filter and update the map
        if (this.filterSource.directions) {
          this.DirectionsClusterSource.forceUpdate = true;
          this.filterDirectionsMarkers();
        } else if (this.map.getSource("directionsSource"))
          getSource(this.map, "directionsSource").setData(
            this.directionsSource.data
          );
        } else if (this.showDirections == false && showToast) {
        this.appService.showToast(
          "",
          this._translate.instant("fabbuttonToast.routeDirectionOff"),
          2000,
          "success"
        );

        //_ REMOVE THE SOURCE FROM MAPBOX
        getSource(this.map, "directionsSource").setData({ features: [] });
      }
    } else {
      this.showWifiMarkers = !this.showWifiMarkers;
    }

    //_ For Debugin performance using thousonds waypoints ...
    // this.directionsSource.data.features = this.directionsSource.data.features.slice(0, 5000)
    this.directionsSource.data.features.forEach((f) => {
      if (f.properties["origin"] == type)
        f.properties["showDirections"] =
          type == "gps" ? this.showDirections : this.showWifiMarkers;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      if (f.properties["origin"] == type)
        f.properties["showDirections"] =
          type == "gps" ? this.showDirections : this.showWifiMarkers;
    });

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource("directionsSource")) {
        if (this.filterSource.directions) this.filterDirectionsMarkers();
        else
          getSource(this.map, "directionsSource").setData(
            this.directionsSource.data
          );
      }

      if (this.map.getSource("scrollDirectionsSource"))
        getSource(this.map, "scrollDirectionsSource").setData(
          this.scrollDirectionsSource.data
        );
    }
  }

  hideDirectionMarkers(type) {
    if (type == "gps") {
      this.showDirections = false;
    } else {
      this.showWifiMarkers = false;
    }

    //_ For Debugin performance using thousonds waypoints ...
    // this.directionsSource.data.features = this.directionsSource.data.features.slice(0, 5000)
    this.directionsSource.data.features.forEach((f) => {
      if (f.properties["origin"] == type)
        f.properties["showDirections"] =
          type == "gps" ? this.showDirections : this.showWifiMarkers;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      if (f.properties["origin"] == type)
        f.properties["showDirections"] =
          type == "gps" ? this.showDirections : this.showWifiMarkers;
    });

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource("directionsSource")) {
        if (this.filterSource.directions)
          this.DirectionsClusterSource.setData(this.directionsSource);
        else
          getSource(this.map, "directionsSource").setData(
            this.directionsSource.data
          );
      }

      if (this.map.getSource("scrollDirectionsSource"))
        getSource(this.map, "scrollDirectionsSource").setData(
          this.scrollDirectionsSource.data
        );
    }
  }

  async showDirectionMarkersIfEnabled(type) {
    if (type == "gps") {
      let value = await this.storage.get("showDirectionMarkers");
      this.showDirections = value ? value : false;
    } else {
      let value = await this.storage.get("showWifiMarkers");
      this.showWifiMarkers = value ? value : false;
    }

    //_ For Debugin performance using thousonds waypoints ...
    // this.directionsSource.data.features = this.directionsSource.data.features.slice(0, 5000)
    this.directionsSource.data.features.forEach((f) => {
      if (f.properties["origin"] == type)
        f.properties["showDirections"] =
          type == "gps" ? this.showDirections : this.showWifiMarkers;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      if (f.properties["origin"] == type)
        f.properties["showDirections"] =
          type == "gps" ? this.showDirections : this.showWifiMarkers;
    });

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource("directionsSource")) {
        if (this.filterSource.directions)
          this.DirectionsClusterSource.setData(this.directionsSource);
        else
          getSource(this.map, "directionsSource").setData(
            this.directionsSource.data
          );
      }

      if (this.map.getSource("scrollDirectionsSource"))
        getSource(this.map, "scrollDirectionsSource").setData(
          this.scrollDirectionsSource.data
        );
    }
  }

  async toggleHeatMap(event) {
    event.stopPropagation();
    // this.isHeatMapMode = !this.isHeatMapMode;
    // await this.storage.set("heatMapMode", this.isHeatMapMode);
    // if (this.isHeatMapMode) {
    //   this.appService.showToast(
    //     "",
    //     this._translate.instant("fabbuttonToast.heatMapOn"),
    //     2000,
    //     "success"
    //   );
    //   await this.setMapStyle(this.mapStyle, this.dimension, null);
    //   this.addHeatMapLayer();
    //   this.hideAlarms();
    //   this.hidePauses();
    //   this.hidePolylines();
    //   this.hideDirectionMarkers("gps");
    //   this.speedCamerasClass.hideCameras();
    // } else {
    //   this.appService.showToast(
    //     "",
    //     this._translate.instant("fabbuttonToast.heatMapOff"),
    //     2000,
    //     "success"
    //   );
    //   this.setMapStyle(this.mapStyle, this.dimension, null);
    //   this.removeHeatMapLayer();
    //   this.showAlarmsIfEnabled();
    //   this.showPausesIfEnabled();
    //   this.showPolylinesIfEnabled();
    //   this.showDirectionMarkersIfEnabled("gps");
    //   let value = await this.storage.get("showSpeedCameras");
    //   this.speedCamerasClass.showCamerasIfEnabled(value ? value : false);
    // }
  }

  async hidePolylines() {
    this.showPolylines = false;
    this.dataSource.data.features.forEach((f) => {
      f.properties["showPolylines"] = this.showPolylines;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      f.properties["showPolylines"] = this.showPolylines;
    });

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource("dataSource"))
        getSource(this.map, "dataSource").setData(this.filterDataSource());

      if (this.map.getSource("scrollDirectionsSource"))
        getSource(this.map, "scrollDirectionsSource").setData(
          this.scrollDirectionsSource.data
        );
    }
  }

  async showPolylinesIfEnabled() {
    let value = await this.storage.get("showPolylines");
    this.showPolylines = value ? value : false;
    this.dataSource.data.features.forEach((f) => {
      f.properties["showPolylines"] = this.showPolylines;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      f.properties["showPolylines"] = this.showPolylines;
    });

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource("dataSource"))
        getSource(this.map, "dataSource").setData(this.filterDataSource());

      if (this.map.getSource("scrollDirectionsSource"))
        getSource(this.map, "scrollDirectionsSource").setData(
          this.scrollDirectionsSource.data
        );
    }
  }

  showPolylines = true;
  async togglePolylines(event, showToast = true) {
    if (event) event.stopPropagation();
    this.showPolylines = !this.showPolylines;
    if (this.showPolylines == true && showToast) {
      this.appService.showToast(
        "",
        this._translate.instant("fabbuttonToast.polyLinesOn"),
        2000,
        "success"
      );
    } else if (this.showPolylines == false && showToast) {
      this.appService.showToast(
        "",
        this._translate.instant("fabbuttonToast.polyLinesOff"),
        2000,
        "success"
      );
    }

    this.dataSource.data.features.forEach((f) => {
      f.properties["showPolylines"] = this.showPolylines;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      f.properties["showPolylines"] = this.showPolylines;
    });

    // And then update the source in the map :)
    if (this.map) {
      if (this.map.getSource("dataSource")) {
        getSource(this.map, "dataSource").setData(this.filterDataSource());
      }

      if (this.map.getSource("scrollDirectionsSource"))
        getSource(this.map, "scrollDirectionsSource").setData(
          this.scrollDirectionsSource.data
        );
    }
  }

  // addHeatMapLayer() {
  //   this.mapType = "heatmap";
  //   if (!this.map.getSource("heatMapSource"))
  //     this.map.addSource("heatMapSource", this.dataSource);

  //   if (this.map.getLayer("heatmapPaj-point")) {
  //     this.map.removeLayer("heatmapPaj-point");
  //   }
  //   if (this.map.getLayer("heatmapPaj-heat")) {
  //     this.map.removeLayer("heatmapPaj-heat");
  //   }
  //   this.map.addLayer({
  //     id: "heatmapPaj-heat",
  //     type: "heatmap",
  //     source: "heatMapSource",
  //     maxzoom: 20,
  //     paint: {
  //       "heatmap-color": [
  //         "interpolate",
  //         ["linear"],
  //         ["heatmap-density"],
  //         0,
  //         "rgba(33,102,172,0)",
  //         0.2,
  //         "rgba(0, 255, 0, 0.7)",
  //         0.4,
  //         "rgba(0, 255, 0, 0.7)",
  //         0.6,
  //         "rgba(0, 255, 0, 0.7)",
  //         0.8,
  //         "rgba(0, 255, 0, 0.7)",
  //         1,
  //         "rgba(255, 0, 0, 0.7)",
  //       ],
  //       // Adjust the heatmap radius by zoom level
  //       "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 19, 20],
  //       // Transition from heatmap to circle layer by zoom level
  //       "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 19, 0.5],
  //     },
  //   });

  //   this.map.addLayer({
  //     id: "heatmapPaj-point",
  //     type: "circle",
  //     source: "heatMapSource",
  //     minzoom: 20,
  //     paint: {
  //       // Size circle radius by earthquake magnitude and zoom level
  //       "circle-radius": [
  //         "interpolate",
  //         ["linear"],
  //         ["zoom"],
  //         7,
  //         // ['interpolate', ['linear'], ['get', 'mag'], 1, 1, 6, 4],
  //         16,
  //         // ['interpolate', ['linear'], ['get', 'mag'], 1, 5, 6, 50]
  //       ],
  //       // Color circle by earthquake magnitude
  //       "circle-color": [
  //         "interpolate",
  //         ["linear"],
  //         ["get", "mag"],
  //         1,
  //         "rgba(33,102,172,0)",
  //         2,
  //         "rgb(103,169,207)",
  //         3,
  //         "rgb(209,229,240)",
  //         4,
  //         "rgb(253,219,199)",
  //         5,
  //         "rgb(239,138,98)",
  //         6,
  //         "rgb(178,24,43)",
  //       ],
  //       "circle-stroke-color": "white",
  //       "circle-stroke-width": 1,
  //       // Transition from heatmap to circle layer by zoom level
  //       "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1],
  //     },
  //   });
  // }

  // removeHeatMapLayer() {
  //   this.mapType = "";
  //   if (this.map.getLayer("heatmapPaj-point")) {
  //     this.map.removeLayer("heatmapPaj-point");
  //   }
  //   if (this.map.getLayer("heatmapPaj-heat")) {
  //     this.map.removeLayer("heatmapPaj-heat");
  //   }
  //   if (this.map.getSource("heatMapSource")) {
  //     this.map.removeSource("heatMapSource");
  //   }
  // }

  changeDeviceMarkerSize(iconSize) {
    this.markersSource.data.features.forEach((f) => {
      if (f.properties.icon)
        if (f.properties.icon.startsWith("marker-")) {
          f.properties.size = (iconSize.width * 100) / 170 / 100;
        }
    });

    // And then update the source in the map :)
    if (this.map.getSource("markersSource"))
      getSource(this.map, "markersSource").setData(this.markersSource.data);
  }

  thicknessChanged(size) {
    this.dataSource.data.features.forEach((f) => {
      if (f.properties.layerType == "route") f.properties.size = size;
    });

    // And then update the source in the map :)
    if (this.map.getSource("dataSource"))
      getSource(this.map, "dataSource").setData(this.filterDataSource());
  }

  // Change direction marker icon
  async changeDirectionIcon(icon) {
    // First delete the previous image
    this.map.removeImage("direction-marker");

    // Load the new image
    await this.map.loadImage(directionMarker[icon], (error, image) => {
      if (!error) this.map.addImage("direction-marker", image, { sdf: true });
    });
  }

  infoVisible = true;
  fixMapInfo() {
    setTimeout(() => {
      // const attributionContainer: any = document.querySelector('.maplibregl-ctrl-bottom-right');
      const prefix = 'mapboxgl'; //_ maplibregl
      const attributionContainer: any = document.querySelector('.' + prefix + '-ctrl-bottom-right');
      if (!attributionContainer) return;

      const attributionDiv: any = document.querySelector('.' + prefix + '-ctrl-attrib-inner');

      if (!attributionDiv) return;

      const createLink = (link, text) => {
        const aTag = document.createElement('a');
        aTag.href = link;
        aTag.target = '_blank';
        aTag.textContent = text;
        return aTag;
      }

      const link1 = createLink('https://www.maptiler.com/license/maps/', '© MapTiler');
      const link2 = createLink('https://www.openstreetmap.org/copyright', '© OpenStreetMap');

      // Append the links to the attribution div
      attributionDiv.innerHTML = '';
      attributionDiv.appendChild(link1);
      attributionDiv.appendChild(link2);
      this.rendered.setStyle(attributionContainer, 'opacity', 1)

    }, 1000);

  }

  //_ Update route color, direction marker color
  //_ And the device marker
  async updateDevice(device) {
    let imageName = getDeviceImageName(device);
    this.imageService
      .getImage(device.properties)
      .subscribe(async (img: any) => {
        if (img != this.mediaApi.whitePicture) {
          if (img.endsWith(".svg"))
            //_ Change aspect ratio if is a paj_iconset svg image
            img = await resizeImage(img, 122, 170, "white", false);
          else img = await resizeImage(img, 170, 170, "white", false, true);

          const imageLoaded = await this.loadImage(device, imageName, img);
          this.markersSource.data.features.forEach((f) => {
            if (f.properties["deviceId"] == device.id)
              f.properties.color = device.color;
            //_ Change 3dmodel Color and model if is different
            if (this.performance.use3dMarkers) {
              this.threedHelper.updateModel(
                device.id,
                device.color,
                device.properties.threedModel_name
              );
            }
            if (
              f.properties["deviceId"] == device.id &&
              f.properties["layerType"] == "marker" &&
              imageLoaded
            ) {
              f.properties["image"] = imageName;
            }
          });

          this.dataSource.data.features.forEach((f) => {
            if (f.properties["deviceId"] == device.id)
              f.properties.color = device.color;
          });

          // And then update the source in the map :)
          getSource(this.map, "markersSource").setData(this.markersSource.data);
          getSource(this.map, "dataSource").setData(this.filterDataSource());
        }
      }); // getDeviceImage(device, this.user.id);

    this.directionsSource.data.features.forEach((f) => {
      if (f.properties["deviceId"] == device.id)
        f.properties.color = device.color;
    });

    //_ Update pulse effect marker image
    const onlineId = "effect-" + device.id;
    if (this.map.hasImage(onlineId)) {
      const newEffectImage = new Effects().PulseShadow(
        this.map,
        "#000",
        device.color,
        200
      );

      //_ Remove online marker image from map and list
      this.map.removeImage(onlineId);
      this.markersImages = this.markersImages.filter(
        (im) => im.name != onlineId
      );
      //_ Add new online marker image to the map and list
      this.map.addImage(onlineId, newEffectImage, { pixelRatio: 2 });
      this.markersImages.push({
        name: onlineId,
        url: newEffectImage,
        isEffect: true,
      });
    }

    // And then update the source in the map :)
    if (this.filterSource.directions)
      this.DirectionsClusterSource.setData(this.directionsSource);
    else
      getSource(this.map, "directionsSource").setData(
        this.directionsSource.data
      );
    this.deviceNameMarkers?.updateName(device);
  }

  showHidePauseMarker(show) {
    this.pauseMarkersClass?.showHidePauseMarker(show);
  }

  _addNotifications(device, removeAll = false) {
    this.alarmMarkersClass?._addAlarms(device, removeAll);
  }

  showHideAlarms(show) {
    this.alarmMarkersClass?.showHideAlarms(show);
  }

  async hideAlarms() {
    this.alarmMarkersClass?.hideAlarms();
  }

  async showAlarmsIfEnabled() {
    this.alarmMarkersClass?.showAlarmsIfEnabled();
  }

  async toggleAlarms(ev = null, showToast = true) {
    if (ev) {
      ev.stopPropagation();
    }
    this.alarmMarkersClass?.toggleAlarms(ev, showToast);
  }

  markersMenu = false;
  showMarkersMenu(ev = null) {
    if (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      ev.srcEvent?.stopPropagation();
    }

    this.markersMenu = !this.FabSubmenu.activated;
    this.moveSubFabMenu();
  }

  moveSubFabMenu() {
  }

  async hidePauses() {
    this.pauseMarkersClass?.hidePauses();
  }

  async showPausesIfEnabled() {
    this.pauseMarkersClass?.showPausesIfEnabled();
  }

  togglePauses(ev, showToast = true) {
    this.pauseMarkersClass?.togglePauses(ev, showToast);
  }

  clearAllData() {
    if (this.map) {
      this.directionsSource = this.createDataSource("markersSource");
      this.directionsSource = this.createDataSource("directionsSource");
      this.dataSource = this.createDataSource("dataSource");
      // this.alarmsSource = this.createDataSource("alarmsSource");
      this.markersSource = this.createDataSource("markersSource");
      this.scrollDirectionsSource = this.createDataSource(
        "scrollDirectionsSource"
      );

      try {
        getSource(this.map, "markersSource").setData(this.markersSource.data);
        getSource(this.map, "directionsSource").setData({
          ...this.directionsSource.data,
          features: [],
        });
        getSource(this.map, "dataSource").setData({
          ...this.dataSource.data,
          features: [],
        });
        // getSource(this.map, "alarmsSource").setData(this.alarmsSource.data);
        getSource(this.map, "scrollDirectionsSource").setData(
          this.scrollDirectionsSource.data
        );
        this.HighSpeedPolylinesClass?.clearData();
      } catch {}
    }
  }

  async startRouteAnimation() {
    let animationspeed = 10;
    // var features = this.map.getSource('dataSource').data.features[0];
    var data = this.dataSource.data;
    if (this.map.getSource("routeDemo"))
      getSource(this.map, "routeDemo").setData(data);
    const coordinates = data.features[0].geometry.coordinates.reverse();
    if (coordinates.length <= 500) {
      animationspeed = 10;
    } else if (coordinates.length > 501) {
      animationspeed = 0.2;
    } else if (coordinates.length > 2000) {
      animationspeed = 0.1;
    }

    // start by showing just the first coordinate
    data.features[0].geometry.coordinates = [coordinates[0]];

    // on a regular basis, add more coordinates from the saved list and update the map
    let i = 0;
    this.timer = setInterval(() => {
      if (i < coordinates.length) {
        data.features[0].geometry.coordinates.push(coordinates[i]);

        getSource(this.map, "dataSource").setData(data);
        // this.map.panTo(coordinates[i]);
        i++;
      } else {
        window.clearInterval(this.timer);
      }
    }, animationspeed);
  }

  createAnimationLayers() {
    this.map.addLayer({
      id: "trace",
      type: "line",
      source: "dataSource",
      paint: {
        "line-color": "#000000",
        "line-opacity": 1,
        "line-width": 5,
      },
    });
    this.map.addSource("routeDemo", {
      type: "geojson",
      data: null,
    });

    this.map.addLayer({
      id: "route-demo",
      type: "line",
      source: "routeDemo",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#a6a6a6",
        "line-opacity": 1,
        "line-width": 5,
      },
    });
  }

  hasMarker(deviceId) {
    let feature = false;
    this.markersSource.data.features.forEach((f) => {
      if (
        f.properties["deviceId"] == deviceId &&
        f.properties["layerType"] == "marker"
      ) {
        feature = f;
        return;
      }
    });
    return feature;
  }

  //_ Performance methods
  clusterize(isEnable, from = "obs") {
    this.markersSource.data.cluster = isEnable;
    if (this.map) {
      if (this.map.getSource("markersSource")) {
        const style = this.map.getStyle();
        style.sources["markersSource"]["cluster"] = isEnable;
        this.map.setStyle(style);
      }
    }
  }

  setBlinkingEffect(show) {
    if (this.map) {
      if (this.map.getSource("markersSource")) {
        this.markersSource.data.features.forEach((f) => {
          f.properties.showBlinking = !show;
        });
      }

      if (this.map.getSource("markersSource"))
        getSource(this.map, "markersSource").setData(this.markersSource.data);
    }
  }

  async updateSettings(settings) {
    await this.authService
      .updateCustommerSettings(settings)
      .then((res) => {})
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.map) this.map.resize();
  }

  mapboxControlsTranslation() {
    const setTitle = (elClass, title) => {
      const el: any = document.querySelector(elClass);
      if (el) el.title = title;
    };

    setTimeout(() => {
      setTitle(
        ".maplibregl-ctrl-zoom-in",
        this._translate.instant("mapBoxControls.zoomIn")
      );
      setTitle(
        ".maplibregl-ctrl-zoom-out",
        this._translate.instant("mapBoxControls.zoomOut")
      );
    }, 1000);
  }

  getBoundingMarker(feature) {
    // const featureData = this.searchFeatureById(this.markersSource, feature.properties.id);
    const featureIndex = this.markersSource.data.features
      .map((x: any) => x.properties["deviceId"])
      .indexOf(feature.properties.deviceId);
    const featureData = this.markersSource.data.features[featureIndex];
    if (!featureData) return null;

    const coors = featureData.geometry.coordinates;
    const markerSize = 200;

    const centerPixel = this.map.project(new this.LngLat(coors[0], coors[1]));
    if (centerPixel)
      return {
        top: centerPixel.y,
        left: centerPixel.x,
        height: markerSize * feature.properties.size,
        width: markerSize * feature.properties.size,
      };
    else return null;
  }

  toggleSpeedCameras(ev) {
    if (ev) ev.stopPropagation();
    this.speedCamerasClass.toggleFeature(true);
  }

  toggleClientLocation(ev) {
    if (ev) ev.stopPropagation();
    this.clientLocationClass?.toggleFeature();
  }

  //_ Add new listener for the hooks|events
  public on(eventName: AvailableHooks, fn: (event) => void) {
    return this.HOOKS.on(eventName, fn);
  }

  //_ Remove listener for the hooks|events
  public off(eventId) {
    return this.HOOKS.off(eventId);
  }

  async showInfoPopup (event, title, description, image, useGif) {

    let popup = await this.popoverController.create({
      componentProps: {
        title: this._translate.instant(title),
        description: description ? this._translate.instant(description) : null,
        image, useGif
      },
      component: CsPopoverComponent,
      event: event,
      mode: "ios",
      translucent: false,
    });

    await popup.present();
    popup = null;
  }

  graphhopperLegendAction(event) {
    event.stopPropagation();
    this.graphhopperLegendIsOpen = true;
  }

  mapMonitorLegendAction(event) {
    event.stopPropagation();
    this.mapMonitorLegendIsOpen = true;
  }

  @HostListener('document:click', ["$event"])
  closeOpenedLegends() {
    if (this.graphhopperLegendIsOpen || this.mapMonitorLegendIsOpen) {
      if (this.graphHopperLegendRef)
        this.graphhopperLegendIsOpen = false;
      if (this.mapMonitorLegendRef)
        this.mapMonitorLegendIsOpen = false;
    }
  }

  //_ Method used to filter all source features isSliderViewOpen
  enableSliderView () {
    this.setSliderView(true);
  }

  disableSliderView () {
    this.setSliderView(false);
  }

  setSliderView (isEnable) {
    this.isSliderViewEnable = isEnable;
    //_ FIRST OF ALL - Propagate the sliderStatus change for feature classes
    this.HOOKS.onSetSliderView.emit({ isEnable });

    //_ FIRST: FILTER ALL CURRENT SOURCES
    this.markersSource.data.features.forEach((f) => {
      f.properties["isSliderViewOpen"] = isEnable;
    });
    this.dataSource.data.features.forEach((f) => {
      f.properties["isSliderViewOpen"] = isEnable;
    });

    this.directionsSource.data.features.forEach((f) => {
      f.properties["isSliderViewOpen"] = isEnable;
    });

    this.scrollDirectionsSource.data.features.forEach((f) => {
      f.properties["isSliderViewOpen"] = isEnable;
    });

    //_ If slider view is close; needs to delete/filter sliderData features
    //_ -- IMPORTANT -- check also if is only slider data other wise should not be removed
    if (!isEnable) {
      this.markersSource.data.features = this.filterFeaturesWithoutSliderProps(this.markersSource.data.features);
      this.markersSource.data.features = this.cleanSliderFeatureProps(this.markersSource.data.features);

      this.dataSource.data.features = this.filterFeaturesWithoutSliderProps(this.dataSource.data.features);
      this.dataSource.data.features = this.cleanSliderFeatureProps(this.dataSource.data.features);

      this.directionsSource.data.features = this.filterFeaturesWithoutSliderProps(this.directionsSource.data.features);
      this.directionsSource.data.features = this.cleanSliderFeatureProps(this.directionsSource.data.features);

      this.scrollDirectionsSource.data.features = this.filterFeaturesWithoutSliderProps(this.scrollDirectionsSource.data.features);
      this.scrollDirectionsSource.data.features = this.cleanSliderFeatureProps(this.scrollDirectionsSource.data.features);
    }

    //_ SECOND: UPDATE MAP DATA SOURCES
    this.updateDataSources({ directions: true, markersAndRoutes: true });
  }

  filterFeaturesWithoutSliderProps (feature) {
    return feature.filter((f) => !f.properties["isSliderData"] || !f.properties['isOnlySliderData']);
  }

  //_ Remove the extra properties to clean the feature properties with slider props
  cleanSliderFeatureProps (features) {
    return features.map((feature) => {
      if (feature.properties['isSliderData'])
        delete feature.properties['isSliderData'];
      if (feature.properties['isOnlySliderData'])
        delete feature.properties['isOnlySliderData'];
      return feature;
    });
  }

  //_ Apply atmosphere to the map style
  //_ Using MapStyleList and if is projection globe or dimension 3d
  applyAtmosphere() {
    if (!this.useMapbox) return;

    if (this.userSettings.map_projection === 'globe') {
      let atmosphere = MapStylesList[this.mapStyle]?.atmosphere ?? MapStylesList.paj.atmosphere;

      if (this.isDarkmode) atmosphere = DARK_ATMOSPHERE;
      this.map?.setFog(atmosphere);
    } else {
      //_ Fix for background color of mapbox_standard style
      if (this.mapStyle === 'mapbox_standard') {
        let atmosphere = MapStylesList[this.mapStyle]?.atmosphere ?? MapStylesList.paj.atmosphere;
        atmosphere.color = MapStylesList[this.mapStyle].backround;
        this.map?.setFog(atmosphere);
      }
    }
  }

  shouldUseMapboxLib (mapStyle: string = null): { useMapbox: boolean, reload: boolean } {
    const newStyle = MapStylesList[(mapStyle ?? this.user.map).toLowerCase()]?.url;
    const style = newStyle ?? MapStylesList.paj.url;
    const useMapboxLib = style.startsWith('mapbox');
    let reload = false;

    if ((useMapboxLib && !this.useMapbox) || (!useMapboxLib && this.useMapbox)) {
      reload = true;
    }

    return { useMapbox: useMapboxLib, reload };
  }
}
