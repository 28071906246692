import {Injectable} from "@angular/core";
import {Constants} from "../constants.enum";
import { StorageService as Storage } from "./storage.service";
import {STORAGE_SETTINGS} from "./storage-settings";
import {Router} from "@angular/router";
import {Platform} from "@ionic/angular";
import {AuthenticationService} from "./authentication.service";
import {FcmService} from "./plugins/fcm.service";
import {MessagingService} from "./messaging.service";
import {DevicesSidebarService} from "../members/map/devices-sidebar/devices-sidebar.service";
import {AppService} from "../app.service";
import {GlobalEventService} from "./globa-event.service";
import {DeviceListService} from "../members/map/devices-sidebar/devices-list/device-list.service";
import { Capacitor } from "@capacitor/core";

@Injectable({
    providedIn: 'root'
})
export class LogoutService {
    constructor(
        private storage: Storage,
        private router: Router,
        private platform: Platform,
        public authService: AuthenticationService,
        private fcmService: FcmService,
        private messagingService: MessagingService,
        private devicesSidebarService: DevicesSidebarService,
        private appService: AppService,
        private globalEventService: GlobalEventService,
        private _deviceListService: DeviceListService) {
    }
    async logout() {
        let firebaseToken: any = null;
        this.router.navigate(["login"], { replaceUrl: true });
        const isMobileApp = Capacitor.isNativePlatform() && (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android');
        if (isMobileApp) {
            firebaseToken = await this.fcmService.getFcmToken();
        } else {
            if (!window["isSafari"]) {
                this.messagingService.getafMessagingtoken().subscribe((token) => {
                    firebaseToken = token;
                });
            }
        }
        if (firebaseToken) {
            this.authService.logOut(firebaseToken);
        }
        this.clearOnLogout();
    }

    async clearOnLogout() {
        this.globalEventService.publishMessageCode("STOP_BACKGROUND_API");
        let darkMode = await this.storage.get("darkMode");

        await this.storage.set(Constants.UPDATE_MAP, 1);
        await this.authService.logoutStatus.next(true);
        //this.storage.set(Constants.LANGUAGE, this.language);
        await this.clearStorageData();
        //await this.storage.set('darkMode', darkMode);
        await this.authService.logout();
        this.appService.user = null;
        this.appService.devices = null;
        this._deviceListService._customDeviceGroups = []; //clear the reorder device list while logout
        this._deviceListService.deviceData = []; //clear the viertual list device list while logout
        // this.router.navigate(["login"], { replaceUrl: true });
        // window.location.href = '/login';
        // setTimeout(() => location.reload(), 500);
    }

    async clearStorageData() {
        this.storage.remove(Constants.USER_DATA);
        this.storage.remove(Constants.USER_ID);
        this.storage.remove(Constants.TOKEN_KEY);
        this.storage.remove(Constants.UPDATE_LOGIN);
        this.storage.remove(Constants.SUPER_USER);
        this.storage.remove(Constants.NOTIFICATIONS_LIST);
        this.storage.remove(Constants.DEVICE_DATA);
        this.storage.remove(Constants.LASTPOINTS);

        this.appService.adminId.remove();
        this.appService.ghostMode.remove();
        this.devicesSidebarService.devicesFlatList.remove();
        this.devicesSidebarService.groups.remove();
        this.devicesSidebarService.userPermissions.remove();
        this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_SELECTED_DEVICE);
        this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_TYPE);
        // this.devicesSidebarService.filters.remove();
        this.devicesSidebarService.resetFilters();
        // console.log('Storaged cleared');
    }
}