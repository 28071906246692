<ion-header class="ion-no-border" no-border>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button mode="md"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'commandsTab.SetupWifi.HomeAddressPicker' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content style="overflow-y: hidden;">
  <div style="padding: 15px 15px 0 15px;">
    <ion-item
      lines="none"
      mode="md"
      class="ion-no-padding"
      style="--inner-padding-end: 0"
    >
      <form (ngSubmit)="hideKeyboard()" style="width: 100% !important">
        <ion-searchbar
          (ionInput)="searchAddress()"
          placeholder="{{ 'deviceManagement.searchText' | translate }}"
          [(ngModel)]="addressInput"
          debounce="250"
          animated="true"
          (ionFocus)="showList = true; searchAddress()"
          enterkeyhint="{{ 'deviceManagement.search' | translate }}"
          class="ion-no-padding"
          name="addressSearch"
        >
        </ion-searchbar>
      </form>
    </ion-item>

    <ion-text class="ion-text-center" style="margin: 10px 0; display: block;">
      {{ 'commandsTab.SetupWifi.HomeAddressMapInfo' | translate }}
    </ion-text>

    <ion-item lines="none" class="address-string ion-no-padding">
      <!-- <ion-icon
        src="assets/images/locate-device/pin-location.svg"
        color="primary"
        slot="start"
      ></ion-icon> -->
      <ion-icon
          *ngIf="type == 'home'"
          color="primary"
          src = 'assets/images/home.svg'
          slot="start">
      </ion-icon>
      <ion-icon
          *ngIf="type == 'work'"
          color="primary"
          src = 'assets/images/logbook/route-towork.svg'
          slot="start">
      </ion-icon>
      <ion-icon
          *ngIf="!(type == 'home' || type == 'work')"
          color="primary"
          src = 'assets/images/location.svg'
          slot="start">
      </ion-icon>
      <ion-label class="ion-text-wrap" *ngIf="!loadingAddress && address">
        {{ address }}</ion-label
      >
      <ion-spinner color="primary" *ngIf="loadingAddress"></ion-spinner>
    </ion-item>

  <ion-card
  *ngIf="addressList.length > 0 && showList"
  class="ion-no-margin addressList"
>
  <ion-list lines="full" class="ion-no-padding">
    <ion-item
      *ngFor="let item of addressList"
      button
      (click)="selectAddress(item)"
      mode="md"
    >
      <ion-icon
          *ngIf="item.props.type == 'home'"
          src = 'assets/images/home.svg'
          slot="start">
      </ion-icon>
      <ion-icon
          *ngIf="item.props.type == 'work'"
          src = 'assets/images/logbook/route-towork.svg'
          slot="start">
      </ion-icon>
      <ion-icon
          *ngIf="!(item.props.type == 'home' || item.props.type == 'work')"
          src = 'assets/images/location.svg'
          slot="start">
      </ion-icon>
      <ion-label
      class="ion-text-wrap"
      [innerHTML]=" item.stringAddress | highLightText: addressInput"
    ></ion-label>
      <!--ion-label>{{ item.info.street }}, {{ item.info.locality }}, {{ item.info.city }}, {{ item.info.country }} </ion-label-->
    </ion-item>
  </ion-list>
</ion-card>
  </div>

  <app-map-min
      #map
      id="mini-map"
      mapStyle="pajLite"
      [useDefinedStyle]="true"
      height="260px"
      [events]="{
        mouseenter: false,
        mouseleave: false,
        click: true,
        dblclick: false
      }"
    >
    </app-map-min>
</ion-content>
<ion-footer class="ion-text-center" style="margin-bottom: 16px;">
  <ion-button class="ion-no-shadow" shape="round" color="primary" (click)="done()">
    <ion-label>{{ 'devicesSetup.doneText' | translate }}</ion-label>
  </ion-button>
</ion-footer>
