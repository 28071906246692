import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { SCREEN_SIZE } from "../interfaces/screen-sizes";
declare var ResizeObserver;
@Injectable()
export class ResizeService {
  size: any = null;
  get onResize$(): Observable<SCREEN_SIZE> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: Subject<SCREEN_SIZE>;

  constructor() {
    this.resizeSubject = new Subject();
  }

  onResize(size: SCREEN_SIZE) {
    this.size = size;
    this.resizeSubject.next(size);
  }

  ScreenLessThan(breakPoint: SCREEN_SIZE){
    const brIndex = BREAK_POINTS.map(br => br.id).indexOf(this.size);
    const brIndex2 = BREAK_POINTS.map(br => br.id).indexOf(breakPoint);
    return brIndex < brIndex2;
  }

  ScreenMajorThan(breakPoint: SCREEN_SIZE){
    const brIndex = BREAK_POINTS.map(br => br.id).indexOf(this.size);
    const brIndex2 = BREAK_POINTS.map(br => br.id).indexOf(breakPoint);
    return brIndex > brIndex2;
  }

}

export const ResizeObs = (element, callback) => {
  const obs = new ResizeObserver(entry => {
    callback(entry);
  });

  return obs.observe(element);
}

export const BREAK_POINTS  = [
  { id: SCREEN_SIZE.XS, name: 'xs', css: 'show-in-xs', minWidth:  0, maxWidth: 576 },
  { id: SCREEN_SIZE.SM, name: 'sm', css: 'show-in-sm', minWidth:  577, maxWidth: 767 },
  { id: SCREEN_SIZE.MD, name: 'md', css: 'show-in-md', minWidth:  768, maxWidth: 991 },
  { id: SCREEN_SIZE.LG, name: 'lg', css: 'show-in-lg', minWidth:  992, maxWidth: 1199 },
  { id: SCREEN_SIZE.XL, name: 'xl', css: 'show-in-xl', minWidth:  1200, maxWidth: 100000 },
];