import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CreateRelativeArrayIndexes } from '../workers/slider-worker';
import { HttpClient } from '@angular/common/http';
import { environment as ENV } from "src/environments/environment";
import { DeviceData } from '../members/map/components/map/class/deviceData';

@Injectable({
  providedIn: 'root'
})
export class MapSliderService {
  url = ENV.FILE_ROUTE + ENV.API_VERSION;
  private worker: Worker;
  private resultSubject = new Subject<number[]>();
  error = new Subject<boolean>();
  generatingIndexes = new Subject<boolean>();
  indexChanged = new Subject<number>();
  sliderDevice: BehaviorSubject<DeviceData> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {
    // this.worker = new Worker('./workers/slider-worker.ts', { type: 'module' });
    // this.worker.onmessage = (event) => {
    //   this.resultSubject.next(event.data);
    // };
  }

  createRelativeIndexes(tripsData, dataPoints, animationPoints, snapedPoints) {
    const url = this.url + 'trackerdata/animationPoints';
    const tripsDataToSend = JSON.parse(JSON.stringify(tripsData));
    delete tripsDataToSend['all_datapoints'];
    delete tripsDataToSend['pause_markers'];
    delete tripsDataToSend['pause_markers'];

    return this.http.post(url, {
      tripsData: tripsDataToSend,
      dataPoints: dataPoints.map(p => ({ lat: p.lat, lng: p.lng, speed: p.speed, dateunix: p.dateunix }) ),
      animationPoints, snapedPoints
    });
  }

  getResultObservable(): Observable<number[]> {
    return this.resultSubject.asObservable();
  }

}
