import { calculateHandlePoint } from "./geofence_center";

let turf = require("@turf/turf");
let dragMode: any = {};
let geofencesSource: any;
let state: any;
declare var window: any;

dragMode.onSetup = function (opts) {
  geofencesSource = opts.draw.options.geofencesSource;
  // turn the opts into state.
  const state = {
    dragMoveLocation: null,
    boxSelectStartLocation: null,
    boxSelectElement: undefined,
    boxSelecting: false,
    canBoxSelect: false,
    dragMoveing: true,
    canDragMove: true,
    selectedFeature: opts.featureIds || false,
    lastMouseDownLngLat: false,
    originalCenter: false,
    mode: "drag" || false,
    draw: opts.draw || null,
    mouseDownFeature: null,
    hPoint: null,
    centerPoint: opts.centerPoint || null
    // initiallySelectedFeatureId: opts.featureId || null
  };

  this.state = state;
  dragMode.onMouseDown(state, opts.event);
  return state;
};
dragMode.toDisplayFeatures = function (state, geojson, display) {
  display(geojson);
};

dragMode.PointDrag = function (e, featureId) {
  dragMode.onMouseDown(this.state, e);
};


dragMode.centroid = function (shape) {
  let polygon = turf.polygon(shape.geometry.coordinates);
  return turf.centroid(polygon).geometry.coordinates;
};

dragMode.onMouseDown = dragMode.onTouchStart = function (state, e) {
  let feature: any;
  // if (e.featureTarget)
  //   feature = geofencesSource.features.find(f => !f ? false : f.id == e.featureTarget.properties.id);
  if (state.selectedFeature) {
    feature = geofencesSource.features.find(f => !f ? false : f.id == state.selectedFeature);

  }
  /*if (
    e.featureTarget &&
    e.featureTarget.properties.id == state.selectedFeature
  ) {
    //e.featureTarget._geometry = feature.geometry;
    // debugger
    if (state.draw.get(e.featureTarget.properties.id)) {
      e.target["dragPan"].disable();
      state.selectedFeature = state.draw.get(e.featureTarget.properties.id);
      state.originalCenter = turf.centroid(e.featureTarget);
      state.originalFeature = e.featureTarget;
      state.mouseDownFeature = state.draw.get(e.featureTarget.properties.id);
    }
  } elseif (
    e.featureTarget &&
    e.featureTarget.properties.id != state.selectedFeature
  ) */ if (feature) {
    if (state.draw.get(state.selectedFeature)) {
      e.target["dragPan"].disable();
      state.selectedFeature = state.draw.get(state.selectedFeature);
      state.mouseDownFeature = state.draw.get(state.selectedFeature);
      state.originalCenter = turf.centroid(state.selectedFeature.geometry);
      state.originalFeature = state.selectedFeature;
      //state.hPoint = calculateHandlePoint(state.selectedFeature)
      state.hPoint = state.centerPoint.getHPointOfFeature(state.selectedFeature.id);
    }
  }
  return state;
};

//_ Mouse down rewrite default onDrag method of Drag Mode
//_ Uses to drag the marker created on the geofence_center file
dragMode.onDrag = function (state, e) {
  if (!state.mouseDownFeature) state.mouseDownFeature = state.originalFeature;
  if (state.mouseDownFeature != null) {
    let feature = state.selectedFeature.id ? state.selectedFeature : state.draw.get(state.selectedFeature);
    //const newLocation = [e.lngLat.lng, e.lngLat.lat];
    const newLocation = translatePoint(feature, state.originalCenter, e, state.hPoint).geometry.coordinates;
    feature = moveGeofence(feature, state, newLocation);
    window[feature.id].setLngLat({ lng: e.lngLat.lng, lat: e.lngLat.lat });
    state.centerPoint.map.geofenceLineIndicatingFeature.updateLineIndication(feature)
    state.draw.add(feature);
  }
};

dragMode.onMouseUp = dragMode.onTouchEnd = function (state, e) {
  if (state.mouseDownFeature != null) {
    let feature = state.selectedFeature.id ? state.selectedFeature : state.draw.get(state.selectedFeature);
    //const newLocation = [e.lngLat.lng, e.lngLat.lat];
    const newLocation = translatePoint(feature, state.originalCenter, e, state.hPoint).geometry.coordinates;
    feature = moveGeofence(feature, state, newLocation);
    window[feature.id].setLngLat({ lng: e.lngLat.lng, lat: e.lngLat.lat });
    state.centerPoint.map.geofenceLineIndicatingFeature.updateLineIndication(feature)
    state.draw.add(feature);
  }

  e.target["dragPan"].enable();
  // emitter.emit('rotateend');
  state.selectedFeature = false;
  state.lastMouseDownLngLat = false;
  state.originalCenter = false;
  state = {};
  return state;
};

export const translatePolygon = (polygon, from, to) => {
  from = turf.point(from);
  to = turf.point(to);

  //finds the bearing angle between the points
  let bearing = turf.rhumbBearing(from, to);

  //calculates the distance between the points
  let distance = turf.rhumbDistance(from, to);
  //moves the polygon to the distance on the direction angle.
  let translatedPoly = turf.transformTranslate(polygon, Math.abs(distance), bearing, {zTranslation: distance,  mutate: true });

  return translatedPoly.geometry.coordinates;
}

export const createCircle = (center, radius) => {
  let options: any = { steps: 60, units: 'kilometers' };
  let circle = turf.circle(center, radius, options);
  return circle.geometry.coordinates;
}

export const getCenter = (polygonCoords) => {
  let polygon = turf.polygon(polygonCoords);
  return turf.centroid(polygon);
}

export const moveGeofence = (feature, state, newLocation) => {
  if (!feature.properties.isCircle){
    let polygon = turf.polygon(state.mouseDownFeature.geometry.coordinates);
    let center = turf.centroid(polygon);
    let from = center.geometry.coordinates;

    const newPolygon = translatePolygon(polygon, from, newLocation);
    feature.geometry.coordinates = newPolygon;
    feature.properties.center = getCenter(newPolygon);
  }
  else {
    feature.geometry.coordinates = createCircle(newLocation, feature.properties.radiusInKm);
    feature.properties.center = newLocation;
  }
  return feature;
}

export const translatePoint = (feature, center = null, event, hPoint) => {
  if (!feature) { return }
  if (!center) {
    let polygon = turf.polygon(feature.geometry.coordinates);
    center = turf.centroid(polygon);
  }
  //get the handle point  of the feature
  //let hPoint = calculateCenterPoint(feature);

  //calculates the distance between hPoint and center of the feature
  let distance = turf.rhumbDistance(hPoint, center);

  //finds the bearing angle between the points
  let bearing = turf.rhumbBearing(hPoint, center);

  let ePoint = turf.point([event.lngLat.lng, event.lngLat.lat])
  //Find the new  location  based to the distance on the direction angle.
  return turf.transformTranslate(ePoint, Math.abs(distance), bearing, {zTranslation: distance, mutate: true});
}

export const customDragMode = dragMode;
