import { Injectable, OnInit } from '@angular/core';
import { StorageService as Storage } from './storage.service';
import * as humanizeTm from 'humanize-duration';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AppService } from '../app.service';
import { environment as ENV }  from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HumanizeTimeService implements OnInit {
  language = 'en';
  currentDateFormat = ENV.DEFAULT_DATE_FORMAT;
  constructor(private storage: Storage, private translate: TranslateService, private appService: AppService) {
    this.getDateFormat();
  }

  async ngOnInit() {
    this.getAppLanguage();
  }

  formatTime(seconds, short = false) { //_ Time passed in seconds converting to miliseconds
    this.getAppLanguage();
    if (short)
      return this.shortTime()(seconds * 1000, { conjunction: "", serialComma: false, units: ['w', 'd', 'h', 'm'], round: true });
    else
      return humanizeTm(seconds * 1000, { language: this.language, round: true, conjunction: "", serialComma: false, units: ['w', 'd', 'h', 'm'] });
  }

  formatTimeWithoutWeek(seconds, short = false) {
    this.getAppLanguage();
    if (short)
      return this.shortTime()(seconds * 1000, { conjunction: "", serialComma: false, units: ['d', 'h', 'm'], round: true });
    else
      return humanizeTm(seconds * 1000, { language: this.language, round: true, conjunction: "", serialComma: false, units: ['w', 'd', 'h', 'm'] });
  }

  shortTime() {
    return humanizeTm.humanizer({
      language: "shortEn",
      languages: {
        shortEn: {
          y: () => this.translate.instant('dateTime.shortYear'),
          mo: () => this.translate.instant('dateTime.shortMonth'),
          w: () => this.translate.instant('dateTime.shortWeek'),
          d: () => this.translate.instant('dateTime.shortDay'),
          h: () => this.translate.instant('dateTime.hr'),
          m: () => this.translate.instant('dateTime.min'),
          s: () => this.translate.instant('dateTime.sec'),
          M: () => this.translate.instant('dateTime.months'),
          ms: () => 'ms',
        },
      },
    });
  }

  formatTimeUnits(seconds, short = false, units = ['w', 'd', 'h', 'm'], round = true) { //_ Time passed in seconds converting to miliseconds
    this.getAppLanguage();
    if (short)
      return this.shortTime()(seconds * 1000, { conjunction: "", serialComma: false, units: units, round: round });
    else
      return humanizeTm(seconds * 1000, { language: this.language, round: true, conjunction: "", serialComma: false, units: units });
  }

  formatDateTime(date, format?, alwaysUseGivenFormat=false, replaceDot=false) {
    let value ='';
    this.getAppLanguage();
    this.getDateFormat();

    /*format = (alwaysUseGivenFormat || format == "HH:mm" || format == "HH:mm:ss")
      ? format
      : this.appService.dateFormat ??
      format ?? this.currentDateFormat;*/
    const formatType = this.getDateFormatType(format)
    format = this.dateFormat(format, formatType, alwaysUseGivenFormat);

    if (typeof (date) === 'number') {
      value = moment.unix(date).locale(this.language).format(format);
    } else {
      value = moment(date).locale(this.language).format(format);
    }
    if (replaceDot == true && this.appService.language == 'de'){
        return value.replace('.','').split(' ').join('. ');
    } else {
        return value
    }
  }

  getAppLanguage(){
    this.language = (this.appService.language == 'en_US' ? 'en' : this.appService.language);
  }

  getDateFormat() {
    this.currentDateFormat = this.appService.dateFormat ?? ENV.DEFAULT_DATE_FORMAT;
  }

  private getDateFormatType(format: string) {
    if(!format) return null;
    let splitFormat = format.split(' ');
    if (splitFormat.length == 1) {
      if (splitFormat[0] == "HH:mm" || splitFormat[0] == "HH:mm:ss")
        return DateFormat.TIME_FORMAT;
      else return DateFormat.DATE_FORMAT
    } else {
        if (format.includes("HH:mm")) return DateFormat.DATE_TIME_FORMAT;
        else return DateFormat.DATE_FORMAT;
    }
  }

  private dateFormat(format: string, formatType: DateFormat, alwaysUseGivenFormat: boolean) {
    if (alwaysUseGivenFormat) return format;
    switch (formatType) {
      case DateFormat.TIME_FORMAT:
        return format;
      case DateFormat.DATE_FORMAT:
        if (format == "YYYY") return format;
        else if (this.appService.dateFormat)  {
          let date = format.split(' ');
          if (date.length >= 2) {
            return date[0] + ' ' + this.appService.dateFormat.split(' ')[0];
          }
          return this.appService.dateFormat.split(' ')[0];
        }
        else return format;
      case DateFormat.DATE_TIME_FORMAT:
      case null:
        return this.appService.dateFormat ??
        format ?? this.currentDateFormat
    }
  }

}

enum DateFormat {
  TIME_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT
}
