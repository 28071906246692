import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UserImgComponent } from './user-img.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from "../../../pipes/pipes.module";

@NgModule({
    imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    ImageCropperModule,
    DirectivesModule,
    PipesModule
],

    declarations: [UserImgComponent],
    exports: [UserImgComponent, ImageCropperModule]
  })


  export class PajUserProfileImageModule {}
