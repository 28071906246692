import { EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, throttleTime } from "rxjs/operators";

export interface MapComponentHooks {
    HOOKS: MapComponentEvents
}

export enum AvailableHooks {
    onLoadLayer, onLoadSource, onLoadImages, onMarkerAdded, onDestroy, onReady, onAddPolylines, onSetSliderView
}

export class MapComponentEvents{
    onLoadLayer = new EventEmitter<{}>();
    onLoadSource = new EventEmitter<{}>();
    onLoadImages = new EventEmitter<{}>();
    onMarkerAdded = new EventEmitter<{}>();
    onAddPolylines = new EventEmitter<{}>();
    onSetSliderView = new EventEmitter<{}>();
    onDestroy = new EventEmitter<{}>();
    onReady = new EventEmitter<{}>();

    private subscriptors = [];
    private lastTime = 0;
    on(eventName: AvailableHooks, fn: (e) => void) : {}{        
        const eventId = this.generateId();
        let debounceTimeValue = 300;

        if(eventName !== AvailableHooks.onMarkerAdded){
            this.subscriptors[eventId] = this[AvailableHooks[eventName]]
            .pipe(throttleTime(debounceTimeValue))
            .subscribe((r) => {
                // this.lastTime = new Date().getTime() - this.lastTime;
                // console.warn('TIME: ' + this.lastTime + ' - EVENT TRIGGERED', AvailableHooks[eventName] + ' id: ' + eventId);
                fn(r);
            });
        }else{
            this.subscriptors[eventId] = this[AvailableHooks[eventName]]            
            .subscribe((r) => {                
                fn(r);
            });
        }

        return eventId;
    }

    off(eventId){
        return this.subscriptors[eventId].unsubscribe();
    }

    offAll(){
      console.warn('OFF HOOKS subscriptors', Object.keys(this.subscriptors));
        Object.keys(this.subscriptors).forEach( k =>  {
          this.subscriptors[k].unsubscribe();
          this.subscriptors[k].complete();
        });
    }

    generateId(){
        let random = () => { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }
        return random() + random() + '-' + random();
    }
}

