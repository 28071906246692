
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceChangeService {
  private changeDevice = new BehaviorSubject<any>(null);
  changeDevice$ = this.changeDevice.asObservable();

  emitEvent(currentDevice:any){
    this.changeDevice.next(currentDevice); 
  }
}