import { ApiService } from 'src/app/services/api.service';
import polyline from './polylines';
import { bearing, transformTranslate, point, transformScale, lineString, lineIntersect, bearingToAzimuth, destination, getCoord } from '@turf/turf';
import * as turf from '@turf/turf';

export class mapUtil {
  constructor(private api: ApiService) {

  }

  public stringToCoordinates(str, precision) {
    let index = 0, lat = 0,
      lng = 0, coordinates = [],
      shift = 0, result = 0,
      byte = null, latitudeChange,
      longitudeChange;
    const factor = Math.pow(10, precision || 6);
    const allCoordinates: number[][] = [];
    // Coordinates have variable length when encoded, so just keep
    // track of whether we've hit the end of the string. In each
    // loop iteration, a single coordinate is decoded.
    while (index < str.length) {

      // Reset shift, result, and byte
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        // tslint:disable-next-line: no-bitwise
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      // tslint:disable-next-line: no-bitwise
      latitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

      shift = result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        // tslint:disable-next-line: no-bitwise
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      // tslint:disable-next-line: no-bitwise
      longitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

      lat += latitudeChange;
      lng += longitudeChange;
      coordinates = [lng / factor, lat / factor];

      allCoordinates.push(coordinates);
    }
    return allCoordinates;
  }

  async traceRoute(points, costing = "auto") {
    let data = [];
    return await this.api.getRoute(points, costing).then(async (res: any) => {
      if (res.data) {
        try {
          data = this.stringToCoordinates(res.data.trip.legs[0].shape, 6);
          return { points: data, response: res.data };
        }
        catch (err) { //If no return data, then return sent points...
          points.forEach(p => {
            data.push([p.lng, p.lat]);
          });
          return { points: data, response: null };
        }
      }
    });
  }

  svgToImage(path, width, height) {
    return new Promise(async resolve => {
      const image = new Image(width, height);
      image.addEventListener('load', () => resolve(image));
      image.src = path;

    });
  }
}

// Convert svg image to a dom image object to render into the mapbox
export const svgToImage = (path, width, height) =>
  new Promise(async resolve => {
    const image = new Image(width, height);
    image.addEventListener('load', () => resolve(image));
    image.src = path;

  });


export const getDeviceImage = (device, userId) => {
  //let imageService = new DeviceImageService();
  //if (!device.properties.iconusecustom)
  //  return imageService.getImage(device.properties, userId);
  //else
  //  return ENV.ROUTE + '/api/user/'+userId+'/device/'+device.id+'/img?name='+device.properties.iconcustomimage;
}

export const getDeviceImageName = (device) => {
  const iconName = !device.properties.iconusecustom ? device.properties.iconname : device.properties.iconcustomimage;
  return iconName ? iconName : 'paj_iconset_logo';
}

export const resizeImage = (base64Str, maxWidth = 400, maxHeight = 350, fill = 'white', cutCircle = true, withRatio = false, setColor = null) => {
  return new Promise(async (resolve) => {
    let img = new Image();
    img.onload = () => {
      let canvas = document.createElement('canvas')
      let width = maxWidth;
      let height = maxHeight;

      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');


      ctx.beginPath();
      if (cutCircle) {
        ctx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, true);

        ctx.fillStyle = 'rgba(255,255,255,0)'; //base64Str.startsWith('https://')?'rgba(255,255,255,0.1)':'rgba(255,255,255,0)';
        ctx.fill();
        ctx.closePath();
        ctx.clip();
      }

      if (withRatio) {
        drawImageScaled(img, ctx);
      } else
        ctx.drawImage(img, 0, 0, width, height);

      if (setColor) {
        //_ get ImageData object
        let svgData = ctx.getImageData(0, 0, width, height);
        let data = svgData.data;

        //_ Set colors
        data = setSvgColor(data, setColor);
        ctx.putImageData(svgData, 0, 0);
      }

      ctx.beginPath();
      ctx.arc(0, 0, 2, 0, Math.PI * 2, true);
      ctx.clip();
      ctx.closePath();
      ctx.restore();


      resolve(canvas.toDataURL());
    }

    img.crossOrigin = "Anonymous";
    img.src = base64Str;
  })
}

export const setSvgColor = (data, color = [0, 0, 0, 1]) => {
  for (var i = 0; i < data.length; i += 4) {
    //_ check if pixel alpha value is not 0, then change the data
    if (data[i + 3] !== 0) {
      data[i] = data[i] == 0 ? color[0] : data[i]; //_ pixel red value
      data[i + 1] = data[i + 1] == 0 ? color[1] : data[i + 1]; //_ pixel green value
      data[i + 2] = data[i + 2] == 0 ? color[2] : data[i + 2]; //_ pixel blue value
      //data[i + 3] = 255;
    }
  }
  return data;
}

export const drawImageScaled = (img, ctx) => {
  var canvas = ctx.canvas;
  var hRatio = canvas.width / img.width;
  var vRatio = canvas.height / img.height;
  var ratio = Math.min(hRatio, vRatio);
  var centerShift_x = (canvas.width - img.width * ratio) / 2;
  var centerShift_y = (canvas.height - img.height * ratio) / 2;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(img, 0, 0, img.width, img.height,
    centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
}

export const ImageWithBubble = (base64Str, maxWidth = 400, maxHeight = 350, fill = 'white', radius) => {
  return new Promise(async (resolve) => {
    let img = new Image();
    img.onload = () => {
      let canvas = document.createElement('canvas')
      let width = maxWidth;
      let height = maxHeight;
      let lineWidth = 2;
      let shadowBlur = 0;
      let ap = (width + (lineWidth * 2) + (shadowBlur * 2)) / 2, aw = 10, ah = 20;  //arrow position, width, height

      canvas.width = width + (lineWidth * 2) + (shadowBlur * 2);
      canvas.height = height + ah + (lineWidth * 2) + (shadowBlur * 2);
      let ctx = canvas.getContext('2d');

      //ctx.filter = 'blur(2px)';
      drawBubble(ctx, width, height, radius, lineWidth, shadowBlur, fill, 'black');
      //ctx.filter = 'none';
      //drawBubble(ctx, width, height, radius, lineWidth, shadowBlur, fill, 'black');

      //_ Draw image
      const imgSize = { w: height * .65, h: height * .65 };
      ctx.beginPath();
      ctx.filter = 'none';
      ctx.shadowColor = "transparent";
      ctx.drawImage(img, (width / 2) - (imgSize.w / 2), (height / 2) - (imgSize.h / 2), imgSize.w, imgSize.h); //_ Draw image
      ctx.closePath();

      //ctx.restore();
      resolve(canvas.toDataURL());
    }

    img.crossOrigin = "Anonymous";
    img.src = base64Str;
  })
}

function drawBubble(ctx, width, height, radius, lineWidth, shadowBlur, fill, stroke) {
  let pi2 = Math.PI * 2
  let ap = width / 2, aw = 10, ah = 20;

  ctx.lineWidth = lineWidth;
  ctx.fillStyle = fill;
  ctx.strokeStyle = stroke;

  //_ shadow
  //ctx.shadowBlur = shadowBlur;
  //ctx.shadowOffsetX = 1;
  //ctx.shadowOffsetY = -1;
  //ctx.shadowColor = stroke;

  //_ Rounded rectangle
  ctx.beginPath();

  ctx.arc(lineWidth + radius, radius, radius - lineWidth, pi2 * 0.5, pi2 * 0.75);
  ctx.arc(width - radius - lineWidth, radius + lineWidth, radius, pi2 * 0.75, pi2);
  ctx.arc(width - radius, height - radius - lineWidth, radius, 0, pi2 * 0.25);       // bottom right corner

  //_ Inserts an arrow (following clock-wise)
  ctx.lineTo(ap + lineWidth, height + lineWidth);
  ctx.lineTo(ap + lineWidth, height + ah + lineWidth);
  ctx.lineTo(ap - aw + lineWidth, height + lineWidth);

  ctx.arc(radius + lineWidth, height - radius, radius, pi2 * 0.25, pi2 * 0.5); // bottom left corner

  ctx.closePath();
  ctx.stroke();
  ctx.fill();
}

export const getDistance = (lat1, lon1, lat2, lon2, unit = 'K') => {
  let radlat1 = Math.PI * lat1 / 180
  let radlat2 = Math.PI * lat2 / 180
  let theta = lon1 - lon2
  let radtheta = Math.PI * theta / 180
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  if (unit == "K") { dist = dist * 1.609344 }
  if (unit == "N") { dist = dist * 0.8684 }
  return Math.abs(dist);
}

export const stringToPoints = (str, precision) => {
  let index = 0,
    lat = 0,
    lng = 0,
    coordinates = [],
    shift = 0,
    result = 0,
    byte = null,
    latitudeChange,
    longitudeChange;
  const factor = Math.pow(10, precision || 6);
  const allCoordinates: number[][] = [];
  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {

    // Reset shift, result, and byte
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      // tslint:disable-next-line: no-bitwise
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    // tslint:disable-next-line: no-bitwise
    latitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      // tslint:disable-next-line: no-bitwise
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    // tslint:disable-next-line: no-bitwise
    longitudeChange = ((result & 1) ? ~(result >> 1) : (result >> 1));

    lat += latitudeChange;
    lng += longitudeChange;
    coordinates = [lng / factor, lat / factor];

    allCoordinates.push(coordinates);
  }

  return allCoordinates;
}

//_ Load of svg with undefined size it will not load right in firefox
//_ so we should use createImageBitmap to get the image as bitmap from the img tag
export const svgToData = async (path, width, height) => {
  return new Promise(async (resolve) => {
    let img = new Image();
    img.width = width;
    img.height = height;
    img.onload = async () => {
      let canvas = document.createElement('canvas')
      const imageBitmap = await createImageBitmap(img, {
        resizeWidth: width,
        resizeHeight: height,
      });

      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');

      ctx.drawImage(imageBitmap, 0, 0, width, height); //_ Draw image
      resolve(canvas.toDataURL());
    }

    img.crossOrigin = "Anonymous";
    img.src = path;
  })
}

export const getHeading = (lat1, lon1, lat2, lon2) => {
  lat1 = lat1 * Math.PI / 180;
  lat2 = lat2 * Math.PI / 180;
  let dLon = (lon2 - lon1) * Math.PI / 180;

  var y = Math.sin(dLon) * Math.cos(lat2);
  var x = Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  var brng = Math.atan2(y, x);

  return (((brng * 180 / Math.PI) + 360) % 360);
}

//_ Return true if lat and lng are ok
//_ Otherwise return false
export const validateLatLng = (lat, lng, data = null) => {
  const isValid = checkLatLng(lat, lng);
  if (data && !isValid){
    console.error('Wrong Lat or Lng', { data });
  }
  return isValid;
}

export const checkLatLng = (lat, lng) => {
  if (!lat || !lat) return false;
  return  isFinite(lat) && Math.abs(lat) <= 90 && (lat != 0)
          && isFinite(lng) && Math.abs(lng) <= 180 && (lng != 0) ;
}

export const cloneObject = (obj) => {
  return JSON.parse( JSON.stringify(obj) );
}

export const getCssStyleVariable = (name) => {
  let style = getComputedStyle(document.body);
  // console.log('DOC STYLE', {style, variable: style.getPropertyValue(name)})
  return style.getPropertyValue(name);
}

export const addMapImage = async (imagePath: string, map, imageName = null, width = 100, height = 100) =>  {
  return new Promise( async (resolve) => {
    const imgData: any = await svgToData(imagePath, width, height); //_ Ratio image w/h
    await map.loadImage(imgData, (error, image) => {
    imageName = imageName || imagePath.replace(/^.*[\\\/]/, "");
    if (!map.hasImage(imageName) && !error)
        map.addImage(imageName, image);
    });
    resolve(true);
  });
}

//_ GRAPHHOPPER METHODS
export const DISTANCE_THRESHOLD_TO_IGNORE_GRAPHHOPPER_PATH = 2;
export const getGraphhopperLegs = (valhallaResponse) => {
  const shapes = [];
  valhallaResponse.trips.forEach((tripItem, index) => {
    if (tripItem.trip?.paths){
      const matchedDistance = tripItem.trip?.map_matching?.distance;
      const ptpDistance = tripItem.trip?.map_matching?.original_distance;
      // console.log('TRIP DATA', { tripItem, index, matchedDistance, ptpDistance, isValid: tripItem.trip?.isValidTrip })
      if ((!tripItem.trip.hasOwnProperty('map_matching')
          ||!tripItem.trip?.isValidTrip) && index > 0)
      {
        // console.log('DISTANCE IS GREATER THAN Original', { matchedDistance, ptpDistance, coordinates: tripItem.coordinates })
        tripItem.coordinates.forEach(point => shapes.push(point));
      } else if (tripItem.trip.hasOwnProperty('paths')) {
        tripItem.trip.paths.forEach(path => {
          if (path.points)
            path.points.coordinates.forEach(point => shapes.push(point));
            // shapes.push(path.points.coordinates);
        })
      }
    } else {
      // console.log('TRIP ITEM ', tripItem);
      tripItem.coordinates.forEach(point => shapes.push(point));
      // const missedShape = GHEncodeCoordinates(tripItem.coordinates, true)
      // shapes.push(missedShape);
    }
  })

  // console.log('GET GRAPHHOPPER LEGS RETURN', { shapes, totalLength })
  return { shapes, totalLength: shapes.length };
}

export const GHDecodeString = (encoded, is3D = false) => {
  var len = encoded.length;
  var index = 0;
  var array = [];
  var lat = 0;
  var lng = 0;
  var ele = 0;

  while (index < len) {
      var b;
      var shift = 0;
      var result = 0;
      do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
      } while (b >= 0x20);
      var deltaLat = ((result & 1) ? ~(result >> 1) : (result >> 1));
      lat += deltaLat;

      shift = 0;
      result = 0;
      do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
      } while (b >= 0x20);
      var deltaLon = ((result & 1) ? ~(result >> 1) : (result >> 1));
      lng += deltaLon;

      if (is3D) {
          // elevation
          shift = 0;
          result = 0;
          do {
              b = encoded.charCodeAt(index++) - 63;
              result |= (b & 0x1f) << shift;
              shift += 5;
          } while (b >= 0x20);
          var deltaEle = ((result & 1) ? ~(result >> 1) : (result >> 1));
          ele += deltaEle;
          array.push([lng * 1e-5, lat * 1e-5, ele / 100]);
      } else
          array.push([lng * 1e-5, lat * 1e-5]);
  }

  return array;
}

export const GHEncodeCoordinates = (coordinates, is3D = false) => {
  var encoded = '';
  var prevLat = 0;
  var prevLng = 0;
  var prevEle = 0;

  function encodeValue(value) {
    var intValue = value < 0 ? ~(value << 1) : (value << 1);
    var result = '';
    while (intValue >= 0x20) {
      result += String.fromCharCode((0x20 | (intValue & 0x1f)) + 63);
      intValue >>= 5;
    }
    result += String.fromCharCode(intValue + 63);
    return result;
  }

  for (var i = 0; i < coordinates.length; i++) {
    const [lng, lat, ele] = coordinates[i];
    var deltaLat = (lat - prevLat) * 1e5;
    var deltaLng = (lng - prevLng) * 1e5;
    var deltaEle = is3D ? (ele - prevEle) * 100 : 0;

    prevLat = lat;
    prevLng = lng;
    prevEle = ele;

    encoded += encodeValue(deltaLat) + encodeValue(deltaLng);
    if (is3D) {
      encoded += encodeValue(deltaEle);
    }
  }

  return encoded;
};

//_ Get legs from valhalla and graphhopper response, should send useGraphhopper if response is of that type
export const GET_LEGS = (vallResponse, length, useGraphhopper) => {
  let shapes = [];
  if (vallResponse.trips) {
      if (useGraphhopper == 0) {
          vallResponse.trips.forEach(trip0 => {
              if (trip0.trip) {
                  let trip1 = trip0.trip;
                  // If distance of the trip is > length
                  if (trip1.trip) {
                      trip1.trip.legs.forEach(leg => {
                          // If distance of the leg is > length then add the shape to the return shape variable
                          if (leg.summary.length > length) {
                              shapes.push(leg.shape);
                          }
                      });
                  }
                  else {
                      //_ Autogenerate string legs for missing valhalla response path
                      //_ Invert lng, lat > lat, lng
                      let coors = trip0.coordinates.map(c => [c[1], c[0]]);
                      const newShapeStr = polyline.encode(coors, 6);
                      shapes.push(newShapeStr);
                  }
              }
              else {
                  //_ Need more test, because some times line starts before|after prevous snaped segment line
                  // console.log('Trip dont have sub trip', trip0);
                  let coors = trip0.coordinates.map(c => [c[1], c[0]]);
                  const newShapeStr = polyline.encode(coors, 6);
                  shapes.push(newShapeStr);
              }

          })
      }
      else if (useGraphhopper == 1) {
          const graphData = getGraphhopperLegs(vallResponse);
          shapes = graphData.shapes;
      }
  }

  return shapes;
}

export const RotateMapCamera = (durationInSeconds: number, map): Promise<void> => {
  const startTime = performance.now();

  return new Promise<void>((resolve) => {
    const animate = (timestamp: number) => {
      const elapsedSeconds = (timestamp - startTime) / 1000;

      if (elapsedSeconds < durationInSeconds) {
        map.rotateTo((timestamp / 100) % 360, { duration: 0 });
        requestAnimationFrame(animate);
      } else {
        map.rotateTo(0, { duration: 0 }); // Ensure the map is back to the original rotation
        resolve();
      }
    };

    requestAnimationFrame(animate);
  });
}

export const getLineOffset = (originalLine, distance, { units = 'kilometers' } = {}) => {
  const parallelLine = [];

  for (const [lng, lat] of originalLine) {
    // const bearing = bearingToAzimuth(getCoord(originalLine));
    const offsetPoint = destination([lng, lat], distance, 0);

    parallelLine.push(getCoord(offsetPoint));
  }

  return parallelLine;
};

export const ExchangeTripSegmentByCoors = (tripData) => {
  tripData.trips.forEach((tripItem, index) => {
    if (tripItem.trip?.paths){
      const matchedDistance = tripItem.trip.map_matching.distance;
      const ptpDistance = tripItem.trip.map_matching.original_distance;
      if (matchedDistance > (ptpDistance * DISTANCE_THRESHOLD_TO_IGNORE_GRAPHHOPPER_PATH) && index > 0) {
        // console.log('DISTANCE IS GREATER THAN Original', { matchedDistance, ptpDistance, coordinates: tripItem.coordinates })
        tripItem.trip.paths[0].points.coordinates = tripItem.coordinates;
      }
    } else {
      tripItem['trip'] = {
        paths: [{ points: { coordinates: tripItem.coordinates } }]
      }
    }
  });

  return tripData;
}

//_ POINTS AND LINES ARRAY UTIL
export interface PointWithDistanceAndIndex {
  index: number;
  point: number[];
  distanceToPoint: number;
  pathDistance: number;
}
export const FindNNearstPoints = (point: number[], linePoints: number[][], pathDistances, N: number): PointWithDistanceAndIndex[] => {
  const currentTurfPoint = turf.point(point);
  const nearestPoints: PointWithDistanceAndIndex[] = [];

  for (let i = 0; i < linePoints.length; i++) {
    const currentDistance = turf.distance(currentTurfPoint, turf.point(linePoints[i]));
    // const pathDistance = GetDistanceFromArray(linePoints.slice(0, i));
    nearestPoints.push({ index: i, point: linePoints[i], distanceToPoint: currentDistance, pathDistance: pathDistances[i] });
  }

  nearestPoints.sort((a, b) => a.distanceToPoint - b.distanceToPoint);

  return nearestPoints.slice(0, N);
}

export const GetDistanceFromArray = (pointsArray, currentIndex = null) => {
  if (currentIndex === 0 || pointsArray.length <= 1) return 0;

  if (currentIndex) {
    let slicedDataPoints = pointsArray.slice(0, currentIndex+1)
    if (pointsArray[0]['lng'] && pointsArray[0]['lat'])
      slicedDataPoints = slicedDataPoints.map(p => [p.lng, p.lat]);

    if (slicedDataPoints.length < 2) return 0;

    const line = turf.lineString(slicedDataPoints);
    return turf.length(line, { units: 'meters' });
  } else {
    const line = turf.lineString(pointsArray);
    return turf.length(line, { units: 'meters' });
  }
}

export const NearestPointDistance = (targetDistance: number, pointsArray: PointWithDistanceAndIndex[]): PointWithDistanceAndIndex | null => {
  //_ Initialize variables to track the nearest distance and its difference
  let nearestPointDistance = null;
  let minDifference = Infinity;

  //_ Iterate through the pointsArray
  for (const point of pointsArray) {
      //_ Calculate the absolute difference between the current distance and the target distance
      const difference = Math.abs(point.pathDistance - targetDistance);

      //_ Update nearestDistance and minDifference if the current difference is smaller
      if (difference < minDifference) {
          minDifference = difference;
          nearestPointDistance = point;
      }
  }

  return nearestPointDistance;
}

export const GenerateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export const GenerateID = (length) => {
  // Generate UUID template string based on the length
  const template = 'x'.repeat(length).replace(/x/g, 'x');

  return template.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export const GenerateCustomFileName = (filename, length = 12) => {
  const extIndex = filename.lastIndexOf('.');
  const ext = filename.substring(extIndex);
  const uuid = GenerateID(length);
  const nameWithoutExt = filename.substring(0, extIndex);
  const customName = `${nameWithoutExt}-${uuid}${ext}`;
  return customName;
}
