
import { AvailableHooks } from "../componentHooks";
import { FeatureInterface } from "./feature-interface";
import { MainMapInterface } from "../../main-map.interface";
import { MapService } from "../../map.service";
import { inject } from "@angular/core";
import { StorageService } from "src/app/services/storage.service";
import { debounce } from 'lodash';
import { DeviceDataService } from "src/app/members/map/devicesData.service";


//_ ALL COMMENTED LINES ARE FOR 3D BUILDINGS
export class HeatMapFeature implements FeatureInterface {
  SOURCE_NAME: string = 'dataSource'; //_ use same source of the map (directions source)
  LAYER_NAME: string = 'heatmapPaj-point'
  HEAT_LAYER_NAME: string = 'heatmapPaj-heat';

  source = null;
  layer = null;
  heatLayer = null;

  mapService: MapService = inject(MapService);
  devicesService: DeviceDataService = inject(DeviceDataService);
  storage: StorageService = inject(StorageService);

  show = false;
  obs = {};
  mapType = '';
  isEnabled = false;
  constructor(public main: MainMapInterface){
    this.init();
  }

  init(){
    this.createLayers();
    this.load();
  }

  //_ Listen for Component hooks to load data
  load(){
    const debouncedEnable = debounce(() => this.enableOrDisable(), 500);
    this.main.on(AvailableHooks.onReady, (isReady) => {
      if (isReady) {
        this.storage.get("heatMapMode").then(async (res) => {
          this.isEnabled = res;
          // setTimeout(async() => {
          //   await this.main.setMapStyle(this.main.mapStyle, this.main.dimension, null);
          //   //this.enableOrDisable()
          //   this.main.setupHeatMap()
          // }, 500);
          if(res){
            setTimeout(() => {
              this.main.setupHeatMap()
              this.enableOrDisable();
            }, 500);
          }

        });
      }
    });

    this.main.on(AvailableHooks.onLoadLayer, (e) => {
      debouncedEnable();
    });

    // this.main.on(AvailableHooks.onLoadSource, (e) => {
    //   debouncedEnable();
    // });

    this.obs["allPointsReady"] = this.devicesService.allPointsReady.subscribe((isEnable) => {
      setTimeout(() => {
        if (this.isEnabled) {
          this.main.setupHeatMap()
          this.enableOrDisable();
        }
      }, 1000);
    });

    this.obs["showHeatMap"] = this.mapService.showHideHeatMap.subscribe((isEnable) => {
      this.isEnabled = isEnable;
    });

    this.obs['storageReady'] = this.storage.storageReady.subscribe((res: boolean) => {
      if (res) this.isEnabled = this.mapService.heatMapMode;
    });

    this.mapService.heatMapMode$.subscribe((status) => {
      if (this.isEnabled != status) {
        this.isEnabled = status;
        // this.setMapStyle(this.mapStyle, this.dimension, null);
      }
    });

    //_ On destroy stop listening for changes
    this.main.HOOKS.on(AvailableHooks.onDestroy, () => {
        Object.keys(this.obs).forEach( (o: any) => this.obs[o].unsubscribe() );
    });
  }

  createSource(){
  }

  createLayers() {
    this.heatLayer = {
      id: this.HEAT_LAYER_NAME,
      type: "heatmap",
      source: this.SOURCE_NAME,
      maxzoom: 20,
      paint: {
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(33,102,172,0)",
          0.2,
          "rgba(0, 255, 0, 0.7)",
          0.4,
          "rgba(0, 255, 0, 0.7)",
          0.6,
          "rgba(0, 255, 0, 0.7)",
          0.8,
          "rgba(0, 255, 0, 0.7)",
          1,
          "rgba(255, 0, 0, 0.7)",
        ],

        "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 19, 20],
        "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 19, 0.5],
      }
    };

    this.layer = {
      id: this.LAYER_NAME,
      type: "circle",
      source: this.SOURCE_NAME,
      minzoom: 20,
      paint: {
        // Size circle radius by earthquake magnitude and zoom level
        "circle-radius": [
          "interpolate",
          ["linear"],
          ["zoom"],
          7,
          16,
        ],

        "circle-color": [
          "interpolate",
          ["linear"],
          ["get", "mag"],
          1,
          "rgba(33,102,172,0)",
          2,
          "rgb(103,169,207)",
          3,
          "rgb(209,229,240)",
          4,
          "rgb(253,219,199)",
          5,
          "rgb(239,138,98)",
          6,
          "rgb(178,24,43)",
        ],
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
        // Transition from heatmap to circle layer by zoom level
        "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1],
      },
    }
  }

  enableOrDisable() {
    if (this.isEnabled)
      this.enable();
    else
      this.disable();
  }

  disable() {
    this.mapType = '';
    if (this.main.map.getLayer(this.LAYER_NAME)) {
      this.main.map.removeLayer(this.LAYER_NAME);
    }
    if (this.main.map.getLayer(this.HEAT_LAYER_NAME)) {
      this.main.map.removeLayer(this.HEAT_LAYER_NAME);
    }
  }

  enable() {
    this.mapType = 'heatmap';
    if (!this.main.map.getLayer(this.LAYER_NAME)) {
      // console.log('-- ADDING LAYER > HEATMAP')
      this.main.map.addLayer(this.layer, 'route');
    }
    if (!this.main.map.getLayer(this.HEAT_LAYER_NAME)) {
      this.main.map.addLayer(this.heatLayer, this.LAYER_NAME);
    }
    this.main.map.moveLayer(this.LAYER_NAME, null);
  }

  async toggleHeatmap() {
    this.isEnabled = !this.isEnabled;
    this.mapService.showHideHeatMap.next(this.isEnabled);
    this.mapService._heatMapMode.next(this.isEnabled)
    this.enableOrDisable();
    return Promise.resolve();
  }
}
