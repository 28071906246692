import * as turf from '@turf/turf';

export function CreateRelativeArrayIndexes(tripsData, dataPoints, animationPoints, snapedPoints): number[] {
    console.time('RELATIVE INDEX CREATED');
    // console.log('INPUT DATA', { tripsData, dataPoints, animationPoints, snapedPoints })
    let relativeArrayIndexes = [];
    const arrayOfDistances = [];

    if (snapedPoints && snapedPoints?.length > 0) {
        animationPoints.reduce((prevDistance, p, index) => {
            const currentDistance = getDistanceFromArray(animationPoints.slice(0, index));
            arrayOfDistances.push(currentDistance + prevDistance);
            return currentDistance + prevDistance;
        }, 0);

        const segmentStartEndIndexes = getSegmentStartEndIndexes(tripsData.trips);
        const animationSegmentIndexes = getAnimationSegmentIndexes(segmentStartEndIndexes, animationPoints, snapedPoints);
        dataPoints.forEach((point, index) => {
            const relativeIndex: any = getRelativeIndex(index, dataPoints, animationPoints, arrayOfDistances, tripsData, animationSegmentIndexes);
            if (relativeIndex === 'PREV_INDEX') {
              relativeArrayIndexes.push(index >= dataPoints.length - 1 ? animationPoints.length - 1 : relativeArrayIndexes[relativeArrayIndexes.length-1]);
            } else {
              relativeArrayIndexes.push(index >= dataPoints.length - 1 ? animationPoints.length - 1 : relativeIndex);
            }
        });
    } else {
        relativeArrayIndexes = dataPoints.map((_, index) => index);
    }

    relativeArrayIndexes = cleanRelativeArrayIndexes(relativeArrayIndexes);

    console.timeEnd('RELATIVE INDEX CREATED');
    // console.log('RELATIVE INDEXES --->', relativeArrayIndexes);

    return relativeArrayIndexes;
}

export const getRelativeIndex = (currentIndex, dataPoints, animationPoints, arrayOfDistances, tripsData, animationSegmentIndexes) => {
    const currentPoint = dataPoints[currentIndex];
    let segmentIndex = findSegmentIndexByDateunix(tripsData.segments, currentPoint.dateunix);

    if (segmentIndex === null) {
        if (currentIndex > 0) {
          const prevPoint = dataPoints[currentIndex-1];
          const prevDistance = turf.distance(turf.point([currentPoint.lng, currentPoint.lat]), turf.point([prevPoint.lng, prevPoint.lat]), { units: 'meters'});

          if (prevDistance < 200) {
            console.warn('RETURN PREV INDEX :(', { currentPoint, segmentIndex });
            return 'PREV_INDEX';
          }
        }

        console.warn('INDEX NOT FOUND :(', { currentPoint, segmentIndex });
        return getNearestPointByDistance(dataPoints, arrayOfDistances, currentIndex, dataPoints, animationPoints);
    }

    const snapedSegmentCoors = tripsData.trips[segmentIndex].trip.paths[0].points.coordinates;

    if (snapedSegmentCoors.length === 0) {
        console.warn('SEGMENT WITH NO COORDS :(', { currentPoint, segmentIndex });
        return getNearestPointByDistance(dataPoints, arrayOfDistances, currentIndex, dataPoints, animationPoints);
    }

    // const startLoop = segmentStartEndIndexes[segmentIndex];
    // console.log('SEGMENT INDEXES', segmentStartEndIndexes);
    // console.log('SEGMENT START INDEX', { startLoop, segmentsWithIndex: getIndexWhereSegmentStarts(tripsData.trips)})
    const animationSegment = animationSegmentIndexes[segmentIndex];
    // const animationSegment = getSegmentByStartEndPoint(animationPoints, snapedSegmentCoors[0], snapedSegmentCoors[snapedSegmentCoors.length - 1], startLoop);
    const slicedAnimationPoints = animationPoints.slice(animationSegment.startIndex, animationSegment.endIndex);

    if (currentPoint.lng && currentPoint.lat) {
        const currentTurfPoint = turf.point([currentPoint.lng, currentPoint.lat]);
        const turfPoints = slicedAnimationPoints.map((point) => turf.point(point));
        const nearestPoint = turf.nearestPoint(
            currentTurfPoint,
            turf.featureCollection(turfPoints),
        );

        return animationSegment.startIndex + nearestPoint?.properties.featureIndex;
    } else {
        return animationSegment.startIndex;
    }
}

export const getNearestPointByDistance = (dataPoints, arrayOfDistances, currentIndex, pointsArray, relativeArray): number => {
    if (!dataPoints.length) return null;

    const currentDistance = getDistanceFromArray(pointsArray, currentIndex);
    const currentPoint = dataPoints[currentIndex];

    if (currentDistance === 0) return null;

    if (currentPoint.lng && currentPoint.lat) {
        const nearestPoints = findNNearstPoints([currentPoint.lng, currentPoint.lat], relativeArray, arrayOfDistances, 10);
        const nearestPointByDistance = nearestPointDistance(currentDistance, nearestPoints);
        return nearestPointByDistance?.index ?? null;
    }

    return null;
}

export const cleanRelativeArrayIndexes = (arr: number[]): number[] => {
    let prevNonNullValue = null;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== null) {
            prevNonNullValue = arr[i];
        } else {
            arr[i] = prevNonNullValue;
        }
    }

    for (let i = 1; i < arr.length - 1; i++) {
        if (arr[i] < arr[i - 1] || arr[i] > arr[i + 1]) {
            arr[i] = arr[i - 1];
        }
    }
    return arr;
}

export const findSegmentIndexByDateunix = (segments, dateunix) => {
    for (let segmentIndex = 0; segmentIndex < segments.length; segmentIndex++) {
        const segment = segments[segmentIndex];
        for (const point of segment) {
            const [lng, lat, speed, dateunixPoint] = point;
            if (dateunix === dateunixPoint) {
                return segmentIndex;
            }
        }
    }
    return null;
}

export const getSegmentByStartEndPoint = (pointsArray, startPoint, endPoint, startLoopIndex = 0) => {
    let startIndex = -1;
    let endIndex = -1;

    for (let i = startLoopIndex; i < pointsArray.length; i++) {
        const currentPoint = pointsArray[i];

        if (currentPoint) {
            if (currentPoint[0] === startPoint[0] && currentPoint[1] === startPoint[1]) {
                startIndex = i;
            }
            if (currentPoint[0] === endPoint[0] && currentPoint[1] === endPoint[1] && startIndex > -1) {
                endIndex = i;
                break;
            }
        }
    }

    return { startIndex, endIndex };
}

export const findNNearstPoints = (point: number[], linePoints: number[][], pathDistances, N: number): PointWithDistanceAndIndex[] => {
    const currentTurfPoint = turf.point(point);
    const nearestPoints: PointWithDistanceAndIndex[] = [];

    for (let i = 0; i < linePoints.length; i++) {
        const currentDistance = turf.distance(currentTurfPoint, turf.point(linePoints[i]));
        nearestPoints.push({ index: i, point: linePoints[i], distanceToPoint: currentDistance, pathDistance: pathDistances[i] });
    }

    nearestPoints.sort((a, b) => a.distanceToPoint - b.distanceToPoint);

    return nearestPoints.slice(0, N);
}

export const getDistanceFromArray = (pointsArray, currentIndex = null) => {
    if (currentIndex === 0 || pointsArray.length <= 1) return 0;

    let slicedDataPoints = pointsArray;
    if (currentIndex) {
        slicedDataPoints = pointsArray.slice(0, currentIndex + 1);
        if (pointsArray[0]['lng'] && pointsArray[0]['lat']) {
            slicedDataPoints = slicedDataPoints.map(p => [p.lng, p.lat]);
        }
    }

    if (slicedDataPoints.length < 2) return 0;

    const line = turf.lineString(slicedDataPoints);
    return turf.length(line, { units: 'meters' });
}

export const nearestPointDistance = (targetDistance: number, pointsArray: PointWithDistanceAndIndex[]): PointWithDistanceAndIndex | null => {
    let nearestPointDistance = null;
    let minDifference = Infinity;

    for (const point of pointsArray) {
        const difference = Math.abs(point.pathDistance - targetDistance);
        if (difference < minDifference) {
            minDifference = difference;
            nearestPointDistance = point;
        }
    }

    return nearestPointDistance;
}

export const getSegmentStartEndIndexes = (trips) => {
  const allCoords = [];
  const segmentsWithStartIndex = [];
  // Iterate over each trip
  trips.forEach((tripData, index) => {
      // Extract trip details
      const trip = tripData.trip;
      const pathCoordinates = trip.paths[0].points.coordinates;

      // Extract coordinates and their corresponding details
      pathCoordinates.forEach((coords, coordIndex) => {
          allCoords.push({
            coords, segmentIndex: index,  coordIndex
          });
      });
  });

  // console.log('DEBUG __-->', { allCoords, trips });
  let prevIndex = -1;
  allCoords.forEach((coords, index) => {
    if (coords.segmentIndex != prevIndex) {
      if (segmentsWithStartIndex[segmentsWithStartIndex.length-1])
        segmentsWithStartIndex[segmentsWithStartIndex.length-1].endIndex = index-1;
      segmentsWithStartIndex.push({ startIndex: index, endIndex: null });
      prevIndex = coords.segmentIndex
    }
    if (index === allCoords.length-1) {
      segmentsWithStartIndex[segmentsWithStartIndex.length-1].endIndex = index;
    }
  })

  return segmentsWithStartIndex;
}

export const getAnimationSegmentIndexes = (segmentStartEndIndex, animationPoints, snapedPoints) => {
  const animationSegmentIndexes = [];
  segmentStartEndIndex.forEach(segmentIndexes => {
    const animationIndex = getSegmentByStartEndPoint(animationPoints, snapedPoints[segmentIndexes.startIndex], snapedPoints[segmentIndexes.endIndex]);
    animationSegmentIndexes.push(animationIndex);
  });

  // console.log('ANIMATION INDExES', animationSegmentIndexes)
  return animationSegmentIndexes;
}

export interface PointWithDistanceAndIndex {
  index: number;
  point: number[];
  distanceToPoint: number;
  pathDistance: number;
}
