import { MapComponent } from "../../map.component";
import { getSource } from "./../castMaplibreData";
import { FeatureInterface } from "./feature-interface";
import { getDistance, mapUtil } from "./../mapUtil";
import { Layer, layerType } from "../layer";
import chroma from "chroma-js";
import { AvailableHooks } from "../componentHooks";
import { DataSource } from "../dataSource";
import { Data, dataSourceType, dataTypes } from "../data";
import * as turf from '@turf/turf';
import { CLONE_OBJ, findNearbyPoints } from "./highSpeedPolylinesHelper";
import { MainMapInterface } from "../../main-map.interface";
import { ApiService } from "src/app/services/api.service";
import { inject } from "@angular/core";
import { MapService } from "../../map.service";

export class HighSpeedPolylines implements FeatureInterface{
    layer = null;
    source = null;
    bounds = null;
    show = false;
    show_speed_btn = false;

    SOURCE_NAME = "highSpeedSource";
    LAYER_NAME = "HighSpeedLayer";
    MIN_ZOOM = 4;
    obs: any = {};
    // showButtonSetting = false;
    colorScale = null;
    MAX_SPEED = 150;
    SPEED_RANGES = [
        { min: 0, max: 49 }, { min: 50, max: 99}, { min: 100, max: 0 }
    ];

    DEFAULT_SPEED_RANGES = [
        { min: 0, max: 49 }, { min: 50, max: 99}, { min: 100, max: 0 }
    ];

    COLORS_RANGE = ['green', 'yellow', 'red'];
    COLORS_PALETE = [];
    creatingSourceData = null;
    apiService = inject(ApiService);
    mapUtil = new mapUtil(this.apiService);
    mapService = inject(MapService)
    constructor (public main: MainMapInterface){
        this.init();
    }

    //_ Declare source and layer for the map
    init(){
        this.COLORS_PALETE = chroma.scale(this.COLORS_RANGE).mode('lch').colors(this.MAX_SPEED);
        // console.log('COLOR PALETE', this.COLORS_PALETE);
        this.createSource();
        this.createLayer();
        this.load();

        this.calcSpeedRange();

        //_ listend for setting changes
        this.obs['showSpeedPolylinesBtn'] = this.mapService.showSpeedPolylinesBtn.subscribe( status => {
            this.show_speed_btn = status;
            this.showHide(this.show);
        });

        this.obs['showSpeedPolylines'] = this.mapService.showSpeedPolylines.subscribe( status => {
            this.showHide(status);
        });

        this.obs['user'] = this.main.appService.user$.subscribe(u => {
            this.calcSpeedRange();
        });

        this.obs['updateDistanceUnit'] = this.main.appService._settingsChanged.subscribe(r => {
            this.calcSpeedRange();
        });
    }

    //_ Load source and layer on the map
    load(){
        this.main.on(AvailableHooks.onLoadSource, (e) => {
            if (!this.main.map.getSource(this.SOURCE_NAME))
                this.main.map.addSource(this.SOURCE_NAME, this.source);
        });

        this.main.on(AvailableHooks.onLoadLayer, (e) => {
            if (!this.main.map.getLayer(this.LAYER_NAME))
                this.main.map.addLayer(this.layer, 'route');
        });

        //_ Lisent for directionsSource changes
        this.main.on(AvailableHooks.onReady, (e) => {
            //_ Update speed polylines when directionsSource|dataSource has some changes
            (this.main.map as any).on('sourcedata', (event) => {
                if (!this.show) return; //_ Do nothing if feature is disabled
                if (event.sourceId == 'directionsSource' || event.sourceId == 'dataSource'){
                    //_ prevent trigger when sourcedata is fired by other user interactions :)
                    if (!this.main.map.isMoving()) {
                        if (this.creatingSourceData)
                            clearInterval(this.creatingSourceData);

                        this.creatingSourceData = setTimeout( () => {
                            this.createPolylineSegments(event, event.sourceId);
                            // console.log('UPDATING POLYLINES FROM SOURCE CHANGES', event);
                        }, 500);
                    }
                }
            });
        });

        this.main.on(AvailableHooks.onSetSliderView, (e) => {
            this.setSliderView(e);
        });

        this.main.on(AvailableHooks.onDestroy, (e) => {
          this.destroy();
        });
    }

    createSource(){
        this.source = <DataSource>{};
        this.source.data = <Data>{};
        this.source.type = dataSourceType.geojson;
        this.source.data.features = [];
        this.source.data.type = dataTypes.FeatureCollection;
        this.source["generateId"] = true;
    }

    createLayer(){
        this.layer = new Layer(this.LAYER_NAME);
        this.layer.source = this.SOURCE_NAME;
        this.layer.type = layerType.line;
        // this.layer.layout["line-join"] = "round";
        this.layer.layout["line-cap"] = "round";
        this.layer.paint['line-color'] = ['get', 'color'];
        this.layer.paint['line-blur'] = 1;
        this.layer.paint['line-width'] = 14;
        this.layer.paint['line-opacity'] = 0.9;

        this.layer.maxzoom = 22;
        this.layer.minzoom = this.MIN_ZOOM;
        // this.layer['slot'] = 'top';
        this.layer["filter"] = [
            "all",
            ["==", ['get', "deviceShow"], 1], //_ Show only if deviceShow == 1
            ['==', ['get', 'show'], true], //_ Show if feature is enabled
            [
                'any',
                ['==', ['get', 'isSliderViewOpen'], false], // OR condition: isSliderViewOpen == false
                ['==', ['get', 'isSliderData'], true], // OR condition: isSliderDevice == true
                // ['==', ['get', 'isOnlySliderData'], true]
            ]
        ];
    }

    createPolylineSegments(data = null, sourceId = null) {
        const MAX_SEGMENT_LENGTH = this.main.MAX_DISTANCE_TO_SPLIT_SEGMENTS;

        // console.log('Creating speed polylines');

        const groupedData = this.getSourceData();
        // console.log('GROUPED DATA', groupedData)
        //_ LOGIC TO CREATE SEGMENTS BY GROUPED FEATURES
        //_ Created segments
        const newFeatures = [];
        const prevLastDistance = 0;
        Object.keys(groupedData).forEach(deviceId => {
            const features = groupedData[deviceId];
            newFeatures
            if (features){
                features.forEach((f, index)=> {
                    // console.log('Index', index);
                    if (index > 0){
                        const distance = turf.distance(features[index-1].geometry.coordinates, features[index].geometry.coordinates, { units: 'meters' });
                        if (distance < MAX_SEGMENT_LENGTH){
                            const line = turf.lineString([features[index-1].geometry.coordinates, features[index].geometry.coordinates]);
                            line.properties['color'] = this.getColorBySpeed(features[index].properties.speed);
                            line.properties['deviceShow'] = features[index].properties.deviceShow;
                            line.properties['show'] = this.show && this.show_speed_btn; //_ hide also when heatmap is active
                            line.properties["isSliderViewOpen"] = this.main.isSliderViewEnable;
                            line.properties["isSliderData"] = features[index].properties.isSliderData;
                            newFeatures.push(line);
                        }
                    }
                })
            }
        });
        //_ END LOGIC TO CREATE SEGMENTS

        this.source.data.features = newFeatures;
        if (this.main.map.getSource(this.SOURCE_NAME)){
            getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
        }
    }

    //_ Group features by deviceId
    getGroupedFeaturesFromSource (data){
        // console.log('DATA FROM GROUPED FEATURES', data);
        return data.source.data.features.reduce((groups, item) => {
            const group = groups[item.properties.deviceId] || []; // Create a new group if it doesn't exist yet
            if (item.properties?.origin != 'wifi'){
                group.push(item); // Add the item to the group
                groups[item.properties.deviceId] = group; // Update the groups object
            }
            return groups;
        }, {});
    }

    getColorBySpeed(speed: number = 0){
        speed = speed > this.MAX_SPEED ? this.MAX_SPEED-1: speed;
        return this.COLORS_PALETE[Math.floor(speed)];
    }

    showHide(status) {
        this.show = status;
        this.source.data.features.forEach((f) => {
          f.properties["show"] = status && this.show_speed_btn;
        });

        // And then update the source in the map :)
        if (this.main.map) {
          if (this.main.map.getSource(this.SOURCE_NAME)){
            if (this.show){
                // getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
                this.createPolylineSegments();
            }
            else {
                getSource(this.main.map, this.SOURCE_NAME).setData( { ...this.source.data, features: [] } );
            }
          }
        }
    }

    //_ Just in Case
    //_ Implemented to use a setting button in the map to toggle the feature
    toggleFeature(showToast = false){
        this.show = !this.show;
        this.showHide(this.show);

        //_ Update setting in storage and backend
        this.main.updateSingleSetting('show_speed_polylines', this.show ? 1 : 0, false);

        if (this.show == true && showToast) {
            this.main.appService.showToast('', this.main._translate.instant('fabbuttonToast.showSpeedPolylinesOn'), 2000, 'success');
        }
        else if (showToast) {
            this.main.appService.showToast('', this.main._translate.instant('fabbuttonToast.showSpeedPolylinesOff'), 2000, 'success');
        }
    }

    //_ Snap the direction points to the dataSource features from main map
    getSourceData(){
        const snapedFeatures = this.getGroupedFeaturesFromSource({ source: { ...this.main.dataSource } });
        const directionsFeatures = this.getGroupedFeaturesFromSource({ source: { ...this.main.directionsSource } });
        // console.log('GET SOURCE DATA', { snapedFeatures, directionsFeatures })
        const SpeedFeatures = [];
        Object.keys(directionsFeatures).forEach( k => {
            const deviceDirections = directionsFeatures[k]; //.map(df => ({ lat: df.geometry.coordinates[1], lng: df.geometry.coordinates[1], ...df.properties }));
            let deviceSnapedpoints = snapedFeatures[k].map(item => item.geometry.coordinates).flat();

            //_ By default use deviceDirections data
            let nearestPoints = deviceDirections;
            if (deviceSnapedpoints.length > 0)
                nearestPoints = findNearbyPoints(deviceDirections, deviceSnapedpoints);

            SpeedFeatures[k] = nearestPoints;
        });
        return SpeedFeatures;
    }

    //_ Convert speed range KM -> Miles
    calcSpeedRange(){
        this.SPEED_RANGES = CLONE_OBJ(this.DEFAULT_SPEED_RANGES);
        if (this.main.appService.user){
            if (this.main.appService.user.distance_unit != 'km'){
                this.SPEED_RANGES.forEach(s => {
                    if (s.min > 0) s.min = Math.ceil(s.min / 1.609);
                    if (s.max > 0) s.max = Math.ceil(s.max / 1.609);
                })
            }
        }
    }

    destroy(){
        Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
    }

    clearData() {
        this.source.data.features = [];
        if (this.main.map.getSource(this.SOURCE_NAME)){
            getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
        }
    }

    setSliderView (ev) {
        //_ Filter source
        this.source.data.features.forEach((f) => {
            f.properties["isSliderViewOpen"] = ev.isEnable;
        });

        //_ If slider is close then needs to filter the sliderData features
        if (!ev.isEnable) {
            this.source.data.features = this.main.filterFeaturesWithoutSliderProps(this.source.data.features);
            this.source.data.features = this.main.cleanSliderFeatureProps(this.source.data.features);
        }

        //_ Update source
        if (this.main.map.getSource(this.SOURCE_NAME))
            getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }

    async hide(){
      this.show = false;
      this.mapService.showSpeedPolylines.next(this.show);
    }

    async showIfEnabled(){
        let value = await this.main.storage.get('showSpeedPolylines');
        this.show = value ? value : false;
        this.mapService.showSpeedPolylines.next(this.show);
        this.main.updateSettings({ show_speed_polylines: this.show });
    }
}
