import {   AfterViewInit, Directive,  ElementRef,  EventEmitter,    HostListener, Input, OnChanges, OnDestroy, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';


@Directive({ 
    selector: '[isInViewport]' 
})
export class IsInViewPortDirective implements OnInit, AfterViewInit {
  wasInView: boolean; //_ check if previos status was on view or not
  @Output('inView') inView = new EventEmitter();
  @Output('outView') outView = new EventEmitter();

  constructor(
    private elementRef: ElementRef
  ) {}

  ngOnInit(){

  }
  
  ngAfterViewInit() {
    const observer = new IntersectionObserver(entries => {
        this.renderContents(entries[0].isIntersecting);
    });
    
    observer.observe(this.elementRef.nativeElement);

  }

  renderContents(isInView) {
    if (isInView && !this.wasInView) {
      this.wasInView = true;
      this.inView.emit();
    } else if (!isInView && this.wasInView) {
      this.wasInView = false;
      this.outView.emit();
    }
  }
}