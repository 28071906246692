import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { WifiListComponent } from '../wifi-list/wifi-list.component';
import { HomeAddressPickerComponent } from '../home-address-picker/home-address-picker.component';
import { SetupWifiService } from '../setup-wifi.service';

@Component({
  selector: 'app-guided-setup',
  templateUrl: './guided-setup.component.html',
  styleUrls: ['./guided-setup.component.scss']
})
export class GuidedSetupComponent implements OnInit, AfterViewInit {
  @ViewChild("nav", { static: false }) nav: IonNav;
  
  constructor(private setupWifiService: SetupWifiService){}

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {    
    this.setupWifiService.initialRoot(WifiListComponent, {}, this.nav)
  }

}
