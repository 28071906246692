import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addressFormat'
})

export class AddressFormatPipe implements PipeTransform {
  constructor() { }

  transform(address: any) {
    return address && !address.includes(', \n') ? address.replace(/, /g, ", \n") : address;
  }
}