<ion-header>
  <ion-item lines="none" color="dark">
    <ion-avatar slot="start">
      <ion-img
        src="{{ device?.properties | DeviceImage: -1 | async }}"
        style="object-fit: contain"
      ></ion-img>
    </ion-avatar>

    <ion-label>
      <h2 style="font-size: 15px" class="ion-text-wrap">
        <span
          [style.background-color]="device?.properties.spurfarbe"
          class="device-circle"
        ></span>
        {{ device?.properties.name }}
      </h2>
      <p class="text-selectable" style="font-size: 13px">
        {{ device?.properties.imei }}
      </p>
    </ion-label>
  </ion-item>
</ion-header>

<ion-list
  lines="none"
  class="ion-no-padding"
  mode="md"
  style="background: var(--ion-background-color, #fff)"
  [class.disable]="device?.permissions?.modify == 0"
>
  <!-- INPUT NAME -->
  <ion-item
    class="input-box"
    title="Click to edit the name of the geofence."
    lines="none"
  >
    <ion-icon name="create-outline" slot="start"></ion-icon>
    <ion-input
      [value]="geofence?.name"
      (ionBlur)="setName($event)"
      (ionFocus)="openKeyboard()"
      debounce="100"
      autocomplete="off"
      (ionChange)="updateName($event)"
      placeholder="{{ 'alerts.geofenceName' | translate }}"
      class="ion-text-center"
      type="text"
      maxlength="50"
      (keyup.enter)="setName($event)"
      enterkeyhint="{{ 'alerts.saveText' | translate }}"
    >
    </ion-input>
  </ion-item>

  <ion-item class="radius-item" color="transparent">
    <!-- RADIUS FOR CIRCLE SHAPE -->
    <ion-icon
      src="assets/images/map/popup/radius.svg"
      color="primary"
      *ngIf="feature?.properties.isCircle"
    ></ion-icon>

    <ion-label
      class="ion-text-center cursor-pointer"
      *ngIf="feature?.properties.isCircle"
      button
      (click)="setRadius()"
    >
      <h1
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        "
      >
        {{ radiusText }}
        <ion-icon name="create-outline" style="cursor: pointer"></ion-icon>
      </h1>
      <p>{{ "geofence.radius" | translate }}</p>
    </ion-label>

    <!-- AREA FOR NON CIRCLE SHAPE -->
    <ion-icon
      src="assets/images/map/popup/area.svg"
      color="primary"
      *ngIf="!feature?.properties.isCircle"
    ></ion-icon>
    <ion-label class="ion-text-center" *ngIf="!feature?.properties.isCircle">
      <h1>{{ areaText }}</h1>
      <p>{{ "geofence.area" | translate }}</p>
    </ion-label>

    <!-- COLOR OF GEOFENCE -->
    <ion-buttons slot="end">
      <ion-label class="ion-text-center">
        <ion-button
          class="color-box pointer"
          (click)="getPickr()"
          [style.background-color]="feature?.properties.portColor"
        ></ion-button>

        <!-- <div class="color" [style.background]="feature?.properties.portColor" (click)="colorPicker.click($event)"></div>  -->
        <p>{{ "geofence.color" | translate }}</p>
      </ion-label>
    </ion-buttons>
  </ion-item>
  <ion-text *ngIf="feature.properties.radiusInKm < 0.1" color="danger">
    <h6 class="ion-margin-horizontal warning-text">
      {{ "alerts.radiusWarning" | translate }}
    </h6>
  </ion-text>

  <!-- Opacity Range select -->
  <ion-item color="transparent" mode="md">
    <ion-label slot="label">Opacity</ion-label>
    <ion-range
      mode="md"
      color="primary"
      min="0.0"
      max="1"
      [value]="feature?.properties.opacity ?? 0.25"
      [pin]="true"
      step="0.01"
      [pinFormatter]="opacityFormat"
      class="ion-no-padding"
      style="padding-top: 22px"
      debounce="500"
      (ionChange)="onChangeOpacity($event)"
    >
      <ion-icon
        slot="start"
        name="sunny-outline"
        style="width: 16px"
        (click)="decreaseOpacity()"
      ></ion-icon>
      <ion-icon
        slot="end"
        name="sunny-outline"
        (click)="increaseOpacity()"
      ></ion-icon>
    </ion-range>
  </ion-item>

  <!-- TOGLES OPTIONS -->
  <div class="toggle-container">
    <div *ngIf="!device?.properties.alarm_enabled" class="overlayAlerts">
      <ion-button (click)="enableAlerts()" expand="block" shape="round">
        {{ "enableDisableAllAlerts.enableAlerts" | translate }}
      </ion-button>
    </div>
    <div [class.disableEnterLeaveAlert]="!device?.properties.alarm_enabled">
      <ion-item class="toggle-item" color="transparent">
        <ion-icon
          src="assets/images/map/popup/entering.svg"
          slot="start"
          color="primary"
        ></ion-icon>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          "
        >
          <ion-label class="ion-text-wrap">{{
            "alerts.enterGeofence" | translate
          }}</ion-label>

          <ion-icon
            *ngIf="this.device.deviceModel.model_nr == 9084"
            (click)="this.toogleSoundAlert('enter')"
            style="width: 24px; height: 24px; cursor: pointer;"
            [src]="
              this.enabledSoundEnter
                ? 'assets/images/geofence/sound-enable.svg'
                : 'assets/images/geofence/sound-disable.svg'
            "
            slot="center"
            color="primary"
          ></ion-icon>
        </div>
        <ion-toggle
          mode="ios"
          color="success"
          slot="end"
          [checked]="geofence?.trigger_enter"
          (ionChange)="updateGeofence($event, 'trigger_enter')"
          aria-label=""
        ></ion-toggle>
      </ion-item>

      <ion-item class="toggle-item" color="transparent">
        <ion-icon
          src="assets/images/map/popup/leaving.svg"
          slot="start"
          color="primary"
        ></ion-icon>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          "
        >
          <ion-label class="ion-text-wrap">{{
            "alerts.leaveGeofence" | translate
          }}</ion-label>
          <ion-icon
            *ngIf="this.device.deviceModel.model_nr == 9084"
            (click)="this.toogleSoundAlert('leave')"
            style="width: 24px; height: 24px; cursor: pointer;"
            [src]="
              this.enabledSoundLeave
                ? 'assets/images/geofence/sound-enable.svg'
                : 'assets/images/geofence/sound-disable.svg'
            "
            slot="center"
            color="primary"
          ></ion-icon>
        </div>
        <ion-toggle
          mode="ios"
          color="success"
          slot="end"
          [checked]="geofence?.trigger_leave"
          (ionChange)="updateGeofence($event, 'trigger_leave')"
          aria-label=""
        ></ion-toggle>
      </ion-item>
    </div>
  </div>

  <!-- DELETE BUTTON -->
  <div class="center-button">
    <ion-button
      shape="round"
      color="light"
      fill="clear"
      size="small"
      class="ion-no-padding"
      title="{{ 'geofence.centerInAddress' | translate }}"
      (click)="openAddressPicker()"
    >
      <ion-icon
        src="assets/images/map/popup/map-pin.svg"
        color="primary"
        slot="start"
        style="margin-right: 10px; width: 40px; height: 20px"
      ></ion-icon>
      <ion-label class="ion-text-wrap">{{
        "geofence.centerInAddress" | translate
      }}</ion-label>
    </ion-button>

    <ion-button shape="round" color="light" size="small" (click)="deleteGeo()">
      <ion-icon name="trash-outline" color="danger"></ion-icon>
      <!-- <ion-label>{{ "geofence.delete" | translate }}</ion-label> -->
    </ion-button>
  </div>
</ion-list>
