import { Injectable } from "@angular/core";
import { Clipboard } from "@capacitor/clipboard";
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "src/app/app.service";

@Injectable({
  providedIn: "root",
})
export class ClipboardService {
  constructor(
    private appService: AppService,
    private translate: TranslateService,
    private platform: Platform
  ) { }


  async copyToClipboard(value: string) {
    await Clipboard.write({
      string: value,
    });
    await this.appService.showToast(
      null,
      this.translate.instant("info.copySuccess"),
      1000,
      "success"
    );
  }
  async checkClipboard() {
    const { type, value } = await Clipboard.read();
  }
}
