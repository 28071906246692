import { Component, Input, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-sos',
  templateUrl: './sos.component.html',
  styleUrls: ['./sos.component.scss'],
})
export class SosComponent implements OnInit {
  @Input() deviceID;
  device: any;
  commands: Array<any>
  constructor(private apiService: ApiService, private toastController: ToastController, private devicesDataService: DeviceDataService) { }

  async ngOnInit() {
    let devices = this.devicesDataService.devices;
    this.device = devices[this.deviceID]
    if (this.device && this.device.deviceModel && this.device.deviceModel.model_nr == 9069) {
      this.apiService.getCommands(this.deviceID).then(
        async (res: any) => {
          this.commands = res;
        },
        async (error) => {
          console.log("ERROR: ", error);
        }
      );
    }


    // postCommandAlert
  }
  activateCommand(index: number) {
    this.apiService.postCommandAlert(this.deviceID, this.commands[index].id).then(
      async (res: any) => {
        // this.commands = res;
        this.presentToast(res.data)
      },
      async (error) => {
        console.log("ERROR: ", error);
      }
    );
  }
  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      'color': 'success',
      cssClass: 'centerToast'
    });
    toast.present();
  }

}
