import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS, MapService } from '../map/map.service';
import { Chart, CategoryScale, LinearScale, LineController, PointElement, LineElement, BarController, BarElement, Filler } from 'chart.js';
import { createConfig, createData } from './chartUtil';
import { getDistance } from 'src/app/members/map/components/map/class/mapUtil';
//import distance from '@turf/distance';
import * as moment from "moment";
import { ModalController, Platform, PopoverController } from "@ionic/angular";
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { AppService } from 'src/app/app.service';
import { RouteService } from '../../devices-sidebar/device-item/device-submenu/route/route.service';
import { NewPullupComponent } from '../../new-pullup/new-pullup.component';
import { TranslateService } from '@ngx-translate/core';
import { environment as ENV } from "src/environments/environment";
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from "@capacitor/core";
import { trigger, transition, style, animate, state } from '@angular/animations';
import { MapComponent } from '../map/map.component';
import { CameraOptionsComponent } from './camera-options/camera-options.component';
import { CAMERA_OPTIONS_OBJ } from '../map/class/features/routeSliderAnimation';
import * as turf from '@turf/turf';
import { MainMapInterface } from '../map/main-map.interface';
import { MapSliderService } from 'src/app/services/map-slider-service';

export const transitionAll = trigger('transitionAll', [
  transition('* => *', [
    style({ transition: 'all 1s ease-out' }), // Initial state
    animate('1s ease-out', style({})), // Final state and animation duration
  ]),
]);
export const fadeInOut = trigger('fadeInOut', [
  state('void', style({
    opacity: 0,
    transform: 'scale(0.5)'
  })),
  transition('void <=> *', animate('300ms ease-in-out')), // Adjust duration and easing function
])


Chart.register(CategoryScale, LinearScale, LineController,
  PointElement, LineElement, BarController, BarElement, Filler);
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [fadeInOut, transitionAll]

})
export class  SliderComponent implements OnInit, AfterViewInit, OnDestroy {
  _dataPoints;
  startDate: any = moment(); //for test
  endDate: any = moment(); //for
  minDate: any = new Date().toISOString(); //for test
  maxDate: any = new Date().toISOString(); //for test
  @Input() get dataPoints() { return this._dataPoints };
  @Input() get sliderDevice() { return this._sliderDevice };
  @Input() controls = { next: true, prev: true, close: true, play: true, popup: true };
  @Input() pullupMenu = null;
  @Output() onClose = new EventEmitter<{}>();
  @Output() onHideDataPoints = new EventEmitter<{}>();
  @Output() sliderChanged = new EventEmitter<{}>();
  @Input() map: MainMapInterface;
  @Input() stepSlider = 0;
  @Input() maxSliderPoints = 0;
  @Input() triggerClearDeviceRoute: boolean = false;
  @Input() isManualRoute: boolean = false;
  @Input() loadingSnapRoute = false;
  @Input() snapToRoad = true;
  @Input() splitRouteSlider = false;
  @Input() logbookRoute:any = {};
  @Input() applyAnimation = true;
  @Input() currentPoint:any = {};
  @Output() currentPointChange: EventEmitter<any> = new EventEmitter<any>();
  set dataPoints(value) {
    this.stepSlider = 0;
    this._dataPoints = value;
    this.playing = false;
    this.stopTimer();
    // console.warn('DATAPOINTS CHANGED');
    this.map?.RouteSliderAnimationClass?.removeSources();
    //this.addDistances();
    if (this.chart)
      this.ngAfterViewInit();
  }

  _sliderDevice = null;
  set sliderDevice (value) {
    this._sliderDevice = value;
    // console.warn('SLIDER DEVICE CHANGED')
    this.map?.RouteSliderAnimationClass?.removeSources();
    this.mapSliderService.sliderDevice.next(value);
    if (value) {
      this.stepSlider = 0;
      this.stopTimer();
      if (this.applyAnimation)
      {
        setTimeout(() => this.updateSliderFeatureData(), 200);
      }
      // this.generatingAnimation = true;
    }
  }

  @ViewChild('chartCanvas', { static: false }) chartCanvas: ElementRef;
  chart: any;
  playing = false;
  playTimer = null;
  enablePopup = false;
  singleDevice = null;
  distanceUpdated = null;
  selectedCamera = CAMERA_OPTIONS_OBJ.center;

  deviceMenuOpen: boolean = true;
  menuOpen: boolean = false;
  distance_unit = 'km';
  keyboardPromises = {};
  showSlider = false;
  isMobile: boolean
  obs = {};
  @Input() durations: Array<any> = [{ value: 4000, title: "1x" }, { value: 2000, title: "2x" }, { value: 800, title: "5x" }];

  currentDurationIndex: number = 0;
  featureMapClicked = false;
  DISTANCE_FACTOR_TO_IGNORE_POINT_FOR_ANIMATION = 100; //_ In meters
  generatingAnimation = false;
  disablePlay = false;

  constructor(private mapService: MapService, private modalCtrl: ModalController, private platform: Platform,
    private devicesService: DeviceDataService, private appService: AppService,
    private routeService: RouteService, private translate: TranslateService, private popover: PopoverController,
    private mapSliderService: MapSliderService) {
    this.appService.deviceMenuisOpen.asObservable().subscribe(status => {
      this.deviceMenuOpen = status;
    });
    this.appService.menuClosed.asObservable().subscribe(status => {
      this.menuOpen = status;
    });

    this.appService.darkMode$.subscribe(status => {
      this.loadChart();
      //this.menuOpen = status;
    });

  }

  ngOnInit() {
    this.addDistances();
    this.isMobile = this.platform.width() < 768 ? true: false
    this.distance_unit = this.appService.user ? this.appService.user.distance_unit : "km";
    this.distanceUpdated = this.routeService.distanceUpdated.subscribe((device: any) => {
      if (device.id == this.sliderDevice.id)
       //this.addDistances(1);

        if(this.snapToRoad){
          this.updateDistances()
        }
        else{
          this.addDistances(1);
        }
    });

    this.showSlider = true;

    if (Capacitor.isNativePlatform()) {
      this.keyboardPromises['keyboardShow'] = Keyboard.addListener('keyboardWillShow', info => {
        // console.log('[LOGSLIDER] keyboard will show with height:', info.keyboardHeight);
        this.showSlider = false;
      });
      this.keyboardPromises['keyboardHide'] = Keyboard.addListener('keyboardWillHide', () => {
        // console.log('[LOGSLIDER] keyboard will hide');
        this.showSlider = true;
      });

      // this.obs['keyboardShow'] = this.keyboard.onKeyboardWillShow().subscribe(r => {
      //   // console.log('Keyboard is open');
      //   this.showSlider = false;
      // });
      // this.obs['keyboardHide'] = this.keyboard.onKeyboardWillHide().subscribe(r => {
      //   // console.log('Keyboard is closed');
      //   this.showSlider = true;
      // });
    }

    this.obs['SelectSliderPoint'] = this.routeService.selectSliderPoint.subscribe((point: any) => {
      this.featureMapClicked = true;
      if (point.deviceId == this.sliderDevice.id)
        this.selectSliderPoint(point);
    });

    this.obs['closeSlider'] =  this.mapService._closeSlider.subscribe(closeSlider => {
      if (closeSlider)
        this.hideSlider();
    });

    if (this.applyAnimation) {
      // this.generatingAnimation = true;
      this.obs['waitAnimationPointsCreationg'] =  this.mapSliderService.generatingIndexes.subscribe(res => {
        // console.log('GENERATING ANIMATION', res);
        this.generatingAnimation = res;
      });

      this.obs['generatingAnimationError'] =  this.mapSliderService.error.subscribe(res => {
        this.disablePlay = res;
      });
    }


    // this.mapService.sliderSnapedPointsReady.subscribe((obj: any) => {
    //   this.stopTimer();
    // });

    this.obs['indexChanged'] = this.mapSliderService.indexChanged.subscribe((currentIndex: any) => {
      this.stepSlider = currentIndex;
      // console.log('SLIDER index from animation changed', { currentIndex, stepSlider: this.stepSlider, dpLength: this.dataPoints.length })
      if (this.stepSlider >= this.dataPoints.length-1) {
        this.playing = false;
        this.stopTimer();
      }

      //_Show popup if is enabled
      const ev = { showPopup: this.enablePopup, detail: { value: this.stepSlider }};
      this.sliderChanged.emit(ev);
    });

  }
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.isMobile = window.innerWidth < 768 ? true: false;

  }

  ngOnDestroy() {
    this.map?.RouteSliderAnimationClass?.removeSources();
    this.distanceUpdated.unsubscribe();
    Object.keys(this.keyboardPromises).forEach(k => this.keyboardPromises[k].remove());
    Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
  }

  ngAfterViewInit() {
    this.loadChart();
    // this.listenForNewPoints(); //_ Not lisent for live tracking to add

    //_ Pulldown the menu, hide keyboard and remove blur the last input focused
    if (this.pullupMenu && this.pullupMenu instanceof NewPullupComponent) {
      // this.pullupMenu.moveToBreak('top');
      if (this.isMobile) {
        this.mapService.showPullup.next(false)
        this.appService.deviceMenuisOpen.next(false)
      }

      // this.appService.deviceMenuisOpen.next(false)
      if (Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
      if (this.pullupMenu.lastInputFocus) {
        this.pullupMenu.lastInputFocus.blur();
        this.pullupMenu.lastInputFocus.getInputElement().then(inputElement => inputElement.blur());
      }
    }
  }


  loadChart() {
    if (this.dataPoints) {
      if (this.chart)
        this.chart.destroy();

      this.sliderDevice?.dataPoints.forEach((data) => data.dateDE = moment.unix(data.dateunix).locale(this.appService.language).format("DD. MMMM YYYY"));
      let speedLabel = this.translate.instant("dashboard.speed.name");
      let distanceLabel = this.translate.instant("dashboard.charts.distance")
      const data = createData(this.sliderDevice.dataPoints, this.sliderDevice.color, speedLabel, distanceLabel);
      let distance_unit = this.translate.instant('speed.' + this.distance_unit + 'H');

      let config = createConfig(data, null, distance_unit, speedLabel);
      // console.log('CREATE SLIDER CHART', { data, config })
      this.chart = new Chart(this.chartCanvas.nativeElement, config);
      //this.totaldistance();
    }
  }



  dist = 0;
  lastdist = 0;
  addDistances(setSlider=0) {
    if(setSlider){
      // slider opened so setting device id so the value does not get ovverridden
     this.devicesService.sliderDeviceId = this.dataPoints[0]?.iddevice;
    }
    let lastPoint = null;
    let lastDistance = 0;
    let device = null;
    if (this.dataPoints) {
      this.dataPoints.sort((a, b) => a.dateunix > b.dateunix ? 1 : -1);
      device = this.appService.devices.find(device => device.id == this.dataPoints[0].iddevice);
    }
    let count = 0;
    this.dataPoints.forEach((value, i) => {
      if (i == 0) {
        lastPoint = value;
      }
      //if (this.snapToRoad) {
        //let distance = getDistance(lastPoint.lat, lastPoint.lng, value.lat, value.lng, 'K');
        //console.log("distance in add didtsnce : ", distance)
        // let distancePercentage = (distance / device?.tripDistance) * 100; // need to improve this to get exact value
        // distance = (distancePercentage / 100) * device?.optimizedDistance;
        // lastDistance += distance ? distance : 0;
      //}
     // else {
        if (value.lat !== lastPoint.lat && value.lng !== lastPoint.lng) { // maybe we need to use this for devices having issues
          let distance = getDistance(lastPoint.lat, lastPoint.lng, value.lat, value.lng, 'K');
           if (distance > DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS / 1000) // need to check this
             distance = 0;

             if(this.splitRouteSlider){
               if (lastDistance > this.logbookRoute.optimized_distance)
                    distance = 0;
            }
          lastDistance += distance ? distance : 0;
          }
      //}
      // // if (value.lat !== lastPoint.lat && value.lng !== lastPoint.lng) { // maybe we need to use this for devices having issues
      //   let distance = getDistance(lastPoint.lat, lastPoint.lng, value.lat, value.lng, 'K');
      //   // if (distance > DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS / 1000) // need to check this
      //   //   distance = 0;
      //
      //   let distancePercentage = (distance / device?.tripDistance) * 100; // need to improve this to get exact value
      //   distance = (distancePercentage / 100) * device?.optimizedDistance;
      //   lastDistance += distance ? distance : 0;
      // // }

      lastPoint = value;
      this.dataPoints[i].distance = this.convertDistance(lastDistance);
      this.lastdist = this.convertDistance(lastDistance);
    });
    if(this.splitRouteSlider){
      this.dataPoints[this.dataPoints.length - 1].distance = this.logbookRoute.optimized_distance;
    }
    // this.routeService.distanceUpdated.next(this.lastdist);
  }
  updateDistances(){
      const device = this.appService.devices.find(device => device.id == this.dataPoints[0].iddevice);
      let diff = device.tripDistance - device.optimizedDistance;
      let percentage = (Math.abs(diff) * 100) / device.tripDistance;
      let distancePercentage = percentage / 100
      this.dataPoints.forEach((value, i) => {
        this.dataPoints[i].distance = this.dataPoints[i].distance * (1 + distancePercentage);
        this.dataPoints[i].distance = Math.min(this.dataPoints[i].distance, device.optimizedDistance);
      });
      this.dataPoints[this.dataPoints.length - 1].distance = device.optimizedDistance
  }

  moveButton(className, style) {
    let button = document.querySelector('.' + className);
    button.setAttribute('style', style);
  }
  //_ Hide the slider and move the buttons in the bottom of the map
  hideSlider() {
    this.playing = false
    this.stopTimer();
    this.onClose.emit(true);
    this.mapService.showSlider.next(false);
    // if (this.applyAnimation)
    //   this.map?.RouteSliderAnimationClass?.removeSources();
    if (this.pullupMenu && this.pullupMenu instanceof NewPullupComponent)
      this.pullupMenu.moveLayoutFix(this.pullupMenu.lastBreakChanged, false);
    if(this.triggerClearDeviceRoute){
      this.onHideDataPoints.emit();
    }
  }

  onSliderChanged(ev) {
    ev.showPopup = this.enablePopup;
    this.sliderChanged.emit(ev);

    if (this.applyAnimation) {
      // if (!this.featureMapClicked)

      //_ only update the routeaniation if is not playing
      if (!this.playing) {
        this.map?.RouteSliderAnimationClass.changeIndex(this.sliderDevice, {
          currentIndex: this.stepSlider,
          isPlaying: this.playing,
          duration: this.durations[this.currentDurationIndex].value,
          selectedCamera: this.selectedCamera.name
        });
        this.currentPointChange.emit(this.sliderDevice.dataPoints[this.stepSlider]);
      } else {
        this.map?.RouteSliderAnimationClass?.changeAnimationIndex(ev.detail.value);
      }
      this.featureMapClicked = false;
    }
  }

  onRangeChange (ev) {
    //_ Stop and start again, to prevent fly after user clicks on the ion-range
    if (this.playing) {
      this.stopTimer();
      this.startInterval();
    }
  }

  updateSliderFeatureData() {
    // console.log('UPDATE SLIDER ANIMATION FEATURE', { currentIndex: this.stepSlider,
    //   isPlaying: this.playing,
    //   duration: this.durations[this.currentDurationIndex].value,
    //   selectedCamera: this.selectedCamera.name });
    if (!this.isManualRoute) {
      this.playing = false;
      this.stepSlider  = 0;
      this.map?.RouteSliderAnimationClass.updateDataSource(this.sliderDevice, {
        currentIndex: this.stepSlider,
        isPlaying: this.playing,
        duration: this.durations[this.currentDurationIndex].value,
        selectedCamera: this.selectedCamera.name
      });
    } else {
      this.appService.showToast('', 'Animation not available for manual routes.', 3000, 'warning');
    }
  }

  next() {
    if (this.stepSlider < this.maxSliderPoints) {
      this.stepSlider++;
      this.onSliderChanged({ detail: { value: this.stepSlider } });
    } else if (this.playing) {
      this.playing = false;
      this.stopTimer();
      this.stepSlider = 0;
    }
  }

  async selectDate() {
    if (this.dataPoints.length > 0) {
      const deviceId = this.dataPoints[0].iddevice;
      this.devicesService.dateRange.next({ id: deviceId, open: true });
    }
  }

  back() {
    if (this.stepSlider > 0) {
      this.stepSlider--;
      this.onSliderChanged({ detail: { value: this.stepSlider } });
    }
  }

  setDuration() {
    this.currentDurationIndex++;
    // If the index exceeds the array length, set it back to 0
    if (this.currentDurationIndex >= this.durations.length) {
      this.currentDurationIndex = 0;
    }
    if (this.applyAnimation)
      this.map?.RouteSliderAnimationClass?.setDuration(this.durations[this.currentDurationIndex].value);
  }

  play() {
    this.playing = !this.playing;
    if (this.playing) {
      if (this.stepSlider === this.dataPoints.length-1)
        this.stepSlider = 0;

      if (this.applyAnimation)
        this.map?.RouteSliderAnimationClass?.startAnimation(this.sliderDevice, {
          currentIndex: this.stepSlider,
          isPlaying: this.playing,
          duration: this.durations[this.currentDurationIndex].value,
          selectedCamera: this.selectedCamera.name
        });
       else {
         this.next();
         this.stopTimer();
         this.startInterval()
      }


      // this.next();
      // this.stopTimer();
      // this.startInterval()
    }
    else {
      this.stopTimer();
    }
  }

  startInterval() {
    this.playTimer = setInterval(() => {
      this.stopTimer();
      this.startInterval();

      this.next();
      //_ Call next until get a point no so close - in case next current point with previous distance is too short
      //_ This prevent stop or stuck in some points when datapoints are near between others
      // let jumpedSliderPoints = 0;
      // if (this.isDistanceLessThanFactor(this.stepSlider) && this.stepSlider < this.dataPoints.length-1)
      //   this.next();
        // while (this.isDistanceLessThanFactor(this.stepSlider) && jumpedSliderPoints < 3) {
        //   jumpedSliderPoints += 1;
        //   this.next();
        // }
      // }
    }, this.durations[this.currentDurationIndex].value);
  }

  stopTimer() {
    if (this.playTimer) {
      clearInterval(this.playTimer);
      this.playTimer = null;
    }
    if (this.applyAnimation) {
      this.map?.RouteSliderAnimationClass.onPause({
        currentIndex: this.stepSlider,
        isPlaying: false,
        duration: this.durations[this.currentDurationIndex].value,
        selectedCamera: this.selectedCamera.name
      });
    }
    // if (this.playTimer)
    //   clearInterval(this.playTimer);
  }

  showPopup() {
    this.enablePopup = !this.enablePopup;
    this.onSliderChanged({ detail: { value: this.stepSlider } });
  }

  handleMapEvent(eventData: any) {
    // Do something with the received data
    this.enablePopup = !eventData.closed
  }

  listenForNewPoints() {
    //_ Listen for device Moved to add new point to the slider
    this.devicesService.deviceMoved$.subscribe(d => {

      if (d.id == this.sliderDevice.id && this.checkIfShouldAddLastPoint(d.properties)) {
        const label = moment.unix(d.lastPoint.dateunix).format('HH:mm');
        const clastPoint = this.dataPoints[this.maxSliderPoints];
        const newPoint = d.lastPoint;
        let distance = getDistance(newPoint.lat, newPoint.lng, clastPoint.lat, clastPoint.lng, 'K');

        if (distance > DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS / 1000)
          distance = 0;

        newPoint.distance = distance ? (clastPoint.distance + distance) : clastPoint.distance;
        newPoint.distance = this.convertDistance(newPoint.distance);
        newPoint.dateDE = moment.unix(newPoint.dateunix).locale(this.appService.language).format("DD. MMMM YYYY")

        this.addPoint(label, newPoint.speed, newPoint.distance);
        this.maxSliderPoints += 1;
        this.dataPoints.push(newPoint);
      }
    })
  }

  checkIfShouldAddLastPoint (device) {
    //_ Spurmodus = 0 and 1 for last minutes/points
    if (device.spurmodus < 2){
      return true;
    } else if (device.spurmodus === 4) {
      const now = moment()
      const endDate = moment.unix(device.spurdatumbis)
      //_ Only add last point in case is same day and is greather than now
      if (endDate.isSame(now, 'day') && endDate.isAfter(now)) {
        return true;
      }
    }
    return false;
  }

  addPoint(label, speed, distance) {
    this.chart.data.labels.push(label);
    this.chart.data.datasets[0].data.push(speed);
    //_ enable it if distance dataset is shown in the chart again
    // this.chart.data.datasets[1].data.push(distance);
    this.chart.update();
  }

  convertDistance(value) {
    return this.distance_unit == 'km' ? value : value / 1.609344;
  }

  selectSliderPoint(point) {
    const pointIndex = this.dataPoints.map(p => p.id).indexOf(point.directionId);
    if (this.dataPoints[pointIndex]) {
      this.stepSlider = pointIndex;
      this.onSliderChanged({ detail: { value: this.stepSlider } });
    }
  }

  async cameraOptions(ev) {
    let popover = await this.popover.create({
      componentProps: { selectedItem: this.selectedCamera.name },
      component: CameraOptionsComponent,
      event: ev,
      mode: "ios",
      backdropDismiss: true,
      showBackdrop: true,
    });

    await popover.present();
    const { data } = await popover.onWillDismiss();
    if (data) {
      this.selectedCamera = CAMERA_OPTIONS_OBJ[data]
    }
  }

  isDistanceLessThanFactor(currentIndex) {
    const pEnd = this.sliderDevice.dataPoints[currentIndex];
    const pStart = this.sliderDevice.dataPoints[currentIndex-1];
    if (pStart) {
      const pA = [pStart.lng, pStart.lat];
      const pB = [pEnd.lng, pEnd.lat];
      const distance = turf.distance(pA, pB, { units: 'meters' });
      if (distance <= this.DISTANCE_FACTOR_TO_IGNORE_POINT_FOR_ANIMATION) {
        return true;
      }
    }

    return false;
  }

}
