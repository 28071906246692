import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { MapMinComponent } from 'src/app/members/generate-video/map-min/map-min.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HumanizeTimeService } from 'src/app/services/humanize-time.service';
import { DashboardTimelineService } from './timeline-service';
import { IonContent, ModalController, PopoverController } from '@ionic/angular';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TimelineDataSource } from './util/timeline-Datasource';
import { SelectSearchableComponent } from 'src/app/components/device-picker/select-searchable.component';
import { AllDevicesItem, TimelineCollectionTypes } from './util/util';
import { DevicesDashboardService } from '../devices-dashboard.service';
import moment from 'moment';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { DeviceDataService } from '../../../devicesData.service';
import { RouteService } from '../../../devices-sidebar/device-item/device-submenu/route/route.service';
import { STORAGE_SETTINGS } from 'src/app/services/storage-settings';
import { StorageService } from 'src/app/services/storage.service';
import { ActivityService } from '../devices-activity/activity.service';

export enum ScrollDirection { UP = 'up', DOWN = 'down' }
@Component({
  selector: 'app-devices-timeline',
  templateUrl: './devices-timeline.component.html',
  styleUrls: ['./devices-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceDashboardTimelineComponent  implements OnInit, AfterViewInit, OnDestroy {
  userId = this.appService.user.id;
  timelineList = [];
  currentHeader: string;
  @ViewChild('map') map: MapMinComponent;
  @ViewChild('content') content: IonContent;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  loadingData = false;

  hasMoreData = true;
  paginationData = { startDateRoutes: null, startDateAlarms: null, endDateRoutes: null, endDateAlarms: null, requestLatests: true, requestOldest: true };
  lastDateRange = this.timelineService.lastDateRange;
  loadingMoreData = false;
  dataSource = new TimelineDataSource<{}>();
  selectedDevice = null;
  devices= [];
  timelineTypes = TimelineCollectionTypes;
  selectedCollectionType = TimelineCollectionTypes[0];
  requestLastRoutesDoneSubs = null;
  timeLineCharged:number = 0;
  cmpDestroyed = new Subject();
  language = this.appService.language == 'en_US' ? 'en' : this.appService.language;
  flatTimelineList = [];
  isLastPage = false;
  constructor(private authService: AuthenticationService, private appService: AppService,
              private humanizeService: HumanizeTimeService, private cdr: ChangeDetectorRef,
              private timelineService: DashboardTimelineService, private modalCtrl: ModalController,
              private dashboardService: DevicesDashboardService, private devicesService:DeviceDataService,
              private routeService: RouteService, private activityService: ActivityService,
              private storage: StorageService) {

              }

  ngOnInit() {
    //console.log('SELECTED ITEM COLLECTION', { selectedCollectionType: this.selectedCollectionType, timelineTypes: this.timelineTypes });
    this.appService.language$.pipe(takeUntil(this.cmpDestroyed)).subscribe(lang => {
      this.language = lang == 'en_US' ? 'en' : lang;
    })
  }

  async ngAfterViewInit(){
    this.devices = await this.dashboardService.filterLogbookDevices(this.appService.devices.map( d => { return { ...d, device: d, customer_id: this.appService.userId } }));
    // this.devices.unshift(AllDevicesItem);

    this.selectedDevice = await this.storage.get(STORAGE_SETTINGS.DEVICE_ACTIVITY_SELECTED_DEVICE);

    if (!this.selectedDevice && this.devices.length > 0){
      this.selectedDevice = this.devices[0];
      this.activityService.DEVICE_ACTIVITY_SELECTED_DEVICE.set(this.selectedDevice);
    }

    this.timelineService.setMap(this.map);
    this.requestLastRoutesDoneSubs = this.dashboardService.requestLastRoutesDone
    .pipe(filter(state => state === true), take(1)).subscribe(state => {
      //console.log('TIMELINE INIT *', {state, date: new Date().toISOString()});
      if (state)
        this.getTimlineData();
    });
  }


  getTimlineData(startDateRoutes=null, startDateAlarms = null, direction = ScrollDirection.DOWN, loadMore = false, event = null){
    this.loadingMoreData = loadMore;
    this.loadingData = true;
    //_ Reset the list
    if (startDateRoutes == null)
      this.timelineList = [];

    this.paginationData.requestLatests = true;
    this.paginationData.requestOldest = true;
    this.cdr.markForCheck();
    
    //_ Vars for filters
    const devicesIds = this.selectedDevice.id != -1 ? this.selectedDevice.id : this.devices.filter(d => d.id !== -1).map(d => d.id);
    const collectionType = this.selectedCollectionType.backendName;
    this.authService.getDashboardTimeline(this.userId, { startDateRoutes, startDateAlarms, itemsPerPage: 5, direction, devicesIds, collectionType }).subscribe((res: any) => {
      //_ If return data, then fix the pagination
      this.setupPagination(res, direction, startDateRoutes, startDateAlarms);

      if (startDateRoutes == null || this.timelineList.length == 0){
        //_ Code to use virtual scroll and data source
        // let data = this.dataSource.getData();
        // data = res.data.reverse();
        // this.dataSource.setData(data);
        // this.timelineList = data;
         // setTimeout(() => this.virtualScroll.scrollTo( {bottom: 300 } ), 200);

        //_ Code to use normal array without virtual scroll
        this.timelineList = res.data.reverse();
        this.flatTimelineList = res.data.reduce((acc, entry) => acc.concat(entry.items), []);
        this.isLastPage = false;
        // setTimeout(() => this.content.scrollByPoint(0, 300, 0), 50);
        this.timeLineCharged++;        
      }
      else{
        this.addNewDataToTimeLine(res.data, direction);
        // this.virtualScroll.checkViewportSize();
      }

      this.loadingData = false;
      this.loadingMoreData = false;

      //_ Complete the event of the ion-infinite-scroll
      if (event)
        event.target.complete();

      this.cdr.markForCheck();
      // this.virtualScroll.scrollToIndex(0, 'auto');
    }, (error) => {
      this.loadingData = false;
      this.loadingMoreData = false;
      this.cdr.markForCheck();
      console.log('ERROR getting Dashboard timeline', error);
    });
  }

  addNewDataToTimeLine(newData: any, direction = ScrollDirection.DOWN){
    if (direction == ScrollDirection.DOWN) newData = newData.reverse();

    const oldFlatList = this.timelineList.reduce((acc, entry) => acc.concat(entry.items), []);
    for (let i = 0; i < newData.length; i++) {
      const dateunix = newData[i].dateunix;
      const matchedSubArray = this.timelineList.find(g => g.dateunix == dateunix);
      const indexOfSubArray = this.timelineList.map(g => g.dateunix).indexOf(dateunix);
      const currentList = JSON.parse(JSON.stringify(this.timelineList));

      console.log('ADD NEW DATA TO TIMELINE', { dateunix, matchedSubArray, indexOfSubArray, currentList });
      if (matchedSubArray) {
        if (direction == ScrollDirection.UP){
          newData[i].items = this.deleteExistentItems(newData[i].items);
          if (newData[i].items.length > 0)
            //this.timelineList[indexOfSubArray].items = [newData[i].items, ...this.timelineList[indexOfSubArray].items];
            this.timelineList[indexOfSubArray].items.unshift(...newData[i].items);
        }
        else{
          newData[i].items = this.deleteExistentItems(newData[i].items);
          newData[i].items.forEach(newItem => {
            const existsItem = this.timelineList[indexOfSubArray].items.find(it => it._id === newItem._id);
            if (!existsItem)
              this.timelineList[indexOfSubArray].items.push(newItem);
          })
        }
      } else {
        if (direction == ScrollDirection.UP)
          this.timelineList.unshift(newData[i]);
        else
          this.timelineList.push(newData[i]);
      }

      const newFlatList = this.timelineList.reduce((acc, entry) => acc.concat(entry.items), []);
      // console.warn('DEBUG - newFlatList 1', { isLastPage: this.isLastPage, newFlatList, timelineList: this.timelineList.length, flatTimelineList: this.flatTimelineList.length })
      // if (this.flatTimelineList.length === newFlatList.length)
      //   this.isLastPage = true;

      this.flatTimelineList = JSON.parse(JSON.stringify(newFlatList));         
      // console.warn('DEBUG - newFlatList 2', { isLastPage: this.isLastPage, newFlatList, timelineList: this.timelineList.length, flatTimelineList: this.flatTimelineList.length })
    }

    // console.warn('DEBUG - addNewTimeline data', { flatTimelineList: this.flatTimelineList, newData, oldFlatList, isLastPage: this.isLastPage })
    if (this.flatTimelineList.length === oldFlatList.length)
      this.isLastPage = true;
    //_ Code to use angular cdk virtual scroll with datasource
    //_ Needs to fix when adding new items to bottom scroll is reset to the top :/
    // let currentData: any = this.dataSource.getData();
    // for (let i = 0; i < newData.length; i++) {
    //   const dateunix = newData[i].dateunix;
    //   const matchedSubArray = currentData.find((g: any) => g.dateunix == dateunix);
    //   const indexOfSubArray = currentData.map((g: any) => g.dateunix).indexOf(dateunix);

    //   if (matchedSubArray) {
    //     if (direction == ScrollDirection.UP)
    //       currentData[indexOfSubArray].items.unshift(...newData[i].items);
    //     else
    //       currentData[indexOfSubArray].items = currentData[indexOfSubArray].items.concat(...newData[i].items);
    //   } else {
    //     if (direction == ScrollDirection.UP)
    //       currentData.unshift(newData[i]);
    //     else
    //       currentData.push(newData[i]);
    //   }
    // }

    // this.dataSource.setData(currentData);
    // this.timelineList = currentData;
    return this.timelineList;
  }

  setupPagination(res, direction, startDateRoutes, startDateAlarms){
    this.paginationData.startDateRoutes = res.startDateRoutes;
    this.paginationData.startDateAlarms = res.startDateAlarms;
    this.paginationData.endDateRoutes = res.endDateRoutes;
    this.paginationData.endDateAlarms = res.endDateAlarms;

    //_ Fix alarm|route dates in case one or the other dates are null (means not returned that kind of data in last request)
    if (res.startDateRoutes && !res.startDateAlarms)
      this.paginationData.startDateAlarms = res.startDateRoutes;
    if (res.endDateRoutes && !res.endDateAlarms)
      this.paginationData.endDateAlarms = res.endDateRoutes;
    if (res.startDateAlarms && !res.startDateRoutes)
      this.paginationData.startDateRoutes = res.startDateAlarms;
    if (res.endDateAlarms && !res.endDateRoutes)
      this.paginationData.endDateRoutes = res.endDateAlarms;

    // console.log('TIMELIST DATA', this.timelineList);
  }

  onItemInView(route){

  }

  close(){
    this.modalCtrl.dismiss();
  }

  loadMoreData(ev, direction){
    //_ Wait previous request for data ends to trigger other request
    if (this.loadingMoreData || this.isLastPage)
      return;

    // this.currentScrollDirection = direction;
    const dates = {
      dateForRoutes: this.getDateToRequest(direction, 'routes'),
      dateForAlarms: this.getDateToRequest(direction, 'alarms'),
    }
    if (direction == ScrollDirection.UP)
      this.getTimlineData(dates.dateForRoutes, dates.dateForAlarms, direction, true, ev);
    else {
      this.getTimlineData(dates.dateForRoutes, dates.dateForAlarms, direction, true, ev);
    }
  }

  //_ The the dateunix of the first or last item of the list; to load more data
  getDateToRequest(direction, type: 'alarms' | 'routes' = 'routes'){
    if (type == 'routes') {
      if (direction == ScrollDirection.UP)
        return this.paginationData.endDateRoutes + 1
      else
        return this.paginationData.startDateRoutes - 1;
    } else {
      if (direction == ScrollDirection.UP)
        return this.paginationData.endDateAlarms + 1
      else
        return this.paginationData.startDateAlarms - 1;
    }
  }

  trackByItemDateunix(index: number, item: any): number {
    return item.dateunix;
  }

  collectionTypeChanged(ev){
    this.selectedCollectionType = ev;
    this.getTimlineData();
  }

  deviceChanged(ev){
    this.timeLineCharged = 1;
    // this.routeService.updateRoute({
    //   dismissed: true,
    //   datapoints: this.devicesService.devices[this.selectedDevice.id].previousDataPoints,
    //   rangeMode: this.devicesService.devices[this.selectedDevice.id].properties.spurmodus,
    //   deviceId: this.selectedDevice.id,
    //   onlyUpdateRoute: true,
    //   clearRouteMarkers: true
    // });

    this.selectedDevice = ev;
    this.getTimlineData();
  }

  deleteExistentItems(newItems){
    const filteredItems = [];
    newItems.forEach( item => {
      if (!this.itemExistInTimelineLiist(item))
        filteredItems.push(item);
    })
    // console.log('Deleting exist items', { newItems: [...newItems], filteredItems: [...filteredItems]})
    return filteredItems;
  }

  itemExistInTimelineLiist(item){
    let flatItems = this.timelineList.map(obj => obj.items).flat();
    const exist = flatItems.find(i => i.id == item.id && i.type == item.type);
    if (!exist)
    // console.log('ITEM DOESNT EXISTS', item);
    return exist;
  }

  ngOnDestroy(): void {
    this.cmpDestroyed.next(true);
    this.cmpDestroyed.complete();

    if (this.requestLastRoutesDoneSubs)
      this.requestLastRoutesDoneSubs.unsubscribe();
  }
}
