import { Injectable } from '@angular/core';
import { NativeGeocoder } from '@capgo/nativegeocoder';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class GeocoderService {

  constructor(private platform: Platform) { }
  async reverseGeocode(latitude: number, longitude: number) {
    //console.log('reverseGeocode', this.platform.is("hybrid"))
    if (this.platform.is("hybrid")) {
      const options: any = {
        useLocale: true,
        maxResults: 5,
        latitude: latitude,
        longitude: longitude,
      };
      let result = await NativeGeocoder.reverseGeocode(options)
      //console.log('reverseGeocode', result)
      return result

    } 



  }
}
