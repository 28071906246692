import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {   AfterViewInit, Directive,  ElementRef,  EventEmitter,    HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { isElementInViewport } from '../members/map/devices-sidebar/devices-list/list/list-helper';
import { NewPullupService } from '../members/map/new-pullup/pullup.service';

@Directive({
  selector: '[infiniteFix]',
})
export class InfiniteScrollFixDirective implements OnInit, OnDestroy, AfterViewInit {

  @Output()ionInfinite = new EventEmitter();
  @Input("infiniteFix") get infiniteFix () { return this._infiniteFix; }
  @Input("spinner") spinner:any = null;
  spinnerEl = null;

  previousLength = null;
  userScrolled = false;
  scrollerContent = null;
  @Input("isVirtualScroll") isVirtualScroll = false;
  touchStartPosY = 0;
  triggeredIonInfinite = null;

  virtualElementTag = 'cdk-virtual-scroll-viewport'
  private _infiniteFix:any[] = null;
  //_ Added support for Angular Virtual Scroll list
  constructor(private element: ElementRef, private platform: Platform,private pullupService:NewPullupService){
    // console.log('ELEMENT', element.nativeElement)
  }

  async ngOnInit(){
    const contentEl = this.element.nativeElement.closest('ion-content');
    this.scrollerContent = this.isVirtualScroll ? this.element.nativeElement.closest(this.virtualElementTag)
                           : await contentEl?.getScrollElement();

    // console.log('SCROLL CONTENT', this.scrollerContent)
    if (this.scrollerContent){
        // if (!this.isVirtualScroll)
          this.scrollerContent.addEventListener("scroll", this.fixTriggerIonInfiniteWhenScrollingBottom.bind(this));

        this.touchStartPosY = 0;
        //   this.scrollerContent.addEventListener("touchmove", (ev) => {
        //     const currentY = Math.round(ev.changedTouches[0].screenY);
        //     const swipeUp = currentY < touchStartPosY;
        //     const diffY = currentY - touchStartPosY;
        //     if (swipeUp && diffY) this.checkLoad();
        //   });

          this.scrollerContent.addEventListener("touchstart", (ev) => {
            this.touchStartPosY = Math.round(ev.changedTouches[0].screenY);
          });

          this.scrollerContent.addEventListener("touchend", (ev) => {
            this.userScrolling(ev);
          });
    }
    this.pullupService.breakPointChanged.subscribe(()=>{
      this.checkLoad()
    })
  }

  ngAfterViewInit() {
    if (this.spinner){
      this.spinnerEl = this.spinner.el;
    }
    setTimeout(() => this.checkLoad(), 1000);

  }

  @HostListener('window:resize', ['$event'])
  onResize(ev) {
    this.checkLoad();
  }

  checkTimer = null
  set infiniteFix(value){
    this._infiniteFix = value;
    if(!this.infiniteFix) return;

    if(!this.previousLength)
      this.previousLength = this.infiniteFix.length;

    if (this.checkTimer) clearTimeout(this.checkTimer)

    this.checkTimer = setTimeout( () => {
      if(this.infiniteFix && this.infiniteFix.length > 0 && !this.userScrolled) {
          this.checkLoad();
        }
      this.previousLength = this.infiniteFix.length;
    }, 500)
  }

  checkLoad() {
    this.checkForScrollbar().then(res => {
      if (!res) {
        // console.warn('CALLING EMIT 1')
        this.ionInfinite.emit();
      } else if (this.spinnerEl){

        //_ Other way to fix when loading spinner not hidded and the list has scrollbars
        // console.log('IS IN VIEW PORT', isElementInViewport(this.spinnerEl))
        if (isElementInViewport(this.spinnerEl)){
          // console.warn('CALLING EMIT 3')
          this.ionInfinite.emit();
        }
      }
    });
  }

  async checkForScrollbar(): Promise<boolean> {
    // console.log('CHECK FOR SCROOLS', { element: this.element.nativeElement, isVirtual: this.isVirtualScroll})
    const contentEl = this.element.nativeElement.closest('ion-content');
    let scrollEl = this.isVirtualScroll ? this.element.nativeElement.closest(this.virtualElementTag)
                   : await contentEl?.getScrollElement();
                   console.warn('SCROLLBARS', {...scrollEl });

    const spinnerHeight = this.spinnerEl?.clientHeight ?? 0;
    return scrollEl?.scrollHeight> (scrollEl?.clientHeight + spinnerHeight);
  }

  userScrolling(ev){

    let scrollingUp = ev.deltaY > 0;
    if (ev.changedTouches)
      scrollingUp = this.touchStartPosY > ev.changedTouches[0].screenY;

    this.touchStartPosY = 0;
    this.userScrolled = scrollingUp;
    if (scrollingUp && (this.platform.is('ios') || this.platform.is('android'))) {
      this.ionInfinite.emit();
      // console.warn('CALLING EMIT 4')
    }
  }

  //_ Ionic 6 issue fix; not fired ionInfinite while draggin to the end of ion-content scroller element
  async fixTriggerIonInfiniteWhenScrollingBottom(ev){
    const contentEl = this.element.nativeElement.closest('ion-content');
    if (!contentEl) return;
    let scrollEl = this.isVirtualScroll ? this.element.nativeElement.closest(this.virtualElementTag)
                   : await contentEl.getScrollElement();

    const scrollHeight = (scrollEl.scrollHeight - scrollEl.clientHeight);
    const threshold = 100;

    //_ If scroll is near the bottom and the spinneris in view port; then trigger infinite method
    if (scrollEl.scrollTop + threshold >= scrollHeight)
      if (isElementInViewport(this.spinnerEl)){
        if (this.triggeredIonInfinite) clearTimeout(this.triggeredIonInfinite);
        this.triggeredIonInfinite = setTimeout(() => {
          // console.warn('CALLING EMIT 1')
          this.ionInfinite.emit();
        }, 100);
      }

  }

  ngOnDestroy(){
    if (this.scrollerContent)
        this.scrollerContent.removeEventListener("scroll", this.fixTriggerIonInfiniteWhenScrollingBottom.bind(this));
  }
}
