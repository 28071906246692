<ion-header>
  <ion-item lines="none" color="dark">
    <ion-avatar slot="start">
      <ion-img
        src="{{ device?.properties | DeviceImage: -1 | async }}"
        style="object-fit: contain"
      ></ion-img>
    </ion-avatar>

    <ion-label>
      <h2 style="font-size: 15px" class="ion-text-wrap">
        <span
          [style.background-color]="device?.properties.spurfarbe"
          class="device-circle"
        ></span>
        {{ device?.properties.name }}
      </h2>
      <p class="text-selectable" style="font-size: 13px">
        {{ device?.properties.imei }}
      </p>
    </ion-label>
  </ion-item>
</ion-header>

<div class="container">
  <ion-label class="wifi-popup-title">{{ 'setupWifi.wifiHomeText' | translate }} {{ feature.properties.wifiIndex }}</ion-label>
  <ion-img src="assets/images/setup-wifi/wifi-home.svg"></ion-img>

  <ion-item *ngIf="deviceStatus == 'Offline'" class="device-ready-info" lines="none" mode="md">
    <ion-label class="ion-text-wrap ion-text-center">
      {{ 'setupWifi.disableInfo' | translate }}
    </ion-label>
  </ion-item>

  <ion-list
    lines="none"
    class="ion-no-padding ion-list"
    mode="md"
    [class.disable]="device?.permissions?.modify == 0"
  >
    <ion-item
      class="ion-button-list"
      title="Edit wifi home address"
      (click)="editAddress()"
      button
      [detail]="false"
      lines="none"
    >
      <ion-icon slot="start" name="create-outline" color="var(--ion-color-secondary-contrast)"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'wifihome.editAddress' | translate }}</ion-label>
    </ion-item>
    <ion-item
      class="ion-button-list"
      title="Delete this wifi home"
      button
      [detail]="false"
      lines="none"
      (click)="deleteWifiHome()"
      [disabled]="loadingDeviceStatus "
    >
      <ion-icon slot="start" name="trash-outline" color="var(--ion-color-secondary-contrast)"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'wifihome.delete' | translate }}</ion-label>
    </ion-item>

    <div class="divider"></div>
    <ion-item
      class="ion-button-list"
      title="Setup wifi home 1 or 2"
      button
      [detail]="false"
      lines="none"
      (click)="setupWifis()"
      [disabled]="loadingDeviceCommand || deviceStatus == 'Offline'"
    >
      <ion-icon slot="start" name="settings-outline" color="var(--ion-color-secondary-contrast)"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'wifihome.reset' | translate }}</ion-label>
      <ion-spinner color="primary" slot="end" *ngIf="loadingDeviceCommand"></ion-spinner>
    </ion-item>
  </ion-list>
</div>
