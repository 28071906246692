import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ThreedModels } from 'src/app/members/map/components/map/class/features/3dModels';

@Component({
  selector: 'app-request-permissions',
  templateUrl: './threed-model-picker.component.html',
  styleUrls: ['./threed-model-picker.component.scss'],
})
export class ThreedModelPickerComponent implements OnInit {
  @Input('showBackButton') showBackButton = false;
  models = ThreedModels;
  
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss()
  }

  selectItem(model){
    this.modalCtrl.dismiss({model});
  }
}
