<ion-header *ngIf="headerTemplete">
  <ng-container [ngTemplateOutlet]="headerTemplete" ></ng-container>
</ion-header>

<ion-list lines="none" class="ion-no-margin ion-no-padding" >
  <div *ngFor = "let item of data; let index = index" (click)="selectItem(item, index)">
    <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item: item, index: index }">
    </ng-container>
  </div>
</ion-list>

<ng-container [ngTemplateOutlet]="footerTemplate" ></ng-container>

<ng-template #itemView let-item="item">
  <ion-item>
    <ion-checkbox slot="start" [checked]="item.checked" color="primary" *ngIf="multiSelection"></ion-checkbox>
    <ion-checkbox slot="start" [checked]="selectedItem?.id == item.id && !multiSelection" color="primary"></ion-checkbox>
    <ion-label>{{ item.name }}</ion-label>
  </ion-item>
</ng-template>
