<ion-header>
  <ion-toolbar color="dark">
    <ion-title>{{
      "deviceManagement.defaultIcon.selectDefaultIconText" | translate
    }}</ion-title>
    <ion-buttons slot="start">
      <ion-back-button
        (click)="modelCtrl.dismiss()"
        style="display: block !important"
        back-close
        back-locale
        [disableClose]="showBackButton"
      >
      </ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="3" size-sm="3" size-md="3" size-lg="2" size-xl="2"
      *ngFor="let icon of defaultIcon"
      (click)="dismiss(icon)"
      >
      <button style="width: 100%; border-radius: 50%">
        <img src="assets/device_markers/{{ icon }}" />
      </button>
    </ion-col>
    </ion-row>
  </ion-grid>

  <!-- <ion-list>
    <button
      style="width: 80px; border-radius: 50%"
      *ngFor="let icon of defaultIcon"
      (click)="dismiss(icon)"
    >
      <img src="assets/device_markers/{{ icon }}" />
    </button>
  </ion-list> -->
  <div style="height: 20px"></div>
</ion-content>
