import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MediaManagmentComponent } from './media-managment.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PajCutAndFrameModule } from './crop-and-frame/paj-cut-and-frame.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { BackcloseDirectiveModule } from 'src/app/directives/backclose-directive.module';

@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      TranslateModule,
      ImageCropperModule,
      PajCutAndFrameModule,
      PipesModule,
      DirectivesModule,
      BackcloseDirectiveModule
    ],

    declarations: [MediaManagmentComponent],
    exports: [MediaManagmentComponent, PajCutAndFrameModule, ImageCropperModule]
  })

  
  export class PajMediaManagementModule {}