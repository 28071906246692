import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { animations } from 'src/app/animations/animations';
import { NewPullupService } from '../../new-pullup/pullup.service';
import { DevicesSidebarService } from '../devices-sidebar.service';
import { DEVICE_BATTERY_LEVEL_FILTERS, DEVICE_STATUS } from '../util';
import { CountAppliedFilters, DefaultFilters, IFILTERS, InitialValues } from './filters-helper';
import { AppService } from 'src/app/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/constants.enum';
import { first } from 'rxjs';
import { StorageService as Storage } from './../../../../services/storage.service';

@Component({
  selector: 'app-devices-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  animations: animations
})
export class DevicesFiltersComponent implements OnInit, AfterViewInit {
  @Input() groups = [];
  DeviceStatus = DEVICE_STATUS;
  filters: IFILTERS = this.devicesSidebarService.filters.get().value;
  selectedStatus = null;
  selectedGroup = null; 
  selectedBatteryLevel = null;
  deviceBatteryLevelFilters = DEVICE_BATTERY_LEVEL_FILTERS
  appliedFilters = 0;
  filtersChanged = false;
  observables = {};
  isPullup = false;
  enableScroll = true;
  pullupBreakpoint = 'middle'
  userInfo: any;
  userSettings: any;
  private filterObject:IFILTERS = DefaultFilters;

  @ViewChild('content', { read: ElementRef }) content: ElementRef;
  constructor(private devicesSidebarService: DevicesSidebarService,
              public pullUpService: NewPullupService,
              public storage: Storage,
              private authService: AuthenticationService,
              public appService: AppService,
              public _translate: TranslateService
              ) { }
  
  async ngOnInit() {
    this.setUpFilters()
    this.loadListeners();
    this.isPullup = this.devicesSidebarService.isOpeninPullup;

    this.observables['pullupBreakpoint'] = this.pullUpService.currentBreakPoint.subscribe(breakName => {
      this.pullupBreakpoint = breakName;
     });

     this.userInfo = Object.assign({}, await this.getUserInfo());
     await this.chargeFilterSettings();
  }

  ngAfterViewInit(){
    this.devicesSidebarService.dragableHeader.next('.draggable-header-filers');
  }

  async chargeFilterSettings(){    
    this.userSettings = await this.storage.get(Constants.USER_SETTINGS);
    if(this.userSettings.device_filters){      
     this.filterObject = JSON.parse(this.userSettings.device_filters);
     this.setUpFilters(JSON.parse(this.userSettings.device_filters));
    }    
  }


  updateDeviceListFilters(key: string, value: any, reset:boolean = false){
    if(reset) this.filterObject = {...InitialValues};
    else this.filterObject[key] = value;   
    this.updateFilterSettings(JSON.stringify(this.filterObject));
    this.storage.set(Constants.USER_SETTINGS, {...this.userSettings, device_filters: JSON.stringify(this.filterObject)});
  } 

  setUpFilters(filters = null){
    this.filters = filters ? filters : this.devicesSidebarService.filters.get().value;
    this.selectedStatus = DEVICE_STATUS.find(s => s.id == +this.filters.status);
    this.selectedGroup = this.groups.find(g => g.devices_groups_group_id == this.filters.groupId);
    this.selectedBatteryLevel = DEVICE_BATTERY_LEVEL_FILTERS.find(batLvl => batLvl.id == this.filters.batteryLevel);
  }

  loadListeners(){
    this.observables['countFilters'] = this.devicesSidebarService.filters.get().subscribe( f => {
      this.appliedFilters = CountAppliedFilters(f);
    });


    if (this.devicesSidebarService.isOpeninPullup)
      this.enableScroll = this.pullUpService.currentBreakPoint.value == 'top';
    this.observables['pullupBreakpoint'] = this.pullUpService.breakPointChanged.subscribe( bp => {
      const scrollTop = this.content.nativeElement.shadowRoot.querySelector('.inner-scroll').scrollTop;
      if (bp == 'top' && scrollTop > 0){
        this.isEnablePullup = false;
        setTimeout(() => this.devicesSidebarService.enableDragPullup.next(false), 355);
      }
      this.enableScroll = bp == 'top';
    });
  }

  setStatus(ev){    
    if (this.selectedStatus.id != ev.selectedItem.id){
      this.selectedStatus = ev.selectedItem;
      this.filters.status = this.selectedStatus.id;
      this.filtersChanged = true;
      this.updateDeviceListFilters('status', ev.selectedItem.id)
    }
    this.appliedFilters = CountAppliedFilters(this.filters);
    
  }

  setGroup(ev){    
    if (this.selectedGroup.devices_groups_group_id != ev.selectedItem.devices_groups_group_id){
      this.selectedGroup = ev.selectedItem;
      this.filters.groupId = this.selectedGroup.devices_groups_group_id;
      this.filtersChanged = true;
      this.updateDeviceListFilters('groupId', ev.selectedItem.devices_groups_group_id)
    }
    this.appliedFilters = CountAppliedFilters(this.filters);
  }

  setBatteryLevel(ev){        
    if (this.selectedBatteryLevel.id != ev.selectedItem.id){
      this.selectedBatteryLevel = ev.selectedItem;
      this.filters.batteryLevel= this.selectedBatteryLevel.id;
      this.filtersChanged = true;
      this.updateDeviceListFilters('batteryLevel', ev.selectedItem.id);
    }
    this.appliedFilters = CountAppliedFilters(this.filters);
  }

  updateFilters(){
    if (this.filters.groupId != this.selectedGroup.devices_groups_group_id){
      this.filters.groupId = this.selectedGroup.devices_groups_group_id;
      this.filtersChanged = true;
    }
    
    if (this.filters.status != this.selectedStatus.id){
      this.filters.status = this.selectedStatus.id;
      this.filtersChanged = true;
    }

    if (this.filters.batteryLevel != this.selectedBatteryLevel.id){
      this.filters.batteryLevel = this.selectedBatteryLevel.id;
      this.filtersChanged = true;
    }
      
    if (this.filtersChanged)
      this.devicesSidebarService.filters.set(this.filters);
  }

  setSortName(value){    
    this.filtersChanged = true;
    if (value != 'no')
      this.filters.sortImei = 'no';
    this.appliedFilters = CountAppliedFilters(this.filters);
    this.updateDeviceListFilters('sortName', value);
  }

  setSortImei(value){    
    this.filtersChanged = true;
    if (value != 'no')
      this.filters.sortName= 'no';
    this.appliedFilters = CountAppliedFilters(this.filters);
    this.updateDeviceListFilters('sortImei', value);
  }

  resetFilters(){
    const defaultF = Object.assign({}, DefaultFilters);
    this.filtersChanged = true;
    this.appliedFilters = 0;
    Object.keys(defaultF).forEach(k => {
      this.filters[k] = defaultF[k];
    });

    this.setUpFilters(this.filters);  
    this.updateDeviceListFilters('', '', true);
  }

  ngOnDestroy(){
    Object.keys(this.observables).forEach ( k => this.observables[k].unsubscribe() );
  }

  backFilters(){
    this.updateFilters();
  }

  isEnablePullup = true;
  ScrollingContent(ev){
    // if (ev.detail.scrollTop == 0 && !this.isEnablePullup){
    //   this.isEnablePullup = true;
    //   this.devicesSidebarService.enableDragPullup.next(true);
    // }
    // if (ev.detail.scrollTop > 0 && this.isEnablePullup){
    //   this.isEnablePullup = false;
    //   this.devicesSidebarService.enableDragPullup.next(false);
    // }
  }

  topReached(ev){
    if (ev.isOnTop && !this.isEnablePullup){
      this.devicesSidebarService.enableDragPullup.next(true);
      this.isEnablePullup = true;
    }
    if (!ev.isOnTop && this.isEnablePullup){
      setTimeout(() => this.devicesSidebarService.enableDragPullup.next(false), 300);
      // this.devicesSidebarService.enableDragPullup.next(false);
      this.isEnablePullup = false;
    }
    
  }
  enablePullup() {
    this.pullUpService.currentBreakPoint.value == 'top' ? this.pullUpService.setBreakPoint.next('middle') : this.pullUpService.setBreakPoint.next('top')
  }

  getUserInfo() {
    return new Promise((resolve, reject) => {
      if (this.appService.user)
        resolve(this.appService.user);
      else {
        this.appService.user$.pipe(first()).subscribe(u => {
          resolve(u);
        })

      }
    })
  }

  updateFilterSettings(value){    
    this.authService.updateUserSettings(this.userInfo.id, {...this.userSettings, 'device_filters': value}).then((r: any) => {
      if (r.success) {
        this.appService.showToast('', this._translate.instant("deviceManagement.mapSettingMessage"), 2000, 'success');
      }
    }).catch(error => {
      console.log("ERROR: ", error);
      this.appService.showToast('', this._translate.instant("deviceManagement.errorUpdateSettings"), 3000, 'danger');
    });
  }
}
