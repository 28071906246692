import { Injectable } from '@angular/core';
import { DeviceOrientation, DeviceOrientationCompassHeading } from  '@awesome-cordova-plugins/device-orientation/ngx';

@Injectable({
  providedIn: 'root'
})
export class GyroscopeService {

  constructor(
    private deviceOrientation: DeviceOrientation,
    //private deviceOrientationCompassHeading: DeviceOrientationCompassHeading
    ) { }


  startWatcher() {
    return this.deviceOrientation.watchHeading();
  }

  stopWatcher() {
    
  }

  getCurrentHeading(){
    this.deviceOrientation.getCurrentHeading().then(
      (data: DeviceOrientationCompassHeading) => console.log(data),
      (error: any) => console.log(error)
    );
  }
}
