import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DeviceSettingComponent } from "src/app/members/device-management/components/device-setting/device-setting.component";
import { DeviceDataService } from "../../devicesData.service";
import {
  getBatteryImage,
  getBatteryPercentage,
  showBatteryInfo,
} from "./betteryHelper";
import { getSpeedIcon } from "./speedHelper";
import { AppService } from "src/app/app.service";
import { MediaApiService } from "src/app/components/media-managment/media-api.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Constants } from "src/app/constants.enum";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MenuController, ModalController, Platform } from "@ionic/angular";
import { DeviceNoteComponent } from "../../devices-sidebar/device-note/device-note.component";
import { TranslateService } from "@ngx-translate/core";
import { BrowserService } from "src/app/services/plugins/browser.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Storage } from "@ionic/storage";
import { MapComponent } from "../map/map.component";
import { getLogbookAddress } from "src/app/components/map-address-picker/util";
import { ClipboardService } from "src/app/services/plugins/clipboard.service";
import { LatLngToMgrsPipe } from "src/app/pipes/latLngToMgrs";
import { DevicesSidebarService } from "../../devices-sidebar/devices-sidebar.service";
import { DeviceItemComponent } from "../../devices-sidebar/device-item/device-item.component";
import { DeviceSubmenuService } from "../../devices-sidebar/device-item/device-submenu/device-submenu.service";
import { DeviceNavigationService } from "src/app/services/device-navigation-service.service";
import { AVAILABLE_SYMBOL_MARKERS } from "../map/class/features/symbolMarkers";
@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
})
export class PopupComponent implements OnInit, OnDestroy {
  _data;
  @Input() map: any;
  public data;
  distance_unit = "";
  speed_unit = "";
  language = "en";
  isDestroyed = new Subject();
  loadImageTimer = null;
  userSettings: any;
  viewcheck = null;
  stopAddress = null;
  constructor(
    private devicesService: DeviceDataService,
    public appService: AppService,
    private browserService: BrowserService,
    private mediaApi: MediaApiService,
    private authService: AuthenticationService,
    private modalCtrl: ModalController,
    private platform: Platform,
    private modalController: ModalController,
    private translate: TranslateService,
    private storage: Storage,
    private clipboard: ClipboardService,
    private coordinatesPipe: LatLngToMgrsPipe,
    private devicesSidebarService: DevicesSidebarService,
    private devicesubmenu: DeviceSubmenuService,
    private menu: MenuController,
    private deviceNavigation:DeviceNavigationService
  ) {
    this.distance_unit = this.appService.user
      ? this.appService.user.distance_unit
      : "km";
    this.speed_unit = this.translate.instant(
      "speed." + this.distance_unit + "H"
    );
  }

  // Feature clicked
  feature = null;
  // Device of the feature, this variable always should exist
  device = null;
  // Pause if clicked in 'pause' feature; if no this variable is null
  pause = null;
  // Devices are the list of devices from DeviceDataService
  devices = [];
  // Point to show information in the template, this variable is null if feature clicked is 'pause'
  point = null;
  // Bettery data to show in the template
  battery = { icon: "", porcentage: "", show: false };
  speedIcon = null;
  height = null;
  voltage = null;
  temperature = null;
  sensorDate = null;

  dataPointImage = null;
  imagesCollection = Constants.DATAPOINTS_COLLECTION_NAME;

  // For user role
  super_user = false;

  async ngOnInit() {
    this.language = this.appService.language;

    // Check if a data value exist
    if (this.data) {
      // The feature value is in data
      this.feature = this.data.feature;
      // Get the devices list from the deviceDataService
      // If doesnt exist
      if (!this.device) {
        this.devices = this.devicesService.devices;
        this.device = this.devices[this.feature.properties["deviceId"]];

      }
      this.userSettings = await this.storage.get(Constants.USER_SETTINGS);

      // Verify which feature type is it, by the name in layer.id
      // If the layer is a marker then get the device from the device list
      // With the layer property deviceId
      if (
        this.feature.layer.id == "markers" ||
        this.feature.layer.id == "markers3d" ||
        this.feature.layer.id == "alarms_circle"
      )
        this.point = this.device.lastPoint;

      //_ If the layer is a direction marker then get the direction point bye sending feature propertie directionId
      if (
        this.feature.layer.id.includes("directions") ||
        this.feature.layer.id == "scrollDirections" ||
        this.feature.layer.id == "wifiDirections" ||
        (this.feature.layer.id == "symbolsLayer" &&
          (this.feature.properties['image'] == AVAILABLE_SYMBOL_MARKERS["start-marker"] ||
          this.feature.properties['image'] == AVAILABLE_SYMBOL_MARKERS["end-marker"]))
      ) {
        //_ If the point doesnt exist in directionMarkers then search into realtimeDirectionMarkers
        if (this.feature.properties.id?.includes("realtime"))
          this.point = this.getDataById(
            this.device.realtimeDirectionMarkers,
            this.feature.properties["directionId"]
          );
        else
          this.point = this.getDataById(
            this.device.directionMarkers,
            this.feature.properties["directionId"]
          );

      }

      // If the layer is a pause marker then get the pause data bye sending feature propertie directionId
      if (this.feature.layer.id == "pauses") {
        let pause;

        if (this.feature.properties.id.includes("realtime"))
          pause = this.device.realtimePauseMarkers.find(
            (p) => p.pause_display.id == this.feature.properties["pauseId"]
          );
        //this.device.realtimePauseMarkers[this.feature.properties['pauseId']];
        else
          pause = this.device.pauseMarkers.find(
            (p) => p.pause_display.id == this.feature.properties["pauseId"]
          ); //this.device.pauseMarkers[this.feature.properties['pauseId']];

        const diffTime = Math.abs(
          pause.data.dateunix - pause.pause_start.dateunix
        );

        //_ Pause time check | uncomment in case wants to show point instead pause information
        //_ If diffTime is less than X minutes|time
        // if (diffTime < 3 * 60) {
        //   console.log('TIME DIFFERENCE', diffTime);
        //   //_ If time of pause is less than 3 minutes, then show the point information
        //   this.point = pause.pause_display;
        // }
        // else {
          // this.pause = pause;
          // this.requestAddress(this.pause.pause_display);
        // }

        this.pause = pause;
        this.requestAddress(this.pause.pause_display);
      }
      // Only get battery status if lastPoints exist
      if (this.point) {
        this.battery.show = showBatteryInfo(this.device.deviceModel);
        this.updateBatteryData(this.point.battery, this.device);
        this.speedIcon = getSpeedIcon(this.point.speed);

        this.point["convertedSpeed"] = this.calcSpeed(this.point.speed);

        let singleCordinate = {
          lat: this.point.lat,
          lng: this.point.lng,
        };
        this.authService.getHeight(singleCordinate).then((res) => {
          if (res) {
            if (res.results) {
              if (res.results[0]) {
                this.height = res.results[0].elevation;
              }
            }
          }
        });

        this.authService.getSensorDataForDate(this.point.iddevice, this.point.dateunix, this.viewcheck).then((res) => {
          this.fixSensorData(res);
        });

        //_ Load image from media files
        if (!this.appService.isSharedView)
          this.loadImageTimer = setTimeout(() => this.loadImage(), 200);
      }
    }

    if(this.appService.user.super_user === 1){
      this.super_user = true
    }
  }

  fixSensorData(res){
    if (res) {
      if (res.success) {
        if (res.success.volt) {
          this.voltage = res.success.volt / 1000;
          this.voltage = Math.round(this.voltage * 10) / 10; // rounding to one decimal place
          this.sensorDate = res.success.ts;
        }
        if (res.success.temp) {
          this.temperature = res.success.temp;
          this.sensorDate = res.success.ts;
        }
      }
    }
  }

  ngAfterContentInit(): void {
    // setTimeout(() => {
    //   if (this.map)
    //     this.map.setZoom(this.map.getZoom());
    //   // const el = document.querySelector(".mapboxgl-popup") as any;
    //   // if (el) el.style.visibility = 'visible';
    // }, 300);
  }

  calcSpeed(speed) {
    return this.distance_unit == "mi" ? speed / 1.609 : speed;
  }

  // Get item from array passing its Id
  getDataById(array, id) {
    const dp = array.find((e) => e.id == id);
    const index = array.map(d => d.id).indexOf(id);
    return {...dp, index };
  }

  // Update battery variable
  // getBatteryIcon and getBatteryPorcentage functions are located into betteryHelper.ts file
  updateBatteryData(battery, device) {
    if (this.battery.show) {
      this.battery.icon = getBatteryImage(
        battery,
        device.deviceModel,
        device.online
      );
      this.battery.porcentage = getBatteryPercentage(
        battery,
        device.deviceModel
      );
    }
  }

  openMap(lat, lng) {
    window.open(
      `http://www.google.com/maps/place/${lat},${lng}/data=!3m1!1e3`,
      "_blank"
    );
  }

  // //_ Open link using mobile map app
  // public openMapApp(lat, lng) {
  //   if (this.platform.is('android'))
  //   this.browserService.openBrowser(`geo://?q=${lat},${lng}`, "_blank");

  //   if (this.platform.is('ios'))
  //   this.browserService.openBrowser(`maps://?q=${lat},${lng}`, "_blank");

  //   // window.open('maps://?q=' + lat + ',' + lng, '_system');
  //   // this.iab.create(`geo://?q=${lat},${lng}/data=!3m1!1e3`, "_system");
  //   // window.location.href = 'geo:' + location;

  //   // window.location.href = 'maps://maps.apple.com/?q=' + location;
  // }

  //_ Media files methods
  loadingImage = true;
  loadImage() {
    this.loadingImage = true;
    const mediaObj = this.mediaApi.createImageObject(
      this.imagesCollection,
      this.imagesCollection + "---" + this.point.id
    );
    this.mediaApi
      .getImageData(mediaObj, false)
      .pipe(takeUntil(this.isDestroyed))
      .subscribe((r: any) => {
        this.loadingImage = false;
        if (r != this.mediaApi.defaultDeviceImage) this.dataPointImage = r;
      });
  }

  progressInfo: any = null;
  uploadPicture(event) {
    this.progressInfo = null;
    let selectedFile = event.target.files[0];
    //selectedFile = this.renameFile(selectedFile, this.point.id);
    this.upload(selectedFile, this.point.id);
  }

  async upload(file, name) {
    this.progressInfo = {
      value: 0,
      fileName: name,
      image: this.mediaApi.getBase64(file),
      error: false,
      file: file,
      compressing: true,
    };

    file = await this.mediaApi
      .compressFile(file, { maxSizeMB: 1, useWebWorker: true })
      .catch((error) => {
        console.log("ERROR: ", error);
        this.progressInfo.value = 0;
        this.progressInfo.error = true;
        this.progressInfo.compressing = false;
        return null;
      });

    if (file != null) {
      this.progressInfo.compressing = false;
      this.mediaApi.upload(file, this.imagesCollection, true, name).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfo.value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            if (event.status == 200) {
              this.loadImage();
              this.progressInfo = null;
            }
          }
        },
        (error) => {
          console.log("ERROR: ", error);
          this.progressInfo.value = 0;
          this.progressInfo.error = true;
        }
      );
    }
  }

  deleting = false;
  async deleteImage() {
    if (
      !(await this.appService.confirmDialog(
        this.translate.instant("toast.deleteTrackerPointImage"),
        this.translate.instant("toast.sureToDelete")
      ))
    )
      return null;

    this.deleting = true;
    await this.mediaApi
      .deleteByName(this.imagesCollection + "---" + this.point.id)
      .then((r) => {
        if (r.success) this.dataPointImage = null;
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        this.appService.showToast(
          "",
          this.translate.instant("toast.errorDeleteImage"),
          3000,
          "danger"
        );
      });
    this.deleting = false;
  }

  renameFile(originalFile, newName) {
    Object.defineProperty(originalFile, "name", {
      writable: true,
    });

    originalFile.name = newName;
    return originalFile;
  }

  async openNote(item, ev) {
    ev.stopPropagation();
    const md = await this.modalCtrl.create({
      component: DeviceNoteComponent,
      backdropDismiss: false,
      componentProps: {
        note: item.note ? item.note.text : null,
        note_color: item.note ? item.note.color : null,
        _id: item.id,
        deviceId: this.device.id,
        onUpdate: (data) => {
          item.note = { text: data.note, color: data.note_color };
        },
      },
    });

    await md.present();
  }

  async openDeviceMenu() {
    if (this.map.isSmallScreen) {
      // Pullup Device Menu
      this.map.showPullup = true; //Open device pullup menu
    } else {
      // Desktop Device Menu
      this.map.showPullup = false;
      await this.menu.enable(true, 'deviceMenu');
      await this.menu.open("deviceMenu");
    }
    this.appService.deviceMenuisOpen.next(true);
  }

  async deviceSettings(item, ev) {
    // ev.preventDefault();
    // ev.stopPropagation();
    const isDifferentDevice =
      this.devicesSidebarService.selectedDevice.value != item.id;
    this.devicesubmenu.selectSubmenuItem("settings");
    console.log('isDifferentDevice',isDifferentDevice)
  if (
    isDifferentDevice ||
    this.devicesSidebarService.activePage == "APP-DEVICES-LIST-HOME"
  ) {
    this.devicesSidebarService.selectedDevice.next(null);
    await this.openDeviceMenu()
        this.devicesSidebarService.selectedDevice.next(item.id);
  }
  else {
    await this.openDeviceMenu()
  }

  // Navigate to device view
  if (isDifferentDevice)
    this.deviceNavigation.navigateToDevice(item.id)

    //_ When user is not logged; can not edit the device settings


    // if (this.appService.user.id == -1)
    //   return false;

    // const modal = await this.modalController.create({
    //   component: DeviceSettingComponent,
    //   componentProps: {
    //     id: item.properties.id,
    //     name: item.properties.name,
    //     color: item.color,
    //     iconname: item.properties.iconname,
    //     iconusecustom: item.properties.iconusecustom,
    //     iconcustomimage: item.properties.iconcustomimage,
    //     uploadedFile: item.properties.uploadedFile,
    //     device_viewlink: item.properties.share_link ? true : false,
    //     user_id: this.appService.user.id,
    //     privacy_mode: item.properties.privacy_mode,
    //   },
    // });

    // await modal.present();
    // const { data } = await modal.onWillDismiss();
    // if (data) this.updateDeviceMapSetting(data, item);
  }

  async updateDeviceMapSetting(data, item) {
    await this.authService
      .updateDeviceMapSetting(
        data.id,
        data.name,
        data.color,
        data.iconname,
        data.iconusecustom,
        data.device_viewlink,
        data.privacy_mode
      )
      .then((res) => {
        Object.keys(data).forEach((k) => {
          if (k == "color")
            this.devicesService.devices[data.id].properties.spurfarbe =
              data.color;
          else if (k == "device_viewlink")
            this.devicesService.devices[data.id].properties.share_link =
              data.device_viewlink;
          else this.devicesService.devices[data.id].properties[k] = data[k];
        });

        this.devicesService.updateDeviceInfo(
          this.devicesService.devices[data.id]
        );

        this.appService.showToast(
          this.translate.instant(
            "deviceManagement.deviceSettings.deviceSettingsText"
          ),
          this.translate.instant("deviceManagement.mapSettingMessage"),
          2000,
          "success"
        );
      })
      .catch((e) => {
        this.appService.showToast(
          "",
          this.translate.instant("toast.errorStoringData"),
          3000,
          "danger"
        );
      });
  }

  requestAddress(point){
    this.authService.getStreetAdrressNew(point.lat, point.lng).then((res: any) => {
      if (res) {
        if (res.success?.features?.length > 0)
          this.stopAddress = getLogbookAddress(res.success.features[0].properties).address;
      }
    });
  }

  async copyCoordinates (point) {
    const textToCopy = await this.coordinatesPipe.transform(point.lat, point.lng);
    await this.clipboard.copyToClipboard(textToCopy);
    await this.appService.showToast('', this.translate.instant("info.copySuccess"), 2000, "success");
  }

  ngOnDestroy(): void {
    this.isDestroyed.next(true);
    this.isDestroyed.complete();
    if (this.loadImageTimer) clearInterval(this.loadImageTimer);
  }
}
