import { CanvasObject } from "./canvasObject";

export class TextObject extends CanvasObject {
    font = '30px sans-serif';
    background = 'rgba(0,0,0,1)';
    foreground = 'rgba(0,0,0,1)';
    align = 'center';
    fontStyle = '';
    constructor(ctx, x, y, public text, public fontSize = 30, align = 'center', foreground = 'rgba(0,0,0,1)', background = 'rgba(0,0,0,0)') {
        super(ctx, x, y, 0, 0);
        this.background = background;
        this.foreground = foreground;
        this.align = align;
        this.setText(text);
    }

    draw() {
        //_ Draw a simple square
        this.ctx.save();
        this.ctx.globalAlpha = this.alpha;

        this.ctx.fillStyle = this.background;
        this.ctx.fillRect(this.x, this.y, this.width, this.height);

        let xText = this.x;
        const padding = this.background != 'rgba(0,0,0,0)' ? 10 : 0;
        if (this.align == 'center') xText = (this.width / 2) + this.x;
        if (this.align == 'right') xText = this.x + this.width - padding;
        if (this.align == 'left') xText = this.x + padding;

        this.ctx.textBaseline = "middle";
        this.setAlign(this.align);
        this.ctx.fillStyle = this.foreground;
        this.ctx.font = this.fontStyle + ' ' + this.font;
        this.ctx.fillText(this.text, xText, this.y + (this.height / 2));

        this.ctx.restore();
    }

    setAlign(align) {
        this.align = align;
        this.ctx.textAlign = align;
    }

    setText(newText) {
        this.text = newText;
        this.font = this.fontSize + 'px sans-serif';
        this.ctx.font = this.font;
        const messureSize = this.ctx.measureText(this.text);
        this.width = messureSize.width + 30 < 306 ? messureSize.width + 30 : 306;
        if (messureSize.width + 30 > 306)
            this.setText(newText.slice(0, -1));
        this.height = this.fontSize + 30;
    }

}