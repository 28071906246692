import { AfterViewInit, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { SCREEN_SIZE } from 'src/app/app.service';
import { BREAK_POINTS, ResizeService } from 'src/app/services/resize.service';

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.scss'],
})
export class SizeDetectorComponent implements OnInit, AfterViewInit {

  prefix = '';
  sizes = BREAK_POINTS;

  constructor(private elementRef: ElementRef, private resizeSvc: ResizeService) {}

  // @HostListener("window:resize", [])
  // private onResize() {
  //   this.detectScreenSize();
  // }
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.detectScreenSize();
  }

  ngOnInit() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const currentSize = this.sizes.find(x => {
    const screenWidth = document.documentElement.clientWidth;
      if ( screenWidth > x.minWidth && screenWidth <= x.maxWidth)
        return x;
    });
    if (currentSize)
      this.resizeSvc.onResize(currentSize.id);
  }
}
