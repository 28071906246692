import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceImageService } from './deviceImagePipe';


@Pipe({
  name: 'DeviceImageSanitizer'
})

export class DeviceImageSanitizer implements PipeTransform { //duration in seconds
  constructor(private imageService: DeviceImageService, private sanitizer: DomSanitizer) { }

  transform(device, userId): any {
    if (!device) return this.imageService.getAssetImage('paj_iconset_auto5');
    //return this.imageService.getImage(device, userId);
    return new Observable(obs => {
      this.imageService.getImage(device, userId).subscribe((img: any) => {
        obs.next(this.sanitizer.bypassSecurityTrustUrl(img));
      });
    });
  }
}
