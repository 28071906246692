<paj-popup-template headerTitle="{{ 'alerts.shockPopup.title' | translate }}" [contentTemplate]="Content">

</paj-popup-template>

<ng-template #Content>
  <ion-grid fixed class="ion-padding">
    <ion-row>
      <ion-col size="12" class="ion-no-padding ion-text-center">
        <ion-label class="ion-text-wrap">
          {{ "alerts.shockPopup.message" | translate}}
        </ion-label>
      </ion-col>

      <ion-col size="12" class="ion-no-padding">
        <ion-list lines="none">
          <ion-item>
            <ion-checkbox slot="start" #setupBattery></ion-checkbox>
            <ion-label class="ion-text-wrap">{{ 'alerts.shockPopup.setupVoltageAlert' | translate }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-checkbox slot="start" #dontShow></ion-checkbox>
            <ion-label class="ion-text-wrap">{{ 'alerts.shockPopup.dontShowAgain' | translate }}</ion-label>
          </ion-item>
      
          <!-- <div class="ion-text-center ion-padding">
            
          </div> -->
        </ion-list>
      </ion-col>

      <ion-col size="12" class="ion-no-padding ion-text-center">
        <ion-button shape="round" color="light" (click)="accept({ setupBattery: setupBattery.checked, dontShow: dontShow.checked })">
          <ion-label>{{ 'alerts.saveText' | translate }}</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <!-- <ion-item lines="none" class="ion-no-padding">
    
  </ion-item> -->

  
</ng-template>