
export interface IPermission {
    //_ For Android Permissions name check https://developer.android.com/reference/android/Manifest.permission.html
    permission: string,
    permissions?: Array<string>,
    title: string,
    description: string,
    help?: string,
    //_ Check this to see all available settings https://github.com/guyromb/Cordova-open-native-settings
    openSetting: string,
    iosOpenSetting: string,
    buttonText: string,
    image: string,
    request?(): Promise<any>,
    callback?(): any,
    permissionCheckFn?(): Promise<boolean>

}

export type TList = {  
    [Key in AvailablePermissions]: IPermission
}

//_ Name = Permission_List_Key
export enum AvailablePermissions{
    Storage = 'Storage', 
    Geolocation = 'Geolocation'
}

export const PermissionList = (translate): TList  => {
    return {
        Storage: {
            permission: 'WRITE_EXTERNAL_STORAGE',
            title: translate.instant('requestPermission.StorageTitle'),
            description: translate.instant('requestPermission.StorageDescription'),
            help: translate.instant('requestPermission.StorageHelp'),
            openSetting: 'application_details',
            iosOpenSetting: 'app',
            buttonText: translate.instant('requestPermission.StorageButtonText'),
            image: 'assets/images/request-permissions/folder.svg'
        },

        Geolocation: {
            permission: 'ACCESS_FINE_LOCATION',
            title: translate.instant('requestPermission.GeolocationTitle'),
            description: translate.instant('requestPermission.GeolocationDescription'),
            openSetting: 'application_details', //'location',
            iosOpenSetting: 'app',
            buttonText: translate.instant('requestPermission.StorageButtonText'),
            image: 'assets/images/request-permissions/allow-gps.svg',
        },
    }
}