import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceMeasure'
})

export class DistanceMeasurePipe implements PipeTransform { //duration in seconds
  constructor() { }

  transform(distance, get: string,): any { //distance in km or 
    switch (get) {
      case 'steps':
        return this.convertToSteps(distance);
        break;

      case 'calories':
        return Math.round(this.convertToSteps(distance) * 0.05); //aprox 0.05 kc per step
        break;

      case 'caloriesBike':
        return Math.round(distance * 30); //aprox 30 kc per km
        break;
    }

    return '0';
  }

  convertToSteps(distance) {
    return Math.round((distance * 1000) / 0.755)
  }
}