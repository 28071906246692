import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayKeys'
})
export class ArrayKeysPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      return Object.keys(value);
    }
    return [];
  }
}