export interface IFILTERS {
  qsearch: string,
  sortName: string,
  sortImei: string,
  status: string,
  groupId: number,
  batteryLevel: number
};

export const DefaultFilters: IFILTERS = {
  qsearch: '',
  sortName: 'no',
  sortImei: 'no',
  status: '-1',
  groupId: -1,
  batteryLevel: -1
};

export const InitialValues: IFILTERS = {
  qsearch: '',
  sortName: 'no',
  sortImei: 'no',
  status: '-1',
  groupId: -1,
  batteryLevel: -1
};

//_ Delete keys from and object passing default values to delete keys
export const cleanObject = (obj1, objDefault) => {
  const newObj: any = Object.assign({}, obj1);
  Object.keys(newObj).forEach(k => {
    if (newObj[k] == objDefault[k])
      delete newObj[k];
  });
  return newObj;
}

export const CountAppliedFilters = (newFilters) =>{
  let appliedFilters = 0;
  Object.keys(DefaultFilters).forEach( k => {
    if (DefaultFilters[k] != newFilters[k])
      appliedFilters++;
  })
  return appliedFilters;
}
