import { Injectable } from '@angular/core';
import { ApiCompany } from '../members/company/apis';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public _devicesPermissions = new Subject<{}>();
  public _devicesPermissions$ = this._devicesPermissions.asObservable();
  public devicesPermissions;
  // public permissions = [];
  constructor(private apiCompany: ApiCompany) { }

  async getPermissions(userId) {
    return await this.apiCompany.getUser(userId).then((data: any) => {
      this.devicesPermissions = this.reduceData(data.success.permissions);
      this._devicesPermissions.next(this.devicesPermissions);
      return this.devicesPermissions;
    });
  }

  reduceData(data) {
    return data.reduce((acc, d) => {
      acc[d.iddevice] = d;
      return acc;
    }, {});
  }
}
