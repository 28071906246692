import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-geo-select',
  templateUrl: './geo-select.component.html',
  styleUrls: ['./geo-select.component.scss'],
})
export class GeoSelectComponent implements OnInit {

  constructor(private popoverCtrl: PopoverController, private translate: TranslateService) { }

  geoTypes = [{
    type: 'circle',
    name: this.translate.instant('alerts.circleText'),
    image: 'assets/images/alarm/circle.svg'
  }, {
    type: 'polygon',
    name: this.translate.instant('alerts.polygonText'),
    image: 'assets/images/alarm/poligon.svg'
  }];

  @Input() selectedItem;
  async ngOnInit() {

  }

  select(geo) {
    this.selectedItem = geo;
    this.popoverCtrl.dismiss({ geo });
  }

  dismiss() {
    this.popoverCtrl.dismiss();
  }

}
