import { Injectable } from "@angular/core";
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Capacitor } from "@capacitor/core";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MessagingService {
  token = null;
  isMobileApp = false;
  private messageSubscription: Subject<any> = new Subject();
  private fireMessaging = null;
  private userLogout = false;
  constructor() { 
    this.isMobileApp = Capacitor.isNativePlatform() && (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android');
  }

  requestPermission() {
    if (this.isMobileApp) return;
    
    this.fireMessaging = getMessaging();
    return new Observable((observer) => {
      getToken(this.fireMessaging, { vapidKey: environment.firebase.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            this.token = currentToken;
            this.initMessageSubscription();
            observer.next(currentToken);
            observer.complete();
          } else {
            observer.complete(); // Complete the Observable
          }
        })
        .catch((err) => {
          observer.error(err); // Emit the error
        });
    });
    // return this.afMessaging.requestToken;
  }

  private initMessageSubscription() {
    // const fireMessaging = getMessaging();
    onMessage(this.fireMessaging, (payload) => {
      if (!this.userLogout)
        this.messageSubscription.next(payload); // Emit the payload
    });
  }

  getMessages(): Observable<any> {
    this.userLogout = false;
    return this.messageSubscription;
  }

  deleteToken() {
    if (this.isMobileApp) return;
    // this.messageSubscription?.complete();
    this.userLogout = true;
  }

  getafMessagingtoken() {
    if (this.isMobileApp) return;
    return new Observable((observer) => {
      observer.next(this.token);
      observer.complete();
    });
  }
}
