import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ResizeService } from 'src/app/services/resize.service';
import { SelectModalComponent } from './select-modal/select-modal.component';
import { SelectPopoverComponent } from './select-popover/select-popover.component';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
})
export class SelectListComponent implements OnInit {
  @Input() data;
  @Input() autoView = true;
  @Input() selectedItems = [];
  @Input() selectedItem = null;
  @Input() multiSelection = false;
  @Input() selectTemplete: TemplateRef<any> = null;
  @Input() itemTemplate: TemplateRef<any> = null;
  @Input() headerTemplete: TemplateRef<any> = null;
  @Input() footerTemplate: TemplateRef<any> = null;

  @Output() onSelect = new EventEmitter<Object>();
  constructor(private sizeService: ResizeService, private popoverCtrl: PopoverController,
              private modalCtrl: ModalController) { }

  ngOnInit() {

  }

  open(ev){
    this.data = this.formatData(this.data);
    //_ Uncomment to use in ionic 6
    if (this.autoView && this.sizeService.size == 'xs')
      this.openModal();
    else
      this.openPopover(ev);
  }

  async openPopover(ev){
    let popup = await this.popoverCtrl.create({
      componentProps: {
        data: this.data, itemTemplate: this.itemTemplate,
        headerTemplete: this.headerTemplete,
        multiSelection: this.multiSelection,
        selectedItems: this.multiSelection || null,
        selectedItem: this.selectedItem || null,
        footerTemplate: this.footerTemplate
      },
      component: SelectPopoverComponent,
      mode: 'ios',
      event: ev,
      showBackdrop: true,
      cssClass: 'list-popover'
      // cssClass: 'details-popup',
    });

    await popup.present();
    const { data } = await popup.onWillDismiss();
    if (data){
      this.onSelectItem(data);
    }
  }

  //_ USED FOR IONIC 6
  async openModal(){
    let modalPopup = await this.modalCtrl.create({
      componentProps: { data: this.data, itemTemplate: this.itemTemplate, headerTemplete: this.headerTemplete, footerTemplate: this.footerTemplate },
      component: SelectModalComponent,
      breakpoints: [0, 0.3, 1],
      initialBreakpoint: 0.3,
      cssClass: 'details-popup',
      canDismiss: true
    });

    await modalPopup.present();
    const { data } = await modalPopup.onWillDismiss();
    if (data){
      this.onSelectItem(data);
    }
  }

  onSelectItem(data){
    this.selectedItems = data.selectedItems;
    this.selectedItem = data;
    this.onSelect.emit({ selectedItems: this.selectedItems,  selectedItem: this.selectedItem });
  }

  formatData(data){
    let i = 0;
    return data.map( d => {
      i ++;
      if (typeof(d) == 'string' || typeof(d) == 'number'){
        return { _index: i, name: d };
      }
      else
        return { _index: i, ...d };
    })
  }

}
