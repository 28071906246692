import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TourComponent } from './tour.component';


@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      PipesModule,
      TranslateModule,
      DirectivesModule
    ],

    declarations: [TourComponent],
    exports: [TourComponent],
    
  })

  
  export class PajTourModule {}