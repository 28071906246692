import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deafult-icons',
  templateUrl: './deafult-icons.component.html',
  styleUrls: ['./deafult-icons.component.scss'],
})
export class DeafultIconsComponent implements OnInit {
  @Input() showBackButton = false;
  defaultIcon: any = [
    'v2_grandma.png',
    'v2_grandpa.png',
    'v2_antique_car.png',
    'v2_antique_car_2.png',
    'v2_antique_car_3.png',
    'v2_bag.png',
    'v2_boat.png',
    'v2_boat_2.png',
    'v2_boat_3.png',
    'v2_boat_4.png',
    'v2_car.png',
    'v2_car_2.png',
    'v2_car_3.png',
    'v2_car_4.png',
    'v2_car_5.png',
    'v2_cat.png',
    'v2_cat_2.png',
    'v2_cat_3.png',
    'v2_cat_4.png',
    'v2_cat_5.png',
    'v2_cat_6.png',
    'v2_cat_7.png',
    'v2_dog.png',
    'v2_dog_2.png',
    'v2_dog_3.png',
    'v2_dog_4.png',
    'v2_dog_5.png',
    'v2_dog_6.png',
    'v2_dog_7.png',
    'v2_dog_8.png',
    'v2_dog_9.png',
    'v2_excavator.png',
    'v2_excavator_2.png',
    'v2_jetski.png',
    'v2_motorcycle.png',
    'v2_motorcycle_2.png',
    'v2_motorcycle_3.png',
    'v2_motorcycle_4.png',
    'v2_motorcycle_5.png',
    'v2_motorcycle_6.png',
    'v2_quad.png',
    'v2_quad_2.png',
    'v2_truck.png',
    'v2_truck_2.png',
    'v2_truck_3.png',
    'v2_truck_4.png',

    // 'v2_car_7.svg',
    // 'v2_truck2.svg',
    // 'v2_car_9.svg',
    // 'v2_car_10.svg',
    // 'v2_dog.svg',
    // 'v2_horse1.svg',
    // 'v2_horse2.svg',
    // 'v2_littledog.svg',

    'paj_iconset_car.svg',
    'paj_iconset_car_10.svg',
    'paj_iconset_backpack.svg',
    //'v2_bagpack.svg',
    // 'v2_bicycle.svg',


    'paj_iconset_auto.svg',
    //'paj_iconset_auto1.svg',
    'paj_iconset_auto2.svg',
    'paj_iconset_auto3.svg',
    'paj_iconset_auto4.svg',
    'paj_iconset_auto5.svg',
    'paj_iconset_auto6.svg',
    'paj_iconset_logo.svg',
    'paj_iconset_auto7.svg',
    'paj_iconset_auto8.svg',
    'paj_iconset_lkw1.svg',
    'paj_iconset_lkw2.svg',
    'paj_iconset_lkw3.svg',
    'paj_iconset_lkw4.svg',
    'paj_iconset_lkw5.svg',
    'paj_iconset_lkw6.svg',
    'paj_iconset_lkw.svg',
    'paj_iconset_boot.svg',
    'paj_iconset_fahrrad.svg',
    'paj_iconset_motorrad.svg',
    'paj_iconset_motorrad1.svg',
    'paj_iconset_motorrad2.svg',
    'paj_iconset_quad.svg',
    'paj_iconset_roller.svg',


    'paj_iconset_anhanger1.svg',
    'paj_iconset_bagger.svg',
    'paj_iconset_camping5.svg',
    'paj_iconset_horse2.svg',
    'paj_iconset_horse1.svg',
    'paj_iconset_dog.svg',



    //'paj_iconset_baumaschinen1.svg',
    'paj_iconset_baumaschinen2.svg',
    'paj_iconset_baumaschinen3.svg',
    'paj_iconset_camping1.svg',
    'paj_iconset_camping2.svg',
    'paj_iconset_camping3.svg',
    'paj_iconset_camping4.svg',

    'paj_iconset_personen.svg',
    'paj_iconset_oma.svg',
    'paj_iconset_oma1.svg',
    'paj_iconset_opa1.svg',


    'paj_iconset_kind_1.svg',
    'paj_iconset_kind_2.svg',
    'paj_iconset_kind_3.svg',
    'paj_iconset_krankenwagen.svg',

    'paj_iconset_landwirtschaft1.svg',
    'paj_iconset_landwirtschaft2.svg',

    'paj_iconset_airplane_v1.svg',
    'paj_iconset_airplane_v2.svg',
    'paj_iconset_airplane_v3.svg',
    'paj_iconset_airplane_v4.svg',
    'paj_iconset_airplane_v5.svg',
    'paj_iconset_paraglider_v1.svg',
    'paj_iconset_paraglider_v2.svg',
    'paj_iconset_paraglider_v3.svg'
  ];

  constructor(public modelCtrl: ModalController, private platform: Platform) {
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.dismiss();
    });
    if (environment.BRAND == 3) {
      this.defaultIcon = [
        'paj_iconset_auto.svg',
        //'paj_iconset_auto1.svg',
        'paj_iconset_auto2.svg',
        'paj_iconset_auto3.svg',
        'paj_iconset_auto4.svg',
        'paj_iconset_auto5.svg',
        'paj_iconset_auto6.svg',
        'paj_iconset_logo.svg',
        'paj_iconset_auto7.svg',
        'paj_iconset_auto8.svg',
        'paj_iconset_lkw1.svg',
        'paj_iconset_lkw2.svg',
        'paj_iconset_lkw3.svg',
        'paj_iconset_lkw4.svg',
        'paj_iconset_lkw5.svg',
        'paj_iconset_lkw6.svg',
        'paj_iconset_lkw.svg',
        'paj_iconset_boot.svg',
        'paj_iconset_fahrrad.svg',
        'paj_iconset_motorrad.svg',
        'paj_iconset_motorrad1.svg',
        'paj_iconset_motorrad2.svg',
        'paj_iconset_quad.svg',
        'paj_iconset_roller.svg',


        //'paj_iconset_baumaschinen1.svg',
        'paj_iconset_backpack.svg',
        'paj_iconset_baumaschinen2.svg',
        'paj_iconset_baumaschinen3.svg',
        'paj_iconset_camping1.svg',
        'paj_iconset_camping2.svg',
        'paj_iconset_camping3.svg',
        'paj_iconset_camping4.svg',

        'paj_iconset_oma1.svg',
        'paj_iconset_opa1.svg',
        'paj_iconset_krankenwagen.svg',


        'paj_iconset_personen.svg',
        'paj_iconset_oma.svg',
        'paj_iconset_kind_1.svg',
        'paj_iconset_kind_2.svg',
        'paj_iconset_kind_3.svg',

        'paj_iconset_landwirtschaft1.svg',
        'paj_iconset_landwirtschaft2.svg',

        'paj_iconset_anhanger1.svg',
        'paj_iconset_bagger.svg',
        'paj_iconset_camping5.svg',
        'paj_iconset_horse2.svg',
        'paj_iconset_horse1.svg',
        'paj_iconset_dog.svg',
        'paj_iconset_car_10.svg',
        'paj_iconset_car.svg',

        'paj_iconset_airplane_v1.svg',
        'paj_iconset_airplane_v2.svg',
        'paj_iconset_airplane_v3.svg',
        'paj_iconset_airplane_v4.svg',
        'paj_iconset_airplane_v5.svg',
        'paj_iconset_paraglider_v1.svg',
        'paj_iconset_paraglider_v2.svg',
        'paj_iconset_paraglider_v3.svg'


      ];
    }
  }

  addNumber() {
    for (let i = 1; i <= 100; i++) {
      this.defaultIcon.push('paj_iconset_' + i + '.svg');
    }

  }
  ngOnInit() {
    this.addNumber();
  }
  async dismiss(icon = null) {
    await this.modelCtrl.dismiss({
      dismissed: true,
      iconname: icon.slice(0, -4), //_ Remove image extention
    });
  }
  async close() {
    await this.modelCtrl.dismiss({
      dismissed: true,
    });
  }

}
