import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface DeviceChangesData { device: any, stepName: string };

@Injectable({
    providedIn: 'root'
  })

export class SetupDeviceService {
    onDeviceChange = new Subject<DeviceChangesData>();
    onDeviceSetupDone = new Subject();
    constructor(){

    }

    
  }