//_ Used for storing status in storage and list all available modes keys
export enum AppModesEnum  {
    NO_MODE='no_mode',
    CAR='car_mode', 
    PET='pet_mode', 
    PEOPLE='people_mode', 
    BUSINESS='business_mode'
}

export type AppModesType = {
    [key in AppModesEnum]: number;
}

export const AppModesArray = (translate) => {
    return [
        {
            type: 'CAR',
            name: translate.instant('appModes.car'),
            details: translate.instant('appModes.carDetails'),
            img: 'assets/images/app-modes/vehicles.png',
            icon: 'assets/images/app-modes/vehicles-icon.svg',
            dbField: 'car_mode',
            features: [
                {
                    name: translate.instant('bottom-fab.logBook'),
                    icon: 'assets/icons/logbook.svg'
                },
                {
                    name: translate.instant('dashboard.speed.name'),
                    icon: 'assets/images/dashboard/speedometer.svg'
                },
                {
                    name: translate.instant('dashboard.charts.tripandRest'),
                    icon: 'assets/images/dashboard/trip-rest.svg'
                },
                {
                    name: translate.instant('appModes.pausesMarkers'),
                    icon: 'assets/images/app-modes/stop-marker-white.svg'
                }
            ]
        },
        {
            type: 'PET',
            name: translate.instant('appModes.pet'),
            details: translate.instant('appModes.petDetails'),
            img: 'assets/images/app-modes/pets.png',
            icon: 'assets/images/app-modes/pets-icon.svg',
            dbField: 'pet_mode',
            features: [
                {
                    name: translate.instant('appModes.heatmap'),
                    icon: 'assets/images/map/heatmap-primary.svg'
                },
                {
                    name: translate.instant('dashboard.activityLabel'),
                    icon: 'assets/images/app-modes/dog-walking.svg'
                }
            ]
        },
        {
            type: 'PEOPLE',
            name: translate.instant('appModes.people'),
            details: translate.instant('appModes.peopleDetails'),
            img: 'assets/images/app-modes/people.png',
            icon: 'assets/images/app-modes/people-icon.svg',
            dbField: 'people_mode',
            features: [
                {
                    name: translate.instant('appModes.heatmap'),
                    icon: 'assets/images/map/heatmap-primary.svg'
                },
                {
                    name: translate.instant('dashboard.activityLabel'),
                    icon: 'assets/images/app-modes/dog-walking.svg'
                }
            ]
        },
        {
            type: 'BUSINESS',
            name: translate.instant('appModes.business'),
            details: translate.instant('appModes.businessDetails'),
            img: 'assets/images/app-modes/business.png',
            icon: 'assets/images/app-modes/business-icon.svg',
            dbField: 'business_mode',
            features: [
                {
                    name: translate.instant('company.menuUsers'),
                    icon: 'assets/icons/user-management.svg'
                },
                {
                    name: translate.instant('locateDevice.name'),
                    icon: 'assets/icons/locate-device.svg'
                }
            ]
        }
    ];

}