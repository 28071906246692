import { Injectable } from '@angular/core';
import { StorageService as Storage } from './../../../../services/storage.service';
import { inherit } from 'hammerjs';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

//Manage all properties that needs updated out of the page that has the map component
export class MapService {
  // Style of the map
  public _closeSlider = new BehaviorSubject(false);
  showSlider = new BehaviorSubject(false);
  public _style = new Subject<string>();
  public style$ = this._style.asObservable();
  public style;

  // Route icon for directions markers
  public _routeIcon = new Subject<string>();
  public routeIcon$ = this._routeIcon.asObservable();
  public routeIcon;

  // Icon size device markers
  public iconSize = new BehaviorSubject<{ width, height }>({ width: 50, height: 50 });

  // Drawer of the map component
  public _drawer = new Subject<{}>();
  public drawer$ = this._drawer.asObservable();

  public showPauses = new BehaviorSubject<boolean>(true);
  public showNotifications = new BehaviorSubject<boolean>(true);
  public showPolylines = new BehaviorSubject<boolean>(true);
  public showHideHeatMap = new BehaviorSubject<any>(0);
  public showDirectionMarkers = new BehaviorSubject<boolean>(true);
  public showWifiMarkers = new BehaviorSubject<boolean>(true);
  public mapStyle = new BehaviorSubject<string>('pajLite');
  public clearAllData = new Subject();
  public mapStyleDimension = new BehaviorSubject<'2d'|'3d'>('2d');
  public sliderSnapedPointsReady = new Subject();
  //_ Optimization settings
  public showDotEffect = new BehaviorSubject<boolean>(false);
  public showClusterMarkers = new BehaviorSubject<boolean>(false);
  public showDefaultImage = new BehaviorSubject<boolean>(false);
  public noAnimatedMarker = new BehaviorSubject<boolean>(false);
  public use3dMarkers = new BehaviorSubject<boolean>(false);
  public thickness = new BehaviorSubject<number>(5);
  public enableSocket = new BehaviorSubject<boolean>(false);

  public showSpeedCameras = new BehaviorSubject<boolean>(true);
  public showSubAccountMarkers = new BehaviorSubject<boolean>(false);
  public isFabMenuOpen = new BehaviorSubject<boolean>(false);
  public showPullup = new BehaviorSubject<boolean>(false);
  public showDeviceNameMarker = new BehaviorSubject<boolean>(false);
  public deviceNamefontSize = new BehaviorSubject<number>(12);
  public showSpeedPolylines = new BehaviorSubject<boolean>(false);
  public showSpeedPolylinesBtn = new BehaviorSubject<boolean>(false);
  public mapMonitorMode = new BehaviorSubject<boolean>(false);

  public _heatMapMode = new Subject<boolean>();
  public heatMapMode$ = this._heatMapMode.asObservable();
  public heatMapMode;

  // Store icon size | if doesnt exist, then create it
  constructor(private storage: Storage) {
    this.storage.storageReady.subscribe(r => {
      if (r) {
        this.storage.get('iconSize').then(res => {
          if (res == null || res == 'undefined') {
            this.storage.set('iconSize', this.iconSize.value);
            res = this.iconSize.value;
          }

          this.iconSize.next(res);
        });

        this.storage.get('showNotifications').then(res => {
          if (res === null) {
            this.storage.set('showNotifications', true);
            res = true;
          }

          this.showNotifications.next(res);
        });

        this.storage.get('showPauses').then(res => {
          if (res == null) {
            this.storage.set('showPauses', true);
            res = true;
          }

          this.showPauses.next(res);
        });

        this.storage.get('showPolylines').then(res => {
          if (res == null) {
            this.storage.set('showPolylines', true);
            res = true;
          }

          this.showPolylines.next(res);
        });

        this.storage.get('showHideHeatMap').then(res => {
          if (res == null) {
            this.storage.set('showHideHeatMap', 0);
            res = 0;
          }

          this.showHideHeatMap.next(res);
        });

        this.storage.get('showDirectionMarkers').then(res => {
          if (res == null) {
            this.storage.set('showDirectionMarkers', true);
            res = true;
          }

          this.showDirectionMarkers.next(res);
        });

        this.storage.get('mapStyle').then(res => {
          if (res == null) {
            this.storage.set('mapStyle', 'pajLite');
            res = 'pajLite';
          }
          this.mapStyle.next(res);
        });

        this.storage.get('mapStyleDimension').then(res => {
          if (res == null) {
            this.storage.set('mapStyleDimension', '2d');
            res = '2d';
          }
          this.mapStyleDimension.next(res);
        });

        this.storage.get('thickness').then(res => {
          if (res == null) {
            this.storage.set('thickness', 5);
            res = 5;
          }

          this.thickness.next(res);
        });

        //_ Disabled Jakob said it should be shown always. by default is true
        // this.storage.get('showWifiMarkers').then( res => {
        //   if (res == null) {
        //     this.storage.set('showWifiMarkers', true);
        //     res = true;
        //   }

        //   this.showWifiMarkers.next(res);
        // });

        //_ Optimization settings
        this.storage.get('showDotEffect').then(res => {
          if (res == null) {
            this.storage.set('showDotEffect', false);
            res = false;
          }
          this.showDotEffect.next(res);
        });

        this.storage.get('showClusterMarkers').then(res => {
          if (res == null) {
            this.storage.set('showClusterMarkers', false);
            res = false;
          }
          this.showClusterMarkers.next(res);
        });

        this.storage.get('showDefaultImage').then(res => {
          if (res == null) {
            this.storage.set('showDefaultImage', false);
            res = false;
          }
          this.showDefaultImage.next(res);
        });

        this.storage.get('noAnimatedMarker').then(res => {
          if (res == null) {
            this.storage.set('noAnimatedMarker', false);
            res = false;
          }
          this.noAnimatedMarker.next(res);
        });

        this.storage.get('use3dMarkers').then(res => {
          if (res == null) {
            this.storage.set('use3dMarkers', false);
            res = false;
          }

          //_ DISABLE BECAUSE THE Threbox issue
          //_ https://github.com/jscastro76/threebox/issues/375
          // this.use3dMarkers.next(res);
          this.use3dMarkers.next(false);
        });

        this.storage.get('enableSocket').then(res => {
          if (res == null) {
            this.storage.set('enableSocket', false);
            res = false;
          }
          this.enableSocket.next(false);
        });

        this.storage.get('showSpeedCameras').then(res => {
          if (res == null) {
            this.storage.set('showSpeedCameras', false);
            res = false;
          }

          this.showSpeedCameras.next(res);
        });

        this.storage.get('showSubAccountMarkers').then(res => {
          if (res == null) {
            this.storage.set('showSubAccountMarkers', false);
            res = false;
          }

          this.showSubAccountMarkers.next(res);
        });

        this.storage.get('showDeviceNameMarker').then(res => {
          if (res == null) {
            this.storage.set('showDeviceNameMarker', false);
            res = false;
          }

          this.showDeviceNameMarker.next(res);
        });

        this.storage.get('deviceNamefontSize').then(res => {
          if (res == null) {
            this.storage.set('deviceNamefontSize', 12);
            res = 12;
          }

          this.deviceNamefontSize.next(res);
        });

        this.storage.get('showSpeedPolylines').then(res => {
          if (res == null) {
            this.storage.set('showSpeedPolylines', false);
            res = false;
          }

          this.showSpeedPolylines.next(res);
        });

        this.storage.get('showSpeedPolylinesBtn').then(res => {
          if (res == null) {
            this.storage.set('showSpeedPolylinesBtn', false);
            res = false;
          }

          this.showSpeedPolylinesBtn.next(res);
        });

        this.storage.get('mapMonitorMode').then(res => {
          if (res == null) {
            this.storage.set('mapMonitorMode', false);
            res = false;
          }

          this.mapMonitorMode.next(res);
        });

        this.init();
      }

      this.storage.get("heatMapMode").then((r) => {
        this.heatMapMode = r ? r : false;
      });
    });

  }

  init() {
    //this.storage.get('showPauses').then( r => this.showPauses.next(r) );
    //this.storage.get('showPolylines').then( r => this.showPolylines.next(r) );

    // Update iconSize in storage
    this.iconSize.subscribe(async ics => {
      await this.storage.set('iconSize', ics);
    });

    this.showNotifications.subscribe(async response => {
      if(!this.showHideHeatMap.value)
      {
        await this.storage.set('showNotifications', response);
      }
    });

    this.showPauses.subscribe(async response => {
      if(!this.showHideHeatMap.value)
      {
        await this.storage.set('showPauses', response);
      }
    });

    this.showPolylines.subscribe(async response => {
      if(!this.showHideHeatMap.value)
      {
        await this.storage.set('showPolylines', response);
      }

    });
    this.showHideHeatMap.subscribe(async r => {
      await this.storage.set('showHideHeatMap', r);
    });

    this.showDirectionMarkers.subscribe(async response => {
      if(!this.showHideHeatMap.value)
      {
        await this.storage.set('showDirectionMarkers', response);
      }
    });

    this.mapStyle.subscribe(async r => {
      await this.storage.set('mapStyle', r);
    });
    this.mapStyleDimension.subscribe(async r => {
      await this.storage.set('mapStyleDimension', r);
    });

    this.showWifiMarkers.subscribe(async response => {
      if(!this.showHideHeatMap.value)
      {
        await this.storage.set('showWifiMarkers', response);
      }
    });

    //_ Performance settings
    this.showDotEffect.subscribe(async r => {
      await this.storage.set('showDotEffect', r);
    });

    this.showClusterMarkers.subscribe(async r => {
      await this.storage.set('showClusterMarkers', r);
    });

    this.showDefaultImage.subscribe(async r => {
      await this.storage.set('showDefaultImage', r);
    });

    this.noAnimatedMarker.subscribe(async r => {
      await this.storage.set('noAnimatedMarker', r);
    });

    this.thickness.subscribe(async ics => {
      await this.storage.set('thickness', ics);
    });

    this.use3dMarkers.subscribe(async status => {
      await this.storage.set('use3dMarkers', status);
    });

    this.enableSocket.subscribe(async status => {
      await this.storage.set('enableSocket', status);
    });

    this.showSpeedCameras.subscribe(async response => {
      if(!this.showHideHeatMap.value)
      {
        await this.storage.set('showSpeedCameras', response);
      }
    });

    this.showSubAccountMarkers.subscribe(async status => {
      await this.storage.set('showSubAccountMarkers', status);
    });

    this.showDeviceNameMarker.subscribe(async status => {
      await this.storage.set('showDeviceNameMarker', status);
    });

    this.deviceNamefontSize.subscribe(async status => {
      await this.storage.set('deviceNamefontSize', status);
    });

    this.showSpeedPolylines.subscribe(async status => {
      if(!this.showHideHeatMap.value)
      {
        await this.storage.set('showSpeedPolylines', status);
        }
    });

    this.showSpeedPolylinesBtn.subscribe(async status => {
      await this.storage.set('showSpeedPolylinesBtn', status);
    });

    this.mapMonitorMode.subscribe(async status => {
      await this.storage.set('mapMonitorMode', status);
    });

    this.heatMapMode$.subscribe(async (dm) => {
      this.heatMapMode = dm;
      // document.body.setAttribute('color-theme', dm ? 'dark' : 'light');
      await this.storage.set("heatMapMode", dm);
    });
  }
}

export const DEFAULT_MAX_DISTANCE_TO_SPLIT_SEGMENTS = 2150;

export const roundCoordinates = (number) => {
  // Convert the number to a string and split it into two parts: integer and decimal
  const parts = number.toString().split(".");

  if (parts) {
    if (parts.length === 1)  return number;
    else if (parts.length === 2) {
      if (parts[1].length > 15)
        return parseFloat(number.toFixed(15));
      // Return the number unchanged if it doesn't need rounding
      return number;
    } else {
      let newNumber = parts[0].concat(parts[1]);
      if (newNumber.length > 15) {
        newNumber = parseFloat(newNumber);
        return  parseFloat(newNumber.toFixed(15));
      } else return parseFloat(newNumber);
    }
  }
}
