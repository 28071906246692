import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharelinkPopupComponent } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/info/sharelink-popup/sharelink-popup.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { InfoComponent } from './info.component';
import { PajInfoPopupModule } from 'src/app/components/cspopover/paj-info-popover.module';
import { VibrateOnToggleDirectiveModule } from 'src/app/directives/vibration-for-toggle-buttons.module';
import { SharelinkOptionsModule } from './sharelink-popup/sharelink-options.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/translations/", ".json");
}

@NgModule({
    imports: [
      CommonModule, 
      IonicModule,
      FormsModule,
      ReactiveFormsModule,
      PipesModule.forRoot(),
      DirectivesModule,
      PajInfoPopupModule,
      SharelinkOptionsModule,
      VibrateOnToggleDirectiveModule,
      TranslateModule.forChild({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          }
        }),
    ],

    declarations: [InfoComponent],
    exports: [InfoComponent, SharelinkOptionsModule, PipesModule, DirectivesModule, VibrateOnToggleDirectiveModule],
    
  })

  export class PajDevicesInfoModule {}