const { noTarget, isOfMetaType, isInactiveFeature, isShiftDown } = require('@mapbox/mapbox-gl-draw/src/lib/common_selectors');
const MapboxDraw = require('@mapbox/mapbox-gl-draw');
const createSupplementaryPoints = require('@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points');
const moveFeatures = require('@mapbox/mapbox-gl-draw/src/lib/move_features');
const Constants = require('@mapbox/mapbox-gl-draw/src/constants');
const constrainFeatureMovement = require('@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement');
const distance = require('@turf/distance').default;
const turfHelpers = require('@turf/helpers');
const circle = require('@turf/circle').default;
const createSupplementaryPointsForCircle = require('../utils/create_supplementary_points_circle');
const turf = require('@turf/turf');
const CommonSelectors = require('@mapbox/mapbox-gl-draw/src/lib/common_selectors');
const {calculateHandlePoint} = require("../../../../geofence_center");
const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);

const DirectModeOverride = MapboxDraw.modes.direct_select;
var isVertexCircleClicked = false;

DirectModeOverride.dragFeature = function (state, e, delta) {
  // if(window['radiusPopup'] && state.feature.properties.isCircle){
  //   feature1 = this._ctx.api.getSelected().features[0]
  //   var polygon = turf.polygon(feature1.geometry.coordinates);
  //   var centroid = turf.centroid(polygon);

  //   // if(window['radiusPopup'].isOpen()){
  //   //   const radiusInKm = Number((feature1.properties.radiusInKm).toFixed(2));
  //   //   let text = radiusInKm + ' km';

  //   //   if (radiusInKm < 1)
  //   //     text = (radiusInKm*1000) + ' mts';
  //   //   window['radiusPopup'].setLngLat(centroid.geometry.coordinates).setHTML('radius: ' + text);

  //   // }else{
  //   //   const radiusInKm = Number((feature1.properties.radiusInKm).toFixed(2));
  //   //   let text = radiusInKm + ' km';
  //   //   if (radiusInKm < 1)
  //   //     text = (radiusInKm*1000) + ' mts';
  //   //   window['radiusPopup'].setLngLat(centroid.geometry.coordinates).setHTML('radius: ' + text).addTo(this.map);;
  //   // }
  // }



  moveFeatures(this.getSelected(), delta);
  this.getSelected()
    .filter(feature => feature.properties.isCircle)
    .map(circle => circle.properties.center)
    .forEach(center => {
      center[0] += delta.lng;
      center[1] += delta.lat;
    });
  state.dragMoveLocation = e.lngLat;
};
DirectModeOverride.startDragging = function (state, e) {

  if (state.vertexIs) {
    this.map.dragPan.disable();
  } else {
    this.map.dragPan.enable();
  }
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
};


DirectModeOverride.onMidpoint = function (state, e) {
  state.vertexIs = 1;
  this.map.dragPan.disable();
  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  state.feature.addCoordinate(about.coord_path, about.lng, about.lat);
  this.fireUpdate();
  state.selectedCoordPaths = [about.coord_path];
};

DirectModeOverride.onVertex = function (state, e) {
  // console.log('On vertex', { isVertex: isVertex(e), points: e.points.length });

  //_ No enable vertex drag when two touch events are send
  if (e.points?.length > 1) {
    console.log('NOT ENABLING VERTEX');
    return;
  }

  this.isVertexCircleClicked = true;
  state.vertexIs = 1;
  this.map.dragPan.disable();
  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  const feature = state.feature;
  const selectedIndex = state.selectedCoordPaths.indexOf(about.coord_path);

  if (feature.properties.isCircle) e.originalEvent.preventDefault();
  if (!isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths = [about.coord_path];
  } else if (isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths.push(about.coord_path);
  }

  const selectedCoordinates = this.pathsToCoordinates(state.featureId, state.selectedCoordPaths);
  this.setSelectedCoordinates(selectedCoordinates);
};

DirectModeOverride.dragVertex = function (state, e, delta) {
  // console.log('DRAG vERTEX', { isVertex: isVertex(e.originalEvent), e});
  if (state.feature.properties.isCircle) {
    const poly = turf.polygon(this._ctx.api.getSelected().features[0].geometry.coordinates);
    const center = state.feature.properties.center;
    //const center = turf.centroid(poly).geometry.coordinates;
    const movedVertex = [e.lngLat.lng, e.lngLat.lat];

    // if(window['radiusPopup'] && state.feature.properties.isCircle){
    //   feature1 = this._ctx.api.getSelected().features[0]

    //   var polygon = turf.polygon(feature1.geometry.coordinates);
    //   var centroid = turf.centroid(polygon);

    //   // if(window['radiusPopup'].isOpen()){
    //   //   const radiusInKm = Number((feature1.properties.radiusInKm).toFixed(2));
    //   // let radiusText = radiusInKm + ' km';
    //   // if (radiusInKm < 1)
    //   //   radiusText = (radiusInKm*1000) + ' m';
    //   //   window['radiusPopup'].setLngLat(centroid.geometry.coordinates).setHTML(
    //   //     '<h6>' + feature1.properties.deviceName + '</h6>'
    //   //     +'<p>radius: ' + radiusText + '</p>');

    //   // }else{
    //   //   const radiusInKm = Number((feature1.properties.radiusInKm).toFixed(2));
    //   // let text = radiusInKm + ' km';
    //   // if (radiusInKm < 1)
    //   //   radiusText = (radiusInKm*1000) + ' m';
    //   //   window['radiusPopup'].setLngLat(centroid.geometry.coordinates).setHTML(
    //   //     '<h6>' + feature1.properties.deviceName + '</h6>'
    //   //     +'<p>radius: ' + radiusText + '</p>'
    //   //   ).addTo(this.map);;
    //   // }
    // }

    const radius = distance(turfHelpers.point(center), turfHelpers.point(movedVertex), { units: 'kilometers' });
    const circleFeature = circle(center, radius);
    state.feature.incomingCoords(circleFeature.geometry.coordinates);
    state.feature.properties.radiusInKm = radius;
  } else {
    const selectedCoords = state.selectedCoordPaths.map(coord_path => state.feature.getCoordinate(coord_path));
    const selectedCoordPoints = selectedCoords.map(coords => ({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: coords
      }
    }));

    const constrainedDelta = constrainFeatureMovement.default(selectedCoordPoints, delta);
    for (let i = 0; i < selectedCoords.length; i++) {
      const coord = selectedCoords[i];
      state.feature.updateCoordinate(state.selectedCoordPaths[i], coord[0] + constrainedDelta.lng, coord[1] + constrainedDelta.lat);
    }
  }
};

DirectModeOverride.toDisplayFeatures = function (state, geojson, push) {
  //console.log('Overwrite toDisplayFeatures');
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = Constants.activeStates.ACTIVE;
    push(geojson);
    const supplementaryPoints = geojson.properties.user_isCircle ? createSupplementaryPointsForCircle(geojson)
      : createSupplementaryPoints.default(geojson, {
        map: this.map,
        midpoints: true,
        selectedPaths: state.selectedCoordPaths
      });

    supplementaryPoints.forEach(push);
  } else {
    geojson.properties.active = Constants.activeStates.INACTIVE;
    push(geojson);
  }
  this.fireActionable(state);

}
DirectModeOverride.onTouchEnd = DirectModeOverride.onMouseUp = function (state) {
  // console.log('Touch end');
  this.isVertexCircleClicked = false;
  // if(window['radiusPopup'].isOpen()){
  //   //window['radiusPopup'].remove();
  // }
  if (state.dragMoving) {
    this.fireUpdate();
  }
  this.stopDragging(state);
};



//_ On click in vertex this method delete the vertex if is checked with 1
//_ Also for mobiles on Tap
DirectModeOverride.onClick = DirectModeOverride.onTap = function (state, e) {
  // console.log('On Clik|tap');
  const feature = state.feature;
  //_ Not delete points of circle geofence
  if (feature.properties.isCircle && this.isVertexCircleClicked) {
    this.isVertexCircleClicked = false;
    this.map.dragPan.disable();
    this.startDragging(state, e);

    if (state.dragMoving) {
      this.fireUpdate();
    }
    this.stopDragging(state);
    return null;
  }

  if (e.featureTarget != undefined && state.vertexIs && !(3 >= state.feature.coordinates[0].length)
  ) {
    DirectModeOverride.onTrash(state);
  }

  if (noTarget(e)) return this.clickNoTarget(state, e);
  if (CommonSelectors.isActiveFeature(e)) return this.clickActiveFeature(state, e);
  if (isInactiveFeature(e)) return this.clickInactive(state, e);
  this.stopDragging(state);
};

// DirectModeOverride.onTap = function(state, e) {
//   const feature = state.feature;
//   //_ Not delete points of circle geofence
//   if (feature.properties.isCircle && this.isVertexCircleClicked ){
//     this.isVertexCircleClicked = false;
//     return null;
//   }

//   if(e.featureTarget != undefined && state.vertexIs && !(3 >= state.feature.coordinates[0].length)
//   ){
//     DirectModeOverride.onTrash(state);
//   }
//   if (noTarget(e)) return this.clickNoTarget(state, e);
//   if (CommonSelectors.isActiveFeature(e)) return this.clickActiveFeature(state, e);
//   if (isInactiveFeature(e)) return this.clickInactive(state, e);
//   // this.stopDragging(state);
// };

DirectModeOverride.onTrash = function (state) {
  DirectModeOverride.trash(state);
};

DirectModeOverride.trash = function (state) {
  state.selectedCoordPaths.sort().reverse().forEach(id => state.feature.removeCoordinate(id));
  // this.fireUpdate();
  state.selectedCoordPaths = [];
  // this.clearSelectedCoordinates();
  // this.fireActionable(state);
  if (state.feature.isValid() === false) {
    this.deleteFeature([state.featureId]);
    this.changeMode(Constants.modes.direct_select, {});
  }

  let feature1 = state.feature.ctx.api.getSelected().features[0]
  var centroid = calculateHandlePoint(feature1);
  window[feature1.id].setLngLat({ lng: centroid.geometry.coordinates[0], lat: centroid.geometry.coordinates[1] });

  state.feature.ctx.map.fire(Constants.events.UPDATE, {
    action: Constants.updateActions.CHANGE_COORDINATES,
    features: state.feature.ctx.store.getSelected().map(f => f.toGeoJSON())
  });
};

module.exports = DirectModeOverride;
