import { Platform, AlertController } from '@ionic/angular';
import { EventEmitter, Injectable } from '@angular/core';
import { StorageService as Storage } from './storage.service';
import { BehaviorSubject, Observable, Subject, takeUntil, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEventType, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Constants } from '../constants.enum';
import { environment as ENV, environment } from 'src/environments/environment';
import { PolylinePoints, DevicePosition } from '../geo-json-template';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { fileDownloadService } from './filedownload.service';
import { STORAGE_SETTINGS } from './storage-settings';
import { CancelablePromise } from '../util/cancelablePromise';
import { getImageLinkByFilename, MEDIA_OBJECT } from '../components/media-managment/media-interfaces';

const TOKEN_KEY = 'auth-token';
const IS_ADMIN = 'super_user';
interface ImageName {
  iconcustomimage: any;
}
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  //_ isUserLogin Deprecated prop -> remove in next refactor
  isUserLogin = false;
  userId = 0;
  profileImg = '';
  userName = '';
  pin = '';
  initClose = 1;
  authenticationState = new BehaviorSubject(false);
  platform = new BehaviorSubject(false);
  url: string = ENV.ROUTE;
  logoutStatus = new BehaviorSubject(false);
  userData = new BehaviorSubject<any>([]);
  profileImgUrl = new BehaviorSubject<any>('');
  componentName = new Subject<any>();

  userUpdate = new BehaviorSubject(false);
  clearData = new Subject();

  constructor(
    private storage: Storage,
    private plt: Platform,
    public httpClient: HttpClient,
    private alertCtrl: AlertController,
    private downloadService: fileDownloadService) {
    this.storage.storageReady.subscribe(r => {
      if (r)
        this.checkToken();
    });
  }


  getDeviceList(searchText, id) {
    let url = '';
    if (Number(id) === 0) {
      url = ENV.ROUTE + ENV.API_VERSION + 'a/searchDevice?userName=' + searchText;
    } else {
      url = ENV.ROUTE + ENV.API_VERSION + 'a/device/' + id;
    }
    return this.httpClient.get(url);
  }

  initUserData() {
    this.userData.subscribe(data => {
      this.profileImg = data.customer_img;
      this.setProfileImageUrl();
    }, error => {
      console.log("ERROR: ", error);
    });
  }

  async setProfileImageUrl() {
    const mediaServier = ENV.mediaServer;
    if (this.userId === 0) {
      await this.storage.get(Constants.USER_ID).then(res => {
        this.userId = res;
      });
    }
    if (!this.profileImg) {
      this.profileImgUrl.next('assets/images/company/user.svg');
    } else {
      this.profileImgUrl.next(this.profileImg);
    }
  }

  checkToken() {
    this.storage.getFromLocalOrSession(Constants.TOKEN_KEY).then(async res => {
      this.userId = await this.storage.get(Constants.USER_ID);
      if (res) {
        if (await this.storage.get('logoutOnClose') == true && !sessionStorage.getItem("tabId")) {
          sessionStorage.removeItem("tabId");
          sessionStorage.setItem("tabId", crypto.randomUUID());
          // this.authenticationState.next(false);
          // this.clearData.next(true);
        }
        this.authenticationState.next(true);
      } else {
        this.authenticationState.next(false);
      }
      this.platform.next(true);
    });
  }


  async login(email: string, password: string, language: string) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const postData = {
      email,
      password,
      language,
      timezone
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'login', postData).subscribe( data => {
        this.loginUser(data);
        resolve(data);
      }, (error) => {
        console.log("ERROR: ", error);
        this.authenticationState.next(false);
        reject(error);
      })
    });
  }

  loginTest() {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'loginTest', {}).subscribe(data => {
      this.loginUser(data);
      resolve(data);
    }, async (error) => {
      console.log("ERROR: ", error);
      this.authenticationState.next(false);
      resolve(error);
    });
    })
  }

  loginUser(data) {
    if (data['success']['refresh_token'])
      this.storage.set(Constants.REFRESH_TOKEN_KEY, data['success']['refresh_token']);
    else
      this.storage.remove(Constants.REFRESH_TOKEN_KEY);

    this.storage.set(Constants.TOKEN_KEY, data['success']['token']);
    this.storage.set(Constants.SUPER_USER, data['success']['super_user']);
    this.storage.set(Constants.USER_ID, data['success']['userID']);
    this.storage.set(Constants.ROUTE_ICON, data['success']['routeIcon']);
    if (data['success']['super_user']){
      this.storage.set(STORAGE_SETTINGS.ADMIN_ID, data['success']['userID']);
    }else{
      this.storage.remove(STORAGE_SETTINGS.ADMIN_ID);
    }

    this.userId = data['success']['userID'];
    this.storage.set('pulsingDevices', []);
    this.authenticationState.next(true);
  }

  async refreshToken(postData) {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'updatetoken',
      postData).subscribe(data => {
        this.storage.set(Constants.TOKEN_KEY, data['success']['token']);
        this.storage.set(Constants.REFRESH_TOKEN_KEY, data['success']['refresh_token']);
        //_ Instead call next for authentication, we should refresh the page to load all data
        //_ in a clean way, if we call authState next it will trigger more things appService (request user, devices, settings ...)
        //this.authenticationState.next(true);
        window.location.reload();
        resolve(data);
      }, error => {
        //_ Call logout to clean storage data and restart the flow of authentication next(false)
        this.logout();
        reject(null);
      });
    })

  }

  async registerFBToken(token) {
    const postData = {
      fbtoken: token
    };

    // console.log('LOGOUT - ', token);
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'fcm/register', postData).subscribe( res => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });

  }
  async logOut(fcm_token) {
    const postData = {
      fbtoken: fcm_token
    };

    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'logout', postData).subscribe(res => {
        resolve(res);
      }, error => {
        reject(null);
      });
    });

  }

  async getDevices() {
    return await this.storage.get(STORAGE_SETTINGS.ISGHOST).then(async data => {
      let url = '';
      if (data) {
        url = ENV.ROUTE + ENV.API_VERSION + 'a/device/' + this.userId;
        // this.isUserLogin = false;
      } else {
        url = ENV.ROUTE + ENV.API_VERSION + 'device';
        // this.isUserLogin = true;
      }

      return new Promise<any>((resolve, reject) => {
        this.httpClient.get(url).subscribe(res => {
          resolve(res);
        }, error => {
          console.log("ERROR: ", error);
          reject(null);
        });
      });

    });
  }

  async getRoutes(deviceId: number, pagecount: number, carId = null, params = null, distanceUnit = 'km', unmarkedAndwoReason = null) {
    const language = await this.storage.get(Constants.LANGUAGE);
    const userID = await this.storage.get(Constants.USER_ID);
    const url = carId ? ENV.ROUTE + ENV.API_VERSION + `logbook/getAllRoutes/${deviceId}/${carId}?page=${pagecount}&distance_unit=${distanceUnit}&c_id=${userID}` :
    ENV.ROUTE + ENV.API_VERSION + `logbook/getAllRoutes/${deviceId}?page=${pagecount}&distance_unit=${distanceUnit}&c_id=${userID}`;
    if (params){
      params = params.append('language', language);
      if(unmarkedAndwoReason == 1){
        params = params.append('unmarkedAndwoReason', unmarkedAndwoReason);
      }

    }
    else{
      params = { language, ...(unmarkedAndwoReason == 1 ? { unmarkedAndwoReason } : {}) };
    }

    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url, { params }).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getNewLogs(deviceId: number) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `logbook/getNewLogs/${deviceId}`)
        .subscribe(res => {
          resolve(res);
        }, error => {
          console.log("ERROR: ", error);
          reject(error);
      });
    });

  }
  async updateRouteType(route, iddevice, routeID, routeInfo: any = null) {
    // route.privateD = Number((+route.privateD).toFixed(3));
    // route.workD = Number((+route.workD).toFixed(3));
    // route.toWorkD = Number((+route.toWorkD).toFixed(3));
    // console.log('ROUTE-> ', route)
    if (route.privateD || route.workD || route.toWorkD) {
      // Update properties only if route is not null
      route.privateD = Number((+route.privateD).toFixed(3));
      route.workD = Number((+route.workD).toFixed(3));
      route.toWorkD = Number((+route.toWorkD).toFixed(3));
      console.log('ROUTE-> ', route);
  }
    const putData = {
      'route': route,
      'routeInfo': routeInfo,
    };
    let url = ENV.ROUTE + ENV.API_VERSION + `logbook/updateRoute/${iddevice}/${routeID}`; //GET contact DATA IF IS IN GHOST MODE
    if (await this.storage.get(Constants.SUPER_USER) == 1)
      url = ENV.ROUTE + ENV.API_VERSION + `a/logbook/updateRoute/${iddevice}/${routeID}/` + await this.storage.get(Constants.USER_ID);

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(url, putData).subscribe(data => {
          resolve(data);
      }, error => {
          console.log("ERROR: ", error);
          reject(null);
      });
    });
  }

  async updateRouteDriver(iddevice, routeID, driverId) {
    return new Promise<any> ((resolve, reject) => {
    this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + `logbook/updateDriver/${iddevice}/${routeID}`, { driverId })
      .subscribe(data => {
        resolve(data);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async updateRouteDriversForSingleDay(iddevice, routes) {
    let putData = {
      'routes' : routes
    };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + `logbook/updateDayDrivers/${iddevice}`, putData).subscribe(data => {
        resolve(data);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async updateRouteForSingleDay(iddevice, routes, route_type, extra) {
    let putData = {
      'routes': routes,
      'route_type': route_type
    };

    let mergedData = {
      ...putData,
      ...extra
    };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + `logbook/updateDayRoutes/${iddevice}`, mergedData).subscribe(data => {
        resolve(data);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getTrackerDataForLogBook(deviceID, startID, endID) {
    const getData = {
      startID,
      endID
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'logbook/getRouteMarkers/' + deviceID, { params: getData }).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }
  async getUserContacts() {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/contact/'; //GET contact DATA IF IS IN GHOST MODE
    if (await this.storage.get(Constants.SUPER_USER) == 1)
      url = ENV.ROUTE + ENV.API_VERSION + 'a/logbook/contact/' + await this.storage.get(Constants.USER_ID);

      return new Promise<any> ((resolve, reject) => {
        this.httpClient.get(url).subscribe(res => {
          resolve(res);
        }, error => {
          console.log("ERROR: ", error);
          reject(null);
        });
    });

  }
  async getUserReasons() {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/reason/'; //GET reason DATA IF IS IN GHOST MODE
    if (await this.storage.get(Constants.SUPER_USER) == 1)
      url = ENV.ROUTE + ENV.API_VERSION + 'a/logbook/reason/' + await this.storage.get(Constants.USER_ID);

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(url).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }
  async getUserDrivers() {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/driver/'; //GET driver DATA IF IS IN GHOST MODE
    if (await this.storage.get(Constants.SUPER_USER) == 1)
      url = ENV.ROUTE + ENV.API_VERSION + 'a/logbook/driver/' + await this.storage.get(Constants.USER_ID);

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(url).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getUserRouteInfo(deviceId = null, carDeviceId = null) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/getUserRouteInfo/'; //GET CUSTOMER DATA IF IS IN GHOST MODE
    if (await this.storage.get(Constants.SUPER_USER) == 1)
      url = ENV.ROUTE + ENV.API_VERSION + 'a/logbook/getUserRouteInfo/' + await this.storage.get(Constants.USER_ID);

    if (deviceId && carDeviceId)
      url += '?deviceId=' + deviceId + '&carDeviceId=' + carDeviceId;

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(url).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getDateRange(car) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `logbook/date-range/${car.iddevice}/${car.id}`)
      .subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  getLogbookCosts (cardDeviceId, parameters) {
    return this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + `logbook/allcosts/${cardDeviceId}`, parameters);
  }

  async getChartData(data) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + `logbook/chart-data`, data).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async clearAllTokens(email) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + "cleartoken?email=" + email + "&auth=LmHjW!49nVY^!Zg4r6j8aa", '').subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getDevicesModel(listofIDs) {
    const postData = {
      model_nrs: listofIDs
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'deviceModel', postData).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getGeoFences(listofIDs) {
    const postData = {
      deviceIDs: JSON.parse(JSON.stringify(listofIDs))
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'geofences', postData).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getAllLastPositionsPost(listofIDs, fromLastPoint = false) {
    const postData = {
      deviceIDs: listofIDs,
      fromLastPoint
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'trackerdata/getalllastpositions', postData).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getAllLastPositions(listofIDs) {
    const postData = {
      deviceIDs: listofIDs
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'trackerdata/getalllastpositions?deviceIDs='+listofIDs.join()).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getAllDatapoints(listofIDs, wifi = 1, gps = 1) {
    const postData = {
      deviceIDs: listofIDs,
      wifi, gps
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'trackerdata/getalldatapoints', postData).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getSingleDeviceData(deviceID) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'device/' + deviceID).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(error);
      });
    });

  }

  async getDevicePoints(deviceID: number, type: any, dateStart: any, dateEnd: any, lastMinutes: any, lastPoints: any, wifi = 1, gps = 1) {
    let getData = null;
    let url = ENV.API_VERSION + 'trackerdata/' + deviceID;

    switch (type) {
      case 0: { // Last Minutes
        getData = {
          lastMinutes: lastMinutes.toString()
        };
        url = url + '/last_minutes';
        break;
      }
      case 1: { // last Points
        getData = {
          lastPoints: lastPoints.toString()
        };
        url = url + '/last_points';
        break;
      }
      case 4: { // date range
        getData = {
          dateStart: dateStart.toString(),
          dateEnd: dateEnd.toString()
        };
        url = url + '/date_range';
        break;
      }
      default: {
        getData = {
          lastMinutes: lastMinutes.toString(),
          lastPoints: lastPoints.toString(),
          dateStart: dateStart.toString(),
          dateEnd: dateEnd.toString()
        };
        url = url + '/last_points';
        break;
      }
    }

    getData = { ...getData, wifi, gps };
    return new Promise<any> ((resolve, reject) => {
      return this.httpClient.get(ENV.ROUTE + url, { params: getData }).subscribe((res: any) => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });
  }

  async generateReport(postData,car) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    headers.set('Content-Type', 'application/download');

    let promise = new Promise<any>((resolve, reject) => {
      this.httpClient.post(ENV.FILE_ROUTE + ENV.API_VERSION + 'logbook/route-export/',
        postData, {
        headers: headers,
        responseType: 'blob',
      }).subscribe((data: Blob) => {
        this.openReportInWindow(postData.startDate, postData.endDate, car, data, postData);
        resolve(true);
      },
        (error) => {
          console.log("ERROR: ", error);
          reject();
        }

      );

    });

    return promise;
  }

  //_ Download report for deeplink validation page
  async getReportbyHash(postData) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    headers.set('Content-Type', 'application/download');

    let promise = new Promise<any>((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'logbook/reports',
        postData, {
        headers: headers,
        responseType: 'blob',
      }).subscribe((data: Blob) => {
        //return data;
        //this.openReportInWindow(start, end, car, data, false);
        resolve(data);
      }, (error) => {
        console.log("ERROR: ", error);
        reject();
      });
    });

    return promise;
  }

  openReportInWindow(start, end, car, data, postData, newWindow = true) {

    //var file = new Blob([data], { type: 'application/pdf' })
    //var fileURL = window.URL.createObjectURL(file);

    let fileName = 'logbook - ' + car.plate_id + ' - ' + moment(start).format('DD-MM-YYYY') + '--' + moment(end).format('DD-MM-YYYY') + '.pdf';

    if(postData.type == 'month'){
      const startMonth = postData.months[0] < 9? '0'+postData.months[0]: postData.months[0];
      if(postData.months.length > 1){
        const endMonth = postData.months[postData.months.length-1] < 9? '0'+postData.months[postData.months.length-1]: postData.months[postData.months.length-1];
        fileName = 'logbook - ' + car.plate_id + ' - ' + startMonth + '-' + moment(start).format('YYYY') + '--' +  endMonth + '-' + moment(end).format('YYYY') + '.pdf';
      }
      else
      fileName = 'logbook - ' + car.plate_id + ' - ' + startMonth + '-' + moment(end).format('YYYY') + '.pdf';
    }
    else if(postData.type == 'year')
      fileName = 'logbook - ' + car.plate_id + ' - ' + moment(end).format('YYYY') + '.pdf';

    // if you want to open PDF in new tab
    /*window.open(fileURL);
    var a         = document.createElement('a');
    a.href        = fileURL;
    a.target      = newWindow?'_blank':'_self';
    a.download    = fileName;
    document.body.appendChild(a);
    a.click();
*/

    this.downloadService.downloadBlob(data, fileName, 'application/pdf');
  }

  async updateUserInfo(firstname: string, lastname: string, email: string, UserID: number) {
    const putData = {
      firstname,
      email,
      lastname,
    };
    return this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'customer/' + UserID, putData).subscribe(data => {
      this.alertCtrl.create({
        header: 'User',
        message: 'User updated successfully',
        buttons: ['OK']
      }).then(alertEl => {
        alertEl.present();
      });

      this.userUpdate.next(true);
      return data;
    }, error => {
      console.log("ERROR: ", error);
      return null;
    });
  }
  async updateUserRouteInfo(data, routename) {
    console.log("updateUserRouteInfo triggered in authentication service")
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + `logbook/${routename}/` + data.id, data).subscribe(data => {
      resolve(data);
    }, error => {
      console.log("ERROR: ", error);
      resolve(null);
    });
    });

  }

  async updateUser(data, userId) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'customer/' + userId, data).subscribe(res => {
      resolve(res);
    }, error => {
      console.log("ERROR: ", error);
      reject(null);
    });
    });

  }


  async addLogbookUserInfo(data, routename) {
    console.log("addLogbookUserInfo in authentication service")
    let url = ENV.ROUTE + ENV.API_VERSION + `logbook/${routename}/`;
    if (await this.storage.get(Constants.SUPER_USER) == 1)
    url = ENV.ROUTE + ENV.API_VERSION + `a/logbook/${routename}/` + await this.storage.get(Constants.USER_ID);

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(url, data).subscribe(data => {
      resolve(data);
    }, error => {
      console.log("ERROR: ", error);
      reject(null);
    });
    });

  }

  async updateLogbookUserInfo(data) {
    let url = ENV.ROUTE + ENV.API_VERSION + `logbook/contact/${data.id}`;
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(url, data).subscribe(data => {
      resolve(data);
    }, error => {
      console.log("ERROR: ", error);
      reject(null);
    });
    });

  }

  async deleteLogbookUserInfo(data, routename) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.delete(ENV.ROUTE + ENV.API_VERSION + `logbook/${routename}/` + data.id).subscribe(data => {
        resolve(true)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });

  }

  async deleteLogbookRoute(routeId) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.delete(ENV.ROUTE + ENV.API_VERSION + `logbook/deleteRoute/${routeId}`).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getUserInfo(UserID: number) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'customer/' + UserID + '?page=1').subscribe(res => {
        this.storage.set(Constants.USER_DATA, res['success']);
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(error);
      });
    });

  }

  async getUserPin(UserID: number) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'customerPin/' + UserID).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(error);
      });
    });
  }

  //get user settings //
  async updateUserSettings(customerID, data) {
    return new Promise<any> ((resolve, reject) => {
        this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'customerSetting/' + customerID, data).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getStreetAdrress(lat, lng) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'streetAddress?lat=' + lat + '&lon=' + lng).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  async getStreetAdrressNew(lat, lng) {
    //_ Language support for reverse geocoding
    let lang = await this.storage.get(Constants.LANGUAGE);
    const supportedLanguages = ['en', 'de', 'fr', 'it'];
    lang = supportedLanguages.includes(lang) ? '&lang=' + lang : '';

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'reverse/geocoding?lat=' + lat + '&lon=' + lng + lang).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }


  async updateLogbookSort(customerId, data, routename) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + `logbook/${routename}/sort/` + customerId, data).subscribe(data => {
        resolve(data);
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
      });
    });

  }

  register(name: string, email: string, password: string) {
    const postData = {
      name,
      email,
      password,
      c_password: password,
    };
    this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'customer',
      postData).subscribe(data => {
        this.alertCtrl.create({
          header: 'User',
          message: 'User created successfully',
          buttons: ['OK']
        }).then(alertEl => {
          alertEl.present();
        });
        return data;
      }, error => {
        console.log("ERROR: ", error);
        return null;
      });
  }

  compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = a[key];
      const varB = b[key];
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  createDevice() {
    const postData = 'test';
    return this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'device', postData).subscribe(data => {
      return data;
    }, error => {
      console.log("ERROR: ", error);
      return null;
    });
  }

  createUpdateRouteInfo(
    contact_business_partner,
    contact_company,
    contact_address,
    reason_name,
    driver_first_name,
    driver_last_name,
  ) {

  }
  updateDevicePosition(devices: any) {
    const deviceArray: DevicePosition[] = [];
    let index = 0;
    devices.forEach(device => {
      const devicePosObj = new DevicePosition();
      devicePosObj.id = device.id;
      devicePosObj.devicepos = index;
      index = index + 1;
      deviceArray.push(devicePosObj);
    });
    deviceArray.sort(this.compareValues('id'));
    const putData = {
      deviceArray
    };
    return this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'device/devicepositions', deviceArray).subscribe(data => {
      return data;
    }, error => {
      console.log("ERROR: ", error);
      return null;
    });
  }

  async createGeofence(deviceID: number, type: number, options: string, coordinates: any) {
    const postData = {
      shape_type: type,
      coordinates,
      status: 1,
      options,
      trigger_enter: 1,
      trigger_leave: 1
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'geofence/' + deviceID, postData).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });

  }

  async createGeofences(listOfDeviceIDs: number[], type: number, options: string, coordinates: any) {
    const data = {
      shape_type: type,
      coordinates,
      status: 1,
      options,
      trigger_enter: 1,
      trigger_leave: 1
    };
    const postData = {
      deviceIDs: listOfDeviceIDs,
      data: data
    }

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'geofences/create', postData).subscribe({
        next: res => resolve(res),
        error: error => {
        console.log("ERROR: ", error);
        reject(null)
        }
      });
    });
  }

  async updateGeofence(geofenceID: number, deviceID: number, coordinates: any, type: number, options: string, name = null) {
    const putData = {
      shape_type: type,
      coordinates,
      status: 1,
      options
    };

    if (name != null)
      putData['name'] = name;

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'geofence/' + deviceID + '/' + geofenceID, putData).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });

  }

  async updateGeofenceSound(geofenceID: number, deviceID: number, property: string, value: number) {
    let putData = {};
    putData[property] = value;

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'geofence/' + deviceID + '/' + geofenceID, putData).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  async updateGeofenceStatus(geofenceID: number, deviceID: number, property: string, status: number) {
    let putData = {};
    putData[property] = status;

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'geofence/' + deviceID + '/' + geofenceID, putData).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  async updateGeofenceColor(geofenceID: number, deviceID: number, options: string) {
    const putData = {
      options
    };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'updateGeofenceColor/' + deviceID + '/' + geofenceID, putData).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });

  }

  async deleteGeofence(geofenceID: number, deviceID: number,) {
    return this.httpClient.delete(ENV.ROUTE + ENV.API_VERSION + 'geofence/' + deviceID + '/' + geofenceID).subscribe(data => {
      return data;
    }, error => {
      console.log("ERROR: ", error);
      return null;
    });
  }

  async updateDeviceShow(deviceID: number, status: number) {
    const putData = {
      deviceshow: status
    };

    return await this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'device/' + deviceID, putData).subscribe(data => {
      return data;
    }, error => {
      console.log("ERROR: ", error);
      return null;
    });
  }

  updateRadiusInfo(deviceID: number, radiusInMeters: number, radiusLat: number, radiusLng: number) {
    const radiusInM = radiusInMeters.toFixed(2);
    const putData = {
      radius_meter: radiusInM,
      radius_lat: radiusLat,
      radius_lng: radiusLng
    };
    return this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'device/' + deviceID, putData).subscribe(data => {
      return data;
    }, error => {
      console.log("ERROR: ", error);
      return null;
    });
  }

  updateDeviceAlarms(deviceID: number, alarmName: string, alarmValue: number) {
    const key = alarmName;
    const putData = {};
    putData[key] = alarmValue;

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'device/' + deviceID, putData).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  enableDisableGeofences(deviceID: number, alarmValue: number) {
    const postData = {
      status: alarmValue
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'enable-disable-geofences/' + deviceID, postData).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  updateSpurInfo(
    deviceID: number,
    selectedTab: number,
    startDate: number,
    endDate: number,
    lastMinutes: number,
    lastPoints: number,
    selected_date_range: string) {
    const putData = {
      spurdatum: startDate,
      spurmodus: selectedTab,
      spurdatumbis: endDate,
      spurminuten: lastMinutes,
      spurpunkte: lastPoints,
      selected_date_range: selected_date_range
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'device/' + deviceID, putData).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  renderPolyline(polylinePoints: PolylinePoints[]) {
    const postData = {
      shape: polylinePoints,
    };

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'renderPoints',
      postData).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  getValhallaResponses(dataPoints: any, snapFlag = true, useDatapoints = false) {
    if (dataPoints.length > 0) {
      let postData;
      if (useDatapoints) {
        postData = { dataPoints, iddevice: dataPoints[0].iddevice, snapFlag, useDatapoints: true };
      } else {
        let first = dataPoints[0].dateunix;
        let last = dataPoints[dataPoints.length - 1].dateunix;
        postData = { firstId: first > last ? last : first, lastId: first > last ? first : last, iddevice: dataPoints[0].iddevice, snapFlag, useDatapoints: false };
      }

      return new Promise<any> ((resolve, reject) => {
        this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'valhallaResponses',
        postData).subscribe(res => {
          resolve(res)
        }, error => {
          reject(null)
        });
      });
    } else
      return null;
  }

  _getValhallaResponses(data, timeout = '5000') {
    return this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'valhallaResponses', data, { headers: new HttpHeaders({ timeout }) });
  }

  getStopsAndSnapRouteByViewcheck(data) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'stopsAndSnapRoute', data, { headers: new HttpHeaders({ timeout: '5000' }) })
      .subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  getStopsAndSnapRouteByViewcheckForCompanies(data) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'company/stopsAndSnapRoute', data, { headers: new HttpHeaders({ timeout: '5000' }) })
      .subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }


  async updateUserMapSetting(route_icons: string,
    distance_unit: string,
    map: string,
    show_kmanzeige: number,
    snap_to_road: number,
    pauses: number, show_pauses = null
  ) {
    const putData = {
      route_icons,
      distance_unit,
      map,
      show_kmanzeige,
      snap_to_road,
      pauses
    };

    return this.updateCustommerSettings(putData);
  }

  async updateCustommerSettings(data) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'customers/map-settting'; //GET CUSTOMER DATA IF IS IN GHOST MODE
    if (await this.storage.get(Constants.SUPER_USER) == 1)
      url = ENV.ROUTE + ENV.API_VERSION + 'a/customers/map-settting/' + await this.storage.get(Constants.USER_ID);

    return new Promise((resolve, reject) => {
      this.httpClient.put(url, data).subscribe(data => {
        resolve(data);
        return data;
      }, error => {
        console.log("ERROR: ", error);
        reject(null);
        return null;
      });
    });
  }

  async getNotifications() {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'notifications').subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  async generateRoutes(deviceID, car) {
    return new Promise<any> ((resolve, reject) => {
      if(car.active){
        this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'logbook/generateSingleDeviceRoutes/' + deviceID).subscribe(res => {
          resolve(res)
        }, error => {
          console.log("ERROR: ", error);
          resolve(null);
        });
      }else{
        resolve(null);
      }

    });
  }

  generateAddresses(deviceID) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'logbook/generateSingleDeviceAddress/' + deviceID).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  optimizeRouteDistance(deviceID, carDeviceID) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'logbook/optimizedistance/' + deviceID + '/' + carDeviceID).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  optimizeSingleRouteDistance(routeId, index) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'logbook/optimizesingledistance/' + routeId)
      .subscribe(res => {
        console.log('DISTANCE RESP', res);
        if(res && res['success'])
        {
          resolve( {
            index :index,
            route: res['success']
          })
        }
        else
        {
          resolve ({
            index :index,
            route: null
          })
        }
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  async updateDeviceMapSetting(
    id: number,
    name: string,
    spurfarbe: string,
    iconname: string,
    iconusecustom: number,
    share_link: boolean,
    privacy_mode: boolean,
  ) {
    const putData = {
      id,
      name,
      spurfarbe,
      iconname,
      iconusecustom,
      share_link,
      privacy_mode,
    };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'device/' + id, putData).subscribe(data => {
        resolve(data)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  //_ CHECK RESPONSE TO VERY IF IS FINE THE PROGRESS WITH Subscription changes
  updateProfileImage(fileToUpload: File) {
    const mediaServer = ENV.mediaServer;
    const fd = new FormData();
    const header = new HttpHeaders();
    if (fileToUpload != null) {
      header.append('Content-Type', 'multipart/form-data');
      header.append('Accept', 'application/json');
      fd.append('uploadedFile', fileToUpload, fileToUpload.name);
    }
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post<ImageName>(mediaServer + ENV.API_VERSION + 'user-custom-img/', fd, {
      headers: header,
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
        } else if (event.type === HttpEventType.Response) {
          resolve(event)
        }
      });
    });
  }
  async updateDeviceCustImage(userId, device_id: any, isCustom: any, deafultImgName: any, fileToUpload: File) {
    const fd = new FormData();
    const header = new HttpHeaders();

    if (fileToUpload != null && isCustom) {
      header.append('Content-Type', 'multipart/form-data');
      header.append('Accept', 'application/json');
      fd.append('uploadedFile', fileToUpload, fileToUpload.name);
    } else if (deafultImgName && !isCustom) {
      fd.append('iconname', deafultImgName);
    }

    if (isCustom)
      fd.append('iconcustomimage', deafultImgName);

    fd.append('iconusecustom', isCustom);
    fd.append('device_id', device_id);

    const url = ENV.ROUTE + ENV.API_VERSION + 'device-custom-img/' + userId;
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post<ImageName>(url, fd, {
      headers: header,
      // reportProgress: true,
      // observe: 'events'
    })
      .subscribe(event => {
        resolve(event);
      }, error => {
        reject(null)
      });
    });
  }

  getDashboard() {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'customer/dashboard').subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  async markAllRead(ids) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'markAllRead', ids).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  logout() {
    this.storage.set(Constants.UPDATE_MAP, 1);
    this.storage.set(Constants.UPDATE_LOGIN, 1);
    this.storage.remove(Constants.REFRESH_TOKEN_KEY);
    return this.storage.remove(Constants.TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
    });
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  async getContact() {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `logbook/contact/158`).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  getShareLink(deviceId, settings = null, deviceviewmode = 1) {
    const postData = {
      iddevice: deviceId,
      deviceviewmode,
      ...settings
    };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'deviceViewMode', postData).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  updateShareLink(viewcheck, settings) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'deviceViewMode/update/' + viewcheck, settings).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  changePassword(data) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'customer/changepassword', data).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(error.error);
      });
    });
  }

  //_
  //_ Use for logbook cars and model
  //_ List of cars by user -> device cars
  async getDevicesCars(userId, distanceUnit) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'sdevice/car/';
    await this.storage.get(STORAGE_SETTINGS.ISGHOST).then(async r => { // Use for Ghost Mode
      if (r) url = ENV.ROUTE + ENV.API_VERSION + 'a/sdevice/car/' + userId;
    })
    const params = { params: new HttpParams().set('user_id', userId).set('distance_unit', distanceUnit) };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(url, params).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(error.error);
      });
    });
  }

  //_
  //_ List of all manufacture cars
  async getAllCars() {
    let url = ENV.ROUTE + ENV.API_VERSION + 'cars/';
    let customerID = await this.storage.get(Constants.USER_ID)
    if (await this.storage.get(STORAGE_SETTINGS.ISGHOST) == 1)
    {
      url = ENV.ROUTE + ENV.API_VERSION + 'a/cars/' + customerID;
    }
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(url).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(error.error);
      });
    });
  }

  getAlllogbookSupportedDevices() {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'logbookdeviceModel').subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(error.error);
      });
    });
  }

  //_
  //_ List of all manufacture models by carId
  async getCarModels(carId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'carmodels/' + carId;
    let customerID = await this.storage.get(Constants.USER_ID);
    if (await this.storage.get(STORAGE_SETTINGS.ISGHOST) == 1)
    {
      url = ENV.ROUTE + ENV.API_VERSION + 'a/carmodels/' + carId + '/' + customerID;
    }
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(url).subscribe(res => {
        resolve(res);
      }, error => {
        console.log("ERROR: ", error);
        resolve(error.error);
      });
    });
  }

  //_
  //_ Save device car by form data received
  async saveDeviceCar(data, userId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'sdevice/car';
    await this.storage.get(STORAGE_SETTINGS.ISGHOST).then(async r => { // Use for Ghost Mode
      if (r) url = ENV.ROUTE + ENV.API_VERSION + 'a/sdevice/car/' + userId;
    })

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(error.error);
      });
    });
  }

  //_
  //_ Update device car by form data received
  async editDeviceCar(data, carId) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'sdevice/car/' + carId, data).subscribe(res => {
      resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(error);
      });
    });
  }

  async updateDeviceCar(data, carId, sameActiveDevice)
  {
    var url = "";
    if(sameActiveDevice)
    {
      url = ENV.API_VERSION + 'sdevice/car/merge/' + carId + '/'+ sameActiveDevice.id;
    }
    else
    {
      url = ENV.API_VERSION + 'sdevice/car/' + carId ;
    }
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + url, data).subscribe(res => {
      resolve(res)
    }, error => {
      resolve(error.error);
    });
    });
  }

  //_
  //_ Update device car form data received
  async updateDevice(deviceId, data) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'device/' + deviceId, data).subscribe(data => {
      resolve ({ success: 'OK' });
    }, e => { resolve (e.error) })
    });
  }

  //_
  //_ Update device car form data received
  async deleteDeviceCar(carId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'sdevice/car/' + carId;
    await this.storage.get(STORAGE_SETTINGS.ISGHOST).then(async r => { // Use for Ghost Mode
      if (r) url = ENV.ROUTE + ENV.API_VERSION + 'a/sdevice/car/' + carId;
    })

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.delete(url).subscribe(data => {
      resolve ({ success: 'OK' });
    }, e => { resolve (e.error) })
    });
  }

  //_
  //_ Update user settings for logbook
  //_ Any data of user you could update here
  async updateUserData(data, userId) {
    return new Promise<any> ((resolve, reject) => {
        this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'customer/' + userId, data).subscribe((res:any) => {
        resolve(res)
      }, (error: HttpErrorResponse) => {
        console.log("HttpErrorResponse: ", error.error);
        reject(error.error);
        // return null;
      });
    });
  }

  async checkPassword(email, password) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'login', { email, password }).subscribe(
      res => {
        resolve(res)
      }, error => { console.log("ERROR: ", error); reject(null) });
    });
  }

  //_
  //_ Merge routes use in logbook device log
  async mergeRoutes(ids) {
    return new Promise<any> ((resolve, reject) => {
        this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + 'route/merge', { ids }).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  //_
  //_ Split routes use in logbook device log
  async splitRoute(id, data) {
    return new Promise<any> ((resolve, reject) => {
        this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'logbook/splitroute/' + id, data).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  //_
  //_ Verify pincode with checkView
  async verifyPin(viewCheck, pin) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'deviceViewMode/verifypin?pin=' + pin + '&viewcheck=' + viewCheck)
      .subscribe((r: any) => { // Use for Ghost Mode
        resolve(r)
      }, error => { console.log("ERROR: ", error); resolve (null); });
    });
  }

  //_ Get all notifications by daterange and device Id
  async getAllNotifications(devices) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'notifications/all'; //GET CUSTOMER DATA IF IS IN GHOST MODE
    if (await this.storage.get(Constants.SUPER_USER) == 1)
      url = ENV.ROUTE + ENV.API_VERSION + 'notifications/all/' + await this.storage.get(Constants.USER_ID);

    const postData = { devices };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(url, postData).subscribe(res => {
        resolve(res)
      }, async error => {
        if (await this.storage.getFromLocalOrSession(Constants.TOKEN_KEY))
          resolve(null)
      });
    });
  }

  updateAllDevices(data, userId) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'devices/update/' + userId, { data }).subscribe(res => {
      resolve(res)
    }, error => {
      console.log("ERROR: ", error);
      resolve(null)
    });
    });
  }

  updateVehicle(data) {
    return new Promise<any> ((resolve, reject) => {
        this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'sdevice/milageUpdateReason', data).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  getLastSensorData(deviceID) {
    return new Promise<any> ((resolve, reject) => {
        this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'sensordata/last/' + deviceID).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  getDeviceStatusData(deviceID) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'devicestatus?iddevice=' + deviceID).subscribe(res => {
      resolve(res)
    }, error => {
      console.log("ERROR: ", error);
      resolve(null)
    });
    });
  }

  updateTrackerPoint(point) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + `trackerdata/${point.did}/update`, point).subscribe(data => {
        resolve(data)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  updateMileage(singleRoute, distanceDiff, manualRoute = 0) {
    const putData = {
      id: singleRoute.id,
      iddevice: singleRoute.iddevice,
      carDevice_id: singleRoute.carDevice_id,
      mileage: singleRoute.mileage,
      distance: singleRoute.distance,
      startID: singleRoute.startID,
      distance_diff: distanceDiff,
      manual_route: manualRoute,
      reason: singleRoute.reason
    };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + `logbook/updateMileage`, putData).subscribe(data => {
        resolve(data)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  async getHeight(singleCordinate) {
    const postData = {
      lat: singleCordinate.lat,
      lon: singleCordinate.lng
    };
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + `height`, postData).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  async getSensorDataForDate(deviceID, date, viewcheck) {
    let link = ENV.ROUTE + ENV.API_VERSION + 'sensordata/last/';
    link += viewcheck ? viewcheck + '/' : '';
    link += deviceID + '/' + date;

    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(link).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  async reactivate(userId: number) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `undo-customer/${userId}`).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  addManualRoute(data, iddevice) {
    return new Promise<any> ((resolve, reject) => {
        this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'logbook/createRoute/' + iddevice, data).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  async getGeofencesV1(userId: number) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `geofencesv1/${userId}`).subscribe(res => {
      resolve(res)
    }, error => {
      console.log("ERROR: ", error);
      resolve(null)
    });
    });
  }

  async deleteGeofencesV1(userId: number) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + `geofencesv1/delete/${userId}`, {}).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  //get user settings //
  async createCompanyForCustommer(customerID, ) {
    let url = ENV.API_VERSION + 'company/create';
    if (await this.storage.get(Constants.SUPER_USER) == 1)
      url = ENV.API_VERSION + 'a/company/create/' + customerID;
      return new Promise<any> ((resolve, reject) => {
        this.httpClient.post(ENV.ROUTE + url, {}).subscribe(res => {
          resolve(res)
        }, error => {
          console.log("ERROR: ", error);
          reject(null)
        });
      });
  }

  //_ Get speed camera datasource
  getSpeedCameras(boundingBox){
    const queryParams = '?' + new URLSearchParams(boundingBox).toString();
    return this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + 'localspeedcameras' + queryParams);
  }

  //_ Get Logbook last Routes
  getLogbookLastRoutes(deviceId, queryParams){
    let url = ENV.API_VERSION + 'logbook/lastRoutes/' + deviceId;
    return this.httpClient.get(ENV.ROUTE + url + '?' + new URLSearchParams(queryParams).toString());
    // .then(res => {
    //   return res;
    // }).catch(error => {
    //   console.log("ERROR: ", error);
    // });
  }

  async getLastNotifications(deviceId, queryParams){
    let url = ENV.API_VERSION + 'notifications/last/' + deviceId;
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + url + '?' + new URLSearchParams(queryParams).toString()).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

   async socketInit(deviceIds){
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + `device/socketInit`, {
        "deviceIDs": deviceIds,
        "updateSocket": true
      }).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  getUnmarkedRoute(DeviceID,CarID) {
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `logbook/unmarkedRoutesCount/${DeviceID}/${CarID}`).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        resolve(null)
      });
    });
  }

  //_ Get Customer Dashboard Timeline
  getDashboardTimeline(userId, queryParams={}){
    let url = ENV.API_VERSION + 'customer/dashboard/timeline/' + userId;
    return this.httpClient.get(ENV.ROUTE + url + '?' + new URLSearchParams(queryParams).toString());
  }

  //_ Save timeline image
  saveTimelineImage(userId, data) {
    return this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + `customer/dashboard/timeline/uploadfile/${userId}`, data);
  }

  //_ Get dashboard sensorData
  getDashboardSensorData(userId, data) {
    return this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + `customer/dashboard/sensorData/${userId}`, data);
  }

  getLogbookMileageStats(deviceId, carDeviceId, data){
    data = new URLSearchParams(data).toString();
    return this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `logbook/stats/mileage/${deviceId}/${carDeviceId}?${data}`, data);
  }

  checkCustomerGroupZero(userId) {
    return this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `CustomerZeroGroup/${userId}`);
  }

  async getCustomerInfo()
  {
    let customerId = await this.storage.get(Constants.USER_ID);
    let url = ENV.ROUTE + ENV.API_VERSION + `logbook/customerinfo`;
    if (await this.storage.get(Constants.SUPER_USER) == 1)
    {
      url = ENV.ROUTE + ENV.API_VERSION + `a/logbook/customerinfo/${customerId}`;
    }
    return new Promise<any> ((resolve, reject) => {
      this.httpClient.get(url).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
    return
  }

  async updateCustomerInfo(data)
  {
    let customerId = await this.storage.get(Constants.USER_ID);
    let url = ENV.ROUTE + ENV.API_VERSION + `logbook/customerinfo/update`;
    data['customer_id'] = customerId;
    return new Promise<any>((resolve, reject) => {
        this.httpClient.put(url, data).subscribe(res => {
        resolve(res)
      }, error => {
        console.log("ERROR: ", error);
        reject(null)
      });
    });
  }

  getRouteLog (routeId) {
    let url = ENV.ROUTE + ENV.API_VERSION + `logbook/updatereason/${routeId}`;
    return this.httpClient.get(url);
  }

  countlyMetrics (dataEvent) {
    const postData = {
      app_key: environment.COUNTLY_KEY,
      'device_id': this.userId+'',
      'events': [ dataEvent ]
    }
    return this.httpClient.post(environment.COUNTLY_URL, postData);
  }

  getDeviceWifis (deviceId) {
    let url = ENV.ROUTE + ENV.API_VERSION + `setupwifi/${deviceId}`;
    return this.httpClient.get(url);
  }

  saveWifiAddress(deviceId, data) {
    return this.httpClient.put(ENV.ROUTE + ENV.API_VERSION + `setupwifi/saveAddress/${deviceId}`, data);
  }
  getLogbookCurrentMileageChanges(logbookCarId){
      return new Promise<any> ((resolve, reject) => {
        this.httpClient.get(ENV.ROUTE + ENV.API_VERSION + `logbook/currentmileage/${logbookCarId}`).subscribe(res => {
          resolve(res)
        }, error => {
          console.log("ERROR: ", error);
          resolve(null)
        });
      });
  }

  addCurrentMileage(data) {
    const postData = {
      'car_device_id': parseInt(data.car_device_id),
      'key': 'current mileage',
      'old_value': parseFloat(data.old_value),
      'new_value':  parseFloat(data.new_value),
      'reason': 'current mileage update',
      'customer_id': parseInt(data.customer_id)
    }
    let url = ENV.API_VERSION + 'logbook/currentmileage';
      return new Promise<any> ((resolve, reject) => {
        this.httpClient.post(ENV.ROUTE + url, postData).subscribe(res => {
          resolve(res)
        }, error => {
          console.log("ERROR: ", error);
          reject(null)
        });
      });
  }

  getRouteCostTypes() {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/costtype';
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url).subscribe({
        next: res => resolve(res),
        error: error => reject(error)
      })
    })
  }
  getRouteCostProviders() {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/costprovider';
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url).subscribe({
        next: res => resolve(res),
        error: error => reject(error)
      });
    });
  }

  getRouteCost(costId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/cost/' + costId;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.get(url).subscribe({
        next: res => resolve(res),
        error: error => reject(error)
      });
    });
  }

  createRouteCost(data) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/cost';
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, data).subscribe({
        next: res => resolve(res),
        error: error => reject(error)
      });
    });
  }

  editRouteCost(data, costId) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'logbook/cost/' + costId;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.put(url, data).subscribe({
        next: res => resolve(res),
        error: error => reject(error)
      });
    });
  }

  deleteRouteCost(costID, routeID) {
    let url = ENV.ROUTE + ENV.API_VERSION + `logbook/cost/${costID}/${routeID}`;
    if (!routeID)
      url = ENV.ROUTE + ENV.API_VERSION + `logbook/cost/${costID}`;

    return new Promise<any>((resolve, reject) => {
      this.httpClient.delete(url).subscribe({
        next: res => resolve(res),
        error: error => reject(error)
      });
    });
  }

  getDevicesDistanceDataObs(
    startDate,
    endDate,
    type,
    deviceId = null,
    lastPoint = false,
    distanceUnit = "km",
    useMongoStats = false
  ) {
      const postData = {
        startDate,
        endDate,
        type,
        deviceId,
        lastPoint,
        distanceUnit,
        useMongoStats
      };

      let url = ENV.ROUTE + ENV.API_VERSION;
      if (useMongoStats) url = url + 'customer/trackerStats/devicesDataStats/distance/' + this.userId;
      else url = url + 'customer/dashboard/devicesData/distance/' + this.userId;

      return this.httpClient.post(url, postData);
  }

  getMultipleDevicesDataObs(postData: any, useMongoStats = false, requestLastPoint = false) {
      // const postData = { startDate, endDate, type, deviceId, lastPoint};

      let url = ENV.ROUTE + ENV.API_VERSION;
      if (useMongoStats && !requestLastPoint) url = url + 'customer/trackerStats/devicesDataStats/speed-by-date/' + this.userId;
      else url = url + 'customer/dashboard/multipleDevicesData/' + this.userId;
      return this.httpClient.post(url, postData);
  }

  getMaxSpeedDataObs(startDate, endDate, type, deviceId = null, lastPoint = false, distanceUnit = "km", useMongoStats = false) {
      const postData = {
        startDate,
        endDate,
        type,
        deviceId,
        lastPoint,
        distanceUnit,
      };

      let url = ENV.ROUTE + ENV.API_VERSION;
      if (useMongoStats) url = url + 'customer/trackerStats/devicesDataStats/max-speed/' + this.userId;
      else url = url + 'customer/dashboard/devicesData/' + this.userId;

      return this.httpClient.post(url, postData)
  }

  getDevicesWifis (userId, deviceIds) {
    let url = ENV.ROUTE + ENV.API_VERSION + 'devices-wifis/' + userId;
    return this.httpClient.post(url, { deviceIds });
  }

  getDeviceAudioMessages (deviceImei): Observable<any> {
    let url = ENV.ROUTE + ENV.API_VERSION + 'voice-messages/' + deviceImei;
    return this.httpClient.get(url);
  }

  getSoundboard (language): Observable<any> {
    if (language === 'en_US') language = 'en';
    let url = ENV.ROUTE + ENV.API_VERSION + 'voice-messages/soundboard/' + language;
    return this.httpClient.get(url);
  }

  sendSound(imei, url): Observable<any> {
    let apiUrl = ENV.ROUTE + ENV.API_VERSION + 'voice-messages/send-sound';
    const data = {
      imei, url
    }
    // Send the POST request with the sound data
    return this.httpClient.post(apiUrl, data);
  }

  getLastMeasurement(imei): Observable<any> {
    let apiUrl = ENV.ROUTE + ENV.API_VERSION + 'watch/get-heart-measurament';
    const data = {
      imei
    }
    // Send the POST request with the sound data
    return this.httpClient.post(apiUrl, data);
  }
}
