<div class="item-content" (click)="open($event)">
  <ng-container [ngTemplateOutlet]="selectTemplete ? selectTemplete : defaultSelectItem" [ngTemplateOutletContext]="{ selectedItems: selectedItems, selectedItem: selectedItem }"></ng-container>
  
</div>

<ng-template #defaultSelectItem let-selectedItem="selectedItem">
  <button mat-raised-button class="mat-round-button fx-layout flex-100" style="margin: 0 2px">
    <div class="fx-layout row space-between">
      <ion-label>{{ selectedItem?.name }}</ion-label>
      <ion-icon name="chevron-down-outline"></ion-icon>
    </div>
  </button>
</ng-template>
