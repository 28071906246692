<div class="pullup-menu-content" [class.safe-area]="currentBreak == 'top' && haveNotch">
    <!-- Dragable header -->
    <div class="dragable-header" (click)="toggleBreakpoint($event)" [class.safe-area]="currentBreak == 'top' && haveNotch">
        <ion-button color="light" class="toggle-btn ion-no-margin" fill="clear" size="small">
        <ion-icon [class.rotate]="currentBreak == 'top'" name='chevron-up-outline' color="primary"></ion-icon>
        </ion-button>
    </div>
    <ion-nav #nav
        class="devicesListNavigation" [ngClass]="{'safe-area-top': currentBreak == 'top'}"
        [ngStyle]="{ 'height':  currentBreak[lastBreakChanged] + 'px !important' }"
        (ionNavDidChange)="navChanged($event)"
    >
    </ion-nav>
</div>
