import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { Constants } from '../constants.enum';
import { DeviceItemComponent } from '../members/map/devices-sidebar/device-item/device-item.component';
import { DevicesSidebarService } from '../members/map/devices-sidebar/devices-sidebar.service';
import { DevicesListHomeComponent } from '../members/map/devices-sidebar/home/home.component';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceNavigationService {

  constructor(private appService:AppService,private devicesSidebarService:DevicesSidebarService,  private storage: StorageService,) { }

  navigateToDevice(deviceID) {
    if (deviceID == 0) return;

    let device_length = this.appService.devices.length;
    const navigateTo = async (deviceId) => {
      await this.storage.set(Constants.SELECTED_DEVICE, deviceId);
      let item = { device_id: deviceId, deviceshow: true };
      const itemData = (itemList) => {
        item = itemList;
      };
      // this.devicesSidebarService.navigation.popToRoot();
      // await this.devicesSidebarService.navigation.setRoot(DeviceItemComponent, { itemList: item, callBack: itemData });
      if (
        this.devicesSidebarService.activePage != "APP-DEVICES-LIST-HOME" &&
        this.devicesSidebarService.navigation != null
      )
        {

          this.devicesSidebarService.navigation.setPages(
            device_length === 1?[{
              component: DeviceItemComponent,
              componentProps: { itemList: item, callBack: itemData },
            }]:
          [
            { component: DevicesListHomeComponent, componentProps: {} },
            {
              component: DeviceItemComponent,
              componentProps: { itemList: item, callBack: itemData },
            },
          ],
          {}
        );
      }
      else if (this.devicesSidebarService.navigation != null)
        this.devicesSidebarService.navigation.push(
          DeviceItemComponent,
          { itemList: item, callBack: itemData },
          {}
        );
    };
    navigateTo(deviceID);
    //this.lastPullupDeviceID = deviceID;
    //this.goToDevice = 0;
  }
}
