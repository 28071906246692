import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonToolbar, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { animations } from 'src/app/animations/animations';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { StorageService } from 'src/app/services/storage.service';
import { NewPullupService } from '../../new-pullup/pullup.service';
import { FlatToGrouped, GroupedToFlat, UpdateGroupsTotal, updateGroupsCounterAfterDelete } from '../devices-list/list/list-helper';
import { DevicesListComponent } from '../devices-list/list/list.component';
import { DevicesSidebarService } from '../devices-sidebar.service';
import { CountAppliedFilters, DefaultFilters, IFILTERS } from '../filters/filters-helper';
import { DevicesFiltersComponent } from '../filters/filters.component';
import { startDevicesListTour } from '../devices-list/list/devices-list-tour';

@Component({
  selector: 'app-devices-list-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: animations
})
export class DevicesListHomeComponent implements OnInit, OnDestroy {
  @Input() isPullup = false;
  @Input() navigation = null;
  filters: IFILTERS;
  defaultFilters = DefaultFilters;
  @ViewChild('devicesList') devicesList: DevicesListComponent;
  @ViewChild('content', { read: ElementRef }) content: ElementRef;
  @ViewChild('searchToolbar') !searchToolbar: IonToolbar
  isSmallScreen:boolean = false;
  groups = null;
  hideDeviceListToolbar = false;
  selectedGroup = null;
  appliedFilters = 0;
  searchHasFocus = false;
  lastTop = 0;
  dragMenuActive = true;
  numberOfGroups: number;
  enableScroll = false;
  observables = {};
  pullupBreakpoint = 'middle'
  constructor(private appService: AppService, private devicesSidebarService: DevicesSidebarService,
    private apiService: ApiService, private storage: StorageService, private permissionsService: PermissionsService,
    private popoverController: PopoverController, private translate: TranslateService, public pullUpService: NewPullupService,
    public platform: Platform, public dataService: DataService) { }

  ngOnInit() {
    this.hideDeviceListToolbar = this.dataService.getData('hideDeviceListToolbar');
    this.enableScroll = this.isPullup ? false : true;
    this.filters = this.devicesSidebarService.filters.get().value;
    this.loadGroups();
    this.appliedFilters = CountAppliedFilters(this.filters);

    this.devicesSidebarService.isOpeninPullup = this.isPullup;
    if (this.navigation)
      this.devicesSidebarService.navigation = this.navigation;

    this.observables['filters'] = this.devicesSidebarService.filters.get().subscribe(f => {
      this.filters = f;
      this.appliedFilters = CountAppliedFilters(f);
      //_ Disable drag option in submenu
      this.dragMenuActive = true;
      if (f.sortImei != 'no' || f.sortName != 'no' || f.qsearch != '')
        this.dragMenuActive = false;

      if (this.appliedFilters == 0)
        this.loadGroups();

      //_ Selecting group in toolbar
      if (this.groups)
        this.selectedGroup = this.groups.find(g => g.devices_groups_group_id == f.groupId);
    });

    this.observables['groupsUpdated'] = this.devicesSidebarService.groupsUpdated.subscribe(groups => {
      this.updateGroupFilter(groups);
      //_ Update groups in storage
      this.devicesSidebarService.groups.set(groups);
    });

    this.observables['pullupBreakpoint'] = this.pullUpService.currentBreakPoint.subscribe(breakName => {
     this.pullupBreakpoint = breakName;
    });

    this.observables['groupDeleted'] = this.devicesSidebarService.groupDeleted.subscribe(async (group: any) => {
      //_ Select all groups and remove the selected group filter; if is deleting the filter selected group id
      if (group.devices_groups_group_id == this.filters.groupId) {
        this.filters.groupId = -1;
        await this.loadGroups(false);
        this.devicesSidebarService.filters.set(this.filters);
      }
      else {
        await this.loadGroups(false, false);
        const updatedGroups = updateGroupsCounterAfterDelete(this.devicesSidebarService.groups.get().value, group);
        this.devicesSidebarService.groupsUpdated.next( updatedGroups );
      }
    });

    this.observables['groupAdded'] = this.devicesSidebarService.groupAdded.subscribe(newGroup => {
      this.devicesSidebarService.devicesGroups.unshift(newGroup); // adding the object to data array
      // this.devicesList.devicesSidebarService.devicesGroups.unshift(resData); // adding the object to data array
      // this.devicesList.flatList = GroupedToFlat(this.devicesList.devicesSidebarService.devicesGroups); //getting the flattened array
      // this.devicesList.devicesSidebarService.groupsUpdated.next( FlatToGrouped(this.devicesList.flatList) );
      // console.log('ADDED GROU -lli', this.devicesSidebarService.devicesGroups);
      const groups = this.devicesSidebarService.groups.get().value;
      groups.unshift(newGroup);
      this.devicesSidebarService.groups.set(groups);
      if (this.filters.groupId == -1) {
        this.devicesList.flatList = GroupedToFlat(this.devicesSidebarService.devicesGroups); //getting the flattened array
        this.devicesSidebarService.syncReorderedDevicesList(this.devicesList.flatList);
        this.loadGroups(false, false);
      }
      else {
        this.loadGroups(false);
      }
    })
    this.observables['startDevicesListTour'] = this.devicesSidebarService.startDevicesListTour.subscribe( r => {
      console.log('startDevicesListTour in mobile view')
      if (r){
        // if (this.devicesSidebarService.activePage != 'APP-DEVICES-LIST-HOME')
        //   this.devicesSidebarService.navigation.popToRoot();
        // startDevicesListTour(this);
      }
    });
    this.observables['pullupBreakpoint'] = this.pullUpService.breakPointChanged.subscribe(bp => {
      // console.log('Breakpiont changed', bp);
      if (this.devicesList.dragActive || this.devicesList.dragGroups) {
        if (bp != 'top') {
          this.devicesList.dragActive = false;
          this.devicesList.dragGroups = false;
        }
      }

      const scrollTop = this.content.nativeElement.shadowRoot.querySelector('.inner-scroll').scrollTop;
      if (bp == 'top' && scrollTop > 0) {
        this.isEnablePullup = false;
        setTimeout(() => this.devicesSidebarService.enableDragPullup.next(false), 355);
      }
      // console.log('SCROOLL IS ON TOP', scrollTop);

      this.enableScroll = bp == 'top' ? true : false;
      // console.log('Pullup Brackpoint change', this.enableScroll);
    });

    this.getScreenSize();
    // this.observables['pullupStartDragging'] = this.pullUpService.startDragging.subscribe( bp => {
    //   if (this.devicesSidebarService.enableDragPullup.value && this.pullUpService.currentBreakPoint.value == 'top')
    //     this.enableScroll = false;
    //   console.log('Start draggin', this.enableScroll)
    // });

    // this.observables['pullupEndDragging'] = this.pullUpService.endDragging.subscribe( bp => {
    //   // setTimeout(() => {
    //     // this.enableScroll = this.pullUpService.currentBreakPoint.value == 'top' ? true : false ;
    //   // this.enableScroll = true;
    //   // }, 200)

    // });

    // this.observables['pullupIsDragEnd'] = this.pullUpService.endDragging.subscribe( ev => {
    //   // if (this.devicesSidebarService.enableDragPullup.value && this.pullUpService.currentBreakPoint.value == 'top')
    //     this.enableScroll = true;
    //     console.log('Pullup drag end', this.enableScroll)
    // });

    // this.observables['pullupIsDragging'] = this.pullUpService.onDragging.subscribe( isDragging => {
    //   if (isDragging && this.devicesSidebarService.enableDragPullup.value)
    //     this.enableScroll = false;
    // });
  }

  ionViewDidEnter() {
    this.checkListScrollPosition();
  }

  async openFilters() {
    await this.devicesSidebarService.navigation.push(DevicesFiltersComponent, { groups: this.groups });
  }

  async loadGroups(useCached = true, updateGroups = true) {
    //_ Load groups from storage
    if (this.devicesSidebarService.groups.get().value && useCached)
      this.updateGroupFilter(this.devicesSidebarService.groups.get().value);

    //_ Load groups from backend
    return new Promise((resolve) => {
      this.apiService.getOnlyGroups().then(r => {
        if (r.success) {
          if (updateGroups)
            this.devicesSidebarService.groups.set(r.success);

          this.updateGroupFilter(r.success);
        }
        resolve(true);
      });
    })

  }

  updateGroupFilter(groups) {
    // console.log('GROUPS ITEMS in updateGroupFilter', groups);
    groups = groups.map(g => {
      // console.log('GROUP in updateGroupFilter', g);
      if (g.group_position == -1)
        return { ...g, name: this.translate.instant('devicesSidebar.filters.ungrouped'), key: 'devicesSidebar.filters.ungrouped', devices: g.device_groups || g.devices }
      else
        return { ...g, name: g.group_name, devices: g.device_groups || g.devices }
    });

    this.groups = [{ devices_groups_group_id: -1, name: this.translate.instant('devicesSidebar.filters.allGroups'), key: 'devicesSidebar.filters.allGroups' }, ...groups];
    this.selectedGroup = this.groups.find(g => g.devices_groups_group_id == this.filters.groupId);
    this.numberOfGroups = this.groups.length;
  }

  selectGroup(ev) {
    this.selectedGroup = ev.selectedItem;
    if (this.filters.groupId != this.selectedGroup.devices_groups_group_id) {
      this.filters.groupId = this.selectedGroup.devices_groups_group_id;
      this.devicesSidebarService.filters.set(this.filters);
    }
  }

  async searchByTerm(ev) {
    //_ Only apply filters when is in home component, prevent update list when filters changed on filters component
    if (this.devicesSidebarService.activePage != 'APP-DEVICES-LIST-HOME')
      return;
    this.filters.qsearch = ev.detail.value;
    this.devicesSidebarService.filters.set(this.filters);
  }

  ScrollingContent(ev) {
    // if (ev.detail.scrollTop != 0)
    //   this.devicesSidebarService.enableDragPullup.next(false);
    this.lastTop = ev.detail.scrollTop;
  }

  //_ No completed integrate in new pullup menu
  scrollEnd() {
    // if (this.lastTop == 0 && this.devicesSidebarService.enableDragPullup.value != true)
    //   this.devicesSidebarService.enableDragPullup.next(true);
    // else if (this.devicesSidebarService.enableDragPullup.value != false)
    //   this.devicesSidebarService.enableDragPullup.next(false);
  }

  onScrollStarts (ev) {
    console.log('Scroll Starts', ev);
    if (ev.isHide)
      this.devicesSidebarService.enableDragPullup.next(false);
  }

  focusSearch() {
    this.searchHasFocus = true;
    if (this.devicesSidebarService.isOpeninPullup)
      this.pullUpService.setBreakPoint.next('top');
  }

  isEnablePullup = true;
  contentIsOnTop = false;
  topReached(ev) {
    // this.contentIsOnTop = ev.isOnTop ? true : false;
    // console.log('TOP REACHED', ev);
    if (ev.isOnTop && !this.isEnablePullup) {
      this.devicesSidebarService.enableDragPullup.next(true);
      this.isEnablePullup = true;
    }
    if (!ev.isOnTop && this.isEnablePullup) {
      setTimeout(() => this.devicesSidebarService.enableDragPullup.next(false), 300);
      // this.devicesSidebarService.enableDragPullup.next(false);
      this.isEnablePullup = false;
    }

  }

  checkListScrollPosition() {
    const scrollTop = this.content.nativeElement.shadowRoot.querySelector('.inner-scroll').scrollTop;
    if (scrollTop == 0) {
      this.devicesSidebarService.enableDragPullup.next(true);
      this.isEnablePullup = true;
    }
    else {
      // this.devicesSidebarService.enableDragPullup.next(false);
      this.isEnablePullup = false;
      setTimeout(() => this.devicesSidebarService.enableDragPullup.next(this.isEnablePullup), 350);
    }

  }

  previousEnablePullupStatus = true;
  isDragging = false;
  headerGestures(ev) {
    // console.log('Gesture detected', ev);
    if (!this.isDragging && !ev.event.isFinal) {
      this.isDragging = true;
      this.previousEnablePullupStatus = this.isEnablePullup;
      this.devicesSidebarService.enableDragPullup.next(true);
      // console.log('HEADER GESTURES - enabled', { isdragging: this.isDragging, previousEnablePullupStatus: this.previousEnablePullupStatus, enableDragPullup: true, isFirst: ev.event.isFirst, isFinal: ev.event.isFinal })
    } else {
      if (ev.event.isFinal && this.isDragging) {
        this.isDragging = false;

        //_ not change pullup drag when drag active is enable
        // if (this.devicesList.dragActive || this.devicesList.dragGroups) return;
        setTimeout(() => {
          this.isEnablePullup = this.pullUpService.currentBreakPoint.value != 'top' ? true : this.previousEnablePullupStatus;
          this.devicesSidebarService.enableDragPullup.next(this.isEnablePullup)
          // console.log('HEADER GESTURES - disable', { isdragging: this.isDragging, previousEnablePullupStatus: this.previousEnablePullupStatus, enableDragPullup: this.isEnablePullup, isFirst: ev.event.isFirst, isFinal: ev.event.isFinal })
        }, 350);
      }
    }
  }

  onDragChanged(status) {
    this.previousEnablePullupStatus = this.isEnablePullup;
    if (status) {
      this.isEnablePullup = false;
      this.devicesSidebarService.enableDragPullup.next(false);
    }
    else {
      this.isEnablePullup = this.previousEnablePullupStatus;
      this.devicesSidebarService.enableDragPullup.next(this.previousEnablePullupStatus);
    }
  }

  @HostListener('document:click', ['$event'])
  onClickListenDismissReordering(event) {
    if (this.devicesList.dragActive || this.devicesList.dragGroups) {
      let dismiss = this.devicesList.reoderButtons.map(element =>
        element.nativeElement.contains(event.target)
      ).every(value => value == false);
      if (dismiss) {
        this.devicesList.dragActive = false;
        this.onDragChanged(false);
        this.devicesList.dragGroups = false;
        if (this.devicesList.disableDragGroupSpinner)
          this.devicesList.disableDragGroupSpinner = !this.devicesList.disableDragGroupSpinner;
      }
    }
    this.getScreenSize();
  }

  //function for pulling up and down the pullup on button click
  enablePullup() {

    this.pullUpService.currentBreakPoint.value == 'top' ? this.pullUpService.setBreakPoint.next('middle') : this.pullUpService.setBreakPoint.next('top')
  }

  ngOnDestroy() {
    Object.keys(this.observables).forEach(k => this.observables[k].unsubscribe());
  }
  clearSearch() {
    this.devicesSidebarService.resetFilters()
  }
  getScreenSize() {
    this.isSmallScreen = document.documentElement.clientWidth < 769 ? true : false;
  }  
}
