import { ModuleWithProviders, NgModule } from '@angular/core';
import { HighlightTextPipe } from './HighlightTextPipe';


@NgModule({
  declarations: [ HighlightTextPipe ],

  exports: [ HighlightTextPipe ],
})

export class HighLightPipeModule {
  static forChilds(): ModuleWithProviders<HighLightPipeModule> {
    return {
      ngModule: HighLightPipeModule,
      providers: [ HighlightTextPipe ],
    };
  }
}