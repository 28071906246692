import { NgModule } from "@angular/core";
import { BackCloseDirective } from "./backclose.directive";

@NgModule({
  declarations: [
    BackCloseDirective
  ],
  imports: [],
  exports: [
    BackCloseDirective
  ],
})
export class BackcloseDirectiveModule {}