import { Injectable } from '@angular/core';
import { ApiService } from './../../services/api.service';
import { StorageService as Storage } from './../../services/storage.service';
import { CsPopoverComponent } from './../../components/cspopover/cspopover.component'
import { PopoverController, ToastController } from '@ionic/angular';
import { Constants } from 'src/app/constants.enum';
import { StorageSetting, STORAGE_SETTINGS } from 'src/app/services/storage-settings';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { first } from "rxjs/operators";
import { MapService } from '../map/components/map/map.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Capacitor } from "@capacitor/core";

@Injectable({
    providedIn: 'root'
})

export class Commons {
  userInfo: any;
  userSettings: any;
  initialized = false;
  language: string;
  user: any;
  showDefaultImage = this.mapService.showDefaultImage.value;
  defaultImageValue = this.mapService.showDefaultImage.value;
  use3dMarkers = this.mapService.use3dMarkers.value;
  defaultUse3dMarkers = this.mapService.use3dMarkers.value;
  defaultTimezone = null;
  defaultSnapToRoad = 0;
  defaultPauseTime = 3;
  isSmallScreen = false;
  isIos = Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios';
  minZoomValue = 1
  maxZoomValue = 24

  constructor(
    private authService: AuthenticationService,
    public appService: AppService,
    public popoverController: PopoverController,
    public mapService: MapService,
    public _translate: TranslateService,
    public storage: Storage,
  ){}

  async chargeData(){
    this.user = await this.storage.get(Constants.USER_DATA);
    this.userSettings = await this.storage.get(Constants.USER_SETTINGS);
    setTimeout(() => (this.initialized = true), 300);
    this.userInfo = Object.assign({}, await this.getUserInfo());
    this.defaultPauseTime = Object.assign({}, this.userSettings.pause_time);
    this.defaultSnapToRoad = Object.assign({}, this.userInfo.snap_to_road);

    this.getScreenSize();
  }

  getUserInfo() {
    return new Promise((resolve, reject) => {
      if (this.appService.user)
        resolve(this.appService.user);
      else {
        this.appService.user$.pipe(first()).subscribe(u => {
          resolve(u);
        })

      }
    })
  }

  async showInfoPopup(event, title, description, image, useGif = true, imageHeight = 262) {
    //_ This method helps to load the gif image before to render it in the popover
    //_ then when is used in the popover component it is served by the cache of the browser
    if (image.endsWith('.gif')){
      await this.getImageSize(image);
    }

    let popup = await this.popoverController.create({
      componentProps: { title, description, image, useGif },
      component: CsPopoverComponent,
      event: event,
      mode: "ios",
      translucent: false
    });

    await popup.present();
    popup = null;
  }

  getImageSize(url: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = (error) => {
        reject(error);
      };
      image.src = url;
    });
  }

  async updateSettings() {
    if (!this.initialized) return false; // Fix first load of user, trigger toggles buttons when changed the model

    // Update map style user settings
    if (this.userInfo.map != this.appService.user.map) {
      this.appService.user.map = this.userInfo.map;
      this.mapService._style.next(this.userInfo.map);
      this.mapService.mapStyle.next(this.userInfo.map) // we can remove this when we remove the local storage
    }

    // Update map route icons user settings
    if (this.userInfo.route_icons != this.appService.user.route_icons) {
      this.mapService._routeIcon.next(this.userInfo.route_icons);
      this.appService.user.route_icons = this.userInfo.route_icons;
    }

    this.appService.user.show_kmanzeige = this.userInfo.show_kmanzeige;
    this.appService.user.distance_unit = this.userInfo.distance_unit;
    this.appService.user.snap_to_road = this.userInfo.snap_to_road;

    await this.authService
      .updateUserMapSetting(
        this.userInfo.route_icons,
        this.userInfo.distance_unit,
        this.userInfo.map,
        this.userInfo.show_kmanzeige,
        this.userInfo.snap_to_road,
        this.userInfo.pauses,
        this.userInfo.show_pauses ? 1 : 0
      )
      .then((res) => {
        if (res) {
          this.appService.showToast(
            this._translate.instant("deviceManagement.mapSettingsText"),
            this._translate.instant("deviceManagement.mapSettingMessage"),
            2000,
            "success"
          );
          this.appService.user.show_pauses = this.userInfo.show_pauses;
          this.mapService.showPauses.next(this.userInfo.show_pauses);
          this.appService._settingsChanged.next(this.userInfo);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        this.appService.showToast(
          this._translate.instant("deviceManagement.mapSettingsText"),
          this._translate.instant("deviceManagement.ErrorSettingMessage"),
          2000,
          "danger"
        );
      });
    this.loadLanguage();
  }

  updateUserSettings() {
    if (!this.initialized) return false;
    console.log('UPDATE SETTINGS', { settings: { ...this.userSettings } })
    this.authService.updateUserSettings(this.userInfo.id, this.userSettings).then((r: any) => {
      if (r.success) {
        this.appService.showToast('', this._translate.instant("deviceManagement.mapSettingMessage"), 2000, 'success');
      }
    }).catch(error => {
      console.log("ERROR: ", error);
      this.appService.showToast('', this._translate.instant("deviceManagement.errorUpdateSettings"), 3000, 'danger');
    });
  }

  // Load language, function reduced !!
  async loadLanguage() {
    await this.storage.get(Constants.LANGUAGE).then((res) => {
      this.language = res;
    });
  }

  updateSingleSetting(settingName, value) {
    const data = {}
    data[settingName] = value;

    if (settingName == 'show_devicename_in_marker') {
      this.mapService.showDeviceNameMarker.next(value);
    }

    if (settingName == 'device_name_font_size') {
      if (+value < 10) value = 10;
      if (+value > 30) value = 30;
      this.mapService.deviceNamefontSize.next(+value);
    }

    if (settingName == 'show_speed_polylines_btn') {
      this.mapService.showSpeedPolylinesBtn.next(value);
    }

    this.storage.set(Constants.USER_SETTINGS, this.userSettings);
    this.appService._userSettings.next(this.userSettings);
    this.updateUserSettings();
  }

  shouldReload(prop = '') {
    if (prop == "showDefaultImage" && this.defaultImageValue != this.showDefaultImage
      || prop == "use3dMarkers" && this.defaultUse3dMarkers != this.use3dMarkers
      || this.userSettings.timezone != this.defaultTimezone
      || prop == 'snapToRoad' && this.defaultSnapToRoad != this.userInfo.snap_to_road
    )
      this.needReload = true;
    else this.needReload = false;
  }

  timeToUpdate = null;
  async setPauseTime(ev) {
    // if (ev.detail.value <= 0) {
    //   this.input.value = 1
    // }
    // else{
    //   this.defaultPauseTime = ev.detail.value;
    //   this.userSettings.pause_time = ev.detail.value;
    //   this.storage.set(Constants.USER_SETTINGS, this.userSettings);
    //   this.appService._userSettings.next(this.userSettings);
    //   await this.updateUserSettings();
    // }

    if (!this.initialized) return false;
    if (this.timeToUpdate) clearTimeout(this.timeToUpdate);
    if (ev <= 0) ev = 1;

    this.timeToUpdate = setTimeout(async () => {
      if (this.defaultPauseTime != ev) {
        this.defaultPauseTime = ev;
        this.userSettings.pause_time = ev;
        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);
        await this.updateUserSettings();
      }
    }, 600);
  }


  async setDefaultZoomLevel(ev) {
    if (!this.initialized) return false;
    if (ev.detail.value.trim() === '')
      this.userSettings.default_map_zoom = 1;
    else {
      let numValue = parseInt(ev.detail.value);
      if (numValue < this.minZoomValue || numValue > this.maxZoomValue) {
        await this.appService.showToast('',
          this._translate.instant("deviceManagement.mapSettingsZoomRange",
            {
              minValue: this.minZoomValue,
              maxValue: this.maxZoomValue
            }),
          3000, 'warning', 'up');
      }
      numValue = Math.min(Math.max(numValue, this.minZoomValue), this.maxZoomValue);
      this.userSettings.default_map_zoom = numValue;
    }

    this.storage.set(Constants.USER_SETTINGS, this.userSettings);
    this.appService._userSettings.next(this.userSettings);
    await this.updateUserSettings();
  }

  numberOnlyValidation(event: any) {
    const pattern = /^\d+$/;
    const ignoreCharts = [43, 44, 45, 46, 69, 101];
    const hasSelectedText = document.getSelection().toString() === event.srcElement.value;
    const maxLength = 5;
    let inputChar = String.fromCharCode(event.charCode);
    if ((!pattern.test(inputChar) || (event.srcElement.value.length + 1 > maxLength && !hasSelectedText))) {
      event.preventDefault();
    }

    if (ignoreCharts.includes(event.charCode))
      event.preventDefault();

  }

  async showHideUploadbutton(ev) {
    if (!this.initialized) return false;
    this.userSettings.upload_markerImg = ev.detail.checked == true ? 1 : 0;
    this.storage.set(Constants.USER_SETTINGS, this.userSettings);
    this.appService._userSettings.next(this.userSettings);
    await this.updateUserSettings();
  }

  async activeDeactivateAutofocusButton(ev) {
    if (!this.initialized) return false;
    this.userSettings.autofocus_on_move = ev.detail.checked == true ? 1 : 0;
    this.storage.set(Constants.USER_SETTINGS, this.userSettings);
    this.appService._userSettings.next(this.userSettings);
    await this.updateUserSettings();
  }

  getScreenSize() {
    this.isSmallScreen =
      document.documentElement.clientWidth < 769 ? true : false;
  }

  needReload = false;
  async updatePerformance(prop, event) {
    this[prop] = !this[prop];
    if(prop !== "hideAllDevicesDefault"){
      this.mapService[prop].next(this[prop]);
    }
    switch (prop) {
      case "showClusterMarkers":
        this.userSettings.cluster_markers =
          event.detail.checked == true ? 1 : 0;
        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);
        break;
      case "showDotEffect":
        this.userSettings.green_dot = event.detail.checked == true ? 1 : 0;
        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);
        break;

      case "noAnimatedMarker":
        this.userSettings.disable_animation =
          event.detail.checked == true ? 1 : 0;
        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);
        break;

      case "showDefaultImage":
        this.userSettings.default_image = event.detail.checked == true ? 1 : 0;
        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);
        break;

      case "use3dMarkers":
        this.userSettings.use3dMarkers = event.detail.checked == true ? 1 : 0;
        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);
        break;

      case "enableSocket":
        this.userSettings.enable_socket = event.detail.checked == true ? 1 : 0;
        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);
        break;
      case "mapMonitorMode":
        this.userSettings.map_monitor_mode = event.detail.checked == true ? 1 : 0;

        if(!event.detail.checked){
          this.userSettings.hide_all_devices_default = 0
        }

        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);


        break;
      case "hideAllDevicesDefault":
        this.userSettings.hide_all_devices_default = event.detail.checked == true ? 1 : 0;
        this.storage.set(Constants.USER_SETTINGS, this.userSettings);
        this.appService._userSettings.next(this.userSettings);
        break;
    }

    await this.updateUserSettings();
    // await this.appService.showToast(
    //   this._translate.instant("deviceManagement.mapSettingsText"),
    //   this._translate.instant("deviceManagement.mapSettingMessage"),
    //   2000,
    //   "success"
    // );

    //_ Show button to apply changes to refresh the page
    this.shouldReload(prop);
  }

  reloadPage() {
    window.location.reload();
  }

  onWillDismiss(event: Event) {
    console.log("closing modal")
  }
}
