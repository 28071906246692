import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor() { }

  playAudio(soundName) {
    let audio;
    if (audio) audio.pause();
    audio = new Audio('/assets/notifications_sounds/' + soundName + '.wav');
    audio.play();
  }
}
