<ion-header [ngClass]="{ 'no-title': title.length <= 0 }">
  <ion-toolbar>
    <ion-buttons slot="primary">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [size]="titleSize">{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false">
  <swiper-container #sliderRef (swiperafterinit)="swiperReady()" [config]="options" init="false" [zoom]="true" swiperElement>
    <swiper-slide>
      <ion-img style="width: 100%; height: 100%" [src]="src"></ion-img>
    </swiper-slide>
  </swiper-container>

  <!-- <ion-slides [options]="options" #sliderRef>
  </ion-slides> -->
  <!-- <ion-img style="width: 100%; height: 100%" [src]="src"></ion-img> -->
</ion-content>

<ion-footer [ngClass]="{ 'no-text': text.length <= 0 }">
  <ion-toolbar class="ion-text-center">
    <ion-text>{{ text }}</ion-text>
  </ion-toolbar>
</ion-footer>
