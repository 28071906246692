import { ModuleWithProviders, NgModule } from '@angular/core';
import { DistanceMeasurePipe } from './distanceMeasurePipe';
import { HumanizeTimePipe } from './humanizeTimePipe';
import { HumanizeTimeUnitsPipe } from './humanizeTimeUnitsPipe';
import { TranslateDatePipe } from './translateDatePipe';
import { ArrayFilterPipe } from './arrayFilterPipe';
import { CheckExtentionPipe } from './checkExtentionPipe';
import { JsonPipe } from './jsonPipe';
import { DeviceImagePipe } from './deviceImagePipe';
import { SecureMediaPipe } from './secureMediaPipe';
import { SanitizeHtmlPipe } from "./../components/tour/sanitizehtml.pipe";
import { DeviceImageSanitizer } from './DeviceImageSanitizer';
import { KmtoMilesPipe } from './KmtoMilesPipe';
import { AddressFormatPipe } from './addressFormatPipe';
import { ArrayKeysPipe } from './arrayKeysPipe';


@NgModule({
  declarations: [HumanizeTimePipe, HumanizeTimeUnitsPipe, DistanceMeasurePipe, TranslateDatePipe, ArrayFilterPipe,
    CheckExtentionPipe, JsonPipe, DeviceImagePipe, SecureMediaPipe, SanitizeHtmlPipe, 
    DeviceImageSanitizer, KmtoMilesPipe, AddressFormatPipe, ArrayKeysPipe ],

  exports: [HumanizeTimePipe, HumanizeTimeUnitsPipe, DistanceMeasurePipe, TranslateDatePipe, ArrayFilterPipe,
    CheckExtentionPipe, JsonPipe, DeviceImagePipe, SecureMediaPipe, SanitizeHtmlPipe, 
    DeviceImageSanitizer, KmtoMilesPipe, AddressFormatPipe, ArrayKeysPipe ],
})

export class PipesModule {
  static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule,
      providers: [HumanizeTimePipe, HumanizeTimeUnitsPipe, DistanceMeasurePipe, TranslateDatePipe, ArrayFilterPipe,
        CheckExtentionPipe, JsonPipe, DeviceImagePipe, SecureMediaPipe, SanitizeHtmlPipe, DeviceImageSanitizer,
        KmtoMilesPipe, AddressFormatPipe, ArrayKeysPipe ],
    };
  }
}
