import { Threebox, DirectionalLight } from 'threebox-plugin'; 
import { getHeading } from '../mapUtil';
// import { DirectionalLight } from 'three';
import { TeslaModel, ThreedModels } from './3dModels';

declare var window: any, tb: any;
export class ThreedHelper {
    objects = [];
    mainMap = null;
    mapSettings = { maxZoom: 18 };
    objectsLayer = null;
    isInitialized = false;
    selectedId = null;
    init(mainMap){
        this.mainMap = mainMap;
        window.tb = new Threebox(
            mainMap.map,
            mainMap.map.getCanvas().getContext('webgl'),
            {
                defaultLights: true,
                enableSelectingFeatures: true,
                enableSelectingObjects: true,
                // enableTooltips: true
            }
        );

        this.isInitialized = true;      
    }

    createLayer(source) {
        const tc = this;
        return this.objectsLayer = {
            id: 'markers', //_ Use the same name as the custom layer markers
            type: 'custom',
            renderingMode: '3d',
            source: source,
            onAdd: async (map, mbxContext) => {
                // Add lights
                // this.addLight(0, -70, 100, 0xffffff);
                // this.addLight(0, 70, 100, 0xffffff);
                // this.addLight(0, -70, -100, 0xffffff);
                // this.addLight(0, 70, -100, 0xffffff);

                this.objects.forEach(o => {
                    if (tc.isInitialized) 
                        tb.add(o);
                });
            },

            render: function (gl, matrix) {
                if (tc.isInitialized) 
                    tb.update();
            }
        };
    }

    addObject(feature) {
        const tc = this;

        feature.layer = this.objectsLayer;
        const vehicle = ThreedModels.find(o => o.name == feature.properties.modelName) || TeslaModel; //TeslaModel by default;
        const options = { 
            ...vehicle,
            rotation: { x: vehicle.rotation.x, y: vehicle.rotation.y-feature.properties.lastPoint.direction, z: vehicle.rotation.z  }, //default rotation
            bbox: false,
            units: 'scene', //_ meters | scene
            feature,
            startBearing: feature.properties.lastPoint.direction,
            fixedZoom: this.mapSettings.maxZoom
        }

        const prevColor = vehicle.color;
        tb.loadObj(options, function (model) {
            const m = model.setCoords(feature.geometry.coordinates);
            // m.addLabel( tc.createLabel(feature.properties.deviceName) );
            m.addEventListener('SelectedChange', (e) => tc.onClick(e, feature.properties.deviceId), false)
            
            //_ Change model color based on device color
            tc.changeModelColor(m, prevColor, feature.properties.color);

            m.hidden = !feature.properties.deviceShow;
            tc.objects[feature.properties.deviceId] = { model: m, feature, options };
            tb.add(m, feature.properties.deviceId);
        });
    }

    changeModelColor(model, prevColor: {r, g, b}, newColor){
        
        model.traverse(function (object) {
            if (object.isMesh) {
                const material = object.material.clone();
                const c = material.color;
            
                if ( c.r == prevColor.r && c.g == prevColor.g && c.b == prevColor.b ) {
                    material.color.set(newColor)
                    material.needsUpdate = true;
                    object.material = material;
                }
            }
        });
        return model;
    }

    moveAnimatedObject(deviceId, start, end, time = 30000) {
        const obj = this.objects[deviceId];
        var options = {
            path: [start, end],
            duration: time
        }

        obj.model.followPath(options, {});
    }

    moveObject(deviceId, point){
        const obj = this.objects[deviceId];
        const currentLocation = obj.model.coordinates;

        //_ [lng, lat]
        let bearing = getHeading(currentLocation[1], currentLocation[0], point[1], point[0]);
        const nBearing = bearing - obj.options.startBearing;
        if (bearing != 0 )
            obj.model.set({ rotation: { x: 0, y: 0, z: -nBearing}});

        obj.model.setCoords(point);
    }

    //_ Trigger the click feature event of the map
    //_ When click on any object of the layer
    onClick(ev, deviceId){
        const feature = this.objects[deviceId].options.feature;
        ev.lngLat = ev.detail.coordinates;
        this.mainMap.onClickFeature.emit({ e: ev, feature: feature });
        this.selectedId = deviceId;
    }

    createLabel(text) {
        let popup = document.createElement('div');
        // popup.innerHTML = '<span title="' + text + '" style="font-size: 30px;color: yellow;">&#9762;</span>';
        popup.innerHTML = '<h2>' + text + '</h2>';
        return popup;
    }

    //_ Show / Hide model
    showHide(deviceId, show: boolean) {
        const obj = this.objects[deviceId];
        if (obj)
            obj.model.hidden = !show;
    }

    //_ Change Color
    changeColor(deviceId, newColor){
        const obj = this.objects[deviceId];
        //_ Renove the object and add again 
        if (obj){
            tb.remove(obj.model);
            this.objects.splice( this.objects.indexOf(obj), 1);
            obj.feature.properties.color = newColor;
            this.addObject(obj.feature);
        }
    }

    //_ Change Icon
    updateMarker(deviceId, newModel){
        const obj = this.objects[deviceId];
        //_ Renove the object and add again 
        if (obj){
            tb.remove(obj.model);
            this.objects.splice( this.objects.indexOf(obj), 1);
            obj.feature.properties.modelName = newModel;
            this.addObject(obj.feature);
        }
    }

    updateModel(deviceId, newColor, modelName){
        const obj = this.objects[deviceId];
        //_ Renove the object and add again 
        if (obj && (obj.feature.properties.color != newColor || obj.feature.properties.modelName != modelName)){
            tb.remove(obj.model);
            this.objects.splice( this.objects.indexOf(obj), 1);
            obj.feature.properties.color = newColor;
            obj.feature.properties.modelName = modelName;
            this.addObject(obj.feature);
        }
    }

    getModel(deviceId){
        const obj = this.objects[deviceId];
        return obj;
    }

    addLight(x, y, z, color){
        // const light = new THREE.PointLight(color, 1);
        const directionalLight = new DirectionalLight(color, 1);
        directionalLight.position.set(x, y, z).normalize();
        tb.scene.add(directionalLight);
    }
    
}

