import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { StorageService as Storage } from '../../services/storage.service';
import { DATE_RANGE, diffDates, isToday, isTwoWeeks, isWeek, isYesterday, TODAY } from './date-util';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatCalendar } from '@angular/material/datepicker';
import { AppService } from 'src/app/app.service';
import { Constants } from 'src/app/constants.enum';
import { DeviceDataService } from '../../members/map/devicesData.service';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' }
],
})

//_ @startDate = Input and output parameter
//_ @endDate = Input and output parameter
//_ range = Used to set the date range for the calendar
//_ times {start, end} = used to fix the time in @startDate & @endDate
//_ timesValues = used to display times 00:00 -> 23:59 in html
export class DateRangeComponent implements OnInit, AfterViewInit {
  @Input() startDate: any = null;
  @Input() endDate: any = null;
  @Input() showInPopover = false;
  @Input() dateTime = true;
  @Input() headerColor = 'dark';
  @Input() showFutureDate:boolean =false
  //_ Used on route component to send back the same if is not changed the date
  @Input() selectedDateRange: any = 'RANGE';
  @Input() maxDate = moment().endOf('day'); //.toDate(); //.format('YYYY-MM-DDTHH:mm:ss');
  @Input() minDate = moment('01/01/2018'); //.toDate(); //.format('YYYY-MM-DDTHH:mm:ss');
  @Input() singleSelection = false;
  @Input() daysLimit = true;
  @Input() deviceId: any
  @Input() showAllMinutes = false;
  @ViewChild(DaterangepickerComponent, { static: false }) datePicker: DaterangepickerComponent;
  @ViewChild(MatCalendar) calendar: MatCalendar<moment.Moment>;
  @ViewChild('dtStart', { static: false }) dtStart: any;
  @ViewChild('dtEnd', { static: false }) dtEnd: any;

  days: number = -1;
  language;
  monthsShortNames;// = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  monthsNames;// = [];
  daysNames;
  today = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
  locale = {};
  dateRange = { startDate: null, endDate: null };
  showTime = true;
  range: DateRange<moment.Moment> = new DateRange<moment.Moment>(null, null);
  times = { start: '00:00', end: '23:59' };
  externalTimes = { start: '00:00', end: '23:59'};
  timesValues = [];
  currentDevice: any = null
  ispopover:boolean = false
  timeZoneLocale = 'en';
  rangeTimeOk = true;

  dateSegmentButtons = [
    {
      days: 1
    },
    {
      days: 0
    },
    {
      days: 6
    },
    {
      days: 29
    },
    {
      days: 59
    },
    {
      days: 99
    }
  ]
  constructor(private modelCtrl: ModalController, private devicesService: DeviceDataService,
    private storage: Storage, private popoverCtrl: PopoverController,
    private _translate: TranslateService, private appService: AppService,
    private dateAdapter: DateAdapter<any>) {

    }

  async ngOnInit() {
    if (this.deviceId) {
      this.currentDevice = this.appService.devices.find((device)=>device.id == this.deviceId)
    }else{
      let devices:Array<any> = this.appService.devices;
      this.currentDevice = devices.reduce((acc, device) => acc = acc > device.speicherdauer < acc.speicherdauer ? acc : device, 0);
    }

    if (this.currentDevice) {
      if (this.currentDevice.speicherdauer) {
        this.dateSegmentButtons.push({ days: this.currentDevice.speicherdauer - 1 });
        this.dateSegmentButtons.sort((a, b) => a.days - b.days);
      }
    }

    this.createTimeArrays();
    if (!this.singleSelection)
      this.range = new DateRange<moment.Moment>(moment(this.startDate), moment(this.endDate));
    this.updateTimes();

    this.load();
  }

  async ngAfterViewInit() {
    this.loadLanguage();
  }

  //_ This onlye helps to highlight the segment button
  //_ According to the @start|end Date parameters
  load() {
    let startD = moment(this.startDate);
    let endD = moment(this.endDate);
    this.updateDatePicker(startD, endD);

    switch (diffDates(startD, endD)) {
      case DATE_RANGE.TODAY: this.days = 0; break;
      case DATE_RANGE.YESTERDAY: this.days = 1; break;
      case DATE_RANGE['7DAYS']: this.days = 6; break;
      //case DATE_RANGE.TWO_WEEKS: this.days = 13; break;
      case DATE_RANGE['30DAYS']: this.days = 29; break;
      case DATE_RANGE['60DAYS']: this.days = 59; break;
      case DATE_RANGE['100DAYS']: this.days = 99; break;
      case DATE_RANGE['365DAYS']: this.days = 364; break;
    }
  }

  async dismiss() {
    // console.log('Close modal.', { selectedRange: this.selectedDateRange});
    if (this.daysLimit) {
      var daysDifference = Math.floor((Date.parse(this.endDate) - Date.parse(this.startDate)) / 86400000) + 1;
      if (daysDifference > this.currentDevice?.speicherdauer && daysDifference > 59) {
        await this._translate.get('dashboard.charts.maxAllowedDays', { days: this.currentDevice?.speicherdauer }).subscribe((text) => {
          this.appService.showToast("", text, 3000, "danger");
        });
        return;
      } else if (daysDifference > this.currentDevice?.speicherdauer) {
        this.startDate = moment().subtract(this.currentDevice?.speicherdauer-1, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        this.endDate = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        this.selectedDateRange = 'RANGE';
        await this._translate.get('dashboard.charts.maxAllowedDaysWarning', { days: this.currentDevice?.speicherdauer }).subscribe((text) => {
          this.appService.showToast("", text, 3000, "warning");
        });
      }
    }

    if (this.showInPopover)
      await this.popoverCtrl.dismiss({
        dismissed: true,
        startDate: this.startDate,
        endDate: this.endDate,
        selectedDateRange: this.selectedDateRange
      });
    else
      await this.modelCtrl.dismiss({
        dismissed: true,
        startDate: this.startDate,
        endDate: this.endDate,
        selectedDateRange: this.selectedDateRange
      });
  }

  async close() {
    if (this.showInPopover) await this.popoverCtrl.dismiss();
    else await this.modelCtrl.dismiss();
  }

  segmentChanged(e) {
    this.days = e.detail.value;

    this.startDate = moment().subtract(this.days, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    this.endDate = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');

    if (e.detail.value == 1)
      this.endDate = moment().subtract(this.days, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ss');

    if (this.days != -1) {
      this.rangeOk = true;
      this.range = new DateRange<moment.Moment>(moment(this.startDate), moment(this.endDate));
    }

    this.selectedDateRange = this.days > 1 ? (Number(this.days) + 1) + 'DAYS' : DATE_RANGE[this.days];
    this.updateTimes();
    this.calendar.activeDate = moment(this.startDate);
  }

  onChangeTime = (value) => {
    
    if(value.start){
      this.times.start = value.time;
      this.externalTimes.start = value.time;
    }
    else{
      this.times.end = value.time;
      this.externalTimes.end = value.time;
    }

    this.rangeTimeOk = this.validateRangeHour(this.times.start, this.times.end);
    this.updateDatePicker("", "");
  }

  validateRangeHour = (hour1:string, hour2:string) => {
    const [hours1, minutes1] = hour1.split(':').map(Number);
    const [hours2, minutes2] = hour2.split(':').map(Number);
    const isRangeValidate = (hours2 > hours1) || (hours2 === hours1 && minutes2 > minutes1);

    return moment(this.range.start).isSame(this.range.end) ? isRangeValidate : true;
  }

  updateDatePicker(startD, endD) {
    //_ Only to one selection for date and time NOT RANGE
    if (this.singleSelection) {
      this.startDate = moment(this.startDate).format('YYYY-MM-DDT' + this.times.start + ':00');
      return;
    }

    if (this.times.start != '00:00' || this.times.end != '23:59') {
      this.selectedDateRange = 'RANGE';
    }
    // console.log('START AND END', { startD: this.times.start , endD: this.times.end, selectedDateRange: this.selectedDateRange });

    if (this.calendar) {
      //this.range = new DateRange<Date>(moment(startD).toDate(), moment(endD).toDate());
      this.startDate = moment(this.range.start).format('YYYY-MM-DDT' + this.times.start + ':00');
      this.endDate = moment(this.range.end).format('YYYY-MM-DDT' + this.times.end + ':59');
    }
  }

  //_ Only Used for (popover) mini datetime picker used on logbook filters :D
  async loadLanguage() {
    this.language = await this.storage.get(Constants.LANGUAGE);
    // this.dateAdapter.setLocale(this.language);
    this.monthsShortNames = this._translate.instant('dateTime.monthsShortNames');
    if (this.dtStart && this.dtEnd) {
      this.dtStart.monthShortNames = this.monthsShortNames;
      this.dtEnd.monthShortNames = this.monthsShortNames;
    }

    moment().local(this.language);
    this.locale = {
      format: 'YYYY-MM-DDTHH:mm:ss', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: 'MM/DD/YYYY', // default is format value
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      //firstDay: 1 // first day is monday
    };

    if (this.language == 'en_US')
      this.timeZoneLocale = 'en-en'
    else
      this.timeZoneLocale = this.language;
  }

  //@Input() selectedRangeValue = range;
  rangeOk = true;
  selectedChange($event) {
    const m = moment($event);
    //_ Only to one selection for date and time NOT RANGE
    if (this.singleSelection) {
      this.startDate = moment(m.toDate()).format('YYYY-MM-DDT' + this.times.start + ':00');
      this.rangeOk = true;
      return;
    }

    if (this.range.end != null || this.range.end != undefined) {
      let start = this.range.start;
      start = m
      this.range = new DateRange<moment.Moment>(start, undefined);
      this.rangeOk = false;
    } else {
      let end = m
      this.range = new DateRange<moment.Moment>(this.range.start, end);
      if (moment(this.range.start).isSameOrAfter(this.range.end)) {
        this.range = new DateRange<moment.Moment>(this.range.end, this.range.start);
      }

      this.days = -1;
      this.updateDatePicker(this.range.start, this.range.end);
      this.selectedDateRange = 'RANGE';
      this.rangeOk = true;
    }

    this.rangeTimeOk = this.validateRangeHour(this.times.start, this.times.end);
  }

  createTimeArrays() {

    // let interval;
    // if (this.showAllMinutes) {
    //   interval = 1;
    // } else {
    //   interval = this.singleSelection ? 10 : 15;
    // }

    const interval = this.singleSelection ? 10 : 15;
    for (let h = 0; h <= 23; h++)
      for (let m = 0; m <= 59; m += interval) {
        const hour = h < 10 ? '0' + h : h;
        const min = m < 10 ? '0' + m : m;
        this.timesValues.push(hour + ':' + min);
      }
    this.timesValues.push('23:59');
  }

  updateTimes() {

    let interval;
    if (this.showAllMinutes) {
      interval = 1;
    } else {
      interval = this.singleSelection ? 10 : 15;
    }

    //const interval = this.singleSelection ? 10 : 15;
    let startMin = Math.round(+moment(this.startDate).format('mm') / interval) * interval;
    let endMin = Math.round(+moment(this.endDate).format('mm') / interval) * interval;

    endMin = endMin == 60 ? 59 : endMin;
    startMin = startMin == 60 ? 59 : startMin;

    this.times.start = moment(this.startDate).format('HH') + ':' + (startMin < 10 ? '0' + startMin : startMin);
    this.times.end = moment(this.endDate).format('HH') + ':' + (endMin < 10 ? '0' + endMin : endMin);
  }

  async showTimePicker(itemType: string, event) {
    if(this.ispopover) return
    const startTime  = moment(this.startDate).format('HH:mm')
    const endTime  = moment(this.endDate).format('HH:mm')
    this.ispopover = true;
    let popup = await this.popoverCtrl.create({
      componentProps: {
        selectedTime: itemType === 'start' ? startTime : endTime
      },

      component: TimePickerComponent,
      event: event,
      mode: "ios",
      //translucent: false,
      backdropDismiss: true,
      cssClass: "time-picker"
    });
    await popup.present();
    const { data } = await popup.onDidDismiss();
    this.ispopover = false;
    if(data){
      if (itemType === 'start') {
        this.startDate = moment(this.startDate).startOf('day').add(moment.duration(data)).format('YYYY-MM-DDTHH:mm:ss');
        this.times.start = moment(this.startDate).format('HH:mm');
      } else {
        this.endDate = moment(this.endDate).startOf('day').add(moment.duration(data)).format('YYYY-MM-DDTHH:mm:ss');
        this.times.end = moment(this.endDate).format('HH:mm');
      }
    }
  }
}
