import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
// import { MapMinComponent } from 'src/app/members/generate-video/map-min/map-min.component';
import { DeviceImageService } from 'src/app/pipes/deviceImagePipe';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { getDistance, resizeImage } from '../../../map/class/mapUtil';
import { TranslateService } from '@ngx-translate/core';
import { DeviceData } from '../../../map/class/deviceData';
import { HttpClient } from '@angular/common/http';
import { environment as ENV } from 'src/environments/environment';
import { GET_LEGS } from './map-helper';
import { getLogbookAddress } from 'src/app/components/map-address-picker/util';
import { animations } from 'src/app/animations/animations';
import { RouteService } from '../../../../devices-sidebar/device-item/device-submenu/route/route.service';
import { ModalController } from '@ionic/angular';
import { DeviceListService } from 'src/app/members/map/devices-sidebar/devices-list/device-list.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { DevicesDashboardService } from '../../devices-dashboard.service';
import { AVAILABLE_SYMBOL_MARKERS } from '../../../map/class/features/symbolMarkers';
import { Subject, takeUntil } from 'rxjs';
import { MainMapLiteComponent } from 'src/app/components/main-lite-map/main-map-lite.component';
import { KmtoMilesPipe } from 'src/app/pipes/KmtoMilesPipe';

@Component({
  selector: 'app-last-logbook-routes',
  templateUrl: './last-logbook-routes.component.html',
  styleUrls: ['./last-logbook-routes.component.scss'],
  animations: animations
})
export class LastLogbookRoutesComponent implements OnInit, OnDestroy {
  lastRoutes = [];
  selectedRoute = null;
  @Input() language = 'en';
  @Input() title = this._translate.instant("timeLine.lastRoutes");
  @Input() get device () { return this._device; };
  @Input() get activityType () { return this._activityType; };
  _device: any = null;
  _activityType: any = null;

  @ViewChild('map') map: MainMapLiteComponent;

  routePoints = [];
  deviceData: DeviceData = null;
  distance = 0;
  distance_unit = 'km'
  loadingData = true;
  @Output() onShowRouteOnMap = new EventEmitter();
  requestingRoutes = null;
  cmpDestroyed = new Subject();
  mapUser = { map: 'paj' }
  constructor(private authService: AuthenticationService, private appService: AppService,
              private imageService: DeviceImageService, private translate: TranslateService,
              private httpClient: HttpClient, private routeService: RouteService,
              private modalCtrl: ModalController, private _translate: TranslateService, private _deviceListService: DeviceListService, private devicesService:DeviceDataService,
              private devicesDashboardService: DevicesDashboardService,
              public distancePipe: KmtoMilesPipe
              ) { }

  ngOnInit() {
      this.devicesService.deviceUpdated$.pipe(takeUntil(this.cmpDestroyed)).subscribe((d) => {
        if (d.id === this.device.id) {
          this.device = d.properties;
          this.map.updateDevice(d);
        }
      });
      this.distance_unit = this.appService?.user?.distance_unit ?? 'km';
  }

  set device(value){
    this._device = value;
    if (this.activityType)
      this.loadData();
  }

  set activityType (value){
    this._activityType = value;
    if (this.device)
      this.loadData();
  }

  //_ The magic starts here :)
  loadData(){
    if (this.map)
      this.map.clearAllData();

    this.selectedRoute = null;
    this.lastRoutes = [];
    this.getLastRoutes();
  }

  getLastRoutes(){
    //console.log('LOADING LAST ROUTES', { date: new Date().toISOString() });
    if (this.requestingRoutes)
      this.requestingRoutes.unsubscribe();

    this.loadingData = true;
    const queryParams = { itemsPerPage: 10, language: this.appService.user.actlang, distanceUnit: this.appService.user.distance_unit, todaysRoutes: (this.activityType == 'today').toString() };
    this.requestingRoutes = this.authService.getLogbookLastRoutes(this.device.id, queryParams).subscribe( (res: any) => {
      let newRoutes = [];
      //_ Extract grouped data and add the group title header as propertie of every route
      Object.keys(res.success).forEach(k => {
        newRoutes = newRoutes.concat(res.success[k].map(r => { return { ...r, header: +k,id:r.id??r._id }; }));
      });

      this.lastRoutes = newRoutes.reverse();
      if (this.lastRoutes.length > 0) {
        this.selectRoute(this.lastRoutes[0]); //this.selectedRoute = this.lastRoutes[0];
        setTimeout(() => { if (this.map) this.map.map.resize() }, 50);
      }
      // if (this.lastRoutes)
      //   this.getRoutePoints();
      this.loadingData = false;
      //console.log('LAST ROUTES LOADED', { date: new Date().toISOString() });
      this.devicesDashboardService.requestLastRoutesDone.next(true);
    }, (e => {
      this.loadingData = false;
      console.error('ERROOR GETTIN ROUTES', e);
      this.devicesDashboardService.requestLastRoutesDone.next(true);
    }));
  }

  getRoutePoints(){
    this.loadingData = true;
    this.distance = 0;
    return this.authService.getTrackerDataForLogBook(this.selectedRoute.iddevice,
      this.selectedRoute.startID,
      this.selectedRoute.endID).then(res => {
        if (res) {
          if (res.success) {
            //_ Show only start and end route marker in the map
            if(res.success.length > 0){
              this.routePoints = res.success;

              //_ Set start|end points with the start|end stored on the route
              if (this.routePoints.length > 0) {
                //_ Map the updated route markers
                this.routePoints = this.routePoints.map(p => {
                  if (p.upt) {
                    p.lat = p.upt[0].lat;
                    p.lng = p.upt[0].lon;
                  }
                  return p;
                })

                this.routePoints[0].lat = +this.selectedRoute.end_lat;
                this.routePoints[0].lng = +this.selectedRoute.end_lng;
                this.routePoints[this.routePoints.length - 1].lat = +this.selectedRoute.start_lat;
                this.routePoints[this.routePoints.length - 1].lng = +this.selectedRoute.start_lng;
              } else {
                this.loadingData = false;
                this.appService.showToast('', this.translate.instant('toast.errortoLoadTrackerPoint'), 3000, 'danger');
              }
            } else {
                if(this.selectedRoute.manual_route === 1){  // manual route
                  let singleRouteData;
                  this.routePoints = [];
                  singleRouteData = {
                    id:1,
                    iddevice: this.selectedRoute.iddevice,
                    battery: 0,
                    dateunix: this.selectedRoute.endID,
                    direction: 0,
                    lat: this.selectedRoute.end_lat,
                    lng: this.selectedRoute.end_lng,
                    speed: 0,
                    upt: null
                  }
                  this.routePoints.push(singleRouteData);
                  singleRouteData = {
                    id:2,
                    iddevice: this.selectedRoute.iddevice,
                    battery: 0,
                    dateunix: this.selectedRoute.startID,
                    direction: 0,
                    lat: this.selectedRoute.start_lat,
                    lng: this.selectedRoute.start_lng,
                    speed: 0,
                    upt: null
                  }
                  this.routePoints.push(singleRouteData);
                }
             }

             this.loadingData = false;
             this.drawRouteOnMap(this.routePoints);
          }

        }
      });
  }

  async drawRouteOnMap(routePoints){
    const updatedDevice = this.devicesService.devices[this.device.id]?.properties;
    this.deviceData = new DeviceData(updatedDevice);
    this.deviceData.properties.deviceshow = 1;
    this.deviceData.init(this.routePoints);

    const lastPoint = routePoints[routePoints.length-1];
    this.deviceData.lastPoint = lastPoint;
    this.deviceData.lastLocation = [lastPoint.lng, lastPoint.lat];

    const lastLocation: any = this.deviceData.lastLocation;
    const startPoint = routePoints[0];
    const startLocation: any = [startPoint.lng, startPoint.lat];

    this.map.symbolMarkersClass.clearMarkers();
    this.map.symbolMarkersClass.addMarker(AVAILABLE_SYMBOL_MARKERS['start-marker'], lastLocation, false);
    this.map.symbolMarkersClass.addMarker(AVAILABLE_SYMBOL_MARKERS['end-marker'], startLocation, true, { iconOffset: [35, 3] });

    this.map._addDataPoints(this.deviceData, false, false, true);
    this.map._addDirectionMarkers(this.deviceData, false, false, true);

    this.map.fitBounds(routePoints.map(r => [r.lng, r.lat]), {top: 40, bottom: 40, left: 40, right: 80 });

    this.deviceData.newDataPoints = [];
    this.deviceData.newDirectionMarkers = [];
    this.deviceData.newSnapedPoints = [];

    this.loadingData = false;
    this.getValhallaResponses(this.routePoints, true, true);
  }

  valhallaSubscription = null;
  optimizing;
  getValhallaResponses(dataPoints: any, snapFlag = true, useDatapoints = false) {
    this.optimizing = true;
    if (dataPoints.length > 0) {
      let first = dataPoints[0];
      let last = dataPoints[dataPoints.length - 1];
      let postData = { dataPoints, iddevice: dataPoints[0].iddevice, snapFlag, useDatapoints: true, noPauses: true, sortByDate: false };

      //_ Check distance; if valhalla not return right distance -> calculate it using distance point to point
      const checkDistance = () => {
        if (this.distance == 0){
          let tdist = 0;
          let lastPoint= null;
          this.routePoints.forEach(p=> {
            if (p.lat && p.lng && !p.wifi && lastPoint){
              tdist += getDistance(lastPoint.lat, lastPoint.lng, p.lat, p.lng);
            }
            lastPoint = p;
          })
          this.distance = tdist;
        }
      }

      this.valhallaSubscription = this.httpClient.post(ENV.ROUTE + ENV.API_VERSION + 'valhallaResponses',
        postData).subscribe((res: any) => {
          if (res.success) {
            const shapeData = GET_LEGS(res.success, 0.0001, this.appService.userSettings.use_graphhopper);
            const tripsShapes = shapeData.shapes;
            // this.distance = this.selectedRoute.manual_route == 1 ? this.selectedRoute.optimized_distance : res.success.optimized_distance;

            // const newRouteData = this.selectedRoute;
            // if (+newRouteData.optimized_distance > 0)
            //   this.distance = +newRouteData.optimized_distance;
            // else
            //   this.distance = +newRouteData.distance;

            if (res.success.optimized_distance > 0)
              this.distance = res.success.optimized_distance;
            else
              this.distance = this.selectedRoute.distance;

            if (tripsShapes.length > 0) {
              this.deviceData.newSnapedPoints = tripsShapes;
              this.deviceData.snapedPoints = tripsShapes;

              this.map._addDataPoints(this.deviceData, true, false, true);
              // this.map.startRouteAnimation();
              this.deviceData.newSnapedPoints = [];
            }

            if (res.success.pause_markers.length > 0)
              this.deviceData.newPauseMarkers.push(res.success.pause_markers);
            this.optimizing = false;

            // if(this.selectedRoute.manual_route !== 1)
            //   checkDistance();
          }
        }, ( (e) => {
            this.optimizing = false;
            checkDistance();
          })
        );

    } else
      return null;
  }

  selectRoute(route){
    //_ exit if select same route
    if (this.selectedRoute){
      if (route.id == this.selectedRoute.id)
        return
    }

    this.selectedRoute = route;

    this.requestAddress(route);

    //_ Stop prevoious valhalla request
    if (this.valhallaSubscription)
      this.valhallaSubscription.unsubscribe();

    //_ After change selectedRoute should request datapoints and update the map
    if (this.map)
      this.map.clearAllData();
    this.getRoutePoints();
  }

  requestAddress(route) {
    if (!route.start_address) {
      this.authService.getStreetAdrressNew(route.start_lat, route.start_lng).then(res => {
        if (res) {
          if (res.success)
            route.start_address = getLogbookAddress(res.success.features[0].properties).address;
        }
      });
    }
    if (!route.end_address) {
      this.authService.getStreetAdrressNew(route.end_lat, route.end_lng).then(res => {
        if (res) {
          if (res.success)
            route.end_address = getLogbookAddress(res.success.features[0].properties).address;
        }
      });
    }
  }

  openRouteInMainMap(){
    /*
    if(this.appService.userSettings.hide_all_devices_default){
      const toggleValue = {
        deviceId: this.device.id,
        checked: true,
      };
      this._deviceListService.toggleDevice(toggleValue)
    }
    */
    if (this.valhallaSubscription)
      this.valhallaSubscription.unsubscribe();

    const device = this.devicesService.devices[this.device.id]
    device.properties.deviceshow = 1
    this.devicesService.devices[device.id].properties.deviceshow = 1;
    this.devicesService.devices[device.id].properties.rangeMode = 4;
    this.routeService.updateRoute({
      dismissed: true,
      datapoints: this.routePoints,
      rangeMode: 4,
      deviceId: this.device.id,
      startDate: this.selectedRoute.startID,
      endDate: this.selectedRoute.endID,
      isManualRoute: this.routePoints.length === 2,
      // notSaveOriginalDataPoints:true,
      routeFrom: 'activity'
    });

    this.dismiss();
    this.devicesDashboardService.openInMobiles.next(false);
  }

  // dismiss(){
  //   this.onShowRouteOnMap.emit({});
  //   this.modalCtrl.dismiss();
  // }
  async dismiss(){
    this.onShowRouteOnMap.emit({});
    // const modal = await this.modalCtrl.getTop();
    // if(modal != null){
    //   this.modalCtrl.dismiss();
    // }
    this.devicesDashboardService.openInMobiles.next(false);
  }

  ngOnDestroy(): void {
    this.cmpDestroyed.next(true);
    // this.map.ngOnDestroy();
    this.devicesDashboardService.requestLastRoutesDone.next(false);
  }


}
