import { Injectable } from "@angular/core";
import { GeolocationService } from "./plugins/geolocation.service";
import { GeocoderService } from "./plugins/geocoder.service";
import { environment as ENV } from 'src/environments/environment';
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "../app.service";
import { BrowserService } from "./plugins/browser.service";
import { Platform } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service"
import { StorageService } from "src/app/services/storage.service"
import * as moment from 'moment';
@Injectable({
    providedIn: "root",
})
export class SupportLinkService {

    permissionChangedWatcher: any

    constructor(
        private geoLocationService: GeolocationService,
        private geoCoderService: GeocoderService,
        public _translate: TranslateService,
        private appService: AppService,
        private browserService: BrowserService,
        private platform: Platform,
        private apiService: ApiService,
        private storageService: StorageService,
    ) {
    }

    async getSupportLink(language?) {
      let countryCode = language;
      if (!language) await this.getCurrentCountryCode()
        .then(r => countryCode = r)
        .catch(() => {
            // do nothing
        });

      if (!countryCode) countryCode = this._translate.currentLang;

      let codeCountry = ENV.SUPPORT_URL_LANGUAGES
        .find(language => language == countryCode?.toLowerCase());

      let code = this.supportLinkEnding(codeCountry);

      return ENV.DEFAULT_SUPPORT_URL.replace('com/support', code);

    }

    async getManualsLink(language?) {
        //ALL_LANGUAGES: ['DE', 'EN', 'ES', 'IT', 'FR', 'PT', 'PL', 'EL'],
        let defaultLink = "https://www.paj-gps.com/finder-portal-instruction/"; //en
        switch(language?.toLowerCase()){
            case 'de': return "https://www.paj-gps.de/portal-anleitung/";
            case 'es': return "https://www.paj-gps.es/manual-de-uso-portal-finder/";
            case 'it': return "https://www.paj-gps.it/istruzioni-del-portal-finder/";
            case 'fr': return "https://www.paj-gps.fr/instructions-portal-finder/";
            case 'pt': return "https://www.paj-gps.pt/manual-do-usuario-do-portal-finder/";
            default: return defaultLink;
        }
        return defaultLink;
    }

    async getDeviceManualsLink(language, manualLink) {
      let defaultLink = "https://www.paj-gps.co.uk/manual/"; //en
      switch(language?.toLowerCase()){
        case 'de':
         defaultLink = "https://www.paj-gps.de/anleitungen";
         break;
        case 'es':
          defaultLink = "https://www.paj-gps.es/manual-de-instrucciones/";
          break;
        case 'it':
          defaultLink = "https://www.paj-gps.it/istruzione-duso/";
          break;
        case 'fr':
          defaultLink = "https://www.paj-gps.fr/mode-demploi/";
          break;
        case 'pt':
          defaultLink = "https://www.paj-gps.pt/assistencia/";
          break;
        case 'en_us':
          defaultLink = "https://www.paj-gps.com/manual";
          break;
      }

      if (!manualLink) {
        return defaultLink;
      } else {
        let linkObj = JSON.parse(manualLink);
        let defaultLinkObj = linkObj.en
        switch(language?.toLowerCase()){
          case 'de': return linkObj.de ?? defaultLinkObj;
          case 'es': return linkObj.es ?? defaultLinkObj;
          case 'it': return linkObj.it ?? defaultLinkObj;
          case 'fr': return linkObj.fr ?? defaultLinkObj;
          case 'pt': return linkObj.pt ?? defaultLinkObj;
          case 'el': return linkObj.el ?? defaultLinkObj;
          case 'pl': return linkObj.pl ?? defaultLinkObj;
          case 'nl': return linkObj.nl ?? defaultLinkObj;
          case 'en_us': return linkObj.en_us ?? defaultLinkObj;
          default: return defaultLinkObj;
        }
      }

    }

    supportLinkEnding(code) {
      let endUrl: string;
      switch (code) {
        case 'in':
        case 'au':
          endUrl = `com/${code}/support`;
          break;
        case 'pt':
          endUrl = 'pt/assistencia';
          break;
        case 'en':
          endUrl = 'co.uk/support';
          break;
        case 'de':
        case 'es':
        case 'it':
        case 'fr':
          endUrl = `${code}/contact`;
          break;
        default:
          endUrl = 'com/support'
      }
      return endUrl;
    }

    //_ Request permission when prompt is not shown -> that happend when user rejected once ther permission and not show popup again was marked
    async requestPermissionInCaseWasRejectedBefore () {
        return await this.geoLocationService.requestPermissionPopup().then( async r => {
            //_ If returned status is true means user allow gps location
            if (r) return await this.getCurrentLocation()
        }).catch(() => {
            this.appService.showToast(
                '',
                this._translate.instant('mapPage.clientLocation.needPermissionMessage'),
                3000,
                'danger'
            )
        });
    }

    async openHelp() {
      let url: string = await this.getSupportLink(this._translate.currentLang);
      await this.openUrl(url)
    }

    async openManuals() {
      let url: string = await this.getManualsLink(this._translate.currentLang);
      await this.openUrl(url)
    }

    async openDeviceManuals(manualLink) {
    //console.log('this._translate.currentLang',this._translate.currentLang);
      let url: string = await this.getDeviceManualsLink(this._translate.currentLang, manualLink);
      await this.openUrl(url)
    }

    async openDeviceGuard() {
        //let url: string = 'https://start.finder-portal.com/deviceguard/';
        let url: string = '';

        if(this._translate.currentLang == 'de'){
            //url = 'https://start.finder-portal.com/de/deviceguard/';
          url = 'https://start.finder-portal.com/de/produkt/device-guard-lizenz/';
        } else if (this._translate.currentLang == 'en' || this._translate.currentLang == 'en_US') {
          url = 'https://start.finder-portal.com/product/device-guard-license/';
        } else {
            url = 'https://start.finder-portal.com/deviceguard/';
        }

        //Check local storage for token first:
        //Currently disabled to make sure everytime a new token is beeing fetched
        /*
        const tokenResponseStored = await this.storageService.get('deviceGuardToken');
        if(tokenResponseStored){
            //console.log('tokenResponseStored',tokenResponseStored);
            if(moment().unix() > moment(tokenResponseStored.ttl).unix()){
                //console.log('DeviceGuard - TTL run out - getting new one');
                let newToken = await this.getNewDeviceGuardToken();
                if(newToken) url = 'https://guard.paj-gps.de/connect/' + newToken;
            }else{
                //console.log('DeviceGuard - TTL valid - using stored token');
                url = 'https://guard.paj-gps.de/connect/' + tokenResponseStored.token;
            }
        }else{
            //console.log('DeviceGuard - Nothing stored - getting new one');
            let newToken = await this.getNewDeviceGuardToken();
            if(newToken) url = 'https://guard.paj-gps.de/connect/' + newToken;
        }
        */

        //New token everytime:
        let token = await this.getNewDeviceGuardToken();
        //console.log('deviceGuard', token);
        if(token && token != '') {
            url = 'https://guard.paj-gps.de/connect/' + token;
        }

        await this.openUrl(url)
    }

    async getNewDeviceGuardToken() {
        //getting a new token also resyncs the customer permissions in device guard
        let tokenResponse = await this.apiService.getDeviceGuardToken();
        if(tokenResponse && tokenResponse.token) {
            //this.storageService.set('deviceGuardToken',tokenResponse); //Currently disabled to make sure everytime a new token is beeing fetched
            return tokenResponse.token;
        }else{
            console.log('Error: Not able to get device guard token.');
        }
        return '';
    }

    async getRegisterPlanLink(language?) {
      let defaultLink = "https://start.finder-portal.com/"; //en
      const lang = language?.toLowerCase();
      switch (lang) {
        case 'de':
        case 'es':
        case 'it':
        case 'fr':
        case 'pl':
        case 'el':
          return `${defaultLink}${lang}/`;
        case 'pt': return "https://start.finder-portal.com/pt-pt";
        case 'en_us': return "https://start.finder-portal.com/en-us";
        default: return defaultLink;
      }
    }

    async openRegisterPlan() {
      let url: string = await this.getRegisterPlanLink(this._translate.currentLang);
      await this.openUrl(url)
    }

    private async openUrl(url) {
      if (url)
        await this.browserService.openBrowser(
          url,
          "_system"
        );
    }

    private async getCurrentAddress() {
        let position = await this.geoLocationCheck().then(pos => {
            if (pos) return pos
        });

        return await this.geoCoderService.reverseGeocode(position?.coords.latitude, position?.coords.longitude);
    }

    private async getCurrentCountryCode() {
        return await
            this.getCurrentAddress().then(r => {
                return r.addresses[0].countryCode;
            });
    }
    private async getCurrentLocation() {
        return await this.geoLocationService.getCurrentPosition({ maximumAge: 10000, timeout: 1000, enableHighAccuracy: true });
    }

    private async geoLocationCheck() {
        if(!this.permissionChangedWatcher) {
            this.permissionChangedWatcher = this.geoLocationService.statusChanged.subscribe(async (r: any) => {
                this.geoLocationService.gpsActivated = r.gpsActivated;
                this.geoLocationService.hasGpsPermit = r.hasGpsPermit;
            });
        }

        let locationDisableOrNoPermission;
        await this.geoLocationService.checkPermission().catch(() => {locationDisableOrNoPermission = true;});

        if (this.geoLocationService.gpsActivated && this.geoLocationService.hasGpsPermit && !locationDisableOrNoPermission) {
            return await this.getCurrentLocation();
        } else {
            this.stopLocalization();
            // await this.geolocationService.requestPermission();
            return await this.geoLocationService.requestPermission().then( async (e) => {
                //_ If native allow popup is shown : check again the gpsActivated and permission
                if (this.geoLocationService.gpsActivated && this.geoLocationService.hasGpsPermit) {
                    return await this.getCurrentLocation();
                }

                //_ In case user rejected once before : show custom popup for request permission
                else if (e.location == 'denied'){
                    return await this.requestPermissionInCaseWasRejectedBefore();
                }

                //_ In case FOR ANDROID if device location is disabled then trigger Dialog to enable it
                else {
                    let r  = await this.geoLocationService.requestNativeDialog();
                    if (r) return await this.getCurrentLocation();
                    else this.appService.showToast(
                        '',
                        this._translate.instant('mapPage.clientLocation.needPermissionMessage'),
                        3000,
                        'danger'
                    );
                }
            }).catch( () => {
                this.stopLocalization();
            })
        }
    }

    private stopLocalization() {
        if (this.permissionChangedWatcher) {
            this.permissionChangedWatcher.unsubscribe();
            this.permissionChangedWatcher = null;
        }
        this.geoLocationService.gpsActivated = false;
        this.geoLocationService.hasGpsPermit = false;
    }


  async requestEmailFromAccountManager() {
    let url: string = this.getLostPasswordLink(this._translate.currentLang);
    await this.openUrl(url)
  }

  private getLostPasswordLink(language) {
    let defaultLink = "https://start.finder-portal.com/my-account/lost-password/"; //en
    switch(language?.toLowerCase()){
      case 'de': return "https://start.finder-portal.com/de/mein-konto/lost-password/";
      case 'es': return "https://start.finder-portal.com/es/mi-cuenta/lost-password/";
      case 'it': return "https://start.finder-portal.com/it/il-mio-account/lost-password/";
      case 'fr': return "https://start.finder-portal.com/es/mi-cuenta/lost-password/";
      case 'pt': return "https://start.finder-portal.com/pt-pt/minha-conta/lost-password/";
      case 'el': return "https://start.finder-portal.com/el/%ce%bf-%ce%bb%ce%bf%ce%b3%ce%b1%cf%81%ce%b9%ce%b1%cf%83%ce%bc%cf%8c%cf%82-%ce%bc%ce%bf%cf%85/lost-password/";
      case 'en_us': return "https://start.finder-portal.com/en-us/my-account/lost-password/"
      default: return defaultLink;
    }
  }
}
