import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { StorageService as Storage } from './../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/constants.enum';
import { TourService } from './tour.service';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

export interface TourOptions {
  name,
  skipped?: false,
  ended?: false,
  endText?: string
}

export interface StepOptions {
  dom, title,
  content, image?, tour?,
  position?, delay?, isStart?, name?, imageStyle?,
  mobileImage?, yPosition?: string
}

export const DEFAULT_OPTIONS: TourOptions = { name: 'tour', endText: 'mapTour.end' };
@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss'],
})
export class TourComponent implements OnInit, AfterViewInit {

  holePadding = { top: 0, bottom: 0, left: 0, right: 0 };
  cardSize = { width: 300, height: 'auto' };
  screen = { width: 0, height: 0 };
  step: StepOptions = null;
  isVisible = false;
  maxSteps = 0;
  stepIndex = 0;
  smallScale = 1;

  @ViewChild('hole', { static: true }) hole: ElementRef; //_ hole xD :) :)-
  @ViewChild('tourcard', { static: true }) card: ElementRef;
  tourOptions: TourOptions = { name: 'tour', endText: 'mapTour.end' };

  constructor(private renderer: Renderer2, private tourService: TourService,
    private translate: TranslateService, private storage: Storage, private platform: Platform) { }

  ngAfterViewInit() {
    this.screen.width = window.innerWidth;
    this.screen.height = window.innerHeight;
    this.onResize({ target: { innerWidth: this.screen.width, innerHeight: this.screen.height } });
    this.initAll();
  }

  async ngOnInit() {
    await this.storage.get(Constants.LANGUAGE).then(res => {
      this.translate.use(res ? res : 'en');
    });
  }

  initAll () {
    this.tourService._showStep.subscribe((step: any) => {
      this.maxSteps = this.tourService.maxSteps;
      this.stepIndex = this.tourService.stepIndex;
      this.isVisible = true;

      setTimeout(ts => {
        if (step.dom) {

        // console.log('step.dom',step.dom)
        // console.log('step.dom',document.querySelector(step.dom))
          if (!document.querySelector(step.dom) && step.dom != '.popover-viewport') { //_ For other steps that always need an existent dom object
            if (this.buttonPressed == 'next')
              this.next();
            if (this.buttonPressed == 'back')
              this.back();

          } else //_ For start tour without dom propertie
            this.showStep(step);
        }
        else
          this.showStep(step);
      }, step.delay ? step.delay : 0);

    });

    this.tourService._updateDomHole.subscribe((newSelector) => {
      this.updateDomElementHole(newSelector);
    })
    this.tourService._end.subscribe(value => this.isVisible = false);
    this.tourService._stop.subscribe(value => this.isVisible = false);

    //_ Apply Tour settings
    this.tourService.setTourSettings.subscribe( (newOptions: TourOptions) => {
      Object.keys(DEFAULT_OPTIONS).forEach( k => {
        if (newOptions[k])
          this.tourOptions[k] = newOptions[k];
        else
          this.tourOptions[k] = DEFAULT_OPTIONS[k];
      });
    })

    //_ Reset some variables to prevent show prev step when start the tour.
    //_ Also move and resize the hole to prevent show prev hole
    this.tourService.tourStarts.subscribe(steps => {
      this.step = steps[0];
      this.stepIndex = 1;
      this.moveHole({ width: 0, height: 0, top: 0, left: 0 });
    })

    setTimeout(() => this.centerCard(), 200);
  }

  getPosition(el) {
    const offset = 2;
    let rect = el.getBoundingClientRect();
    let docEl = document.documentElement;

    let rectTop = rect.top + window.scrollY - docEl.clientTop; // window.pageXOffset is deprecated
    if (rectTop == 0) rectTop = 1;

    let rectLeft = rect.left + window.scrollY - docEl.clientLeft;
    if (rectLeft == 0) rectLeft = 1;

    let rectHeight = rect.height;
    if (rectTop + rect.height >= window.innerHeight) {
      let diff = (rectTop + rect.height) - window.innerHeight + offset;
      rectHeight = rect.height - diff;
    }
    let rectWidth = rect.width;
    if (rectLeft + rectWidth >= window.innerWidth) {
      let diff = rectLeft + rectWidth - window.innerWidth + offset;
      rectWidth = rect.width - diff;
    }
    return { top: rectTop, left: rectLeft, width: rectWidth, height: rectHeight };
  }

  //_ Start with delay or 0 timeout
  showStep(step) {
    if (step.isStart) { //_ Show in the midle only for start tour step.
      this.step = step;
      this.card.nativeElement.style.transform = 'scale(1)';
      setTimeout(ts => {
        this.moveHole({ width: 0, height: 0, top: 0, left: 0 });
        this.centerCard();
        // this.card.nativeElement.style.top = (this.screen.height / 2) - (this.card.nativeElement.offsetHeight / 2) + 'px';
        // this.card.nativeElement.style.left = (this.screen.width / 2) - (this.card.nativeElement.offsetWidth / 2) + 'px';
      }, 100);
    }
    else {
      this.card.nativeElement.style.transform = 'scale(' + this.smallScale + ')';
      setTimeout(async ts => {
        this.step = step;
        let el = document.querySelector(step.dom);
        const elements = document.querySelectorAll(step.dom);
        if (this.step.dom == '#map3dview')
          await delay(200);

        let rect = this.getPosition(el);

        setTimeout(tt => { //_ Prevent wrong location if not has setted the size before call the move functions
          this.moveHole(rect);
          this.moveCard(rect, step);
        }, 100)

      }, 0);
    }
  }

  moveHole(rect) {
    this.hole.nativeElement.style.width = rect.width + this.holePadding.right + 'px';
    this.hole.nativeElement.style.height = rect.height + this.holePadding.bottom + 'px';
    this.hole.nativeElement.style.top = rect.top - this.holePadding.top + 'px';
    this.hole.nativeElement.style.left = rect.left - this.holePadding.left + 'px';
  }

  cardOffset = 10;
  async moveCard(rect, step: StepOptions) {
    let xAdjustedPixels = step.mobileImage ? 40 : 10;
    let yAdjustedPixels = step.mobileImage ? 40 : 10;
    let xCard = rect.left + rect.width + this.cardOffset;
    //let yCard = rect.top + rect.height + this.cardOffset;// - this.cardOffset;

    const initialHeight = !Capacitor.isNativePlatform() && step.yPosition == "top" ? 0 : rect.height;

    let yCard = rect.top  + initialHeight + this.cardOffset;
    if (!await this.hasSpaceRight(rect))
      xCard = rect.left - this.card.nativeElement.offsetWidth - this.cardOffset - (this.smallScale == 1 ? 0 : xAdjustedPixels );
    if (await this.hasSpaceLeft(rect))
      xCard = rect.left - this.card.nativeElement.offsetWidth - this.cardOffset + (this.smallScale == 1 ? 0 : xAdjustedPixels );
    if (!await this.hasSpaceBottom(rect))
      yCard = rect.top - this.card.nativeElement.offsetHeight + (this.smallScale == 1 ? 0 : yAdjustedPixels );
    if(await this.hasSpaceTop(rect))
      yCard = rect.top - this.card.nativeElement.offsetHeight - (this.smallScale == 1 ? 0 : yAdjustedPixels );
    if (yCard < 1)
      yCard = rect.top - (this.smallScale == 1 ? 0 : yAdjustedPixels );
    if (xCard < 1) //_ Fix location for small screens
      xCard = rect.left + rect.width + this.cardOffset - (this.smallScale == 1 ? 0 : xAdjustedPixels );
    if ((xCard + this.card.nativeElement.offsetWidth) > this.screen.width)
      xCard = this.screen.width - this.card.nativeElement.offsetWidth - xAdjustedPixels;
    if (yCard + this.card.nativeElement.offsetHeight > this.screen.height)
      yCard = this.screen.height - this.card.nativeElement.offsetHeight + this.adjustPixel(yAdjustedPixels, step);
    this.card.nativeElement.style.top = yCard + 'px';
    this.card.nativeElement.style.left = xCard + 'px';
  }

  adjustPixel(yAdjustedPixels, step) {
    return this.smallScale == 1 ? -yAdjustedPixels :
      step.mobileImage && !step.isStart ?
        yAdjustedPixels : -yAdjustedPixels;
  }

  buttonPressed = null;
  next() {
    this.buttonPressed = 'next';
    this.tourService._buttonPressed.next('next');
    this.tourService.next();
  }

  back() {
    this.buttonPressed = 'back';
    this.tourService._buttonPressed.next('back');
    this.tourService.back();
  }

  end(from) {
    this.buttonPressed = from;
    this.tourService._buttonPressed.next(from);
    this.tourService.end();
    this.centerCard();
  }

  isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screen.width = this.platform.width(); //event.target.innerWidth;
    this.screen.height = this.platform.height(); //event.target.innerHeight;

    if (this.screen.width < 768)
      this.smallScale = 0.7;
    else
      this.smallScale = 1;

    this.card.nativeElement.style.transform = 'scale(' + this.smallScale + ')';
    this.isSmallScreen = this.screen.width < 768;

    setTimeout(() => this.centerCard(), 50);
    if (this.step)
      setTimeout(r => this.showStep(this.step), 100);
  }

  hasSpaceRight(rect) {
    return (rect.left + rect.width + this.card.nativeElement.offsetWidth + this.cardOffset) < this.screen.width;
  }

  hasSpaceLeft(rect) {
    return (rect.left + rect.width + this.card.nativeElement.offsetWidth + this.cardOffset > this.screen.width && rect.left > this.card.nativeElement.offsetWidth);
  }

  async hasSpaceBottom(rect) {
    return (rect.top + this.card.nativeElement.offsetHeight) < this.screen.height;
  }

  async hasSpaceTop(rect) {
    const iosSafePaddingTop = Capacitor.getPlatform() == "ios" ? 15 : 0;
    return (rect.top + rect.height + this.card.nativeElement.offsetHeight + iosSafePaddingTop > this.screen.height && rect.top > this.card.nativeElement.offsetHeight)
  }

  //_ Center the card at startup
  centerCard(){
      let top = (this.screen.height / 2) - (this.card.nativeElement.offsetHeight / 2)
      let left = (this.screen.width / 2) - (this.card.nativeElement.offsetWidth / 2);
      //_In case top or left is less than the visible area; add a margin of 15px
      top = Math.max(top, 15);
      left = Math.max(left, 15);
      this.card.nativeElement.style.top = top + 'px';
      this.card.nativeElement.style.left = left + 'px';
  }

  updateDomElementHole(newSelector){
    const step = this.step;
    if (newSelector)
      step.dom = newSelector;
    let el = document.querySelector(step.dom);
    let rect = this.getPosition(el);

    // console.log('RECALCULATE DOMO HOLE', {newSelector, el, rect});
    setTimeout(tt => { //_ Prevent wrong location if not has setted the size before call the move functions
      this.moveHole(rect);
      this.moveCard(rect, step);
    }, 100)
  }
}
const delay = ms => new Promise(res => setTimeout(res, ms));
