import { EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";

export class CancelablePromise<T> {
  private promise: Promise<T>;
  private isCanceled: boolean = false;
  private httpSubscription: Subscription | null = null;
  public onCancel = new EventEmitter<void>();
  public isCompleted = false;
  constructor(executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void, cancel: () => void) => void, private onCancelCallback?: () => void) {
    this.promise = new Promise((resolve, reject) => {
      executor(resolve, reject, () => {
        this.isCanceled = true;
        reject(new Error("Promise canceled"));
      });
    });
  }

  then(onFulfilled?: ((value: T) => T | PromiseLike<T>) | undefined | null, onRejected?: ((reason: any) => T | PromiseLike<T>) | undefined | null): Promise<T> {
    if (this.isCanceled) {
      return Promise.reject(new Error("Promise canceled"));
    }
    return this.promise.then(onFulfilled, onRejected);
  }

  catch(onRejected?: ((reason: any) => T | PromiseLike<T>) | undefined | null): Promise<T> {
    if (this.isCanceled) {
      return Promise.reject(new Error("Promise canceled"));
    }
    return this.promise.catch(onRejected);
  }

  cancel() {
    this.isCanceled = true;
    this.isCompleted = true;
    this.onCancel.emit();
    if (this.onCancelCallback) {
      this.onCancelCallback();
    }
    if (this.httpSubscription) {
      this.httpSubscription.unsubscribe();
      this.httpSubscription.closed = true;
    }

    console.warn('Promise canceled; DONT WORRY for this error message.');
    return Promise.reject({ message: "Promise canceled" });
  }

  setHttpSubscription(subscription: Subscription) {
    this.httpSubscription = subscription;
  }

  resolvePromise(value?: T | PromiseLike<T>) {
    if (!this.isCanceled) {
      this.promise = Promise.resolve(value);
    }
  }

  rejectPromise(reason?: any) {
    if (!this.isCanceled) {
      this.promise = Promise.reject(reason);
    }
  }
}