import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { StorageService as Storage } from '../../../services/storage.service';
import { ApiServerAlert } from './apis';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ServerAlertService {
    alerts = [];
    addAlert$ = new Subject();
    constructor(private api: ApiServerAlert) {

    }

    get() {
        return this.api.getServerAlerts().then(r => {
            return r;
        }).catch(e => { return null; });
    }

    addAlert(alert){
        this.addAlert$.next(alert);
    }
}