import { Constants } from 'src/app/constants.enum';

//_____________________ ATMOSPHERES AND COLORS _____________________
export interface AtmosphereSettings {
  range?: [number, number];
  color: string; // Lower atmosphere
  'high-color': string; // Upper atmosphere
  'horizon-blend'?: any; // Atmosphere thickness (default 0.2 at low zooms)
  'space-color'?: any; // Background color
  'star-intensity': any; // Background star brightness (default 0.35 at low zooms )
  'vertical-range'?: [number, number]
}

export const DEFAULT_ATMOSPHERE: AtmosphereSettings = {
  range:  [0.5, 10],
  color: 'rgb(135, 188, 246)',
  'high-color': 'rgb(36, 92, 223)',
  'horizon-blend': ["interpolate", ["linear"], ["zoom"], 4, 0.01, 7, 0.1],
  'space-color': ["interpolate", ["linear"], ["zoom"], 4, "#010b19", 7, "#367ab9"],
  'star-intensity': ["interpolate", ["linear"], ["zoom"], 5, 0.35, 6, 0],
  'vertical-range': [0, 0]
}

export const DARK_ATMOSPHERE = {
  ...DEFAULT_ATMOSPHERE,
  color: 'rgb(0, 0, 20)',
  'high-color': 'rgb(0, 0, 40)',
  'space-color': ["interpolate", ["linear"], ["zoom"], 4, "#010b19", 7, "#02284C"]
}

export const LIGHT_ATMOSPHERE = {
  ...DEFAULT_ATMOSPHERE,
  color: 'rgb(186, 210, 235)',
}

// ___________________________________________________________________

export type MapStyleItem = {
  name: string, value: string, image: string, url: string | any, atmosphere: AtmosphereSettings,
  isUserSetting: boolean,
  backround?: string
}

export type MapStylesType = { [key: string]: MapStyleItem };
export const MapStylesList: MapStylesType = {
    // osm: {
    //     name: 'Open Streep Map',
    //     value: 'osm',
    //     image: 'assets/dummy/path/osm.svg',
    //     url: Constants.MAP_TILES_PAJ_HILL
    // },
    paj: {
        name: 'PAJ Streets',
        value: 'paj',
        image: 'assets/images/app-settings/standardMap.png',
        url: Constants.MAP_TILES_PAJ_STREET_3D,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#61C7F8',
        isUserSetting: true
    },
    pajLite: {
        name: 'deviceManagement.pajMapsLiteText',
        value: 'pajLite',
        image: 'assets/images/app-settings/liteMap.png',
        url: Constants.MAP_TILES_PAJ_STREET_RASTER,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#61C7F8',
        isUserSetting: true
    },
    pajhills: {
        name: 'PAJ Hills',
        value: 'pajhills',
        image: 'assets/images/app-settings/hillMap.png',
        url: Constants.MAP_TILES_PAJ_HILL,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#61C7F8',
        isUserSetting: false
    },
    '3d': {
        name: '3D',
        value: '3d',
        image: 'assets/images/app-settings/hillMap.png',
        url: Constants.MAP_TILES_PAJ_STREET_3D,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#66CAFE',
        isUserSetting: false
    },
    // mtsatellite: {
    //     name: 'MT Satellite',
    //     value: 'mtsatellite',
    //     image: 'assets/dummy/path/mtsatellite.svg',
    //     url: Constants.MAP_TILES_MAPTILER_SATELLITE,
    //     atmosphere: DEFAULT_ATMOSPHERE,
    //     backround: '',
    //     isUserSetting: false
    // },
    mapbox_satellite_streets_v11: {
      name: 'Satellite',
      value: 'mapbox_satellite_streets_v11',
      image: 'assets/images/app-settings/mapbox_satellite_streets_v11.png',
      url: Constants.MAP_TILES_MAPBOX_SATELLITE_STREETS_V12,
      atmosphere: DEFAULT_ATMOSPHERE,
      backround: '#030A18',
      isUserSetting: true
    },
    mapbox_standard: {
        name: '3D Building',
        value: 'mapbox_standard',
        image: 'assets/images/app-settings/mapbox-standard.png',
        url: Constants.MAP_TILES_MAPBOX_STANDARD,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#66CAFE',
        isUserSetting: true
    },
    mapbox_outdoor_v11: {
        name: 'Outdoor',
        value: 'mapbox_outdoor_v11',
        image: 'assets/images/app-settings/mapbox_outdoor_v11.png',
        url: Constants.MAP_TILES_MAPBOX_OUTDOOR_V11,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#75CFF0',
        isUserSetting: true
    },

    mapbox_street_v12: {
      name: 'Mapbox street v11',
      value: 'mapbox_street_v11',
      image: 'assets/images/app-settings/mapbox_street_v11.png',
      url: Constants.MAP_TILES_MAPBOX_STREETS_V11,
      atmosphere: DEFAULT_ATMOSPHERE,
      backround: '#75CFF0',
      isUserSetting: false
    },
    mapbox_light_v10: {
        name: 'Mapbox Light V10',
        value: 'mapbox_light_v10',
        image: 'assets/images/app-settings/mapbox_light_v10.png',
        url: Constants.MAP_TILES_MAPBOX_LIGHT_V10,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#CAD3D3',
        isUserSetting: false
    },
    mapbox_dark_v10: {
        name: 'Mapbox Dark V10',
        value: 'mapbox_dark_v10',
        image: 'assets/images/app-settings/mapbox_dark_v10.png',
        url: Constants.MAP_TILES_MAPBOX_DARK_V10,
        atmosphere: DARK_ATMOSPHERE,
        backround: '#191A1A',
        isUserSetting: false
    },
    mapbox_satellite_v9: {
        name: 'Mapbox Satellite V9',
        value: 'mapbox_satellite_v9',
        image: 'assets/images/app-settings/mapbox_satellite_v9.png',
        url: Constants.MAP_TILES_MAPBOX_SATELLITE_V9,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#030A18',
        isUserSetting: false
    },
    mapbox_navigation_day_v1: {
        name: 'Mapbox Navigation Day V1',
        value: 'mapbox_navigation_day_v1',
        image: 'assets/images/app-settings/mapbox_navigation_day_v1.png',
        url: Constants.MAP_TILES_MAPBOX_NAVIGATION_DAY_V1,
        atmosphere: DEFAULT_ATMOSPHERE,
        backround: '#91DFFE',
        isUserSetting: false
    },
    mapbox_navigation_night_v1: {
        name: 'Mapbox Navigation Night V1',
        value: 'mapbox_navigation_night_v1',
        image: 'assets/images/app-settings/mapbox_navigation_night_v1.png',
        url: Constants.MAP_TILES_MAPBOX_NAVIGATION_NIGHT_V1,
        atmosphere: DARK_ATMOSPHERE,
        backround: '#5E757D',
        isUserSetting: false
    }
};

export const directionMarker = {
    'arrow': 'assets/images/map/arrow.png',
    'circle': 'assets/images/map/circle.png',
    'wifi': 'assets/images/map/wifi.png'
}

//_ DEPRECATED -> Now using top MapStylesList
export const urlStyles = {
  osm: Constants.MAP_TILES_PAJ_HILL,
  paj: Constants.MAP_TILES_PAJ_STREET,
  pajLite: Constants.MAP_TILES_PAJ_STREET_RASTER,
  pajhills: Constants.MAP_TILES_PAJ_HILL,
  '3d': Constants.MAP_TILES_PAJ_STREET_3D,
  mtsatellite: Constants.MAP_TILES_MAPTILER_SATELLITE,
  mapbox_standard: Constants.MAP_TILES_MAPBOX_STANDARD,
  mapbox_street_v11: Constants.MAP_TILES_MAPBOX_STREETS_V11,
  mapbox_outdoor_v11: Constants.MAP_TILES_MAPBOX_OUTDOOR_V11,
  mapbox_satellite_streets_v12: Constants.MAP_TILES_MAPBOX_SATELLITE_STREETS_V12,
  mapbox_light_v10: Constants.MAP_TILES_MAPBOX_LIGHT_V10,
  mapbox_dark_v10: Constants.MAP_TILES_MAPBOX_DARK_V10,
  mapbox_satellite_v9: Constants.MAP_TILES_MAPBOX_SATELLITE_V9,
  mapbox_navigation_day_v1: Constants.MAP_TILES_MAPBOX_NAVIGATION_DAY_V1,
  mapbox_navigation_night_v1: Constants.MAP_TILES_MAPBOX_NAVIGATION_NIGHT_V1,
};
