import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  //_ Update displayed route in map
  private _udpateRoute = new Subject<{}>();
  updateRoute$ = this._udpateRoute.asObservable();

  distanceUpdated = new Subject();
  distanceUpdated$ = this.distanceUpdated.asObservable();
  //_ Update displayed route in map
  _loadingRoute = new Subject<{}>();
  loadingRoute$ = this._loadingRoute.asObservable();
  canceledValhallaRequest = new Subject();
  selectSliderPoint = new Subject();
  constructor() { }

  updateRoute(data) { //Trigger event
    this._udpateRoute.next(data);
  }

}
