import { animate, AnimationMetadata, style, AnimationBuilder } from '@angular/animations';
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[back-close]'
})
export class BackCloseDirective implements OnInit {
  hostElement: HTMLElement
  @Input('disableClose') disableClose = false;
  constructor(public elementRef: ElementRef, public render: Renderer2, private platform: Platform,
    private builder: AnimationBuilder) { }

  async ngOnInit() {
    this.hostElement = this.elementRef.nativeElement;
    let buttons = this.hostElement.parentElement;

    if (this.disableClose) return;
    await this.platform.ready().then(() => {
      if (screen.width >= 768) {
        this.render.setProperty(this.hostElement, 'style', 'display:none'); //_ hide to not show at first start
        setTimeout(() => { //Platformm ready didnt work; so is better wait a bit to get htmlcollections items
          let ionIcon = this.hostElement.shadowRoot.querySelector('ion-icon');
          const partText = this.hostElement.shadowRoot.querySelector('.button-text');

          this.render.setProperty(buttons, 'slot', 'end');
          this.render.setProperty(ionIcon, 'name', 'close-circle-outline');
          this.render.setProperty(buttons, 'style', 'margin-right: 10px');

          if (partText)
            this.render.setProperty(partText, 'style', 'display: none');




          const factory = this.builder.build(this.fadeIn());
          const player = factory.create(this.hostElement);

          player.play();
          this.render.setProperty(this.hostElement, 'style', 'display: block'); //_ display again, after changed the icon x :D
        }, 400);
      }
    })
  }

  private fadeIn(): AnimationMetadata[] {
    return [
      style({ opacity: 0 }),
      animate('400ms ease-in', style({ opacity: 1 })),
    ];
  }
}
