<ion-header *ngIf="title">
  <ion-toolbar>
    <ion-item color="transparent" lines="none">
      <ion-label class="ion-text-wrap ion-text-center"> <h3>{{ title }}</h3></ion-label>
    </ion-item>
  </ion-toolbar>
</ion-header>

<ion-content class="language-popover-content">
  <ion-list lines="none" mode="md">
    <ion-radio-group [value]="selectedCurrency?.shortCode">
      <ion-item *ngFor="let curr of currencies" detail="false" (click)="selectCurrency(curr)">
        <!-- <ion-icon [src]="curr.img" slot="start"></ion-icon> !-->
        <span style="font-size: 25px;width: 50px;">{{ curr.id }}</span>
        <ion-label>{{ curr.name }}</ion-label>
        <ion-radio [value]="curr.shortCode" slot="end"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
