import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { VoiceMessagesComponent } from './voice-messages.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { AudioItemModule } from './audio-item/audio-item.module';
import { PajTimerCountModule } from 'src/app/members/generate-video/timer-count/paj-timer-count.module';
import { PredefinedAudiosModule } from './predefined-audios/predefined-audios.module';



@NgModule({
  declarations: [VoiceMessagesComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    PipesModule,
    AudioItemModule,
    PajTimerCountModule,
    PredefinedAudiosModule
  ],
  exports: [VoiceMessagesComponent]
})
export class VoiceMessagesModule { }
