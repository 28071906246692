import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Constants } from 'src/app/constants.enum';
import { PwaUpdateService } from 'src/app/services/pwa-update.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-pwa-update',
  templateUrl: './pwa-update.component.html',
  styleUrls: ['./pwa-update.component.scss'],
})
export class PwaUpdateComponent implements OnInit {
  @Input() showWhatsNew = false;
  @Input() alert = null;
  onUpdate = () => {};
  language = 'en';
  messageKey = 'message';

  alertArray: Array<any> = []
  constructor(private storage: StorageService, private modalCtrl: ModalController) { }

  async ngOnInit() {
    await this.loadLanguage();
    this.alertArray = this.alert ? this.alert.alert[this.messageKey].split('<br>') : [];
  }

  async loadLanguage () {
    return this.storage.get(Constants.LANGUAGE).then(r => {
      this.language = r;
      this.messageKey = !this.language.startsWith('en') && this.language != null ? 'message_' + this.language : 'message';
      this.messageKey = this.language == 'pt' ? 'message_pr' :  this.messageKey;
    });
  }

  close(){
    this.modalCtrl.dismiss();
  }

  update(){
    this.onUpdate();
  }
}
