<ng-container [ngTemplateOutlet]="headerTemplate || DefaultHeaderTemplate"></ng-container>


<ion-content [class]="cssContentClass">
  <ng-container [ngTemplateOutlet]="contentTemplate || DefaultContentTemplate"></ng-container>
</ion-content>


<ng-template #DefaultHeaderTemplate>
  <ion-header mode="md">
    <ion-toolbar color="dark">
      <ion-buttons slot="end">
        <ion-button (click)="dismiss()">
          <ion-icon
            slot="icon-only"
            name="close-circle-outline"
          ></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        <div class="ion-text-wrap">
          {{ headerTitle }}
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>
</ng-template>

<ng-template #DefaultContentTemplate>
</ng-template>