import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { animations } from 'src/app/animations/animations';
import { AppService } from 'src/app/app.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { ActivityService } from './activity.service';
import { DevicesDashboardService } from '../devices-dashboard.service';
import { STORAGE_SETTINGS } from "../../../../../services/storage-settings";
import { RouteService } from '../../../devices-sidebar/device-item/device-submenu/route/route.service';
import { DeviceDataService } from '../../../devicesData.service';
import { Subject, takeUntil } from 'rxjs';
import { DeviceChangeService } from './devices.service';

@Component({
  selector: 'app-devices-activity',
  templateUrl: './devices-activity.component.html',
  styleUrls: ['./devices-activity.component.scss'],
  animations: animations,
})
export class DevicesActivityComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() device = null;


  userSettings = null;
  userId = null;
  scrollTop = 0;
  opacityDeviceItem = 1;
  @ViewChild('content', { static: true }) content: IonContent;
  activitySegment = undefined; //_ last | today
  isBrowser = window['isBrowser'];
  devices = [];

  cmpDestroyed = new Subject();
  language = this.appService.language == 'en_US' ? 'en' : this.appService.language;
  constructor(private storage: StorageService, private appService: AppService, private userSettingsSrv: UserSettingsService,
              private selfModal: ModalController, private modalCtrl: ModalController, private activityService: ActivityService,
              private dashboardService: DevicesDashboardService, private routeService: RouteService, private devicesService:DeviceDataService,
              private deviceChangeService: DeviceChangeService) { }

  ngOnInit() {
    this.appService.language$.pipe(takeUntil(this.cmpDestroyed)).subscribe(lang => {
      this.language = lang == 'en_US' ? 'en' : lang;
    });
  }

  async updateDevicesList(id, name){
    if(id && name){      
      const newDevices = this.devices.map((d:any) => {        
        return d.id == id ? {...d, name} : d
      })

      this.devices =  [...newDevices]
    }    

    if(this.device.id == id){
      this.device = {...this.device, name};
    }    
  }

  async ngAfterViewInit () {
    this.deviceChangeService.changeDevice$.subscribe((r:any) => {
      if(r){     
        const name = r.name;
        const id = r.id        
        this.updateDevicesList(id, name);      
      }      
    })

    this.userId = this.appService.user.id;
    const devices = this.appService.devices.map( d => { return { ...d, device: d, customer_id: this.appService.userId } });
    this.devices = await this.dashboardService.filterLogbookDevices(devices);

    this.activitySegment = this.activityService.DEVICE_ACTIVITY_TYPE.get().value;

    this.activityService.DEVICE_ACTIVITY_TYPE.get().subscribe(r => {
      this.activitySegment = r;
    });

    this.device = await this.storage.get(STORAGE_SETTINGS.DEVICE_ACTIVITY_SELECTED_DEVICE);

    if (!this.device && this.devices.length > 0){
      this.device = this.devices[0];
      this.activityService.DEVICE_ACTIVITY_SELECTED_DEVICE.set(this.device);
    }

    this.activityService.DEVICE_ACTIVITY_SELECTED_DEVICE.get().subscribe(r => {
      this.device = r;
    })

    this.userSettings = this.userSettingsSrv.userSettings;
  }

  ngOnDestroy() {
    this.cmpDestroyed.next(true);
    this.cmpDestroyed.complete();
  }

  flyTo(ev){

  }

  async selectDevice(){
    // const modal = await this.modalCtrl.create({
    //   component: SelectSearchableComponent,
    //   componentProps: {
    //     devices: this.appService.devices,
    //     userId: this.userId
    //   }
    // });

    // await modal.present();
    // const { data } = await modal.onWillDismiss();
    // if (data) {
    //   this.device = data.selDevice;
    //   this.DEVICE_ACTIVITY_SELECTED_DEVICE.set(this.device);

    // }
  }

  //_ NEEDS TO CREATE A DIRECTIVE TO REDUCE THE CODE AND REUSE IT
  //_ Math opacity by scrolling position
  async logScrolling(ev){
    // this.scrollTop = ev.detail.scrollTop;
    // const scroller = await this.content.getScrollElement();
    // // console.log('SCROLLING', { ev: ev.detail, offseet: scroller.offsetHeight, height: scroller.scrollHeight});
    // const itemHight = 75;
    // if ( (scroller.scrollHeight - scroller.offsetHeight) < itemHight){
    //   this.opacityDeviceItem = 1;
    //   return;
    // }

    // let alpha = Math.round((this.scrollTop*100)/itemHight);
    // if (alpha > 100) alpha = 100;
    // this.opacityDeviceItem = this.round((100-alpha)/100, 2);
  }

  changeActivitySegment(type){
    this.activitySegment = type;
    this.activityService.DEVICE_ACTIVITY_TYPE.set(type);
  }

  round(num, decimalPlaces = 0) {
    var p = Math.pow(10, decimalPlaces);
    var n = (num * p) * (1 + Number.EPSILON);
    return Math.round(n) / p;
  }
  //____________________________________________________

  dismiss(){
    this.selfModal.dismiss();
  }

  deviceChanged(ev){
    // this.routeService.updateRoute({
    //   dismissed: true,
    //   datapoints: this.devicesService.devices[this.device.id].previousDataPoints,
    //   rangeMode: this.devicesService.devices[this.device.id].properties.spurmodus,
    //   deviceId: this.device.id,
    //   onlyUpdateRoute: true,
    //   clearRouteMarkers: true
    // });

    this.device = ev;
    this.activityService.DEVICE_ACTIVITY_SELECTED_DEVICE.set(this.device);
    // this.getTimlineData();
  }



}
