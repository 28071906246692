import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { StorageService as Storage } from '../../services/storage.service';
import { MapMinComponent } from 'src/app/members/generate-video/map-min/map-min.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { fixNumber, getStreetAddress, getStreetAddressNew, countryBounds, getLogbookAddress } from './util';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/app.service';
import {LngLatBounds, Popup} from "maplibre-gl";
import { TranslateService } from '@ngx-translate/core';
import { Keyboard } from '@capacitor/keyboard';
import {AlertService} from "../../members/map/devices-sidebar/device-item/device-submenu/alert/alert.service";
import {circle as Tcircle} from "@turf/turf";
import {MapService} from "../../members/map/components/map/map.service";
import {CustomDirectMode} from "../../members/map/components/map/class/mapbox-draw/customDirectMode";
import * as MapboxDraw from "../../members/map/components/map/class/mapbox-draw/customMapboxDraw";
import {styles} from "../../members/map/components/map/class/geofences";
import {customDragMode} from "../../members/map/components/map/class/customDragmode";
import {CenterPoint, StaticMode} from "../../members/map/components/map/class/geofence_center";
import {
    CircleMode,
    DragCircleMode,
    SimpleSelectMode,
} from "../../members/map/components/map/class/custom-drawer-libs/mapbox-gl-draw-circle";
import { Subject, takeUntil } from 'rxjs';
import { getMostAccurateAddress } from './iaddress-search';

@Component({
  selector: 'app-map-address-picker',
  templateUrl: './map-address-picker.component.html',
  styleUrls: ['./map-address-picker.component.scss'],
})
export class MapAddressPickerComponent implements OnInit, OnDestroy {
  @ViewChild('map') map: MapMinComponent;
  @Input() coords = { lat: 0, lng: 0 };
  @Input() markerTitle = '';
  @Input() modalTitle = '';
  @Input() defaultType;
  @Input() limitQueryByCountryBox = false;
  @Input() showWorkAndHomeOptions: boolean = false; // Define the new prop
  @Input() showGeofenceArea: boolean = false; // Geofence flat to show a circle below the marker
  @Input() mapStyle = 'paj';

  title = 'deviceManagement.chooseAddressText';
  countryTitle = 'deviceManagement.chooseAddressCountryText';

  hideInfo = true;
  newCoords = { lat: 0, lng: 0 };
  address = null;
  type = null;
  loadingAddress = false;
  canSearch = true;
  addressInput = '';
  showList = false;
  countryBounds = null;
  mapLoaded:boolean=false;
  workAddress = null;
  homeAddress = null;
  geofenceDrawer: MapboxDraw = null;
  primaryColor = '#f05123';
  cmpDestroyed$ = new Subject();
  markerName = 'marker' + new Date().getTime();
  constructor(private modalCtrl: ModalController, private authService: AuthenticationService, private popoverCtrl: PopoverController,
    private storage: Storage, private apiService: ApiService, private appService: AppService,
    private _translate: TranslateService, private platform: Platform,
              private alertService: AlertService, private centerPoint: CenterPoint) {
      this.customerAddress();

    }


  async customerAddress()
  {
    let info = await this.authService.getCustomerInfo();
    if(info && info['success'].length)
    {
      if(info['success'][0]['home_address'])
      {
        this.homeAddress = {
          'originalStringAddress': info['success'][0]['home_address'],
          "stringAddress": this._translate.instant('routeForm.myHomeAddressLabel'),
          //"stringAddress": "MY HOME ADDRESS",
          "coordinates":[ info['success'][0]['home_lng'] , info['success'][0]['home_lat']],
          props: {
            "type":"home"
          }
        };
        if(this.homeAddress['originalStringAddress'] == this.address)
        {
          this.type = 'home';
        }
      }
      if(info['success'][0]['work_address'])
      {
        this.workAddress = {
          'originalStringAddress': info['success'][0]['work_address'],
          "stringAddress": this._translate.instant('routeForm.myWorkAddressLabel'),
          //"stringAddress": "MY WORK ADDRESS",
          "coordinates":[ info['success'][0]['work_lng'], info['success'][0]['work_lat']],
          props: {
            "type":"work"
          }
        };
        if(this.workAddress['originalStringAddress'] == this.address)
        {
          this.type = 'work';
        }
      }
    }
  }

  ngOnInit() {
    let style = getComputedStyle(document.body);
    this.primaryColor = style.getPropertyValue('--ion-color-primary');
    if (this.address) {
      var find = '<br/>';
      var re = new RegExp(find, 'g');
      this.address = this.address.replace(re, '');
    }
    this.firstLoad();

    this.appService.language$.subscribe((language) => {
      this.mapboxControlsTranslation();
    });
    this.mapboxControlsTranslation();
    if(this.defaultType){
      this.type = this.defaultType;
    }

  }

  async firstLoad() {
    //_ Config to only show one time the infos
    await this.storage.get('configs.map-address-picker.hideInfo').then(r => {
      if (!r) {
        this.hideInfo = false;
        this.map.map?.resize();
        this.storage.set('configs.map-address-picker.hideInfo', true);
      } else this.hideInfo = r;
    });
  }

  //_
  //_ Start after ngOnInit
  //_ Prevent null map or undefined

  ionViewWillEnter() {
    this.map.mapLoaded.pipe(takeUntil(this.cmpDestroyed$)).subscribe((value)=>{
      if(!value) return
      this.map.map?.resize();

      this.mapEvents();
      let zeroCoords = { lat: 0, lng: 0 };
      //_ Change circle layer opacity and source to use same point of the marker
      if (this.showGeofenceArea){
        this.setupMapCircleLayer();
        this.markerTitle = this.CIRCLE_RADIUS + ' mt';
      }

      if (this.coords != zeroCoords) {
        this.getCountryBounds(this.coords);
        this.map.addLayerMarker(this.markerName, this.coords.lng, this.coords.lat, 'assets/images/locate-device/pin-location.png', 0.4, this.markerTitle, null, 'viewport', null, { 'text-offset': [0, 2], 'text-color': this.appService.userSettings.dark_mode === 0 ? '#000000' : '#ffffff'});
        this.map.centerTo(this.coords.lng, this.coords.lat, 18, 0.5);

        if (this.showGeofenceArea)
          this.map.addCircle([this.coords.lng, this.coords.lat], "address-circle", this.primaryColor, this.CIRCLE_RADIUS, 3, this.primaryColor);
      }
    })
  }

  countryCode = null;
  async getCountryBounds(coords) {
    await this.authService.getStreetAdrressNew(coords.lat, coords.lng).then(res => {
      const response = res['success'];
      if (response) {
        if (response.features.length > 0) {
          const res = response.features[0].properties;
          this.countryCode = res.countrycode;
          this.countryBounds = countryBounds()[res.countrycode] ? countryBounds()[res.countrycode] : null;
          this.mapLoaded = true;
          if (!this.address) {
            this.address = getLogbookAddress(response.features[0].properties).address;
          }
        }
      }
    });
  }

  //_ Close modal when click on cancel button or ion-back button
  close() {
    this.modalCtrl.dismiss();
  }

  //_
  //_ Functions for dragging feature
  //_
  clickAdress = false;
  isDragged = false;
  mapPopup = null;
  mapEvents() {
    const tc = this;

    //_ FOR DESKTOP/MOBILE DEVICES
    this.map.map?.on('mousedown', 'markers', startDragging);
    this.map.map?.on('touchstart', 'markers', startDragging);
    this.map.map?.on('click', 'markers', (feature) => {
      feature.preventDefault();

      this.mapPopup = new this.map.Popup({ offset: 25, className: 'new-map-popup' })
        .setLngLat([feature.lngLat.lng, feature.lngLat.lat])
        .setHTML(('<p class="ABC">' + this._translate.instant('mapAddressPicker.popupText') + '</p>'))
        .addTo(this.map.map);
    });



    //_ When start drawing, add mouse move and mouse up listeners
    function startDragging(e) {
      e.preventDefault();
      tc.isDragged = false;
      if (tc.mapPopup) tc.mapPopup.remove();
      let features = tc.map.map.queryRenderedFeatures(e.point);
      if (features.length > 0) {
        if (features[0].properties.icon == tc.markerName) {
          tc.map.map.getCanvas().style.cursor = 'grab';
          tc.clickAdress = true;

          tc.map.map.on('mousemove', dragging);
          tc.map.map.on('touchmove', dragging);
          tc.map.map.on('touchend', endDragging);
          tc.map.map.on('mouseup', endDragging);
        }
      }
    }

    function dragging(e) {
      e.preventDefault();
      if (tc.clickAdress) {
        tc.isDragged = true;
        tc.newCoords.lat = fixNumber(e.lngLat.lat, 7);
        tc.newCoords.lng = fixNumber(e.lngLat.lng, 7);
        tc.map.moveMarker(tc.markerName, [tc.newCoords.lng, tc.newCoords.lat]);
        tc.map.map.getCanvas().style.cursor = 'grabbing';
      }
    }

    //_ When end drawing, delete mouse move and mouse up listeners
    function endDragging(e) {
      e.preventDefault();
      if (tc.clickAdress && tc.isDragged) {
        tc.map.map.getCanvas().style.cursor = 'default';
        tc.updateAddressByLatLng(tc.newCoords);
      }

      tc.clickAdress = false;
      tc.map.map.off('mousemove', dragging);
      tc.map.map.off('touchmove', dragging);
      tc.map.map.off('mouseup', endDragging);
      tc.map.map.off('touchend', endDragging);
    }
  }
  //_ Send data when click on ok button
  select() {
    this.modalCtrl.dismiss({coords: this.newCoords, address: this.address, type: this.type });
  }

  //_
  //_ Request new address to the api
  //_ using getStreetAddres to parse the api response
  async updateAddressByLatLng(_coords) {
    this.loadingAddress = true;
    await this.authService.getStreetAdrressNew(_coords.lat, _coords.lng).then(res => {
      const response = res.success;
      if (response) {
        if (response.features.length > 0) {
          this.address = getLogbookAddress(response.features[0].properties).address;
        }
        else {
          this.address = "";
        }
      }
    });
    this.loadingAddress = false;
  }

  updateMap() {
    this.hideInfo = true;
    setTimeout(() => this.map.map.resize(), 100); //wait a bit hide the div, to resize the map again
  }

  addressList = [];
  searchAddress() {
    if (this.addressInput != '') {
      this.addressList = [];
      let bounds = '';
      if (this.countryBounds && this.limitQueryByCountryBox)
        bounds = '&bbox=' + this.countryBounds.join(',');

      this.apiService.searchAddress(this.addressInput + '&limit=100' + bounds).then(r => {
        if (r.success) {
          let features = r.success.features;

          // console.log('DEBUG - COUNTRY CODE --', { countryCode: this.countryCode, countryBounds: this.countryBounds, features: [ ...features ]})
          if (this.countryCode)
            features = features.sort((a: any, b: any) => (b.properties.countrycode === this.countryCode ? 1 : 0) - (a.properties.countrycode === this.countryCode ? 1 : 0));

          const allFeatures = JSON.parse(JSON.stringify(features));
          features = features.slice(0, 20);
          this.addressList = features.map(f => {
            return {
              stringAddress: getLogbookAddress(f.properties).address,
              coordinates: f.geometry.coordinates, props: f.properties
            }
          });

          console.log('DEBUG - COUNTRY CODE --', { allFeatures, features, addressList: this.addressList, isearch: getMostAccurateAddress(features, this.addressInput) })
          if (this.addressList.length == 0)
            this.appService.showToast('', this._translate.instant('toast.noAddressFound'), 3000, 'danger');
          else
            this.showList = true;

          this.updateNewCoords();
          if (!(this.newCoords?.lat ==  0 && this.newCoords?.lng == 0))
            this.address = this.addressInput;
        }
      }).catch(e => this.appService.showToast('', this._translate.instant('toast.errorSearchAddress'), 3000, 'danger'));
    }
    else {
      this.addressList = [];
      this.updateNewCoords();
    }
    if(!this.defaultType)
    {
      this.homeAddress ? this.addressList.push(this.homeAddress) : null;
      this.workAddress ? this.addressList.push(this.workAddress) : null;
    }

  }

  updateNewCoords() {
    if (this.addressInput.length) {
      this.newCoords.lat = this.coords.lat;
      this.newCoords.lng = this.coords.lng;
    } else {
      this.newCoords.lat = 0;
      this.newCoords.lng = 0;
      this.address = null;
    }
  }

  selectAddress(address) {
    this.showList = false;
    if(this.homeAddress && address.stringAddress == this.homeAddress['stringAddress'])
    {
      this.address = this.homeAddress['originalStringAddress']
    }
    else if(this.workAddress && address.stringAddress == this.workAddress['stringAddress'])
    {
      this.address = this.workAddress['originalStringAddress'];
    }
    else {
      this.address = address.stringAddress;
    }
    this.type = this.defaultType ? this.defaultType : address.props.type;
    this.coords = { lng: address.coordinates[0], lat: address.coordinates[1] };
    const point: any = [this.coords.lng, this.coords.lat];
    this.newCoords = { lat: this.coords.lat, lng: this.coords.lng };

    this.map.moveMarker(this.markerName, point);
    this.map.map.flyTo({
      center: point,
      zoom: 15
    });
    //this.map.flyTo(point[0], point[1], 15, 1);
  }


  hideKeyboard() {
    if (this.platform.is('cordova') || this.platform.is('capacitor')){
      Keyboard.hide();
    }
  }
  mapboxControlsTranslation() {
    const setTitle = (elClass, title) => {

      const el: any = document.querySelectorAll(elClass);
      el.forEach(element => {
        element.title = title
      });
      //el.title = title
    };
    setTimeout(() => {
      setTitle('.maplibregl-ctrl-zoom-in', this._translate.instant('mapBoxControls.zoomIn'));
      setTitle('.maplibregl-ctrl-zoom-out', this._translate.instant('mapBoxControls.zoomOut'));
    }, 1000);

  }

  saveAddressType(event, type) {
    if(this.defaultType)
    {
      return;
    }
    let homeAddress = {};
    let workAddress = {};
    if(this.type == type){
      this.type = null;
      return;
    }
    else if(type == 'home'){
      this.type = 'home';
      workAddress = {
        address: this.workAddress ? this.workAddress['originalStringAddress'] : null,
        coords: {
          lat : this.workAddress ? this.workAddress['coordinates'][1] : null,
          lng : this.workAddress ? this.workAddress['coordinates'][0] : null,
        }
      }
      homeAddress = {
        address: this.address,
        coords: {
          lat : this.coords.lat,
          lng : this.coords.lng,
        }
      }
      this.updateAddress(homeAddress, workAddress);
    }
    else if(type == 'work'){
      this.type = 'work';
      homeAddress = {
        address: this.homeAddress ? this.homeAddress['originalStringAddress'] : null,
        coords: {
          lat : this.homeAddress ? this.homeAddress['coordinates'][1] : null,
          lng : this.homeAddress ? this.homeAddress['coordinates'][0] : null,
        }
      };
      workAddress = {
        address: this.address,
        coords: {
          lat : this.coords.lat,
          lng : this.coords.lng,
        }
      }
    }
    this.newCoords = { lat: this.coords.lat, lng: this.coords.lng };
    this.updateAddress(homeAddress, workAddress);
  }

  async updateAddress(homeAddress, workAddress)
  {
    let info = {
      "home_lat": homeAddress ? homeAddress.coords.lat : null,
      "home_lng": homeAddress ? homeAddress.coords.lng : null,
      "home_address": homeAddress ? homeAddress.address : null,
      "work_lat": workAddress ? workAddress.coords.lat : null,
      "work_lng": workAddress ? workAddress.coords.lng : null,
      "work_address": workAddress ? workAddress.address : null
    };
    let response = await this.authService.updateCustomerInfo(info);
    if(response['success']){
      this.appService.showToast("", this._translate.instant('toast.addressTypeUpdated'), 2000, "success");
    }
  }

  CIRCLE_RADIUS = 100; //_in meters
  calculateRadius (zoom) {
    //_ radius = 100 => π * radius^2 => (π * 100^2)
    const constantArea = Math.PI * Math.pow(this.CIRCLE_RADIUS, 2);;
    const center = [this.coords.lng, this.coords.lat];
    const metersPerPixel = (40075016.686 * Math.cos(center[1] * Math.PI / 180)) / (512 * Math.pow(2, zoom)); // Meters per pixel
    const radius = Math.sqrt(constantArea / Math.PI) / metersPerPixel;
    return radius;
  }

  setupMapCircleLayer () {
    this.map.circlesLayer['paint']['circle-opacity'] = 0.1;
    this.map.circlesLayer['source'] = 'markers';
    this.map.circlesLayer['paint']["circle-stroke-color"] = this.primaryColor;
    this.map.circlesLayer['paint']["circle-stroke-width"] = 3;
    this.map.reloadLayer('circlesLayer');

    this.map.markerLayer['layout']["text-anchor"] = 'left';
    this.map.markerLayer['layout']["text-offset"] = [1, 0];
    this.map.reloadLayer('markerLayer');

    //_ Lisent for zoom changes to fix the circle radius size
    let timerChangeCircleSize = null;
    this.map.map.on('zoom', () => {
      //_ Debounce time to not trigger for every little zoom factor changes
      clearTimeout(timerChangeCircleSize);
      timerChangeCircleSize = setTimeout(() => {
        const currentZoom = this.map.map.getZoom();
        if (this.map.map.getLayer('circles') && this.map.map.getSource('circles'))
          this.map.map.setPaintProperty('circles', 'circle-radius', this.calculateRadius(currentZoom));
      }, 0)

    });
  }

  ngOnDestroy(): void {
      this.cmpDestroyed$.next(true);
      this.cmpDestroyed$.complete();
  }
}
