import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import { SetupDeviceService } from './device-setup.service';
import { DeviceDataService } from '../../map/devicesData.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { animations } from 'src/app/animations/animations';
import { SetupDeviceSetingsListComponent } from '../device-settings-list/device-settings-list.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-device-setup-guide',
  templateUrl: './device-setup-guide.component.html',
  styleUrls: ['./device-setup-guide.component.scss'],
  animations: animations
})
export class DeviceSetupGuideComponent  implements OnInit, AfterViewInit, OnDestroy {
  @Input() device: any = null;
  @Input() deviceGroups: Array<any> = []
  @ViewChild('NAME_CONTENT') nameTemplete;
  @ViewChild('COLOR_CONTENT') colorTemplate;
  @ViewChild('IMAGE_CONTENT') imageTemplate;
  @ViewChild('ALARMS_CONTENT') alarmsTemplate;
  @ViewChild('POSITION_CONTENT') positionTemplate;
  @ViewChild("nav", { static: false }) nav: IonNav;
  
  steps: Array<any> = null;
  currentStep = 0;
  obs = [];
  constructor(public appService: AppService, private modalCtrl: ModalController,
              private setupDeviceService: SetupDeviceService, private devicesService: DeviceDataService, private authService: AuthenticationService, private translate: TranslateService) { }

  ngOnInit() {
    //_ Listen for device changes
    this.obs['onDeviceChange'] = this.setupDeviceService.onDeviceChange.subscribe(data => this.onDeviceChange(data));
    this.obs['onDeviceSetupDone'] = this.setupDeviceService.onDeviceSetupDone.subscribe(data => this.onDeviceSetupDone(data));
  }

  async ngAfterViewInit(){
    this.steps = [
      {
        progress: { buffer: 1/5, value: 1/5 }, templateRef: this.nameTemplete,
        showBack: false, showNext: true
      },
      {
        progress: { buffer: 2/5, value: 2/5 }, templateRef: this.colorTemplate,
        showBack: true, showNext: true
      },
      {
        progress: { buffer: 3/5, value: 3/5 }, templateRef: this.imageTemplate,
        showBack: true, showNext: true
      },
      {
        progress: { buffer: 4/5, value: 4/5 }, templateRef: this.alarmsTemplate,
        showBack: true, showNext: true
      },
      {
        progress: { buffer: 5/5, value: 5/5 }, templateRef: this.positionTemplate,
        showBack: true, showNext: false
      }
    ]

    this.nav.setRoot(SetupDeviceSetingsListComponent, {
      navigation: this.nav,
      device: this.device,
      deviceGroups: this.deviceGroups
    });
  }

  //_ Save data when device change is detected
  onDeviceChange(data){
    const { device, stepName } = data;
    this.device = device;
    const deviceInList = this.devicesService.devices[this.device.id];

    //_ Update device in devicesList and Map
    if (deviceInList){
      deviceInList.properties = this.device;
      deviceInList.color = this.device.spurfarbe;
      this.devicesService.updateDeviceInfo(deviceInList);
    }

    //_ Save device settings in backend
    const payload = {
      name: this.device.name,
      spurfarbe: this.device.spurfarbe,
      iconname: this.device.iconname,
      iconusecustom: this.device.iconusecustom,
      iconcustomimage: this.device.iconcustomimage,
      threedModel_name: this.device.threedModel_name
    };

    //_ Show and update data in backend if not comes from alarms step -> alarms are automaticly saved in alarm component
    if (stepName != 'alarms'){
      this.authService.updateDevice(this.device.id, payload).then((r) => {
        //this.appService.showToast(null, 'Device updated successfully', 2000, 'success', 'top');

        this.translate.get('devicesSetup.update.successMessage').subscribe((text) => {
          this.appService.showToast(null, text, 2000, 'success', 'top');
        });

      }).catch(error => { 
        this.appService.showToast(null, 'Error trying to update the device', 3000, 'danger', 'top');
      });
    }
  }

  onDeviceSetupDone(device){
    this.authService.updateDevice(this.device.id, { setup_done: true }).then((r) => {
      
    }).catch(error => {
      console.log('Error trying to save device data', error);
    });
  }

  nextStep(){
    if (this.currentStep < this.steps.length-1)
      this.currentStep++;
  }

  prevStep(){
    if (this.currentStep > 0)
      this.currentStep--;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy(): void {
    Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
  }

}
