import { Injectable } from "@angular/core";
import { Badge } from "@capawesome/capacitor-badge";

@Injectable({
  providedIn: "root",
})
export class BadgeService {
  constructor() {}

  async getBadge() {
    const result = await Badge.get();
    return result.count;
  }

  async setBadge(count: number) {
    // console.log('setBadge',count)
    return await Badge.set({ count });
  }

  async increaseBadge() {
    return await Badge.increase();
  }

  async decreaseBadge() {
    return  await Badge.decrease();
  }

  async clearBadge() {
    return  await Badge.clear();
  }

  async isBadgeSupported() {
    const result = await Badge.isSupported();
    return result.isSupported;
  }

  async checkBadgePermission() {
    const result = await Badge.checkPermissions();
    return result;
  }

  async requestBadgePermission() {
    const result = await Badge.requestPermissions();
  }
}
