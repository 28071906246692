import { Injectable } from '@angular/core';
import { Share } from '@capacitor/share';

@Injectable({
  providedIn: 'root'
})
export class SocialShareService {

  constructor() { }
  async share(title: string, text: string, url: any, dialogTitle: string, files: any = null) {
    console.log('[title]', title)
    console.log('[text]', text)
    console.log('[url]', url)
    console.log('[dialogTitle]', dialogTitle)
    await Share.share({
      title: title,
      text: text,
      url: url,
      dialogTitle: dialogTitle,
      files:files
    });
  }
}
