<ion-app>
  <!-- code only for testing -->
  <!-- <div style="background: red; padding: 15px">
    Token <br>
    {{ firebaseToken }}
  </div> -->
  <!-- code only for testing -->
  <app-size-detector></app-size-detector>
  <app-server-alert></app-server-alert>
  <app-custom-alert></app-custom-alert>
  <app-startup-loading *ngIf="brand != 3"></app-startup-loading>
  <ion-content scrollY="false" scrollX="false" slot="fixed">

    <!-- CREATE A NICE COMPONENT TO SHOW THE LOADING OF USER DATA -->
    <!-- <div class="loading" @fadeIn *ngIf="guardLoading$ | async">
      <ion-spinner color="primary" size="md"></ion-spinner>
    </div> -->

    <app-side-bar></app-side-bar>
    <!-- <ion-content
      scrollY="false"
      scrollX="false"
      slot="fixed"
      [hidden]="hideSplashScreen"
    >
      <section> -->
    <!-- <video
          *ngIf="brand == 0"
          #video
          poster="assets/video/thumb.jpeg"
          src="assets/video/splash-video-desktop.mp4"
          autoplay
          loop
          playsinline
          muted
        >
      </video> -->
    <!-- <img
          *ngIf="brand == 0"
          src="assets/default_brand/img/splash-desktop.gif"
        />
        <img
          *ngIf="brand != 0"
          src="assets/default_brand/img/splash-desktop.gif"
        />
      </section> -->
    <!-- <video
        *ngIf="brand == 0"
        autoplay
        muted
        loop
        id="myVideo"
        playsinline
        #video
        class="video"
      >
        <source
          src="assets/video/splash-video-desktop.mp4"
          type="video/mp4"
        />

      </video>
      <img class="video"
        *ngIf="brand != 0"
        src="assets/default_brand/img/splash-desktop.gif"
      /> -->
    <!-- </ion-content> -->
    <ion-router-outlet
      [class.pushroute]="mainMenuOpen && currentWidth>769"
      [class.transitionformenuclose]="!mainMenuOpen"
      id="main-content"
    ></ion-router-outlet>
    <app-banner></app-banner>
    <!-- <div class="network-popup" *ngIf="networkStatus == false">
      <p>{{ "noNetwork.youAreOffline" | translate }}</p>
    </div> -->
  </ion-content>
  <app-tour></app-tour>
</ion-app>
