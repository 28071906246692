import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { PwaUpdateComponent } from '../components/pwa-update/pwa-update.component';
import { StorageSetting, STORAGE_SETTINGS } from './storage-settings';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {
  updateSubs = null;
  lastUpdateDone = new StorageSetting(this.storage, STORAGE_SETTINGS.LAST_UPDATE_DONE, null);
  lastUpdateAlert = new StorageSetting(this.storage, STORAGE_SETTINGS.LAST_UPDATE_ALERT);
  pwaPopup = null;
  constructor(private readonly updates: SwUpdate, private modalCtrl: ModalController, 
    private storage: StorageService) {
    setInterval(() => {
      this.updates.checkForUpdate();
    }, 120 * 1000); // Check every 2 min for update
  }

  initUpdateCheck() {
    this.updates.checkForUpdate();
    
    //_ Stop previous listener
    if (this.updateSubs) 
      this.updateSubs.unsubscribe();

    if (this.updates.isEnabled) {
      this.updateSubs = this.updates.versionUpdates.subscribe( (event:any) => {
        if(event.type == 'VERSION_READY'){
          this.showAppUpdateAlert();
        }
      });
      this.updates.checkForUpdate();
    }
  }

  async showAppUpdateAlert() {
    const showWhatsNew = this.shouldShowWhatsNew();
    //_ In case exist a popup; should dismiss it before
    if (this.pwaPopup) {
      this.pwaPopup.dismiss();
    }

    const onClickUpdate = () => {
      this.lastUpdateDone.set(moment().unix());
      this.doAppUpdate();
    }

    this.pwaPopup = await this.modalCtrl.create( {
      component: PwaUpdateComponent,
      cssClass: 'pwa-popup',
      componentProps: {
        showWhatsNew,
        alert: this.lastUpdateAlert.get().value,
        onUpdate: onClickUpdate
      } 
    }); 

    //_ Only show in browsers not in native mobile apps.
    if (window['isBrowser']) {
      this.pwaPopup.present();
      await this.pwaPopup.onDidDismiss();
      this.pwaPopup = null;
    }
      
    // const doUpdate = await this.appService.confirmDialog(this.translate.instant('settings.updateAvailable'),
    //   this.translate.instant('settings.updateMessage'));
    // if (doUpdate)
    //   this.doAppUpdate();
  }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  //_ Check if stored update alert date is major than last update done date
  shouldShowWhatsNew(){
    if (this.lastUpdateAlert.get().value == null) 
      return false;
    else if (this.lastUpdateDone.get().value == null)
      return true;
    return this.lastUpdateAlert.get().value.date > this.lastUpdateDone.get().value;
  }
}
