import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-nodata-popup',
  templateUrl: './nodata-popup.component.html',
  styleUrls: ['./nodata-popup.component.scss'],
})
export class NodataPopupComponent implements OnInit {
  @Input() message:string;
  @Input() title:string;
  constructor( private popoverController: PopoverController,private translate: TranslateService) {
    
   }

  ngOnInit() {
    this.title = this.translate.instant(this.title);
    this.message = this.translate.instant(this.message);
  }
  onCancel() {
    this.popoverController.dismiss(null)
  }

}
