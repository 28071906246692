import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import { DeviceSetupGuideComponent } from './device-setup-guide/device-setup-guide.component';
import { SetupDeviceService } from './device-setup-guide/device-setup.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { animations } from 'src/app/animations/animations';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-devices-setup',
  templateUrl: './devices-setup.page.html',
  styleUrls: ['./devices-setup.page.scss'],
  animations: animations
})
export class DevicesSetupPage implements OnInit, OnDestroy {
  obs = [];
  loadingDevices = false;
  showDoneButton = false;
  @Input() devices = null;
  deviceGroups = [];
  constructor(public appService: AppService, private modalSelf: ModalController, private modalChild: ModalController,
              private setupDeviceService: SetupDeviceService, private authService: AuthenticationService,
              private apiService: ApiService) { }

  ngOnInit() {
    //_ Listen for device changes
    this.obs['onDeviceChange'] = this.setupDeviceService.onDeviceChange.subscribe((data: any) => {
      const { device, stepName } = data;
      const deviceIndex = this.devices.findIndex((d) => d.id == device.id);
      //_ Update local devices list with device changes
      //_ For update devices sidebar, map and backend -> check -> device-setup-guide.ts file
      this.devices[deviceIndex] = device;
      this.devices = [...this.devices];
    });

    //_ Listen for device setup done
    this.obs['onDeviceSetupDone'] = this.setupDeviceService.onDeviceSetupDone.subscribe(data => this.checkDevicesSetupDone(data));
    if (this.devices.length > 0)
      this.getDevicesGroups();
  }

  //_ Logic to open devices setup popup
  async openSetupGuide(device){
    // if (device.setup_done == true)
    //   return;

    const modal = await this.modalChild.create({
      component: DeviceSetupGuideComponent,
      backdropDismiss: true,
      cssClass: 'device-guide-setup',
      componentProps: {
        device: device,
        deviceGroups: this.deviceGroups
      },
    });

    await modal.present();
    await modal.onWillDismiss();
  }

  //_ Check when all devices setup is done
  checkDevicesSetupDone(device){
    this.showDoneButton = this.devices.filter(d => d.setup_done == false).length == 0;
  }

  dismiss(){
    this.authService.updateAllDevices({ setup_done: true }, this.appService.user.id);
    this.modalSelf.dismiss();
  }

  ngOnDestroy(): void {
    Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
  }

  getDevicesGroups () {
    this.apiService.getOnlyGroups().then(res => {
      this.deviceGroups = res.success ?? [];
    }).catch(error => this.deviceGroups = [] )
  }

}
