import { Observable, Subject } from "rxjs";
import { drawImageScaled, setSvgColor } from "./mapUtil";
import crypto from 'crypto-js';

export enum StackDataStatus { proccessing = 'proccessing', completed = 'completed', error = 'error' };

//_ id: unique id, name: image name should be also unique.
//_ status: status of the proccess, data: dataUrl of the image
//_ observable: obs to subscribe to it when new resizeImage is requested with same imageName
export interface StackData {
    id: string, name: string, status: StackDataStatus, data: any, observable: Subject<any>
}

//_ BASE CLASS FOR STACK MANAGAMENT
//_ NEED BETTER ENCAPSULATION TO DO A GENERIC PROCCESS MANAGAMENT
//_ ACCEPT THE ACTION METHOD and trigger that when START THE PROCCESS
export class StackManagamentClass {
    //_ This saves the status, dataUrl proccessed and an obs to observe the proccess flow
    //_ All images are cached by every proccess.data of the stack.
    proccessStack: Array<StackData> = [];

    generateIdFromString (stringKey) {
        const hash = crypto.MD5(stringKey);
        return hash.toString(crypto.enc.Hex);
    }

    startProccess (imageName) : StackData {
        const id = this.generateIdFromString(imageName)
        const newProccess = {
            id: id, 
            name: imageName,
            status: StackDataStatus.proccessing, 
            data: null, 
            observable: new Subject()
        };
        this.proccessStack.push(newProccess);

        return newProccess;
    }

    getProccessByName (name) {
        return this.proccessStack.find(p => p.name == name);
    }

    getProccessById (id) {
        return this.proccessStack.find(p => p.id == id);
    }

    //_ Complete a proccess passing the data to resolve the promise
    completeProccess (proccess, data) {
        proccess.data = data;
        proccess.status = StackDataStatus.completed;
        proccess.observable.next(data);
        proccess.observable.complete();
    }
}

//_ Image stack managament
export class ImageStackManagament extends StackManagamentClass {
    //_ Single quote proccess
    async resizeImage (base64Str, maxWidth = 400, maxHeight = 350, fill = 'white', cutCircle = true, withRatio = false, setColor = null, imageName) {
        let proccess = this.getProccessByName(imageName);
        // console.log('Resizing image', {imageName, stack: this.proccessStack });
        if (proccess) {
            if (proccess.status == StackDataStatus.completed){
                // console.log('Resource already loaded');
                return new Promise( (resolve) => resolve(proccess.data));
            }
        } else {
            proccess = this.startProccess(imageName);
            this.doResize(base64Str, maxWidth, maxHeight, fill, cutCircle, withRatio, setColor, proccess);
        }

        return new Promise ( (resolve, reject) => {
            proccess.observable.subscribe(res => {
                resolve(res);
            })
        })
    }

    async imageExists (url, iconName) {
        let proccess = this.getProccessByName(iconName);
        // console.log('Resizing image', {imageName, stack: this.proccessStack });
        if (proccess) {
            if (proccess.status == StackDataStatus.completed){
                // console.log('Resource already loaded');
                return new Promise( (resolve) => resolve(proccess.data));
            }
        } else {
            proccess = this.startProccess(iconName);
            this.doesImageExist(url, proccess);
        }

        return new Promise ( (resolve, reject) => {
            proccess.observable.subscribe(res => {
                resolve(res);
            })
        })
    }
    //_____________________________________________________________________________

    async doResize (base64Str, maxWidth = 400, maxHeight = 350, fill = 'white', cutCircle = true, withRatio = false, setColor = null, proccess: StackData) {
        let image = new Image();
    
        image.onload = () => {
            let canvas = document.createElement('canvas');
            let width = maxWidth;
            let height = maxHeight;
        
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');
        
            ctx.beginPath();
            if (cutCircle) {
                ctx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, true);
                ctx.fillStyle = 'rgba(255,255,255,0)';
                ctx.fill();
                ctx.closePath();
                ctx.clip();
            }
        
            if (withRatio) {
                drawImageScaled(image, ctx);
            } else {
                ctx.drawImage(image, 0, 0, width, height);
            }
        
            if (setColor) {
                let svgData = ctx.getImageData(0, 0, width, height);
                let data = svgData.data;
                data = setSvgColor(data, setColor);
                ctx.putImageData(svgData, 0, 0);
            }
        
            const dataUrl = canvas.toDataURL();
            this.completeProccess(proccess, dataUrl);
        };
    
        image.crossOrigin = "Anonymous";
        image.src = base64Str;
    }

    async doesImageExist(url, proccess) {
        let img = new Image();
        img.onload = () => {
            this.completeProccess(proccess, true);
        };
        img.onerror = () => { 
            this.completeProccess(proccess, false);
        };
        img.src = url;
    }

    //_ Single quotes for proccess example 
    async getImageData (url, id = null): Promise<any> {
        if (!id) id = this.generateIdFromString(url);
        let proccess = this.getProccessById(id);
        if (proccess) {
            if (proccess.status == StackDataStatus.completed){
                // console.log('Resource already loaded');
                return new Promise( (resolve) => resolve(proccess.data));
            }
        } else {
            proccess = this.startProccess(id);
            this.loadImageData(url, proccess);
        }

        return new Promise ( (resolve, reject) => {
            proccess.observable.subscribe(res => {
                resolve(res);
            })
        })
    }

    async loadImageData(url, proccess) {
        let img = new Image();
        img.onload = () => {
            this.completeProccess(proccess, img);
        };
        img.onerror = () => { 
            this.completeProccess(proccess, img);
        };
        img.src = url;
    }
    //____________________________________________________________
}
