import { MapComponent } from "../../map.component";
import { getSource } from "./../castMaplibreData";
import { FeatureInterface } from "./feature-interface";
import { GeolocationService } from "src/app/services/plugins/geolocation.service";
import { GyroscopeService } from "src/app/services/plugins/gyroscope.service";
import { ServiceLocator } from 'src/app/locator.service';
import { AvailableHooks } from "../componentHooks";
import { NativesettingsService } from 'src/app/services/plugins/nativesettings.service';
import { PopoverController } from "@ionic/angular";
import { RequestPermissionsComponent } from "src/app/components/request-permissions/request-permissions.component";
import { AvailablePermissions } from "src/app/components/request-permissions/permissions";
import { zoomInAnimation } from "src/app/animations/ionic-animations";
import { Capacitor } from "@capacitor/core";
import { MainMapInterface } from "../../main-map.interface";
const GPS_STATUS = { NOT_ENABLE: 'not enabled', GRANTED: 'granted', DENIED: 'denied' }

export class ClientLocation implements FeatureInterface {
    layer = null;
    source = null;
    show = true;

    SOURCE_NAME = "clientLocationSource";
    LAYER_NAME = "clientLocation";
    clientLocationMarkerImageInMotion = null;
    clientLocationMarkerImageNoMotion = null;
    public clientLocationState = 'off';
    public flownTo = false;

    geoLocationOptions = { maximumAge: 10000, timeout: 1000, enableHighAccuracy: true };
    geolocationData = null;
    gyroscopeWatcher = null;
    gyroscopeData = { magneticHeading: 0 };
    updateLoop = null;
    layerHook = null;
    permissionChangedWatcher = null;

    gpsStatus: string = GPS_STATUS.DENIED;
    constructor (
        public main: MainMapInterface,
        public gyroscopeService = ServiceLocator.injector.get(GyroscopeService),
        public geolocationService = ServiceLocator.injector.get(GeolocationService),
        public nativeSettings = ServiceLocator.injector.get(NativesettingsService),
        private popoverCtrl = ServiceLocator.injector.get(PopoverController)
        ){
        this.init();
    }

    //_ Declare source and layer for the map
    init(){
        this.source = this.main.createDataSource(this.SOURCE_NAME);
        this.layer = this.main.createLayer(this.LAYER_NAME, this.SOURCE_NAME);

        //Request client location access:


        //Marker
        const size = 200;
        const clientLocationInstance = this;
        this.clientLocationMarkerImageInMotion = {
          width: size,
          height: size,
          data: new Uint8Array(size * size * 4),

          // When the layer is added to the map,
          // get the rendering context for the map canvas.
          onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
          },

          // Call once before every frame where the icon will be used.
          render: function () {
            const duration = 1000;
            const t = (performance.now() % duration) / duration;

            const radius = (size / 2) * 0.3;
            const outerRadius = (size / 2) * 0.7 * t + radius;
            const context = this.context;

            // Draw the outer circle.
            context.clearRect(0, 0, this.width, this.height);
            context.beginPath();
            context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
            );
            context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
            context.fill();

            // Draw the inner circle.
            context.beginPath();
            context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
            );
            //context.fillStyle = 'rgba(255, 100, 100, 1)';
            context.fillStyle = '#ff4e00';
            context.strokeStyle = 'white';
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            // Draw the Arrow.
            let height = 200 * Math.cos(Math.PI / 6);
            const x0 = this.width / 2;
            const y0 = this.height / 2;
            const p1 = { x: x0 - 25, y: y0 - 30 }
            const p2 = { x: x0 + 25, y: y0 - 30 }
            const p3 = { x: x0, y: y0 - 60 }

            context.beginPath();
            context.moveTo( p1.x , p1.y );
            context.arcTo(
              x0,
              y0-60,
              p2.x,
              p2.y,
              //Math.sqrt( Math.pow(p1.x-x0, 2) + Math.pow(p1.y-y0, 2) ) / 2 //radius + 10
              radius + 10
            );

            context.lineTo( p3.x, p3.y - 1 ); // -1 ios / safari bug hotfix
            context.closePath();

            // the outline
            context.lineWidth = 2 + 4 * (1 - t);
            context.strokeStyle = 'white';
            context.stroke();

            // the fill color
            context.fillStyle = "#ff4e00";
            context.fill();

            // Update this image's data with data from the canvas.
            this.data = context.getImageData(
            0,
            0,
            this.width,
            this.height
            ).data;

            // Continuously repaint the map, resulting
            // in the smooth animation of the dot.
            clientLocationInstance.main.map.triggerRepaint();

            // Return `true` to let the map know that the image was updated.
            return true;
          }
        };

        /*
        this.clientLocationMarkerImageNoMotion = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
              const canvas = document.createElement('canvas');
              canvas.width = this.width;
              canvas.height = this.height;
              this.context = canvas.getContext('2d');
            },

            // Call once before every frame where the icon will be used.
            render: function () {
              const duration = 1000;
              const t = (performance.now() % duration) / duration;

              const radius = (size / 2) * 0.3;
              const outerRadius = (size / 2) * 0.7 * t + radius;
              const context = this.context;

              // Draw the outer circle.
              context.clearRect(0, 0, this.width, this.height);
              context.beginPath();
              context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
              );
              context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
              context.fill();

              // Draw the inner circle.
              context.beginPath();
              context.arc(
              this.width / 2,
              this.height / 2,
              radius,
              0,
              Math.PI * 2
              );
              //context.fillStyle = 'rgba(255, 100, 100, 1)';
              context.fillStyle = '#ff4e00';
              context.strokeStyle = 'white';
              context.lineWidth = 2 + 4 * (1 - t);
              context.fill();
              context.stroke();

              // the outline
              context.lineWidth = 2 + 4 * (1 - t);
              context.strokeStyle = 'white';
              context.stroke();

              // the fill color
              context.fillStyle = "#ff4e00";
              context.fill();

              // Update this image's data with data from the canvas.
              this.data = context.getImageData(
              0,
              0,
              this.width,
              this.height
              ).data;

              // Continuously repaint the map, resulting
              // in the smooth animation of the dot.
              clientLocationInstance.main.map.triggerRepaint();

              // Return `true` to let the map know that the image was updated.
              return true;
            }
        };
        */
    }

    //_ Load source and layer on the map
    async load(){
        //Directly request a position on load
        this.geolocationData = await this.geolocationService.getCurrentPosition(this.geoLocationOptions);

        //Marker
        if (!this.main.map.hasImage("pulsing-dot")){
            this.main.map.addImage("pulsing-dot", this.clientLocationMarkerImageInMotion, { pixelRatio: 2 });
        }

        //Source:
        this.source = {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                'type': 'Feature',
                'geometry': {
                  'type': 'Point',
                  'coordinates': [
                    this.geolocationData.coords.longitude ? this.geolocationData.coords.longitude : 0,
                    this.geolocationData.coords.latitude ? this.geolocationData.coords.latitude : 0
                    ]
                },
                'properties': {
                  'rotation': 0
                }
                }
              ]
            }
        }

        //Layer:
        this.layer.type = 'symbol';
        this.layer.layout = {
          'icon-image': 'pulsing-dot',
          'icon-rotate': ['get', 'rotation'],
          'icon-pitch-alignment': 'map'
        }

        if (!this.main.map.getSource(this.SOURCE_NAME))
            this.main.map.addSource(this.SOURCE_NAME, this.source);

        if (!this.main.map.getLayer(this.LAYER_NAME))
            this.main.map.addLayer(this.layer, 'onlineDot');

        if(!this.gyroscopeWatcher){
            this.gyroscopeWatcher = this.gyroscopeService.startWatcher().subscribe(async (r: any) => {
                this.gyroscopeData = r;
                this.drawClientLocationMarker(this.geolocationData.coords.longitude, this.geolocationData.coords.latitude, this.gyroscopeData.magneticHeading);
            });
        }

    }

    async startClientLocation(){
        this.clientLocationState = 'waiting';

        //_ Check permissions return permission granted but not detect if device geolocation is on or off
        await this.geolocationService.checkPermission().then((e: any)=> {
          this.gpsStatus = this.getGpsStatus(e.location);
        //_ Catch error when device geolocation is not enabled and permission is granted
        }).catch( async e => {
          this.stopClientLocation();
          this.gpsStatus = this.getGpsStatus(e.message);
        });

        //GPS Permission change detection
        if(!this.permissionChangedWatcher) {
            this.permissionChangedWatcher = this.geolocationService.statusChanged.subscribe(async (r: any) => {
                this.geolocationService.gpsActivated = r.gpsActivated;
                this.geolocationService.hasGpsPermit = r.hasGpsPermit;
                if (r.gpsActivated && r.hasGpsPermit)
                  this.gpsStatus = GPS_STATUS.GRANTED;
            });
        }

        if (this.geolocationService.gpsActivated && this.geolocationService.hasGpsPermit && this.gpsStatus == GPS_STATUS.GRANTED) {
            await this.load();

            //Listen to layer changes -> reload
            this.layerHook = this.main.on(AvailableHooks.onLoadLayer, async (e) => {
                await this.load();
            });

            if (this.updateLoop) clearInterval(this.updateLoop);
            this.updateLoop = setInterval( async () => {
                //_ Stop requesting currentPosition when some error happend
                this.geolocationData = await this.geolocationService.getCurrentPosition(this.geoLocationOptions).catch(e => this.stopClientLocation());
                this.drawClientLocationMarker(this.geolocationData.coords.longitude,this.geolocationData.coords.latitude, this.gyroscopeData.magneticHeading);
            }, 1000, this);
        } else {

            const prevStatus = this.gpsStatus + '';
            this.stopClientLocation();
            this.gpsStatus = prevStatus;
            // await this.geolocationService.requestPermission();
            await this.geolocationService.requestPermission().then( async (e) => {
              console.log('STATUALL', { gpsActivated: this.geolocationService.gpsActivated, hasGpsPermit: this.geolocationService.hasGpsPermit, GPS_STATUS: this.gpsStatus, RESLT: e})
              //_ If native allow popup is shown : check again the gpsActivated and permission
              if (this.geolocationService.gpsActivated && this.geolocationService.hasGpsPermit) {
                return this.startClientLocation();
              }

              //_ In case user rejected once before : show custom popup for request permission
              if (this.gpsStatus == GPS_STATUS.DENIED){
                this.requestPermissionInCaseWasRejectedBefore();
                return;
              }

              //_ In case FOR ANDROID if device location is disabled then trigger Dialog to enable it
              if (this.gpsStatus == GPS_STATUS.NOT_ENABLE) {
                this.geolocationService.requestNativeDialog().then(r => {
                  if (r) this.startClientLocation();
                  else this.clientLocationState = 'off';
                })
              }
            }).catch( e => {
              this.stopClientLocation();
            })

            if (this.geolocationService.gpsActivated && this.geolocationService.hasGpsPermit && this.gpsStatus == GPS_STATUS.GRANTED) {
              this.startClientLocation();
            }
        }
    }

    stopClientLocation(){
        this.clientLocationState = 'off';

        clearTimeout(this.updateLoop);
        this.removeClientLocationMarker();
        if (this.gyroscopeWatcher) {
            this.gyroscopeWatcher.unsubscribe();
            this.gyroscopeWatcher = null;
        }
        if (this.permissionChangedWatcher) {
            this.permissionChangedWatcher.unsubscribe();
            this.permissionChangedWatcher = null;
        }
        if(this.layerHook) this.main.off(this.layerHook);
        this.geolocationService.gpsActivated = false;
        this.geolocationService.hasGpsPermit = false;
        this.gpsStatus = GPS_STATUS.DENIED;
    }

    drawClientLocationMarker(longitude,latitude,rotation = 0){
        this.clientLocationState = 'on';

        //Source:
        this.source = {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [
              {
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [longitude, latitude]
              },
              'properties': {
                'rotation': rotation
              }
              }
            ]
          }
        }

        if (this.main.map.getSource(this.SOURCE_NAME)){
            getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
        }
    }

    removeClientLocationMarker(){
      if (this.main.map.getLayer(this.LAYER_NAME)) this.main.map.removeLayer(this.LAYER_NAME);
      if (getSource(this.main.map, this.SOURCE_NAME)) this.main.map.removeSource(this.SOURCE_NAME);
      this.main.map.triggerRepaint();
    }

    //_ Set status of every feature in the source
    async toggleFeature(zoom = 0){
      /*
        if(this.clientLocationState == 'on' || this.clientLocationState == 'waiting') {
          if(this.flownTo) {
            this.flownTo = false;
            this.stopClientLocation();
            return;
          }
          this.flownTo = true;
          this.main.flyTo(this.geolocationData.coords.longitude,this.geolocationData.coords.latitude);
          return;
        }
        */

        if(this.clientLocationState == 'on' || this.clientLocationState == 'waiting') {
          this.stopClientLocation();
          return;
        }

        await this.startClientLocation();
        this.main._flyTo(this.geolocationData.coords.longitude, this.geolocationData.coords.latitude, zoom > 12? zoom: 12);
    }

    getGpsStatus (errorMessage) {
      const value = Object.values(GPS_STATUS).find(value =>  errorMessage.includes(value));
      return value;
    }

    //_ Request permission when prompt is not shown -> that happend when user rejected once ther permission and not show popup again was marked
    requestPermissionInCaseWasRejectedBefore () {
      this.requestPermissionPopup().then(r => {
        //_ If returned status is true means user allow gps location
        if (r){
          this.gpsStatus = GPS_STATUS.GRANTED;
          this.startClientLocation();
        }
        else
          this.main.appService.showToast('', this.main._translate.instant('mapPage.clientLocation.needPermissionMessage'), 3000, 'danger')
      });
    }

    //_ Request permission popup where the user can grant gps location from device app settings
    async requestPermissionPopup() {
      let iosPermissionCheck = null;
      //_ Permission check not support ios; so this is a custom check fn to pass to the generic request permission popup
      if (Capacitor.getPlatform() == 'ios' && Capacitor.isNativePlatform()){
        iosPermissionCheck = () => { return new Promise( (resolve) => {
          this.geolocationService.requestPermission().then(r => {
              resolve(r.location == 'granted')
            })
          })
        }
      }

      return new Promise(async (resolve, reject) => {
        const popoverObj = await this.popoverCtrl.create({
          component: RequestPermissionsComponent,
          cssClass: "choice-tour-popover",
          componentProps: { permissionName: AvailablePermissions.Geolocation, permissionCheckFn: iosPermissionCheck },
          showBackdrop: true,
          mode: 'ios',
          enterAnimation: zoomInAnimation
        });

        popoverObj.present();
        const { data } = await popoverObj.onWillDismiss();

        if (data) {
          if (data.hasPermission) resolve(true);
        }

        resolve(false);
      })

    }

}
