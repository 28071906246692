import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CommandComponent } from './command.component';
import { PajTimerCountModule } from 'src/app/members/generate-video/timer-count/paj-timer-count.module';
import { SetupWifiModule } from './setup-wifi/setup-wifi.module';
import { VoiceMessagesModule } from './voice-messages/voice-messages.module';
import { TextInputModule } from './text-input/text-input.module';
import { AlarmClockModule } from './alarm-clock/alarm-clock.module';
import { PulseMeasurementModule } from './pulse-measurement/pulse-measurement.module';

@NgModule({
    imports: [
      CommonModule,
      IonicModule,
      FormsModule,
      ReactiveFormsModule,
      PipesModule.forRoot(),
      TranslateModule,
      PajTimerCountModule,
      SetupWifiModule,
      VoiceMessagesModule,
      TextInputModule,
      AlarmClockModule,
      PulseMeasurementModule
    ],

    declarations: [CommandComponent],
    exports: [CommandComponent, PipesModule],

  })

  export class PajDeviceCommandModule {}
